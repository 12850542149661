import * as types from '../actions/types';
import {
  getReportFailed,
  getReportRequesting,
  getReportSuccess,
  onPrepareReport,
  onReceiveReport,
  getMailFiancaFailed,
  getMailFiancaSuccess,
  getMailFiancaRequesting,
  getMailFiancaReset
} from './reducers';

const initialState = {
  requesting: true,
  error: undefined,
  data: {},
  applicants: [],
  requestingMailFianca: false,
  errorMailFianca: false,
  successMailFianca: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.REPORT_REQUESTNG:
      return getReportRequesting(state);
    case types.REPORT_SUCCESS:
      return getReportSuccess(state, payload);
    case types.REPORT_FAILED:
      return getReportFailed(state);
    case types.PREPARE_REPORT:
      return onPrepareReport(state, payload);
    case types.RECEIVE_REPORT:
      return onReceiveReport(state, payload);
    case types.SEND_MAIL_FIANCA:
      return getMailFiancaRequesting(state, payload);
    case types.SEND_MAIL_FIANCA_FAILED:
      return getMailFiancaFailed(state, payload);
    case types.SEND_MAIL_FIANCA_SUCCESS:
      return getMailFiancaSuccess(state, payload);
    case types.SEND_MAIL_FIANCA_RESET:
      return getMailFiancaReset(state, payload);
    default:
      return state;
  }
}
