import { simpleRequiredText, simpleDisabledText, simpleNonRequiredText } from '@bit/wavfichacerta.fichacerta.fields-templates.simple-text';
import { simpleRequiredSelect } from '@bit/wavfichacerta.fichacerta.fields-templates.select';
import { defaultCEP } from '@bit/wavfichacerta.fichacerta.fields-templates.cep';
import { defaultPhoneWithoutDDI } from '@bit/wavfichacerta.fichacerta.fields-templates.phone';
import { defaultNonRequiredPhoneWithoutDDI } from '../../../components/shared/fcFields';

const sizes = {
  containerLarge: "field-fc field-fc-m",
  pronounsName: "field-fc col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xs-2",
  name: "field-fc col-xs-6 col-sm-6 col-md-10 col-lg-10 col-xl-10",
  profile: "field-fc col-xs-6 col-sm-6 col-md-3",
  common: "field-fc col",
  cep: "field-fc field-fc-xs",
  creci: "field-fc col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3",
  fullColumn: 'field-fc col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12',
  address: "field-fc col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3",
  website: "field-fc col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
  phone: "field-fc col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
  contactsPhone: "field-fc field-fc-xs"
}

// Identification Section
export const identificationFields = (master) => { 
  if(master){
    return [
      {
        name: 'firstLine',
        fields: [
          // simpleRequiredText({ size: sizes.containerLarge, name: "nome", label: "Razão Social" }),
          // simpleDisabledText({ size: sizes.profile, name: 'cnpj', label: 'CNPJ' }),
          // simpleNonRequiredText({ size: sizes.creci, name: "creci", label: 'CRECI' })
          simpleDisabledText({ size: sizes.containerLarge, name: "nome", label: "Razão Social" }),
          simpleDisabledText({ size: sizes.profile, name: 'cnpj', label: 'CNPJ' }),
          simpleDisabledText({ size: sizes.creci, name: "creci", label: 'CRECI' })
        ]
      }
    ];
  }else{
    return [
      {
        name: 'firstLine',
        fields: [
          simpleDisabledText({ size: sizes.containerLarge, name: "nome", label: "Razão Social" }),
          simpleDisabledText({ size: sizes.profile, name: 'cnpj', label: 'CNPJ' }),
          simpleDisabledText({ size: sizes.creci, name: "creci", label: 'CRECI' })
        ]
      }
    ];
  }
}

// Address Section
export const addressFields = (master) => {
  if(master){
    return [
      // {
      //   name: 'firstLine',
      //   fields: [
      //     defaultCEP(sizes.cep, '', 'CEP', 'cep'),
      //     simpleRequiredText({ size: sizes.address, name: 'logradouro', label: 'Endereço' }),
      //     simpleRequiredText({ size: sizes.common, name: 'numero', label: 'Número' }),
      //     simpleNonRequiredText({ size: sizes.common, name: 'complemento', label: 'Complemento' }),
      //     simpleRequiredText({ size: sizes.common, name: 'bairro', label: 'Bairro' }),
      //     simpleRequiredText({ size: sizes.common, name: 'cidade', label: 'Cidade' }),
      //     simpleRequiredSelect({ size: sizes.common, name: 'uf', label: 'Estado' }),
      //   ]
      // },
      // {
      //   name: 'secondLine',
      //   fields: [
      //     simpleNonRequiredText({ size: sizes.website, name: 'website', label: 'Website' }),
      //     defaultPhoneWithoutDDI("telefone", "Telefone", sizes.contactsPhone),
      //     defaultPhoneWithoutDDI("telefone_adicional", "Telefone adicional", sizes.contactsPhone, false),
      //   ]
      // }
      {
        name: 'firstLine',
        fields: [
          simpleDisabledText({ size: sizes.cep, label: 'CEP', name: 'cep' }),
          simpleDisabledText({ size: sizes.address, name: 'logradouro', label: 'Endereço' }),
          simpleDisabledText({ size: sizes.common, name: 'numero', label: 'Número' }),
          simpleDisabledText({ size: sizes.common, name: 'complemento', label: 'Complemento' }),
          simpleDisabledText({ size: sizes.common, name: 'bairro', label: 'Bairro' }),
          simpleDisabledText({ size: sizes.common, name: 'cidade', label: 'Cidade' }),
          simpleDisabledText({ size: sizes.common, name: 'uf', label: 'Estado' }),
        ]
      },
      {
        name: 'secondLine',
        fields: [
          simpleDisabledText({ size: sizes.website, name: 'website', label: 'Website' }),
          simpleDisabledText({ name: "telefone", label: "Telefone", size: sizes.contactsPhone }),
          simpleDisabledText({ name: "telefone_adicional", label: "Telefone adicional", size: sizes.contactsPhone }),
        ]
      }
    ];
  }else{
    return [
      {
        name: 'firstLine',
        fields: [
          simpleDisabledText({ size: sizes.cep, label: 'CEP', name: 'cep' }),
          simpleDisabledText({ size: sizes.address, name: 'logradouro', label: 'Endereço' }),
          simpleDisabledText({ size: sizes.common, name: 'numero', label: 'Número' }),
          simpleDisabledText({ size: sizes.common, name: 'complemento', label: 'Complemento' }),
          simpleDisabledText({ size: sizes.common, name: 'bairro', label: 'Bairro' }),
          simpleDisabledText({ size: sizes.common, name: 'cidade', label: 'Cidade' }),
          simpleDisabledText({ size: sizes.common, name: 'uf', label: 'Estado' }),
        ]
      },
      {
        name: 'secondLine',
        fields: [
          simpleDisabledText({ size: sizes.website, name: 'website', label: 'Website' }),
          simpleDisabledText({ name: "telefone", label: "Telefone", size: sizes.contactsPhone }),
          simpleDisabledText({ name: "telefone_adicional", label: "Telefone adicional", size: sizes.contactsPhone }),
        ]
      }
    ];
  }
} 

// Aditional Contacts Section
export const aditionalContactsFirstFields = (master) => {
  if(master){
    return [
      // {
      //   name: 'firstLine',
      //   fields: [
      //     simpleNonRequiredText({ size: sizes.address, name: 'operacional_nome', label: 'Nome' }),
      //     simpleNonRequiredText({ size: sizes.address, name: 'operacional_email', label: 'E-mail' }),
      //     simpleNonRequiredText({ size: sizes.address, name: 'operacional_funcao', label: 'Função' }),
      //     defaultPhoneWithoutDDI('operacional_telefone', 'Telefone', sizes.phone, false)
      //   ]
      // }
      {
        name: 'firstLine',
        fields: [
          simpleDisabledText({ size: sizes.address, name: 'operacional_nome', label: 'Nome' }),
          simpleDisabledText({ size: sizes.address, name: 'operacional_email', label: 'E-mail' }),
          simpleDisabledText({ size: sizes.address, name: 'operacional_funcao', label: 'Função' }),
          simpleDisabledText({ name: 'operacional_telefone', label: 'Telefone', size: sizes.phone })
        ]
      }
    ];
  }else{
    return [
      {
        name: 'firstLine',
        fields: [
          simpleDisabledText({ size: sizes.address, name: 'operacional_nome', label: 'Nome' }),
          simpleDisabledText({ size: sizes.address, name: 'operacional_email', label: 'E-mail' }),
          simpleDisabledText({ size: sizes.address, name: 'operacional_funcao', label: 'Função' }),
          simpleDisabledText({ name: 'operacional_telefone', label: 'Telefone', size: sizes.phone })
        ]
      }
    ];
  }
} 

export const aditionalContactsSecondFields = (master) => {
  if(master){
    return [
      // {
      //   name: 'secondLine',
      //   fields: [
      //     simpleNonRequiredText({ size: sizes.address, name: 'financeiro_nome', label: 'Nome' }),
      //     simpleNonRequiredText({ size: sizes.address, name: 'financeiro_email', label: 'E-mail' }),
      //     simpleNonRequiredText({ size: sizes.address, name: 'financeiro_funcao', label: 'Função' }),
      //     defaultPhoneWithoutDDI('financeiro_telefone', 'Telefone', sizes.phone, false)
      //   ]
      // }
      {
        name: 'secondLine',
        fields: [
          simpleDisabledText({ size: sizes.address, name: 'financeiro_nome', label: 'Nome' }),
          simpleDisabledText({ size: sizes.address, name: 'financeiro_email', label: 'E-mail' }),
          simpleDisabledText({ size: sizes.address, name: 'financeiro_funcao', label: 'Função' }),
          simpleDisabledText({ name: 'financeiro_telefone', label: 'Telefone', size: sizes.phone })
        ]
      }
    ];
  }else{
    return [
      {
        name: 'secondLine',
        fields: [
          simpleDisabledText({ size: sizes.address, name: 'financeiro_nome', label: 'Nome' }),
          simpleDisabledText({ size: sizes.address, name: 'financeiro_email', label: 'E-mail' }),
          simpleDisabledText({ size: sizes.address, name: 'financeiro_funcao', label: 'Função' }),
          simpleDisabledText({ name: 'financeiro_telefone', label: 'Telefone', size: sizes.phone })
        ]
      }
    ];
  }
}

export const aditionalContactsThirdFields = (master) => {
  if(master){
    return [
      // {
      //   name: 'thirdLine',
      //   fields: [
      //     simpleNonRequiredText({ size: sizes.address, name: 'diretoria_nome', label: 'Nome' }),
      //     simpleNonRequiredText({ size: sizes.address, name: 'diretoria_email', label: 'E-mail' }),
      //     simpleNonRequiredText({ size: sizes.address, name: 'diretoria_funcao', label: 'Função' }),
      //     defaultNonRequiredPhoneWithoutDDI('diretoria_telefone', 'Telefone', sizes.phone)
      //   ]
      // }
      {
        name: 'thirdLine',
        fields: [
          simpleDisabledText({ size: sizes.address, name: 'diretoria_nome', label: 'Nome' }),
          simpleDisabledText({ size: sizes.address, name: 'diretoria_email', label: 'E-mail' }),
          simpleDisabledText({ size: sizes.address, name: 'diretoria_funcao', label: 'Função' }),
          simpleDisabledText({ name: 'diretoria_telefone', label: 'Telefone', size: sizes.phone })
        ]
      }
    ];
  }else{
    return [
      {
        name: 'thirdLine',
        fields: [
          simpleDisabledText({ size: sizes.address, name: 'diretoria_nome', label: 'Nome' }),
          simpleDisabledText({ size: sizes.address, name: 'diretoria_email', label: 'E-mail' }),
          simpleDisabledText({ size: sizes.address, name: 'diretoria_funcao', label: 'Função' }),
          simpleDisabledText({ name: 'diretoria_telefone', label: 'Telefone', size: sizes.phone })
        ]
      }
    ];
  }
} 