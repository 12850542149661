import React, { PureComponent } from 'react';
import { CardAnalythic } from '../analythic.styled';
import AmountContainerPj from './AmountContainerPj';
import ProfileContainer from './ProfileContainer';
import ProfileContainerPj from './ProfileContainerPj';
import BusinessParticipation from './BusinessParticipation';
import RoundedMoney from '@bit/wavfichacerta.fichacerta.icons.rounded-money';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../shared/TourTooltip';

export default class RentAndJobPj extends PureComponent {

  render() {
    const { data } = this.props;
    const fcDoc = data.pretendente.produtos.ativo.filter(product => product.id === 6 || product.id === 8)

    return (
      <CardAnalythic
        title="PERFIL FINANCEIRO"
        iconHeader={RoundedMoney}
        iconLeft={true}
      >
        {fcDoc.length > 0 && fcDoc[0].ativo === true && data.laudo.produtos.fcrenda.resultado &&
          <span style={{backgroundColor: '#152b4e', color: '#fff', fontWeight: 600, padding: '2px 6px', marginLeft: 4, position: 'absolute', top: 12, left: 222}}>
            FC DOC
          </span>
        }
        
        <AmountContainerPj data={data} />
        <ProfileContainerPj data={data} />
        {/* <BusinessParticipation data={data} /> */}
        
        {/* <TourTooltip
          target="tooltip-perfil-financeiro-pj"
          style={{right: 0, top: -15}}
        />
        <ReactTooltip id="tooltip-perfil-financeiro-pj" place="left" type="dark" effect="float">
          <span>Detectamos faturamento presumido mensal.</span>
        </ReactTooltip> */}
      </CardAnalythic>
    );
  }
}
