import styled from 'styled-components';

export const ContainerRequest = styled.div`
  width: 100%;
  height: ${props => props.editMode? '91vh': '84vh'};
  max-height: ${props => props.editMode? '91vh': '84vh'};

  @media (max-width: 800px) {
    height: ${props => props.editMode? '91vh': 'initial'};
    max-height: ${props => props.editMode? '91vh': 'initial'};
  }
`;

export const ContainerForm = styled.div`
  margin: auto;

  label { bottom: 23px !important; }

  @media (max-width: 800px) {
    width: 95%;

    button {
      width: 90px;
      height: 33px;
      padding: 0;
      font-size: 14px;
    }
  }
`;
