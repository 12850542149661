import styled from 'styled-components';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';

export const IconContainer = styled.div`
  width: 15px;
  align-self: center;
`;

export const StatusBar = styled.span`
  height: 100%;
  width: .1rem;
  border-bottom: .1rem;
  border-top: 1.1rem;
  border-left: .1rem;
  border-right: .1rem;
  border-style: solid;
  border-color: ${props => {
    if(props.status === "negativo" || props.status === "negativo-nulo" || props.status === "triangule-red"){
      return props.theme['color_fire-engine-red'];
    }else if(props.status === "positivo" || props.status === "triangule-green-light"){
      return props.theme.color_green;
    }else if(props.status === "laranja-nulo" || props.status === "triangule-orange"){
      return "#ff7200";
    }else if(props.status === "triangule-yellow"){
      return "#feda32";
    }else if(props.status === "triangule-green-dark"){
      return "#006400";
    }else{
      return props.theme['color_dark-gray'];
    }
  }};
`;

export const ResumeList = styled.ul`
  width: 100%;
  height: 100%;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ResumeLi = styled.li`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  color: ${props => props.theme.color_gray};
  padding: .25rem 0;
  margin: .1rem 0;
  font-size: 12px;
  justify-content: start;

  &:nth-last-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:not(:hover) {
    background-color: ${({name}) => (name == 'NEGATIVAÇÕES DE CRÉDITO*' || name == 'AÇÕES DE DESPEJO E DE LOCAÇÃO*' || name == 'AÇÕES DE DESPEJO E DE LOCAÇÃO*') && `#ffefcb !important` || name === 'AÇÕES CRIMINAIS (v. Beta)*' && `#673ab736 !important` || name === 'AÇÕES CÍVEIS (v. Beta)*' && `#79554833 !important`};
  }

  &:hover {
    cursor: ${({name}) => (name != 'IRÁ RESIDIR' && name != 'RENDAS INFORMADAS em conjunto' && name != 'ORIGEM Perfil Financeiro ' && name != 'RENDAS PRESUMIDAS em conjunto (Mercado)' && name != 'SCORE FC em conjunto (nesta locação)' && name != 'RISCO DE CRÉDITO em conjunto (Mercado)' && name != 'RISCO FC em conjunto (nesta locação)') ? `pointer` : `default`};
    background-color: ${props => {
      if(props.status === 'triangule-green-dark') {
        return '#afefa4';
      }
      if(props.status === 'triangule-yellow') {
        return '#ffefcb';
      }
      if(props.status === 'triangule-orange') {
        return '#ffd3be';
      }else if(props.status === "negativo" || props.status === "triangule-red"){
        return props.theme['color_tr-light-red'];
      }else if(props.status === "positivo" || props.status === "triangule-green-light"){
        return props.theme['color_tr-light-green'];
      }else{
        return props.theme['color_tr-light-gray'];
      }
    }} !important;
  }
`;

export const TextContainer = styled(BaseColumn)`
  display: flex;
  flex-direction: row;
  align-self: center;

  @media(max-width: 800px){
    width: 45%;
  }
`;

export const ResumeText = styled.div`
  padding: 0 .5rem;
  font-weight: 500;
  font-size: 11px;
  display: flex;
  align-items: center;
  width: 100%;
`;
