import * as types from '../actions/types';
import {
  onGetRequests,
  onGetRequestsFailed,
  onGetRequestsSuccess,
  onChangePage,
  onChangeResultsPerPage,
  onChangeSort,
  onSetFilter,
  onSetEndDate,
  onSetInitialDate,
  onResetRequests
} from './reducers';

const initialState = {
  requesting: false,
  error: undefined,
  data: [],
  pagination: {
		totalCount: undefined,
		numberOfPages: undefined,
		lastPage: undefined,
		currentPage: 1,
		resultsPerPage: localStorage.getItem("resultsPerPageRequests") || '10',
		sort: 'nome',
		nextPage: '',  
		previousPage: '',
    filter: '',
    date: {}
	}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.FCFIANCA_REQUESTS_LOADING:
      return onGetRequests(state);
    case types.FCFIANCA_REQUESTS_DONE:
      return onGetRequestsSuccess(state, payload);
    case types.FCFIANCA_REQUESTS_ERROR:
      return onGetRequestsFailed(state);
    case types.FCFIANCA_CHANGE_PAGE:
			return onChangePage(state, payload);
		case types.FCFIANCA_CHANGE_PER_PAGE:
      return onChangeResultsPerPage(state, payload);
    case types.FCFIANCA_CHANGE_SORT:
      return onChangeSort(state, payload);
    case types.FCFIANCA_SET_FILTER:
      return onSetFilter(state, payload);
    case types.FCFIANCA_SET_FINAL_DATE:
      return onSetEndDate(state, payload);
    case types.FCFIANCA_SET_START_DATE:
      return onSetInitialDate(state, payload);
    case types.FCFIANCA_RESET_REQUESTS:
      return onResetRequests(state, initialState);
    default:
      return state;
  }
}
