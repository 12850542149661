import React, { PureComponent } from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  width: 100%;
  height: 100%;

  & > g > path:first-child {
    fill: ${props => {
      if(props.order === "" || props.order === "ASC") {
        return `${props.theme['color_border-gray']};`;
      } else {
        return `${props.theme['color_dark-gray']};`;
      }
    }}
  }

  & > g > path:last-child {
    fill: ${props => {
      if(props.order === "" || props.order === "DESC") {
        return `${props.theme['color_border-gray']};`;
      } else {
        return `${props.theme['color_dark-gray']};`;
      }
    }}
  }
`;

export default class OrderArrow extends PureComponent {

  render() {
    return (
      <SVG {...this.props} viewBox="10 10 600 600">
        <g>
          <path d="M453.1,29.3v409.3l-76.6-76.6c-9.1-9.1-23.8-9.1-32.9,0c-9.1,9.1-9.1,23.8,0,32.9l116.4,116.4
            c9.1,9.1,23.8,9.1,32.9,0l116.4-116.4c4.5-4.5,6.8-10.5,6.8-16.5c0-6-2.3-11.9-6.8-16.5c-9.1-9.1-23.8-9.1-32.9,0l-76.6,76.6V29.3
            c0-12.9-10.4-23.3-23.3-23.3S453.1,16.4,453.1,29.3z"/>
          <path d="M63.8,129.2c-9.1,9.1-9.1,23.8,0,32.9c9.1,9.1,23.8,9.1,32.9,0l76.6-76.6v409.3
            c0,12.9,10.4,23.3,23.3,23.3c12.9,0,23.3-10.4,23.3-23.3V85.5l76.6,76.6c9.1,9.1,23.8,9.1,32.9,0c4.5-4.5,6.8-10.5,6.8-16.5
            c0-6-2.3-11.9-6.8-16.5L213.1,12.8c-9.1-9.1-23.8-9.1-32.9,0L63.8,129.2z"/>
        </g>
      </SVG>
    );
  }
}
