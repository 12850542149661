import React from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  ContainerGray,
  ConceptTitle,
  ConceptLine
} from '../analythic.styled';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../shared/TourTooltip';

export default (props) => {
  const Null = props.Null
  return (
    <ContainerGray style={props.style} className="escalas-de-risco">
      <ConceptTitle style={{position: 'relative', width: 'max-content', color: '#000'}}>
        Escala de risco de mercado referente ao score acima:

        {/* <TourTooltip
          target="tooltip-escalas-risco-pj"
          style={{right: -10, top: 0}}
        />
        <ReactTooltip id="tooltip-escalas-risco-pj" place="top" type="dark" effect="float">
          <span>Observe aqui a escala de risco de mercado, e identifique assim o <br />risco que essa empresa representa para a locação pretendida.</span>
        </ReactTooltip> */}
      </ConceptTitle>
      <StyledRow margin="0 .5rem 30px">
        <BaseColumn xs={12} md={6} lg={6} xl={6}>
          <ConceptLine style={{color: '#000'}}><div style={{width: 15}}><Null color="#006400" /></div>&nbsp; Muito Baixo</ConceptLine>
          <ConceptLine style={{color: '#000'}}><div style={{width: 15}}><Null color="#37c120" /></div>&nbsp; Baixo</ConceptLine>
          <ConceptLine style={{color: '#000'}}><div style={{width: 15}}><Null color="#feda32" /></div>&nbsp; Médio</ConceptLine>
        </BaseColumn>
        <BaseColumn xs={12} md={6} lg={6} xl={6}>
          <ConceptLine style={{color: '#000'}}><div style={{width: 15}}><Null color="#ff7200" /></div>&nbsp; Acima da Média</ConceptLine>
          <ConceptLine style={{color: '#000'}}><div style={{width: 15}}><Null color="#fe4d36" /></div>&nbsp; Alto</ConceptLine>
        </BaseColumn>
      </StyledRow>
      {/* <ConceptLine>*As recomendações de mais flexibilidade ou flexibilidade são uma combinação entre o perfil de risco e a renda do pesquisado.</ConceptLine> */}
    </ContainerGray>
  )
}
