import React, { PureComponent } from 'react';

import {
  StepsContainer,
  StepContainer,
  LineStep,
  MobileStyledRow,
  RoundedCircle,
  StepText,
  TextContainer
} from './blueSteps.styled';

export default class BlueSteps extends PureComponent {
  static defaultProps = {
    steps: []
  }

  renderSteps = () => {
    const { steps } = this.props;
    if (Array.isArray(steps) && steps.length) {
      return steps.map((step, index) => (
        <StepContainer key={index} status={step.status} onClick={step.onClick}>
          <LineStep />
          <TextContainer>
            <RoundedCircle />
            <StepText data-testid={`stepText${index}`}>{ step.title }</StepText>
          </TextContainer>
        </StepContainer>
      ));
    }

    return null;
  }

  render() {
    if (!this.renderSteps()) { return null }

    return (
      <StepsContainer customstyle={this.props.customstyle}>
        <MobileStyledRow>
          { this.renderSteps() }
        </MobileStyledRow>
      </StepsContainer>
    );
  }
}
