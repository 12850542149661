
export const onChangeUserStatus = (state) => ({
  ...state,
  requesting: true,
  error: false
})

export const onChangeUserStatusSuccess = (state, payload) => ({
  ...state,
  requesting: false,
  error: false,
  data: payload.data
})

export const onChangeUserStatusFailed = (state) => ({
  ...state,
  requesting: false,
  error: true
})

export const onChangeUserType = (state) => ({
  ...state,
  requesting: true,
  error: false
})

export const onChangeUserTypeSuccess = (state, payload) => ({
  ...state,
  requesting: false,
  error: false,
  data: payload.data
});

export const onChangeUserTypeFailed = (state) => ({
  ...state,
  requesting: false,
  error: true
})

export const onResendMailConfirmation = (state) => ({
  ...state,
  requesting: true,
  error: false
})

export const onResendMailSuccess = (state, payload) => ({
  ...state,
  requesting: false,
  error: false,
  data: payload.data
});

export const onResendMailFailed = (state) => ({
  ...state,
  requesting: false,
  error: true
});


export const onGetUsers = (state) => ({
  ...state,
  requesting: true,
  error: undefined
});



export const onGetUsersSuccess = (state, payload) => ({
  ...state,
  requesting: false,
  error: false,
  data: payload.data,
  pagination: {
    ...state,
    currentPage: payload.pagination.current_page, 
    lastPage: payload.pagination.last_page, 
    resultsPerPage: payload.pagination.per_page
  }
});

export const onGetUsersFailed = (state) => ({
  ...state,
  requesting: false,
  error: true
});

export const onChangeSort = (state, payload) => ({
  ...state,
  pagination: {
    ...state.pagination,
    sort: payload
  }
});

export const onChangePage = (state, payload) => ({
  ...state,
  pagination: {
    ...state.pagination,
    currentPage: payload
  }
});

export const onChangeMaxResults = (state, payload) => ({
  ...state,
  pagination: {
    ...state.pagination,
    resultsPerPage: payload
  }
});
