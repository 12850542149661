import React, { Component } from 'react';
const TrashBlue = require('../../../img/png/ico-lixeira.png');

export default class BlueTrash extends Component {
  render() {
    return (
      <img src={TrashBlue} alt="" />
    )
  }
}
