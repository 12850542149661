import React from 'react';
import styled, { css } from 'styled-components';

const SVG = styled.svg`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  margin: ${props => props.margin || 'auto'};
  & > path { fill: ${props => props.theme[props.color] || props.theme.color_orange}; }
  ${props => css`${props.customStyle}`};
`;

export default (props) => (
  <SVG {...props} viewBox="0 0 512 512">
      <polygon fill="var(--ci-primary-color, currentColor)" points="376 160 376 192 441.372 192 252 381.373 180 309.373 76.686 412.686 99.314 435.314 180 354.627 252 426.627 464 214.628 464 280 496 280 496 160 376 160" class="ci-primary"/>
      <polygon fill="var(--ci-primary-color, currentColor)" points="48 104 16 104 16 496 496 496 496 464 48 464 48 104" class="ci-primary"/>
  </SVG>
);
