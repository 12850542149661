import React from 'react'
import { StyledRow as styledrow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import styled from 'styled-components'
import RenderFields from '../../../../components/shared/RenderFields'
import fields from '../fields'
import SecondLineFields from './SecondLineFields'
import { ButtonForm } from '../../../../components/shared/formStyles.styled';

const StyledRow = styled(styledrow)`
  @media (max-width: 800px) {
    & input { font-size: 12px !important; }

    & .field-fc {
      margin-bottom: 28px;

      & input, select {
        font-size: 13px !important;
        color: #7f7f7f;
      }
    }
    
    .secondLineFields {
      margin-top: 0;
    }

    .container-buttons {
      margin: 3vh 0 0 0;

      > div {
        justify-content: center;

        button:first-child {
          margin-right: 20px;
        }
      }
    }

    & div > label {
      font-size: .7rem !important;
    }

    & .field-fc-sm-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }

    & .field-fc-sm-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    & .field-fc-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    & .field-fc-sm-4 {
      flex: 0 0 38.5%;
      max-width: 38.5%;
    }

    & .field-fc-sm-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    & .field-fc-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    & .field-fc-sm-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    & .field-fc-sm-8 {
      flex: 0 0 61.5%;
      max-width: 61.5%;
    }

    & .field-fc-sm-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    & .field-fc-sm-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    & .field-fc-sm-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }

    & .field-fc-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

`

// Check if the submit button can appear
const renderSubmitButton = (editMode, typeUserEdit, loggedUser) => {
  if (editMode && typeUserEdit === "MASTER" && !loggedUser) {
    return true;
  }

  return true;
}

const UsersFormWrapper = (props) => (
  
  <StyledRow>
    <RenderFields
      fields={fields(props.editMode, props.isLoggedUser, props.typeUserEdit)}
    />
    <SecondLineFields
      editMode={props.editMode}
      loggedUser={props.isLoggedUser}
      typeUserEdit={props.typeUserEdit}
      typeUser={props.typeUser}
      changedValues={props.changedValues}
    />

    <StyledRow margin="5vh 0 0 0" align="center" className='container-buttons'>
      <BaseColumn xs={12} md={3} lg={3} xl={3} customstyle="display: flex;justify-content: space-around;">
        {window.innerWidth <= 800 ? // caso seja menor, mostrar o botão com a chamada do modal informativo de cancelamento que tem somente na versão mobile
          <ButtonForm onClick={props.editMode ? props.onGoBack : props.formValues === null ? props.onGoBack : props.handleCancelModal}>Voltar</ButtonForm> :
          <ButtonForm onClick={props.onGoBack}>Voltar</ButtonForm>
        }
        {renderSubmitButton(props.editMode, props.typeUserEdit, props.isLoggedUser) &&
          <ButtonForm disabled={props.submitDisabled || props.editMode && props.formValues === null} onClick={props.onSubmit}>{props.editMode? "Salvar Alterações" : "Salvar"}</ButtonForm>
        }
      </BaseColumn>
    </StyledRow>
  </StyledRow>
)

export default UsersFormWrapper;
