import { simpleRequiredText, simpleDisabledText, simpleNonRequiredText } from '@bit/wavfichacerta.fichacerta.fields-templates.simple-text';
import { simpleRequiredSelect } from '@bit/wavfichacerta.fichacerta.fields-templates.select';
import { defaultCEP } from '@bit/wavfichacerta.fichacerta.fields-templates.cep';
import { defaultPhoneWithoutDDI } from '@bit/wavfichacerta.fichacerta.fields-templates.phone';

const sizes = {
  pronounsName: "field-fc col-xs-6 col-sm-6 col-md-1 col-lg-1 col-xl-1",
  name: "field-fc field-fc-l",
  profile: "field-fc field-fc-sm",
  common: "field-fc col",
  cpf: 'field-fc field-fc-xs',
  doc: 'field-fc field-fc-xs',
  issuingAgency: 'field-fc field-fc-xs',
  creci: "field-fc col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3",
  fullColumn: 'field-fc col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12',
  address: "field-fc col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3",
  website: "field-fc col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
  phone: "field-fc col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
  contactsPhone: "field-fc field-fc-xs"
}

export const identificationFields = (master) => {
  if(master){
    return([
      {
        name: 'firstLine',
        fields: [
          simpleRequiredSelect({ name: 'tratamentos', label: 'Nome', size: sizes.pronounsName }),
          simpleRequiredText({ size: sizes.name, name: 'nome', label: '' }),
          simpleRequiredSelect({ name: 'tipo', label: 'Perfil', size: sizes.profile }),
          simpleDisabledText({ size: sizes.cpf, name: 'cpf', label: 'CPF' }),
          simpleRequiredText({ size: sizes.doc, name: "identidade", label: 'Identidade' }),
          simpleRequiredText({ size: sizes.issuingAgency, name: "orgao_expedidor", label: 'Órgão expedidor' }),
        ]
      },
      {
        name: 'secondLine',
        fields: [
          simpleNonRequiredText({ size: sizes.creci, name: "creci", label: 'CRECI' })
        ]
      }
    ])
  }else{
    return([
      {
        name: 'firstLine',
        fields: [
          simpleDisabledText({ name: 'tratamentos', label: 'Nome', size: sizes.pronounsName }),
          simpleDisabledText({ size: sizes.name, name: 'nome', label: '' }),
          simpleDisabledText({ name: 'tipo', label: 'Perfil', size: sizes.profile }),
          simpleDisabledText({ size: sizes.creci, name: "creci", label: 'CRECI' })
        ]
      }
    ])
  }
};

export const addressFields = (master) => {
  if(master){
    return([
      {
        name: 'firstLine',
        fields: [
          defaultCEP(sizes.common, "", "CEP"),
          simpleRequiredText({ size: sizes.address, name: 'logradouro', label: 'Endereço' }),
          simpleRequiredText({ size: sizes.common, name: 'numero', label: 'Número' }),
          simpleNonRequiredText({ size: sizes.common, name: 'complemento', label: 'Complemento' }),
          simpleRequiredText({ size: sizes.common, name: 'bairro', label: 'Bairro' }),
          simpleRequiredText({ size: sizes.common, name: 'cidade', label: 'Cidade' }),
          simpleRequiredSelect({ size: sizes.common, name: 'uf', label: 'Estado' })
        ]
      },
      {
        name: 'secondLine',
        fields: [
          simpleNonRequiredText({ size: sizes.website, name: 'website', label: 'Website' }),
          defaultPhoneWithoutDDI("telefone", "Telefone", sizes.contactsPhone),
          defaultPhoneWithoutDDI("telefone_adicional", "Telefone adicional", sizes.contactsPhone, false),
        ]
      }
    ]);
  }else{
    return([
      {
        name: 'firstLine',
        fields: [
          simpleDisabledText(sizes.common, "", "CEP"),
          simpleDisabledText({ size: sizes.address, name: 'logradouro', label: 'Endereço' }),
          simpleDisabledText({ size: sizes.common, name: 'numero', label: 'Número' }),
          simpleDisabledText({ size: sizes.common, name: 'complemento', label: 'Complemento' }),
          simpleDisabledText({ size: sizes.common, name: 'bairro', label: 'Bairro' }),
          simpleDisabledText({ size: sizes.common, name: 'cidade', label: 'Cidade' }),
          simpleDisabledText({ size: sizes.common, name: 'uf', label: 'Estado' })
        ]
      },
      {
        name: 'secondLine',
        fields: [
          simpleDisabledText({ size: sizes.website, name: 'website', label: 'Website' }),
          simpleDisabledText("telefone", "Telefone", sizes.contactsPhone),
          simpleDisabledText("telefone_adicional", "Telefone adicional", sizes.contactsPhone, false),
        ]
      }
    ]);
  }
}

export const aditionalContactsFirstFields = (master) => { 
  if(master){
    return([
      {
        name: 'firstLine',
        fields: [
          simpleNonRequiredText({ size: sizes.address, name: 'operacional_nome', label: 'Nome' }),
          simpleNonRequiredText({ size: sizes.address, name: 'operacional_email', label: 'E-mail' }),
          simpleNonRequiredText({ size: sizes.address, name: 'operacional_funcao', label: 'Função' }),
          defaultPhoneWithoutDDI('operacional_telefone', 'Telefone', sizes.phone, false)
        ]
      }
    ])
  }else{
    return([
      {
        name: 'firstLine',
        fields: [
          simpleDisabledText({ size: sizes.address, name: 'operacional_nome', label: 'Nome' }),
          simpleDisabledText({ size: sizes.address, name: 'operacional_email', label: 'E-mail' }),
          simpleDisabledText({ size: sizes.address, name: 'operacional_funcao', label: 'Função' }),
          simpleDisabledText('operacional_telefone', 'Telefone', sizes.phone, false)
        ]
      }
    ])
  }
}

export const aditionalContactsSecondFields = (master) => {
  if(master){
    return([
      {
        name: 'secondLine',
        fields: [
          simpleNonRequiredText({ size: sizes.address, name: 'financeiro_nome', label: 'Nome' }),
          simpleNonRequiredText({ size: sizes.address, name: 'financeiro_email', label: 'E-mail' }),
          simpleNonRequiredText({ size: sizes.address, name: 'financeiro_funcao', label: 'Função' }),
          defaultPhoneWithoutDDI('financeiro_telefone', 'Telefone', sizes.phone, false)
        ]
      }
    ])
  }else{
    return([
      {
        name: 'secondLine',
        fields: [
          simpleDisabledText({ size: sizes.address, name: 'financeiro_nome', label: 'Nome' }),
          simpleDisabledText({ size: sizes.address, name: 'financeiro_email', label: 'E-mail' }),
          simpleDisabledText({ size: sizes.address, name: 'financeiro_funcao', label: 'Função' }),
          simpleDisabledText('financeiro_telefone', 'Telefone', sizes.phone, false)
        ]
      }
    ])
  }
}