import * as types from './types';
import { toastr } from 'react-redux-toastr';
import { actionCreator, generalActionTypes, extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { getCharge, getChargePrint, getChargePrintExcel } from '../../../../services/charge/charge';
import { replace } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../../../../pages/auth/login/actions/types.js';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const changePage = (page) => actionCreator(types.CHANGE_PAGE, page);
export const changePerPage = (perPage) => actionCreator(types.CHANGE_PER_PAGE, perPage);
export const changeFilters = (filters) => actionCreator(types.CHANGE_FILTERS, filters);
export const resetFilters = () => actionCreator(types.RESET_FILTERS)

export const getChargeData = () => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.CHARGE_DATA_REQUEST));
    try {
      const { login: { user: { token } }, chargeData: {pagination, filters} } = getState();
      const payload = await getCharge(token.access_token, {...filters, ...pagination});
      if(payload.status !== 200) {
        if(payload.status === 401){
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        }else{
          toastr.warning('Error', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.CHARGE_DATA_FAILED));
        }
      }else if(typeof payload.data === "string"){
        toastr.error(payload.data);
        dispatch(actionCreator(types.EMPTY_CHARGE_DATA))
      }else{
        dispatch(actionCreator(types.CHARGE_DATA_SUCCESS, payload.data.data))
      }
    } catch (error) {
      dispatch(actionCreator(NON_HANDLED_ERROR))
    }
  }
}

export const getChargeDataPrint = (id) => {
  return async (dispatch, getState) => {
    try {
      toastr.info("Aguarde alguns instantes, estamos gerando o arquivo de impressão.", {timeOut: 0, extendedTimeOut: 0})

      const { login: { user: { token } }, chargeData: {pagination, filters} } = getState();
      const payload = await getChargePrint(token.access_token, {...filters, ...pagination, id});

      if(payload.status !== 200) {
        if(payload.status === 401){
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        }else{
          dispatch(actionCreator(types.CHARGE_DATA_FAILED));
          toastr.removeByType('info')
          toastr.error(payload.data)
        }
      }else if(typeof payload.data === "string"){
        window.open(payload.data, '_blank')
        toastr.removeByType('info')
      }
    } catch (error) {
      toastr.error(error.message)
      dispatch(actionCreator(NON_HANDLED_ERROR))
    }
  }
}

export const getChargeDataPrintExcel = (id) => {
  return async (dispatch, getState) => {
    try {
      toastr.info("Aguarde alguns instantes, estamos gerando o arquivo para download.", {timeOut: 0, extendedTimeOut: 0})

      const { login: { user: { token } }, chargeData: {pagination, filters} } = getState();
      const payload = await getChargePrintExcel(token.access_token, {...filters, ...pagination, id});

      if(payload.status !== 200) {
        if(payload.status === 401){
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        }else{
          dispatch(actionCreator(types.CHARGE_DATA_FAILED));
        }
      }else if(payload.status === 200) {
        toastr.removeByType('info')
        const url = window.URL.createObjectURL(new Blob([payload.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'relatorio.xls'); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      toastr.error(error.message)
      dispatch(actionCreator(NON_HANDLED_ERROR))
    }
  }
}