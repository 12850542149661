import React from 'react';
import 'react-select/dist/react-select.css';
import { ResulstSelect } from './footer.styles';

const SelectInput = ({ onChange, value }) => (
  <ResulstSelect onChange={onChange} value={value}>
    <option>10</option>
    <option>15</option>
    <option>20</option>
    <option>25</option>
  </ResulstSelect>
)

SelectInput.defaultProps = {
  isRequired: false,
  placeholder: ''
}

export default SelectInput;
