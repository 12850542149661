import React, { PureComponent } from 'react';

import { Col as col } from 'reactstrap';
import styled from 'styled-components'
import {
  CardboardResume,
  HeaderCardboardResume,
  LabelHeaderResume,
  IconHeaderLoading,
  BodyContainer,
  TitleProduct,
  FooterContainer,
  PriceResume,
  DescriptionParagraph,
  IconFooter,
  IconHeader
} from './productCardboard.styles';
import CustomTrash from '@bit/wavfichacerta.fichacerta.icons.custom-trash';
import LoadingBlueOrange from '../shared/icons/LoadingBlueOrange';
import icons from '../shared/treatedIcons';
import Add from '../shared/icons/Add';


const Col = styled(col)`
  ${({customstyle}) => customstyle}
`

const Svg = styled.svg`
  @media (max-width: 800px) {
    margin-top: -9px !important;
    margin-right: 5px !important;
    margin-left: 74px !important;
  }
`

const labelStatus = [
  { key: "INCLUIDO", name: "Incluído" },
  { key: "EDITADO", name: "Editado" },
  { key: "SOLICITADO", name: "Solicitado" },
  { key: "ANDAMENTO", name: "Em andamento" },
  { key: "PENDENTE", name: "Pendente" },
  { key: "CONCLUIDO", name: "Concluído" },
  { key: "REINCLUIDO", name: "Reincluída" },
  { key: "INCLUIR", name: "Incluir" }
]

class ProductResume extends PureComponent {
  state = {
    mouseEnter: false
  }

  static defaultProps = {
    active: false,
    included: false,
    title: '',
    isTrashVisible: true,
    onSelect: () => { },
    statusProduct: '',
    price: '',
    description: ''
  }

  renderHeader = () => {
    const { statusProduct } = this.props;
    if (statusProduct === "ANDAMENTO") {
      return (<IconHeaderLoading><LoadingBlueOrange /></IconHeaderLoading>);
    }else if (this.state.mouseEnter){
      return <TitleProduct style={{margin: 0}} >{this.renderStatusName()}</TitleProduct>
    }
    return null;
  }

  renderTrash = () => {
    const { statusProduct, id } = this.props;
    if (statusProduct === "INCLUIDO" || statusProduct === "INCLUIDA" || statusProduct === "REINCLUIDA" || statusProduct === "REINCLUIDO") {
      return (
        <IconFooter style={{cursor: 'pointer'}}>
          <CustomTrash width="1rem" height=".9rem" color={id === 1 && 'color_orange' || id === 5 && 'color_purple_2' || (id === 7 || id === 8) && 'color_brown' || 'color_purple'} onClick={this.props.oculto ? null : this.props.onClickTrash} style={{fill: this.getColorsProduct(id), cursor: this.props.oculto ? 'no-drop' : 'pointer'}} />
        </IconFooter>
      );
    }

    return null;
  }

  renderStatusName = () => {
    const { statusProduct } = this.props;
    const statusFound = labelStatus.find((status) => status.key === statusProduct);
    if (statusFound !== undefined) {
      return statusFound.name;
    }
    return statusProduct;
  }

  selectReinclusion = () => {
    const { statusProduct, onSelect, addFcRenda, title, oculto, consultado_hoje } = this.props;
    if(oculto) {
      return
    }
    if(consultado_hoje) {
      this.props.toggleDontTodayReinclusionModal()
      return;
    }
    if(statusProduct === "EDITADO" || statusProduct === "CONCLUIDO" ){
      onSelect();
    }
    if(statusProduct === "INCLUIR"){
      addFcRenda();
    }
  }

  renderIconCart = () => {
    const { statusProduct, id } = this.props;
    if(statusProduct != 'INCLUIDO') return null

    return(
      <Svg style={{marginTop: -4, marginRight: 5}} fill="none" height="16" viewBox="0 0 30 27" width="30" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.39999 1.70001H6.60001" stroke={this.getColorsProduct(id)} stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        <path d="M6.60001 1.70001L11 18.9" stroke={this.getColorsProduct(id)} stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        <path d="M11.8 18.9H28.3" stroke={this.getColorsProduct(id)} stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        <path d="M13.8 25.7C15.4569 25.7 16.8 24.3569 16.8 22.7C16.8 21.0432 15.4569 19.7 13.8 19.7C12.1431 19.7 10.8 21.0432 10.8 22.7C10.8 24.3569 12.1431 25.7 13.8 25.7Z" stroke={this.getColorsProduct(id)} stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        <path d="M25.3 25.7C26.9568 25.7 28.3 24.3569 28.3 22.7C28.3 21.0432 26.9568 19.7 25.3 19.7C23.6431 19.7 22.3 21.0432 22.3 22.7C22.3 24.3569 23.6431 25.7 25.3 25.7Z" stroke={this.getColorsProduct(id)} stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        <path d="M25.7 14.6H11.3C10.7 14.6 10.1 14.2 10 13.6L8.1 6.90001C7.9 6.00001 8.49999 5.20001 9.39999 5.20001H27.5C28.4 5.20001 29.1 6.10001 28.8 6.90001L26.9 13.6C26.9 14.2 26.4 14.6 25.7 14.6Z" stroke={this.getColorsProduct(id)} stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
      </Svg>
    )
  }

  getColorsProduct = (id) => {
    switch(id) {
      case 1:
        return '#ff7200'
      case 5:
        return '#660099'
      case 7:
      case 8:
        return '#795548'
      default:
        return '#152b4e'
    }
  }

  render() {
    const Icon = icons[this.props.icon];
    const statusProduct = this.props.statusProduct;
    const alignFooter = (statusProduct !== "INCLUIDO" && statusProduct !== "INCLUIDA" && statusProduct !== "REINCLUIDA" && statusProduct !== "REINCLUIDO") ? 'flex-end' : 'space-between';
    const opacityOn = (statusProduct !== "INCLUIDO" && statusProduct !== "INCLUIDA");
    const productId = this.props.id

    return (
      <Col xs="auto" sm="auto" md="auto" lg="auto" xl="auto" customstyle='@media (max-width: 800px) {padding: 0;margin-right: 10px;}'>
        <CardboardResume productId={productId} id={productId} title={this.props.title} editCount={this.props.editCount} data-testid="CardboardContainer" included={this.props.included} onClick={this.selectReinclusion} status={statusProduct} price={this.props.price} statusFcRenda={this.props.statusFcRenda} style={{cursor: this.props.oculto ? 'no-drop' : null}} oculto={this.props.oculto}>
            <HeaderCardboardResume>
              <LabelHeaderResume status={statusProduct} included={this.props.included} style={{color: productId === 1 && statusProduct == 'INCLUIR' && '#ff7200', backgroundColor: productId === 1 && statusProduct == 'INCLUIDO' && '#ff7200' || productId === 5 && statusProduct == 'INCLUIDO' && '#660099' || (productId === 7 || productId === 8) && statusProduct == 'INCLUIDO' && '#795548'}}>
                {this.renderStatusName()}
              </LabelHeaderResume>
              { this.renderHeader() }
              {this.renderIconCart()}
            </HeaderCardboardResume>

            <BodyContainer data-testid="BodyContainer" opacityOn={opacityOn}>
              <span style={{position: 'relative'}}>
                <Icon color="color_purple" width="48px" className="icon-resume" style={{fill: this.getColorsProduct(productId)}} customStyle='& path, line { stroke: #660099; }' />
                {(productId === 1 || productId === 7) &&
                  <IconHeader style={{position: 'absolute', top: -5, left: 11.5, fontSize: 32,fontWeight: 300, color: productId === 7 ? '#795548' : '#ff7200'}}>
                    +
                  </IconHeader>
                }
              </span>
              <TitleProduct included={this.props.included} style={{color: this.getColorsProduct(productId)}}>{this.props.title}</TitleProduct>
            </BodyContainer>

            <FooterContainer align={alignFooter} opacityOn={opacityOn}>
              { this.renderTrash() }
              {/* { this.props.price != 'xx.xx' &&
                <PriceResume included={this.props.included} style={{color: this.getColorsProduct(productId)}}>
                  R$
                  {
                    window.innerWidth <= 800 ?
                      this.props.price.toString().split('.')[0]
                      : this.props.price
                  }
                </PriceResume>
              } */}
            </FooterContainer>

            <DescriptionParagraph>
              <TitleProduct >{(statusProduct === "CONCLUIDO" && this.props.editCount === 0) || (statusProduct === "CONCLUIDO" && (productId === 1 || productId === 3 || productId === 4 || productId === 7 || productId === 8)) ? "RECONSULTAR" : statusProduct}</TitleProduct>
              <br/>
              {this.props.description}
            </DescriptionParagraph>
        </CardboardResume>
      </Col>
    );
  }
}

export default ProductResume;
