import styled, { css } from 'styled-components';
import { Col } from 'reactstrap';
import Card from '@bit/wavfichacerta.fichacerta.cards.card';

export const SyntethicColumn = styled.div`
  width: ${props => props.width || '100%'};
  max-width: 100%;
  display: flex;
  flex-direction: ${props => props.direction || "row"};

  @media(max-width: 800px){
    width: 100%;
    margin: 0 0 8px 0;
    flex-direction: ${props => props.direction || "initial"};
  }
  
  ${props => css`${props.customstyle}`};
`;

export const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  ${props => {
    const { getMedias: { iphones } } = props.theme;
    return `
      ${iphones()`
        flex-wrap: wrap;
        `
      }
    `;
  }}
`;

export const CardContainer = styled(Card)`
  width: 99%;
  margin: auto auto 3rem auto;
  ${props => css`${props.cardStyle}`};

  @media(max-width: 800px){
    width: 100%;
    box-shadow: none;
    padding: 0;
    margin: 0;
    min-height: 0;
  }
`;

export const ContentBox = styled.div`
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
  padding: 1rem;
  background-color: ${props => props.theme['color_light-gray']};
  display: flex;
  flex-direction: ${props => props.direction || 'column'};
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'unset'};

  @media(max-width: 800px){
    width: ${props => props.width || "50%"};
  }
`;

export const TitleContainer = styled.h5`
  font-size: 17px;
  font-weight: 400;
  text-align: ${props => props.align? props.align : 'left'};
  color: ${props => props.theme.color_purple};
  margin-top: ${props => props.marginTop? props.marginTop : '.5rem'};
  margin-bottom: ${props => props.marginBottom? props.marginBottom : '.5rem'};
`;

export const SubtitleContainer = styled.p`
  font-size: 11px;
  color: ${props => props.theme.color_red};
  text-align: center;
`;

export const Arrow = styled.div`
  width: 0;
  height: ${props => `${props.heightArrow}px`};
  border-top: calc(${props => `${props.heightArrow}px`}/2) solid transparent;
  border-bottom: calc(${props => `${props.heightArrow}px`}/2) solid transparent;
  border-left: calc(${props => `${props.heightArrow}px`}/5) solid ${props => `${props.color ? props.color : '#e0e0e0'}`};
  align-self: center;

  @media(max-width: 800px){
    display:none;
  }
`;

export const ComplementCol = styled(Col)`
  ${props => {
    const { getMedias: { iphones } } = props.theme;
    return `
      ${iphones()`
        padding: 0;
        margin: .5rem 0;
        `
      }
    `;
  }}
`;
