import {
  EDIT_PRODUCTS_ROW,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILED,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  RESET_REDUX_PRODUCTS
} from './types';
import { LOGOUT_SUCCESS } from '../../../../../pages/auth/login/actions/types';
import { replace } from 'connected-react-router';
import {
  addProduct,
  removeProduct,
  updateProduct,
} from '../../../../../services/customer/products';
import { getClientInfos } from '../../../../../services/customer/customer'
import { actionCreator, generalActionTypes } from '../../../../../utils/reduxUtils';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const editProductRow = (id) => actionCreator(EDIT_PRODUCTS_ROW, id);

export const resetReduxProducts = () => actionCreator(RESET_REDUX_PRODUCTS);


export const getClientProducts = () => {
  return async (dispatch, getState) => {
    const { login: { user: { token } }, client: { client: { infos } } } = getState();

    try {
      dispatch(actionCreator(GET_PRODUCTS_REQUEST));
      const payload = await getClientInfos(token.access_token, infos.id);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(GET_PRODUCTS_FAILED, payload.data.message));
        }

      } else {
        dispatch(actionCreator(GET_PRODUCTS_SUCCESS, payload.data.data.cliente.produtos));
      }
    } catch (e) {
      dispatch(actionCreator(GET_PRODUCTS_FAILED, ''));
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};

export const addClientProduct = (product) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } }, client: { client: { infos } } } = getState();

    try {
      const payload = await addProduct(token.access_token, infos.id, { id: product.value });
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
        }

      } else {
        dispatch(actionCreator(ADD_PRODUCT, product));
        dispatch(actionCreator(getClientProducts()));
      }
    } catch (e) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};

export const removeClientProduct = (productId) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } }, client: { client: { infos } } } = getState();

    try {
      const payload = await removeProduct(token.access_token, infos.id, productId);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
        }

      } else {
        dispatch(actionCreator(DELETE_PRODUCT, productId));
        dispatch(actionCreator(getClientProducts()));
      }
    } catch (e) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};

export const updateClientProduct = (productId, discount, value) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } }, client: { client: { infos } } } = getState();

    try {
      const payload = await updateProduct(token.access_token, infos.id, productId, discount, value);

      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
        }
      } else {
        dispatch(actionCreator(UPDATE_PRODUCT, productId));
      }
    } catch (e) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};




