import {
  OPEN_EMPLOYEE_MODAL,
  CLOSE_EMPLOYEE_MODAL,
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAILED,
  SELECT_EMPLOYEE_CREDIT,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILED,
  REGISTER_EMPLOYEE_FAILED,
  EDIT_EMPLOYEE_FAILED,
  OPEN_TYPE_EMPLOYEE_MODAL,
  CLOSE_TYPE_EMPLOYEE_MODAL,
  OPEN_STATUS_EMPLOYEE_MODAL,
  CLOSE_STATUS_EMPLOYEE_MODAL,
  OPEN_EMPLOYEE_CHANGE_MODAL,
  CLOSE_EMPLOYEE_CHANGE_MODAL,
  ON_UPDATE_EMPLOYEE_CREDIT,
  CHANGE_PAGE,
  CHANGE_RESULTS_PER_PAGE,
  RESET_PAGINATION
} from './types';
import { LOGOUT_SUCCESS } from '../../../../../pages/auth/login/actions/types';
import { replace } from 'connected-react-router';
import {
  getEmployee,
  registerEmployee,
  getEmployees,
  updateEmployee,
  updateEmployeeCredit,
  updateEmployeeType,
  updateEmployeeStatus
} from '../../../../../services/customer/employees';
import { actionCreator, generalActionTypes, extractErrorMessages } from '../../../../../utils/reduxUtils';
import { closeStatusModal } from '../../../../../components/shared/status-modal/actions/actions';
import { handleEmployeeDetail } from '../../../../../utils/clientsTableHelper';
import { createUserObj } from '../../../../../utils/formHelper';
import { toastr } from 'react-redux-toastr';
import { omit } from 'lodash';
import { getCustomerData } from "./../../../../customer/CustomerData/actions";
import {
  getUsers
} from "./../../../../users/actions";


const { NON_HANDLED_ERROR } = generalActionTypes;

export const openEmployeeModal = (mode, employee, mensagem) => actionCreator(OPEN_EMPLOYEE_MODAL, { mode, employee, mensagem });
export const closeEmployeeModal = () => actionCreator(CLOSE_EMPLOYEE_MODAL);
export const selectEmployeeCredit = id => actionCreator(SELECT_EMPLOYEE_CREDIT, id);

export const openTypeEmployeeModal = (employee) => actionCreator(OPEN_TYPE_EMPLOYEE_MODAL, employee);
export const closeTypeEmployeeModal = () => actionCreator(CLOSE_TYPE_EMPLOYEE_MODAL);

export const openStausEmployeeModal = (employee) => actionCreator(OPEN_STATUS_EMPLOYEE_MODAL, employee);
export const closeStausEmployeeModal = () => actionCreator(CLOSE_STATUS_EMPLOYEE_MODAL);

const openEmployeeEmailChangeModal = (email, mensagem) => actionCreator(OPEN_EMPLOYEE_CHANGE_MODAL,{ email, mensagem});
export const closeEmployeeEmailChangeModal = () => actionCreator(CLOSE_EMPLOYEE_CHANGE_MODAL);

export const onUpdateEmployeeCredit = (id, credit) => actionCreator(ON_UPDATE_EMPLOYEE_CREDIT, { id, credit });

export const changePage = page => actionCreator(CHANGE_PAGE, page);
export const changeResultsPerPage = num => actionCreator(CHANGE_RESULTS_PER_PAGE, num);
export const resetPagination = () => actionCreator(RESET_PAGINATION);

export const createEmployee = () => {
  return async (dispatch, getState) => {
    const { login: { user: { token } }, client: { client: { infos } }, form } = getState();
    const formData = form.employeeForm.values;
    const data = createUserObj(
      {
        ...formData,
        pessoa: 'FISICA'
      }
    );

    try {
      const payload = await registerEmployee(
        token.access_token,
        infos.id,
        {
          ...data,
          complemento: {
            tipo: formData.tipo_usuario,
            // financeiro: formData.tipos_acesso.indexOf('FINANCEIRO') !== -1,
            // operacional: formData.tipos_acesso.indexOf('OPERACIONAL') !== -1,
            ficha_rapida: formData.ficha_rapida
          }
        }
      );
      
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(REGISTER_EMPLOYEE_FAILED, payload.data.message));
          toastr.error('Criar Usuário', extractErrorMessages(payload.data.message));
        }

      } else {
        //dispatch(getClientEmployees());
        setTimeout(() => {
          dispatch(closeEmployeeModal());
          toastr.success('Criar', 'Funcionario criado com sucesso');
        }, 300);
      }
    } catch (e) {
      dispatch(actionCreator(REGISTER_EMPLOYEE_FAILED, ''));
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};

export const editEmployeeStatus = (id) => {
  return async (dispatch, getState) => {
    const {
      login: { user: { token } },
      client: { client: { infos } },
      form: { stateModalForm: { values: { selectStatus, statusReason } } }
    } = getState();

    try {
      const payload = await updateEmployeeStatus(token.access_token, infos.id, id, selectStatus, statusReason);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.error('Editar STATUS', extractErrorMessages(payload.data.message));
          dispatch(closeStatusModal());
        }

      } else {
        dispatch(closeStatusModal());
        toastr.success('Editar', 'Funcionario editado com sucesso');
        setTimeout(() => {
          //dispatch(getClientEmployees())
        }, 200);
      }
    } catch (e) {
      toastr.error('Editar STATUS', 'Não foi possivel executar a ação');
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};

export const getEmployeeDetail = (employeeId) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } }, client: { client: { infos } } } = getState();

    try {
      dispatch(actionCreator(GET_EMPLOYEE_REQUEST));
      const payload = await getEmployee(token.access_token, infos.id, employeeId);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(GET_EMPLOYEE_FAILED, payload.data.message));
          toastr.error('Usuário', extractErrorMessages(payload.data.message));
        }
      } else {
        dispatch(actionCreator(GET_EMPLOYEE_SUCCESS, handleEmployeeDetail(payload.data.data)));
      }
    } catch (e) {
      dispatch(actionCreator(GET_EMPLOYEE_FAILED, e.message));
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};

export const editEmployee = () => {
  return async (dispatch, getState) => {
    const {
      login: { user: { token } },
      client: { client: { infos } },
      form,
      validation: {
        dirtyForms: {
          employeeForm
        }
      },
      clientsTable: {
        employees: {
          employeeModal: {
            selectedEmployee
          }
        }
      }
    } = getState();
    const formData = form.employeeForm.values;
    const data = createUserObj(
      {
        ...formData,
        pessoa: 'FISICA'
      }
    );

    try {
      const payload = await updateEmployee(
        token.access_token,
        infos.id,
        selectedEmployee.funcionario.id,
        {
          ...omit(data, ['cpf']),
          complemento: {
            tipo: formData.tipo_usuario,
            financeiro: formData.tipos_acesso.indexOf('FINANCEIRO') !== -1,
            operacional: formData.tipos_acesso.indexOf('OPERACIONAL') !== -1,
            ficha_rapida: formData.ficha_rapida
          }
        }

      );
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(EDIT_EMPLOYEE_FAILED, payload.data.message));
          toastr.error('Editar', extractErrorMessages(payload.data.message));
        }
      } else {
        //dispatch(getClientEmployees());
        setTimeout(() => {
          dispatch(closeEmployeeModal());

          toastr.success('Editar', 'Funcionario editado com sucesso');
        }, 300);

        if (employeeForm.indexOf('email') !== -1) {
          setTimeout(() => dispatch(openEmployeeEmailChangeModal(formData.email)), 500);
        }
      }
    } catch (e) {
      dispatch(actionCreator(EDIT_EMPLOYEE_FAILED, ''));
      toastr.error('Editar', 'Algo inesperado aconteceu');
    }
  }
};

export const editEmployeeCredit = (id, credit) => {
  return async (dispatch, getState) => {
    const {
      login: { user: { token } },
      customerData: { data: { cliente } },
    } = getState();

    try {
      const payload = await updateEmployeeCredit(token.access_token, cliente.id, id, credit);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.error('Editar', extractErrorMessages(payload.data.message));
          //dispatch(getClientEmployees());
        }

      } else {
        //dispatch(getClientEmployees());
        toastr.success('Editar CREDITO', 'Credito editado com sucesso');
        dispatch(getCustomerData());
        dispatch(getUsers());
      }
    } catch (e) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};


export const getClientEmployees = (status = null) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } }, client: { client: { infos } }, clientsTable: { employees: { pagination } } } = getState();

    try {
      dispatch(actionCreator(GET_EMPLOYEES_REQUEST));
      const payload = await getEmployees(token.access_token, infos.id, pagination, status);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(GET_EMPLOYEES_FAILED, payload.data.message));
          toastr.error('Lista de Usuários', extractErrorMessages(payload.data.message));
        }
      } else {
        dispatch(actionCreator(GET_EMPLOYEES_SUCCESS, payload.data));
      }
    } catch (e) {
      dispatch(actionCreator(GET_EMPLOYEES_FAILED, e.message));
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};

export const editEmployeeType = () => {
  return async (dispatch, getState) => {
    const {
      login: { user: { token } },
      client: { client: { infos } },
      form: { employeeTypeForm },
      clientsTable: { employees: { typeModal: { selectedEmployee } } }
    } = getState();

    try {
      const payload = await updateEmployeeType(token.access_token, infos.id, selectedEmployee.id, employeeTypeForm.values.tipo);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.error('Editar TIPO', payload.data.data.message);
          dispatch(closeTypeEmployeeModal());
        }

      } else {
        dispatch(closeTypeEmployeeModal());
        toastr.success('Editar TIPO', 'Funcionario editado com sucesso');
        setTimeout(() => {
          //dispatch(getClientEmployees());
        }, 200);
      }
    } catch (e) {
      toastr.error('Editar TIPO', 'Não foi possivel executar a ação');
      dispatch(actionCreator(NON_HANDLED_ERROR, e.message));
    }
  }
};
