import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeModal, deleteProduct } from './actions';

import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import {
  Container,
  Title,
  Description,
  ContainerButtons,
  CancelButton,
  ConfirmButton
} from './modalDeleteLastProduct.styled';

class ModalDeleteProduct extends PureComponent {

  static defaultProps = {
    confirmDelete: () => {}
  }

  confirmDelete = () => {
    const { deleteProduct, resume: {id}, applicant, product, lastApplicant} = this.props
    deleteProduct(id, applicant, product, lastApplicant)
  }

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        onClose={this.props.closeModal}
      >
        <Container>
          <Title>EXCLUIR {this.props.product === 1 ? 'FC REPORT' : this.props.product === 3 ? 'FC ESSENCIAL' : 'FC DOC'}</Title>
          {this.props.lastApplicant ? 
            <Description>
              Este é o único produto solicitado. Tem certeza que deseja exclui-lo? Todos os dados inseridos desse pretendente serão perdidos.
            </Description> 
            : 
            <Description>
              Tem certeza que deseja excluir este produto?
            </Description>
          }
          <ContainerButtons>
            <CancelButton onClick={this.props.closeModal}>Não</CancelButton>
            <ConfirmButton onClick={this.confirmDelete}>Sim</ConfirmButton>
          </ContainerButtons>
        </Container>
      </ModalContainer>
    );
  }
}

export const mapStateToProps = (state) => ({
  isOpen: state.modalDeleteLastProduct.isOpen,
  applicant: state.modalDeleteLastProduct.applicantId,
  resume: state.requestResume.resume.infos,
  lastApplicant: state.modalDeleteLastProduct.lastApplicant,
  product: state.modalDeleteLastProduct.idProduct
});

export const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeModal,
  deleteProduct
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteProduct);
