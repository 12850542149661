
export const CUSTOMER_DATA_REQUEST = "customerDataForm/CUSTOMER_DATA_REQUEST";
export const CUSTOMER_DATA_SUCCESS = "customerDataForm/CUSTOMER_DATA_SUCCESS";
export const CUSTOMER_DATA_FAILED = "customerDataForm/CUSTOMER_DATA_FAILED";

export const CUSTOMER_SAVE_REQUEST = "customerDataForm/CUSTOMER_SAVE_REQUEST";
export const CUSTOMER_SAVE_SUCCESS = "customerDataForm/CUSTOMER_SAVE_SUCCESS";
export const CUSTOMER_SAVE_FAILED = "customerDataForm/CUSTOMER_SAVE_FAILED";

export const RESET_DATA = "customerData/RESET_DATA";
