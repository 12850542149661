import React from 'react'
import { SubmitButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { InputColContainer } from '@bit/wavfichacerta.fichacerta.inputs-styles.inputs-styles';
import Loading from '@bit/wavfichacerta.fichacerta.icons.loading';
import ModalExcluedFile from './ModalExcludedFile'
import ModalRecommendedDocs from './ModalRecommendedDocs'
import { Select } from './../../../../../components/shared/selectInput.styles';
import RoundedMoney from '../../../../../components/shared/treatedIcons/RoundedMoney';
import styled from 'styled-components'

const WrapperContainerAnexos = styled.div`
	display: flex;
	justify-content: space-between;

	.wrapper-box-file {
		width: 49%;
		position: relative;
	}

	@media (max-width: 800px) {
		display: block;

		.wrapper-box-file {
			width: 100%;

			&:last-child:not(:first-child) {
				margin-top: 20px;
			}
		}
	}
`

class Card extends React.Component {
	state = {
		files: [],
		documentSelected: null,
		filesChanged: []
	}

	inputRef = React.createRef()

	componentDidUpdate(prevProps, prevState) {
		// console.log("prevProps", prevProps)
		// console.log("props3", this.props)
		// console.log("prevState", prevState)
		// console.log("state3", this.state)
		const {files, documentSelected} = this.state
		if(documentSelected != prevState.documentSelected) {
			this.props.onChanged({
				// files: files,
				documentSelected: documentSelected
			})
		}
		
		// if(this.props.defaultFiles && this.props.defaultFiles.length != this.state.files.length) {
		// 	console.log('entrei aqui')
		// 	this.setState({files: this.props.defaultFiles})
		// }
	}

	handleChangeFiles = (e) => {
		const files = this.state.files
		// console.log('changed1', this.props.defaultFiles)
		let filesAfter = []
		Array.from(e.target.files).forEach(targetFile => {
			const countRepeatFile = files.filter(file => {
				return file.name === targetFile.name
			}).length

			if(countRepeatFile < 1) {
				filesAfter.push(targetFile)
			}
			
		});
		if(filesAfter.length > 0) {
			/// aqui retorna um callback para fazer a chamada quando novos arquivos são adicionados em filesAfter
			this.setState({filesChanged: filesAfter})
			this.props.onSelected(filesAfter)
		}

		filesAfter = [...files, ...filesAfter]

		// this.setState({files: filesAfter})
		this.inputRef.current.value = ''
	}

	triggerSelectFiles = () => {
		this.inputRef.current.click()
	}

	renderListFiles = () => {
		let {files} = this.state
		if(this.props.defaultFiles.length < 1) return null

		const listFiles = this.props.defaultFiles.map(file => (
			<React.Fragment key={file.id}>
				{ !file.analise &&
					<div onClick={() => this.removeSinleFile(file)} style={{position: 'absolute', marginTop: -7, cursor: 'pointer'}} title='Remover arquivo'>x {file.id}</div>
				}
				<p key={file.name} style={{fontSize: 11, marginLeft: 20}}>{file.name.substring(0,30)+"..."}</p>
			</React.Fragment>
		))

		return <div className='list-files'>{listFiles}</div>
	}

	clearListFiles = () => {
		this.setState({files: []})
		this.inputRef.current.value = ''
	}

	removeSinleFile = (file) => {
		const files = this.props.defaultFiles.filter(defaultFile => {
			return defaultFile.name != file.name
		})

		const fileDeleted = this.props.defaultFiles.filter(defaultFile => {
			return defaultFile.name == file.name
		})[0]

		this.setState({files})
		this.inputRef.current.value = ''
		this.props.onExcluded(fileDeleted)
	}

	handleDocumentType = (e) => {
		this.setState({documentSelected: e.target.value})
	}

	renderOptions = () => {
		const options = this.props.documents.map(document => {
			if(document.label.id == 7 && this.props.selectedCardOrRenda == 'renda') {
				return null
			}
			return <option key={document.label.id} value={document.label.id} selected={document.label.id == 7 && this.props.selectedCardOrRenda == 'card'}>{document.label.titulo}</option>
		})

		if(options[0].props.value === 1){
			const el = options.shift()
			options.push(el)
		}

		return options
	}

	renderLoading = () => {
		if (this.props.isLoading) {
			return (
				<div
					style={{
						position: 'absolute',
						backgroundColor: '#000',
						right: 0,
						left: 0,
						top: 0,
						bottom: 0,
						opacity: .9,
						display: 'flex',
						alignItems: 'center',
						zIndex: 1
					}}
				>
					<Loading width="55px" height="55px" color="color_border-gray" margin="0 auto" />
				</div>
			)
		}
		return null
	}

	render() {

		console.log("current SATE0", this.state)
		return(
			<div className='box-file' style={{border: `1px solid ${this.props.isValid ? '#37c120' : '#ff7200'}`, display: 'flex', alignItems: 'center', position: 'relative', padding: '40px 10px 10px', color: '#7f7f7f'}}>
				<p style={{position: 'absolute', top: 0, left: 5, fontSize: 12}}>
					{this.props.selectedCardOrRenda == 'card' ? 'Limite do Cartão de Crédito' : this.props.label}
					<div style={{visibility: 'hidden'}}>*{this.props.phraseRecommendation}</div>
				</p>
				
				<div style={{width: '50%', textAlign: 'center'}}>
					<p style={{fontSize: 14, fontWeight: 500}}>Qual documento deseja anexar?</p>
					<InputColContainer underlinecolor="input_underline_default_color" style={{backgroundColor: this.props.selectedCardOrRenda == 'card' && '#e9ecef'}}>
						<Select disabled={this.props.selectedCardOrRenda == 'card'} onChange={this.handleDocumentType} style={{width: '100%', height: 26, fontSize: 13}}>
							<option value=''>Selecione</option>
							{this.renderOptions()}
						</Select>
					</InputColContainer>
					<p style={{fontSize: 11, color: '#152b4e', marginTop: 5, marginBottom: 10}}>PDF, JPG, JPEG ou PNG</p>
					<SubmitButton
						disabled={!this.state.documentSelected && this.props.selectedCardOrRenda != 'card'}
						onClick={this.triggerSelectFiles}
						style={{width: 120, height: 34, padding: 0, fontSize: 11, color: '#fff', display: 'block', margin: '0 auto'}}
					>
						Escolher arquivos
					</SubmitButton>
				</div>
				<div style={{width: '50%', paddingLeft: 25}}>
					<input id='file' type='file' multiple ref={this.inputRef} onChange={this.handleChangeFiles} style={{display: 'none'}} />
					{this.renderListFiles()}
				</div>
				{this.renderLoading()}
			</div>
		)
	}
}

Card.defaultProps = {
	defaultFiles: [],
	callback: () => null,
	onChanged: () => null, // callback quando qualquer alteração é feita no card
	onExcluded: () => null, // callback quando algum anexo é excluido
	onSelected: () => null // callback quando novos arquivos são adicionados
}

class ModuleFiles extends React.Component {
	state = {
		modulo_principal: {files: [], documentSelected: null},
		modulo_secundario: {files: [], documentSelected: null},
		modalExcludedOpen: false,
		modalRecommendedOpen: false,
		titleModal: '',
		textModal: '',
		excludedData: {}
	}
	
	// componentDidMount() {
	// 	setTimeout(() => {
	// 		this.setState({
	// 			modulo_principal: {
	// 				...this.state.modulo_principal,
	// 				files: [
	// 					{id: 2, name: 'bola2.png'},
	// 					{id: 3, name: 'bola3.png'}
	// 				]
	// 			}
	// 		})
	// 		console.log('foi')
	// 	}, 5000)
	// 	console.log('montopu')
	// }

	// handleDocuments = (origem_renda_id) => {
	// 	const renda = this.props.origem_renda_documentos.filter(documento => {
	// 		return documento.label.id == origem_renda_id
	// 	})
	// 	return origem_renda_id && renda[0].documentos || []
	// }

	handleDocumentPhraseRecommendation = (origem_renda_id) => {
    if(!origem_renda_id) {return []}
		const phraseRecommendation = this.props.origem_renda_documento_recomendacao.filter(documento => {
			return documento.value == origem_renda_id
		})
		return phraseRecommendation[0] && phraseRecommendation[0].documentos
	}

	handleOrigemLabel = (origem_renda_id) => {
		const renda = this.props.origem_renda_documento_recomendacao.filter(renda => {
			return renda.value == origem_renda_id
		})
		return origem_renda_id && renda[0].label
	}

	handleSelectedFiles = (files, modulo = 'principal') => {
		let formData = new FormData();
		let documentSelected = null
		// const id_solicitacao = this.props.location.state.requestId
		// const id_pretendente = this.props.location.state.applicantId
    const id_cliente = this.props.clientId || 0
    // const entity = this.props.location.state.entity
    const fcDocId = this.props.products[0]

		for (var i = 0; i < files.length; i++) {
			formData.append('arquivo[]', files[i]);
		}

		if(modulo == 'principal') {
			documentSelected = this.state.modulo_principal.documentSelected
			this.setState({
				modulo_principal: {
					...this.state.modulo_principal,
					// files
				}
			})
		} else {
			documentSelected = this.state.modulo_secundario.documentSelected
			this.setState({
				modulo_secundario: {
					...this.state.modulo_secundario,
					// files
				}
			})
		}

		if(this.props.reconsultaFcRenda) {
			formData.append('reconsulta', 2);
		}

    formData.append('cliente', id_cliente);
		formData.append('renda', modulo == 'principal' ? 'principal' : 'renda');
		formData.append('documento', this.props.changedValues.avaliacao_financeira == 'card' ? '7' : documentSelected);
		this.props.sendFileUpload(formData, modulo, fcDocId)
	}
	
	handleExcludedFile = (file, modulo = 'principal') => {
		console.log('FILE1', file)
		// const id_solicitacao = this.props.location.state.requestId
		// const id_pretendente = this.props.location.state.applicantId
    const id_solicitacao = 0
		const id_pretendente = 0
		this.setState({excludedData: {file, modulo, id_solicitacao, id_pretendente}})
		this.handleModalExcluded()
	}

	handleModalExcluded = () => {
		this.setState({modalExcludedOpen: !this.state.modalExcludedOpen})
	}

	handleModalRecommended = (title, text) => {
		this.setState({
			modalRecommendedOpen: !this.state.modalRecommendedOpen,
			titleModal: title,
			textModal: text
		})
	}

	confirmModalExcluded = () => {
		const {file, modulo, id_solicitacao, id_pretendente} = this.state.excludedData
    // const entity = this.props.location.state.entity
    const fcDocId = this.props.products[0]
    console.log('vix2', fcDocId)
    // return;
		this.props.removeFileUpload(file, modulo, fcDocId)
		this.handleModalExcluded()
	}

	handleChangeDocumentSelected = (documentType, modulo = 'principal') => {
		if(modulo == 'principal') {
			this.setState({
				modulo_principal: {
					...this.state.modulo_principal,
					documentSelected: documentType.documentSelected
				}
			})
		} else {
			this.setState({
				modulo_secundario: {
					...this.state.modulo_secundario,
					documentSelected: documentType.documentSelected
				}
			})
		}
	}

	handleTextosRecomendados = (origem_renda_id) => {
		if(!origem_renda_id) return null
		const text_recomendacao = this.handleDocumentPhraseRecommendation(origem_renda_id).split('OU')
		const textos = text_recomendacao.map((texto, id)=> {
			return id >=1 && <p style={{margin: 0}}>{id >= 2 && <React.Fragment><span className='ou' style={{margin: 14, display: 'block'}}>ou</span></React.Fragment>}{texto.trim()}</p>
		})
		return textos
	}

  currentActiveProduct = () => {
    if(!this.props.location) {
      return null
    }
    const { ativo } = this.props.location.state.products
    const produtoFcReport = ativo.filter(produto => produto.id === 1)
    const produtoFcEssencial = ativo.filter(produto => produto.id === 3)
    const produtoFcEmpresa = ativo.filter(produto => produto.id === 4)
    const produtoFcBasico = ativo.filter(produto => produto.id === 5)
    if(produtoFcReport.length) {
      return produtoFcReport[0].id
    } else if(produtoFcEssencial.length) {
      return produtoFcEssencial[0].id
    } else if(produtoFcBasico.length) {
      return produtoFcBasico[0].id
    }
    return null
  }

	render() {
		const origem_renda_id = this.props.changedValues.origem_renda
		const origem_renda_secundaria_id = this.props.changedValues.origem_renda_secundaria
		let textosPrincipal = this.handleTextosRecomendados(origem_renda_id)
		let textosSecundario = this.handleTextosRecomendados(origem_renda_secundaria_id)
    this.currentActiveProduct()
    const entity = this.props.location && this.props.location.state.entity
		return(
				<div className='moduleFiles'>
          {!this.props.moduloAnalise &&
            <h5 style={{fontSize: '1rem', color: '#152b4e', marginTop: 40, marginBottom: 0, display: 'flex', width: 'max-content'}}>
              <RoundedMoney width="1rem" />
              <span style={{marginLeft: 4}}>DOCUMENTOS PARA ANÁLISE:</span>
            </h5>
          }
					{!this.props.moduloAnalise &&
						<div className='title' style={{display: 'flex', alignItems: 'center'}}>
							<hr style={{width: '100%'}} />
							<p style={{margin: '0 20px', color: '#152b4e', fontSize: 12, fontWeight: 500, width: 340}}>ANEXAR DOCUMENTOS</p>
							<hr style={{width: '100%'}} />
						</div>
					}

					{this.props.moduloAnalise &&
						<div style={{display: 'flex', justifyContent: 'space-between'}}>
							<div className='title' style={{display: 'flex', alignItems: 'center', width: '49%'}}>
								<hr style={{width: '100%'}} />
								<p style={{margin: '0 20px', color: '#152b4e', fontSize: 12, fontWeight: 500, width: 320}}>DOCS PRINCIPAIS</p>
								<hr style={{width: '100%'}} />
							</div>
              {entity !== 'PJ' &&
                <div className='title' style={{display: 'flex', alignItems: 'center', width: '49%'}}>
                  <hr style={{width: '100%'}} />
                  <p style={{margin: '0 20px', color: '#152b4e', fontSize: 12, fontWeight: 500, width: 320}}>OUTRAS RENDAS</p>
                  <hr style={{width: '100%'}} />
                </div>
              }
						</div>
					}

					<WrapperContainerAnexos>
						{(this.props.changedValues.avaliacao_financeira == 'renda' && origem_renda_id || this.props.changedValues.avaliacao_financeira == 'card' || this.props.changedValues.avaliacao_financeira == 'extratos' || this.props.changedValues.avaliacao_financeira == 'nao-informado' || this.currentActiveProduct() === 5 || this.props.changedValues.avaliacao_financeira_fcdoc_pj) &&
							<div className='wrapper-box-file'>
								<Card
									label={this.handleOrigemLabel(origem_renda_id)}
									documents={(entity === 'PJ' || this.props.changedValues.avaliacao_financeira_fcdoc_pj) ? this.props.origem_faturamento_documentos : this.props.origem_renda_documentos}
									onExcluded={(file)=> this.handleExcludedFile(file)}
									onChanged={(document) => this.handleChangeDocumentSelected(document)}
									onSelected={(files) => this.handleSelectedFiles(files)}
									phraseRecommendation={this.handleDocumentPhraseRecommendation(origem_renda_id)}
									defaultFiles={[...this.state.modulo_principal.files, ...this.props.modulo_principal]}
									isLoading={this.props.loadingFileUploadPrincipal}
									isValid={this.props.principalIsValid}
									selectedCardOrRenda={this.props.changedValues.avaliacao_financeira}
								/>
                {this.currentActiveProduct() !== 5 &&
                  <div onClick={() => this.handleModalRecommended(this.handleOrigemLabel(origem_renda_id), textosPrincipal)} style={{background: '#e04f5f', position: 'absolute', top: 20, left: 6, color: '#fff', fontSize: 11, padding: '0 4px', fontWeight: 500, cursor: 'pointer', paddingRight: 18}}>
                    docs recomendados <span style={{fontWeight: 500, transform: 'rotate(-45deg)', display: 'block', width: 15, position: 'absolute', top: -4, right: 1, fontSize: 14}}>&#10132;</span>
                  </div>
                }
								<div style={{background: '#e04f5f', position: 'absolute', top: 20, left: this.currentActiveProduct() === 5 ? 10 : 150, color: '#fff', fontSize: 11, padding: '0 4px', fontWeight: 500, paddingRight: 18}}>
									upload máximo: 20mb <span style={{fontWeight: 500, display: 'block', width: 15, position: 'absolute', top: -4, right: 1, fontSize: 14}}>&#9888;</span>
								</div>
							</div>
						}
						{origem_renda_secundaria_id && this.props.changedValues.avaliacao_financeira == 'renda' &&
							<div className='wrapper-box-file'>
								<Card
									label={this.handleOrigemLabel(origem_renda_secundaria_id)}
									documents={this.props.origem_renda_documentos}
									onExcluded={(file)=> this.handleExcludedFile(file, 'secundario')}
									onChanged={(document) => this.handleChangeDocumentSelected(document, 'secundario')}
									onSelected={(files) => this.handleSelectedFiles(files, 'secundario')}
									phraseRecommendation={this.handleDocumentPhraseRecommendation(origem_renda_secundaria_id)}
									defaultFiles={[...this.state.modulo_secundario.files, ...this.props.modulo_secundario]}
									isLoading={this.props.loadingFileUploadSecundario}
                  isValid={this.props.secundarioIsValid}
                  selectedCardOrRenda={this.props.changedValues.avaliacao_financeira}
								/>
								<div onClick={() => this.handleModalRecommended(this.handleOrigemLabel(origem_renda_secundaria_id), textosSecundario)} style={{background: '#e04f5f', position: 'absolute', top: 20, left: 6, color: '#fff', fontSize: 11, padding: '0 4px', fontWeight: 500, cursor: 'pointer', paddingRight: 18}}>
									docs recomendados <span style={{fontWeight: 500, transform: 'rotate(-45deg)', display: 'block', width: 15, position: 'absolute', top: -4, right: 1, fontSize: 14}}>&#10132;</span>
								</div>
								<div style={{background: '#e04f5f', position: 'absolute', top: 20, left: 150, color: '#fff', fontSize: 11, padding: '0 4px', fontWeight: 500, paddingRight: 18}}>
									upload máximo: 20mb <span style={{fontWeight: 500, display: 'block', width: 15, position: 'absolute', top: -4, right: 1, fontSize: 14}}>&#9888;</span>
								</div>
							</div>
						}
					</WrapperContainerAnexos>
					<ModalExcluedFile
						isOpen={this.state.modalExcludedOpen}
						onClose={this.handleModalExcluded}
						undo={this.confirmModalExcluded}
					/>
					<ModalRecommendedDocs
						isOpen={this.state.modalRecommendedOpen}
						onClose={() => this.handleModalRecommended()}
						undo={this.confirmModalExcluded}
						title={this.props.changedValues.avaliacao_financeira == 'card' ? 'Limite do Cartão de Crédito' : this.state.titleModal}
						text={this.props.changedValues.avaliacao_financeira == 'card' ? 'Extrato/Fatura do cartão de crédito' : this.state.textModal}
					/>		
				</div>
		)
	}
}

export default ModuleFiles