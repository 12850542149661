
export const REPORT_REQUESTNG = "report/REPORT_REQUESTNG";
export const REPORT_SUCCESS = "report/REPORT_SUCCESS";
export const REPORT_FAILED = "report/REPORT_FAILED";

export const PREPARE_REPORT = "report/PREPARE_REPORT";
export const RECEIVE_REPORT = "report/RECEIVE_REPORT";

export const PRINT_REQUESTING = "report/PRINT_REQUESTING";
export const PRINT_FAILED = "report/PRINT_FAILED";
export const PRINT_SUCCESS = "report/PRINT_SUCCESS";

export const SEND_MAIL_FIANCA = "report/SEND_MAIL_FIANCA";
export const SEND_MAIL_FIANCA_FAILED = "report/SEND_MAIL_FIANCA_FAILED";
export const SEND_MAIL_FIANCA_SUCCESS = "report/SEND_MAIL_FIANCA_SUCCESS";
export const SEND_MAIL_FIANCA_RESET = "report/SEND_MAIL_FIANCA_RESET";