import React, { PureComponent, Fragment } from 'react';
import { Collapse } from 'reactstrap';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';

import ArrowDown from './img/ArrowDown';
import ArrowUp from './img/ArrowUp';

import styled from 'styled-components';

export const ButtonArrow = styled.button`
  border: 0;
  background-color: transparent;
  color: ${props => props.theme.color_purple};
  position: relative;
  bottom: 1.5rem;
  cursor: pointer;
  width: 98%;
  display: flex;
  justify-content: flex-end;
  &:focus {outline:0;}
`;

export default class CollapsableContainer extends PureComponent {

  static defaultProps = {
    open: true
  }

  state = { isOpen: this.props.open }

  toggleCollapse = () => this.setState({ isOpen: !this.state.isOpen });
  
  render() {
    return (
      <Fragment>
        <ButtonArrow onClick={this.toggleCollapse}>
          { this.state.isOpen? <ArrowUp width="1rem" customstyle="margin: 0;"/> : <ArrowDown width="1rem" customstyle="margin: 0;"/> }
        </ButtonArrow>
        <Collapse isOpen={this.state.isOpen}>
          <StyledRow>
            <BaseColumn>
              { this.props.children }
            </BaseColumn>
          </StyledRow>
        </Collapse>
      </Fragment>
    )
  }
}
