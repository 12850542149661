import handler from '@bit/wavfichacerta.fichacerta.inputs-functions.input-handler';
import { textDisabled } from '@bit/wavfichacerta.fichacerta.inputs.inputs';
import { simpleDisabledText } from '@bit/wavfichacerta.fichacerta.fields-templates.simple-text';

export default {
  responsavel_comercial: handler({ textDisabled })(simpleDisabledText({ size: 'field-fc field-fc-l', name: 'responsavel_comercial', label: 'Responsável comercial (seguros)' })),
  obs_fianca: handler({ textDisabled })(simpleDisabledText({ size: 'field-fc field-fc-l', name: 'observacao_fianca', label: 'Observações do cliente' })),
  obs_imobiliario: handler({ textDisabled })(simpleDisabledText({ size: 'field-fc field-fc-l', name: 'observacao_imobiliario', label: 'Observações do cliente' })),
  obs_titulo: handler({ textDisabled })(simpleDisabledText({ size: 'field-fc field-fc-l', name: 'observacao_titulo', label: 'Observações do cliente' }))
}
