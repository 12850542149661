import React from 'react'
import { StyledInput } from './Inputs.styles';
import createInputWithValidation from './createInputWithValidation';


const TextInput = ({ name, value, onChange, onBlur, placeholder, isRequired, disabled }) => (
  <StyledInput
    id={name}
    name={name}
    value={value}
    onChange={onChange}
    type="text"
    onBlur={onBlur}
    placeholder={placeholder}
    required={isRequired}
    disabled={disabled}
  />
);

TextInput.defaultProps = {
  isRequired: false
};

export default createInputWithValidation('text')(TextInput);
