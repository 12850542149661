import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { closeModal } from './actions';

import ModalContainer from '../../../../../components/shared/modal/ModalContainer';
import { PrimaryButton } from '../../../../../components/shared/sharedStyles.styles';
import { Container, TitleModal, TextModal, ContainerButton } from './incomeModal.styled';

class IncomeModal extends PureComponent {

  renderContent = () => {
    if(this.props.avaliacao_financeira == 'renda' && this.props.origem_renda == 1) {
      return(
        <Fragment>
          <TextModal>
            Para informar um Valor de Renda, selecione alguma
            “Origem de Renda” diferente de "Renda Não Informada"
            ou digite o valor R$0,00 para prosseguir.
          </TextModal>
        </Fragment>
      )
    }

    if(this.props.avaliacao_financeira == 'renda') {
      return(
        <Fragment>
          <TextModal>Para Valor de Renda R$0,00, selecione na Origem de Renda - "Renda Não Informada" ou informe o valor para prosseguir.</TextModal>
        </Fragment>
      )
    } else if(this.props.avaliacao_financeira == 'card') {
      return (
        <Fragment>
          <TextModal>Para Valor de Renda R$0,00, selecione a opção:</TextModal>
          <TextModal>Perfil Financeiro Por - "Renda Pessoal Informada" e Origem de Renda - "Renda Não Informada".</TextModal>
        </Fragment>
      )
    }

    return(
      <Fragment>
        <TextModal>Se for fornecido o valor de uma renda, é necessário informar a origem dessa renda e vice-versa.</TextModal>
        <TextModal>Altere a renda ou a origem da renda para prosseguir.</TextModal>
      </Fragment>
    )
  }

  render() {
    const { isOpen, closeModal } = this.props;
    return (
      <ModalContainer
        isOpen={isOpen}
        size="md"
        onClose={closeModal}
      >
        <Container>
          <TitleModal>ATENÇÃO</TitleModal>
          { this.renderContent() }
          <ContainerButton>
            <PrimaryButton onClick={closeModal}>Voltar</PrimaryButton>
          </ContainerButton>
        </Container>
      </ModalContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.modalIncomeSection.isOpen
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(IncomeModal);
