import React, { Component, Fragment } from 'react';
import Steps from '../../../../components/Steps/Steps';
import ReportHeader from '../../../../components/Operational/ReportHeader';
import SyntheticReport from '../../../../components/Operational/SyntheticReport/SyntheticReport';
import SyntheticReportFcBasico from '../../../../components/Operational/SyntheticReport/SyntheticReportFcBasico';
import AnalythicReport from '../../../../components/Operational/AnalythicReport/AnalythicReport';
import { Container, BottomButton } from './report.styles';
import Title from './TitleCategory';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { MainContainer } from './../resume/RequestResume';

import ReactTooltip from 'react-tooltip'
import TourTooltip from './../../../../components/shared/TourTooltip';

export default class SingleReport extends Component {
  constructor(props){
    super(props)

    this.state = {
      FullPortViewContainer: null
    }
  }

  componentDidMount() {
    this.setState({FullPortViewContainer: document.querySelector('.full-port-view-container')})
  }

  // Avoid errors with data props
  getData = () => {
    const { data } = this.props;
    if (data === undefined || data === null || (typeof data === "object" && Object.keys(data).length === 0)) {
      return {};
    }
    return data;
  }

  // Avoid errors in the loading prop
  getLoadingStatus = () => {
    const { reportPending } = this.props;
    if (typeof reportPending !== "boolean") {
      return true;
    }
    return reportPending;
  }

  render() {
    const dataSingleReport = this.getData();
    const reportPending = this.getLoadingStatus();
    let mockedSteps = [
      { label: 'FC REPORT', checked: !reportPending, id: 1 }
    ];
    let fcrenda = {}
    let status = null;
    let isPF = true;
    let productFcEssencial = [];
    let productFcReport = [];
    let productFcCompletaPF = [];
    let isFcBasico = false;

    if(dataSingleReport.laudo && 'produtos' in dataSingleReport.laudo) {
      productFcReport = dataSingleReport.pretendente.produtos.ativo.filter(product => product.id === 1 && product.status !== 'INCLUIDO')
      const productFcRenda = dataSingleReport.pretendente.produtos.ativo.filter(product => product.id === 2)
      productFcEssencial = dataSingleReport.pretendente.produtos.ativo.filter(product => product.id === 3 && product.status !== 'INCLUIDO')
      const productFcEmpresa = dataSingleReport.pretendente.produtos.ativo.filter(product => product.id === 4)
      const productFcBasico = dataSingleReport.pretendente.produtos.ativo.filter(product => product.id === 5)
      const productFcDocPJ = dataSingleReport.pretendente.produtos.ativo.filter(product => product.id === 6)
      productFcCompletaPF = dataSingleReport.pretendente.produtos.ativo.filter(product => product.id === 7)
      const productFcCompletaPJ = dataSingleReport.pretendente.produtos.ativo.filter(product => product.id === 8)

      isFcBasico = (productFcBasico.length && !productFcEssencial.length && !productFcReport.length)

      if(productFcEssencial.length && !productFcReport.length){
        mockedSteps = [
          { label: "FC ESSENCIAL", checked: !reportPending, id: 3 }
        ];
      }

      if(productFcEmpresa.length){
        isPF = false;
        mockedSteps = [
          { label: "FC EMPRESA", checked: !reportPending, id: 4 }
        ];
      }

      if(productFcCompletaPF.length){
        mockedSteps = [
          { label: "FC COMPLETA", checked: !reportPending, id: 7 }
        ];
      }

      if(productFcCompletaPJ.length){
        isPF = false;
        mockedSteps = [
          { label: "FC COMPLETA", checked: !reportPending, id: 8 }
        ];
      }

      if(isFcBasico){
        mockedSteps = [
          { label: "FC BASICO", checked: !reportPending, id: 5 }
        ];
      }

      if(productFcRenda.length || productFcDocPJ.length){
        fcrenda = productFcRenda[0] || productFcDocPJ[0]
        status = productFcRenda[0] ? productFcRenda[0].status : productFcDocPJ[0].status;
      }
    }

    return (
      <Fragment>
        {this.props.currentApplicantIndex === 1 &&
          <div className='reportHeader' style={{margin: '0 auto'}}>
            <Title className='color-black-print'>LAUDOS INDIVIDUAIS</Title>
          </div>
        }
        <ReportHeader data={dataSingleReport} loading={reportPending} data-testid="headerReport"/>
        {!reportPending && <Steps items={mockedSteps} fcrenda={fcrenda} status={status} />}

        <MainContainer className="full-port-view-container" style={{paddingTop: 0, overflowY: 'auto'}}>
          {isPF &&
            <div style={{marginTop: 10}}>
              <Title className='color-black-print'>RESUMO INDIVIDUAL

                {!reportPending &&
                  <Fragment>
                    <TourTooltip
                      target="tooltip-sintese-parecer-locacao-individual-pf"
                      style={{right: -10, top: -14}}
                    />
                    <ReactTooltip id="tooltip-sintese-parecer-locacao-individual-pf" place="bottom" type="dark" effect="float">
                      <span style={{textTransform: 'initial'}}>Quer entender melhor algum item do Laudo FC? <br />Clique no tour e saiba mais.</span>
                    </ReactTooltip>
                  </Fragment>
                }
              </Title>
              {(productFcEssencial.length || productFcReport.length || productFcCompletaPF.length) &&
                <SyntheticReport toggleModalFianca={this.props.toggleModalFianca} indexActive={this.props.indexActive} data={dataSingleReport} loading={reportPending} mainContainerRef={this.state.FullPortViewContainer} customer={this.props.customer} /> ||
                <SyntheticReportFcBasico toggleModalFianca={this.props.toggleModalFianca} indexActive={this.props.indexActive} data={dataSingleReport} loading={reportPending} mainContainerRef={this.state.FullPortViewContainer} customer={this.props.customer} />
              }
            </div>
          }

          <div style={{marginTop: isPF ? 0 : 10}}>
            <Title className='color-black-print' style={{display: isPF ? 'none' : ''}}>
              {isPF && 'Dados' || 'DADOS ENCONTRADOS'}
              {!isPF &&
                <Fragment>
                  <TourTooltip
                    target="tooltip-sintese-parecer-locacao-individual-pj"
                    style={{right: -10, top: -14}}
                  />
                  <ReactTooltip id="tooltip-sintese-parecer-locacao-individual-pj" place="bottom" type="dark" effect="float">
                    <span style={{textTransform: 'initial'}}>Quer entender melhor algum item do Laudo FC? <br />Clique no tour e saiba mais.</span>
                  </ReactTooltip>
                </Fragment>
              }
            </Title>
          </div>
          <AnalythicReport data={dataSingleReport} loading={reportPending} />

          <StyledRow margin="1rem auto auto auto">
            <BottomButton onClick={this.props.manageReport}>Editar ou Incluir</BottomButton>
          </StyledRow>
        </MainContainer>
      </Fragment>
    );
  }
}