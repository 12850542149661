import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BlueArrow, MonthContainer, FilterContainer, DateContainer, BasicContainer, ResumeContainer, Line, ValueContainer, ValueTitle, TotalValue, ResumeTitle } from './ChargeStyles';
import Filter from '../../../../components/shared/icons/Filter';
import handler from '@bit/wavfichacerta.fichacerta.inputs-functions.input-handler'
import { radio } from '@bit/wavfichacerta.fichacerta.inputs.inputs';
import { ContainerRadioCobranca, ButtonForm } from '../../../../components/shared/formStyles.styled'
import {reduxForm} from 'redux-form';
import { bindActionCreators } from 'redux';
import { getChargeData, changeFilters, getChargeDataPrint, getChargeDataPrintExcel } from '../actions';
import FilterHandler from './FilterHandler';
import Resume from './Resume'
import { setFilter, getInitialValues } from '../charge-helpers';
import { PDFButton } from '../../../../components/Header/ButtonsHeader/DefaultButton';
import Moment from 'moment';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../../components/shared/TourTooltip';

const canRequest = [
  { value: '1', label: 'Período' },
  { value: '0', label: 'Mês' },
]

function getMonth(){
  const month = Moment().format("MM");
  switch(month){
    case '01':
      return "JANEIRO"
    case '02':
      return "FEVEREIRO"
    case '03':
      return "MARÇO"
    case '04':
      return "ABRIL"
    case '05':
      return "MAIO"
    case '06':
      return "JUNHO"
    case '07':
      return "JULHO"
    case '08':
      return "AGOSTO"
    case '09':
      return "SETEMBRO"
    case '10':
      return "OUTUBRO"
    case '11':
      return "NOVEMBRO"
    case '12':
      return "DEZEMBRO"
    default:
      return null
  }
}

const SideContent = (props) => {
  const { filtered, login } = props
  const { master } = login.user.usuario

  const handleSubmitFilter = () => {
    setFilter(props.changedValues, props.changeFilters, props.getChargeData)
    props.callback() // callback for setState in dad component
  }

  const handleSubmitGetPrint = () => {
    props.getChargeDataPrint(props.id)
  }

  const handleSubmitGetPrintExcel = () => {
    props.getChargeDataPrintExcel(props.id)
  }

  return (
    <Fragment>
      <MonthContainer filtered={filtered}>
        <span>MÊS ATUAL: <span style={{fontSize: "15px", fontWeight: 600}} >{getMonth()}</span></span>
      </MonthContainer>
      <BlueArrow filtered={filtered} />
      <FilterContainer>
        <StyledRow align="flex-end" >
          <BaseColumn xl={4}>
            <Filter />
            <span style={{fontSize: "12px", marginLeft: "3px"}} >
              Filtrar por:
            </span>
          </BaseColumn>
          <BaseColumn>
            <ContainerRadioCobranca>
              {handler({ radio })({ size: 'field-fc field-fc-sm', type: 'radio', name: 'radio_filter', options: canRequest })}
            </ContainerRadioCobranca>
          </BaseColumn>
        </StyledRow>
      </FilterContainer>
      <DateContainer filtered={filtered} className='date-container' style={{position: 'relative'}}>
        {FilterHandler(props.changedValues.radio_filter)}
        <TourTooltip
          target="tooltip-selecionar-periodo"
          style={{right: -10}}
        />
        <ReactTooltip id="tooltip-selecionar-periodo" place="top" type="dark" effect="float">
          Selecione o período desejado
        </ReactTooltip>
      </DateContainer>
      <BlueArrow type='toSecondField' filtered={filtered} />
      <BasicContainer>
        <StyledRow margin="10px 0 0 0" justify="flex-end" style={{position: 'relative', marginTop: 15}}>
          <ButtonForm disabled={false} onClick={handleSubmitFilter} className='button-filtrar'>FILTRAR</ButtonForm>
          <TourTooltip
            target="tooltip-button-filtrar"
            style={{right: -10}}
          />
          <ReactTooltip id="tooltip-button-filtrar" place="top" type="dark" effect="float">
            Clique em filtrar para obter as informações
          </ReactTooltip>
        </StyledRow>
      </BasicContainer>
      <BasicContainer>
        <ResumeTitle>RESUMO</ResumeTitle>
        <ResumeContainer>
          <Resume resume={props.resume} master={master} />
        </ResumeContainer>
        {/* {master && <Line/>} */}
        {/* {master &&
          <ValueContainer className='value-container' style={{position: 'relative'}}>
            <ValueTitle>VALOR TOTAL</ValueTitle>
            <TotalValue>{props.resume ? props.resume.valor_pago : "R$ 0,00"}</TotalValue>
            <TourTooltip
              target="tooltip-value-container"
              style={{right: -10}}
            />
            <ReactTooltip id="tooltip-value-container" place="top" type="dark" effect="float">
              Aqui você observa o valor consumido no período
            </ReactTooltip>
          </ValueContainer>
        } */}
        <div style={{position: 'relative', width: 'max-content', marginTop: 20}}>
          <PDFButton onClick={handleSubmitGetPrint} disabled={Object.keys(props.resume.produtos).length < 1} className='imprimir-pdf'>Imprimir/Salvar PDF</PDFButton><br />
          <TourTooltip
            target="tooltip-button-pdf"
            style={{right: -10}}
          />
          <ReactTooltip id="tooltip-button-pdf" place="top" type="dark" effect="float">
            Você pode salvar em PDF
          </ReactTooltip>
        </div>
        <div style={{position: 'relative', width: 'max-content', marginTop: 10}}>
          <PDFButton onClick={handleSubmitGetPrintExcel} disabled={Object.keys(props.resume.produtos).length < 1} className='salvar-excel'>Salvar Excel</PDFButton>
          <TourTooltip
            target="tooltip-excel"
            style={{right: -10}}
          />
          <ReactTooltip id="tooltip-excel" place="right" type="dark" effect="float">
            <span>Exporte seu relatório para Excel possibilitando analisar os <br />dados com mais facilidade.</span>
          </ReactTooltip>
        </div>
      </BasicContainer>
    </Fragment>
  )
}

const Form = reduxForm({
  form: "cobranca",
})(SideContent)

const mapDispatchToProps = (dispatch) => bindActionCreators({getChargeData, changeFilters, getChargeDataPrint, getChargeDataPrintExcel}, dispatch)

const mapStateToProps = (state) => ({
  changedValues: state.form.cobranca !== undefined ? state.form.cobranca.values : {},
  initialValues: getInitialValues(state.chargeData.resume),
  resume: state.chargeData.resume,
  login: state.login,
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)