import React, { PureComponent, Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import handler from '@bit/wavfichacerta.fichacerta.inputs-functions.input-handler';
import { toastr } from 'react-redux-toastr';
import { fetchCEP } from '../../validation/actions';

import { text, select, composed, maskedText, textDisabled, cep } from '@bit/wavfichacerta.fichacerta.inputs.inputs';
import { SubtitleCard } from '../../../components/Customer/customerData.styled';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { ButtonForm } from '../../../components/shared/formStyles.styled';
import {
  StyledRow,
  FormContentRow
} from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import Card from '@bit/wavfichacerta.fichacerta.cards.card';
import { getChangedValues } from '../../../utils/formHelper';

import CollapsableContainer from '../../../components/CollapsableContainer';
import {
  identificationFields,
  addressFields,
  aditionalContactsFirstFields,
  aditionalContactsSecondFields
} from './fieldsPF';
import {
  identificationFields as identificationPJ,
  addressFields as addressPJ,
  aditionalContactsFirstFields as aditionalContactsFirstFieldsPJ,
  aditionalContactsSecondFields as aditionalContactsSecondFieldsPJ,
  aditionalContactsThirdFields as aditionalContactsThirdFieldsPJ
} from './fieldsPJ';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../components/shared/TourTooltip';
import { instance as axiosInstance } from './../../../services/axiosInstance';

class CustomerForm extends PureComponent {
  state = {
    dataProducts: []
  }

  static defaultProps = {
    requirements: {}
  }

  componentDidMount(){
    if(this.props.master) {
      this.getAdministrador();
    }
  }

  getAdministrador = async () => {
    try {
      const URL = '/api/administrador?relations=true&information=true'

      const token = this.props.login.user.token.access_token

      const headers = {
        'Authorization': `Bearer ${token}`
      }

      const result = await axiosInstance.get(URL, {headers});
      if(result.status === 401) {
        this.props.history.push('/auth')
      }
      const response = result.data.data;
      const produtos_disponiveis_usuario_adicional = result.data.data.cliente.produtos_adicionais;
      const produtos_habilitados_cliente = result.data.data.cliente.produtos_habilitados;
      const cliente_tem_fccompletaPF = produtos_habilitados_cliente.some((item) => item.id === 7)
      const cliente_tem_fccompletaPJ = produtos_habilitados_cliente.some((item) => item.id === 8)
      let products = result.data.data.information.produtos.map(product => {
        return {
          id: product.id,
          titulo: product.titulo,
          checked: produtos_disponiveis_usuario_adicional.some((item) => item.id === product.id),
        }
      })
      if(!cliente_tem_fccompletaPF) {
        products = products.filter(item => item.id !== 7)
      }
      if(!cliente_tem_fccompletaPJ) {
        products = products.filter(item => item.id !== 8)
      }
      this.setState({dataProducts: [...products]})
      console.log('productsx1', products)
      return response;
    } catch(err) {
      console.log('ERR:', err)
      throw err
    }
  }

  addProdutoAdicional = async (productId) => {
    try {
      const URL = '/api/administrador/funcionario/produtos-adicionais'

      const token = this.props.login.user.token.access_token

      const headers = {
        'Authorization': `Bearer ${token}`
      }

      let ids = []
      
      this.state.dataProducts.map(product => {
        if(product.checked) {
          ids.push(product.id)
        }
      })

      ids.push(Number(productId))

      const data = {
        produtos: ids
      }

      const result = await axiosInstance.post(URL, data, {headers});
      if(result.status === 401) {
        this.props.history.push('/auth')
      }
      const response = result.data;
      await this.getAdministrador();
      if (result.status !== 200) {
        toastr.error('Solicitação', 'Não foi possível processar a sua solicitação no momento.');
        return;
      }
      
      toastr.success('Sucesso!', response.message);
      return response;
    } catch(err) {
      console.log('ERR:', err)
      toastr.error('Solicitação', 'Não foi possível processar a sua solicitação no momento.');
      throw err
    }
  }

  deleteProdutoAdicional = async (productId) => {
    try {
      const URL = '/api/administrador/funcionario/produtos-adicionais'

      const token = this.props.login.user.token.access_token

      const headers = {
        'Authorization': `Bearer ${token}`
      }

      const data = {
        produtos: [Number(productId)]
      }

      const result = await axiosInstance.delete(URL, {data, headers});
      if(result.status === 401) {
        this.props.history.push('/auth')
      }
      const response = result.data;
      await this.getAdministrador();
      if (result.status !== 200) {
        toastr.error('Solicitação', 'Não foi possível processar a sua solicitação no momento.');
        return;
      }
      
      toastr.success('Sucesso!', response.message);
      return response;
    } catch(err) {
      console.log('ERR:', err)
      toastr.error('Solicitação', 'Não foi possível processar a sua solicitação no momento.');
      throw err
    }
  }

  renderFields = (fields) => {
    const { requirements } = this.props;
    return fields.map((row, index) => (
      <StyledRow key={row.name} align="flex-start" justify="flex-start" customstyle={`margin-top: ${index > 0? ".5rem": "0"};`}>
        {
          row.fields.map((field) => {
            if (field.type === "select") {
              return handler({ select })({ ...field, options: requirements[field.name] });
            } else if (field.type === "cep") {
              const { fetchCEP } = this.props;
              return handler({ cep })({ ...field, fetchCEP });
            }
            return handler({ text, composed, maskedText, textDisabled })(field);
          })
        }
      </StyledRow>
    ));
  }

  submitForm = () => {
    const { entity, changedValues, initialValues, onSubmitForm } = this.props;
    const formValues = getChangedValues(initialValues, changedValues);
    onSubmitForm(entity, formValues);
  }

  onChange = (e) => {
    const checked = e.target.checked
    const productId = e.target.id
    if(checked) {
      this.addProdutoAdicional(productId)
    } else {
      this.deleteProdutoAdicional(productId)
    }
  }

  getDefaultChecked = (name) => {
    this.state.dataProducts.filter(product => {
      return product.id === 2
    })
  }

  renderFieldsCheckProducts = () => {
    return this.state.dataProducts.map(product => {
      return(
        <div style={{width: 160}} key={product.id}>
          <input type="checkbox" id={product.id} name={product.id} style={{cursor: 'pointer'}} onChange={this.onChange} defaultChecked={product.checked} />
          <label for={product.id} style={{fontSize: '0.8rem', color: '#7f7f7f', fontWeight: 600, marginLeft: 7, marginTop: 1, position: 'absolute', cursor: 'pointer'}}>{product.titulo}</label>
        </div>
      )
    })
  }
  
  render() {
    const { entity, requirements, isSaving, changedValues, initialValues, master } = this.props;
    if (typeof requirements === "object" && Object.keys(requirements).length === 0) {
      return null;
    }
    const formValues = getChangedValues(initialValues, changedValues);
    return (
      <StyledRow margin="3vh 0">
        <BaseColumn>
          <div className="dados-cadastrais">
            <Card title="Identificação" padding="1rem 1rem 0 1rem">
              <CollapsableContainer>
                <FormContentRow>
                  {entity === "PF"? this.renderFields(identificationFields(master)) : this.renderFields(identificationPJ(master))}
                </FormContentRow>
              </CollapsableContainer>
            </Card>
            
            <Card title="Endereço" padding="1rem 1rem 0 1rem">
              <CollapsableContainer>
                <FormContentRow>
                  {entity === "PF"? this.renderFields(addressFields(master)) : this.renderFields(addressPJ(master))}
                </FormContentRow>
              </CollapsableContainer>
            </Card>
            <TourTooltip
              target="tooltip-dados-cadastrais"
              style={{right: -10}}
            />
            <ReactTooltip id="tooltip-dados-cadastrais" place="left" type="dark" effect="float">
              Aqui é possível conferir os dados cadastrais da imobiliária
            </ReactTooltip>
          </div>
          
          <Card title="Contatos Adicionais" padding="1rem 1rem 0 1rem" style={{position: 'relative'}}>
            <CollapsableContainer open={false}>
              <SubtitleCard>| OPERACIONAL</SubtitleCard>
              <FormContentRow>
                {entity === "PF"? this.renderFields(aditionalContactsFirstFields(master)) : this.renderFields(aditionalContactsFirstFieldsPJ(master))}
              </FormContentRow>

              <SubtitleCard>| FINANCEIRO</SubtitleCard>
              <FormContentRow>
                {entity === "PF"? this.renderFields(aditionalContactsSecondFields(master)) : this.renderFields(aditionalContactsSecondFieldsPJ(master))}
              </FormContentRow>

              {entity === "PJ" && (
                <Fragment>
                  <SubtitleCard>| DIRETORIA</SubtitleCard>
                  <FormContentRow>
                    {this.renderFields(aditionalContactsThirdFieldsPJ(master))}
                  </FormContentRow>
                </Fragment>
              )}
            </CollapsableContainer>
            <TourTooltip
              target="tooltip-contatos-adicionais"
              style={{right: -10}}
            />
            <ReactTooltip id="tooltip-contatos-adicionais" place="left" type="dark" effect="float">
              Caso precise atualizar os contatos das áreas, acione o nosso suporte.
            </ReactTooltip>
          </Card>

          {master &&
            <Card title="Produtos do CLIENTE" padding="1rem 1rem 0 1rem">
              <CollapsableContainer>
                <h6 style={{fontSize: '0.8rem', color: '#7f7f7f', marginBottom: 15}}>Produtos para o usuário ADICIONAL</h6>
                <div style={{columnCount: 2, columnGap: 50, width: '25%', marginBottom: 20}}>
                  {this.renderFieldsCheckProducts()}
                </div>
              </CollapsableContainer>
            </Card>
          }
        </BaseColumn>
        {/* <StyledRow margin="0 0 2vh 0">
          {master && <ButtonForm onClick={this.submitForm} disabled={isSaving || formValues === null}>Salvar Alterações</ButtonForm>}
        </StyledRow> */}
      </StyledRow>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ fetchCEP }, dispatch);

const mapStateToProps = (state) => ({
  requirements: state.requirements,
  changedValues: state.form.customerForm !== undefined? state.form.customerForm.values : {},
  master: state.login.user.usuario.master,
  isSaving: state.customerData.isSaving,
  login: state.login
});

const Form = reduxForm({
  form: 'customerForm',
  enableReinitialize: true
})(CustomerForm);

export default connect(mapStateToProps, mapDispatchToProps)(Form);
