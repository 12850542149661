import React from 'react';
import styled, { css } from 'styled-components';

const SVG = styled.svg`
  width: ${props => props.width || '24px'} !important;
  height: ${props => props.height || '100%'};
  margin: ${props => props.margin || 'auto'};
  margin-left: -4px;

  & path, line { fill: none !important; stroke: #152b4e; }
  ${props => css`${props.customStyle}`};
`;

export default (props) => (
  <SVG {...props} viewBox="0 0 512 512" enable-background="new 0 0 512 512">
    <g><path d="    M96.88,414.604" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="8"/><path d="    M97.649,96.372" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="8"/><path d="    M416,130v330.648c0,8.479-6.731,15.352-15.211,15.352H111.207C102.732,476,96,469.128,96,460.648V51.347    C96,42.872,102.732,36,111.207,36H321" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="8"/><path d="    M402,132h-66.725c-8.479,0-15.275-6.733-15.275-15.208V50" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="8"/><path d="    M363.949,98.421" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="8"/><line fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="8" x1="333.227" x2="414.072" y1="38" y2="118.836"/><line fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="8" x1="125" x2="225" y1="185" y2="185"/><line fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="8" x1="125" x2="381" y1="233" y2="233"/><line fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="8" x1="125" x2="381" y1="282" y2="282"/><line fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="8" x1="287" x2="381" y1="381" y2="381"/><line fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="8" x1="125" x2="381" y1="332" y2="332"/></g>
  </SVG>
);
