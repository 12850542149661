import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { omit } from 'lodash';

import { openModal, closeModal, openImportantFieldsModal, closeImportantFieldsModal } from './fieldModal/actions';
import StepButton from '../../../../components/shared/form/StepButton';
// import StepButton from '@bit/wavfichacerta.fichacerta.forms-buttons.step-button';

import FormModalFields from './fieldModal/FormModalFields';
import ImportantFieldsModal from './fieldModal/ImportantFieldsModal';

import {
  getNotFilledImpoprtantFields,
  getImportantFieldsWithWarning,
  getFieldsLabelByForm
} from '../../../../utils/operational/requests/requestFormHelper';

class RequestStepButton extends PureComponent {
  static defaultProps = {
    onChangeStep: () => { },
    withoutTenancy: false,
    editTenancy: false,
    applicantWithReport: false,
    products: []
  }

  getImportantFieldsLabels = (allForms) => {
    const { entity } = this.props;
    let notFilled = getNotFilledImpoprtantFields(allForms, entity);
    let withWarnig = getImportantFieldsWithWarning(allForms);

    if (this.props.withoutTenancy) {
      notFilled = omit(notFilled, ['tenancyForm']);
      withWarnig = omit(withWarnig, ['tenancyForm']);
    }

    if (this.props.editTenancy) {
      notFilled = omit(notFilled, ['applicantForm', 'incomeForm']);
      withWarnig = omit(withWarnig, ['applicantForm', 'incomeForm']);
    }

    if (this.props.applicantWithReport) {
      notFilled = omit(notFilled, ['tenancyForm', 'applicantForm']);
      withWarnig = omit(withWarnig, ['tenancyForm', 'applicantForm']);
    }
    
    return getFieldsLabelByForm(
      { notFilled, withWarnig },
      this.props.usedFields
    );
  }

  sendRequestRegistration = () => {
    const { closeModal, sendRequestRegistration, closeImportantFieldsModal } = this.props;

    sendRequestRegistration();
    closeModal();
    closeImportantFieldsModal();
  }

  changeStep = (allForms, fieldsEmpty = []) => {
    if (fieldsEmpty.length > 0) {

      this.props.openModal(fieldsEmpty);

    } else {
      const { openImportantFieldsModal, products } = this.props;

      let importantFields = this.getImportantFieldsLabels(allForms);
      if(products[0] === 5) {
        importantFields = {};
      }
      if (Object.keys(importantFields).length > 0) {
        
        openImportantFieldsModal(importantFields);
      } else {
        this.sendRequestRegistration();
      }
    }

  }

  render() {
    return (
      <Fragment>
        <StepButton
          showBackButton={this.props.showBackButton}
          forms={this.props.forms}
          goBack={this.props.goBack}
          currentStep={this.props.step}
          usedFields={this.props.usedFields}
          onChangeStep={this.changeStep}
          sending={this.props.sending}
          entity={this.props.entity}
          label="Salvar"
        />
        <FormModalFields />
        <ImportantFieldsModal sendData={this.sendRequestRegistration} entity={this.props.entity} />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openModal,
  closeModal,
  openImportantFieldsModal,
  closeImportantFieldsModal
}, dispatch);

export default connect(null, mapDispatchToProps)(RequestStepButton);
