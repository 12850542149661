
const showTenancyTotal = (fields) => {
  if (!("aluguel" in fields) && !("condominio" in fields) && !("iptu" in fields)) {
    return ['total_locacao'];
  }
  
  return [];
}

/**
 * This function returns an array with the fields
 * what will be removed from the form. If the function
 * returns an empty array, all fields will appear to the
 * user.
 * 
 * @param entity <String>
 * @param fields <Array>
 */
export default (entity = 'PF', fields) => {
  let fieldsRemoved = [
    'total_locacao'
  ];

  if (entity === "PF" && fields !== undefined && (typeof fields === 'object' && Object.keys(fields).length > 0)) {
    fieldsRemoved = [];

    fieldsRemoved = [...fieldsRemoved, ...showTenancyTotal(fields)];

    return fieldsRemoved;
  } else if (entity === "PJ" && fields !== undefined && (typeof fields === 'object' && Object.keys(fields).length > 0)) {
    fieldsRemoved = [];

    fieldsRemoved = [...fieldsRemoved, ...showTenancyTotal(fields)];

    return fieldsRemoved;
  }

  return fieldsRemoved;
}

