export const OPEN_EMPLOYEE_MODAL = 'employeesTable/OPEN_EMPLOYEE_MODAL';
export const CLOSE_EMPLOYEE_MODAL = 'employeesTable/CLOSE_EMPLOYEE_MODAL';
export const GET_EMPLOYEE_REQUEST = 'employeesTable/GET_EMPLOYEE_REQUEST';
export const GET_EMPLOYEE_SUCCESS = 'employeesTable/GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAILED = 'employeesTable/GET_EMPLOYEE_FAILED';
export const GET_EMPLOYEES = 'employeesTable/GET_EMPLOYEES';
export const SELECT_EMPLOYEE_CREDIT = 'employeesTable/SELECT_EMPLOYEE_CREDIT'
export const GET_EMPLOYEES_REQUEST = 'employeesTable/GET_EMPLOYEES_REQUEST';
export const GET_EMPLOYEES_SUCCESS = 'employeesTable/GET_EMPLOYEES_SUCCESS';
export const REGISTER_EMPLOYEE_FAILED = 'employeesTable/REGISTER_EMPLOYEE_FAILED';
export const GET_EMPLOYEES_FAILED = 'employeesTable/GET_EMPLOYEES_FAILED';
export const EDIT_EMPLOYEE_FAILED = 'employeesTable/EDIT_EMPLOYEE_FAILED';
export const CREDIT_EMPLOYEE_FAILED = 'employyesTable/CREDIT_EMPLOYEE_FAILED';
export const OPEN_TYPE_EMPLOYEE_MODAL = 'employeeTable/OPEN_TYPE_EMPLOYEE_MODAL';
export const CLOSE_TYPE_EMPLOYEE_MODAL = 'employeeTable/CLOSE_TYPE_EMPLOYEE_MODAL';
export const OPEN_STATUS_EMPLOYEE_MODAL = 'employeeTable/OPEN_STATUS_EMPLOYEE_MODAL';
export const CLOSE_STATUS_EMPLOYEE_MODAL = 'employeeTable/CLOSE_STATUS_EMPLOYEE_MODAL';
export const OPEN_EMPLOYEE_CHANGE_MODAL = 'employeeTable/OPEN_EMPLOYEE_CHANGE_MODAL';
export const CLOSE_EMPLOYEE_CHANGE_MODAL = 'employeeTable/CLOSE_EMPLOYEE_CHANGE_MODAL';
export const ON_UPDATE_EMPLOYEE_CREDIT = 'employeeTable/ON_UPDATE_EMPLOYEE_CREDIT';
export const CHANGE_PAGE = 'employeeTable/CHANGE_PAGE';
export const CHANGE_RESULTS_PER_PAGE = 'employeeTable/CHANGE_RESULTS_PER_PAGE';
export const RESET_PAGINATION = 'employeeTable/RESET_PAGINATION';
export const EDIT_PRODUCTS_ROW = 'productsTable/EDIT_PRODUCTS_ROW';
export const GET_PRODUCTS_REQUEST = 'productsTable/GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'productsTable/GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILED = 'productsTable/GET_PRODUCTS_FAILED';
export const ADD_PRODUCT = 'productsTable/ADD_PRODUCT';
export const DELETE_PRODUCT = 'productsTable/DELETE_PRODUCT';
export const UPDATE_PRODUCT = 'productsTable/UPDATE_PRODUCT';
export const RESET_REDUX_PRODUCTS = 'productsTable/RESET_REDUX_PRODUCTS';
export const CHANGE_TAB = "contributorsTable/CHANGE_TAB";
export const CHANGE_SORT = "contributorsTable/CHANGE_SORT";
export const SET_FILTER = "contributorsTable/SET_FILTER";
export const GET_CONTRIBUTORS_REQUEST = "contributorsTable/GET_CONTRIBUTORS_REQUEST";
export const GET_CONTRIBUTORS_SUCCESS = "contributorsTable/GET_CONTRIBUTORS_SUCCESS";
export const GET_CONTRIBUTORS_FAILED  = "contributorsTable/GET_CONTRIBUTORS_FAILED";
export const SAVE_SELECTED_CONTRIBUTOR = "contributorsTable/SAVE_SELECTED_CONTRIBUTOR";
export const OPEN_MODAL_CONTRIBUTOR = "contributorsTable/OPEN_MODAL_CONTRIBUTOR";
export const CLOSE_MODAL_CONTRIBUTOR = "contributorsTable/CLOSE_MODAL_CONTRIBUTOR";
//export const CHANGE_PAGE = 'contributorsTable/CHANGE_PAGE';
//export const CHANGE_RESULTS_PER_PAGE = 'contributorsTable/CHANGE_RESULTS_PER_PAGE';
//export const RESET_PAGINATION = 'contributorsTable/RESET_PAGINATION';
export const CLOSE_STATUS_MODAL = 'clientsTable/CLOSE_STATUS_MODAL';
export const GET_CLIENTS_FAILED = 'clientsTable/GET_CLIENT_FAILED';
export const GET_CLIENTS_REQUEST = 'clientsTable/GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = 'clientsTable/GET_CLIENTS_SUCCESS';
export const OPEN_STATUS_MODAL = 'clientsTable/OPEN_STATUS_MODAL';
export const SAVE_SELECTED_CUSTOMER = 'clientsTable/SAVE_SELECTED_CUSTOMER';

