import styled, { css } from 'styled-components';
import close from '../../img/svg/ico-close-interno.svg';
import check from '../../img/svg/Check.svg';
import { BaseScaleButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { Modal } from 'reactstrap';

export const FadeContainer = styled.div`
  ${props => !props.isVisible && css`display: none`};
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: rgba(46, 53, 78, 0.8);
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; } 
  }
  @-moz-keyframes fadeIn {
    0% { opacity: 0;}
    100% { opacity: 1; }
  }
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  -webkit-animation: fadeIn .8s ease-in-out;
  -moz-animation: fadeIn .8s ease-in-out;
  -o-animation: fadeIn .8s ease-in-out;
  animation: fadeIn .8s ease-in-out;
  overflow: auto;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex: ${props => props.flex || 1};
  height: 100%;
  ${props => {
    const items = props.alignItems ? `align-items: ${props.alignItems}` : '';
    const content = props.justifyContent ? `justify-content: ${props.alignItems}` : '';
    const direction = props.flexDirection ? `flex-direction: ${props.flexDirection}` : '';

    return css`
      ${items}
      ${content}
      ${direction}
    `
  }};
`;

export const ModalContent = styled.div`
  background-color: transparent;
  position: absolute;
  border: none;
  ${(props) => {
    const { getMedias: { laptops, iphones, desktops } } = props.theme;
    return `
      ${laptops()`
          height: 85%;
          width: 70vw;
          top: 7.5vh;
          left: 14.5vw;
        `
      }
      ${iphones()`
          height: 100%;
          width: 100vw;
        `
      }
      ${desktops()`
          height: 85%;
          width: 50vw;
          top: 7.5vh;
          left: 25vw;
        `
      }
    `;
  }}
`;

export const ChoseEntityButton = styled(BaseScaleButton)`
  background-color: transparent;
  border-radius: 0;
  border-color: ${props => props.theme['color_white']};
  border-width: 1px;
  color: ${props => props.theme['color_white']};
  text-align: center;

  ${(props) => {
    const { getMedias: { iphones } } = props.theme;
    return `
      ${iphones()`
          height: 4rem;
          width:  10rem;
          margin-top: 5rem
        `
      }
    `;
  }}

  &:hover {
    background-color: transparent;
    border-color: ${props => props.theme['color_white']};
  }
`;

export const ChoseEntityButtonsContainer = styled(FlexContainer)`
  align-items: flex-start;
  justify-content: center;
  background-color: transparent;

  ${(props) => {
    const { getMedias: { laptops, iphones, desktops } } = props.theme;
    return `
      ${laptops()`
          align-items: flex-start;
          justify-content: center;
        `
      }
      ${iphones()`
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
        `
      }
      ${desktops()`
          align-items: flex-start;
          justify-content: center;
        `
      }
    `;
  }}
`;

export const ChoseEntityLabel = styled.p`
  align-self: center;
  color: ${props => props.theme['color_white']};
  margin-bottom: 0.5rem;

  ${(props) => {
    const { getMedias: { laptops, iphones, desktops } } = props.theme;
    return `
      ${laptops()`
          margin-top: 2rem;
        `
      }
      ${iphones()`
          margin-top: 1rem;
        `
      }
      ${desktops()`
          margin-top: 2rem;
        `
      }
    `;
  }}
`;

export const ModalCloseButton = styled.img.attrs({ src: close, alt: 'closeSVG' })`
  position: absolute;
  right: .5rem;
  z-index: 1000;
  background-color: transparent;
  cursor: pointer;
  ${(props) => {
    const { getMedias: { laptops, iphones, desktops } } = props.theme;
    return `
      ${laptops()`
          height: 1.1rem;
          width: 1.1rem;
        `
      }
      ${iphones()`
          margin: 1.1rem 1rem 0 0;
          height: 1.1rem;
          width: 1.1rem;
        `
      }
      ${desktops()`
          height: 1.1rem;
          width: 1.1rem;
        `
      }
    `;
  }}
`;

export const SubmitButton = styled(BaseScaleButton)`
  background-color: ${props => {
    if (props.disabled) {
      return props.theme.color_gray;
    }

    return props.theme.color_purple;
  }};
  padding: .4rem 1.5rem .4rem 1.5rem;
  border-radius: 0;
  font-weight: 400;
  border-color: transparent;
  ${props => css`${props.customstyle}`};
`;

export const SuccessCheck = styled.img.attrs({ src: check, alt: 'checkSVG' })`
  ${(props) => {
    const { getMedias: { laptops, iphones, desktops } } = props.theme;
    return `
      ${laptops()`
          height: 1.5rem;
          width: 1.5rem;
        `
      }
      ${iphones()`
          height: 1.5rem;
          width: 1.5rem;
        `
      }
      ${desktops()`
          height: 1.5rem;
          width: 1.5rem;
        `
      }
    `;
  }}
`

export const AuthModal = styled(Modal)`
  div.modal-content {
    background-color: transparent;
    border: none;
    ${props => css`${props.contentStyle}`}
  }
`;

export const SuccessText = styled.span`
  text-align: center;
  color: ${props => props.theme.color_white};
  font-weight:  ${props => props.bold ? 'bold' : 'normal'};
`;
