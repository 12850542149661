import React, { PureComponent } from 'react';

import ModalContainer from '../../../../components/shared/modal/ModalContainer';
import { PrimaryButton } from '../../../../components/shared/sharedStyles.styles';
import { Container, TitleModal, TextModal, ContainerButton } from './usersCreateCancel.styled';

class UsersCreateCancelModal extends PureComponent {

  render() {
    const { isOpen, closeModal, onGoBack } = this.props;
    return (
      <ModalContainer
        isOpen={isOpen}
        size="md"
        onClose={closeModal}
      >
        <Container>
          <TitleModal>ATENÇÃO</TitleModal>
          <TextModal>Tem certeza que deseja cancelar o cadastro do novo usuário?</TextModal>
          <ContainerButton>
            <PrimaryButton onClick={closeModal}>Não</PrimaryButton>
            <PrimaryButton onClick={onGoBack}>Sim</PrimaryButton>
          </ContainerButton>
        </Container>
      </ModalContainer>
    );
  }
}

export default UsersCreateCancelModal
