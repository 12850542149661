import React, { Component } from 'react';
const AddBlue = require('../../../img/png/ico-add.png');

export default class Add extends Component {
  render() {
    return (
      <img src={AddBlue} alt="" />
    )
  }
}
