export const getAllValues = (allForms) => {
  const values = Object.keys(allForms).reduce((acc, form) => {
    if (allForms[form].values !== undefined) {
      return { ...acc, ...allForms[form].values };
    }

    return acc;
  }, {});

  return values;
}

export const getAllValuesByForm = (allForms) => {
  const values = Object.keys(allForms).reduce((acc, form) => {
    if (allForms[form].values !== undefined) {
      const newAcc = acc;
      newAcc[form] = allForms[form].values;

      return newAcc;
    }

    return acc;
  }, {});

  return values;
}

export const getRequiredFieldsWithError = (allForms) => {
  const syncErrors = Object.keys(allForms).reduce((acc, form) => {
    if (allForms[form].syncErrors !== undefined) {
      return { ...acc, ...allForms[form].syncErrors };
    }

    return acc;
  }, {});

  return syncErrors;
}

export const getNonRequiredFieldsWithWarning = (allForms) => {
  const syncWarnings = Object.keys(allForms).reduce((acc, form) => {
    if (allForms[form].syncWarnings !== undefined) {
      return { ...acc, ...allForms[form].syncWarnings };
    }

    return acc;
  }, {});

  return syncWarnings;
}

export const getNonRequiredFieldsWithWarningByForm = (allForms) => {
  const syncWarnings = Object.keys(allForms).reduce((acc, form) => {
    if (allForms[form].syncWarnings !== undefined) {
      const newAcc = acc;
      newAcc[form] = allForms[form].syncWarnings;

      return newAcc;
    }

    return acc;
  }, {});

  return syncWarnings;
}

export const getFieldsLabel = (keysSource, usedFields) => {
  let labels = [];
  if (Array.isArray(keysSource)) {
    if (keysSource.length > 0) {
      labels = keysSource.map((key) => usedFields[key] !== undefined ? usedFields[key].label : "");
    }
  } else if (typeof keysSource === 'object') {
    const keys = Object.keys(keysSource);

    if (keys.length > 0) {
      labels = Object.keys(keysSource).map((key) => {
        if (key === "telefone_principal") {
          return "Telefone";
        }

        if (key === "telefone_adicional") {
          return "Telefone adicional";
        }
        
        return usedFields[key].label;
      });
    }
  }

  return labels;
}