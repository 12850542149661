import React, { PureComponent } from 'react';

import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import RenderFields from '../../shared/RenderFields';
import RenderServicesFields from './RenderServicesFields';

import interestFields from './interestFields';
import servicesFields from './servicesFields';

class InterestSection extends PureComponent {

  renderLeftContent = () => {
    const { initialValues, master } = this.props;
    const hasIndication = initialValues.como_conheceu === "INDICACAO"? true : false;
    const fields = interestFields(hasIndication, master);
    
    return <RenderFields fields={fields} />;
  }

  renderRightContent = () => {
    return <RenderServicesFields fields={servicesFields} master={this.props.master} />;
  }

  render() {
    return (
      <StyledRow margin="1rem 0 0 0">
        <BaseColumn sm={12} md={6} lg={6} xl={6} align="flex-start" margin="0 0 1.3rem 0">
          {this.renderLeftContent()}
        </BaseColumn>
        <BaseColumn sm={12} md={6} lg={6} xl={6}>
          {this.renderRightContent()}
        </BaseColumn>
      </StyledRow>
    );
  }
}

export default InterestSection;
