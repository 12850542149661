import { toastr } from 'react-redux-toastr';
import { updateClientCredit } from '../../../../services/customer/customer';
import { actionCreator, generalActionTypes, extractErrorMessages } from '../../../../utils/reduxUtils';
import { createPersonalEntityObj, createLegalEntityObj, updateClientComplementaryFields } from '../../../../utils/formHelper';
import { getCreditBalances } from '../../../../utils/clientsTableHelper';
import { editEmployeeCredit, getClientEmployees } from '../../tables/actions/employees';
import { getClientProducts } from '../../tables/actions/products';
import { validateExtraContacts } from '../../../../utils/validations';
import { omit } from 'lodash';
import { change } from 'redux-form';
import { getUsers } from "../../../users/actions";
import {
  REGISTER_CLIENT_FAILED,
  REGISTER_CLIENT_REQUEST,
  REGISTER_CLIENT_SUCCESS,
  OPEN_PRE_REGISTERED_MODAL,
  ClOSE_PRE_REGISTERED_MODAL,
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILED,
  UPDATE_NOT_LINK_CREDIT_BALANCE,
  UPDATE_AVALIABLE_CREDIT_BALANCE,
  UPDATE_EMPLOYEE_CREDIT,
  CLEAN_COLLABORATOR,
  CLEAN_CLIENT_CACHE,
  SAVE_STATUS_EDIT
} from './types';
import { LOGOUT_SUCCESS } from '../../../../pages/auth/login/actions/types';
import { GET_REQUIREMENTS } from '../../../form-requirements/actions/types';
import { push, replace } from 'connected-react-router';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const changeCreditBalances = () => {
  return (dispatch, getState) => {
    const { client, customerData } = getState();
    const credito = parseFloat(customerData.data.cliente.credito);
    const balances = getCreditBalances(customerData.data.cliente.funcionarios, credito);
    dispatch(actionCreator(UPDATE_AVALIABLE_CREDIT_BALANCE, balances.avaliableBalance));
    dispatch(actionCreator(UPDATE_NOT_LINK_CREDIT_BALANCE, balances.notLinkedBalance));
  }
}

export const changeEmployeeCredit = (id, value) => {
  return (dispatch, getState) => {
    const { client, customerData } = getState();
    const credito = parseFloat(customerData.data.cliente.credito);
    const newList = customerData.data.cliente.funcionarios.filter(emp => emp.id !== id);
    const { notLinkedBalance } = getCreditBalances(newList, credito);

    if (value > credito) {
      toastr.error('Limite', 'Valor atribuído não deve ser maior que o limite disponível do cliente');
      dispatch(getUsers());
    } else if (value > notLinkedBalance) {
      toastr.error('Limite', 'Valor atribuído não deve ser maior que o limite disponível não Vinculado');
      dispatch(getUsers());
    } else {
      dispatch(actionCreator(UPDATE_EMPLOYEE_CREDIT, { id, value }));
      setTimeout(() => {
        dispatch(changeCreditBalances());
        dispatch(editEmployeeCredit(id, value));
      }, 300);
    }
  }
}

export const editClientCredit = (credit) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } }, client: { client: { infos } } } = getState();

    try {
      const payload = await updateClientCredit(token.access_token, infos.id, credit);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.error('Limite Disponível Total', 'Não foi possivel editar');
        }

      } else {
        toastr.success('Limite Disponível Total', 'Editado com sucesso');
      }
    } catch (e) {
      toastr.error('Limite Disponível Total', 'Não foi possivel');
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};
