import React from 'react';
import { Th } from '@bit/wavfichacerta.fichacerta.tables.styles';
const TableHeader = () => (
  <tr style={{color: '#000'}}>
    <Th>
      Data
    </Th>

    <Th>
      Quantidade
    </Th>

    <Th>
      Alínea
    </Th>

    {/* <Th>
      N° do Cheque
    </Th> */}

    {/* <Th align="right">
      Valor
    </Th> */}

    <Th>
      Banco
    </Th>

    <Th>
      Agência
    </Th>

    {/* <Th>
      Cidade/UF
    </Th> */}
  </tr>
);

export default TableHeader;
