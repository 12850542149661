import { simpleDisabledText } from '@bit/wavfichacerta.fichacerta.fields-templates.simple-text';

const sizes = {
  responsavel: "field-fc field-fc-sm",
  status: "field-fc field-fc-xs",
  condicao: "field-fc field-fc-xs",
  observacao: "field-fc field-fc-l",
}

export default [
  {
    name: 'firstLine',
    fields: [
      simpleDisabledText({ size: sizes.responsavel, name: 'responsavel_comercial', label: 'Responsável comercial (análise cadastral)' }),
      simpleDisabledText({ size: sizes.status, name: 'status_cliente', label: 'Status do cliente' }),
      simpleDisabledText({ size: sizes.condicao, name: 'condicao_pagamento', label: 'Condição de pagto' }),
      simpleDisabledText({ size: sizes.observacao, name: 'observacao_cliente', label: 'Observações do cliente' }),
    ]
  }
];
