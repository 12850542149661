import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Welcome from '../pages/common/dashboard/Welcome';

export default () => (
  <Switch>
    <Route exact path="/intranet/dashboard" component={Welcome} />
  </Switch>
);
