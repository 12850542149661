import React, { PureComponent } from 'react';
import SaveSVG from "../svg/Save";
import CancelSVG from '../svg/Cancel';
import SubmitButton from '@bit/wavfichacerta.fichacerta.forms-buttons.submit-button';
import Button from './Button';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BtHeader, ButtonLink } from './buttons.styled';

import BackArrow from '@bit/wavfichacerta.fichacerta.icons.back-arrow';
import Edit from '@bit/wavfichacerta.fichacerta.icons.edit';

export default class ButtonsHeader extends PureComponent {

	static defaultProps = {
		formName: '',
		cancelIcon: 'default',
		confirmIcon: 'default',
		activeColor: 'color_green'
	}

	leftButtonLabel = () => {
		switch (this.props.cancelIcon) {
			case 'visualizar':
				return 'voltar';
			default:
				return 'cancelar';
		}
	}

	renderCancelButton = () => {
		switch (this.props.cancelIcon) {
			case 'visualizar':
				return <BackArrow width="1.5rem !important" />;
			default:
				return <CancelSVG />;
		}
	}

	renderConfirmButton = () => {
		let icon;
		if (this.props.formName) {
			const SubmitButtonComponent = SubmitButton(Button, { form: this.props.formName, action: this.props.confirm, typeButton: 'rounded', icon: this.props.confirmIcon });
			return <SubmitButtonComponent />
		} else {
			let nameButton = 'salvar';
			switch (this.props.confirmIcon) {
				case 'visualizar':
					icon = <Edit width="1.25rem !important" />;
					nameButton = 'editar';
					break;
				default:
					icon = <SaveSVG width="1rem" />;
					break;
			}
			return (
				<BtHeader>
					<ButtonLink role="button" onClick={this.props.confirm}>
						{icon}
					</ButtonLink>
					<span>{nameButton}</span>
				</BtHeader>
			)
		}
	}

	render() {
		return (
			<StyledRow justify="space-between">

				<BtHeader onClick={this.props.cancel}>
					<ButtonLink role="button" className="ico-save">
						{this.renderCancelButton()}
					</ButtonLink>
					<span>{this.leftButtonLabel()}</span>
				</BtHeader>

				{this.renderConfirmButton()}

			</StyledRow>
		)
	}
}
