import React from 'react';
import PropTypes from 'prop-types';
import { SubmitButton } from '../sign-up/signup.styles';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';

const styles = {
  loginWrapper: {
    textAlign: 'center',
    height: 'auto',
    backgroundColor: 'transparent'
  }
};

const FormWrapper = (props) => {
  return (
    <StyledRow>
      <BaseColumn
        {...props.size}
        customstyle={styles.loginWrapper}
      >
        {props.header}

        {props.children}

        {props.hasSubmit && 
          <StyledRow>
            <SubmitButton
                disabled={props.submitDisabled}
                onClick={props.onSubmit}
                customstyle={{ color: 'white', padding: '.3rem 1.5rem .3rem 1.5rem', borderColor: 'transparent', fontWeight: 300 }}
              >
              {props.submitLabel || 'Salvar'}
            </SubmitButton>
          </StyledRow>
        }

        {props.footer}
      </BaseColumn>
    </StyledRow>
  );
}

FormWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

FormWrapper.defaultProps = {
  hasSubmit: true,
  header: null,
  footer: null,
  size: {
    xl: 7,
    lg: 7,
    md: 7,
    sm: 7,
    col: 7
  }
}

export default FormWrapper;