import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchCEP } from '../../../pages/validation/actions';
import CustomMaskedInput from '../CustomMaskedInput';

class CEPField extends PureComponent {
  state = {}
  
  static getDerivedStateFromProps (nextProps) {
    const { input: { value }, meta: { valid, dirty, form, warning }, fetchCEP, path } = nextProps;
    
    if (valid && dirty && !warning && value.length === 8) {
      fetchCEP(value, form, path);
    }

    return null;
  }

  render() {
    return (
      <CustomMaskedInput
       {...this.props}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ fetchCEP }, dispatch);

export default connect(null, mapDispatchToProps)(CEPField);
