import styled, {css} from 'styled-components';

export const Tr = styled.tr`
  &:hover {
  cursor: ${props => props.clickable? 'pointer' : 'initial'};
    background-color: ${props => props.theme['table_line-hover']} !important;
  }
`;

export const Th = styled.th`
  text-transform: ${props => props.uppercase? 'uppercase' : 'initial'};
  width: ${props => props.width || 'auto'};
  min-width: ${props => props.minWidth || 'auto'};
  max-width: ${props => props.maxWidth || 'auto'};
  text-align: ${props => props.textAlign? props.textAlign : 'left'};
  padding-left: ${props => props.paddingLeft || 0};
  border-bottom: 0 !important;
  
`;

export const Td = styled.td`
  text-transform: ${props => props.uppercase? 'uppercase' : 'initial'};
  padding: 0 .5rem !important;
  width: ${props => props.width || 'auto'};
  border-right: ${props => props.theme[props.bordercolor || 'color_white']} solid .1rem;
  border-top: 0;
  vertical-align: ${props => props.align? `${props.align} !important` : 'middle'};
  height: 2.7rem !important;
  min-width: ${props => props.minWidth || 'auto'};
  max-width: ${props => props.maxWidth || 'auto'};
  text-align: ${props => props.textAlign? props.textAlign : 'left'};
  ${props => css`${props.customStyle}`};
`;

export const BorderedButton = styled.button`
  width: 5rem;
  background-color: transparent;
  font-size: .78rem;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  padding: 0 .1rem;
  height: 23px;
  border: 1px solid ${props => props.color? props.theme[props.color] : props.theme.color_purple};
  color: ${props => props.color? props.theme[props.color] : props.theme.color_purple};
  transform: scale(1);
  transition: all 100ms ease-in;
  cursor: pointer;

  @media(max-width: 800px){
    width: 5.3rem;
  }

&:hover {
    background-color: ${props => props.color? props.theme[props.color] : props.theme.color_purple};
    color: ${props => props.theme.color_white};
    transform: scale(1.1);
    font-weight: normal;
  }
`;

export const UnborderedButton = styled.button`
  width: 5rem;
  background-color: transparent;
  font-size: .88rem;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  padding: 0 .5rem;
  height: 23px;
  border: none;
  color: ${props => props.color? props.theme[props.color] : props.theme.color_purple};
  transform: scale(1);
  transition: all 100ms ease-in;
  cursor: pointer;

/* &:hover {
    background-color: ${props => props.color? props.theme[props.color] : props.theme.color_purple};
    color: ${props => props.theme.color_white};
    transform: scale(1.1);
    font-weight: normal;
  } */
`;

export const BalanceTh = styled(Th)`
  width: 7.3vw !important;
`;

export const CreditTh = styled(Th)`
  width: 10vw !important;
`;

export const EditCreditTh = styled(Th)`
  width: 4vw !important;
`;


