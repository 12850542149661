import React, {Component} from "react";
import SideContent from "./charge-components/SideContent";
import Header from '../../../components/Header';
// import { PageContainer, MainContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';
import ChargeTable from "./charge-components/ChargeTable";
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { TableWrapper } from "./charge-components/ChargeStyles";
import { bindActionCreators } from 'redux';
import { getChargeData, resetFilters } from './actions';
import { connect } from 'react-redux';
import { Main, MainContainer } from './../../operational/request/resume/RequestResume';

import Tour from 'reactour'
import axios from 'axios';
import { instance as axiosInstance } from './../../../services/axiosInstance';

const steps = [
  {
    selector: 'table tr',
    content: 'Confira aqui o consumo por período de utilização da FC ANALISE.',
  },
  {
    selector: '.date-container',
    content: 'Selecione o período desejado',
  },
  {
    selector: '.button-filtrar',
    content: 'Clique em filtrar para obter as informações',
  },
  {
    selector: '.value-container',
    content: 'Aqui você observa o valor consumido no período',
  },
  {
    selector: '.imprimir-pdf',
    content: 'Você pode salvar em PDF',
  },
  {
    selector: '.salvar-excel',
    content: 'Ou mesmo exportar para o excel, lembrando que o arquivo contém o nome dos usuários que fizeram a solicitação, data e horário da análise. O que facilita caso queira acompanhar o consumo por usuário.',
  },  
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class Charge extends Component {
  state = {
    filtered: false,
    tourOpen: false,
    tourId: null,
    perfisConsumoTable: {}
  }

  componentWillUnmount(){
    this.props.resetFilters()
  }

  componentDidMount(){
    this.props.getChargeData();
    this.getCheckOnboard();
    this.getPerfisConsumo();
  }

  getPerfisConsumo = async () => {
    try {
      const URL = '/api/administrador/perfisconsumo'

      const token = this.props.login.user.token.access_token

      const headers = {
        'Authorization': `Bearer ${token}`
      }

      const result = await axiosInstance.get(URL, {headers});
      if(result.status === 401) {
        this.props.history.push('/auth')
      }

      const response = result.data.data;
      const produtos = await this.getAdministrador();
      console.log('produtoszzxxx', produtos)
      const produtosIds = produtos.map(produto => produto.id)
      // if(this.props.login.user.usuario.master) {
      //   if(!produtosIds.includes(1)) {
      //     produtosIds.push(1)
      //   }
      //   if(!produtosIds.includes(2)) {
      //     produtosIds.push(2)
      //   }
      //   if(!produtosIds.includes(3)) {
      //     produtosIds.push(3)
      //   }
      //   if(!produtosIds.includes(4)) {
      //     produtosIds.push(4)
      //   }
      //   if(!produtosIds.includes(5)) {
      //     produtosIds.push(5)
      //   }
      //   if(!produtosIds.includes(6)) {
      //     produtosIds.push(6)
      //   }
      // }
      let perfis = {}
      response.map(item => {
        if(item.produto_id && produtosIds.includes(item.produto_id)) {
          if(perfis[`product_id-${item.produto_id}`]) {
            perfis[`product_id-${item.produto_id}`].push(item)
            perfis['perfil_consumo_name'].push(item)
            perfis['perfil_consumo_minimo'].push(item)
          } else {
            perfis[`product_id-${item.produto_id}`] = [item]
            perfis['perfil_consumo_name'] = [item]
            perfis['perfil_consumo_minimo'] = [item]
          }
        }
      })
      this.setState({perfisConsumoTable: perfis})
    } catch(err) {
      console.log('ERR:', err)
    }
  }

  getAdministrador = async () => {
    try {
      const URL = '/api/administrador?relations=true&information=true'

      const token = this.props.login.user.token.access_token

      const headers = {
        'Authorization': `Bearer ${token}`
      }

      const result = await axiosInstance.get(URL, {headers});
      if(result.status === 401) {
        this.props.history.push('/auth')
      }
      const response = result.data.data.cliente.a_vista ? result.data.data.cliente.produtos_habilitados : result.data.data.cliente.produtos;
      return response;
    } catch(err) {
      console.log('ERR:', err)
      throw err
    }
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'cobranca') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'cobranca'
      }

      const result = await axios.post(URL, data);
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'cobranca',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleCallBackSubmitFilter = () => {
    this.setState({filtered: true})
  }

  renderSideContent(){
    const {periodo} = this.props.resume
    const {filtered} = this.state
    if(periodo){
      return <SideContent filtered={filtered} callback={this.handleCallBackSubmitFilter} />
    }else{
      return null
    }
  }

  renderLines = (props) => {
    let data = {};
    let product_name = ''
    props.map((item, index) => {
      product_name = item.produto.titulo;
      if(item.nivel === 'A_VISTA' && item.ativo) {
        data.nivel_a_vista = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: parseFloat(item.valor) ? item.valor.toString().replace('.', ',') : item.produto.valor_a_vista.toString().replace('.', ',')
        }
      }
      if(item.nivel === 'BRONZE' && item.ativo) {
        data.nivel_1 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: parseFloat(item.valor) ? item.valor.toString().replace('.', ',') : item.produto.valor_bronze.toString().replace('.', ',')
        }
      }
      if(item.nivel === 'PRATA' && item.ativo) {
        data.nivel_2 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: parseFloat(item.valor) ? item.valor.toString().replace('.', ',') : item.produto.valor_prata.toString().replace('.', ',')
        }
      }
      if(item.nivel === 'OURO' && item.ativo) {
        data.nivel_3 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: parseFloat(item.valor) ? item.valor.toString().replace('.', ',') : item.produto.valor_ouro.toString().replace('.', ',')
        }
      }
      if(item.nivel === 'DIAMANTE' && item.ativo) {
        data.nivel_4 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: parseFloat(item.valor) ? item.valor.toString().replace('.', ',') : item.produto.valor_diamante.toString().replace('.', ',')
        }
      }
    })
    return(
      <div className='render-product-prices' style={{borderBottom: '1px solid #b2a4a430', display: 'flex', padding: '4px 0px'}}>
        <span style={{paddingLeft: '0.5rem', width: '20%', fontSize: 15, fontWeight: 600, borderLeft: '1px solid #b2a4a430'}}>{product_name}</span>
        {data.nivel_a_vista && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430', position: 'relative'}} className={data.nivel_a_vista.referencia && 'borderFaixaReferencia'}>R$ {data.nivel_a_vista.valor}<div></div></span>}
        {data.nivel_1 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430', position: 'relative'}} className={data.nivel_1.referencia && 'borderFaixaReferencia'}>R$ {data.nivel_1.valor}<div></div></span>}
        {data.nivel_2 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430', position: 'relative'}} className={data.nivel_2.referencia && 'borderFaixaReferencia'}>R$ {data.nivel_2.valor}<div></div></span>}
        {data.nivel_3 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430', position: 'relative'}} className={data.nivel_3.referencia && 'borderFaixaReferencia'}>R$ {data.nivel_3.valor}<div></div></span>}
        {data.nivel_4 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430', position: 'relative', borderRight: '1px solid #b2a4a430'}} className={data.nivel_4.referencia && 'borderFaixaReferencia'}>R$ {data.nivel_4.valor}<div></div></span>}
        {/* {(props.length - 1) === index && 
          <div>cola aqui</div>
        } */}
      </div>
    )
  }

  renderLinesTitles = (props) => {
    let data = {};
    let product_name = 'Faixa de Consumo'
    props.map(item => {
      if(item.nivel === 'A_VISTA' && item.ativo) {
        data.nivel_a_vista = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: item.consumo_minimo.toString().replace('.', ','),
          nome: item.nome || item.produto.nome_a_vista
        }
      }
      if(item.nivel === 'BRONZE' && item.ativo) {
        data.nivel_1 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: item.consumo_minimo.toString().replace('.', ','),
          nome: item.nome || item.produto.nome_bronze
        }
      }
      if(item.nivel === 'PRATA' && item.ativo) {
        data.nivel_2 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: item.consumo_minimo.toString().replace('.', ','),
          nome: item.nome || item.produto.nome_prata
        }
      }
      if(item.nivel === 'OURO' && item.ativo) {
        data.nivel_3 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: item.consumo_minimo.toString().replace('.', ','),
          nome: item.nome || item.produto.nome_ouro
        }
      }
      if(item.nivel === 'DIAMANTE' && item.ativo) {
        data.nivel_4 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: item.consumo_minimo.toString().replace('.', ','),
          nome: item.nome || item.produto.nome_diamante
        }
      }
    })
    return(
      <div className='render-product-prices' style={{borderBottom: '1px solid #b2a4a430', display: 'flex', backgroundColor: '#152b4e61', padding: '4px 0px'}}>
        <span style={{paddingLeft: '0.5rem', width: '20%', fontSize: 15, fontWeight: 600, borderLeft: '1px solid #b2a4a430'}}>{product_name}</span>
        {data.nivel_a_vista && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430'}} className={data.nivel_a_vista.referencia && 'borderFaixaReferencia'}>{data.nivel_a_vista.nome}<div></div></span>}
        {data.nivel_1 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430'}} className={data.nivel_1.referencia && 'borderFaixaReferencia'}>{data.nivel_1.nome}<div></div></span>}
        {data.nivel_2 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430'}} className={data.nivel_2.referencia && 'borderFaixaReferencia'}>{data.nivel_2.nome}<div></div></span>}
        {data.nivel_3 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430'}} className={data.nivel_3.referencia && 'borderFaixaReferencia'}>{data.nivel_3.nome}<div></div></span>}
        {data.nivel_4 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430', borderRight: '1px solid #b2a4a430'}} className={data.nivel_4.referencia && 'borderFaixaReferencia'}>{data.nivel_4.nome}<div></div></span>}
      </div>
    )
  }

  renderLinesMinimo = (props) => {
    let data = {};
    let product_name = 'Mínimo'
    props.map(item => {
      if(item.nivel === 'A_VISTA' && item.ativo) {
        data.nivel_a_vista = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: '0,00'
        }
      }
      if(item.nivel === 'BRONZE' && item.ativo) {
        data.nivel_1 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: parseFloat(item.consumo_minimo) ? item.consumo_minimo.toString().replace('.', ',') : item.produto.consumo_minimo_bronze.toString().replace('.', ',')
        }
      }
      if(item.nivel === 'PRATA' && item.ativo) {
        data.nivel_2 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: parseFloat(item.consumo_minimo) ? item.consumo_minimo.toString().replace('.', ',') : item.produto.consumo_minimo_prata.toString().replace('.', ',')
        }
      }
      if(item.nivel === 'OURO' && item.ativo) {
        data.nivel_3 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: parseFloat(item.consumo_minimo) ? item.consumo_minimo.toString().replace('.', ',') : item.produto.consumo_minimo_ouro.toString().replace('.', ',')
        }
      }
      if(item.nivel === 'DIAMANTE' && item.ativo) {
        data.nivel_4 = {
          nome: item.produto_id,
          nivel: item.nivel,
          referencia: item.referencia,
          valor: parseFloat(item.consumo_minimo) ? item.consumo_minimo.toString().replace('.', ',') : item.produto.consumo_minimo_diamante.toString().replace('.', ',')
        }
      }
    })
    return(
      <div className='render-product-prices' style={{borderBottom: '1px solid #b2a4a430', display: 'flex', backgroundColor: '#0000000d', padding: '4px 0px'}}>
        <span style={{paddingLeft: '0.5rem', width: '20%', fontSize: 15, fontWeight: 600, borderLeft: '1px solid #b2a4a430'}}>{product_name}</span>
        {data.nivel_a_vista && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430'}} className={data.nivel_a_vista.referencia && 'borderFaixaReferencia'}>R$ {data.nivel_a_vista.valor}</span>}
        {data.nivel_1 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430'}} className={data.nivel_1.referencia && 'borderFaixaReferencia'}>R$ {data.nivel_1.valor}</span>}
        {data.nivel_2 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430'}} className={data.nivel_2.referencia && 'borderFaixaReferencia'}>R$ {data.nivel_2.valor}</span>}
        {data.nivel_3 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430'}} className={data.nivel_3.referencia && 'borderFaixaReferencia'}>R$ {data.nivel_3.valor}</span>}
        {data.nivel_4 && <span style={{width: '20%', textAlign: 'center', fontSize: 15, fontWeight: 600, color: 'red', borderLeft: '1px solid #b2a4a430', borderRight: '1px solid #b2a4a430'}} className={data.nivel_4.referencia && 'borderFaixaReferencia'}>R$ {data.nivel_4.valor}</span>}
      </div>
    )
  }

  renderTablePrices = () => {
    const { perfisConsumoTable } = this.state
    const keys = [
      'perfil_consumo_name',
      'perfil_consumo_minimo',
      'product_id-5',
      'product_id-3',
      'product_id-1',
      'product_id-4',
      'product_id-2',
      'product_id-6',
      'product_id-7',
      'product_id-8',
    ]
    return(
      <div style={{marginBottom: 60}}>
        {
          keys.map(item => {
            if(perfisConsumoTable[item] && perfisConsumoTable[item].length) {
              if(item === 'perfil_consumo_name') {
                return this.renderLinesTitles(perfisConsumoTable[item])
              }
              if(item === 'perfil_consumo_minimo') {
                return this.renderLinesMinimo(perfisConsumoTable[item])
              }
              return this.renderLines(perfisConsumoTable[item])
            }
          })
        }
      </div>
    )
  }

  render(){
    return (
      <Main>
        <Header 
          title="Relatório"
          actualLink="Relatório"
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <MainContainer>
          <StyledRow justify="flex-start" align="flex-start" >
            <BaseColumn xs="12" sm="12" md="3" lg="3" xl="3">
              {this.renderSideContent()}
            </BaseColumn>
            <TableWrapper xs="12" sm="12" md="9" lg="9" xl="9">
              <ChargeTable />
              <div style={{marginTop: 50}}>
                {/* <h6>TABELA DE PRECOS</h6> */}
                <div>
                  <div>
                    {this.props.login.user.usuario.master && this.renderTablePrices()}
                  </div>
                </div>
              </div>
            </TableWrapper>
          </StyledRow>
        </MainContainer>
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
        />
      </Main>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({getChargeData, resetFilters}, dispatch)

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  resume: state.chargeData.resume,
  login: state.login,
})

export default connect(mapStateToProps, mapDispatchToProps)(Charge);