import React, { PureComponent } from 'react';

import {
  Wrapper,
  ContainerIcon,
  TitleLoading,
  TextLoading
} from './containerLoading.styled';
import Loading from '@bit/wavfichacerta.fichacerta.icons.loading';
import ReportAlert from '@bit/wavfichacerta.fichacerta.icons.report-alert';

export default class ContainerLoading extends PureComponent {
  static defaultProps = {
    size: 'small',
    text: 'Em alguns instantes a ANALISE será concluída.',
    textLoading: "Processamento mais lento que o habitual...",
    textLoadingExpired: "Alguma de nossas capturas de dados está passando por instabilidade. Aguarde alguns minutos e atualize a tela (tecla F5) para verificar a conclusão dos laudos, ou retorne ao resumo da solicitação. Pedimos desculpas pelo transtorno.",
    showIcon: true,
    showFirstIcon: true,
    showSecondIcon: true,
    showText: true,
    fullWidth: true,
    lightAlert: false,
    margin: 'auto'
  }

  state = {
    showTextSlow: false,
    expired: false
  }

  componentDidMount() {
    this.beginCounter();
  }

  beginCounter = () => {
    setTimeout(() => {
      this.setState({ showTextSlow: true });
      setTimeout(() => {
        this.setState({ showTextSlow: false, expired: true });
      }, 20000);
    }, 40000);
  }

  renderText = () => {
    const { showText, text, textLoading, textLoadingExpired } = this.props;
    const { expired, showTextSlow } = this.state;
    if (showText) {
      if (showTextSlow) {
        return (<TextLoading data-testid="loadingText" style={{color: '#000'}}>{ textLoading }</TextLoading>);
      }

      if (expired) {
        return (<TextLoading data-testid="loadingText" style={{color: '#000'}}>{ textLoadingExpired }</TextLoading>);
      }

      return (<TextLoading data-testid="loadingText" style={{color: '#000'}}>{ text }</TextLoading>);
    }
    return null;
  }

  renderIcon = () => {
    const { showIcon, showFirstIcon, showSecondIcon, showText, lightAlert } = this.props;
    const { expired } = this.state;
    if (showIcon) {
      if (expired && showSecondIcon) {
        const color = lightAlert? "color_border-light-gray" : "color_border-gray";
        return (<ReportAlert width="95px" height="95px" color={color} margin="0 auto" />);
      }

      if (showFirstIcon && !showText && !expired) {
        return (<Loading width="55px" height="55px" color="color_border-gray" margin="0 auto" />);
      }
      
      if (showIcon && showFirstIcon && showText && showText) {
        return (
          <ContainerIcon>
            <TitleLoading style={{color: '#000'}}>PROCESSANDO</TitleLoading>
            <Loading width="55px" height="55px" color="color_border-gray" margin="0 auto" />
          </ContainerIcon>
        );
      }
    }
    return null;
  }

  render() {
    const { size, fullWidth, margin } = this.props;
    return (
      <Wrapper size={size} fullWidth={fullWidth} margin={margin}>
        { this.renderIcon() }
        { this.renderText() }
      </Wrapper>
    );
  }
}
