import React, { PureComponent } from 'react'
import FormWrapper from './FormWrapper';
import LoginHeader from './LoginHeader';
import LoginFormFieldContainer from './LoginFormFieldContainer';
import LoginResponseContainer from '../../pages/auth/login/LoginResponseContainer';
import {  StyledInput } from '@bit/wavfichacerta.fichacerta.inputs-styles.inputs-styles';
import { FullColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { ForgotPasswordButton } from './login.styles';

import Tour from 'reactour'
import axios from 'axios'

const steps = [
  {
    selector: '.row',
    content: 'Digite seu email e senha já cadastrados no nosso sistema',
  },
  {
    selector: '.btn-secondary',
    content: 'Caso esqueça sua senha clique aqui, informe seu e-mail que enviaremos um link para criação de uma nova senha.',
  },
  {
    selector: '.whatsapp-button',
    content: 'Caso precise de ajuda chame nosso time através do WhatsApp',
  },
  // ...
]

const styles = {
  loginContent: {
    marginTop: '.5rem',
    textAlign: 'left',
    height: '50%'
  }
};

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}


class LoginForm extends PureComponent {
  state = {
    isButtonDisabled: false,
    email: '',
    password: '',
    tourOpen: false,
    tourId: null
  }

  componentDidMount() {
    this.handleVisibleTour()
  }

  handleVisibleTour = () => {
    const exists = localStorage.getItem('tour-login');
    if(!exists) {
      localStorage.setItem('tour-login', true)
      this.setState({tourOpen: true})
    }
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: '',
        page: 'login',
        name: '',
        cargo: '',
        customer: ''
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  onChange = (e) => {
    e.preventDefault();
    const { target: { name, value } } = e;
    this.setState({ [name]: value });
    if(value === 'reset-tour') {
      this.resetTourTemporary()
    }
  }

  renderForgotPasswordButton = () => (
    <FullColumn margin=".4rem 0 0 0">
      <div style={{color: '#ccc9c9', fontSize: 9.9}}>
        mínimo de 6 caracteres: letra maiúscula, minúscula e numeral
      </div>

      <ForgotPasswordButton tag="a" onClick={this.props.goToRecovery}>
        Esqueci e-mail ou senha
      </ForgotPasswordButton>
    </FullColumn>
  )

  whiteListAccess = (email) => {
    const emailsNonBlock = [
      'cliente@fichacerta.com.br',
      'marcello@terraz.com.br',
      'marcusadicional@teste.com',
      'marcus@terraz.com.br',
      'teste1@sol.com.br',
      'teste2@sol.com.br',
      'marcus@fichacerta.com.br',
      'marcello@fichacerta.com.br',
      'marcello.nicolau@gmail.com',
      'marcello.nicolau@bol.com.br',
      'mantoniuscosta@gmail.com',
      'raquelesquivelpereira@gmail.com',
      'wfelipo@gmail.com',
      'wcaldas_pub@outlook.com'
    ]

    if(emailsNonBlock.includes(email.trim())) {
      return true;
    }

    alert('Acesso não liberado, estamos em processo de atualização do sistema!')
    return false;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    // if (!this.whiteListAccess(email)) return;
    this.props.onSubmit(email, password);
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.onSubmit(event);
    }
  }

  resetTourTemporary = () => {
    localStorage.removeItem('tour-login')
    alert('Onboard resetado!')
  }

  render() {
    return (
      <FormWrapper
        submitDisabled={this.state.isButtonDisabled}
        onClose={this.props.onClose}
        onSubmit={this.onSubmit}
        header={<LoginHeader />}
        submitLabel="Entrar"
        size={{ md: 12, sm: 12, lg: 12, xl: 12 }}
        classes={{}}
      >
        <StyledRow justify="space-around" align="center" style={styles.loginContent}>
          <LoginResponseContainer />
          <LoginFormFieldContainer
            label="E-mail"
            name="email"
          >
            <StyledInput
              id="email"
              name="email"
              value={this.state.passwordConfirmation}
              onChange={this.onChange}
              type="text"
              required={true}
              onKeyPress={this.handleKeyPress}
            />
          </LoginFormFieldContainer>

          <LoginFormFieldContainer
            label="Senha"
            name="password"
            errorMessage={this.renderForgotPasswordButton()}
          >
            <StyledInput
              id="password"
              name="password"
              value={this.state.passwordConfirmation}
              onChange={this.onChange}
              type="password"
              required={true}
              onKeyPress={this.handleKeyPress}
            />
            
          </LoginFormFieldContainer>
      </StyledRow>
      <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={() => this.setState({tourOpen: false})}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
        />
      </FormWrapper>
    );
  }
}

export default LoginForm;
