
export const onOpenModalState = (state, payload) => {
    return {
        ...state,
        isVisible: true,
        selectedData: payload
    }
}

export const onCloseModalState = (state) => {
    return {
        ...state,
        isVisible: false,
        selectedData: {}
    }
}
