import styled from 'styled-components';
import Card from '@bit/wavfichacerta.fichacerta.cards.card';

export const Container = styled(Card)`
  padding: .25rem .5rem .25rem .5rem;
  width: ${props => props.width || '99%'};;
  margin: 1rem auto;
  
  @media(max-width: 800px){
    margin-top: 5.5rem;
    padding: .5rem;
    width: ${props => props.widthMobile || '100%'};
    background-color: #f8f6f6;
    box-shadow: none;
  }
`;

export const VerticalLine = styled.hr`
  width: 1px; 
  height: 40px;
  border-right: 1px solid #7f7f7f !important;
  margin: 0.1rem 20px;

  @media(min-width: 801px){
    display: none;
  }
`;
