import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'

export default class CustomTooltip extends Component {
  constructor() {
    super();
    this.tip = React.createRef();
  }

  state = {
    tooltipOpen: false,
    position: 'right',
    innerstyle: {}
  }

  static defaultProps = {
    target: 'defaultTarget',
    customstyle: {}
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  get arrowColor() {
    const { position } = this.props;

    if (position.search(/left/g) > -1) {
      return 'border-left-color';
    }

    if (position.search(/top/g) > -1) {
      return 'border-top-color';
    }

    if (position.search(/bottom/g) > -1) {
      return 'border-bottom-color';
    }

    return 'border-right-color';
  }

  

  render() {
    return (
      <div >
        <a data-tip data-for={this.props.target} margin="auto 2px">{this.props.children}</a>
        <ReactTooltip className='customTooltip' id={this.props.target} place="bottom" type="dark" effect="solid">
          {this.props.message}
        </ReactTooltip>
      </div>
    );
  }
}