import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import CardOld from '@bit/wavfichacerta.fichacerta.cards.card';
import FormContent from '../../../../../components/shared/form/FormContent';

import getTenancyFields from './tenancyFields';
import tenancyFields from './fields';
import tenancyFieldsFcBasico from './fieldsFcBasico';
import { getValues } from '../selectors';
import { formatToBRL } from '../../../../../utils/formatter';
import House from '@bit/wavfichacerta.fichacerta.icons.house';
import styled from 'styled-components'

const Card = styled(CardOld)`
  svg {
    fill: #152b4e;
  }
`

class TenancyForm extends PureComponent {

  static defaultProps = {
    withCard: true,
    products: [1],
    entity: 'PF'
  }

  calcTotalTenancy = () => {
    const { entity, changedValues } = this.props;
    if (changedValues !== undefined && typeof changedValues === 'object') {
      if (entity === 'PF' || entity === "PJ") {
        const { aluguel, condominio, iptu, total_locacao } = changedValues;
        let total = 0;
        if (aluguel !== undefined || condominio !== undefined || iptu !== undefined) {
          const aluguelParsed = aluguel || 0;
          const condominioParsed = condominio || 0;
          const iptuParsed = iptu || 0;
          total = aluguelParsed + condominioParsed + iptuParsed;

          total = formatToBRL(total);
          if (total !== total_locacao) {
            this.props.change('total_locacao', total);
          }
        }
      }
    }
  }

  getFields = () => {
    const { products, entity, changedValues } = this.props;
    let fields = tenancyFields;
    if(products.length && products[0] === 5) {
      fields = tenancyFieldsFcBasico;
    }
    const fieldsName = getTenancyFields(products, entity, changedValues);
    this.calcTotalTenancy();
    return [{
      name: 'firstRow',
      fields: fieldsName.map((field) => (field in fields) && fields[field])
    }];
  }

  render() {

    if (!this.props.withCard) {
      return (<FormContent rows={this.getFields()} />);
    }

    return (
      <Card title="Locação" iconHeader={House} iconLeft>
        <FormContent rows={this.getFields()} />
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  changedValues: getValues(state, 'tenancyForm'),
});

const TenancyFormWithReduxForm = reduxForm({
  form: 'tenancyForm',
  enableReinitialize: true
})(TenancyForm);

const TenancyFormConnected = connect(mapStateToProps, null)(TenancyFormWithReduxForm);

export default TenancyFormConnected;
