import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};

  & > circle { fill: #00af24; }
  & > rect { fill: #00af24; }
  & > path:nth-child(1) { fill: #002402; }
  & > path:nth-child(2) { fill: #002903; }
  & > path:nth-child(3) { fill: #002803; }
`;

export default (props) => (
  <SVG {...props} viewBox="0 0 137.25 137.25">
    <circle class="cls-1" cx="68.58" cy="68.58" r="68.58"/>
    <path class="cls-2" d="M38,91.83c5.89-16.15,68-15.69,73.33,0,2.83,8.29-10,21.47-22.17,26.83-3.71,1.63-17.34,6.92-31.67-.17C46.31,113,35,100,38,91.83Z" transform="translate(-6.5 -4.67)"/>
    <rect class="cls-1" x="20.83" y="74.67" width="90.5" height="9.5"/>
    <path class="cls-3" d="M111.84,64.05c-.12,1.76.45,4.46-.63,7s-2.93,3.71-5.52,3.25a4.49,4.49,0,0,1-4.07-4.39c-.16-4-.31-8-.22-12,.07-3.14,1.42-5.62,5-5.65,3.77,0,5.18,2.49,5.43,5.85C111.94,59.83,111.84,61.5,111.84,64.05Z" transform="translate(-6.5 -4.67)"/>
    <path class="cls-4" d="M38.92,63.11c0-2-.06-4,0-6a4.39,4.39,0,0,1,4.22-4.64c2.9-.35,5.25.94,5.49,3.74a82.75,82.75,0,0,1,0,14.33c-.21,2.45-2.39,4-5,3.83A4.53,4.53,0,0,1,39,70c-.19-2.3,0-4.62,0-6.94Z" transform="translate(-6.5 -4.67)"/>
  </SVG>
);
