import React, { PureComponent } from 'react';
import moment from 'moment';

import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import { PrimaryButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { SpanTitle, RetangularBorderedContainer } from './fieldModal/formModalFields.styled';
import CheckBox from '@bit/wavfichacerta.fichacerta.inputs.check-box';

class BeginningModal extends PureComponent {
  state = {
    isOpen: false
  }

  async componentDidMount() {
    const time = await localStorage.getItem('requestModalTime');

    if (time !== null && time !== undefined && moment(time).isValid) {
      const today = moment();
      const oldDate = moment(time);

      // em produção modificar hours para days
      const passedTime = today.diff(oldDate, 'days');

      // em produção modificar 1 para 30
      if (passedTime >= 30) {
        this.setState({ isOpen: true });
      }

    } else {
      this.setState({ isOpen: true });
    }
  }

  onConfirm = () => {
    const { suspend, isOpen } = this.state;

    if (suspend && isOpen) {
      localStorage.setItem('requestModalTime', moment().format());
    }

    this.closeModal();
  }

  renderFooter = () => (
    <StyledRow>
      <PrimaryButton onClick={this.onConfirm}>OK</PrimaryButton>
    </StyledRow>
  )

  renderHeader = () => (
    <SpanTitle>
      Aviso
    </SpanTitle>
  )

  closeModal = () => {
    this.setState({ isOpen: false });
  }

  onChange = (e) => {
    const { target: { checked } } = e;

    this.setState({ suspend: checked });
  }

  render() {
    let text = 'Quanto mais campos forem preenchidos (mesmo os não obrigatórios) mais assertiva será a análise individual.'
    if(this.props.entity === 'PJ') {
      text = 'O FC EMPRESA retorna com informações cadastrais relevantes e com score e risco de mercado (não calculado pela FC).'
    }
    return (
      <ModalContainer
        isOpen={this.state.isOpen}
        size="sm"
        onClose={this.closeModal}
        footer={this.renderFooter()}
        header={this.renderHeader()}
      >

        <RetangularBorderedContainer>
          {text}
        </RetangularBorderedContainer>

        <CheckBox
          labelStyles={{ fontSize: '0.7rem' }}
          onChange={this.onChange}
          label="Suspender temporariamente a exibição desta mensagem"
          customstyle="width: 95%; margin-left: 10px"
        />

      </ModalContainer>
    );
  }
}


export default BeginningModal;
