import { createSelector } from 'reselect';

export const getLoadingStatus = (state) => state.requestsTable.requesting;
const getErrorStatus = state => state.requestsTable.error;

const getSuccessStatus = createSelector(
  [getErrorStatus, getLoadingStatus],
  (error, isLoading) => !isLoading && !error && error !== undefined
);

export const getRequestsList = (state) => {
  if (getSuccessStatus(state)) {
    return state.requestsTable.data;
  }
  return [];
}
