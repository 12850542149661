import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BtHeader, ButtonLink } from './buttons.styled';
import SaveSVG from '../svg/Save';

import Edit from '@bit/wavfichacerta.fichacerta.icons.edit';

class FormButton extends PureComponent {

  isButtonDisabled = () => {
    let errorsKeys = [];

    if (this.props.form !== undefined) {
      const { syncErrors, asyncErrors } = this.props.form;

      if (syncErrors !== undefined) {
        errorsKeys = [...errorsKeys, ...Object.keys(syncErrors)];
      }

      if (asyncErrors !== undefined) {
        errorsKeys = [...errorsKeys, ...Object.keys(asyncErrors)];
      }
    }
    
    if (Array.isArray(errorsKeys) && errorsKeys.length === 0) {
      return false;
    }

    return true;
  }

  renderIcon = () => {
    let icon;
    switch(this.props.proccess){
      case 'editar':
        icon = <SaveSVG width="1rem" />;
      break;
      case 'visualizar':
        icon = <Edit width="1.25rem !important" />;
      break;
      default:
        icon = <SaveSVG width="1rem" />;
      break;
    }
    return icon;
  }

  render() {
    const isDisabled = (this.props.proccess === "visualizar")? false : this.isButtonDisabled();
    return (
      <BtHeader disabled={isDisabled} onClick={this.props.onClickButton}>
        <ButtonLink role="button">
          {this.renderIcon()}
        </ButtonLink>
        <span>{this.props.nameButton}</span>
      </BtHeader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  form: state.form[ownProps.formName]
});

export default connect(mapStateToProps, null)(FormButton);
