import React from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { HalfColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  ContainerGray,
  AnalythicList,
  ColoredSpan,
  AnalythicLine,
  LineTitle,
} from '../../analythic.styled';
import { ColoredColumn, TableTitle, Line } from '../styled';
import CheckConstraintTableCards from '../CheckConstraintTableCards';

import { checkResume, getResultObj } from '../products/fcAnalise';
import Table from './Table';

const CheckConstraints = ({ dataSource }) => {

  const renderDataLine = ({ title, data }) => (
    <AnalythicLine key={(title + data)} style={{color: '#000'}}>
      <LineTitle maxWidth="205px">{title}</LineTitle>
      <ColoredSpan color="color_brown">{data}</ColoredSpan>
    </AnalythicLine>
  );

  const renderResumeLines = (keys) => {
    const dataGiven = checkResume(dataSource, keys);
    return dataGiven.map((line) => renderDataLine(line));
  };

  const source = getResultObj(dataSource, 'restricoes_financeiras');

  const details = window.matchMedia('(max-width: 800px)').matches ? <CheckConstraintTableCards lines={"cheques" in source && source.cheques.detalhes} /> : <Table lines={"cheques" in source && source.cheques.detalhes} />

  if ("cheques" in source && source.cheques.info !== 'CONSTA') {
    return null
    // return (
    //   <ContainerGray margin="0 0 8px 0">
    //     <StyledRow customstyle="height: auto">
    //       <HalfColumn>
    //         <TableTitle>CHEQUES SEM FUNDOS</TableTitle>
    //       </HalfColumn>

    //       <HalfColumn>
    //         <TableTitle>NADA CONSTA</TableTitle>
    //       </HalfColumn>
    //     </StyledRow>
    //   </ContainerGray>
    // )
  }

  return (
    <ColoredColumn>
      <TableTitle style={{color: '#000'}}>CHEQUES SEM FUNDOS</TableTitle>
      <StyledRow align="initial" margin="0 0 .5rem 0">
        <HalfColumn>
          <ContainerGray>
            <AnalythicList>
              {renderResumeLines(['total_ocorrencias', 'valor_total_pendencia'])}
            </AnalythicList>
          </ContainerGray>
        </HalfColumn>
        <HalfColumn>
          <ContainerGray>
            <AnalythicList>
              {renderResumeLines(['data_ultima_ocorrencia'])}
            </AnalythicList>
          </ContainerGray>
        </HalfColumn>
      </StyledRow>
      <Line />
      <TableTitle style={{color: '#000'}}>DETALHAMENTO (MAIS RECENTES - ATÉ 5)</TableTitle>
      {details}
    </ColoredColumn>
  )
};

export default CheckConstraints;
