import React from 'react'

import { StyledChoseEntityButton } from './entityChoice.styled';
import { ChoseEntityLabel } from '@bit/wavfichacerta.fichacerta.shared-styles.typography';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { FullColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { RowCenter } from '../../Header/Title/title.styles';


const EntityChoiceButtonGroupUnlinked = ({ onClick, entity, fcEmpresa }) => (
  <StyledRow customstyle={{ height: '100%', width: '100%' }}>

    <StyledChoseEntityButton
      selected={entity === 'FISICA'}
      onClick={() => onClick('FISICA')}
      color="color_gray"
      borderColor="color_gray"
    >
      Pessoa Física
    </StyledChoseEntityButton>

    {fcEmpresa.length &&
      <StyledChoseEntityButton
        selected={entity === 'JURIDICA'}
        onClick={() => onClick('JURIDICA')}
        color="color_gray"
        borderColor="color_gray"
      >
        Pessoa Jurídica
      </StyledChoseEntityButton>
    || null}

  </StyledRow>
);

export default EntityChoiceButtonGroupUnlinked;
