import { RegularScaleButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import React, { PureComponent } from 'react';
import { noRepeatproduct, removeProduct } from '../../utils/operational/requests/productsHelper';
import { StyledFragment, StyledRowComponent } from '../shared/entity-choice/entityChoice.styled.js';
import EntityChoiceButtonGroupUnlinked from '../shared/entity-choice/EntityChoiceButtonGroupUnlinked.js';
import { DisabledOverlay } from './entityChoiceContent.styled';
import ProductCardboard from './ProductCardboard';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../shared/TourTooltip';

class EntityChoiceContent extends PureComponent {
  static defaultProps = {
    showGoBack: false
  }

  state = {
    entity: 'FISICA',
    products: []
  }

  renderProducts = () => {
    const { list } = this.props;
    console.log('list1', list)
    const products = list.map((product) => {
      const { valor, id, titulo, valor_editavel } = product;
      const price = valor_editavel > 0 ? valor_editavel : valor;

      if (id) {
        return (
          <div style={{display: 'flex'}}>
            {this.state.entity == 'FISICA' &&
              <div>
                {id === 5 &&
                  <ProductCardboard
                    isTrashVisible={false}
                    id={id}
                    onSelect={this.selectProduct(product.id)}
                    active={this.state.entity !== undefined}
                    selected={this.isProductSelected(product.id)}
                    icon="Document"
                    title={'FC BASICO'}
                    price={price}
                    description={<span>INCLUIR Consultas de Dados, Restrições, Ações Locatícias, Cíveis e Criminais</span>}
                  />
                }

                {id === 3 &&
                  <ProductCardboard
                    isTrashVisible={false}
                    id={id}
                    onSelect={this.selectProduct(product.id)}
                    active={this.state.entity !== undefined}
                    selected={this.isProductSelected(product.id)}
                    icon="FcRapida"
                    title={'FC ESSENCIAL'}
                    price={price}
                    description={"INCLUIR\nAnálise FC para Locação + Ações Locatícias e Criminais"}
                  />
                }

                {id === 1 &&
                  <ProductCardboard
                    isTrashVisible={false}
                    id={id}
                    onSelect={this.selectProduct(product.id)}
                    active={this.state.entity !== undefined}
                    selected={this.isProductSelected(product.id)}
                    icon="FcRapida"
                    title={'FC REPORT'}
                    price={price}
                    description={<span>INCLUIR Análise FC para Locação + Restrições e Ações Locatícias, Cíveis e Criminais</span>}
                  />
                }

                {id === 7 &&
                  <ProductCardboard
                    isTrashVisible={false}
                    id={id}
                    onSelect={this.selectProduct(product.id)}
                    active={this.state.entity !== undefined}
                    selected={this.isProductSelected(product.id)}
                    icon="FcRapida"
                    title={'FC COMPLETA'}
                    price={price}
                    description={<span>INCLUIR Análise FC + Restrições + Ações + Documentos</span>}
                  />
                }
              </div>
            }

            {this.state.entity == 'JURIDICA' &&
              <div>
                {id === 4 &&
                  <ProductCardboard
                    isTrashVisible={false}
                    id={id}
                    onSelect={this.selectProduct(product.id)}
                    active={this.state.entity !== undefined}
                    selected={this.isProductSelected(product.id)}
                    icon="FcRapida"
                    title={'FC EMPRESA'}
                    price={price}
                    description={"INCLUIR\nAnálise do perfil de risco para locação: FC EMPRESA"}
                  />
                }

                {id === 8 &&
                  <ProductCardboard
                    isTrashVisible={false}
                    id={id}
                    onSelect={this.selectProduct(product.id)}
                    active={this.state.entity !== undefined}
                    selected={this.isProductSelected(product.id)}
                    icon="FcRapida"
                    title={'FC COMPLETA'}
                    price={price}
                    description={"INCLUIR\nAnálise FC + Restrições + Ações + Documentos"}
                  />
                }
              </div>
            }
          </div>
        );
      }

      return null;
    });

    return products;
  }

  addProduct = (id) => {
    this.setState({ products: noRepeatproduct(id, this.state.products) }, () => {
      this.goForward()
    });
  }

  removeProduct = (id) => {
    this.setState({ products: removeProduct(id, this.state.products) });
  }

  selectProduct = (id) => () => {
    if (this.isProductSelected(id)) {
      this.removeProduct(id);
    } else {
      this.addProduct(id)
    }
  }

  isProductSelected = (id) => {
    const { products } = this.state;
    const productIndex = products.findIndex(prtId => prtId === id);

    return productIndex !== -1;
  }

  selectEntity = (entity) => {
    this.setState({ entity });
  }

  isGoForwardDisabled = () => this.state.products.length === 0

  goForward = () => {
    const { products, entity } = this.state;

    this.props.goForward(products, entity);
  }

  renderGoBackButton = () => {
    if (this.props.showGoBack) {
      return (
        <RegularScaleButton
          onClick={this.props.onGoBack}
          color="color_white"
          background="color_purple"
          padding="8px 22px"
          customstyle={{ 'width': '90px', 'height': '33px', 'padding': '0 1rem 0 1rem', 'margin': '0 .5rem 0 .5rem' }}
        >
          {this.props.cancel ? "Cancelar" : "Voltar"}
        </RegularScaleButton>
      );
    }

    return null;
  }

  render() {
    const { list } = this.props
    console.log('list11', list)
    const fcEmpresa = list.filter(product => product.id === 4 || product.id === 8)
    console.log('fcEmpresa', fcEmpresa)
    return (
      <StyledFragment>
        <StyledRowComponent>
          <div className="container-select-type" style={{position: 'relative'}}>
            <EntityChoiceButtonGroupUnlinked
              entity={this.state.entity}
              onClick={this.selectEntity}
              fcEmpresa={fcEmpresa}
            />
            <TourTooltip
              target="tooltip-select-type"
              style={{left: '50%', marginLeft: -10, right: 'initial'}}
            />
            <ReactTooltip id="tooltip-select-type" place="top" type="dark" effect="float">
              O produto Pessoa Física já vem selecionado, mas caso deseje inserir uma consulta PJ basta clicar em Pessoa Jurídica.
            </ReactTooltip>
          </div>
       </StyledRowComponent>
       <StyledRowComponent customstyle={{ textAlign: 'center' }} >
          {this.state.entity === undefined && <DisabledOverlay />}
          <div className="container-cards-products" style={{display: 'flex', position: 'relative'}}>
            {this.renderProducts()}
            <TourTooltip
              target="tooltip-select-product"
              style={{left: '50%', marginLeft: -10, right: 'initial'}}
            />
            <ReactTooltip id="tooltip-select-product" place="top" type="dark" effect="float">
              Selecione o produto disponível e avance
            </ReactTooltip>
          </div>
        </StyledRowComponent>
        
        <StyledRowComponent>
          {this.renderGoBackButton()}
          {/* <RegularScaleButton
            onClick={this.goForward}
            disabled={this.isGoForwardDisabled()}
            color="color_white"
            background={this.isGoForwardDisabled() ? 'color_gray' : 'color_purple'}
            customstyle={{'width': '90px', 'height': '33px', 'padding': '0 1rem 0 1rem', 'margin': '0 .5rem 0 .5rem'}}
          >
            Avançar
          </RegularScaleButton> */}
        </StyledRowComponent>
      </StyledFragment>
    );
  }
}

export default EntityChoiceContent;
