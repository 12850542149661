import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: 1.5rem;
`;
const StyledPath = styled.path`
  fill: ${props => props.theme.color_purple};
`;

const AddClientSVG = () => (
  <StyledSVG viewBox="0 0 603 512">
  <StyledPath d="M581.6,325c-11.6-19.1-27.4-34.2-47.3-45.3C514.3,268.6,492.6,263,469,263c-23.6,0-45.3,5.6-65.3,16.7
    c-19.9,11.1-35.7,26.2-47.3,45.3c-11.6,19.1-17.4,39.9-17.4,62.5c0,22.6,5.8,43.4,17.4,62.5c11.6,19.1,27.4,34.2,47.3,45.3
    c19.9,11.1,41.7,16.7,65.3,16.7s45.3-5.6,65.3-16.7c19.9-11.1,35.7-26.2,47.3-45.3c11.6-19.1,17.4-39.9,17.4-62.5
    C599,364.9,593.2,344.1,581.6,325z M544.8,397.9c0,2.8-1.1,5.2-3.2,7.3c-2.1,2.1-4.7,3.1-7.6,3.1h-43.3v41.5
    c0,2.8-1.1,5.2-3.2,7.3c-2.1,2.1-4.7,3.1-7.6,3.1h-21.7c-2.9,0-5.5-1-7.6-3.1c-2.1-2.1-3.2-4.5-3.2-7.3v-41.5H404
    c-2.9,0-5.5-1-7.6-3.1c-2.1-2-3.2-4.5-3.2-7.3v-20.8c0-2.8,1.1-5.2,3.2-7.3c2.1-2.1,4.7-3.1,7.6-3.1h43.3v-41.5
    c0-2.8,1.1-5.2,3.2-7.3c2.1-2.1,4.7-3.1,7.6-3.1h21.7c2.9,0,5.5,1,7.6,3.1c2.1,2.1,3.2,4.5,3.2,7.3v41.5H534c2.9,0,5.5,1,7.6,3.1
    c2.1,2.1,3.2,4.5,3.2,7.3V397.9z"></StyledPath>
  
  <StyledPath d="M388.8,293c-15.5-9.4-31.9-17.2-49-23.1c38.8-26.7,64.3-71.4,64.3-122C404,66.4,337.6,0,256,0S108,66.4,108,148
    c0,50.5,25.5,95.3,64.3,122c-36.2,12.5-69.4,33.1-97.3,61C26.6,379.3,0,443.6,0,512h40c0-119.1,96.9-216,216-216
    c39.2,0,76,10.5,107.8,28.9C370.6,313.1,379,302.4,388.8,293z M256,256c-59.6,0-108-48.4-108-108S196.4,40,256,40
    c59.6,0,108,48.4,108,108S315.6,256,256,256z"></StyledPath>
  </StyledSVG>
);

export default AddClientSVG;
