import styled from 'styled-components';
import { CardLogin } from '../../home/home.styled';

export const CardRefresh = styled(CardLogin)`
  padding-top: 7vh;
`;

export const TitleRefresh = styled.h5`
  color: ${props => props.theme.color_purple};
  text-align: center;
`;
