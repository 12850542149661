import React, { Component } from "react";
import { connect } from 'react-redux';
import GuestSVG from "./svg/Guest";
import LogoutSVG from "./svg/Logout";
import { 
  Container,
  UserPanelContainer,
  BoxNotification,
  BoxWelcome,
  ContainerIcons,
  NotifyContainer,
  ContaineGuestIcon,
  ContainerInfoUser,
  SpanHello,
  NameUser,
  BoxExit,
  ExitIconContainer
} from './userPanel.styles';

class UserPanel extends Component {

  renderName = () => {
    const { login } = this.props;
    let name = '';
    if (typeof login === 'object' && login) {
      if (login.user && "usuario" in login.user) {
        const { user: { usuario } } = login;
        name = usuario.nome.split(' ')[0];
      }
    }
    return name;
  }

  render() {
    return (
      <Container>
        <UserPanelContainer>
          <BoxNotification>
            <ContainerIcons role="button">
              {/* <BellSVG /> */}
              <NotifyContainer>
                {/* <NotifySVG /> */}
              </NotifyContainer>
            </ContainerIcons>
            <ul className="sub-panel list-notify">
              <li>Lorem ipsum dolor sit amet, consectetur...</li>
              <li>Lorem ipsum dolor sit amet, consectetur..</li>
            </ul>
          </BoxNotification>
          <BoxWelcome>
            <ContaineGuestIcon>
              <GuestSVG />
            </ContaineGuestIcon>
            <ContainerInfoUser>
              <SpanHello>Olá,</SpanHello>
              <NameUser role="button">
                {this.renderName()}
              </NameUser>
            </ContainerInfoUser>
          </BoxWelcome>
          <BoxExit>
            <ExitIconContainer role="button" onClick={this.props.logout}>
              <LogoutSVG />
              sair
            </ExitIconContainer>
          </BoxExit>
        </UserPanelContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login
});

export default connect(mapStateToProps, null)(UserPanel);
