import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { activeMenu, closeMenu } from './actions';

import { Collapse } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { isItemOpen/*, getMenuStructure*/ } from './selectors';
import { Title, TitleContainer, StyledNavItem, StyledNavLink, IconContainer, Arrow, DisabledLink } from './menu.styles';
import Icons from '../../components/Menu/Icons';

class MenuCollapsableItem extends PureComponent {
  state = {
    isOpen: false
  }

  toggleSubItems = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  onMobileClick = () => {
    const width = window.outerWidth;
    if (width < 1366) {
      this.props.resetMenu();
    }
  }

  onChangeSubmenu = () => () => {
    this.onMobileClick();
  }

  mapMenuLinks = () => {
    const { subItems, title, location: { pathname } } = this.props;
    if(title === "Suporte" && Array.isArray(subItems) && subItems.length > 0){
      const mappedLinks = subItems.map(subItem => {
        return (
          <StyledNavLink key={subItem.slug} style={{height: "auto"}} disabled={true} >
              <Link style={{marginLeft: 0, wordBreak: "break-all", cursor: "default"}} 
                onClick={() => ({})}
                activeClassName="active"
                key={subItem.slug}
                to={"#"}>
                  {subItem.icon} {subItem.title}
                </Link>
          </StyledNavLink>
        );
      });

      return (
        <Collapse isOpen={this.props.isOpen}>
          {mappedLinks}
        </Collapse>
      );
    } else if (Array.isArray(subItems) && subItems.length > 0) {
      const mappedLinks = subItems.map(subItem => {
        let to = '#';
        if(subItem.slug) {
          to = `/intranet/${title.toLowerCase()}/${subItem.slug}`;
        }
        const isDisabled = (to === pathname);

        if(to == '#') {
          return (
            <StyledNavLink key={subItem.slug}>
              <DisabledLink>{subItem.title}</DisabledLink>
            </StyledNavLink>
          )
        }

        return (
          <StyledNavLink key={subItem.slug} className={subItem.slug}>
            {isDisabled ?
              <DisabledLink className="active">{subItem.title}</DisabledLink>
              :
              <Link
                onClick={this.onChangeSubmenu(subItem.slug)}
                activeClassName="active"
                key={subItem.slug}
                to={to}
              >
                {subItem.title}
              </Link>
            }

          </StyledNavLink>
        );
      });

      return (
        <Collapse isOpen={this.props.isOpen}>
          {mappedLinks}
        </Collapse>
      );
    }

    return null;
  }

  renderIcon = () => {
    const Icon = Icons[this.props.title];
    if(Icon)
      return <Icon />;
    else
      return 'X'
  }

  openSubItems = () => {
    const { activeMenu, title, closeMenu, isOpen } = this.props;
    if (isOpen) {
      closeMenu(title);
    } else {
      activeMenu(title);
    }
  }

  getCurrentArea = () => {
    const { location, title } = this.props;

    return location.pathname.search(title.toLowerCase()) > -1;
  }

  render() {
    return (
      <StyledNavItem isOpen={this.getCurrentArea()} className={this.props.title}>
        <TitleContainer onClick={this.openSubItems}>
          <IconContainer>
            {this.renderIcon()}
          </IconContainer>
          <Title onClick={this.openSubItems}>{this.props.title}</Title>
          <div style={{ position: 'relative', left: -17, fontSize: '1rem', color: 'white' }}>
            <Arrow isOpen={this.props.isOpen}>
              {'⌵'}
            </Arrow>
          </div>
        </TitleContainer>
        {this.mapMenuLinks()}
      </StyledNavItem>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  activeMenu,
  closeMenu,
}, dispatch);

const mapStateToProps = (state, ownProps) => (
  {
    ...ownProps,
    isOpen: isItemOpen(state, ownProps)
  }
);
const Item = connect(mapStateToProps, mapDispatchToProps)(MenuCollapsableItem);
export default withRouter(Item);