import React from 'react';
import { PageNumberTypography, PageNumberContainer, PageNumberUnderScore, PageNumberCol } from './footer.styles';

const PageNumber = ({ num, onClick, currentPage }) => (
  <PageNumberCol>
    <PageNumberContainer onClick={() => onClick(num)}>
      <PageNumberTypography current={currentPage === num}>
        {num}
      </PageNumberTypography>
      {currentPage === num && <PageNumberUnderScore />}
    </PageNumberContainer>
  </PageNumberCol>
);

export default PageNumber;
