import React from 'react';
import logo from '../../img/png/Logo-fc-analise-so-fc.png';
import close from '../../img/svg/ico-close-interno.svg';

const styles = {
  logoHeader: {
    height: '20%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
    fontSize: '1.3rem'
  }
};

const LoginHeader = ({ title }) => (
  <div style={styles.logoHeader}>
    <img style={{height: '40px', marginBottom: '.5rem'}} src={logo} alt="logo" />
    <a href='https://www.fcanalise.com.br/' style={{position: 'absolute', right: 0, top: -4}}>
      <img style={{width: '17px'}} src={close} alt="close" />
    </a>
    {typeof title === 'string' ? <span>{title}</span> : title}
  </div>
);

LoginHeader.defaultProps = {
  title: ''
};

export default LoginHeader;
