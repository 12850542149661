import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const amountMask = (prefix = '') => {
    return createNumberMask({
        prefix: prefix,
        thousandsSeparatorSymbol:'.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2
    });
}