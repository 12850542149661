import React, { Component } from 'react';
import styled from 'styled-components';
const SVG = styled.svg`
  width: ${props => props.width || '1rem'};
  fill: ${props => props.color || props.theme.color_red};
`;

export default class CancelSVG extends Component {
  render() {
    return (
      <SVG viewBox="0 0 191.721 191.72" {...this.props}>
        <path className="fill-dark-red" d="M191.328,133.248c-6.39-12.188-19.232-21.508-29.474-30.397c-10.948-9.502-22.332-18.497-33.687-27.513
          c12.985-16.615,26.197-33.1,39.136-49.818c2.477-0.111,4.292-2.813,3.856-5.3c1.225-3.119-2.752-6.463-5.736-5.2
          c-5.744-3.401-12.061-6.335-17.977-9.095c-2.389-1.115-5.137,1.275-4.309,3.452c-14.211,14.521-28.93,28.564-43.797,42.467
          c-6.208-5.272-12.332-10.644-18.3-16.188c-0.028-0.026-0.054-0.056-0.082-0.082c-0.013-1.002-0.447-2.013-1.469-2.859
          c-0.295-0.244-0.615-0.452-0.916-0.687l0.01-0.013c-0.038-0.02-0.075-0.044-0.113-0.065c-1.137-0.884-2.283-1.755-3.453-2.59
          c-5.625-6.393-11.027-13.564-17.396-18.717c-0.107-3.117-3.365-6.168-6.835-4.063c-11.694,7.096-25.984,14.422-35.003,24.937
          c-0.285,0.332-0.502,0.674-0.675,1.023c-2.405,0-4.331,3.555-2.289,5.636c16.229,16.54,34.895,30.518,53.126,44.752
          c-20.276,18.875-40.302,37.979-59.288,58.052c-0.08,0.071-0.165,0.098-0.243,0.18c-1.737,1.782-3.445,3.593-5.122,5.431
          c-2.882,3.107-0.485,7.23,2.663,7.824c5.768,9.427,14.606,16.436,23.635,22.602c6.122,4.182,17.289,11.836,24.996,8.038
          c1.435,0.256,2.953-0.159,3.71-1.67c12.661-25.268,27.836-48.728,44.177-71.383c9.179,8.064,18.289,16.209,27.48,24.216
          c2.699,2.351,32.588,33.965,37.721,23.447c0.705,0.192,1.441,0.281,2.169,0.147c4.673-0.851,8.372-6.737,10.926-10.222
          c2.64-3.602,5.362-7.41,7.675-11.325C189.584,140.387,192.869,136.188,191.328,133.248z M21.652,139.835
          c-0.232,0.629-0.283,1.287-0.24,1.938c-1.146,0.634-1.929,1.723-2.232,2.958c-1.187,0.104-2.329,0.498-3.314,1.236
          c-0.14-0.058-0.277-0.118-0.417-0.176C17.506,143.8,19.571,141.813,21.652,139.835z M51.05,177.043
          c-0.836-0.394-1.706-0.678-2.581-0.959c1.438-0.5,2.758-1.215,3.959-2.127C51.977,174.988,51.492,176.008,51.05,177.043z
            M72.621,76.7c-10.23-8.269-20.595-16.375-30.994-24.432c3.282,2.261,6.666,4.363,10.146,6.318
          c6.855,6.141,13.933,12.056,21.077,17.901C72.772,76.559,72.696,76.63,72.621,76.7z" />
      </SVG>
    )
  }
}
