import { formatDateToBR, formatToBRL } from '../../../../../utils/formatter';

export const getDeathSuspicius = (data) => {
  if (data !== undefined && typeof data === "object") {
    const { laudo } = data;
    if (laudo !== undefined && laudo !== null && "suspeita_obito" in laudo) {
      const { suspeita_obito } = laudo;
      return (suspeita_obito.icon === "positivo")? "NÃO" : "SIM";
    }
  }
  return "Não encontrado";
}

export const getProfessionalGuarantor = (data) => {
  if (data !== undefined && typeof data === "object") {
    const { laudo } = data;
    if (laudo !== undefined && laudo !== null && "rede_ficha" in laudo) {
      const { rede_ficha } = laudo;

      if ("fiador_profissional" in rede_ficha) {
        const { fiador_profissional } = rede_ficha;
        return fiador_profissional.result;
      }
    }
  }
  return "Não encontrado";
}

export const getPastSearches = (data) => {
  if (data !== undefined && "laudo" in data) {
    const { laudo } = data;
    if (laudo !== undefined && laudo !== null && "rede_ficha" in laudo) {
      const { rede_ficha } = laudo;

      if ("pesquisas_anteriores" in rede_ficha) {
        const { pesquisas_anteriores: { recommendation, result } } = rede_ficha;

        if ((typeof result === "object" && result.total === 0) || typeof result !== "object") {
          return recommendation[0];
        }
        
        if (typeof result === "object" && "passages" in result && Array.isArray(result.passages)) {
          return result.passages.map((item) => {
            const dateFormated = formatDateToBR(item.date) || "00/00/0000";
            const valueFormated = formatToBRL(item.value);
            return `Em ${dateFormated}, consta como Pretendente a ${item.type.toLowerCase()} para uma locação no valor de R$${valueFormated}.`;
          });
        }
      }
    }
  }
  return "Não encontrado";
}

export const getNumberOfCreditSearchesPhrase = (data) => {

  if (data !== undefined && "laudo" in data) {
    const { laudo } = data;

    if (laudo !== undefined && laudo !== null && "rede_ficha" in laudo) {
      const { rede_ficha } = laudo;

      if ("pesquisas_anteriores_api" in rede_ficha) {

        const { pesquisas_anteriores_api: { result, recommendation } } = rede_ficha;

        if (!result.total) {
          return "NÃO ENCONTRADO.";
        }

        if (typeof result === "string") {
          return result;
        }

        return result.total_consultas_credito_frase || "";
      }
    }
  }

  return "NÃO ENCONTRADO.";
}

export const getPastSeachesCommerce = (data) => {

  if (data !== undefined && "laudo" in data) {
    const { laudo } = data;

    if (laudo !== undefined && laudo !== null && "rede_ficha" in laudo) {
      const { rede_ficha } = laudo;

      if ("pesquisas_anteriores_api" in rede_ficha) {

        const { pesquisas_anteriores_api: { result, recommendation } } = rede_ficha;

        // if (!result.total) {
        //   return recommendation[0]
        // }

        // if (typeof result === "string") {
        //   return result;
        // }

        if (typeof result === "object" && "passages" in result && Array.isArray(result.passages)) {
          if(result.passages.length > 0) {
            return result.passages.map((item) => {
              let dateFormated = "00/00/0000";
              const modQuery = item.modalidade_consulta !== "" && item.modalidade_consulta? `na modalidade ${item.modalidade_consulta}` : "";
              const company = item.nome_empresa;

              if (item.data_consulta !== "" && item.data_consulta !== "0000-00-00") {
                  dateFormated = formatDateToBR(item.data_consulta);
              }

              if(company == 'ASSECAD BRASIL ASSESSORIA CADASTRAL LTDA'){
                return `Em ${dateFormated}, este pretendente foi consultado na FC ANALISE Digital.`;
              }
              
              return `Em ${dateFormated}, a empresa/segmento ${company} fez uma consulta ${modQuery}.`;
            });
          }
        }
      }
    }
  }

  return "";
}

export const getRecommendations = (data) => {
  
  if (data !== undefined && data !== null && (typeof data === "object" && Object.keys(data).length > 0)) {
    const searchKeys = ["fiador_profissional"];
    const { laudo } = data;
    let recommendations = [];
    if (laudo !== undefined && laudo !== null && "suspeita_obito" in laudo) {
      recommendations = [...recommendations, ...laudo.suspeita_obito.recommendation];
    }

    if (laudo !== undefined && laudo !== null && "rede_ficha" in laudo) {
      const { rede_ficha } = laudo;
      searchKeys.forEach((key) => {
        if (rede_ficha[key] !== undefined && rede_ficha[key] !== null) {
          const foundObj = rede_ficha[key].recommendation || rede_ficha[key].recomendation;
          recommendations = [...recommendations, ...foundObj];
        }
      });
    }

    if (Array.isArray(recommendations) && recommendations.length > 0) {
      // Remove duplicates
      return recommendations.filter((rec, index) => recommendations.indexOf(rec) === index);
    }
  }
  return [];
}
