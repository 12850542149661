import React from 'react';
import handler from '@bit/wavfichacerta.fichacerta.inputs-functions.input-handler'
import { maskedText, select } from '@bit/wavfichacerta.fichacerta.inputs.inputs';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';

const months = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"]

const FilterHandler = (type) => {
  if(type === "1"){
    return (
      <StyledRow justify="flex-start" >
        <span style={{fontSize: "12px", marginLeft: "3px"}} >De</span>
        <BaseColumn xl={5}>
          {handler({ maskedText })(
            {
              name: "initial_date",
              label: "",
              size: "field-fc field-fc-l",
              isRequired: false,
              type: 'maskedText',
              value: "",
              // normalize: date,
              // format: formatDateToBR,
              // warn: (value) => value !== undefined ? warnDate(value) : value,
              hasValidation: true,
              props: {
                regex: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
              }
            }
          )}
        </BaseColumn>
        <span style={{fontSize: "12px", marginLeft: "3px"}} >a</span>
        <BaseColumn xl={5}>
          {handler({ maskedText })(
            {
              name: "end_date",
              label: "",
              size: "field-fc field-fc-l",
              isRequired: false,
              type: 'maskedText',
              value: "",
              // normalize: date,
              // format: formatDateToBR,
              // warn: (value) => value !== undefined ? warnDate(value) : value,
              hasValidation: true,
              props: {
                regex: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
              }
            }
          )}
        </BaseColumn>
      </StyledRow>
    )
  } else {
    var filterYears = [];
    var actualYear = new Date().getFullYear();
    for (var i = actualYear; i >= 2019; i--){
      filterYears.push({value: i.toString(), label: i.toString() });
    }
    return (
      <StyledRow justify="flex-start" >
        <span style={{fontSize: "12px", marginLeft: "3px"}} >Ano</span>
        <BaseColumn xl={4}>
          {handler({ select })(
            {
              name: "year_filter",
              label: "",
              size: "field-fc field-fc-l",
              type: 'select',
              hasValidation: true,
              options: filterYears
            }
          )}
        </BaseColumn>
        <span style={{fontSize: "12px", marginLeft: "3px"}} >Mês</span>
        <BaseColumn xl={5}>
          {handler({ select })(
            {
              name: "month_filter",
              label: "",
              size: "field-fc field-fc-l",
              type: 'select',
              hasValidation: true,
              options: months.map((month, index) => ({value: index, label: month}))
            }
          )}
        </BaseColumn>
      </StyledRow>
    )
  }
}

export default FilterHandler