import React, { PureComponent, Fragment } from 'react';

import Card from '@bit/wavfichacerta.fichacerta.cards.card';
import { CardText, TextBold, PriceContainer, Separator } from './operational.styles';
import { formatToBRL } from '../../utils/formatter';
import { Title, TitleButton } from '../../pages/operational/request/resume/requestResume.styles';
import TourTooltip from '../shared/TourTooltip';

class RequestsCard extends PureComponent {

  static defaultProps = {
    lightTheme: false,
    hasButton: false,
    hasEdited: false,
    btnDisabled: false,
    onClickBtnTitle: () => console.log('Missing callback function')
  }

  renderLineProduct = (line) => (
    <PriceContainer key={line.title}>
      <CardText>{line.title}</CardText>
      {/* <CardText>R$ {formatToBRL(line.total)}</CardText> */}
    </PriceContainer>
  )

  showTotal = () => {
    const { lines } = this.props;
    
    if (Array.isArray(lines) && lines.length > 0) {
      let total = 0;
      lines.forEach((line) => {
        total += line.total;
      });
      return "R$ " + formatToBRL(total);
    }
    return "R$ 0,00";
  }

  renderIconCart = () => {
    const { title } = this.props;
    if(title != 'A Solicitar') return null
  
    return(
      <svg style={{width: 20, marginLeft: 4, marginBottom: 11}} fill="none" height="16" viewBox="0 0 30 27" width="30" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.39999 1.70001H6.60001" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        <path d="M6.60001 1.70001L11 18.9" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        <path d="M11.8 18.9H28.3" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        <path d="M13.8 25.7C15.4569 25.7 16.8 24.3569 16.8 22.7C16.8 21.0432 15.4569 19.7 13.8 19.7C12.1431 19.7 10.8 21.0432 10.8 22.7C10.8 24.3569 12.1431 25.7 13.8 25.7Z" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        <path d="M25.3 25.7C26.9568 25.7 28.3 24.3569 28.3 22.7C28.3 21.0432 26.9568 19.7 25.3 19.7C23.6431 19.7 22.3 21.0432 22.3 22.7C22.3 24.3569 23.6431 25.7 25.3 25.7Z" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        <path d="M25.7 14.6H11.3C10.7 14.6 10.1 14.2 10 13.6L8.1 6.90001C7.9 6.00001 8.49999 5.20001 9.39999 5.20001H27.5C28.4 5.20001 29.1 6.10001 28.8 6.90001L26.9 13.6C26.9 14.2 26.4 14.6 25.7 14.6Z" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
      </svg>
    )
  }

  render() {
    const { lines, title, lightTheme, hasButton, hasEdited, btnDisabled, customstyleTitle } = this.props;
    const opacity = lightTheme? '.6' : '1';
    const hasValue = lines.findIndex((line) => line !== undefined && line.total > 0) > -1;
    if (Array.isArray(lines) && hasValue) {
      return (
        <Fragment>
          <Title customstyle={customstyleTitle}>
            {title}
            {this.renderIconCart()}
            {hasButton && (
              <TitleButton className="botao-ver-analise" onClick={() => this.props.onClickBtnTitle(hasEdited)} disabled={btnDisabled}>
                {hasEdited? "Atualizar" : "Ver"} ANÁLISE
                <TourTooltip
                  target="tooltip-ver-analise"
                />
              </TitleButton>
            )}
          </Title>
          <Card opacity={opacity} padding=".5rem .9rem .5rem .9rem">
            {lines.map((line) => this.renderLineProduct(line))}
            {/* <Separator /> */}
            {/* <PriceContainer>
              <CardText><TextBold>TOTAL:</TextBold></CardText>
              <CardText><TextBold>{this.showTotal()}</TextBold></CardText>
            </PriceContainer> */}
          </Card>
        </Fragment>
      );
    }

    return null;
  }
}

export default RequestsCard;
