import styled from 'styled-components'

export const TabLink = styled.a`
    color: #007bff !important;
    font-size: 12px;
    cursor: pointer;
    margin: .5rem;

    @media(max-width:800px){
        flex-direction: row;

    }
`;

export const ParecerText = styled.span`
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.color || props.theme.color_gray};
    text-align: center;

    @media(max-width: 800px){
        font-size: 10px;
    }
`;

export const Name = styled.span`
    font-size: 12px;
    color: #7f7f7f;
`;

export const RiskContainer = styled.div`
    width: 100%;
    background-color: #ffefcb;
    font-size: 12px ;
    color: #7f7f7f;
    padding: .5rem;
    margin-top: 3px;

    @media(max-width: 800px){
        margin: 0;
    }
`;