import { instance as axios } from './axiosInstance';
import { statusHandler } from '../utils/loginHelper';
import moment from 'moment';

const getHeader = (token) => (
  {
    'Authorization': `Bearer ${token}`
  }
);

const REQUEST_DATA_ENDPOINT = '/api/solicitacao/data';
const REQUESTS_ENDPOINTS = '/api/solicitacao';
const USER_INFO = '/api/user';
const FCFIANCA_REQUESTS_ENDPOINTS = (status) => `/api/solicitacao/fcfianca?produto_status=${status}`;
const getRequestByIdEndpoint = id => `${REQUESTS_ENDPOINTS}/${id}`;
const getApplicantEndpoint = (requestId, applicantId) => `api/solicitacao/${requestId}/pretendente/${applicantId}`
const getChargeEndpoint = (requestId, type) => `/api/solicitacao/${requestId}/cobranca?tipo=${type}`
const REQUEST_ENDPOINT = requestID => `/api/solicitacao/${requestID}`;
const TENANCY_ENDPOINT = (requestID) => `/api/solicitacao/${requestID}/locacao`;
const getAddApplicantEndpoint = (requestId) => `/api/solicitacao/${requestId}/pretendente`;
const postFileUploadEndpoint = (id_solicitacao, id_pretendente, id_produto) => `/api/solicitacao/${id_solicitacao}/pretendente/${id_pretendente}/produto/${id_produto || 2}/arquivo`
const postFileUploadFcCompletaEndpoint = (id_produto) => `/api/solicitacao/produto/${id_produto || 7}/arquivo`
const getFileUploadEndpoint = (id_solicitacao, id_pretendente, id_produto) => `/api/solicitacao/${id_solicitacao || 268}/pretendente/${id_pretendente || 803}/produto/${id_produto || 2}/arquivo`
const deleteFileUploadEndpoint = (id_arquivo, id_solicitacao, id_pretendente, id_produto) => `/api/solicitacao/${id_solicitacao}/pretendente/${id_pretendente}/produto/${id_produto || 2}/arquivo/${id_arquivo}`
const postProductFcRendaEndpoint = (id_solicitacao, id_pretendente, id_produto) => `/api/solicitacao/${id_solicitacao}/pretendente/${id_pretendente}/produto/${id_produto || 2}`
const mailFiancaEndpoint = (id_solicitacao) => `/api/solicitacao/${id_solicitacao}/fcfianca/email`

let ENV_REPORT_ENDPOINT = process.env.REACT_APP_START_REPORT_ENDPOINT;

if(process.env.REACT_APP_ENV === 'prod') {
  ENV_REPORT_ENDPOINT = process.env.REACT_APP_START_REPORT_ENDPOINT_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  ENV_REPORT_ENDPOINT = process.env.REACT_APP_START_REPORT_ENDPOINT_STAG;
} else if (process.env.REACT_APP_ENV === 'fcrenda') {
  ENV_REPORT_ENDPOINT = process.env.REACT_APP_START_REPORT_ENDPOINT_FCRENDA;
}

const REPORT_ENDPOINT = (requestID) => `${ENV_REPORT_ENDPOINT}?solicitation=${requestID}`;
const getRemoveApplicantEndpoint = (requestId, applicantId) => `/api/solicitacao/${requestId}/pretendente/${applicantId}`
const getRemoveProductEndpoint = (requestId, applicantId, product) => `/api/solicitacao/${requestId}/pretendente/${applicantId}/produto`
const getRemoveProductReinclusionEndpoint = (requestId, applicantId, productId) => `/api/solicitacao/${requestId}/pretendente/${applicantId}/produto/${productId}/reinclusao`

export const removeApplicant = (token, requestId, applicantId) => {
  const headers = getHeader(token);
  return axios.delete(getRemoveApplicantEndpoint(requestId, applicantId), { validteStatus: statusHandler, headers })
}

export const removeProduct = (token, requestId, applicantId, productId) => {
  const headers = getHeader(token);
  return axios.delete(getRemoveProductEndpoint(requestId, applicantId), {validteStatus: statusHandler, headers, data: { ids: [productId]}} )
}

export const removeReinclusionProduct = (token, requestId, applicantId, productId = 1) => {
  const headers = getHeader(token);
  return axios.delete(getRemoveProductReinclusionEndpoint(requestId, applicantId, productId), {validteStatus: statusHandler, headers })
}

export const chargeRequest = (data, token, type, tipo) => {
  const headers = getHeader(token);
  return axios.post(getChargeEndpoint(data.solicitation_id, type), {}, { validteStatus: statusHandler, headers })
}

export const getUserInfo = (token) => {
  const headers = getHeader(token)
  return axios.get(USER_INFO, {headers})
}

export const requests = (token, pagination) => {
  const params = {
    sort: pagination.sort,
    perPage: pagination.resultsPerPage,
    page: pagination.currentPage,
    filter: pagination.filter,
  }

  if(pagination.date.startDate){
    params.start_date = moment(pagination.date.startDate, "DD-MM-YYYY").format("YYYY-MM-DD")
  }

  if(pagination.date.endDate){
    params.end_date = moment(pagination.date.endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
  }

  const headers = getHeader(token);
  return axios.get(REQUESTS_ENDPOINTS, { validteStatus: statusHandler, headers, params })
}

export const fcfiancaRequests = (token, pagination, complete, id) => {
  const params = {
    sort: pagination.sort,
    perPage: pagination.resultsPerPage,
    page: pagination.currentPage,
    filter: pagination.filter,
    cliente: id
  }

  let status = 'CONCLUIDO';

  if(pagination.date.startDate){
    params.start_date = moment(pagination.date.startDate, "DD-MM-YYYY").format("YYYY-MM-DD")
  }

  if(pagination.date.endDate){
    params.end_date = moment(pagination.date.endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
  }

  if(!complete){
    status = ['solicitado','andamento']
  }
  console.log('entrei aqui')

  params.filter = status
  const headers = getHeader(token);
  return axios.get(FCFIANCA_REQUESTS_ENDPOINTS(status), { validteStatus: statusHandler, headers, params })
}

export const getDataRequirements = (token) => {
  const headers = getHeader(token);
  return axios.get(REQUEST_DATA_ENDPOINT, { validateStatus: statusHandler, headers });
}

export const getApplicant = (token, requestId, applicantId) => {
  const headers = getHeader(token);
  return axios.get(getApplicantEndpoint(requestId, applicantId), { validateStatus: statusHandler, headers });
};

export const createRequests = (token, data) => {
  const headers = getHeader(token);
  return axios.post(REQUESTS_ENDPOINTS, data, { validateStatus: statusHandler, headers });
}

export const addApplicant = (token, data, requestId) => {
  const headers = getHeader(token);
  return axios.post(getAddApplicantEndpoint(requestId), data, { validateStatus: statusHandler, headers });
}

export const updateApplicant = (token, requestId, applicantId, data, reInclusion, selectedProduct) => {
  const headers = getHeader(token);
  if(reInclusion) data = {...data, reconsulta: selectedProduct || 1}
  return axios.put(getApplicantEndpoint(requestId, applicantId), data, { validateStatus: statusHandler, headers });
}

export const getRequestById = (token, id) => {
  const headers = getHeader(token);
  return axios.get(getRequestByIdEndpoint(id), { validateStatus: statusHandler, headers });
}

export const getRequestData = (token, requestID) => {
  const headers = getHeader(token);
  return axios.get(REQUEST_ENDPOINT(requestID), { validateStatus: statusHandler, headers });
}

export const updateTenancy = (token, requestID, data) => {
  const headers = getHeader(token);
  return axios.put(TENANCY_ENDPOINT(requestID), data, { validateStatus: statusHandler, headers });
}

export const getReport = (data) => {
  
  return fetch(REPORT_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(data)
  });
}

export const updateReport = (data, token) => {
  
  const headers = {
    'authorization': `${token}`
  };
  return axios.post(REPORT_ENDPOINT(data.solicitation_id), data, { headers });
}


export const postFileUpload = (token, data, id_solicitacao, id_pretendente, id_produto) => {
  const headers = getHeader(token);
  return axios.post(postFileUploadEndpoint(id_solicitacao, id_pretendente, id_produto), data, { validateStatus: statusHandler, headers });
};

export const postFileUploadFcCompleta = (token, data, id_produto) => {
  const headers = getHeader(token);
  return axios.post(postFileUploadFcCompletaEndpoint(id_produto), data, { validateStatus: statusHandler, headers });
};

export const getFileUpload = (token, data, id_solicitacao, id_pretendente, id_produto) => {
  const headers = getHeader(token);
  return axios.post(getFileUploadEndpoint(id_solicitacao, id_pretendente, id_produto), data, { validateStatus: statusHandler, headers });
};

export const deleteFileUpload = (token, data, id_solicitacao, id_pretendente, id_produto) => {
  const headers = getHeader(token);
  return axios.delete(deleteFileUploadEndpoint(data.id, id_solicitacao, id_pretendente, id_produto), { validateStatus: statusHandler, headers });
};

export const addProductFcRenda = (token, data, id_solicitacao, id_pretendente, id_produto) => {
  const headers = getHeader(token);
  return axios.post(postProductFcRendaEndpoint(id_solicitacao, id_pretendente, id_produto), data, { validateStatus: statusHandler, headers });
};

export const sendMailFianca = (token, id_solicitacao) => {
  const headers = getHeader(token);
  return axios.get(mailFiancaEndpoint(id_solicitacao), { validateStatus: statusHandler, headers });
};