
/**
 * Extract applicants who will appear in the report
 * @param {Array} applicants 
 */
export const extractApplicantsHasReport = (applicants) => {
  if (Array.isArray(applicants) && applicants.length > 0) {
    return applicants.filter((applicant) => {
      if (applicant.produtos.findIndex((product) => product.status === "INCLUIDO") === -1) {
        return applicant;
      }
      return false
    });
  }

  return [];
}

/**
 * Create de initial structure for the report screen
 * @param {Array} applicants 
 * 
 * [
 *  {
 *    applicant: ID <Int>,
 *    isRequesting: true <Boolean>,
 *    data: {} <Object>
 *  }
 * ]
 */
export const extractReportInitialStructure = (applicants, withIncludedApplicants = true) => {
  if(Array.isArray(applicants) && applicants.length > 0) {
    return applicants
      .map((line) => {
        const reportStructure = {
          applicant: line.id,
          isRequesting: true,
          data: {}
        };

        // Ignore product status of the actual applicants
        if (withIncludedApplicants) {
          return reportStructure;
        }

        // Return the applicant if don't have a product with status INCLUIDO
        const productFound = line.applicant && line.produtos.ativo.findIndex((prod) => prod.status === "INCLUIDO") > -1;
        if (!productFound) {
          return reportStructure;
        }else{
          return undefined
        }
      })
      .filter((line) => line !== undefined);
  }

  return [];
}

export const updateApplicantReport = (applicantsAr, reportData) => {
  if (Array.isArray(applicantsAr) && applicantsAr.length > 0) {
    let newArrApplicants = [...applicantsAr];
    const indexAr = applicantsAr.findIndex((line) => line.applicant === reportData.pretendente.id);
    if (indexAr > -1) {
      const newObj = {
        applicant: reportData.pretendente.id,
        isRequesting: false,
        data: reportData
      }
      newArrApplicants.splice(indexAr, 1, newObj);
      return newArrApplicants;
    }
  }

  return applicantsAr;
}
