import { formatAddress, formatPhone, formatCPF, formatDateToBR, formatToBRL } from '../../../../../utils/formatter';

export const getLocationGiven = (data) => {
  let address = "";
  let phone = "";
  let mobile = "";
  let email = "";

  if (data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      const { pretendente } = data;

      if ("endereco" in pretendente) {
        const { endereco } = pretendente;
        address = formatAddress(endereco[0] || {});
      }

      if ("telefones" in pretendente) {
        const { telefones } = pretendente;
        if (Array.isArray(telefones) && telefones.length > 0) {
          telefones.forEach((phoneData) => {
            const key = Object.keys(phoneData)[0];
            if (key === "residencial") {
              phone = formatPhone(phoneData.residencial.numero);
            } else if (key === "celular") {
              mobile = formatPhone(phoneData.celular.numero);
            }
          });
        }
      }

      if ("emails" in pretendente) {
        const { emails: { principal } } = pretendente;
        email = principal;
      }
    }
  }

  return [
    { title: "Endereço", data: address || 'Não fornecido' },
    { title: "Telefone", data: phone || 'Não fornecido' },
    { title: "Celular", data: mobile || 'Não fornecido' },
    { title: "E-mail", data: email || 'Não fornecido' }
  ];
}

export const getLocationChecked = (data) => {
  let titleDoc = '';
  let doc = '';
  let name = '';
  let date = '';
  let age = '';
  let motherName = '';
  let cpf = '';
  let fundacao = '';
  let naturezaJuridica = '';
  let cnaeAtividade = '';
  let capitalSocial = '';
  let sociosDiretores = [];
  let sociosDiretoresNomeDated = [];
  let sociosDiretoresCpfDated = [];
  let sociosDiretoresFuncaoDated = [];
  doc = name = date = motherName = "Aguarde...";

  if (data !== undefined && data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      cpf = data.pretendente.cpf || data.pretendente.cnpj;
      titleDoc = (cpf.length === 11)? 'CPF' : 'CNPJ';
    }

    if ("laudo" in data && data.laudo !== null && data.laudo !== undefined) {
      const { situacao_cpf, situacao_cnpj, veracidade_nome, veracidade_data_nascimento, veracidade_nome_mae } = data.laudo;
      doc = `${formatCPF(cpf)} (${(titleDoc === "CPF")? situacao_cpf.result : situacao_cnpj.result})`;
      name = veracidade_nome.result || 'Não encontrado';
      date = formatDateToBR(veracidade_data_nascimento.result.data_nascimento) !== "Invalid date"? formatDateToBR(typeof veracidade_data_nascimento.result == 'string' ? veracidade_data_nascimento.result : veracidade_data_nascimento.result.data_nascimento) : "Não encontrado";
      age = Number.isInteger(veracidade_data_nascimento.result.idade) ? ` (${veracidade_data_nascimento.result.idade} anos)` : '';
      motherName = veracidade_nome_mae.result || 'Não encontrado';
      fundacao = veracidade_nome.fundacao && formatDateToBR(veracidade_nome.fundacao) || 'Não encontrado';
      naturezaJuridica = veracidade_nome.naturezaJuridica || 'Não encontrado';
      cnaeAtividade = veracidade_nome.cnaeAtividade || 'Não encontrado';
      capitalSocial = veracidade_nome.capitalSocial && 'R$' + formatToBRL(veracidade_nome.capitalSocial) || 'Não encontrado';
      sociosDiretores = veracidade_nome.sociosDiretores || [];
      sociosDiretores.map(item => {
        sociosDiretoresNomeDated.push({
          nome: item.nome,
          cpf: item.cpf,
          funcao: item.funcao,
          documentFormat: 'CPF/CNPJ',
          participacao: item.participacao || 'Não informado'
        })
      })

      sociosDiretores.map(item => {
        sociosDiretoresCpfDated.push({
          title: 'CPF',
          data: item.cpf
        })
      })

      sociosDiretores.map(item => {
        sociosDiretoresFuncaoDated.push({
          title: 'Função',
          data: item.funcao
        })
      })
    }
  }

  return sociosDiretores.length > 0 ? sociosDiretoresNomeDated : [];
}

export const getLocationCheckedBoth = (data) => {
  let titleDoc = '';
  let doc = '';
  let name = '';
  let date = '';
  let age = '';
  let motherName = '';
  let cpf = '';
  let fundacao = '';
  let naturezaJuridica = '';
  let cnaeAtividade = '';
  let capitalSocial = '';
  let sociosDiretores = [];
  let sociosDiretoresDated = [];
  doc = name = date = motherName = "Aguarde...";

  if (data !== undefined && data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      cpf = data.pretendente.cpf || data.pretendente.cnpj;
      titleDoc = (cpf.length === 11)? 'CPF' : 'CNPJ';
    }

    if ("laudo" in data && data.laudo !== null && data.laudo !== undefined) {
      const { situacao_cpf, situacao_cnpj, veracidade_nome, veracidade_data_nascimento, veracidade_nome_mae } = data.laudo;
      doc = `${formatCPF(cpf)} (${(titleDoc === "CPF")? situacao_cpf.result : situacao_cnpj.result})`;
      name = veracidade_nome.result || 'Não encontrado';
      date = formatDateToBR(veracidade_data_nascimento.result.data_nascimento) !== "Invalid date"? formatDateToBR(typeof veracidade_data_nascimento.result == 'string' ? veracidade_data_nascimento.result : veracidade_data_nascimento.result.data_nascimento) : "Não encontrado";
      age = Number.isInteger(veracidade_data_nascimento.result.idade) ? ` (${veracidade_data_nascimento.result.idade} anos)` : '';
      motherName = veracidade_nome_mae.result || 'Não encontrado';
      fundacao = veracidade_nome.fundacao && formatDateToBR(veracidade_nome.fundacao) || 'Não encontrado';
      naturezaJuridica = veracidade_nome.naturezaJuridica || 'Não encontrado';
      cnaeAtividade = veracidade_nome.cnaeAtividade || 'Não encontrado';
      capitalSocial = veracidade_nome.capitalSocial && 'R$' + formatToBRL(veracidade_nome.capitalSocial) || 'Não encontrado';
      sociosDiretores = veracidade_nome.sociosDiretores || [];
      sociosDiretores.map(item => {
        sociosDiretoresDated.push({
          title: 'CPF',
          data: formatCPF(item.cpf)
        })
      })
    }
  }

  return sociosDiretoresDated.length > 0 ? sociosDiretoresDated : [{title: 'CPF', data: 'Não encontrado'}];
}

export const getLocationCheckedThree = (data) => {
  let titleDoc = '';
  let doc = '';
  let name = '';
  let date = '';
  let age = '';
  let motherName = '';
  let cpf = '';
  let fundacao = '';
  let naturezaJuridica = '';
  let cnaeAtividade = '';
  let capitalSocial = '';
  let sociosDiretores = [];
  let sociosDiretoresDated = [];
  doc = name = date = motherName = "Aguarde...";

  if (data !== undefined && data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      cpf = data.pretendente.cpf || data.pretendente.cnpj;
      titleDoc = (cpf.length === 11)? 'CPF' : 'CNPJ';
    }

    if ("laudo" in data && data.laudo !== null && data.laudo !== undefined) {
      const { situacao_cpf, situacao_cnpj, veracidade_nome, veracidade_data_nascimento, veracidade_nome_mae } = data.laudo;
      doc = `${formatCPF(cpf)} (${(titleDoc === "CPF")? situacao_cpf.result : situacao_cnpj.result})`;
      name = veracidade_nome.result || 'Não encontrado';
      date = formatDateToBR(veracidade_data_nascimento.result.data_nascimento) !== "Invalid date"? formatDateToBR(typeof veracidade_data_nascimento.result == 'string' ? veracidade_data_nascimento.result : veracidade_data_nascimento.result.data_nascimento) : "Não encontrado";
      age = Number.isInteger(veracidade_data_nascimento.result.idade) ? ` (${veracidade_data_nascimento.result.idade} anos)` : '';
      motherName = veracidade_nome_mae.result || 'Não encontrado';
      fundacao = veracidade_nome.fundacao && formatDateToBR(veracidade_nome.fundacao) || 'Não encontrado';
      naturezaJuridica = veracidade_nome.naturezaJuridica || 'Não encontrado';
      cnaeAtividade = veracidade_nome.cnaeAtividade || 'Não encontrado';
      capitalSocial = veracidade_nome.capitalSocial && 'R$' + formatToBRL(veracidade_nome.capitalSocial) || 'Não encontrado';
      sociosDiretores = veracidade_nome.sociosDiretores || [];
      sociosDiretores.map(item => {
        sociosDiretoresDated.push({
          title: 'Função',
          data: item.funcao
        })
      })
    }
  }

  return sociosDiretoresDated.length > 0 ? sociosDiretoresDated : [{title: 'Função', data: 'Não encontrado'}];
}

export const locationValidation = (data) => {
  const searchKeys = ["veracidade_endereco", "veracidade_telefone", "veracidade_celular", "veracidade_email"];
  return searchKeys.map((key) => data[key] !== undefined && data[key].icon);
}
