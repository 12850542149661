import { GET_REQUIREMENTS } from '../actions/types';
import { storeRequirements } from './reducers';

const initialState = {
  
};

export default (state = initialState, action) => {
  switch(action.type) {
    case GET_REQUIREMENTS:
      return storeRequirements(state, action);
    default:
      return state;
  }
};
