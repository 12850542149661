import { pickBy, identity, uniq } from 'lodash';
import { createAddressObj } from '../../formHelper';
import { getNonRequiredFieldsWithWarningByForm, getAllValuesByForm, getFieldsLabel } from '../../extractors/extractors';

const getTenancyAddress = (values) => {
  const address = pickBy(createAddressObj(values));

  return Object.keys(address).length > 0 ? address : undefined;
}

export const getTenancyObj = (formValues) => {
  return {
    codigo_imovel: formValues.codigo_imovel,
    aluguel: formValues.aluguel,
    condominio: formValues.condominio,
    iptu: formValues.iptu,
    tipo_imovel: formValues.tipo_imovel,
    proprietario: formValues.proprietario,
    cpf_cnpj_proprietario: formValues.cpf_proprietario,
    endereco: getTenancyAddress(formValues)
  };
}

export const getTenancyPJObj = (formValues) => {
  return {
    codigo_imovel: formValues.codigo_imovel,
    aluguel: formValues.aluguel,
    condominio: formValues.condominio,
    iptu: formValues.iptu,
    tipo_imovel: formValues.tipo_imovel,
    proprietario: formValues.proprietario,
    cpf_cnpj_proprietario: formValues.cpf_proprietario,
    endereco: getTenancyAddress(formValues)
  };
}

export const getIncomeObj = (incomeValues, incomeFormChangedValues) => {
  if (removeForEmptyValue(incomeValues) === undefined) {
    return undefined;
  }

  let incomeObj = {};

  if ('origem_renda' in incomeValues || 'renda_principal' in incomeValues) {
    incomeObj.principal = {
      origem: incomeValues.origem_renda,
      valor: incomeValues.renda_principal
    }
  }

  if ('origem_renda_secundaria' in incomeValues || 'outras_rendas' in incomeValues) {
    incomeObj.outra = {
      origem: incomeValues.origem_renda_secundaria,
      valor: incomeValues.outras_rendas
    }
  }

  if('avaliacao_financeira' in incomeValues && incomeValues.avaliacao_financeira == 'card') {
    incomeObj.principal = {
      origem: 13,
      valor: incomeValues.limite_cartao_credito
    }
    incomeObj.outra = {
      origem: ''
    }
  }

  if(incomeFormChangedValues && 'avaliacao_financeira' in incomeFormChangedValues && incomeFormChangedValues.avaliacao_financeira == 'card') {
    if(incomeValues.limite_cartao_credito) {
      incomeObj.principal = {
        origem: 13,
        valor: incomeFormChangedValues.limite_cartao_credito
      }
      incomeObj.outra = {
        origem: ''
      }
    }
  }

  if('avaliacao_financeira' in incomeValues && incomeValues.avaliacao_financeira == 'extratos') {
    incomeObj.principal = {
      origem: 15,
      valor: incomeValues.movimentacao_bancaria_extratos
    }
    incomeObj.outra = {
      origem: ''
    }
  }

  if(incomeFormChangedValues && 'avaliacao_financeira' in incomeFormChangedValues && incomeFormChangedValues.avaliacao_financeira == 'extratos') {
    if(incomeValues.movimentacao_bancaria_extratos) {
      incomeObj.principal = {
        origem: 15,
        valor: incomeFormChangedValues.movimentacao_bancaria_extratos
      }
      incomeObj.outra = {
        origem: ''
      }
    }
  }

  if('avaliacao_financeira' in incomeValues && incomeValues.avaliacao_financeira == 'nao-informado') {
    incomeObj.principal = {
      origem: 1,
      valor: 0,
      nao_informado: 1
    }
    incomeObj.outra = {
      origem: ''
    }
  }

  if(incomeFormChangedValues && 'avaliacao_financeira' in incomeFormChangedValues && incomeFormChangedValues.avaliacao_financeira == 'nao-informado') {
    if(incomeValues.movimentacao_bancaria_extratos) {
      incomeObj.principal = {
        origem: 1,
        valor: 0,
        nao_informado: 1
      }
      incomeObj.outra = {
        origem: ''
      }
    }
  }

  return incomeObj;
}

const getIncomePJObj = (incomeValues) => {
  if(!incomeValues) return undefined;
  if(!Object.keys(incomeValues).length) return undefined;
  return {
    // faturamento: incomeValues.faturamento_mensal,
    // tipo_negocio: incomeValues.tipo_negocio,
    // natureza_juridica: incomeValues.natureza_juridica
    receita_media_mensal: incomeValues.receita_media_mensal,
    capital_de_investimento: incomeValues.capital_de_investimento,
    patrimonio_estimado: incomeValues.patrimonio_estimado,
    nome_do_responsavel_pelas_informacoes: incomeValues.nome_do_responsavel_pelas_informacoes,
    faturamento_origem_id: incomeValues.avaliacao_financeira_fcdoc_pj
  }
}

const removeForEmptyValue = (value) => {
  if (!Array.isArray(value) && typeof value === 'object') {
    const keys = Object.keys(value);

    if (keys.length === 0) {
      return undefined;
    }

    return value;
  }

  if (Array.isArray(value)) {
    if (value.length === 0) {
      return undefined;
    }

    return value;
  }

  return value;
}

export const getApplicantObj = (applicantValues, incomeValues) => {
  let ddd = undefined
  let tel = undefined
  let telefones = {}
  if(incomeValues && incomeValues.tel_contato) {
    ddd = incomeValues.tel_contato.substr(0, 2)
    tel = incomeValues.tel_contato.substr(2)
    telefones.principal = {
      ddd,
      tel_contato: tel
    }
  }
  if(incomeValues && incomeValues.tel_contato_trabalho) {
    ddd = incomeValues.tel_contato_trabalho.substr(0, 2)
    tel = incomeValues.tel_contato_trabalho.substr(2)
    telefones.contato_fcrenda_trabalho = {
      ddd,
      tel_contato: tel
    }
  }
  if(Object.keys(telefones).length === 0) {
    telefones = undefined
  }
  return pickBy({
    tipo_pretendente: applicantValues.tipo_pretendente,
    residir: applicantValues.residir,
    compor_renda: incomeValues && incomeValues.compor_renda,
    participante: applicantValues.participante,
    nome: applicantValues.nome,
    cpf: applicantValues.cpf,
    telefones,
    renda: getIncomeObj(incomeValues),
  }, identity);
}

export const getApplicantPJObj = (applicantValues, incomeValues) => {
  let ddd = undefined
  let tel = undefined
  let telefones = undefined
  if(incomeValues && incomeValues.tel_contato_pj) {
    ddd = incomeValues.tel_contato_pj.substr(0, 2)
    tel = incomeValues.tel_contato_pj.substr(2)
    telefones = {
      principal: {
        ddd,
        tel_contato: tel
      }
    }
  }
  return pickBy({
    tipo_pretendente: applicantValues.tipo_pretendente,
    razao_social: applicantValues.razao_social,
    nome: applicantValues.nome_fantasia,
    cnpj: applicantValues.cnpj,
    tempo_fundacao: applicantValues.tempo_fundacao,
    uso_imovel: applicantValues.uso_imovel,
    telefones,
    // ddd,
    // tel_contato: tel,
    financeiro: getIncomePJObj(incomeValues),
    // faturamento: getIncomePJObj(incomeValues)
  }, identity);
}

export const getApplicantEditObj = ({ applicantForm = {}, incomeForm = {}, changedValues = {}, ...props }) => {
  const incomeFormChangedValues = changedValues
  let ddd, tel

  if('tel_contato' in incomeForm) {
    ddd = incomeForm.tel_contato.substr(0, 2)
    tel = incomeForm.tel_contato.substr(2)
  }

  return (
    {
      tipo_pretendente: applicantForm.tipo_pretendente,
      residir: applicantForm.residir,
      compor_renda: incomeForm.compor_renda,
      participante: applicantForm.participante,
      nome: applicantForm.nome,
      cpf: applicantForm.cpf,
      ddd: ddd,
      tel_contato: tel,
      renda: getIncomeObj(incomeForm, incomeFormChangedValues)
    }
  );
}

export const getApplicantPJEditObj = ({ applicantForm = {}, incomeForm = {} }) => {
  let ddd = undefined
  let tel = undefined
  // let telefones = undefined
  if(incomeForm && incomeForm.tel_contato_pj) {
    ddd = incomeForm.tel_contato_pj.substr(0, 2)
    tel = incomeForm.tel_contato_pj.substr(2)
    // telefones = {
    //   principal: {
    //     ddd,
    //     tel_contato: tel
    //   }
    // }
  }
  return {
    tipo_pretendente: applicantForm.tipo_pretendente,
    razao_social: applicantForm.razao_social,
    nome: applicantForm.nome_fantasia,
    cnpj: applicantForm.cnpj,
    tempo_fundacao: applicantForm.tempo_fundacao,
    uso_imovel: applicantForm.uso_imovel,
    ddd,
    tel_contato: tel,
    financeiro: getIncomePJObj(incomeForm)
  }
};

export const getRequestObj = (customer, products, formValues, entity = "PF") => {
  const { tenancyForm, applicantForm, incomeForm } = formValues;

  if (entity === "PF") {
    return {
      cliente: customer,
      produtos: products,
      locacao: getTenancyObj(tenancyForm),
      pretendente: getApplicantObj(applicantForm, incomeForm)
    }
  }

  return {
    cliente: customer,
    produtos: products,
    locacao: getTenancyPJObj({ ...tenancyForm, ...applicantForm }),
    pretendente: getApplicantPJObj(applicantForm, incomeForm)
  }
};

const importantFields = {
  tenancyForm: ['aluguel', 'condominio', 'iptu'],
  applicantForm: [],
  incomeForm: ['renda_principal', 'outras_rendas']
}

const importantFieldsPJ = {
  tenancyForm: ['aluguel', 'condominio', 'iptu'],
  applicantForm: [],
  incomeForm: []
}

export const getNotFilledImpoprtantFields = (allForms, entity) => {
  const allValues = getAllValuesByForm(allForms);
  const fieldsEntity = entity === "PF"? importantFields : importantFieldsPJ;

  const notFilled = Object.keys(fieldsEntity).reduce((acc, formName) => {
    const values = allValues[formName];

    if (values === undefined) {
      acc[formName] = fieldsEntity[formName];
    } else {
      const fields = fieldsEntity[formName].filter(field => values[field] === undefined || values[field] === '');
      if (fields.length > 0) {
        acc[formName] = fields;
      }
    }

    return acc;
  }, {});

  return notFilled;
}

export const getImportantFieldsWithWarning = (allForms) => {
  const syncWarnings = getNonRequiredFieldsWithWarningByForm(allForms);
  const importantFieldsWithWarning = Object.keys(importantFields).reduce((acc, formName) => {
    const warnings = syncWarnings[formName];

    if (warnings !== undefined) {
      const fields = importantFields[formName].filter(field => field in warnings);
      acc[formName] = fields;
    }

    return acc;
  }, {});

  return importantFieldsWithWarning;
}

const joinImportantInputsVerify = ({ notFilled, withWarnig }) => {
  const mergedObj = Object.keys(importantFields).reduce((acc, formName) => {
    const fillVerify = notFilled[formName] || [];
    const warningVerify = withWarnig[formName] || [];

    acc[formName] = uniq([...fillVerify, ...warningVerify]);
    return acc;
  }, {});

  return mergedObj;
}

export const getFieldsLabelByForm = (keysSource, usedFields) => {
  const fieldsToShow = joinImportantInputsVerify(keysSource);

  const labels = Object.keys(importantFields).reduce((acc, formName) => {
    if (Array.isArray(fieldsToShow[formName]) && fieldsToShow[formName].length > 0) {
      acc[formName] = getFieldsLabel(fieldsToShow[formName], usedFields);
    }

    return acc;
  }, {});

  return labels;
}