import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip'

import axios from 'axios';

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class CustomTooltip extends Component {
  constructor() {
    super();
    this.tip = React.createRef();
  }

  state = {
    tooltipOpen: false,
    position: 'right',
    innerstyle: {},
    loading: false
  }

  static defaultProps = {
    target: 'defaultTarget',
    position: 'right',
    customstyle: {}
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  mouseEnter = () => {
    if(!this.state.loading){
      console.log('toggle aqui', this.props.target)
      this.setState({loading: true})
      this.postAfterHoverTooltip()
      setTimeout((t) => {
        this.setState({loading: false})
        console.log('timed out finish!', t)
      }, 3000);
    }
  }

  postAfterHoverTooltip = async () => {

    // if(this.state.tourId) {
    //   return;
    // }

    try {
      const URL = API_NODE_V1_URL + '/tour-tooltip'

      const data = {
        email: this.props.login.user.usuario.email,
        tooltip_action: this.props.target,
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3 tooltip', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  get arrowColor() {
    const { position } = this.props;

    if (position.search(/left/g) > -1) {
      return 'border-left-color';
    }

    if (position.search(/top/g) > -1) {
      return 'border-top-color';
    }

    if (position.search(/bottom/g) > -1) {
      return 'border-bottom-color';
    }

    return 'border-right-color';
  }

  

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          top: -15,
          right: -15,
          ...this.props.style
        }}
        className="tour-tooltip"
        onMouseEnter={this.mouseEnter}
      >
        <p
          data-tip
          id={this.props.target}
          data-for={this.props.target}
          style={{
            borderRadius: '50%',
            width: 20,
            height: 20,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            backgroundColor: '#fff',
            color: '#152b4e',
            cursor: 'default',
            fontWeight: 800,
            fontSize: 12,
            border: '1px solid #152b4e'
          }}
        >
          ?
        </p>
        {/* <ReactTooltip id={this.props.target} place={this.props.position} type="dark" effect="solid">
          {this.props.message}
        </ReactTooltip> */}
        {/* <a data-tip data-for={this.props.target} margin="auto 2px">{this.props.children}</a>
        <ReactTooltip className='customTooltip' id={this.props.target} place="bottom" type="dark" effect="solid">
          {this.props.message}
        </ReactTooltip> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  login: state.login,
});

export default connect(mapStateToProps, null)(CustomTooltip);