
export const getRequestDataRequesting = (state) => ({
  ...state,
  data: {
    ...state.data,
    requesting: true,
    error: undefined
  }
});

export const getRequestDataSuccess = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    requesting: false,
    error: false,
    data: payload.locacao,
    customer: payload.cliente
  }
});

export const getRequestDataFailed = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    requesting: false,
    error: true
  }
});

export const onTenancyFormRequsting = (state) => ({
  ...state,
  requesting: true,
  error: undefined
});

export const onTenancyFormSuccess = (state) => ({
  ...state,
  requesting: false,
  error: false
});

export const onTenancyFormFailed = (state, payload) => ({
  ...state,
  requesting: false,
  error: payload
});