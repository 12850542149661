import { simpleRequiredSelect } from '@bit/wavfichacerta.fichacerta.fields-templates.select'
import { simpleRequiredText, simpleDisabledText } from '@bit/wavfichacerta.fichacerta.fields-templates.simple-text'
import { defaultCPF } from '../../../components/shared/form/fields'

const userTypeOptions = [
  { value: "", label: "Selecione" },
  { value: "ADICIONAL", label: "Adicional" },
  { value: "MASTER", label: "Master" },
]

export default (editMode = false, loggedUser = false, typeUserEdit = "") => {
  
  let fields = [];

  //se for a pagina de cadastro...
  if(!editMode){
    fields.push(
      {...simpleRequiredSelect({ size: 'field-fc field-fc-xs field-fc-sm-4', name: 'tipo_usuario', label: 'Tipo de usuário' }), options: userTypeOptions},
      simpleRequiredText({ size: 'field-fc field-fc-sm field-fc-sm-8', name: 'nome', label: 'Nome' }),
      defaultCPF('field-fc field-fc-xs field-fc-sm-6'),
      simpleRequiredText({ size: 'field-fc field-fc-xs field-fc-sm-6', name: 'identidade', label: 'Identidade' }),
      simpleRequiredText({ size: 'field-fc field-fc-xs field-fc-sm-4', name: 'orgao_expedidor', label: 'Órgão expedidor' }),
      simpleRequiredText({ size: 'field-fc field-fc-sm field-fc-sm-8', name: 'funcao', label: 'Função' }),
    )
  //se for a pagina de edição...  
  }else{
    fields.push(
      simpleDisabledText({ size: 'field-fc field-fc-sm field-fc-sm-8', name: 'nome', label: 'Nome' }),
      simpleDisabledText({ size: 'field-fc field-fc-xs field-fc-sm-6', name: 'cpf', label: 'CPF' }),
      simpleDisabledText({ size: 'field-fc field-fc-xs field-fc-sm-6', name: 'identidade', label: 'Identidade' }),
      simpleDisabledText({ size: 'field-fc field-fc-xs field-fc-sm-4', name: 'orgao_expedidor', label: 'Órgão expedidor' }),
      //se o usuario a ser editado for master e nao for o usuario logado...
      (typeUserEdit === "MASTER" && !loggedUser) 
            ? simpleRequiredText({ size: 'field-fc field-fc-sm field-fc-sm-8', name: 'funcao', label: 'Função' })
            : simpleRequiredText({ size: 'field-fc field-fc-sm field-fc-sm-8', name: 'funcao', label: 'Função' }),
    )
  }

  return [
    {
      name: 'firstLine',
      fields
    }
  ];
}
