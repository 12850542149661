
export const USERS_LIST_REQUEST = "usersTable/USERS_LIST_REQUEST";
export const USERS_LIST_SUCCESS = "usersTable/USERS_LIST_SUCCESS";
export const USERS_LIST_FAILED = "usersTable/USERS_LIST_FAILED";
export const USERS_LIST_CHANGE_ORDER = "usersTable/USERS_LIST_CHANGE_ORDER";
export const USERS_LIST_CHANGE_MAX_RESULTS = "usersTable/USERS_LIST_CHANGE_MAX_RESULTS";
export const USERS_LIST_CHANGE_PAGE = "usersTable/USERS_LIST_CHANGE_PAGE";
export const USERS_LIST_RESET_DATA = "usersTable/RESET_DATA";
export const RESEND_EMAIL_CONFIRMATION = "usersTable/RESEND_EMAIL_CONFIRMATION";
export const RESEND_EMAIL_CONFIRMATION_SUCCES = "usersTable/RESEND_EMAIL_CONFIRMATION_SUCCESS";
export const RESEND_EMAIL_CONFIRMATION_FAILED = "usersTable/RESEND_EMAIL_CONFIRMATION_FAILED";
export const CHANGE_USER_TYPE = "usersTable/CHANGE_USER_TYPE";
export const CHANGE_USER_TYPE_SUCCESS = "usersTable/CHANGE_USER_TYPE_SUCCESS";
export const CHANGE_USER_TYPE_FAILED = "usersTable/CHANGE_USER_TYPE_FAILED";
export const CHANGE_USER_STATUS = "usersTable/CHANGE_USER_STATUS";
export const CHANGE_USER_STATUS_SUCCESS = "usersTable/CHANGE_USER_STATUS_SUCCESS";
export const CHANGE_USER_STATUS_FAILED = "usersTable/CHANGE_USER_STATUS_FAILED"; 


