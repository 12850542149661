import React, { PureComponent } from 'react';

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  CardAnalythic,
  SubtitleCard,
  SubtitleSemiBold,
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  RecommendationBox,
  RecommendationText,
  RecommendationsColumn,
  IconRecommendation,
  SpanRecommendation,
  OtherDataList
} from '../analythic.styled';

import { getLocationChecked, getLocationCheckedBoth } from './products/fcEmpresa';
import Location from '@bit/wavfichacerta.fichacerta.icons.location';
import ReportRecommendation from '@bit/wavfichacerta.fichacerta.icons.report-recommendation';

export default class LocationSection extends PureComponent {

  renderDataLine = (title, data) => (
    <AnalythicLine key={(title + data)} style={{color: '#000'}}>
      <LineTitle maxWidth="12.5%">{title}</LineTitle>
      <span data-testid={title}>{data}</span>
    </AnalythicLine>
  )

  renderOtherAddresses = (title, data) => (
    <AnalythicLine key={(title + data)} style={{color: '#000'}}>
      <LineTitle maxWidth="12.5%">{title}</LineTitle>
      <span data-testid={title}>
        <OtherDataList>{data.map((address) => <li>{address}</li>)}</OtherDataList>
      </span>
    </AnalythicLine>
  )

  renderDataChecked = () => {
    const dataChecked = getLocationChecked(this.props.data);
    return dataChecked.map((line) => {
      if (line.key === 'otherAddress' && Array.isArray(line.data)) {
        return this.renderOtherAddresses(line.title, line.data);
      }
      return this.renderDataLine(line.title, line.data);
    });
  }

  renderDataCheckedBoth = () => {
    const dataChecked = getLocationCheckedBoth(this.props.data);
    return dataChecked.map((line) => {
      if (line.key === 'otherAddress' && Array.isArray(line.data)) {
        return this.renderOtherAddresses(line.title, line.data);
      }
      return this.renderDataLine(line.title, line.data);
    });
  }

  getRecommendations = () => {
    const { data } = this.props;

    if (data !== undefined && data !== null && (typeof data === "object" && Object.keys(data).length > 0)) {
      const searchKeys = ["veracidade_endereco", "veracidade_telefone", "veracidade_celular", "veracidade_email"];
      const { laudo } = data;
      let recommendations = [];
      searchKeys.forEach((key) => {
        if (laudo[key] !== undefined && laudo[key] !== null) {
          recommendations = [...recommendations, ...laudo[key].recommendation];
        }
      });

      if (Array.isArray(recommendations) && recommendations.length > 0) {
        // Remove duplicates
        recommendations = recommendations.filter((rec, index) => recommendations.indexOf(rec) === index);
        return (
          <RecommendationBox>
            <BaseColumn xs={12} md={1} lg={1} xl={1}>
              <RecommendationText>Obs: </RecommendationText>
            </BaseColumn>
            <RecommendationsColumn xs={12} md={11} lg={11} xl={11}>
              {recommendations.map((item) => (
                <RecommendationText>
                  <IconRecommendation><ReportRecommendation /></IconRecommendation>
                  <SpanRecommendation>{item}</SpanRecommendation>
                </RecommendationText>
              ))}
            </RecommendationsColumn>
          </RecommendationBox>
        );
      }
    }
    return null;
  }

  renderResultFcRenda = (key) => {
    const { data } = this.props;
    const fcDoc = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {};
    let title = '';
    switch(key) {
      case 'confirmacao_endereco':
        title = 'CONFIRMAÇÃO DE ENDEREÇO'
        break
      case 'confirmacao_pagamento_aluguel':
        title = 'CONFIRMAÇÃO DE PAGAMENTO DE ALUGUEL'
        break
    }
    if(!fcDoc.resultado || !fcDoc[key] || !fcDoc.ativo) {
      return null;
    }

    return(
      <div style={{border: '2px solid #152b4e', padding: '4px 10px', color: '#152b4e', fontSize: 12, marginTop: 6, width: '100%', fontWeight: 'bolder'}} className='result-fcdoc'>
        {title}: {fcDoc[key]}
      </div>
    )
  }

  render() {
    const { data } = this.props;
    const fcDoc = data.pretendente.produtos.ativo.filter(product => product.id === 6 || product.id === 8)

    return (
      <CardAnalythic
        title="DADOS DE LOCALIZAÇÃO (encontrados)"
        iconHeader={Location}
        iconLeft={true}
      >
        {fcDoc.length > 0 && fcDoc[0].ativo === true && data.laudo.produtos.fcrenda.resultado && (data.laudo.produtos.fcrenda.confirmacao_endereco || data.laudo.produtos.fcrenda.confirmacao_pagamento_aluguel) &&
          <span style={{backgroundColor: '#152b4e', color: '#fff', fontWeight: 600, padding: '2px 6px', marginLeft: 4, position: 'absolute', top: 12, left: 402}}>
            FC DOC
          </span>
        }

        <StyledRow margin=".5rem 0 0 0">
          <BaseColumn padding="0 .2rem">
            <ContainerGray data-testid="dadosLocalizacao">
              {/* <SubtitleCard>DADOS DE LOCALIZAÇÃO <SubtitleSemiBold>ENCONTRADOS</SubtitleSemiBold></SubtitleCard> */}
              <AnalythicList>
                {this.renderDataChecked()}
                {this.renderDataCheckedBoth()}
              </AnalythicList>
            </ContainerGray>
          </BaseColumn>
          {this.renderResultFcRenda('confirmacao_endereco')}
          {this.renderResultFcRenda('confirmacao_pagamento_aluguel')}
        </StyledRow>
      </CardAnalythic>
    );
  }
}
