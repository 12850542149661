import React from 'react';
import Styled from 'styled-components';


const StyledSvg = Styled.svg`
  width: ${props => props.width ? props.width : '10px'};
  fill: ${props => props.color ? props.color : props.theme.color_gray};
  display: ${props => props.display ? props.display : "inline-block"};
  margin: ${props => props.margin ? props.margin : "auto"};

  @media(max-width: 800px){
      fill: white;
      width: 14px;
  }
`;

const StyledCircle = Styled.circle`
    fill: none;
    stroke: #7f7f7f;
    stroke-miterlimit: 10;
    stroke-width: 6px;

`;

const StyledText = Styled.text`
    font-size: 180px;
    fill: #7f7f7f;
    font-family: TimesNewRomanPSMT, Times New Roman;

    @media(max-width: 800px){
        fill: white;
    }
`;

export default (props) => (
    <StyledSvg {...props} viewBox="0 0 185.84 207.79">
        <StyledCircle class="cls-1" cx="92.92" cy="92.92" r="89.92"/>
        <StyledText class="cls-2" transform="translate(67.92 152.59)">
            i
        </StyledText>
    </StyledSvg>
    
)