import React, { PureComponent } from 'react';
import CustomTable from '@bit/wavfichacerta.fichacerta.tables.custom-table';
import PricesHeader from './PricesHeader';
import PricesRow from './PricesRow';

const defaultColumns = [
  {
    title:'Produto',
    width: 213,
    align: 'left',
    paddingLeft: 10
  },
  {
    title:'Valor',
    width: 150,
    align: 'left',
    paddingLeft: 10
  },
  {
    title:'Desconto',
    width: 171,
    align: 'left',
    paddingLeft: 10
  },
  {
    title:'Serviços Extras',
    width: 327,
    align: 'left',
    paddingLeft: 10
  },
  {
    title:'Valor Final',
    width: 187,
    align: 'left',
    paddingLeft: 10
  }
];

class Prices extends PureComponent {
  itHasDiscount() {
    const { products, discounts } = this.props;

    if (Array.isArray(discounts) && discounts.length > 0) {

      const discountsSum = products.reduce((sum, prd) => {
        const value = discounts[prd.desconto_selecionado].valor;

        return (sum + parseFloat(value));
      }, 0);

      return discountsSum > 0;
    }

    return false;
  }

  removeColumn = (title, columns) => columns.filter(column => column.title !== title)

  get headerColumns() {
    const { services } = this.props;
    let dynamicColumns = defaultColumns;

    if (!this.itHasDiscount()) {
      dynamicColumns = this.removeColumn('Desconto', dynamicColumns);
    }

    if (Array.isArray(services) && services.length === 0) {
      dynamicColumns = this.removeColumn('Serviços Extras', dynamicColumns);
    }

    return dynamicColumns;
  }

  renderHeader = () => (
    <PricesHeader columns={this.headerColumns} />
  )

  getSelectedDiscount = (discounts, selectedId) => {
    if(discounts) return discounts.find(disct => disct.id ===selectedId)
  }

  renderLine = (product) => (
    <PricesRow
      key={product.titulo}
      {...product}
      desconto={this.getSelectedDiscount(product.desconto, product.desconto_selecionado)}
    />
  )

  render() {
    return (
      <CustomTable
        maxSize="57vh"
        lines={this.props.products}
        renderHeader={this.renderHeader}
        renderLine={this.renderLine}
      />
    );
  }
}

export default Prices;
