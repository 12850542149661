import React, { Component } from 'react';
import styled from 'styled-components';

const IconSVG = styled.svg`
	width: ${props => props.width ? props.width : "120px"};
	margin: ${props => props.margin ? props.margin : '0'};

	& > path {
		fill: ${props => props.fill ? props.fill : props.theme['color_purple']};
		opacity: ${props => props.opacity ? props.opacity : 1};
	}
`

export default class CompanyIcon extends Component {
	render() {
		return (
			<IconSVG { ...this.props } viewBox="0 0 470.161 470.161">
				<path d="M502.467,493.933H459.8V50.2h17.067c5.12,0,8.533-3.413,8.533-8.533V7.533c0-5.12-3.413-8.533-8.533-8.533H33.133     C28.013-1,24.6,2.413,24.6,7.533v34.133c0,5.12,3.413,8.533,8.533,8.533H50.2v443.733H7.533c-5.12,0-8.533,3.413-8.533,8.533     S2.413,511,7.533,511h51.2h238.933h102.4h51.2h51.2c5.12,0,8.533-3.413,8.533-8.533S507.587,493.933,502.467,493.933z      M41.667,16.067h426.667v17.067h-17.067H58.733H41.667V16.067z M306.2,493.933V357.4h34.133v136.533H306.2z M357.4,493.933V357.4     h34.133v136.533H357.4z M408.6,493.933V348.867c0-5.12-3.413-8.533-8.533-8.533h-102.4c-5.12,0-8.533,3.413-8.533,8.533v145.067     H67.267V50.2h375.467v443.733H408.6z" fill="#152b4e"/>
				<path d="M229.4,84.333h-68.267H92.867c-5.12,0-8.533,3.413-8.533,8.533v68.267c0,5.12,3.413,8.533,8.533,8.533h68.267H229.4     c5.12,0,8.533-3.413,8.533-8.533V92.867C237.933,87.747,234.52,84.333,229.4,84.333z M101.4,101.4h51.2v51.2h-51.2V101.4z      M220.867,152.6h-51.2v-51.2h51.2V152.6z" fill="#152b4e"/>
				<path d="M417.133,84.333h-68.267H280.6c-5.12,0-8.533,3.413-8.533,8.533v68.267c0,5.12,3.413,8.533,8.533,8.533h68.267h68.267     c5.12,0,8.533-3.413,8.533-8.533V92.867C425.667,87.747,422.253,84.333,417.133,84.333z M289.133,101.4h51.2v51.2h-51.2V101.4z      M408.6,152.6h-51.2v-51.2h51.2V152.6z" fill="#152b4e"/>
				<path d="M229.4,203.8h-68.267H92.867c-5.12,0-8.533,3.413-8.533,8.533V280.6c0,5.12,3.413,8.533,8.533,8.533h68.267H229.4     c5.12,0,8.533-3.413,8.533-8.533v-68.267C237.933,207.213,234.52,203.8,229.4,203.8z M101.4,220.867h51.2v51.2h-51.2V220.867z      M220.867,272.067h-51.2v-51.2h51.2V272.067z" fill="#152b4e"/>
				<path d="M229.4,340.333h-68.267H92.867c-5.12,0-8.533,3.413-8.533,8.533v68.267c0,5.12,3.413,8.533,8.533,8.533h68.267H229.4     c5.12,0,8.533-3.413,8.533-8.533v-68.267C237.933,343.747,234.52,340.333,229.4,340.333z M101.4,357.4h51.2v51.2h-51.2V357.4z      M220.867,408.6h-51.2v-51.2h51.2V408.6z" fill="#152b4e"/>
				<path d="M417.133,203.8h-68.267H280.6c-5.12,0-8.533,3.413-8.533,8.533V280.6c0,5.12,3.413,8.533,8.533,8.533h68.267h68.267     c5.12,0,8.533-3.413,8.533-8.533v-68.267C425.667,207.213,422.253,203.8,417.133,203.8z M289.133,220.867h51.2v51.2h-51.2     V220.867z M408.6,272.067h-51.2v-51.2h51.2V272.067z" fill="#152b4e"/>
			</IconSVG>

		)
	}
}