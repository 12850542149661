import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { PropTypes } from 'prop-types';
import { ContainerBreadcrumb, UlBreadcrumb } from './breadcrumb.styles';

export default class Breadcrumb extends PureComponent {
  render() {
    return (
      <ContainerBreadcrumb>
        <UlBreadcrumb className="breadcrumb">
          <li>
            <Link to="/intranet/dashboard">Início</Link>
          </li>
          {this.props.links.map( element => {
            if (element.link === undefined || element.link === null || element.link === "") {
              return (<li>{ element.label }</li>);
            }
            return (
              <li key={element.link}>
                <Link to={element.link}>{element.label}</Link>
              </li>
            );
          })}
          {this.props.actualLink && <li>{this.props.actualLink}</li>}
        </UlBreadcrumb>
      </ContainerBreadcrumb>
    );
  }
}


Breadcrumb.propTypes = {
  links: PropTypes.array.isRequired,
  actualLink: PropTypes.string.isRequired
}