import { formatDateToUS } from './formatter';

export const removeNonDigit = value => value.replace(/\D/g, '');
export const money = value => {
  if (value === "") {
    return value;
  }
  const amount = value.replace(/\./g, '').replace(',', '.');
  return parseFloat(amount);
}
export const date = value => {
  let tempVal = value.replace(/_/g, '');
  if (tempVal.length === 10) {
    return formatDateToUS(value);
  }
  return value;
}
