import React, { Component } from 'react';
const FcFraudeIcon = require('../../../img/png/ico-fc-fraude-id.png');

export default class FcFraude extends Component {
  render() {
    return (
      <img src={FcFraudeIcon} alt="" />
    )
  }
}
