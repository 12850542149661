export const getInterestChangedValues = (data, requirements) => {
  const indexFound = requirements.como_conheceu.findIndex((item) => item.value === data.como_conheceu);
  return {
    como_conheceu: indexFound >= 0 ? requirements.como_conheceu[indexFound].label : '',
    numero_imoveis: data.numero_imoveis.toString(),
    servicos_interesse: data.servicos_interesse
  }
};

export const AnalysisValues = (data) => ({
  responsavel_comercial: data.colaborador_comercial !== undefined && data.colaborador_comercial !== null? data.colaborador_comercial.nome : "",
  status_cliente: data.status,
  condicao_pagamento: data.condicao,
  observacao_cliente: data.observacao
});

export const getInitialValues = (state, requirements) => {
  if (state !== undefined && typeof state === "object" && "data" in state) {
    if ("cliente" in state.data) {
      const { cliente } = state.data;
      return {
        ...getInterestChangedValues(cliente.pesquisa, requirements),
        ...AnalysisValues(cliente)
      };
    }
  }
  return {};
}
