import React, { PureComponent } from 'react';
import { CardAnalythic } from '../analythic.styled';
import AmountContainer from './AmountContainer';
import ProfileContainer from './ProfileContainer';
import BusinessParticipation from './BusinessParticipation';
import RoundedMoney from '@bit/wavfichacerta.fichacerta.icons.rounded-money';

export default class RentAndJob extends PureComponent {

  render() {
    const { data } = this.props;
    const fcDoc = data.pretendente.produtos.ativo.filter(product => product.id === 2 || product.id === 7)
    const productFcReport = data.pretendente.produtos.ativo.filter(product => product.id === 1 && product.status !== 'INCLUIDO')
    const productFcEssencial = data.pretendente.produtos.ativo.filter(product => product.id === 3 && product.status !== 'INCLUIDO')
    const productFcEmpresa = data.pretendente.produtos.ativo.filter(product => product.id === 4)
    const productFcBasico = data.pretendente.produtos.ativo.filter(product => product.id === 5 && product.status !== 'INCLUIDO')
    const productFcCompletaPF = data.pretendente.produtos.ativo.filter(product => product.id === 7 && product.status !== 'INCLUIDO')
    // if(!productFcEssencial.length && !productFcReport.length) {
    //   return null;
    // }

    return (
      <CardAnalythic
        title="PERFIL FINANCEIRO"
        iconHeader={RoundedMoney}
        iconLeft={true}
      >
        {fcDoc.length > 0 && fcDoc[0].ativo === true && data.laudo.produtos.fcrenda.resultado &&
          <span className='font-13-print' style={{backgroundColor: '#152b4e', color: '#fff', fontWeight: 600, padding: '2px 6px', marginLeft: 4, position: 'absolute', top: 12, left: 222}}>
            FC DOC
          </span>
        }
        
        {(productFcEssencial.length || productFcReport.length || productFcCompletaPF.length) &&
          <AmountContainer data={data} /> || null
        }

        {(!productFcEssencial.length && !productFcReport.length && productFcBasico.length && fcDoc.length > 0 && fcDoc[0].ativo === true && data.laudo.produtos.fcrenda.resultado) &&
          <AmountContainer data={data} isFcBasico={true} /> || null
        }
        <ProfileContainer data={data} />
        <BusinessParticipation data={data} />
      </CardAnalythic>
    );
  }
}
