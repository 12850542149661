import React, { PureComponent } from 'react';

import ModalContainer from '../../../../../components/shared/modal/ModalContainer';
import { PrimaryButton, StyledRow, SecundaryButton } from '../../../../../components/shared/sharedStyles.styles';
import { Container, SpanTitle, GrayText } from '../../../request/form/fieldModal/formModalFields.styled';
import LoadingCircle from '@bit/wavfichacerta.fichacerta.icons.loading-circle';
import Loading from '@bit/wavfichacerta.fichacerta.icons.loading';
import styled from 'styled-components';

export const Wrapper = styled.div`
  label {
    display: block;
    width: 37px;
  }

  textarea {
    height: 24px;
    resize: none;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #7f7f7f91;
  }
`;

class ModalReportReviewNewReport extends PureComponent {

  componentWillUnmount() {
    console.log('DESMOTANDO MODAL PRINT')
  }

  renderFooter = () => {
    if(this.props.loading || this.props.error || this.props.success) {
      return null
    }

    return(
      <StyledRow padding='0 3em' justify="space-around">
        <PrimaryButton data-testid="send-data-button" onClick={this.props.onClose}>Cancelar</PrimaryButton>
        <PrimaryButton data-testid="go-back-button" onClick={this.props.undo} disabled={!this.props.reviewValueNumber ? true : false}>Enviar</PrimaryButton>
      </StyledRow>
    )
  }

  renderHeader = () => {
    if(this.props.success) {
      return null
    }

    return (
      <SpanTitle customstyle="margin: auto;">
        Olá {this.props.nome}, a FC quer saber a sua avaliação.
      </SpanTitle>
    )
  }

  renderBody = () => {
    if(this.props.error) {
      return 'Ocorreu um erro interno ao enviar sua avaliação. Se persistir o erro, contate a FC ANALISE.'
    }
    if(this.props.success) {
      return <p style={{marginBottom: 0, marginTop: 15}}>Agradecemos sua participação {this.props.nome}, seu feedback é fundamental para a FC ANALISE</p>
    }

    return (
      <Wrapper>
        <p>
          Atualizamos o modo de apresentar as informações em nosso laudo e gostaríamos de saber a sua opinião sobre essa novidade.
        </p>
        <p>Conta pra gente qual a sua avaliação?</p>
        <div style={{display: 'flex'}}>
          <div style={{marginTop: 25}}>Não gostei</div>
          <div>
            <label for="id_1">1</label>
            <input type="radio" id="id_1" name="review_field" value="1" onChange={this.props.onChangeReviewValue} />
          </div>
          <div>
            <label for="id_2">2</label>
            <input type="radio" id="id_2" name="review_field" value="2" onChange={this.props.onChangeReviewValue} />
          </div>
          <div>
            <label for="id_3">3</label>
            <input type="radio" id="id_3" name="review_field" value="3" onChange={this.props.onChangeReviewValue} />
          </div>
          <div>
            <label for="id_4">4</label>
            <input type="radio" id="id_4" name="review_field" value="4" onChange={this.props.onChangeReviewValue} />
          </div>
          <div>
            <label for="id_5">5</label>
            <input type="radio" id="id_5" name="review_field" value="5" onChange={this.props.onChangeReviewValue} />
          </div>
          <div>
            <label for="id_6">6</label>
            <input type="radio" id="id_6" name="review_field" value="6" onChange={this.props.onChangeReviewValue} />
          </div>
          <div>
            <label for="id_7">7</label>
            <input type="radio" id="id_7" name="review_field" value="7" onChange={this.props.onChangeReviewValue} />
          </div>
          <div>
            <label for="id_8">8</label>
            <input type="radio" id="id_8" name="review_field" value="8" onChange={this.props.onChangeReviewValue} />
          </div>
          <div>
            <label for="id_9">9</label>
            <input type="radio" id="id_9" name="review_field" value="9" onChange={this.props.onChangeReviewValue} />
          </div>
          <div>
            <label for="id_10">10</label>
            <input type="radio" id="id_10" name="review_field" value="10" onChange={this.props.onChangeReviewValue} />
          </div>
          <div style={{marginTop: 25}}>Gostei muito</div>
        </div>

        <br />
        <br />
        <div>
          <p>Comentários são bem vindos, fique à vontade</p>
          <textarea data-rows="1" tabindex="0" placeholder="Sua resposta" aria-label="Sua resposta" dir="auto" data-initial-dir="auto" data-initial-value="" badinput="false" onChange={this.props.onChangeCommentValue}></textarea>
        </div>
      </Wrapper>
    )
  }

  renderLoading = () => {
    if(this.props.loading) {
      return <div style={{marginTop: 20, marginBottom: -10}}>
          <Loading width="30px" height="30px" margin="0 auto" color="color_border-gray" />
        </div>
    }
  }

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        size="md"
        onClose={this.props.onClose}
        footer={this.renderFooter()}
        header={this.renderHeader()}
        customstyle='
          @media (max-width: 800px) {
            h5 {
              font-weight: 400;
            }
          }

          max-width: 640px;
        '
        customstyleModalFooter='
          @media (max-width: 800px) {
            button {
              width: 121px;
              height: 33px;
              font-size: .88rem;
              background-color: #152b4e;
              padding: 0;
              margin-top: 0;
              margin: 0 9px;
            }

            .row {
              justify-content: center;
              padding: 0;
            }
          }

          button:disabled,
          button:disabled:hover {
            background: #7f7f7f;
          }
        '
      >

        <Container
          customstyle="
            text-align: center;
            margin: 20px auto;

            span {
              font-size: 12px;
              font-weight: 400 !important;
            }

            @media (max-width: 800px) {
              margin: 10px auto;
            }
          "
        >
          <GrayText>
            {this.renderBody()}
          </GrayText>
          {this.renderLoading()}
        </Container>

      </ModalContainer>
    );
  }
}

export default ModalReportReviewNewReport;