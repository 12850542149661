
const showIncomeTotal = (fields) => {
  if ("renda_principal" in fields) {
    return [];
  }

  return ['total_renda'];
}

const showIncomeCardOrRenda = (fields) => {
  if ("avaliacao_financeira" in fields) {
    return [];
  }

  return ['limite_cartao_credito'];
}

/**
 * This function returns an array with the fields
 * what will be removed from the form. If the function
 * returns an empty array, all fields will appear to the
 * user.
 * 
 * @param entity <String>
 * @param fields <Array>
 */
export default (entity = 'PF', fields) => {
  let fieldsRemoved = [
    'total_renda',
    'limite_cartao_credito'
  ];

  if (fields !== undefined && (typeof fields === 'object' && Object.keys(fields).length > 0)) {
    fieldsRemoved = [];

    fieldsRemoved = [...showIncomeTotal(fields), ...showIncomeCardOrRenda(fields)];

    return fieldsRemoved;
  }

  return fieldsRemoved;
}