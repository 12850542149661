import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 80%;
  margin: auto;
  ${props => css`${props.customstyle}`};
`;

export const ContainerButton = styled.div`
  width: 30%;
  margin: auto;
  padding: 1.5rem 0;

  & > button { position: fixed; }
`;

export const SpanTitle = styled.span`
  color: ${props => props.theme.color_purple};
  margin: 0 1rem;
 ${props => css`${props.customstyle}`};
`;

export const ListModal = styled.ul`
  color: ${props => props.theme.color_gray};
  margin-top: .5rem; 
`;

export const GrayText = styled.span`
  font-size: 13px;
  font-weight: 500 !important;
  color: ${props => props.theme.color_gray};
  ${props => css`${props.customstyle}`};
`;

export const BlueText = styled.span`
  font-size: 13px;
  font-weight: 500 !important;
  color: ${props => props.theme.color_purple};
`;

export const OrderedList = styled.ol`
  color: ${props => props.theme.color_gray};
  font-size: 13px;
  font-weight: 500 !important;
  margin-top: .3rem;
  ${props => css`${props.customstyle}`};
`;

export const RetangularBorderedContainer = styled.div`
  width: 90%;
  border: 1px solid ${props => props.theme.color_purple};
  color: ${props => props.theme.color_purple};
  font-weight: 500 !important;
  font-size: 12px;
  padding: 10px 15px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px; 
  text-align:center;
`;
