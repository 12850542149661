import React from 'react';

import TableForReport from '../TableForReport';
import TableHeader from './TableHeader';
import TableLine from './TableLine';

const Table = ({ lines, pretendente }) => {
  const renderLine = (line) => <TableLine {...line} pretendente={pretendente} />;

  return (
    <TableForReport
      loading={false}
      lines={Array.isArray(lines) ? lines : [lines]}
      renderHeader={() => <TableHeader pretendente={pretendente} />}
      renderLine={renderLine}
    />
  );
}


export default Table;
