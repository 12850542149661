
import styled, { css } from 'styled-components';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';
import { Col } from 'reactstrap';

export const StyledInput = styled.input.attrs({ autoComplete: 'off' })`
  display: flex;
  flex: 1;
  outline: none;
  font-family: Open Sans; 
  border-color: transparent;
  background: transparent;
  padding-left: 0.1rem;
  height: 30px;
  transition: width .5s ease, border-color .5s ease;
  color: ${props => props.theme[props.color || 'input_color']};
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: 0px 10px 21px -13px rgba(0, 139, 255, 0.8);
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color:${props => props.theme.color_gray};
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color:${props => props.theme.color_gray};
  }
  :-ms-input-placeholder { /* IE 10+ */
    color:${props => props.theme.color_gray};
  }
  :-moz-placeholder { /* Firefox 18- */
    color:${props => props.theme.color_gray};
  }
`;

export const StyledTextArea = styled.textarea`
  display: flex;
  flex: 1;
  outline: none;
  font-family: Open Sans; 
  border-color: transparent;
  background: transparent;
  padding-left: 0.1rem;
  height: 30px;
  transition: width .5s ease, border-color .5s ease;
  color: ${props => props.theme.input_color};
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: 0px 10px 21px -13px rgba(0, 139, 255, 0.8);
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color:${props => props.theme.color_gray};
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color:${props => props.theme.color_gray};
  }
  :-ms-input-placeholder { /* IE 10+ */
    color:${props => props.theme.color_gray};
  }
  :-moz-placeholder { /* Firefox 18- */
    color:${props => props.theme.color_gray};
  }
`;

export const InputColContainer = styled(Col)`
  height: auto;
  padding: 0;
  margin: 0;
  outline: none;
  border-bottom: 1px solid ${props => props.disabled && props.theme['input_underline_default_color'] || props.theme[props.underlinecolor]};
  background-color: ${props => props.disabled && '#e9ecef' || 'transparent'};

  & > div > input { font-size: 15px; }

  ${(props) => {
    const { mediaTemplates: { laptops: { getMediaByReference } } } = props.theme;

    const smallFullHd = getMediaByReference('fullHD13Pols')`
      height: 22px;
    `;

    const small = getMediaByReference('small')`
      height: 22px;
    `;

    return `
        ${smallFullHd}
        ${small}
      `;

  }}
`;

export const MaskedContainer = styled.div`
  height: 100%;
  font-size: .72rem;
  display: flex;
  ${props => {
    if (props.moneyMask) {
      return css`
        &:before {
          content: 'R$';
          position: absolute;
          top: 7px;
          left: 1px;
          color: ${props => props.theme['color_gray']};
          font-size: .75rem;
        }
      `
    }

    return ''
  }}
`;

export const StyledMaskedInput = styled(MaskedInput)`
  display: flex;
  flex: 1;
  outline: none;
  font-family: Open Sans; 
  border-color: transparent;
  background: transparent;
  padding-left: ${props => props.moneyMask ? '1.1rem' : '0.1rem'};
  transition: width .5s ease, border-color .5s ease;
  color: ${props => props.theme.input_color};
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: 0px 10px 21px -13px rgba(0, 139, 255, 0.8);
  }
`;

export const SelectColContainer = styled(InputColContainer)`
  padding: 0;
  position: relative;
  margin: 0;
`;

export const StyledSelect = styled(Select)`
  background: transparent;
  border: none;
  font-size: .8rem;
  -webkit-appearance: none;
  -webkit-padding-end: 20px;
  -webkit-padding-start: 2px;
  background-position: 97% center;
  background-repeat: no-repeat;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border: none;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: 0px 10px 21px -13px rgba(0, 139, 255, 0.8);
  }
`;

export const StyledSelectValue = styled.span`
  color: ${props => props.theme.input_color};
  font-size: 0.9rem;
  margin: 0;
  padding-left: 10px;
`;

export const StyledSelectArrow = styled.div`
  color: ${props => props.theme.input_color};
  ${props => {
    if (props.isOpen) {
      return 'transform: rotate(180deg); padding-bottom: 0.7rem;'
    }

    return '';
  }};
  ${props => css`${props.customStyle}`};
`;

export const StyledSelectArrowContainer = styled.div`
  position: absolute;
  bottom: .5rem;
  right: 0;
  ${props => css`${props.customStyle}`};
`;

export const StyledTextInputContainer = styled(Col)`
  margin-top: 1.5rem;
  background-color: transparent;

  & > div > label { margin-bottom: 6px !important; }

  div.Select-placeholder {
    padding-left: 5px;
  };

  div.Select-value {
    padding-left: 0 !important;
  }

  div.Select-option {
    &:hover {
      opacity: 1 !important;
      background-color: ${props => props.theme['color_ligth-gray']} !important;
    }
  }

  &.field-fc-small {
    flex-basis: unset;
    flex-grow: unset;
    width: 12.5%;
    max-width: 12.5%;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 0px;
    margin-left: 0px;

    ${(props) => {
      const { getMedias: { iphones } } = props.theme;
      return `
            ${iphones()`
              width: 100%;
              max-width: 100%;
              `
        }`;
    }}

    ${(props) => {
      const { mediaTemplates: { laptops: { getMediaByReference } } } = props.theme;


      const smallFullHd = getMediaByReference('fullHD13Pols')`
        margin-top: -10.5% !important;
        margin-bottom: 0px !important;
      `;
      const small = getMediaByReference('small')`
        margin-top: -7.5% !important;
        margin-bottom: 0px !important;
      `;

      return `
        & > div > label {
          ${smallFullHd}
          ${small}
          font-size: .72rem;
        }
      `;
    }}

    & > div > div { height: 26px; }
    & > div > div > div {
      &::before {
        top: 3px !important;
      }
    }

    & input { font-size: 13px; }

    & > div > div > input {
      font-size: 13px;
      height: 22px;
    }

    & > div > div > select {
      font-size: 13px;
      height: 26px;
    }
  }

  &.field-fc-medium {
    flex-basis: unset;
    flex-grow: unset;
    width: 25%;
    max-width: 25%;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 0px;
    margin-left: 0px;

    ${(props) => {
      const { getMedias: { iphones } } = props.theme;
      return `
          ${iphones()`
            width: 100%;
            max-width: 100%;
            `
        }`;
    }}

    ${(props) => {
      const { mediaTemplates: { laptops: { getMediaByReference } } } = props.theme;

      const smallFullHd = getMediaByReference('fullHD13Pols')`
        margin-top: -10.5% !important;
        margin-bottom: 0px !important;
      `;
      const small = getMediaByReference('small')`
        margin-top: -7.5% !important;
        margin-bottom: 0px !important;    
      `;

      return `
        & > div > label {
          ${smallFullHd}
          ${small}
          font-size: .72rem;
        }
      `;
    }}

    & > div > div { height: 26px; }
    & > div > div > div {
      &::before {
        top: 3px !important; 
      }
    }

    & input { font-size: 13px; }

    & > div > div > input {
      font-size: 13px;
      height: 22px;
    }

    & > div > div > select {
      font-size: 13px;
      height: 26px;
    }
  }

  &.field-fc-large {
    flex-basis: unset;
    flex-grow: unset;
    width: 75%;
    max-width: 75%;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 0px;
    margin-left: 0px;

    ${(props) => {
      const { getMedias: { iphones } } = props.theme;
      return `
          ${iphones()`
            width: 100%;
            max-width: 100%;
            `
        }`;
    }}

    ${(props) => {
      const { mediaTemplates: { laptops: { getMediaByReference } } } = props.theme;

      const smallFullHd = getMediaByReference('fullHD13Pols')`
        margin-top: -10.5% !important;
        margin-bottom: 0px !important;
      `;
      const small = getMediaByReference('small')`
        margin-top: -7.5% !important;
        margin-bottom: 0px !important;
      `;

      return `
        & > div > label {
          ${smallFullHd}
          ${small}
          font-size: .72rem;
        }
      `;
    }}

    & > div > div { height: 26px; }
    & > div > div > div {
      &::before {
        top: 3px !important; 
      }
    }
    
    & input { font-size: 13px; }

    & > div > div > input {
      font-size: 13px;
      height: 22px;
    }

    & > div > div > select {
      font-size: 13px;
      height: 26px;
    }
  }

  &.read-only {
    ${InputColContainer} {
      display: flex;

      &::before {
        content: 'R$';
        margin-top: 2px;
        margin-left: 1px;
        color: #7f7f7f;
        font-size: .75rem;
      }
    }
  }

  @media (max-width: 800px) {
    margin-top: 2.5rem !important;

    & input { font-size: 12px !important; }

    &.field-fc-sm-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }

    &.field-fc-sm-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    &.field-fc-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &.field-fc-sm-4 {
      flex: 0 0 38.5%;
      max-width: 38.5%;
    }

    &.field-fc-sm-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    &.field-fc-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &.field-fc-sm-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    &.field-fc-sm-8 {
      flex: 0 0 61.5%;
      max-width: 61.5%;
    }

    &.field-fc-sm-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    &.field-fc-sm-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    &.field-fc-sm-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }

    &.field-fc-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    div > label {
      position: absolute;
      bottom: 20px !important;
      color: #7f7f7f;
    }

    &.read-only {
      label + div {
        background-color: #e9ecef;
      }
    }
  }
`;

export const StyledLabel = styled.label`
  font-size: .8rem;
  font-weight: 600;
  margin-top: 3px;
  color: ${props => props.theme[props.color || 'input_label_color']};
  
  ${props => css`${props.customStyle}`};
`;

export const StyledErrorSpan = styled.span`
  font-size: .6rem;
  color: ${props => props.theme['color_red']};
  position: absolute;
`;

export const StyledHelperLine = styled.span`
  font-size: .6rem;
  color: ${props => props.theme.input_color};
  position: absolute;
`;

export const StyledErrorSpanForCheckboxGroup = styled.span`
  font-size: .6rem;
  color: ${props => props.theme['color_red']};
  position: absolute;
  bottom: -.5rem;
`;

export const SelectPlaceholder = styled.span`
  color:${props => props.theme.color_gray};
`;

export const StyledCheckBox = styled.div`
  color: ${props => props.theme.input_color};
  width: 100%;
  display: inline-block;
`;


export const StyledCheck = styled.input`
  position: absolute;
  opacity: 0;
  top: 6px;
  z-index: 20;
  cursor: pointer;
  width: 100%;

  &:checked + label:before,
  &:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 6px;
    width: 13px;
    height: 13px;
    border: 1px solid ${props => props.theme[props.boxColor || 'checkbox_color']};
    background: transparent;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '✔';
    position: absolute;
    top: -4px;
    left: 1px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    color: ${props => props.theme['color_light-green']};
    font-size: 1.4em;
    font-family: 'Open Sans' !important;
  }

  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  `;

export const StyledRadio = styled.input`
  position: absolute;
  opacity: 1;
  left: -15px;
  top: 6px;
  z-index: 20;
  cursor: pointer;

&:checked + label:before,
&:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: -15px;
  top: 6px;
  width: 12px;
  height: 12px;
  border: 1px solid ${props => props.theme.input_color};
  background: transparent;
  border-radius: 100%;
}

&:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

&:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
`;

export const EditableTableSelectContainer = styled.div`
  display: table-cell;
  color:${props => props.theme.color_gray};
  ${StyledSelectArrowContainer} {
    margin-right: 5px;
  }

  div.Select-placeholder {
    padding-left: 5px;
  };

  div.Select-value {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 3px;

    span {
      color:${props => props.theme.color_gray} !important;
    }
  }

  div.Select-arrow-zone {
    width: 15px;
  }

  div.Select-option {
    &:hover {
      opacity: 1 !important;
      background-color: ${props => props.theme['color_ligth-gray']} !important;
    }
  }
`;

export const CheckboxContainer = styled.div`
  position: relative;
  width: 160px;
  ${props => css`${props.customstyle}`};
`;
