import React, { PureComponent } from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  SubtitleCard,
  RecommendationBox,
  RecommendationText,
  RecommendationsColumn,
  IconRecommendation,
  SpanRecommendation,
  RiskText
} from '../analythic.styled';
import { getBusinessParticipation, getBusinessRecommendations } from './products/fcAnalise';
import ReportRecommendation from '@bit/wavfichacerta.fichacerta.icons.report-recommendation';
import { company } from '../../../../pages/operational/request/report/singleReport/actions';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../shared/TourTooltip';

class BusinessParticipation extends PureComponent {
  constructor(props){
    super(props)

    this.business = React.createRef()
  }

  componentDidMount(){
    this.props.company(this.business.current)
  }

  renderEmptyBusinessParticipation = () => {
    return (
      <StyledRow margin="1rem 0 0 0">
        <ContainerGray ref={this.business} className="company font-13-print">
          <StyledRow align="initial" margin="0">
            <BaseColumn xs={12} md={6} lg={6} xl={6}>
              <AnalythicList>
                <AnalythicLine background="transparent" style={{color: '#000'}}>
                  <LineTitle maxWidth="205px">PARTICIPAÇÃO EMPRESARIAL*</LineTitle>
                </AnalythicLine>
              </AnalythicList>
            </BaseColumn>
            <BaseColumn>
              <AnalythicList>
                <AnalythicLine background="transparent" style={{color: '#000'}}>
                  <span>NÃO ENCONTRADA*</span>
                </AnalythicLine>
              </AnalythicList> 
            </BaseColumn>
          </StyledRow>
          <RiskText style={{fontStyle: 'italic', fontSize: 11, fontWeight: 500, color: '#152b4e'}}>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de participações do pesquisado.</RiskText>
        </ContainerGray>
      </StyledRow>
    );
  }

  renderRecommendations = () => {
    const { data } = this.props;
    const recommendations = getBusinessRecommendations(data);
    if (Array.isArray(recommendations) && recommendations.length > 0) {
      return (
        <RecommendationBox margin=".5rem 0 0 0">
          <BaseColumn xs={12} md={1} lg={1} xl={1}>
            <RecommendationText style={{color: '#000'}}>Obs: </RecommendationText>
          </BaseColumn>
          <RecommendationsColumn xs={12} md={11} lg={11} xl={11}>
            {recommendations.map((item) => (
              <RecommendationText>
                <IconRecommendation><ReportRecommendation /></IconRecommendation>
                <SpanRecommendation style={{color: '#000'}}>{item}</SpanRecommendation>
              </RecommendationText>
            ))}
          </RecommendationsColumn>
        </RecommendationBox>
      );
    }
    return null;
  }

  renderParticipations = (list) => {
    if (Array.isArray(list) && list.length > 0) {
      return list.map((list, index) => (
        <StyledRow key={index} align="initial" className='font-13-print' margin={index > 0? "1rem 0" : "0"}>
          {index === 0 && (
          <BaseColumn xs={12} md={12} lg={12} xl={12}>
            <SubtitleCard style={{color: '#000'}}>PARTICIPAÇÃO EMPRESARIAL*</SubtitleCard>  
            {/* <RiskText style={{fontStyle: 'italic', fontSize: 13}}>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de apontamentos do pesquisado.</RiskText> */}
            {/* <TourTooltip
              target="tooltip-participacao-empresarial"
              style={{right: -3, top: -17}}
            />
            <ReactTooltip id="tooltip-participacao-empresarial" place="left" type="dark" effect="float">
              <span>Aqui demonstramos possíveis participações empresariais.</span>
            </ReactTooltip> */}
          </BaseColumn>
          )}
          <BaseColumn margin="0 .5rem 0 0">
            <AnalythicList>
              <AnalythicLine style={{color: '#000'}}>
                <LineTitle maxWidth="205px">Nome</LineTitle>
                <span>{list.name}</span>
              </AnalythicLine>
              <AnalythicLine style={{color: '#000'}}>
                <LineTitle maxWidth="205px">CNPJ</LineTitle>
                <span>{list.cnpj}</span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn>

          <BaseColumn margin="0 0 0 .5rem">
            <AnalythicList>
              <AnalythicLine style={{color: '#000'}}>
                <LineTitle maxWidth="205px">Participação</LineTitle>
                <span>{list.participation}</span>
              </AnalythicLine>
              <AnalythicLine style={{color: '#000'}}>
                <LineTitle maxWidth="205px">Data de entrada</LineTitle>
                <span>{list.date}</span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn>
        </StyledRow>
      ));
    }
    return null;
  }

  render() {
    const { data } = this.props;
    const participation = getBusinessParticipation(data);

    if (!participation.hasParticipation) {
      return this.renderEmptyBusinessParticipation();
    }

    return (
      <StyledRow margin="1rem 0 0 0" className="company font-13-print">
        <ContainerGray ref={this.business}>
          {this.renderParticipations(participation.list)}
          <RiskText style={{fontStyle: 'italic', fontSize: 11, fontWeight: 500, color: '#152b4e'}}>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de participações do pesquisado.</RiskText>
        </ContainerGray>
        { this.renderRecommendations() }
      </StyledRow>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  company
}, dispatch)

export default connect(null, mapDispatchToProps)(BusinessParticipation)