import { instance as axios } from './axiosInstance'
import { statusHandler } from '../utils/loginHelper'

const getPrintLaudoEndpoint = (id) => `/api/solicitacao/${id}/laudo/print`

const getHeader = (token) => (
  {
  'Authorization': `Bearer ${token}`
  }
)

export const getPrintLaudo = (token, id) => {
  const headers = getHeader(token)
  
  return axios.get(getPrintLaudoEndpoint(id), { validateStatus: statusHandler, headers })
}
