import React, { PureComponent } from 'react';

import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import { PrimaryButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { Container, SpanTitle, GrayText } from '../form/fieldModal/formModalFields.styled';

class InsuficientCreditsModal extends PureComponent {

  renderFooter = () => (
    <StyledRow padding='0 3em' justify="center">
      <PrimaryButton data-testid="go-back-button" onClick={this.props.closeModal} >OK</PrimaryButton>
    </StyledRow>
  )

  renderHeader = () => (
    <SpanTitle customstyle="margin: auto;">
      CRÉDITOS INSUFICIENTES
    </SpanTitle>
  )

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        size="md"
        onClose={this.props.closeModal}
        footer={this.renderFooter()}
        header={this.renderHeader()}
      >

        <Container customstyle="text-align: center; margin: 20px auto;">
          <GrayText>
            <span style={{fontSize: 20}} >Ops!</span>
            <br/>
            Seu crédito é insuficiente para esta solicitação. Por favor, entre em contato com o responsável pelo seu contrato ou com nosso atendimento.
          </GrayText>
        </Container>

      </ModalContainer>
    );
  }
}

export default InsuficientCreditsModal;