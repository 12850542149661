import Styled from 'styled-components';

export const LinesContainer = Styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
        background-color: ${props => `${props.background} !important`}
    }
`;

export const LawsuitLine = Styled.div`
    width: 49.5%;
    display: inline-flex;
    margin: .1rem 0;
    flex-direction: row;
    align-items: flex-start;
    color: #7f7f7f;
    padding: .25rem;
    font-size: 12px
`;