import styled, { css } from 'styled-components'
import { MainContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';

export const StyledSpan = styled.span`
  font-size: ${props => props.size || '1em'};
  color: ${props => props.theme[props.color || 'color_black']};
  text-align: ${props => props.align};
  ${props => css`${props.styles}`};
`;

export const StyledMainComponent = styled(MainContainer)`
  margin-top: 50px;
  padding-left: 0 !important;

  @media(max-width: 800px){
    width: 96%;
  }

`;
