import React, { PureComponent } from 'react';
import moment from 'moment';

import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import { PrimaryButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { SpanTitle, RetangularBorderedContainer } from './loginModal.styled';
import CheckBox from '@bit/wavfichacerta.fichacerta.inputs.check-box';

class LoginModal extends PureComponent {
  state = {
    isOpen: false
  }

  async componentDidMount() {
    const time = await localStorage.getItem('requestModalTime');

    if (time !== null && time !== undefined && moment(time).isValid) {
      const today = moment();
      const oldDate = moment(time);

      // em produção modificar hours para days
      const passedTime = today.diff(oldDate, 'days');

      // em produção modificar 1 para 30
      if (passedTime >= 30) {
        this.setState({ isOpen: true });
      }

    } else {
      this.setState({ isOpen: true });
    }
  }

  onConfirm = () => {
    const { suspend, isOpen } = this.state;

    if (suspend && isOpen) {
      localStorage.setItem('requestModalTime', moment().format());
    }

    this.closeModal();
  }

  renderFooter = () => (
    <StyledRow>
      <PrimaryButton onClick={this.onConfirm}>OK</PrimaryButton>
    </StyledRow>
  )

  renderHeader = () => (
    <SpanTitle>
      Aviso
    </SpanTitle>
  )

  closeModal = () => {
    this.setState({ isOpen: false });
  }

  onChange = (e) => {
    const { target: { checked } } = e;

    this.setState({ suspend: checked });
  }

  render() {
    return (
      <ModalContainer
        isOpen={this.state.isOpen}
        size="sm"
        onClose={this.closeModal}
        footer={this.renderFooter()}
        header={this.renderHeader()}
      >

        <RetangularBorderedContainer>
       
          PLANTÃO ATENDIMENTO/SUPORTE: Informamos que manteremos plantão especial de atendimento/suporte remoto nos dias 26, 29, 30, 31/03 e 01/04, mesmo com a decretação de feriado em algumas regiões do país. Ressaltamos, que primando pela segurança de todos, nossa equipe permanece em trabalho remoto, mesmo nos dias fora dos feriados.
        
        </RetangularBorderedContainer>

      </ModalContainer>
    );
  }
}


export default LoginModal;
