import { simpleRequiredText } from '@bit/wavfichacerta.fichacerta.fields-templates.simple-text';

const sizes = {
  subject: "field-fc field-fc-l",
  message: 'field-fc field-fc-l'
}

export const messageFields = [
  {
  name: 'firstLine',
  fields: [
    simpleRequiredText({name: 'assunto', size: sizes.subject})
  ]
},
]