import React, { PureComponent, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import ReactTooltip from 'react-tooltip'
import { uniqBy } from 'lodash';

import {
  getRequest,
  removeApplicantFromRequest,
  startReport,
  resetApplicantInfos,
  getUserInfos
} from "./actions";
import { prepareReport } from "../report/actions";
import { resetForm } from "../form/actions";

import Header from "../../../../components/Header";
import { Col as col, Row as row } from "reactstrap";
import { MainContainer as maincontainer } from "@bit/wavfichacerta.fichacerta.shared-styles.containers";
import { StyledRow } from "@bit/wavfichacerta.fichacerta.shared-styles.rows";
import {
  FullColumn,
  BaseColumn
} from "@bit/wavfichacerta.fichacerta.shared-styles.columns";
import {
  Title,
  ContainerButtons,
  ResumeButton,
  ApplicantsContainer
} from "./requestResume.styles";
import ApplicantCard from "../../../../components/Operational/ApplicantCard";

import BlueSteps from "../../../../components/Operational/request/BlueSteps";
import { LoadingOperational } from "../../../../components/Operational/operational.styles";
import ResumeSideContent from "./ResumeSideContent";
import { ContainerGifLoading } from "@bit/wavfichacerta.fichacerta.tables.styles";
import RemoveApplicantModal from "./RemoveApplicantModal";
import ReinclusionModal from "./ReinclusionModal";
import DontTodayReinclusionModal from "./DontTodayReinclusionModal";
import LoadingCircle from "@bit/wavfichacerta.fichacerta.icons.loading-circle";
import InsuficientCreditsModal from "./InsuficientCreditsModal";
import AddPretenderProductModal from "./AddPretenderProductModal";
import IncludedProductModal from "./IncludedProductModal";
import ModalPixQrCode from "./ModalPixQrCode";
import ModalAlertaPagamentoPendente from "./ModalAlertaPagamentoPendente";
import EditsCountModal from "./EditsCountModal";
import AddIcon from "../../../../components/shared/icons/AddIcon";
import TourTooltip from '../../../../components/shared/TourTooltip';
import FCRendaProcessModal from "./FCRendaProcessModal";
import FCCompletaProcessModal from "./FCCompletaProcessModal";
import { resolve } from "url";
import { reject } from "q";
import axios from 'axios';

import { instance as axiosInstance } from './../../../../services/axiosInstance';

import Tour from 'reactour'

import { toastr } from 'react-redux-toastr';

import { getClientProducts } from './../../../operational/request/actions';

export const Main = styled.div`
  @media (min-width: 801px) {
    width: calc(100% - 65px);
    margin-left: auto;
  }

  @media (max-width: 800px) {
    margin-top: 42px;
  }
`;

export const MainContainer = styled(maincontainer)`
  @media (min-width: 801px) {
    width: 98%;
    min-width: 98%;
  }

  @media (min-width: 1256px) {
    width: 81%;
    min-width: 1177px;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding-left: 0;
  }
`;

const Row = styled(row)`
  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

const Col = styled(col)`
  ${({ customstyle }) => customstyle}
`;

const breadcrumb = [
  { link: "", label: "operacional" },
  { link: "/intranet/operacional/solicitacao", label: "Locações" }
];

let steps = [
  {
    selector: '.botao-adicionar-pretendente',
    content: 'Inclua na mesma análise, todos os participantes da locação',
  },
  {
    selector: '.botao-editar-pretendente',
    content: <span>A qualquer momento você pode <b>editar</b> a renda informada, ou até mesmo <b>adicionar</b> a renda extra</span>,
  },
  {
    selector: '.botao-editar-pretendente',
    content: <span><b>Dica FC:</b> Caso precise desconsiderar um pretendente basta editar a condição dele para <b>outros</b>. Assim ele deixará de ser citado no Parecer da locação.</span>,
  },
  {
    selector: '.botao-editar-locacao',
    content: <span>É possível <b>editar</b> o valor do aluguel + encargos, e o tipo de imóvel pretendido.</span>,
  },
  {
    selector: '[title="FC DOC"]',
    content: <span>Conte com nosso time de especialistas para a <b>análise</b> de documento de <b>comprovação de renda</b>. Para isso, basta nos enviar por aqui.</span>
  },
  {
    selector: '.botao-ver-analise',
    content: 'Clique aqui para conferir a ANÁLISE.'
    // content: 'Para obter o laudo da FC clique aqui.',
  },
  // ...
]

const steps_fcempresa = [
  {
    selector: '.botao-adicionar-pretendente',
    content: 'Inclua na mesma análise, todos os participantes da locação',
  },
  {
    selector: '.botao-editar-pretendente',
    content: <span>A qualquer momento você pode <b>editar</b> a renda informada, ou até mesmo <b>adicionar</b> a renda extra</span>,
  },
  {
    selector: '.botao-editar-pretendente',
    content: <span><b>Dica FC:</b> Caso precise desconsiderar um pretendente basta editar a condição dele para <b>outros</b>. Assim ele deixará de ser citado no Parecer da locação.</span>,
  },
  {
    selector: '.botao-editar-locacao',
    content: <span>É possível <b>editar</b> o valor do aluguel + encargos, e o tipo de imóvel pretendido.</span>,
  },
  {
    selector: '.botao-ver-analise',
    content: 'Clique aqui para conferir a ANÁLISE.'
    // content: 'Para obter o laudo da FC clique aqui.',
  },
  // ...
]

const steps_fcrenda_incluido = [
  {
    selector: '.botao-adicionar-pretendente',
    content: 'Inclua na mesma análise, todos os participantes da locação',
  },
  {
    selector: '.botao-editar-pretendente',
    content: <span>A qualquer momento você pode <b>editar</b> a renda informada, ou até mesmo <b>adicionar</b> a renda extra</span>,
  },
  {
    selector: '.botao-editar-pretendente',
    content: <span><b>Dica FC:</b> Caso precise desconsiderar um pretendente basta editar a condição dele para <b>outros</b>. Assim ele deixará de ser citado no Parecer da locação.</span>,
  },
  {
    selector: '.botao-editar-locacao',
    content: <span>É possível <b>editar</b> o valor do aluguel + encargos, e o tipo de imóvel pretendido.</span>,
  },
  {
    selector: '[title="FC DOC"]',
    content: 'Observe que a solicitação do FC DOC consta como incluída.'
  },
  {
    selector: '.botao-processar-analise',
    content: 'Clique em processar análise para que a solicitação seja direcionada ao analista. Prazo de 1 hora útil para o resultado. Não se preocupe você será notificado por e-mail.'
    // content: 'Para obter o laudo da FC clique aqui.',
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class RequestResume extends PureComponent {
  state = {
    editMode: false,
    removeModalOpen: false,
    insuficientCredits: false,
    fcRendaProcess: false,
    fcCompletaProcess: false,
    editsCountModal: false,
    goToReport: false,
    typeOfReport: "show",
    reinclusionModalInfo: {
      id: null,
      products: null,
      entity: "PF",
      open: false,
      selectedProduct: null
    },
    dontReinclusionModalInfo: {
      open: false
    },
    modalMobileIsOpen: false,
    fromWhere: null,
    hasRating: false,
    tourOpen: false,
    tourId: null,
    openAddPretenderProductModal: false,
    openIncludedProductModal: false,
    openPixModal: false,
    openAlertaPagamentoPendenteModal: false,
    emailValue: ''
  };

  componentDidMount() {
    const {
      location: { state }
    } = this.props;
    if (state !== undefined) {
      this.props.getRequest(state.request);
      this.props.getUserInfos();
    }

    this.props.getClientProducts()
    this.getRatingAll()
    this.getCheckOnboard()
    this.props.resetForm()
    this.handleCheckModalAddPretenderProduct()
  }

  componentWillUnmount() {
    this.props.resetApplicantInfos();
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'resumo-solicitacao') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'resumo-solicitacao'
      }

      const result = await axios.post(URL, data);
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'resumo-solicitacao',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  getPrintRecibo = async () => {
    try {
      toastr.info("Aguarde alguns instantes, estamos gerando o arquivo de impressão.", {timeOut: 0, extendedTimeOut: 0})
      const token = this.props.login.user.token.access_token
      const requestId = this.props.infos.id
      const URL = `/api/solicitacao/${requestId}/recibo`

      const headers = {
        'Authorization': `Bearer ${token}`
      }

      const result = await axiosInstance.get(URL, {headers});
      if(result.status === 401) {
        this.props.history.push('/auth')
      }
      window.open(result.data, '_blank')
      toastr.removeByType('info')
    } catch (err) {
      console.log('ERR:', err)
      toastr.error(err.message)
    }
  }

  postCriarCobrancaAVista = async () => {
    try {
      toastr.info("Aguarde alguns instantes, estamos criando a cobrança.", {timeOut: 0, extendedTimeOut: 0})
      const token = this.props.login.user.token.access_token
      const requestId = this.props.infos.id
      const URL = `/api/solicitacao/${requestId}/criar-cobranca`
      const headers = {
        'Authorization': `Bearer ${token}`
      }
      const result = await axiosInstance.post(URL, {}, {headers});
      if(result.status === 401) {
        this.props.history.push('/auth')
      }
      // window.open(result.data, '_blank')
      console.log('datax12', result)
      toastr.removeByType('info')
      this.setState({openPixModal: true})
      const { location: { state } } = this.props;
      this.props.getRequest(state.request);
    } catch (err) {
      console.log('ERR:', err)
      toastr.error(err.message)
    }
  }

  putCancelarCobrancaAVista = async () => {
    try {
      toastr.info("Aguarde alguns instantes, estamos criando a cobrança.", {timeOut: 0, extendedTimeOut: 0})
      const token = this.props.login.user.token.access_token
      const requestId = this.props.infos.id
      const URL = `/api/solicitacao/${requestId}/cancelar-cobranca-pendente`
      const headers = {
        'Authorization': `Bearer ${token}`
      }
      const result = await axiosInstance.put(URL, {}, {headers});
      if(result.status === 401) {
        this.props.history.push('/auth')
      }
      // window.open(result.data, '_blank')
      console.log('datax12', result)
      toastr.removeByType('info')
      const { location: { state } } = this.props;
      this.props.getRequest(state.request);
    } catch (err) {
      console.log('ERR:', err)
      toastr.error(err.message)
    }
  }

  postEnviarEmailPix = async () => {
    try {
      toastr.info("Aguarde alguns instantes, estamos enviando a cobrança.", {timeOut: 0, extendedTimeOut: 0})
      const token = this.props.login.user.token.access_token
      const cobranca_id = this.returnPixObject().cobranca_id
      const URL = `/api/cobranca/${cobranca_id}/enviar-emails-pix`
      const data = {
        emails: [
          this.state.emailValue
        ]
      }
      const headers = {
        'Authorization': `Bearer ${token}`
      }
      const result = await axiosInstance.post(URL, data, {headers});
      if(result.status === 401) {
        this.props.history.push('/auth')
      }
      // window.open(result.data, '_blank')
      console.log('datax12', result)
      toastr.removeByType('info')
      this.setState({emailValue: ''})
      toastr.success("Cobrança enviada com sucesso!")
      // const { location: { state } } = this.props;
      // this.props.getRequest(state.request);
    } catch (err) {
      console.log('ERR:', err)
      toastr.error(err.message)
    }
  }

  onChangeValueEmail = (e) => {
		const value = e.target.value
		this.setState({emailValue: value})
	}

  handleStepsTour = () => {
    const pretendentes =  this.props.infos.pretendentes;
    let hasFcRendaIncluido = false;
    let hasFcEssencial = false;
    let hasFcReport = false;
    let hasFcEssencialIncluido = false;
    let hasFcEmpresaIncluido = false;

    if(pretendentes) {
      pretendentes.map(pretendente => {
        const {
          produtos: { ativo }
        } = pretendente;
  
        const productFcRenda = ativo.filter(product => product.id === 2)
        const productFcEssencial = ativo.filter(product => product.id === 3)
        const productFcReport = ativo.filter(product => product.id === 1)
        const productFcEmpresa = ativo.filter(product => product.id === 4)
        
        if(productFcRenda[0]) {
          if(productFcRenda[0].status === "INCLUIDO") {
            hasFcRendaIncluido = true;
          }
        }

        if(productFcEssencial[0]) {
          hasFcEssencial = true;
          if(productFcEssencial[0].status === 'INCLUIDO') {
            hasFcEssencialIncluido = true;
          }
        }
        if(productFcReport[0]) {
          hasFcReport = true;
          if(productFcReport[0].status === 'INCLUIDO') {
            hasFcEssencialIncluido = true;
          }
        }

        if(productFcEmpresa[0]) {
          if(productFcEmpresa[0].status === 'INCLUIDO') {
            hasFcEmpresaIncluido = true;
          }
        }
      });
    }

    if((hasFcEssencial || hasFcReport) && hasFcEmpresaIncluido) {
      steps = steps.map(item => {
        if(item.selector === '.botao-ver-analise') {
          item.selector = '.botao-processar-analise'
        }
        return item;
      })
    }

    if(hasFcEssencialIncluido) {
      steps = steps.map(item => {
        if(item.selector === '.botao-ver-analise') {
          item.selector = '.botao-processar-analise'
        }
        return item;
      })
    }

    if(hasFcRendaIncluido) {
      return steps_fcrenda_incluido;
    }
    if(!hasFcEssencial && !hasFcReport) {
      return steps_fcempresa;
    }
    return steps;
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  resumeDone = (soTemFcCompletaSemAnalise) => {
    const {
      startReport,
      prepareReport,
      location: { state },
      infos,
      userInfo
    } = this.props;
    const data = {
      solicitation_id: state.request,
      generate_report: true
    };

    if(infos.cliente_forma_pagamento_id === 3) {
      // alert('pagar para processar')
      this.postCriarCobrancaAVista();
      // this.putCancelarCobrancaAVista();
      return;
    }

    const total = this.getTotal(infos);

    const status = this.props.login.user.usuario.status
    const statusCliente = this.props.customer && this.props.customer.status

    if(statusCliente === 'SUSPENSO' || statusCliente === 'INATIVO' || status === 'SUSPENSO') {
      toastr.warning('Status', 'Sua conta está SUSPENSA')
    } else {
      this.handleModalFCRendaProcess(infos, userInfo, total, 'finalize');
      if(soTemFcCompletaSemAnalise) {
        this.handleModalFCCompletaProcess(infos, userInfo, total, 'finalize');
      }
    }
  };

  getTotal(infos) {
    return infos.pretendentes.reduce((acc, pretendente) => {
      const {
        produtos: { ativo, reconsulta }
      } = pretendente;
      if (ativo[0] && ativo[0].status === "INCLUIDO") {
        acc += Number(ativo[0].valor);
      }
      if (ativo[1] && ativo[1].status === "INCLUIDO") {
        acc += Number(ativo[1].valor);
      }
      for (let i = 0; i < reconsulta.length; i++) {
        if (reconsulta[i] && reconsulta[i].status === "REINCLUIDO")
          acc += Number(reconsulta[i].valor);
      }
      return acc;
    }, 0);
  }

  handleModalFCRendaProcess(infos, userInfo, total, fromWhere, typeOfReport = 'show') {
    const fcCompletaProcess = infos.pretendentes.map(pretendente => {
      const {
        produtos: { ativo }
      } = pretendente;

      const productFcCompleta = ativo.filter(product => (product.id === 7 || product.id === 8))

      if (productFcCompleta[0] && productFcCompleta[0].status !== "CONCLUIDO") {
        switch (ativo[0].status) {
          case "INCLUIDO":
            return Boolean(true);
          case "SOLICITADO":
            return Boolean(false);
          case "EDITADO":
            return Boolean(true);
          case "CONCLUIDO":
            return Boolean(true);
          case "REINCLUIDO":
            return Boolean(false);
          default:
            return Boolean(true);
        }
      } else {
        return false;
      }
    });
    const gotATrueCompleta = fcCompletaProcess.find(current => current === true && fromWhere == 'finalize');
    const fcRendaProcess = infos.pretendentes.map(pretendente => {
      const {
        produtos: { ativo }
      } = pretendente;

      const productFcRenda = ativo.filter(product => (product.id === 2 || product.id === 6))

      if (productFcRenda[0] && productFcRenda[0].status !== "CONCLUIDO") {
        switch (ativo[0].status) {
          case "INCLUIDO":
            return Boolean(true);
          case "EDITADO":
            return Boolean(true);
          case "CONCLUIDO":
            return Boolean(true);
          default:
            return Boolean(true);
        }
      } else {
        return false;
      }
    });
    const gotATrue = fcRendaProcess.find(current => current === true);
    const showModal = () => {
      if (gotATrue || gotATrueCompleta) {
        if(gotATrueCompleta) { return null; }
        if (userInfo && userInfo.credito >= total) {
          this.setState({
            fcRendaProcess: true,
            fcAnaliseStatuses: this.props.infos.pretendentes.map(pretendente => {
              const {
                produtos: { ativo }
              } = pretendente;
              const productFcRenda = ativo.filter(product => (product.id === 2 || product.id === 6))
              if(ativo[0] && ativo[1]){
                return [ativo[0].status, productFcRenda[0] && productFcRenda[0].status || 'INCLUIR']
              } else {
                return [ativo[0].status, "INCLUIR"]
              }
            }),
            fromWhere: fromWhere
          });
        } else {
          this.setState({ insuficientCredits: true });
        }
      } else {
        if (userInfo && userInfo.credito >= total) {
          // prepareReport([{id: 0}, ...infos.pretendentes], true);
          // startReport(data, 'charge');
          this.setState({ goToReport: true, typeOfReport: fromWhere == 'finalize' ? 'charge' : typeOfReport });
        } else {
          this.setState({ insuficientCredits: true });
        }
      }
    };
    showModal();
  }

  handleModalFCCompletaProcess(infos, userInfo, total, fromWhere, typeOfReport = 'show') {
    let soTemFcCompletaSemAnalise = true;
    const fcCompletaProcess = infos.pretendentes.map(pretendente => {
      const {
        produtos: { ativo }
      } = pretendente;

      const produtoFcReport = pretendente.produtos.ativo.filter(produto => produto.id === 1)
      const produtoFcEssencial = pretendente.produtos.ativo.filter(produto => produto.id === 3)
      const produtoFcEmpresa = pretendente.produtos.ativo.filter(produto => produto.id === 4)
      const produtoFcBasico = pretendente.produtos.ativo.filter(produto => produto.id === 5)
      const produtoFcCompletaPF = pretendente.produtos.ativo.filter(produto => produto.id === 7)
      const produtoFcCompletaPJ = pretendente.produtos.ativo.filter(produto => produto.id === 8)

      if(produtoFcReport.length || produtoFcEssencial.length || produtoFcEmpresa.length || produtoFcBasico.length) {
        soTemFcCompletaSemAnalise = false
      }

      if(produtoFcCompletaPF.length && produtoFcCompletaPF[0].status != 'INCLUIDO') {
        if(pretendente.renda && Object.keys(pretendente.renda.confirmada) && pretendente.renda.confirmada.resultado) {
          soTemFcCompletaSemAnalise = false
        }
      }
      if(produtoFcCompletaPJ.length && produtoFcCompletaPJ[0].status != 'INCLUIDO') {
        if(pretendente.faturamento && Object.keys(pretendente.faturamento.confirmada) && pretendente.faturamento.confirmada.resultado) {
          soTemFcCompletaSemAnalise = false
        }
      }
      const productFcCompleta = ativo.filter(product => (product.id === 7 || product.id === 8))

      if (productFcCompleta[0] && productFcCompleta[0].status !== "CONCLUIDO") {
        switch (ativo[0].status) {
          case "INCLUIDO":
            return Boolean(true);
          case "EDITADO":
            return Boolean(true);
          case "CONCLUIDO":
            return Boolean(true);
          default:
            return Boolean(true);
        }
      } else {
        return false;
      }
    });
    const gotATrue = fcCompletaProcess.find(current => current === true);
    const showModal = () => {
      if (gotATrue || soTemFcCompletaSemAnalise) {
        console.log('cegou aqui', soTemFcCompletaSemAnalise)
        if(!soTemFcCompletaSemAnalise) {
          if (userInfo && userInfo.credito >= total) {
            this.setState({ goToReport: true, typeOfReport: fromWhere == 'finalize' ? 'charge' : typeOfReport });
          } else {
            this.setState({ insuficientCredits: true });
          }
        }
        if (userInfo && userInfo.credito >= total) {
          this.setState({
            fcCompletaProcess: true,
            fcAnaliseStatuses: this.props.infos.pretendentes.map(pretendente => {
              const {
                produtos: { ativo }
              } = pretendente;
              const productFcCompleta = ativo.filter(product => (product.id === 7 || product.id === 8))
              if(ativo[0] && ativo[1]){
                return [ativo[0].status, productFcCompleta[0] && productFcCompleta[0].status || 'INCLUIR']
              } else {
                return [ativo[0].status, "INCLUIR"]
              }
            }),
            fromWhere: fromWhere
          });
        } else {
          this.setState({ insuficientCredits: true });
        }
      } else {
        if (userInfo && userInfo.credito >= total) {
          // prepareReport([{id: 0}, ...infos.pretendentes], true);
          // startReport(data, 'charge');
          this.setState({ goToReport: true, typeOfReport: fromWhere == 'finalize' ? 'charge' : typeOfReport });
        } else {
          this.setState({ insuficientCredits: true });
        }
      }
    };
    showModal();
  }

  handleReportUpdate = (isUpdate = false, shouldUpdate) => {
    if (isUpdate && this.props.infos.contador_edicao <= 5) {
      this.setState({ editsCountModal: isUpdate });
    } else {
      this.showOrUpdateReport(isUpdate);
    }
  };

  showOrUpdateReport = (update, forceRedirect = false) => {
    const {
      startReport,
      prepareReport,
      location: { state },
      infos,
      userInfo
    } = this.props;

    const data = {
      solicitation_id: state.request,
      generate_report: false
    };
    const type = update ? "update" : "show";
    if(type === 'show' && this.showDoneBtn() && forceRedirect === false) {
      this.setState({openIncludedProductModal: true})
      return;
    }
    // prepareReport([{id: 0}, ...infos.pretendentes], false);
    // startReport(data, type);
    const total = this.getTotal(infos);
    this.handleModalFCRendaProcess(infos, userInfo, total, 'showUpdate', type);
    // this.handleModalFCCompletaProcess(infos, userInfo, total, 'showUpdate', type);
  };

  showEditForm = (id, entity, products, reInclusion = false, selectedProduct = null) => () => {
    if (reInclusion || this.props.infos.contador_edicao > 0) {
      this.props.history.push({
        pathname: "/intranet/operacional/solicitacao/resumo/pretendente",
        state: {
          applicantId: id,
          requestId: this.props.infos.id,
          entity,
          reInclusion,
          products,
          selectedProduct,
          applicants: this.props.infos.pretendentes
        }
      });
    } else {
      this.setState({ editsCountModal: true });
    }
  };

  editProduct = (id, entity, products, reInclusion = false, selectedProduct = null) => () => {
    const status = this.props.login.user.usuario.status
    const statusCliente = this.props.customer && this.props.customer.status

    if(statusCliente === 'SUSPENSO' || statusCliente === 'INATIVO' || status === 'SUSPENSO') {
      toastr.warning('Status', 'Sua conta está SUSPENSA')
    } else {
      if (reInclusion) {
        this.setState({
          reinclusionModalInfo: { id, entity, products, open: true, selectedProduct }
        });
      } else {
        this.showEditForm(id, entity, products, reInclusion)();
      }
    }
  };

  addFcRenda = (id, entity, products, reconsulta = false) => {
    this.props.history.push({
      pathname: "/intranet/operacional/solicitacao/resumo/adicionar/fc-renda",
      state: {
        applicantId: id, // id pretendente
        requestId: this.props.infos.id, // id solicitacao
        entity,
        products,
        reconsulta
      }
    });
  };

  addFcDocPJ = (id, entity, products, reconsulta = false) => {
    this.props.history.push({
      pathname: "/intranet/operacional/solicitacao/resumo/adicionar/fc-doc-pj",
      state: {
        applicantId: id, // id pretendente
        requestId: this.props.infos.id, // id solicitacao
        entity,
        products,
        reconsulta
      }
    });
  };

  addFcReport = (id, entity, products, reconsulta = false) => {
    this.props.history.push({
      pathname: "/intranet/operacional/solicitacao/resumo/adicionar/fc-analise-mais",
      state: {
        applicantId: id, // id pretendente
        requestId: this.props.infos.id, // id solicitacao
        entity,
        products,
        reconsulta,
        product: 1,
        locacao: this.props.infos.locacao,
        applicants: this.props.infos.pretendentes
      }
    });
  };

  addFcEssencial = (id, entity, products, reconsulta = false) => {
    this.props.history.push({
      pathname: "/intranet/operacional/solicitacao/resumo/adicionar/fc-analise-mais",
      state: {
        applicantId: id, // id pretendente
        requestId: this.props.infos.id, // id solicitacao
        entity,
        products,
        reconsulta,
        product: 3,
        locacao: this.props.infos.locacao,
        applicants: this.props.infos.pretendentes
      }
    });
  };

  addNewApplicant = () => {
    if(Object.keys(this.returnPixObject()).length) {
      this.setState({openAlertaPagamentoPendenteModal: true})
      return;
    }
    const { cliente_id, id, locacao } = this.props.infos;
    const status = this.props.login.user.usuario.status
    const statusCliente = this.props.customer && this.props.customer.status

    if(statusCliente === 'SUSPENSO' || statusCliente === 'INATIVO' || status === 'SUSPENSO') {
      toastr.warning('Status', 'Sua conta está SUSPENSA')
    } else {
      this.props.history.push({
        pathname:
          "/intranet/operacional/solicitacao/resumo/adicionar-pretendente",
        state: {
          clientId: cliente_id,
          requestId: id,
          tipo_imovel: locacao.tipo_imovel,
          locacao,
          applicants: this.props.infos.pretendentes
        }
      });
    }
  };

  onRemoveApplicant = id => () => {
    this.setState({ removeModalOpen: true, applicantToRemove: id });
  };

  closeRemoveApplicantModal = () => {
    this.setState({ removeModalOpen: false });
  };

  removeApplicant = () => {
    const { removeApplicantFromRequest, infos } = this.props;
    const { applicantToRemove } = this.state;
    const isLastApplicant = infos.pretendentes.length === 1;

    removeApplicantFromRequest(infos.id, applicantToRemove, isLastApplicant);
    this.closeRemoveApplicantModal();
  };

  notShowWarningAgain = () => {
    const minutos = 10080 // 7 dias em minutos
    let expirarem = new Date().getTime() + (60000 * minutos);
    localStorage.setItem(`modal:addPretenderProduct-${this.props.login.user.usuario.id}`, expirarem);
    this.setState({openAddPretenderProductModal: false})
  }

  submitVerLaudoAnterior = () => {
    this.showOrUpdateReport(false, true)
  }

  renderApplicants = () => {
    const applicantsQuantity = this.props.infos.pretendentes.length;
    const applicants = this.props.infos.pretendentes.map((applicant, index) => {
      const entity = "cpf" in applicant ? "PF" : "PJ";
      return (
        <ApplicantCard
          key={applicant.id}
          id={applicant.id}
          index={index}
          request={this.props.infos.id}
          login={this.props.login}
          getRequest={this.props.getRequest}
          pretendentes={this.props.infos.pretendentes}
          onEdit={(reInclusion, selectedProduct) =>
            this.editProduct(
              applicant.id,
              entity,
              applicant.produtos,
              reInclusion,
              selectedProduct
            )
          }
          addFcRenda={(reconsulta) =>
            this.addFcRenda(applicant.id, entity, applicant.produtos, reconsulta)
          }
          addFcDocPJ={(reconsulta) =>
            this.addFcDocPJ(applicant.id, entity, applicant.produtos, reconsulta)
          }
          addFcReport={(reconsulta) =>
            this.addFcReport(applicant.id, entity, applicant.produtos, reconsulta)
          }
          addFcEssencial={(reconsulta) =>
            this.addFcEssencial(applicant.id, entity, applicant.produtos, reconsulta)
          }
          onRemove={this.onRemoveApplicant(applicant.id)}
          toggleDontTodayReinclusionModal={this.toggleDontTodayReinclusionModal}
          applicantsLength={applicantsQuantity}
          entity={entity}
          products={this.props.products.list}
          {...applicant}
        />
      );
    });

    return applicants;
  };

  editLocation = () => {
    const {
      location: { state }
    } = this.props;
    const idRequest = state.request;
    
    let pretendentes = this.props.infos.pretendentes;

    // pretendentes.map(pretendente => {
    //   if (pretendente.produtos) {
    //     let produtoFcReport = pretendente.produtos.ativo.filter(produto => produto.id === 1)
    //     let produtoFcEssencial = pretendente.produtos.ativo.filter(produto => produto.id === 3)
    //     let produtoFcEmpresa = pretendente.produtos.ativo.filter(produto => produto.id === 4)
    //     let produtoFcBasico = pretendente.produtos.ativo.filter(produto => produto.id === 5)
    //   }
    // });
    this.props.history.push({
      pathname: "/intranet/operacional/solicitacao/resumo/locacao",
      state: { request: idRequest, applicants: pretendentes }
    });
  };

  getSteps = () => {
    if(this.props.isLoading) { return null }

    const { pretendentes } = this.props.infos;
    if (
      pretendentes &&
      pretendentes.some(
        pretendente =>
          pretendente.produtos.ativo[0] &&
          pretendente.produtos.ativo[0].status !== "INCLUIDO"
      )
    ) {
      return null;
    } else {
      return [
        {
          title: "pesquisar cliente",
          status: "color_light-green-step",
          onClick: () => {}
        },
        {
          title: "escolher produto",
          status: "color_light-green-step",
          onClick: () => {}
        },
        {
          title: "formulário",
          status: "color_light-green-step",
          onClick: () => {}
        },
        {
          title: "resumo da solicitação",
          status: "color_purple",
          onClick: () => {}
        },
        { title: "laudo", status: "color_border-light-gray", onClick: () => {} }
      ];
    }
  };

  // O botão de Processar ANÁLISE, só pode aparecer se tiver no mínimo um pretendente com o status de 'INCLUÍDO'
  showDoneBtn = () => {
    const { infos } = this.props;
    if (Array.isArray(infos.pretendentes) && infos.pretendentes.length > 0) {
      let allProducts = [];
      infos.pretendentes.forEach(applicant => {
        allProducts = [...allProducts, ...applicant.produtos.ativo];
      });
      if (
        Array.isArray(allProducts) &&
        allProducts.findIndex(
          product =>
            product.status === "INCLUIDO" || product.status === "REINCLUIDO"
        ) > -1
      ) {
        return true;
      }
    }
    return false;
  };

  handleSubmitGetPrint = () => {
    this.getPrintRecibo()
  }

  handleContent = (soTemFcCompletaSemAnalise) => {
    if (this.props.isLoading || this.props.products.isLoading) {
      return (
        <LoadingOperational>
          <ContainerGifLoading>
            <LoadingCircle />
          </ContainerGifLoading>
        </LoadingOperational>
      );
    }

    if (this.props.success) {
      const { infos, reportBtnLoading } = this.props;
      const allOcultos = this.props.infos.pretendentes && this.props.infos.pretendentes.every(pretendente => Boolean(pretendente.oculto) === true)
      return (
        <Row>
          <BaseColumn
            xs="12"
            sm="12"
            md="9"
            lg="9"
            xl="9"
            customstyle="@media (max-width: 800px) {margin-bottom: 100px;padding: 2.5%;}"
          >
            <Title customstyle="@media (max-width: 800px) {margin-left: 2px;margin-bottom: 6px;}">
              Pretendentes incluídos [ID-FC: {infos.id}]
            </Title>
            <ApplicantsContainer>
              <FullColumn padding="2px">{this.renderApplicants()}</FullColumn>
            </ApplicantsContainer>
            <ContainerButtons customstyle="svg {display: none;} @media (max-width: 800px) {position: absolute;width: 24px;height: 24px;top: 6px;right: 2.5%; svg {width: 100%;display: block;} button {display: none;}}">
              <ResumeButton onClick={this.addNewApplicant} className="botao-adicionar-pretendente" style={{position: 'relative'}}>
                Incluir outra pessoa
                <TourTooltip
                  target="tooltip-adicionar-outro-pretendente"
                  // position="top"
                />
              </ResumeButton>
              
              <AddIcon
                width="24px"
                height="24px"
                onClick={this.addNewApplicant}
              />
            </ContainerButtons>
          </BaseColumn>
          <Col
            xs="12"
            sm="12"
            md="3"
            lg="3"
            xl="3"
            customstyle="@media (max-width: 800px) {padding: 0;}"
          >
            <ResumeSideContent
              onClickLocation={this.editLocation}
              infos={infos}
              location={infos.locacao}
              Wrapper={FullColumn}
              onClick={() => this.resumeDone(soTemFcCompletaSemAnalise)}
              showDoneBtn={this.showDoneBtn()}
              doneLoading={reportBtnLoading}
              onClickShowReport={this.handleReportUpdate}
              modalMobileIsOpen={this.state.modalMobileIsOpen}
              handleModalMobile={this.handleModalMobile}
              soTemFcCompletaSemAnalise={soTemFcCompletaSemAnalise}
              handleSubmitGetPrint={this.handleSubmitGetPrint}
              putCancelarCobrancaAVista={this.putCancelarCobrancaAVista}
              toggleModalPix={this.toggleModalPix}
              allOcultos={allOcultos}
            />
          </Col>
          <ReactTooltip id="tooltip-adicionar-outro-pretendente" place="top" type="dark" effect="float">
            Inclua na mesma análise, todos os participantes da locação
          </ReactTooltip>
          <ReactTooltip id="tooltip-editar-pretendente" place="right" type="dark" effect="float">
            A qualquer momento você pode <b>editar</b> a renda informada, ou até mesmo <b>adicionar</b> a renda extra
          </ReactTooltip>
          <ReactTooltip id="tooltip-editar-locacao" place="left" type="dark" effect="float">
            É possível <b>editar</b> o valor do aluguel + encargos, e o tipo de imóvel pretendido.
          </ReactTooltip>
          <ReactTooltip id="tooltip-adicionar-fcrenda" place="right" type="dark" effect="float">
            O FC RENDA agora é FC DOC. <br /> Você poderá anexar comprovantes de renda, identificação e residência, para serem analisados pela <br /> nossa mesa de especialistas, no prazo médio de até 1 hora.
          </ReactTooltip>
          <ReactTooltip id="tooltip-adicionar-fcdoc-pj" place="right" type="dark" effect="float">
            No FC DOC Pessoa Jurídica você poderá anexar comprovantes da <br /> receita, patrimônio e de constituição da empresa para serem analisados pela <br /> nossa mesa de especialistas, no prazo médio de até 1 hora.
          </ReactTooltip>
          <ReactTooltip id="tooltip-ver-analise" place="left" type="dark" >
            {allOcultos ? 'ANÁLISE Ocultada.' : soTemFcCompletaSemAnalise ? 'Esse botão ficará disponível após a conclusão da ANÁLISE.' : 'Clique aqui para ver o resultado da ANÁLISE.'}
          </ReactTooltip>
        </Row>
      );
    }

    return (
      <StyledRow align="center" justify="center">
        <FullColumn>
          <h5>
            Não foi possivel carregar esse pretendente.
            <br />
            Por Favor, entre em contato com a FC ANALISE!
          </h5>
        </FullColumn>
      </StyledRow>
    );
  };

  get customerName() {
    const { infos } = this.props;
    return typeof infos === "object" && "cliente_nome" in infos
      ? infos.cliente_nome
      : "";
  }

  handleModalMobile = () => {
    this.setState({ modalMobileIsOpen: !this.state.modalMobileIsOpen });
  };

  getRatingAll = async () => {
    try {
      // const URL = 'http://localhost:3333/rating/list'
      const URL = 'https://api-v1.ficharapida.rio.br/rating/list'

      const data = {
        email: this.props.login.user.usuario.email
      }

      const exists = localStorage.getItem('reset1');

      if(!exists) {
        localStorage.setItem('reset1', true);
        localStorage.removeItem('tryOne');
        localStorage.removeItem('trySecond');
      }

      const result = await axios.post(URL, data);
      console.log('result', result)
      if(result.data.length) {
        this.setState({hasRating: true}) // usuário já fez avaliação.
      } else {
        let rating = Number(localStorage.getItem('trySecond'))
        if(rating >= 6) {
          localStorage.setItem('trySecond', 1);
        }
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  reloadPage = () => {
    const data = {
      solicitation_id: this.props.location.state.request,
      generate_report: false
    };
    this.props.startReport(data, 'charge');

    // window.location.reload();
  }

  handleCheckModalAddPretenderProduct = () => {
    console.log('locationxx1', this.props.location)
    let storageModal = localStorage.getItem(`modal:addPretenderProduct-${this.props.login.user.usuario.id}`)
    if(storageModal) {
      const currentDate = new Date().getTime()
      const expires = JSON.parse(storageModal)
      if (expires <= currentDate) {
        localStorage.removeItem(`modal:addPretenderProduct-${this.props.login.user.usuario.id}`)
      }
    }
    storageModal = localStorage.getItem(`modal:addPretenderProduct-${this.props.login.user.usuario.id}`) // Atualizo a variavel storageModal novamente pois pode ser que o item tenha sido excluido acima.
    if(this.props.location.state && this.props.location.state.addPretenderProduct && storageModal === null) {
      this.setState({openAddPretenderProductModal: true})
    }
  }

  toggleDontTodayReinclusionModal = () => {
    this.setState({ dontReinclusionModalInfo: { open: true }})
  }

  returnPixObject = () => {
    let pix = {}
    if(this.props.infos.cliente_forma_pagamento_id !== 3) {
      return pix
    }
    this.props.infos && this.props.infos.pretendentes && this.props.infos.pretendentes.map(pretendente => {
      pretendente.produtos.ativo.map(product => {
        if(product.cobranca) {
          if(product.cobranca.status === 'à pagar') {
            pix = {
              pix_copia_cola: product.cobranca.pix.pix_copia_cola,
              valor_final: product.cobranca.valor_final,
              vencimento: product.cobranca.vencimento,
              cobranca_id: product.cobranca.id
            }
          }
        }
      })
    })
    return pix
  }

  toggleModalPix = () => {
    this.setState({openPixModal: !this.state.openPixModal})
  }

  render() {
    const { reinclusionModalInfo, dontReinclusionModalInfo, goToReport, typeOfReport } = this.state;
    let soTemFcBasico = true

    if(goToReport && typeOfReport === 'charge') {
      // if(this.props.reportBtnLoading) {
      //   alert('carregando')
      // }
      if(this.props.reportBtnSuccess) {
        window.location.reload();
        // return;
      }
    }

    let soTemFcCompletaSemAnalise = true;
    console.log('propsx2', this.props)
    console.log('statex', this.state)
    this.props.infos.pretendentes && this.props.infos.pretendentes.map(pretendente => {
      if (pretendente.produtos && Boolean(pretendente.oculto) === false) {
        const produtoFcReport = pretendente.produtos.ativo.filter(produto => produto.id === 1 && produto.status !== 'INCLUIDO')
        const produtoFcEssencial = pretendente.produtos.ativo.filter(produto => produto.id === 3 && produto.status !== 'INCLUIDO')
        const produtoFcEmpresa = pretendente.produtos.ativo.filter(produto => produto.id === 4 && produto.status !== 'INCLUIDO')
        const produtoFcBasico = pretendente.produtos.ativo.filter(produto => produto.id === 5 && produto.status !== 'INCLUIDO')
        const produtoFcCompletaPF = pretendente.produtos.ativo.filter(produto => produto.id === 7)
        const produtoFcCompletaPJ = pretendente.produtos.ativo.filter(produto => produto.id === 8)

        if(produtoFcReport.length || produtoFcEssencial.length || produtoFcEmpresa.length || produtoFcBasico.length) {
          soTemFcCompletaSemAnalise = false
        }

        if(produtoFcCompletaPF.length && produtoFcCompletaPF[0].status != 'INCLUIDO') {
          if(pretendente.renda && Object.keys(pretendente.renda.confirmada) && pretendente.renda.confirmada.resultado) {
            soTemFcCompletaSemAnalise = false
          }
        }
        if(produtoFcCompletaPJ.length && produtoFcCompletaPJ[0].status != 'INCLUIDO') {
          if(pretendente.faturamento && Object.keys(pretendente.faturamento.confirmada) && pretendente.faturamento.confirmada.resultado) {
            soTemFcCompletaSemAnalise = false
          }
        }
      }
    })

    if (goToReport) {
      let pretendentes = this.props.infos.pretendentes;
      let pretendentesToPush = [];
      if (typeOfReport != "charge") {
        pretendentes.map(pretendente => {
          if (pretendente.produtos && Boolean(pretendente.oculto) === false) {
            let produtoFcReport = pretendente.produtos.ativo.filter(produto => produto.id === 1)
            let produtoFcEssencial = pretendente.produtos.ativo.filter(produto => produto.id === 3)
            let produtoFcEmpresa = pretendente.produtos.ativo.filter(produto => produto.id === 4)
            let produtoFcBasico = pretendente.produtos.ativo.filter(produto => produto.id === 5)
            let produtoFcCompletaPF = pretendente.produtos.ativo.filter(produto => produto.id === 7)
            let produtoFcCompletaPJ = pretendente.produtos.ativo.filter(produto => produto.id === 8)
            if(produtoFcEssencial.length && !produtoFcReport.length && produtoFcEssencial[0].status != 'INCLUIDO') {
              pretendentesToPush.push(pretendente)
            }
            if(!produtoFcEssencial.length && produtoFcReport.length && produtoFcReport[0].status != 'INCLUIDO') {
              pretendentesToPush.push(pretendente)
            }
            if(produtoFcEssencial.length && produtoFcReport.length && produtoFcEssencial[0].status != 'INCLUIDO') {
              pretendentesToPush.push(pretendente)
            }
            if(produtoFcEmpresa.length && produtoFcEmpresa[0].status != 'INCLUIDO') {
              pretendentesToPush.push(pretendente)
            }
            if(produtoFcBasico.length && produtoFcBasico[0].status != 'INCLUIDO') {
              pretendentesToPush.push(pretendente)
            }
            if(produtoFcCompletaPF.length && produtoFcCompletaPF[0].status != 'INCLUIDO') {
              if(pretendente.renda && Object.keys(pretendente.renda.confirmada) && pretendente.renda.confirmada.resultado) {
                pretendentesToPush.push(pretendente)
              }
            }
            if(produtoFcCompletaPJ.length && produtoFcCompletaPJ[0].status != 'INCLUIDO') {
              if(pretendente.faturamento && Object.keys(pretendente.faturamento.confirmada) && pretendente.faturamento.confirmada.resultado) {
                pretendentesToPush.push(pretendente)
              }
            }
          }
        })
      } else if (typeOfReport == "charge") {
        pretendentes.map(pretendente => {
          let addPretender = true
          if (pretendente.produtos && Boolean(pretendente.oculto) === false) {
            const produtoFcCompletaPF = pretendente.produtos.ativo.filter(produto => produto.id === 7)
            const produtoFcCompletaPJ = pretendente.produtos.ativo.filter(produto => produto.id === 8)
            if(produtoFcCompletaPF.length || produtoFcCompletaPJ.length) {
              addPretender = false
            }
            if(produtoFcCompletaPF.length && produtoFcCompletaPF[0].status != 'INCLUIDO') {
              if(pretendente.renda && Object.keys(pretendente.renda.confirmada) && pretendente.renda.confirmada.resultado) {
                pretendentesToPush.push(pretendente)
              }
            }
            if(produtoFcCompletaPJ.length && produtoFcCompletaPJ[0].status != 'INCLUIDO') {
              if(pretendente.faturamento && Object.keys(pretendente.faturamento.confirmada) && pretendente.faturamento.confirmada.resultado) {
                pretendentesToPush.push(pretendente)
              }
            }
          }
          if(addPretender) {
            pretendentesToPush.push(pretendente)
          }
        })
        // pretendentesToPush = pretendentes
      }

      pretendentesToPush = uniqBy(pretendentesToPush, 'id');

      pretendentes.map(pretendente => {
        if (pretendente.produtos && Boolean(pretendente.oculto) === false) {
          const produtoFcReport = pretendente.produtos.ativo.filter(produto => produto.id === 1)
          const produtoFcEssencial = pretendente.produtos.ativo.filter(produto => produto.id === 3)
          const produtoFcEmpresa = pretendente.produtos.ativo.filter(produto => produto.id === 4)
          const produtoFcBasico = pretendente.produtos.ativo.filter(produto => produto.id === 5)
          const produtoFcCompletaPF = pretendente.produtos.ativo.filter(produto => produto.id === 7)
          const produtoFcCompletaPJ = pretendente.produtos.ativo.filter(produto => produto.id === 8)
          const includedProdutoFcReport = pretendente.produtos.ativo.filter(produto => produto.id === 1 && produto.status !== 'INCLUIDO')
          const includedProdutoFcEssencial = pretendente.produtos.ativo.filter(produto => produto.id === 3 && produto.status !== 'INCLUIDO')
          const includedProdutoFcEmpresa = pretendente.produtos.ativo.filter(produto => produto.id === 4 && produto.status !== 'INCLUIDO')
          const includedProdutoFcBasico = pretendente.produtos.ativo.filter(produto => produto.id === 5)
          const includedProdutoFcCompletaPF = pretendente.produtos.ativo.filter(produto => produto.id === 7 && produto.status !== 'INCLUIDO')
          const includedProdutoFcCompletaPJ = pretendente.produtos.ativo.filter(produto => produto.id === 8 && produto.status !== 'INCLUIDO')

          if(typeOfReport == "charge") {
            if(produtoFcReport.length || produtoFcEssencial.length || produtoFcEmpresa.length || produtoFcBasico.length) {
              soTemFcCompletaSemAnalise = false
            }
          }
          
          if(typeOfReport == "charge") {
            if(produtoFcReport.length || produtoFcEssencial.length || produtoFcEmpresa.length) {
              soTemFcBasico = false
            }
          } else {
            if(includedProdutoFcReport.length || includedProdutoFcEssencial.length || includedProdutoFcEmpresa.length) {
              soTemFcBasico = false
            }
          }

          if(produtoFcCompletaPF.length && produtoFcCompletaPF[0].status != 'INCLUIDO') {
            if(pretendente.renda && Object.keys(pretendente.renda.confirmada) && pretendente.renda.confirmada.resultado) {
              soTemFcBasico = false
              soTemFcCompletaSemAnalise = false
            }
          }
          if(produtoFcCompletaPJ.length && produtoFcCompletaPJ[0].status != 'INCLUIDO') {
            if(pretendente.faturamento && Object.keys(pretendente.faturamento.confirmada) && pretendente.faturamento.confirmada.resultado) {
              soTemFcBasico = false
              soTemFcCompletaSemAnalise = false
            }
          }
        }
      })

      let applicants = [{ id: 0, nome: "SÍNTESE" }, ...pretendentesToPush]
      if(soTemFcBasico) {
        applicants = [...pretendentesToPush]
      }

      if(soTemFcCompletaSemAnalise && typeOfReport == "charge") {
        this.reloadPage()
        // return;
      } else {
        this.props.history.push({
          pathname: "/intranet/operacional/solicitacao/laudo",
          state: {
            request: this.props.infos.id,
            data: {
              solicitation_id: this.props.location.state.request,
              generate_report: false
            },
            typeOfReport,
            customer: this.props.infos.cliente_nome,
            infos: this.props.infos,
            applicants: applicants,
            hasRating: this.state.hasRating
          }
        });
      }

      
    }

    const tt = this.handleStepsTour()
    return (
      <Main>
        <Header
          title="Resumo da Solicitação"
          breadcrumb={breadcrumb}
          actualLink="Resumo"
          subtitle={this.customerName}
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <BlueSteps
          steps={this.getSteps()}
          customstyle="@media (max-width: 800px) {background-color: #f8f6f6;width: 100%;padding: 0 2%;}"
        />
        <MainContainer>
          <div className="container-fluid">
            {this.handleContent(soTemFcCompletaSemAnalise)}
            <RemoveApplicantModal
              removeApplicant={this.removeApplicant}
              isOpen={this.state.removeModalOpen}
              closeRemoveApplicantModal={this.closeRemoveApplicantModal}
            />
            <InsuficientCreditsModal
              isOpen={this.state.insuficientCredits}
              closeModal={() => this.setState({ insuficientCredits: false })}
            />
            <AddPretenderProductModal
              notShowWarningAgain={this.notShowWarningAgain}
              isOpen={this.state.openAddPretenderProductModal}
              closeModal={() => this.setState({ openAddPretenderProductModal: false })}
            />
            <IncludedProductModal
              submitVerLaudoAnterior={this.submitVerLaudoAnterior}
              isOpen={this.state.openIncludedProductModal}
              closeModal={() => this.setState({ openIncludedProductModal: false })}
            />
            <EditsCountModal
              isOpen={this.state.editsCountModal}
              closeModal={() => this.setState({ editsCountModal: false })}
              continue={update => this.handleReportUpdate(update, true)}
              count={this.props.infos.contador_edicao}
            />
            <ReinclusionModal
              reinclude={() =>
                this.showEditForm(
                  reinclusionModalInfo.id,
                  reinclusionModalInfo.entity,
                  reinclusionModalInfo.products,
                  true,
                  reinclusionModalInfo.selectedProduct
                )()
              }
              isOpen={reinclusionModalInfo.open}
              onClose={() =>
                this.setState({ reinclusionModalInfo: { open: false } })
              }
            />
            <DontTodayReinclusionModal
              isOpen={dontReinclusionModalInfo.open}
              onClose={() =>
                this.setState({ dontReinclusionModalInfo: { open: false } })
              }
            />
            <FCRendaProcessModal
              isOpen={this.state.fcRendaProcess}
              closeModalAndGo={(callback) =>
                this.setState({
                  fcRendaProcess: false,
                  goToReport: true,
                  typeOfReport: callback
                })
              }
              justCloseModal={() =>
                this.setState({
                  fcRendaProcess: false
                })
              }
              status={this.state.fcAnaliseStatuses || null}
              fromWhere={this.state.fromWhere}
            />
            <FCCompletaProcessModal
              isOpen={this.state.fcCompletaProcess}
              closeModalAndGo={(callback) =>
                this.setState({
                  fcCompletaProcess: false,
                  goToReport: true,
                  typeOfReport: callback
                })
              }
              justCloseModal={() =>
                this.setState({
                  fcCompletaProcess: false
                })
              }
              status={this.state.fcAnaliseStatuses || null}
              fromWhere={this.state.fromWhere}
              soTemFcCompletaSemAnalise={soTemFcCompletaSemAnalise}
            />
            <ModalPixQrCode
              isOpen={this.state.openPixModal}
              closeModal={() => this.setState({ openPixModal: false })}
              emailValue={this.state.emailValue}
              pix={this.returnPixObject()}
              postEnviarEmailPix={this.postEnviarEmailPix}
              onChangeValueEmail={this.onChangeValueEmail}
            />
            <ModalAlertaPagamentoPendente
              isOpen={this.state.openAlertaPagamentoPendenteModal}
              closeModal={() => this.setState({ openAlertaPagamentoPendenteModal: false })}
              pix={this.returnPixObject()}
            />
          </div>
          <Tour
            steps={tt}
            isOpen={this.state.tourOpen}
            onRequestClose={this.handleTourClose}
            getCurrentStep={this.postCurrentStepOnboard}
            onAfterOpen={this.postAfterOpenOnboard}
          />
        </MainContainer>
      </Main>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRequest,
      removeApplicantFromRequest,
      startReport,
      resetApplicantInfos,
      prepareReport,
      getUserInfos,
      getClientProducts,
      resetForm
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isLoading: state.requestResume.resume.isLoading,
  infos: state.requestResume.resume.infos,
  userInfo: state.requestResume.userInfo,
  success:
    !state.requestResume.resume.isLoading &&
    !state.requestResume.resume.error &&
    state.requestResume.resume.error !== undefined,
  reportBtnLoading: state.requestResume.reportSettings.isRequesting,
  reportBtnSuccess: state.requestResume.reportSettings.success,
  products: state.request.products,
  login: state.login,
  customer: state.customerData.data.cliente
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestResume);
