
export const getUsersFromState = (state) => {
  const { employersList: { data } } = state;
  if (data !== undefined && Array.isArray(data)) {
    return data;
  }
  return [];
}

export const getStatusPermission = state => state.customerData.data.status
export const getStatusOptions = state => state.requirements.status