import { omitBy, isNull, omit, isEqual, pickBy, identity } from 'lodash';

const handleEmptyObj = (obj) => {
  const notValidValuesRemoved = pickBy(obj, (value) => {

    if (value === "") {
      return true;
    }

    if (value === 0) {
      return true;
    }

    return identity(value);
  });
  const keys = Object.keys(notValidValuesRemoved);

  return keys.length > 0 ? notValidValuesRemoved : null;
}

export const getChangedValues = (initialValues, changedValues) => {
  if(!changedValues) return {};

  const values = Object.keys(changedValues).reduce((acc, key) => {
    if (!isEqual(changedValues[key], initialValues[key])) {
      acc[key] = changedValues[key];
    }

    return acc;
  }, {});

  return handleEmptyObj(values);
}


export const createSurveyObj = (data) => {
  const survey = {
    servicos_interesse: data.servicos_interesse,
    como_conheceu: data.como_conheceu,
    numero_imoveis: data.numero_imoveis
  };

  if (data.indicacao !== undefined) {
    return { ...survey, indicacao: data.indicacao };
  }

  return survey;
}

export const createAddressObj = (data) => {
  return {
    cep: data.cep,
    logradouro: data.logradouro,
    bairro: data.bairro,
    cidade: data.cidade,
    uf: data.uf,
    numero: data.numero,
    complemento: data.complemento || ""
  }
}

export const createLegalClientObj = (data) => (
  {
    cnpj: data.cnpj
  }
);

export const createBaseContact = ({ operacional, financeiro }) => {
  const contacts = {
    operacional: {
      nome: operacional.nome,
      email: operacional.email,
      funcao: operacional.funcao,
      telefones: createPhoneObj(operacional)
    },
    financeiro: {
      nome: financeiro.nome,
      email: financeiro.email,
      funcao: financeiro.funcao,
      telefones: createPhoneObj(financeiro)
    }
  };

  return { contato: omitBy(contacts, (category) => isNull(category.email) && isNull(category.nome)) };
}

export const createLegalEntityContact = (data) => (
  {
    ...createBaseContact(data),
    diretoria: {
      nome: data.diretoria.nome,
      email: data.diretoria.email,
      funcao: data.diretoria.funcao,
      telefones: createPhoneObj(data.diretoria)
    }
  }
);

export const createPersonalClientObj = (data) => (
  {
    identidade: data.identidade,
    orgao_expedidor: data.orgao_expedidor,
    cpf: data.cpf,
    tipo_pessoa_fisica: data.tipo || data.tipo_pessoa_fisica
  }
);

export const createClientObj = (data) => {
  const client = {
    pessoa: data.pessoa,
    tratamento: data.tratamentos,
    nome: data.nome,
    telefones: createPhoneObj(data),
    email: data.email,
    website: data.website,
    creci: data.creci
  };

  if (data.pessoa === 'FISICA') {
    return {
      ...client,
      ...createPersonalClientObj(data),
      contato: data.contatos,
      tipo_pessoa_fisica: data.tipo_pessoa_fisica || data.tipo
    };
  }

  return {
    ...client,
    ...createLegalClientObj(data),
    contato: data.contatos
  };
}

export const createPhoneObj = (data) => {
  const keys = ['principal', 'celular', 'adicional', 'emergencia'];

  const phoneObj = keys.reduce((obj, key) => {
    if (data.telefones[key] !== undefined && data.telefones[key].numero !== '') {
      obj[key] = {
        ddi: data.telefones[key].ddi,
        numero: data.telefones[key].numero
      }
    }

    return obj;
  }, {});

  return phoneObj;
};

export const extractPersonObj = (data) => ({
  email: data.email,
  email_confirmation: data.email_confirmation || data.email,
  nome: data.nome,
  cpf: data.cpf,
  identidade: data.identidade,
  orgao_expedidor: data.orgao_expedidor,
  funcao: data.funcao
});

export const createUserObj = (data) => {
  const isPersonalEntity = data.pessoa === 'FISICA';

  const email = isPersonalEntity ? data.email : data.email_master;
  const nome = isPersonalEntity ? data.nome : data.nome_master;
  const email_confirmation = isPersonalEntity ? data.email_confirmation : data.email_master_confirmation;

  return {
    email,
    email_confirmation: email_confirmation || email,
    nome,
    cpf: data.cpf,
    identidade: data.identidade,
    telefones: createPhoneObj(data),
    orgao_expedidor: data.orgao_expedidor,
    funcao: data.funcao || 'Não informado'
  };
}

export const mergeForms = (forms, entityType) => {
  const formsKeys = Object.keys(forms);
  const mergedForms = formsKeys.reduce((mergedObj, key) => {
    return { ...mergedObj, ...forms[key].values };
  }, { pessoa: entityType });

  return mergedForms;
};

export const createPersonalEntityObj = (data) => ({
  cliente: createClientObj(data),
  usuario: createUserObj(data),
  pesquisa: createSurveyObj(data.pesquisa || data),
  endereco: createAddressObj(data.endereco || data)
});

export const createLegalEntityObj = (data) => ({
  cliente: createClientObj(data),
  usuario: createUserObj(data.usuario || data),
  pesquisa: createSurveyObj(data.pesquisa || data),
  endereco: createAddressObj(data.endereco || data)
});

export const createAnalyticClientObj = (data) => {
  const {
    observacao_operacional,
    observacao_operacional_interno,
    colaborador_comercial
  } = data;
  const responsavel = colaborador_comercial !== null ? { responsavel: handleValueType(colaborador_comercial) } : {};
  return {
    observacao_operacional,
    observacao_operacional_interno,
    ...responsavel
  }
};

const handleValueType = (value) => {
  if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
    return value.id;
  }

  return value;
}

export const createChargeClientObj = (data) => {
  const {
    localidade,
    atendimento,
    entrega,
    observacao_financeira,
    observacao_financeira_interno,
    colaborador_comercial
  } = data;

  return {
    localidade: handleValueType(localidade),
    atendimento,
    entrega,
    observacao_financeira,
    observacao_financeira_interno,
    responsavel: handleValueType(colaborador_comercial)
  }
};

export const createEnsuranceClientObj = (data) => {
  const {
    seguros,
    colaborador_seguro
  } = data;
  const responsavel = colaborador_seguro !== null ? { responsavel: handleValueType(colaborador_seguro) } : {};
  return {
    ...responsavel,
    seguros: seguros.filter(value => value.checked).map(item => omit(item, ['checked']))
  }
}

export const updateClientComplementaryFields = (data) => (
  {
    analise: createAnalyticClientObj(data),
    cobranca: createChargeClientObj(data),
    seguro: createEnsuranceClientObj(data)
  }
);

export const initialDdiValues = (labels) => {
  const field = labels.reduce((accObj, label) => {
    accObj.telefones = {};
    accObj.telefones[label] = {
      ddi: '+55',
      numero: ''
    };

    return accObj;
  }, {});

  return field;
}

export const handlePhonesFromAPI = (fieldsObj) => {
  const keys = Object.keys(fieldsObj);

  const phoneObj = keys.reduce((obj, key) => {
    if (fieldsObj[key] !== undefined) {
      obj = fieldsObj;

      obj[key] = {
        ...fieldsObj[key]
      };

    }

    return obj;
  }, {});

  return phoneObj;
};

/**
 * @argument contactsObj: { operacional: Object, financeiro: Object, diretoria: Object }
 * operacional: { name: String, email: String , telefones: Object }
 * diretoria just in case of pessoa = JURIDICA
 */
export const handleContactsFromAPI = (contactsObj, entity) => {
  const keys = Object.keys(contactsObj);
  const contacts = keys.reduce((accObj, contact) => {
    accObj[contact] = {
      nome: contactsObj[contact].nome || '',
      email: contactsObj[contact].email || '',
      funcao: contactsObj[contact].funcao || '',
      telefones: handlePhonesFromAPI(contactsObj[contact].telefones)
    }
    return accObj;
  }, {});

  if (entity === 'FISICA') {
    return omit(contacts, ['diretoria']);
  }

  return contacts;
}

/**
 * @argument {Object} referenceObj
 * @argument {Array.<string>} paths 
 */
export const extractValues = (referenceObj, paths) => {
  const values = paths.reduce((acc, path) => {
    if (!Array.isArray(referenceObj[path]) && typeof referenceObj[path] === 'object') {
      const keys = Object.keys(referenceObj[path])
      acc[path] = extractValues(referenceObj[path], keys);
    }

    acc[path] = referenceObj[path];

    return acc;
  }, {});

  return values;
}

export const createSparseObj = (data) => {
  const sparse = {
    tratamento: data.tratamentos,
    nome: data.nome,
    tipo: data.tipo,
    email: data.email,
    telefones: createPhoneObj(data)
  };

  if (data.pessoa === 'FISICA') {
    return { ...sparse, cpf: data.cpf };
  }

  return { ...sparse, cnpj: data.cnpj };
};

export const handleSelectFields = (field, requirements) => {
  const defaultValue = { label: 'Selecione', value: '' };
  
  if (field.name.search(/ddi/) > -1) {
    return requirements.ddi;
  }

  if (field.name.search(/colaborador/) > -1) {
    return [defaultValue, ...requirements.colaboradores];
  }

  if (field.name === 'tipo' || field.name === 'tipo_pessoa_fisica') {
    return [defaultValue, ...requirements.tipos];
  }

  if (field.name === 'pretendente_solicitacao' || field.name === 'entrega' || field.name === 'atendimento') {
    return [
      { label: 'Sim', value: true },
      { label: 'Não', value: false },
    ];
  }

  if (field.name === 'participante' || field.name === 'residir') {
    console.log('Requirements[field.name] > ', requirements[field.name]);
    console.log('field.name > ', field.name);

    console.log('defaultValue >', defaultValue, 'reverseByValue > ', reverseByValue);
    
    const reverseByValue = requirements[field.name] && requirements[field.name].sort((a, b) => b.value - a.value) || [];
    return [defaultValue, ...reverseByValue];
  }

  if (field.name === 'origem_renda' || field.name === 'origem_renda_secundaria') {
    console.log('requirements1', requirements)
    if(!requirements.editMode){
      if(requirements[field.name] !== undefined && requirements[field.name][1].value === 1){
        requirements[field.name].splice(1,1);
        
      }
    }
    return requirements[field.name];
  }

  if (field.type === 'checkbox' || field.type === 'radio') {
    return requirements[field.name];
  }

  if (requirements[field.name] !== undefined && requirements[field.name] !== null) {
    return [defaultValue, ...requirements[field.name]];
  }

  return [defaultValue, {...requirements}];
};

export const createProfileObj = (fields, registeredFields) => {
  const registeredFieldsKeys = Object.keys(registeredFields);
  let arPermissoes = [];
  if (fields.permissoes) {
    Object.keys(fields.permissoes).forEach((name) => {
      if (registeredFieldsKeys.find((key) => key === `permissoes.${name}.funcionalidade`)) {
        arPermissoes.push(fields.permissoes[name]);
      }
    });
  }

  let formData = {
    perfil: {
      categoria: fields.perfil.categoria,
      titulo: fields.perfil.titulo,
      descricao: fields.perfil.descricao
    },
    permissoes: arPermissoes
  };
  delete formData.permissoes.undefined;
  return formData;
}
