import styled from 'styled-components';
import { SubmitButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';

export const ContainerForm = styled.div`
  height: 91.5vh;
  max-height: 91.5vh;
`;

export const FormWrapper = styled.div`
  margin: auto;
`;

export const ButtonForm = styled(SubmitButton)`
  color: ${props => props.theme.color_white};
  font-size: .88rem !important;
  padding: 0.375rem 1.65rem;
`;

export const ContainerCheckbox = styled.div`
  width: 25%;
  & label { margin-top: 0; margin-bottom: 0; }
  & > div {
    margin-top: 0;
  }
  & > div {
    &:last-child {
      & > div {
        display: flex;
        justify-content: space-between;
      }
      & > div > div { width: auto;margin: 0 .5rem; }
    }
  }
`;

export const ContainerRadio = styled.div`
  width: 25%;
  & label { margin-top: 0; margin-bottom: 0; }
  & > div {
    margin-top: 0;
    &:last-child {
      & > div {
        display: flex;
        justify-content: space-between;
      }
      & > div > div { width: auto;margin: 0 .5rem; }
    }
  }
  &:last-child {
    padding: 0;
  }
  & label { 
    &::before { content: none !important; }
  }
`;

export const ContainerRadioCobranca = styled(ContainerRadio)`
  width: auto;
  margin: 0;
  
  & > div > div {
    padding: 0;
    &.col {
      display: none;
    }
    & > div {
      margin: 0 0 0 20px !important;
    }
  }

  input[type='radio'] {
    height: initial !important;

    :checked:after {
      width: 6px;
      height: 6px;
      border-radius: 6px;
      top: 3px;
      left: 3px;
      position: absolute;
      background-color: #152b4e;
      content: '';
      display: inline-block;
      visibility: visible;
    }
  }
`;
