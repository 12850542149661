import styled, { css } from 'styled-components';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem, Nav } from 'reactstrap';

export const StyledNav = styled(Nav)`
  display: block;
  width: 185px;
`;

export const StyledSelectArrow = styled.div`
  color: ${props => props.theme.input_color};
  ${props => {
    if (props.isOpen) {
      return 'transform: rotate(180deg); padding-bottom: 0.7rem;';
    }

    return '';
  }};
  ${props =>
    css`
      ${props.customstyle}
    `};
`;

export const DisabledLink = styled.a`
  cursor: default;

  @media (max-width: 800px) {
    font-weight: normal !important;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-left: 0;

  & > svg {
    margin-left: 7px !important;
  }
`;

export const Arrow = styled(StyledSelectArrow)`
  position: relative;
  display: none;
  color: ${props => props.theme.color_white};
  font-size: 1.1rem;
  left: 15px;
  bottom: ${props => (props.isOpen ? '5px' : '3px')};
`;

export const StyledNavItem = styled(NavItem)`
  margin-bottom: 10px;
  background-color: ${props =>
    props.isOpen ? props.theme.color_charcoal : 'transparent'};
  border-left: 0.25rem
    ${props =>
      props.isOpen ? props.theme['color_systemic_feedback_regular'] : 'transparent'}
    solid;
  width: auto;
  transition: width 0.5 ease-in;

  &:hover,
  &.active {
    background-color: ${props => props.theme.color_charcoal};
    width: 185px;
  }

  div.collapse.show {
    display: none;
  }
`;

export const StyledNavLink = styled.li`
  list-style: none;
  padding: 0;
  margin-left: 30px;
  height: 30px;
  z-index: 8;
  display: none;

  .offset-1 {
    margin-left: 0;
  }

  a {
    margin-left: 25px;
    text-decoration: none;
    color: ${props => props.theme.color_white};
    font-size: 0.8rem;
    &:hover {
      font-weight: ${props => (props.disabled ? '' : 'bold')};
      color: ${props => props.theme.color_white};
      transition: width 0.5s ease-in;
    }

    &.active {
      font-weight: 700;
      color: ${props => props.theme['color_systemic_feedback_regular']};
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 42px;
  cursor: pointer;
  transition: width 0.5s ease-in;
`;

export const Title = styled.span`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 0;
    margin: 0;
  }
`;

export const MainTitle = styled.span`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: auto !important;
    margin: 0;
    text-transform: uppercase;
    font-weight: 300;
  }
`;

export const StyledBrand = styled(NavbarBrand)`
  height: 42px;
  margin-right: 0;
  g.symbol {
    display: block;
  }

  g.full {
    display: none;
  }

  svg.logo {
    width: 65px;
    height: 65px;
  }

  @media (max-width: 800px) {
    width: 50px;
    height: 30px;
    display: flex;
    align-items: center;

    g.symbol {
      display: block;
    }

    g.full {
      display: none;
    }

    svg.logo {
      width: 50px;
      height: 30px;
    }

    svg.path {
      width: 50px;
      height: 30px;
    }

    :hover svg {
      width: 100% !important;
    }
  }
`;

export const CollapsableContainer = styled(Collapse)`
  align-items: flex-start;
  padding-top: 50px;
  width: 100%;
  ${props =>
    css`
      ${props.customstyle}
    `};
  
  transition: width 0.5s ease-in;
  
  @media (max-width: 800px) {
    position: relative;
    top: 75px;
    padding-top: 10px;
    
    &.no-flex {
      display: none !important;
    }
  };
`;

export const MainNav = styled(Navbar)`
  flex-direction: column;
  width: 65px;
  height: 100%;
  background-color: ${props => props.theme['color_deep-koamaru']};
  position: fixed;
  top: 0;
  font-family: 'Open Sans', sans-serif;
  z-index: 100;
  font-size: 1rem;
  color: ${props => props.theme.color_white};
  padding: 0;
  transition: width 0.1s ease-in;

  svg.nano {
    width: 1.8rem;
  }

  button:nth-of-type(2) {
    width: 58%;
    & > span {
      display: none;
    }
  }

  svg.sub-icon {
    width: 1rem;
    display: inline-block;
    margin: 0;
  }

  &:hover,
  &.active {
    width: 185px;

    svg.nano {
      margin-left: 20px;
    }

    ${Title} {
      display: block;
      width: 100px;
      transition: width 0.5s ease-in;
    }

    ${MainTitle} {
      @media (min-width: 800px) {
        display: none;
        width: 100px;
      }

      @media (max-width: 800px) {
        display: block;
        width: 100px;
      }
    }

    ${StyledBrand} {
      .no-expanded-logo,
      g.symbol {
        @media(min-width: 801px){
          display: none;
        }
      }

      g.full {
        display: block;
      }

      svg {
        width: 150px;
        height: auto;
      }
      .expanded-logo {
        @media(min-width: 801px){
          display: block !important;
          width: 141px;
          margin-top: 20px;
        }
      }

      @-moz-document url-prefix() {
        svg {
          width: 150px;
        }
      }
    }

    ${StyledNavItem} {
      div.collapse.show {
        display: block;
        min-width: 65px;
      }

      ${StyledNavLink} {
        display: block;
        transition: height 0.5s ease-in-out;
      }
    }

    ${IconContainer} {
      margin-left: 0;
    }

    ${Arrow} {
      display: block;
    }

    button:nth-of-type(2) {
      width: 90%;
      & > span {
        display: block;
      }
    }
  }

  @media (max-width: 800px) {
    &:hover {
      transition: none;
      width: 100%;

      ${StyledBrand} {
        g.symbol {
          display: block;
        }
  
        g.full {
          display: none;
        }

        svg {
          width: 50px !important;
          height: 30px !important;
        }
      }
    }
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    width: 100%;
    transition: opacity 0.1s, width 0.1s 0.1s;

    ${StyledBrand} {
      img {
        top: 8px !important;
      }
    }

    &:hover {
      ${StyledBrand} {
        img {
          top: 20px;
          left: 10px !important;
          height: 28px !important;
          transform: none !important;
        }
      }
    }
  }
`;

export const NewRequestContainer = styled.button`
  display:block;
  padding: 0.1rem 0 0.2rem 0;
  border: 1px solid ${props => props.theme.color_white};
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  margin: 50px 0px 5px 0;

  &:hover {
    background-color: #00000052;
  }
  &:focus {
    outline: 0;
  }
  & > svg {
    margin-left: 7px !important;
  }

  @media (max-width: 800px){
    display: none;
  }
`;

export const MobileNewRequestContainer = styled.button`
  display: none;
  padding: 0.1rem 0 0.2rem 0;
  border: 1px solid ${props => props.theme.color_white};
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  margin: 0 10px 5px 0;

  &:hover {
    background-color: #00000052;
  }
  &:focus {
    outline: 0;
  }

  @media (max-width: 800px) {
    margin: 10px 0 20px 14px;
  }
`;

export const TextRequest = styled.div`
  text-align: center;
  padding: 0.2rem;
  line-height: 0.6;
  font-size: 39.45px;
  color: ${props => props.theme.color_white};

  @media (max-width: 800px){
    margin-left: 0.3rem;
    padding: 0.3rem;
  }
`;

export const TitleRequestButton = styled.span`
  font-size: 12px;
  min-width: 122px;
  color: ${props => props.theme.color_white};
`;

export const Lightbox = styled.div`
  display: none;
  ${props => {
    if (props.toggle === true) {
      return `
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(46, 53, 78, 0.7);
      overflow: hidden;
      z-index: 2;
      bottom: 0;
      `;
    }
  }}

  @media (min-width: 801px) {
    display: none
  }
`;

export const Sidebar = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.toggle ? 0 : '-354px'};
  height: 100%;
  width: 185px;
  padding: 0;
  overflow: auto;
  flex-direction: column;
  float: left;
  z-index: 3;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  background-color: ${props => props.theme['color_deep-koamaru']};
  color: ${props => props.theme.color_white};
  transition: all 0.5s ease;
  
  @media (min-width: 801px) {
    display: none;
  }
`;

export const UserIconContainer = styled.div`
  position: relative;
  top: -25px;
  right: -20px;
  height: auto;
  width: 28px;
  font-size: 11px;
  line-height: 10px;
  color: ${props => (props.color && props.color) || props.theme.color_white};
  cursor: pointer;

  & > svg {
    fill: ${props => (props.color && props.color) || props.theme.color_white};
  }
`;

export const SpanHello = styled.div`
  position: absolute;
  top: -11px;
  left: 48px;
  color: ${props => (props.color && props.color) || props.theme.color_gray};
  font-size: 12px;
  line-height: 0.7rem;
`;

export const NameUser = styled.a`
  color: ${props =>
    (props.color && props.color) || props.theme.color_gray} !important;
  font-weight: 600;
  padding-right: 10px !important;
  font-size: 12px;
  line-height: 10px;
  position: relative;

  &:before {
    content: '';
    width: calc(100% - 10px);
    height: 1px;
    background: #fff;
    position: absolute;
    bottom: 1px;
  }

  &:after {
    width: 10px;
    height: 4px;
  }
`;

export const BoxExit = styled.div`
  align-self: center;
  display: none;

  @media (max-width: 800px) {
    display: block;
    width: 20px !important;
    height: auto;
    position: relative;
    top: 40px;
    right: -140px;
  }
`;

export const ExitIconContainer = styled.div`
  position: relative;
  right: 125px;
  height: auto;
  width: 28px;
  font-size: 11px;
  line-height: 10px;
  color: ${props => (props.color && props.color) || props.theme.color_white};
  cursor: pointer;

  & > svg {
    & > path {
      fill: ${props => (props.color && props.color) || props.theme.color_white};
    }
  }
`;

export const ExitTitleContainer = styled.div`
  position: absolute;
  top: 5px;
  right: -40px;
  height: auto;
  width: 28px;
  font-size: 1rem;
  line-height: 10px;
  color: ${props => (props.color && props.color) || props.theme.color_white};
  cursor: pointer;
`;

export const StyledNavbarToggler = styled(NavbarToggler)`
  display: none;

  @media (max-width: 800px) {
    width: 46px;
    height: 30px;
    display: block !important;
  }
`;
