import {
  OPEN_MENU_ITEM,
  CLOSE_MENU_ITEM,
  RETRACT_MENU
} from '../actions/types';

import {
  openMenuItem,
  closeMenuItem,
  retractMenu
} from './reducers';

const INITIAL_STATE = {
  selectedItems: []
};

export default (state = INITIAL_STATE, { type, payload}) => {
  switch (type) {
    case OPEN_MENU_ITEM:
      return openMenuItem(state, payload);
    case CLOSE_MENU_ITEM:
      return closeMenuItem(state, payload);
    case RETRACT_MENU:
      return retractMenu(state);
    default:
      return state
  }
};