import React from 'react';
import moment from 'moment';

import { StyledRow, BaseColumn } from '../../../../../components/shared/sharedStyles.styles';
import { SubtitleNoMargin, CardTextNoMargin, TextContainer } from '../../../../../components/Operational/operational.styles';
import { Container } from '../../../../../components/Operational/reportHeader.styled';
import ContainerLoading from '../../../../../components/Operational/ContainerLoading';
import { formatToBRL } from '../../../../../utils/formatter';

const getAddress = (location) => {
  let finalAddress = "";
  if (location !== undefined) {
    const { endereco, codigo_imovel } = location;
    if (endereco !== null && typeof endereco === "object" && Object.keys(endereco).length > 0) {
      const address = endereco[0];
      finalAddress += (address.logradouro !== null)? address.logradouro : "";
      finalAddress += (address.numero !== null)? `, ${address.numero}` : "";
      finalAddress += (address.complemento !== null)? ` - ${address.complemento}` : "";

      if(address.bairro || address.cidade) {
        finalAddress += ' -'
        finalAddress += (address.bairro !== null)? ` ${address.bairro}` : "";
        finalAddress += (address.cidade !== null)? ` ${address.cidade}` : "";
      }

      if(address.uf) {
        finalAddress += (address.uf !== null)? ` - ${address.uf}` : "";
      }
    }

    if (codigo_imovel !== undefined && codigo_imovel !== null && typeof codigo_imovel === "string" && codigo_imovel.length > 0) {
      finalAddress += ` | Código: ${codigo_imovel}`;
    }
  }

  return finalAddress;
}

const getDate = (date) => {
  if (date !== undefined && date !== null && date !== "") {
    return moment(date).format('DD/MM/YYYY (H:mm)');
  }
  return "00/00/0000 [00:00]";
}

const GeneralReportHeader = (props) => {
  if (props.loading) {
    return (
      <ContainerLoading
        size="extra-small"
        showFirstIcon={true}
        showSecondIcon={false}
        fullWidth={true}
        showText={false}
        margin="1rem .5rem auto .5rem"
      />
    );
  }

  const { data: { locacao, laudo } } = props;
  const aluguel = (locacao !== undefined && parseFloat(locacao.aluguel)) || 0;
  const condominio = (locacao !== undefined && parseFloat(locacao.condominio)) || 0;
  const iptu = (locacao !== undefined && parseFloat(locacao.iptu)) || 0;
  const totalTenancy = formatToBRL(aluguel + condominio + iptu);
  const data = (laudo !== undefined && getDate(laudo.data_conclusao)) || "";

  const tipoImovel = (tipo) => {
    switch(tipo) {
      case "NAO_RESIDENCIAL":
        return 'Não Residencial'
      default:
        return tipo
    }
  }

  return (
    <Container width='98%' widthMobile='96%' className='generalReportHeader'>
      <StyledRow margin="0">
        <BaseColumn xs="12" sm="12" md="8" lg="8" xl="8">
          <TextContainer>
            <SubtitleNoMargin style={{marginTop: 1}}>IMÓVEL PRETENDIDO:</SubtitleNoMargin>
            <CardTextNoMargin data-testid="imovelPretendido" style={{maxWidth: '100%', color: '#000'}}>&nbsp;{getAddress(locacao)}.</CardTextNoMargin>
          </TextContainer>
        </BaseColumn>

        <BaseColumn xs="12" sm="12" md="2" lg="2" xl="2">
          <TextContainer align='flex-end'>
            <SubtitleNoMargin style={{marginTop: 1}}>ALUGUEL + ENCARGOS:</SubtitleNoMargin>
            <CardTextNoMargin data-testid="valorLocacao" style={{color: '#000'}}>&nbsp;R$ {totalTenancy}</CardTextNoMargin>
          </TextContainer>
        </BaseColumn>

        <BaseColumn xs="12" sm="12" md="2" lg="2" xl="2">
          <TextContainer align='flex-end'>
            <SubtitleNoMargin style={{marginTop: 1}}>TIPO IMÓVEL:</SubtitleNoMargin>
            <CardTextNoMargin data-testid="valorLocacao" style={{color: '#000'}}>&nbsp;{tipoImovel(locacao.tipo_imovel)}</CardTextNoMargin>
          </TextContainer>
        </BaseColumn>
        {/* <BaseColumn xs="12" sm="12" md="1" lg="1" xl="1">
          <TextContainer align="flex-end">
            <CardTextNoMargin>{data.replace('(', '[').replace(')', ']')}</CardTextNoMargin>
          </TextContainer>
        </BaseColumn> */}
      </StyledRow>
    </Container>
  );
}

export default GeneralReportHeader;