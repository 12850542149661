import { formatCNPJ, formatCPF, formatDateToBR, formatToBRL } from "../../../../../utils/formatter";

export const personalDataGiven = (data) => {
  let titleDoc = '';
  let doc = '';
  let name = '';
  let date = '';
  let motherName = '';
  doc = name = date = motherName = "Aguarde...";
  if (data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      const { cpf, cnpj, nome, nome_mae, data_nascimento } = data.pretendente;
      titleDoc = (cpf.length === 11)? 'CPF' : 'CNPJ';
      doc = (cpf.length === 11)? formatCPF(cpf) : formatCNPJ(cnpj);
      name = nome;
      date = (data_nascimento !== "" && data_nascimento !== undefined)? formatDateToBR(data_nascimento) : "00/00/0000";
      motherName = nome_mae;
    }
  }
  return [
    { title: titleDoc, data: doc },
    { title: 'Nome', data: name || 'Não fornecido' },
    { title: 'Nascimento', data: date || 'Não fornecido' },
    { title: 'Mãe', data: motherName || 'Não fornecido' }
  ];
}

export const personalDataChecked = (data) => {
  let titleDoc = '';
  let doc = '';
  let name = '';
  let date = '';
  let motherName = '';
  let cpf = '';
  let situationCnpj = '';
  let nomeFantasia = '';
  doc = name = date = motherName = "Aguarde...";

  if (data !== undefined && data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      cpf = data.pretendente.cpf || data.pretendente.cnpj;
      titleDoc = (cpf.length === 11)? 'CPF' : 'CNPJ';
    }
    console.log('data11', data)

    if ("laudo" in data && data.laudo !== null && data.laudo !== undefined) {
      const { situacao_cpf, situacao_cnpj, veracidade_nome, veracidade_data_nascimento, veracidade_nome_mae } = data.laudo;
      doc = `${formatCNPJ(cpf)}`;
      situationCnpj = (titleDoc === "CPF")? situacao_cpf.result : situacao_cnpj.result;
      if(situacao_cnpj.recommendation[0] && situacao_cnpj.recommendation[0].includes('Regularização verificada pelo')) {
        situationCnpj = `${situationCnpj} (${situacao_cnpj.recommendation[0]})`
      }
      name = veracidade_nome.result || 'Não encontrado';
      date = formatDateToBR(veracidade_data_nascimento.result.data_nascimento) !== "Invalid date"? formatDateToBR(typeof veracidade_data_nascimento.result == 'string' ? veracidade_data_nascimento.result : veracidade_data_nascimento.result.data_nascimento) : "Não encontrado";
      motherName = veracidade_nome_mae.result || 'Não encontrado';
      nomeFantasia = veracidade_nome.nomeFantasia || 'Não encontrado';
    }
  }

  return [
    { title: titleDoc, data: doc },
    { title: 'Situação CNPJ', data: situationCnpj },
    { title: 'Razão Social', data: name },
    { title: 'Nome Fantasia', data: nomeFantasia }
  ];
}

export const personalDataCheckedBoth = (data) => {
  let titleDoc = '';
  let doc = '';
  let name = '';
  let date = '';
  let age = '';
  let motherName = '';
  let cpf = '';
  let fundacao = '';
  let naturezaJuridica = '';
  let cnaeAtividade = '';
  let capitalSocial = '';
  doc = name = date = motherName = "Aguarde...";

  if (data !== undefined && data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      cpf = data.pretendente.cpf || data.pretendente.cnpj;
      titleDoc = (cpf.length === 11)? 'CPF' : 'CNPJ';
    }

    if ("laudo" in data && data.laudo !== null && data.laudo !== undefined) {
      const { situacao_cpf, situacao_cnpj, veracidade_nome, veracidade_data_nascimento, veracidade_nome_mae } = data.laudo;
      doc = `${formatCPF(cpf)} (${(titleDoc === "CPF")? situacao_cpf.result : situacao_cnpj.result})`;
      name = veracidade_nome.result || 'Não encontrado';
      date = formatDateToBR(veracidade_data_nascimento.result.data_nascimento) !== "Invalid date"? formatDateToBR(typeof veracidade_data_nascimento.result == 'string' ? veracidade_data_nascimento.result : veracidade_data_nascimento.result.data_nascimento) : "Não encontrado";
      age = Number.isInteger(veracidade_data_nascimento.result.idade) ? ` (${veracidade_data_nascimento.result.idade} anos)` : '';
      motherName = veracidade_nome_mae.result || 'Não encontrado';
      fundacao = veracidade_nome.fundacao && formatDateToBR(veracidade_nome.fundacao) || 'Não encontrado';
      naturezaJuridica = veracidade_nome.naturezaJuridica || 'Não encontrado';
      cnaeAtividade = veracidade_nome.cnaeAtividade || 'Não encontrado';
      capitalSocial = veracidade_nome.capitalSocial && 'R$ ' + formatToBRL(Number(veracidade_nome.capitalSocial)) || 'Não encontrado';
    }
  }

  return [
    { title: 'Fundação', data: fundacao },
    { title: 'Natureza Jurídica', data: naturezaJuridica },
    { title: 'CNAE/Atividade', data: cnaeAtividade },
    { title: 'Capital Social', data: capitalSocial }
  ];
}

export const getDataValidation = (data) => {
  if (data !== undefined && data !== null) {
    const { laudo } = data;
    if (laudo !== undefined) {
      const searchKeys = ["situacao_cpf", "veracidade_nome", "veracidade_data_nascimento", "veracidade_nome_mae"];
      return searchKeys.map((key) => (laudo[key] !== undefined)? laudo[key].icon : "");
    }
  }
  return [];
}
