import React, { PureComponent } from 'react';

import {
  FlexContainer,
  ContentBox,
  TitleContainer,
  Arrow,
  CardContainer,
  SyntethicColumn
} from './syntheticReport.styles';
import ResumeTable from './ResumeTable/ResumeTable';
import SystemicSight from '../SystemicSight/SystemicSight';
import ContainerLoading from '../ContainerLoading';
import {
  ResumeText
} from './ResumeTable/resumeTable.styles';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../shared/TourTooltip';

class SyntheticReportFcBasico extends PureComponent {
  state = {
    showArrow: false,
    arrow: undefined
  }

  shouldComponentUpdate(nextProps, nextState) {
    const cardAnaliseIndividual = document.querySelectorAll('.card-resumo-analise-individual')

    if(nextProps.indexActive != this.props.indexActive) {
      this.setState({arrow: cardAnaliseIndividual[nextProps.indexActive - 1] && cardAnaliseIndividual[nextProps.indexActive - 1].offsetHeight})
    }
    return true
  }
  
  showArrow = element => {
    if (!this.state.showArrow) {
      this.setState({ showArrow: true, arrow: element && element.offsetHeight });
    }
  }

  getHeightTable = () => {
    if (this.state.arrow !== null && this.state.arrow !== undefined) {
      return this.state.arrow;
    }
    return 100;
  }

  handleViewNewVersion = () => {
    const user = this.props.customer;
    const userPermited = [
      'IMOBILIARIA TESTE LTDA',
      'IMOBILIÁRIA TESTE LTDA',
      'KHCNX QICCT',
      'ABREU ADMINISTRADORA DE IMóVEIS E CONDOMíNIOS LTDA',
      'AE PATRIMôNIO',
      'ANCORA IMOBILIARIA LTDA',
      'BROGNOLI - CDA',
      'BROGNOLI - CONTINENTE',
      'BROGNOLI - DIVID (DIVID COMPARTILHAMENTOS DE IMOVEIS)',
      'BROGNOLI - ILHA (BROGNOLI IMOVEIS LTDA)',
      'ETICA EMPREENDIMENTOS LTDA',
      'FRIAS NETO CONSULTORIA E EMPREENDIMENTOS IMOBILIARIOS LTDA',
      'FUHRO SOUTO (F. SOUTO IMOVEIS LTDA)',
      'GRALHA LOCACAO E ADMINISTRACAO DE IMOVEIS LTDA',
      'HOME HUNTERS (BLUE NEGOCIOS IMOBILIARIOS – EIRELI)',
      'IMOBILIARIA AVENIDA',
      'REAL BENEFÍCIOS (REDE DE BENEFICIOS DOS AGENTES DO MERCADO IMOBILIARIO DO BRASIL)',
      'RENASCENCA (IMOPRET EMPREENDIMENTOS IMOBILIARIOS LTDA)',
      'ROCA ADMINISTRADORA DE IMÓVEIS EIRELLI',
      'TERRAZ ASSESSORIA IMOBILIÁRIA LTDA',
      'VALOR IMOBILIARIA LTDA',
      'FRANCIOSI CONSTRUTORA E IMOVEIS LTDA',
      'IVO IMÓVEIS (IVO CONSULTORIA DE IMOVEIS S S LTDA)'
    ];
    const newVersion = 'tagFrontVersion' in this.props.data && this.props.data.tagFrontVersion ? this.props.data.tagFrontVersion[0] : null
    const newVersionAll = 'tagFrontVersion' in this.props.data && this.props.data.tagFrontVersion ? this.props.data.tagFrontVersion[1] : null

    if((userPermited.includes(user) && newVersion === '1.0.0') || newVersionAll === '1.0.1') {
      return true;
    }
    return false;
  }

  render() {
    const { data, loading } = this.props;
    const cardAnaliseIndividual = document.querySelectorAll('.card-resumo-analise-individual')

    if (loading) {
      return (<ContainerLoading size="large" />);
    }
    const laudo = ("laudo" in data)? data.laudo : {};
    const parecer = ("parecer_sistemico" in laudo)? laudo.parecer_sistemico : {};

    return (
      <CardContainer cardStyle="padding: .5rem;margin-bottom: 0rem;min-height: auto;">
        <FlexContainer style={{alignItems: 'center'}}>

          <SyntethicColumn width={!this.handleViewNewVersion() ? "68%" : "100%"} className={`syntethicColumn-1 ${this.handleViewNewVersion() ? 'syntethicColumn-4' : ''}`} customstyle="display:flex;flex-direction: row;max-height: auto;">
            <ContentBox width="100%" ref={this.showArrow} className="card-resumo-analise-individual sintese-individual" style={{position: 'relative'}}>
              <TitleContainer marginBottom="15px" align='center'>PRINCIPAIS DADOS APURADOS</TitleContainer>
              {/* <ResumeText style={{fontWeight: 400, padding: 0, marginBottom: 5, color: '#152b4e'}}>*O Score FC abaixo é individual, caso este pretendente alugue sozinho este imóvel. Alterando o valor da locação, renda e origem ele é recalculado.</ResumeText>
              <ResumeText style={{fontWeight: 400, padding: 0, marginBottom: 10, color: '#152b4e'}}>**Todas as informações abaixo são consideradas no score e risco para esta locação.</ResumeText> */}
              <ResumeTable data={ data } mainContainer={this.props.mainContainerRef} tipo="fcBasico" />
              {/* <TourTooltip
                target="tooltip-sintese-individual"
                style={{right: -13, top: -25}}
              />
              <ReactTooltip id="tooltip-sintese-individual" place="left" type="dark" effect="float">
                <span>Aqui entregamos indicadores que esclarecem o <br />motivo do risco que o pretendente representa.</span>
              </ReactTooltip> */}
              <ResumeText style={{padding: 0, marginTop: 5, color: '#152b4e', fontStyle: 'italic', display: 'inline'}} className='bold-print'>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de apontamentos do pesquisado. <span style={{color: '#fe4d36'}}> {laudo.restricoes_financeiras && laudo.restricoes_financeiras.alerta_acoes_riogrande_do_sul}</span></ResumeText>
            </ContentBox>
            {!this.handleViewNewVersion() && this.state.showArrow && <Arrow className='arrow-card-analise-individual' heightArrow={cardAnaliseIndividual[this.props.indexActive - 1] && cardAnaliseIndividual[this.props.indexActive - 1].offsetHeight || 388} />}
          </SyntethicColumn>

          {!this.handleViewNewVersion() &&
            <SyntethicColumn width="28%" className='syntethicColumn-2'>
              <ContentBox width="100%" justify="flex-start">
                <TitleContainer marginTop="1.5rem" align="center" marginBottom="0">PARECER INDIVIDUAL</TitleContainer>
                <SystemicSight parecer={ parecer } toggleModalFianca={this.props.toggleModalFianca} />
                <ResumeText style={{
                  fontWeight: 400,
                  padding: 0,
                  marginBottom: 10,
                  marginTop: Array.isArray(parecer) && parecer.length && parecer[0].observacao_complementar ? 15 : -10,
                  textAlign: 'center',
                  color: '#152b4e',
                  display: 'block'
                }}>
                  *Verifique no quadro ao lado as principais informações utilizadas no cálculo deste risco individual.</ResumeText>
              </ContentBox>
            </SyntethicColumn>
          }

        </FlexContainer>
      </CardContainer>
    );
  }
}

export default SyntheticReportFcBasico;
