import React, { PureComponent } from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  CardAnalythic,
  SubtitleCard,
  SubtitleSemiBold,
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  RecommendationBox,
  RecommendationText,
  SpanRecommendation,
  RecommendationsColumn,
  IconRecommendation
} from '../analythic.styled';

import { personalDataChecked, personalDataCheckedBoth } from './products/fcEmpresa';
import ReportRecommendation from '@bit/wavfichacerta.fichacerta.icons.report-recommendation';
import Person from '@bit/wavfichacerta.fichacerta.icons.person';
import MagnifyingGlass from '@bit/wavfichacerta.fichacerta.icons.magnifying-glass';
import { identification } from '../../../../pages/operational/request/report/singleReport/actions';
import icons from '../../../shared/treatedIcons';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../shared/TourTooltip';

const { Triangle, Null, ReportAlert, Document } = icons;

class MercadoCardPj extends PureComponent {
  constructor(props){
    super(props)

    this.IdRef = React.createRef()
  }

  componentDidMount(){
    this.props.identification(this.IdRef.current)
  }

  renderDataLine = (title, data) => {
    const { status } = data;
    let Icon = null;

    if(status == 'positivo') {
      Icon = <Triangle color="#37c120" green rotate={"-90"} />;
    }
    if(status == 'negativo') {
      Icon = <Triangle color="#fe4d36" rotate={"90"} />;
    }
    if(status == 'nulo') {
      Icon = <ReportAlert />;
    }
    if(status == 'orange-circle') {
      Icon = <Null color="#ff7200" />;
    }
    if(status == 'negative-circle') {
      Icon = <Null color="#fe4d36" />;
    }
    if(status == 'dark-green-circle') {
      Icon = <Null color="#006400" />;
    }
    if(status == 'light-green-circle') {
      Icon = <Null color="#37c120" />;
    }
    if(status == 'yellow-circle') {
      Icon = <Null color="#feda32" />;
    }

    return (
      <AnalythicLine key={(title+data.value)} style={{alignItems: 'center', color: '#000'}}>
        <LineTitle maxWidth="25%" md={3} lg={3} xl={3}>{ title }</LineTitle>
        <div style={{width: 15, marginRight: 5, alignSelf: 'center'}}>{Icon}</div>
        <span data-testid={title}>{ data.value }</span>
      </AnalythicLine>
    )
  }

  renderLinesChecked = () => {
    const lines = personalDataChecked(this.props.data);
    return lines.map((item) => this.renderDataLine(item.title, item.data));
  }

  renderLinesCheckedBoth = () => {
    const lines = personalDataCheckedBoth(this.props.data);
    return lines.map((item) => this.renderDataLine(item.title, item.data));
  }

  getRecommendations = () => {
    const { data } = this.props;
    
    if (data !== undefined && data !== null && (typeof data === "object" && Object.keys(data).length > 0)) {
      const searchKeys = ["situacao_cpf", "veracidade_nome", "veracidade_data_nascimento", "veracidade_nome_mae"];
      const { laudo } = data;
      let recommendations = [];
      searchKeys.forEach((key) => {
        if (laudo[key] !== undefined && laudo[key] !== null) {
          recommendations = [...recommendations, ...laudo[key].recommendation];
        }
      });

      if (Array.isArray(recommendations) && recommendations.length > 0) {
        // Remove duplicates
        recommendations = recommendations.filter((rec, index) => recommendations.indexOf(rec) === index);
        return (
          <RecommendationBox>
            <BaseColumn xs={12} md={1} lg={1} xl={1}>
              <RecommendationText>Obs: </RecommendationText>
            </BaseColumn>
            <RecommendationsColumn xs={12} md={11} lg={11} xl={11}>
              {recommendations.map((item) => (
                <RecommendationText>
                  <IconRecommendation><ReportRecommendation/></IconRecommendation>
                  <SpanRecommendation>{item}</SpanRecommendation>
                </RecommendationText>
              ))}
            </RecommendationsColumn>
          </RecommendationBox>
        );
      }
    }
    return null;
  }

  renderResultFcRenda = (key) => {
    const { data } = this.props;
    const fcDoc = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {};
    let title = '';
    switch(key) {
      case 'parecer_fc':
        title = 'PARECER FC'
        break
    }
    if(!fcDoc.resultado || !fcDoc[key] || !fcDoc.ativo) {
      return null;
    }

    return(
      <div style={{border: '2px solid #152b4e', padding: '4px 10px', color: '#152b4e', fontSize: 12, marginTop: 6, width: '100%', fontWeight: 'bolder'}} className='result-fcdoc'>
        {title}: {fcDoc[key]}
      </div>
    )
  }

  render() {
    const { data } = this.props;
    const fcDoc = data.pretendente.produtos.ativo.filter(product => product.id === 6 || product.id === 8)

    return (
      <CardAnalythic
        title="MERCADO"
        iconHeader={MagnifyingGlass}
        iconLeft={true}
      >
        {fcDoc.length > 0 && fcDoc[0].ativo === true && data.laudo.produtos.fcrenda.resultado && (data.laudo.produtos.fcrenda.parecer_fc) &&
          <span style={{backgroundColor: '#152b4e', color: '#fff', fontWeight: 600, padding: '2px 6px', marginLeft: 4, position: 'absolute', top: 11, left: 143}}>
            FC DOC
          </span>
        }

        <StyledRow align="initial" margin="0 0 .5rem 0">
          <BaseColumn padding="0 .2rem" className="score-mercado">
            <ContainerGray ref={this.IdRef}>
              {/* <SubtitleCard>DADOS PESSOAIS <SubtitleSemiBold>ENCONTRADOS</SubtitleSemiBold></SubtitleCard> */}
              <AnalythicList>
                { this.renderLinesChecked() }
                
                {/* <TourTooltip
                  target="tooltip-score-mercado-pj"
                  style={{right: 0, top: -15}}
                />
                <ReactTooltip id="tooltip-score-mercado-pj" place="top" type="dark" effect="float">
                  <span>Confira aqui o Score de Mercado da Empresa Pesquisada.</span>
                </ReactTooltip> */}
              </AnalythicList>
            </ContainerGray>
          </BaseColumn>
          <BaseColumn padding="0 .2rem" className="informacoes-restritivas">
            <ContainerGray>
              <AnalythicList>
                { this.renderLinesCheckedBoth() }
                
                {/* <TourTooltip
                  target="tooltip-informacoes-restritivas-pj"
                  style={{right: 0, top: -15}}
                />
                <ReactTooltip id="tooltip-informacoes-restritivas-pj" place="top" type="dark" effect="float">
                  <span>Aqui citamos se houve detecção de restrições financeiras.</span>
                </ReactTooltip> */}
              </AnalythicList>
            </ContainerGray>
          </BaseColumn>
          {this.renderResultFcRenda('parecer_fc')}
        </StyledRow>
        {/* { this.getRecommendations() } */}
      </CardAnalythic>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  identification
}, dispatch)

export default connect(null, mapDispatchToProps)(MercadoCardPj)