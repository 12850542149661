import { uniq } from 'lodash';

import fcRapidaRules from './rules/fcRapidaRules';

const incomeFieldsPF = ({origem_renda, origem_renda_secundaria, renda_principal, outras_rendas}) => [
//   origem_renda === "1" || !origem_renda ? 'renda_principal' : 'renda_principal_obrigatoria',
//   renda_principal === '' || !renda_principal ? 'origem_renda' : 'origem_renda_obrigatoria',
//   origem_renda_secundaria === "1" || !origem_renda_secundaria ? 'outras_rendas' : 'outras_rendas_obrigatoria',
//   outras_rendas === '' || !outras_rendas ? 'origem_renda_secundaria' : 'origem_renda_secundaria_obrigatoria',
//   'total_renda',
  'movimentacao_bancaria_extratos'
  // 'compor_renda'
];

const incomeFieldsPJ = [
  // 'faturamento_mensal',
  // 'tipo_negocio',
  // 'natureza_juridica'
  'tel_contato_pj',
  'nome_do_responsavel_pelas_informacoes'
];

const productsPF = [
  {
    id: 1,
    name: 'FCRAPIDA',
    fields: [
      // 'renda_principal',
      // 'renda_principal_obrigatoria',
      // 'origem_renda',
      // 'origem_renda_obrigatoria',
      // 'outras_rendas',
      // 'outras_rendas_obrigatoria',
      // 'origem_renda_secundaria',
      // 'origem_renda_secundaria_obrigatoria',
      // 'total_renda',
      'movimentacao_bancaria_extratos'
      // 'compor_renda'
    ]
  },
  {
    id: 3,
    name: 'FCRAPIDA',
    fields: [
      // 'renda_principal',
      // 'renda_principal_obrigatoria',
      // 'origem_renda',
      // 'origem_renda_obrigatoria',
      // 'outras_rendas',
      // 'outras_rendas_obrigatoria',
      // 'origem_renda_secundaria',
      // 'origem_renda_secundaria_obrigatoria',
      // 'total_renda',
      'movimentacao_bancaria_extratos'
      // 'compor_renda'
    ]
  }
];

const productsPJ = [
  {
    id: 1,
    name: 'FCRAPIDA',
    fields: [
      // 'faturamento_mensal',
      // 'tipo_negocio',
      // 'natureza_juridica'
      'tel_contato_pj',
      'nome_do_responsavel_pelas_informacoes'
    ]
  },
  {
    id: 8,
    name: 'FCRAPIDA',
    fields: [
      // 'faturamento_mensal',
      // 'tipo_negocio',
      // 'natureza_juridica'
      'tel_contato_pj',
      'nome_do_responsavel_pelas_informacoes'
    ]
  }
];

export default (productsSent = [1], entity = 'PF', changedValues) => {
  if (entity === 'PF' || entity === "PJ") {
    const fieldsByProducts = productsSent.reduce((acc, id) => {
      if (entity === "PF") {
        const productsIndex = productsPF.findIndex(prd => prd.id === id);

        if (productsIndex !== -1) {
          return [...acc, ...productsPF[productsIndex].fields];
        }
      } else if (entity === "PJ") {
        const productsIndex = productsPJ.findIndex(prd => prd.id === id);

        if (productsIndex !== -1) {
          return [...acc, ...productsPJ[productsIndex].fields];
        }
      }

      return acc;
    }, []);

    let fieldsMerged = uniq(fieldsByProducts);

    // FC RAPIDA
    if (productsSent.indexOf(1) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    // FC ESSENCIAL
    if (productsSent.indexOf(3) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    if (entity === "PF") {
      return incomeFieldsPF(changedValues).filter((field) => fieldsMerged.indexOf(field) >= 0);
    } else if (entity === "PJ") {
      return incomeFieldsPJ.filter((field) => fieldsMerged.indexOf(field) >= 0);
    }
  }
  
  return [];
}