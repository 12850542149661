import React from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { Tr, Td } from '../../shared/customTable.styled';

const handleServices = (servicos) => {
  if (Array.isArray(servicos) && servicos.length > 0) {
    const mappedSevices = servicos.map(service => (
      <StyledRow justify="flex-start" key={service.id} customStyle={{ alignItems: 'center', textAlign: 'left', backgroundColor: 'transparent !important' }}>
        <span>
          {service.nome}
        </span>
        <span>
          <span style={{ marginLeft: '10px' }}>R$ {service.valor}</span>
        </span>
      </StyledRow>
    ));

    return mappedSevices;
  }

  return null;
};

const getSum = (valor, desconto, servicos) => {
  if (Array.isArray(servicos)) {
    let discountValue = 0
    if(desconto){
      discountValue = parseFloat(desconto.valor);
    }
    if (servicos.length > 0) {
      const finalSum = servicos.reduce((sum, service) => sum + parseFloat(service.valor), parseFloat(valor));
      return `R$ ${finalSum - (discountValue || 0)}`;
    }

    return `R$ ${parseFloat(valor) - (discountValue || 0)}`;
  }

  return '...Calculando';
};

const PricesRow = ({ titulo, valor, desconto, servicos, valor_editavel, valor_final }) => {
  const price = valor_editavel > 0 ? valor_editavel : valor;
  return (
  <Tr>
    <Td width="213">{titulo == 'FC REPORT' && <span>FC REPORT</span> || titulo}</Td>
    <Td width="150">R$ {price}</Td>
    {desconto &&
      <Td>R$ {desconto}</Td>
    }
    <Td width="327">
      {handleServices(servicos)}
    </Td>
    <Td width="187">
      R$ {valor_final}
      {/* {getSum(price, desconto, servicos)} */}
    </Td>
  </Tr>
)};


export default PricesRow;
