import { instance as axios } from '../axiosInstance';
import { statusHandler } from '../../utils/loginHelper';


const getEmployeeEndpoint = (clientId, employeeId) => `/api/administrador/${clientId}/funcionario/${employeeId}`;
const registerEmployeeEndpoint = (clientId) => `/api/administrador/${clientId}/funcionario`;
const getClientEmployeesEndPoint = clientId => `/api/administrador/${clientId}/funcionarios`;
const updateEmployeeEndPoint = (clientId, employeeId) => `/api/administrador/${clientId}/funcionario/${employeeId}`;
const updateEmployeeCreditEndPoint = (clientId, employeeId) => `/api/administrador/${clientId}/funcionario/${employeeId}/credito`;
const updateEmployeeTypeEndPoint = (id) => `/api/administrador/funcionario/${id}/tipo`;
const updateEmployeeStatusEndPoint = (employeeId) => `/api/administrador/funcionario/${employeeId}/status`;
const getResendEmailConfirmation = (employeeId) => `/api/confirmation/resend/${employeeId}`;
const getHeader = (token) => (
  {
    'Authorization': `Bearer ${token}`
  }
);

export const resendEmailConfirmation = (token, employeeId) => {
  const headers = getHeader(token);
  return axios.get(getResendEmailConfirmation(employeeId), {validateStatus: statusHandler, headers});
};

export const getEmployee = (token, clientId, employeeId) => {
  const headers = getHeader(token);
  return axios.get(getEmployeeEndpoint(clientId, employeeId), { validateStatus: statusHandler, headers });
};

export const registerEmployee = (token, clientId, data) => {
  const headers = getHeader(token);
  return axios.post(registerEmployeeEndpoint(clientId), data, { validateStatus: statusHandler, headers });
};

export const updateEmployee = (token, clientId, employeeId, data) => {
  const headers = getHeader(token);
  const body = {
    _method: 'PUT',
    ...data
  };
  return axios.put(updateEmployeeEndPoint(clientId, employeeId), body, { validateStatus: statusHandler, headers });
};

export const updateEmployeeType = (token, data, id) => {
  const headers = getHeader(token);
  const body = {
    _method: 'PUT',
    tipo: data
  };
  return axios.put(updateEmployeeTypeEndPoint(id), body, { validateStatus: statusHandler, headers });
};

export const updateEmployeeStatus = (token, employeeId, status, observation) => {
  const headers = getHeader(token);

  const body = {
    _method: 'PUT',
    status,
    observacao: observation
  };
  return axios.put(updateEmployeeStatusEndPoint(employeeId), body, { validateStatus: statusHandler, headers });
};

export const updateEmployeeCredit = (token, clientId, employeeId, credit) => {
  const headers = getHeader(token);
  const data = {
    _method: 'PUT',
    credito: credit
  };
  return axios.put(updateEmployeeCreditEndPoint(clientId, employeeId), data, { validateStatus: statusHandler, headers });
};

export const getEmployees = (token, clientId) => {
  const headers = getHeader(token);
  return axios.get(getClientEmployeesEndPoint(clientId), { validateStatus: statusHandler, headers });
};