import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {getUsers } from '../../users/actions';
import { getCustomerData } from '../../customer/CustomerData/actions';
import CardMessageForm from './components/CardMessageForm';
import SACModal from './components/SACModal'
import ModalTourReview from './components/ModalTourReview'
import TourModal from './components/TourModal'
import {
  StyledRow
} from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { 
  DashCard, 
  CardContainer,
  CardTitle,
  CardDescription,
  StyledLink,
  StyledLinkWithoutTo,
  StyledPageContainer,
  MainContainer
 } from './components/Card.styled';
import Header from '../../../components/Header';

import axios from 'axios';
import ReactTooltip from 'react-tooltip'

import { checkTourRequirements } from '../../../services/tourRequests';
import TourTooltip from '../../../components/shared/TourTooltip';

// ICONS
import NovaSolicitacao from '../../../components/shared/icons/svg/NovaSolicitacao';
import HouseIcon from '../../../components/shared/icons/svg/HouseIcon';
import EnvelopeIcon from '../../../components/shared/icons/svg/EnvelopeIcon';

// ** //
import DashCreditBar from './components/DashCreditBar';
import { getUsersFromState } from '../../users/selector';
import CompanyCreditBar from './components/CompanyCreditBar';

import { toastr } from 'react-redux-toastr';

import Tour from 'reactour'

const steps_adicional = [
  {
    selector: '.card-nova-solicitacao',
    content: 'Aqui você inicia uma nova análise',
  },
  {
    selector: '.tour-nova-solicitacao',
    content: 'Você pode inserir uma nova análise por aqui também',
  },
  {
    selector: '.card-ultimas-locacoes',
    content: 'Clique aqui para conferir todas as análises já realizadas',
  },
  {
    selector: '.Operacional',
    content: 'Por aqui você também confere o histórico de análises',
  },
  {
    selector: '.card-limite-credito',
    content: 'Aqui você observa seu limite disponível, caso precise de ajustes, basta acionar o usuário Master da sua empresa, ou acionar o suporte da FC.',
  },
  {
    selector: '.card-atendimento-cliente',
    content: 'Por aqui você pode enviar um e-mail para o nosso suporte',
  },
  {
    selector: '.whatsapp-button',
    content: 'Caso precise de orientação no uso da FC, ou mesmo dicas do nosso time, basta chamar um especialista FC por aqui.',
  },
  // ...
]

const steps_master = [
  {
    selector: '.Cadastro',
    content: 'Seu acesso é Master, e tem autonomia para ajustar o limite disponível. Bloquear e criar novos usuários sempre que necessário. Para mais detalhes acesse o menu cadastro!',
  },
  {
    selector: '.card-nova-solicitacao',
    content: 'Aqui você inicia uma nova análise',
  },
  {
    selector: '.tour-nova-solicitacao',
    content: 'Você pode inserir uma nova análise por aqui também',
  },
  {
    selector: '.usuarios',
    content: 'Aqui você confere as pessoas cadastradas na FC, e o limite disponível para cada uma delas.',
  },
  {
    selector: '.card-limite-credito',
    content: 'Veja aqui o limite total disponível. Para ajustar a distribuição, basta clicar neste ícone, assim você acessa a lista de usuários cadastrados.',
  },
  {
    selector: '.relatorio',
    content: 'Nesta área você consegue gerar o relatório de todas as análises realizadas no período desejado.',
  },
  {
    selector: '.card-ultimas-locacoes',
    content: 'Você acompanha as solicitações realizadas pelo seu time',
  },
  {
    selector: '.solicitacao',
    content: 'Por aqui você também acessa as solicitações do seu time.',
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class Welcome extends Component {

  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this);
    this.state = {
      clicked: false,
      isMobile: false,
      tourOpen: false,
      modalTourOpen: false,
      tourId: null,
      openModalReviewTour: false,
      requestingReviewTour: false,
      successReviewTour: false,
      errorReviewTour: false,
      reviewValueNumber: 0,
      reviewValueComment: '',
    }
  }

  componentDidMount() {
    this.props.getCustomerData();
    this.props.getUsers();
    this.setState({
      isMobile: window.matchMedia('(max-width: 800px)').matches
    });

    // let x = checkTourRequirements(true);
    // console.log('xxx1', x)
    this.getCheckOnboard();
    this.checkPermissionReview();
  }

  handleClick = () => {
    this.setState({
      clicked: false  
    })
  }

  toggleCard = () => {
    this.setState({
      clicked: false  
    })
  }

  changeState = () => {
    if (!this.state.clicked) {
      this.setState({
        clicked: true
      })
    }
  }

  renderName = () => {
    const { login } = this.props;
    let name = '';
    if (typeof login === 'object' && login) {
      if (login.user && "usuario" in login.user) {
        const { user: { usuario } } = login;
        name = usuario.nome;
      }
    }
    return name;
  }

  handleModalExcluded = () => {
		this.setState({modalTourOpen: !this.state.modalTourOpen})
    this.postCheckOnboard(true)
	}

  confirmModalExcluded = () => {
		// const {file, modulo, id_solicitacao, id_pretendente} = this.state.excludedData
		// this.props.removeFileUpload(file, modulo, id_solicitacao, id_pretendente)
    this.setState({tourOpen: true, modalTourOpen: !this.state.modalTourOpen})
    this.postCheckOnboard(false)
		// this.handleModalExcluded()
	}

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(!result.data.length) {
        this.setState({modalTourOpen: true})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async (verDepois) => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois,
        page: 'home'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    if(this.props.login.user.usuario.master) {
      document.querySelector('.navbar').classList.add('active');
    }

    if(this.state.tourId) {
      return;
    }

    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'home',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      if(this.props.login.user.usuario.master) {
        if(step === 4) {
          document.querySelector('.navbar').classList.add('active');
          document.querySelector('.Cadastro .collapse').classList.add('show')
          document.querySelector('.Cadastro').classList.add('active')
  
          document.querySelector('.Consumo').classList.remove('active')
          document.querySelector('.Operacional').classList.remove('active')
        }
        if(step === 6) {
          document.querySelector('.navbar').classList.add('active');
          document.querySelector('.Consumo .collapse').classList.add('show')
          document.querySelector('.Consumo').classList.add('active')
  
          document.querySelector('.Cadastro').classList.remove('active')
          document.querySelector('.Operacional').classList.remove('active')
        }
        if(step === 8) {
          document.querySelector('.navbar').classList.add('active');
          document.querySelector('.Operacional .collapse').classList.add('show')
          document.querySelector('.Operacional').classList.add('active')
  
          document.querySelector('.Cadastro').classList.remove('active')
          document.querySelector('.Consumo').classList.remove('active')
        }
      }
      
      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  renderUserData = () => {
    const {customer, list, login} = this.props;

    
    
    if(!Array.isArray(list) || customer === undefined || customer.condicao === "PRECADASTRADO") {
      return null;
    }

    let user = list.find((res) => {
      return res.funcionario.id === login.user.usuario.id;

    })
    
    if(user === undefined) {
      return null
    }
    
    const textMaster = 'Veja aqui o limite total disponível. Para ajustar a distribuição, basta clicar neste ícone, assim você acessa a lista de usuários cadastrados.'
    const textAdicionar = <span>Aqui você observa seu limite disponível, caso precise de ajustes, basta acionar o usuário Master da sua empresa, ou acionar o suporte da FC.</span>
    const textToolTip = login.user.usuario.master ? textMaster : textAdicionar
    
    return (
      <div style={{position: 'relative'}}>
        <DashCard className="card-limite-credito">
          <CardTitle>
            Limite Disponível
          </CardTitle>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minHeight: '76%'
            }}
          >
            <DashCreditBar
              customer={login.user.usuario.nome}
              users={user}
            />
            {login.user.usuario.master &&
              <CompanyCreditBar
                users={list}
              />
            }
          </div>
        </DashCard>
        <TourTooltip
          target="tooltip-card-limite-credito"
          style={{right: 15, top: 10}}
        />
        <ReactTooltip id="tooltip-card-limite-credito" place="top" type="dark" effect="float">
          {textToolTip}
        </ReactTooltip>
      </div>
    )
  }

  cardSacDefault(){
    return(
      <div style={{position: 'relative'}}>
        <CardTitle>
          Atendimento ao Cliente
        </CardTitle>
        <EnvelopeIcon margin={'20'} opacity={'0.7'} className='main-icon sac-icon' />
        <CardDescription>
            Escreva uma mensagem. <br/>Nós responderemos logo logo.
        </CardDescription>
      </div> 
    )
  }

  cardSacMessage() {
    if(!this.state.isMobile) {
      return (
        <span>
        <CardTitle>
          Atendimento ao cliente
        </CardTitle>
  
        <CardMessageForm toggleCard={this.toggleCard} onClick={this.handleClick.bind(this)}/>
        </span>
        )

    }

    return (
      <div>
        <CardTitle>
          Atendimento ao Cliente
        </CardTitle>
        <EnvelopeIcon margin={'20'} opacity={'0.7'} className='main-icon sac-icon' />
        <CardDescription>
            Escreva uma mensagem. <br/>Nós responderemos logo logo.
        </CardDescription>
        <SACModal toggleCard={this.toggleCard}></SACModal>
      </div> 
      )
    
  }

  handleUsersSuspenso = () => {
    const status = this.props.login.user.usuario.status
    const statusCliente = this.props.customer && this.props.customer.status

    if(statusCliente === 'SUSPENSO' || statusCliente === 'INATIVO' || status === 'SUSPENSO') {
      return(
        <StyledLinkWithoutTo onClick={() => toastr.warning('Status', 'Sua conta está SUSPENSA')}>
          <DashCard>
            <CardTitle>
              Nova Solicitação
            </CardTitle>
            <NovaSolicitacao opacity={0.7} className='main-icon' />
            <CardDescription>
              Adicionar uma nova locação.
            </CardDescription>
          </DashCard>
        </StyledLinkWithoutTo>
      )
    }

    return(
      <StyledLink to="/intranet/operacional/solicitacao/adicionar/selecionar-produto" style={{position: 'relative'}}>
        <DashCard className="card-nova-solicitacao">
          <CardTitle>
            Nova Solicitação
          </CardTitle>
          <NovaSolicitacao opacity={0.7} className='main-icon' />
          <CardDescription>
            Adicionar uma nova locação.
          </CardDescription>
        </DashCard>
        <TourTooltip
          target="tooltip-card-nova-solicitacao"
          style={{right: 15, top: 10}}
        />
        <ReactTooltip id="tooltip-card-nova-solicitacao" place="right" type="dark" effect="float">
          Aqui você inicia uma nova análise
        </ReactTooltip>
      </StyledLink>
    )
    
  }

  closeTour = () => {
    if(this.props.login.user.usuario.master) {
      document.querySelector('.navbar').classList.remove('active');
      document.querySelector('.Consumo').classList.remove('active');
      document.querySelector('.Cadastro').classList.remove('active');
      document.querySelector('.Operacional').classList.remove('active');
    }
    this.setState({tourOpen: false})
  }

  checkPermissionReview = async () => {
    try {
      const URL = API_NODE_V1_URL + '/rating-tour/list'

      const data = {
        email: this.props.user.email
      }

      const result = await axios.post(URL, data);
      console.log('result permission tour', result)
      if(result.data.length === 0) {
        this.setState({openModalReviewTour: true})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  sendReview = async () => {
    try {
      const URL = API_NODE_V1_URL + '/rating-tour'
      this.setState({requestingReviewTour: true})

      const data = {
        email: this.props.user.email,
        rating: Number(this.state.reviewValueNumber),
        comment: this.state.reviewValueComment,
        name: this.props.user.nome,
        cargo: this.props.user.funcao,
        customer: this.props.user.cliente,
        master: this.props.user.master
      }

      const result = await axios.post(URL, data);
      console.log('result', result)
      this.setState({successReviewTour: true, requestingReviewTour: false})

    } catch (err) {
      console.log('ERR:', err)
      this.setState({requestingReviewTour: false, errorReviewTour: true})
    }
  }

  closeModalReviewTour = async () => {
    this.setState({openModalReviewTour: false})

    if(!this.state.successReviewTour) {
      try {
        const URL = API_NODE_V1_URL + '/rating-tour'
  
        const data = {
          email: this.props.user.email,
          rating: 0,
          comment: '',
          name: this.props.user.nome,
          cargo: this.props.user.funcao,
          customer: this.props.user.cliente,
          master: this.props.user.master
        }
  
        const result = await axios.post(URL, data);
        console.log('result', result)
  
      } catch (err) {
        console.log('ERR:', err)
      }
    }
  }

  toggleModalReviewNewReport = () => {
    if(this.state.openModalReviewNewReportCount === 0) {
      this.setState({openModalReviewTour: true, openModalReviewNewReportCount: 1})
    }
  }

  onChangeReviewValue = (event) => {
    this.setState({reviewValueNumber: event.target.value})
  }

  onChangeCommentValue = (event) => {
    this.setState({reviewValueComment: event.target.value})
  }

  render() {
    const { clicked } = this.state;

    let cardContent;
    
    if(!clicked) {
      cardContent = this.cardSacDefault()
    }else {
      cardContent = this.cardSacMessage()
    }

    let steps = steps_adicional

    if(this.props.login.user.usuario.master) {
      steps = steps_master
    }

    let nome = this.props.login.user.usuario.nome
    if(nome) {
      nome = nome.split(' ')[0]
    }

    return (
      <StyledPageContainer>
        <Header
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <MainContainer>
          <StyledRow>
            <CardContainer>
              {this.handleUsersSuspenso()}
              <StyledLink to="/intranet/operacional/solicitacao" style={{position: 'relative'}}>
                <DashCard className='card-ultimas-locacoes'>
                  <CardTitle>
                    Últimas Locações
                  </CardTitle>
                  <HouseIcon opacity={0.7} className='main-icon' />
                  <CardDescription>
                    Acessar uma locação já existente.
                  </CardDescription>
                </DashCard>
                <TourTooltip
                  target="tooltip-card-ultimas-locacoes"
                  style={{right: 15, top: 10}}
                />
                <ReactTooltip id="tooltip-card-ultimas-locacoes" place="right" type="dark" effect="float">
                  {this.props.login.user.usuario.master ? 'Você acompanha as solicitações realizadas pelo seu time' : 'Clique aqui para conferir todas as análises já realizadas'}
                </ReactTooltip>
              </StyledLink>
            </CardContainer>
            </StyledRow >
            <StyledRow>
            <CardContainer>
              <StyledLink to={this.state.isMobile ? '#' : "/intranet/cadastro/usuarios"}>
                {this.renderUserData()}
              </StyledLink>
              <div style={{width: '48%', position: 'relative'}}>
                <DashCard onClick={this.changeState.bind(this)} className="card-atendimento-cliente">
                  {cardContent}
                </DashCard>
                <TourTooltip
                  target="tooltip-card-atendimento-cliente"
                  style={{right: 15, top: 10}}
                />
                <ReactTooltip id="tooltip-card-atendimento-cliente" place="right" type="dark" effect="float">
                  Por aqui você pode enviar um e-mail para o nosso suporte
                </ReactTooltip>
              </div>
            </CardContainer>
            </StyledRow >
        </MainContainer>
        <TourModal
          isOpen={this.state.modalTourOpen}
          onClose={this.handleModalExcluded}
          undo={this.confirmModalExcluded}
          nome={this.props.login.user.usuario.nome}
          master={this.props.login.user.usuario.master}
        />
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.closeTour}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
          startAt={0}
        />
        <ModalTourReview
          undo={() => this.sendReview()}
          isOpen={this.state.openModalReviewTour}
          onClose={this.closeModalReviewTour}
          loading={this.state.requestingReviewTour}
          error={this.state.errorReviewTour}
          success={this.state.successReviewTour}
          nome={nome}
          onChangeReviewValue={this.onChangeReviewValue}
          onChangeCommentValue={this.onChangeCommentValue}
          reviewValueNumber={this.state.reviewValueNumber}
        />
      </StyledPageContainer>
    );
  }
}

const mapDispatchTopProps = (dispatch) => bindActionCreators({
  getCustomerData,
  getUsers
  
}, dispatch)

const mapStateToProps = (state) => ({
  login: state.login,
  user: state.login.user.usuario,
  customer: state.customerData.data.cliente,
  list: getUsersFromState(state)
});

const Form = reduxForm({
  form: 'sacForm',
  enableReinitialize: true
})(Welcome);

export default connect(mapStateToProps, mapDispatchTopProps)(Form);
