import React, { Component } from "react";

export default class DrawerIcon extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 54 54"
        width="20px"
        height="20px"
      >
        <g>
          <circle
            cx="7"
            cy="47"
            r="7"
            data-original="#556080"
            class="active-path"
            data-old_color="#ffffff"
            fill="#ffffff"
          />
          <circle
            cx="27"
            cy="47"
            r="7"
            data-original="#556080"
            class="active-path"
            data-old_color="#ffffff"
            fill="#ffffff"
          />
          <circle
            cx="47"
            cy="47"
            r="7"
            data-original="#556080"
            class="active-path"
            data-old_color="#ffffff"
            fill="#ffffff"
          />
          <circle
            cx="7"
            cy="27"
            r="7"
            data-original="#556080"
            class="active-path"
            data-old_color="#ffffff"
            fill="#ffffff"
          />
          <circle
            cx="27"
            cy="27"
            r="7"
            data-original="#556080"
            class="active-path"
            data-old_color="#ffffff"
            fill="#ffffff"
          />
          <circle
            cx="47"
            cy="27"
            r="7"
            data-original="#556080"
            class="active-path"
            data-old_color="#ffffff"
            fill="#ffffff"
          />
          <circle
            cx="7"
            cy="7"
            r="7"
            data-original="#556080"
            class="active-path"
            data-old_color="#ffffff"
            fill="#ffffff"
          />
          <circle
            cx="27"
            cy="7"
            r="7"
            data-original="#556080"
            class="active-path"
            data-old_color="#ffffff"
            fill="#ffffff"
          />
          <circle
            cx="47"
            cy="7"
            r="7"
            data-original="#556080"
            class="active-path"
            data-old_color="#ffffff"
            fill="#ffffff"
          />
        </g>
      </svg>
    );
  }
}
