import * as types from '../actions/types';
import {
  onGetCustomerData,
  onGetCustomerDataSuccess,
  onGetCustomerDataFailed,
  onSaveCustomerData,
  onSaveCustomerDataSuccess,
  onSaveCustomerDataFailed
} from './reducers';

const initialState = {
  isRequesting: false,
  error: undefined,
  isSaving: false,
  errorSaving: undefined,
  data: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CUSTOMER_DATA_REQUEST:
      return onGetCustomerData(state);
    case types.CUSTOMER_DATA_SUCCESS:
      return onGetCustomerDataSuccess(state, payload);
    case types.CUSTOMER_DATA_FAILED:
      return onGetCustomerDataFailed(state);
    case types.CUSTOMER_SAVE_REQUEST:
      return onSaveCustomerData(state);
    case types.CUSTOMER_SAVE_SUCCESS:
      return onSaveCustomerDataSuccess(state);
    case types.CUSTOMER_SAVE_FAILED:
      return onSaveCustomerDataFailed(state);
    case types.RESET_DATA:
      return initialState;
    default:
      return state;
  }
}
