import styled from 'styled-components';

export const ModalTitle = styled.span`
  color: ${props => props.theme.color_purple};
`;

export const LastReasonContainer = styled.div`
  border: 1px solid ${props => props.theme.color_gray};
  color: ${props => props.theme.color_gray};
  font-size: .8rem;
  padding: .5rem;
  margin-top: 1rem;
`;
