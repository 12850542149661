import styled from 'styled-components';
import { Row } from 'reactstrap';

export const HeaderPage = styled.div`
  display: block;
  background-color: ${props => props.theme['color_light-gray']};
  min-height: 50px;
  height: ${props => props.height || '50px'};
  max-height: 100px;
  z-index: 2;
  border-bottom: 1px solid ${props => props.isTab? props.theme['color_silver-chalice'] : props.theme['color-menu-gray']};
  box-shadow: ${props => props.isTab? 'none': `0px 1px 2px 0px ${props.theme['color-menu-gray']}}`};
  
  @media (max-width: 800px) {
    display: none;
  }
`;

export const ContentHeader = styled.div`
  margin: auto;

  @media (min-width: 801px) {
    width: 98%;
    min-width: 98%;
  }

  @media (min-width: 1256px) {
    width: 81%;
    min-width: 1177px;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding-left: 0;
  }
  
  @media (min-width: 800px) {
    padding-top: .3rem;
  }
`;
export const RowSpacingBetween = styled(Row)`
  margin: 0;
  padding: 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
`;

export const SearchContainer = styled.div`
  display: flex;
  height: ${props => props.height ? props.height : '9vh'};
  justify-content: ${props => props.justify || 'space-between'};
  align-items: center;
  flex-direction: row;
  margin-bottom: 2px;

  @media(max-width: 800px){
    display: none;
  }
`;

export const SubtitleHeader = styled.span`
  color: ${props => props.theme.color_gray};
  text-align: center;
  font-size: 11px;
`;
