import React from 'react';
import TextInputContainer from '@bit/wavfichacerta.fichacerta.inputs.text-input-container';
import { InputColContainer } from '@bit/wavfichacerta.fichacerta.inputs-styles.inputs-styles';

const styles = {
  spaceFields: `
    margin: .8rem 0 1rem 0;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 !important;

    .form-group label {
      color: #f6f8f8;
      font-weight: 300;
      font-size: 13px !important;
      margin-bottom: 2px !important;
    }

    input {
      color: #f6f8f8;
      box-shadow: none !important;
    }
  `
};

const FieldContainer = ({ label, name, children, errorMessage }) => (
  <TextInputContainer
      label={label}
      htmlFor={name}
      size="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8"
      type="text"
      customstyle={styles.spaceFields}
    >
      <InputColContainer
        underlinecolor="input_underline_default_color"
      >
        {children}
      </InputColContainer>
      {errorMessage}
  </TextInputContainer>
);

export default FieldContainer;
