import styled from 'styled-components';

export const Select = styled.select`
  width: 100%;
  height: 34px;
  max-width: 100%;
  border: 0;
  color: ${props => props.value === "" ? props.theme.color_gray :  props.theme.input_color};
  background-color: transparent;

  &:focus { outline: 0; }
  & > option { color: ${props => props.theme.color_gray}; }
`;

export const SpanValue = styled.span`
  font-size: .9rem;
  margin: 0;
  color: ${props => props.color || props.theme.color_gray};
`;

export const SpanArrow = styled.span`
  position: absolute;
  bottom: .5rem;
  right: 0;
  color: ${props => props.color || props.theme.color_gray};
`;