import React, { PureComponent } from 'react';
import { StyledModal, StyledModalHeader, StyledModalFooter, ModalClose } from './styled';
import icons from '../treatedIcons';
const { Close } = icons;

class ModalContainer extends PureComponent {
  static defaultProps = {
    contentStyle: {},
    size: 'md',
    closeModal: () => {},
    centred: true,
    showCloseIcon: true
  }

  getRef = (ref) => {
    this.modal = ref;
  }

  render() {
    return (
      <StyledModal
        centered={this.props.centred}
        isOpen={this.props.isOpen}
        size={this.props.size}
        contentStyle={this.props.contentStyle}
        innerRef={this.getRef}
        toggle={this.props.closeModal}
        customstyle={this.props.customstyle}
      > 
        <div>
          <StyledModalHeader>
            {this.props.header}
          </StyledModalHeader>
          {this.props.showCloseIcon &&
            <ModalClose onClick={this.props.onClose}>
              <Close />
            </ModalClose>
          }
        </div>

        {this.props.children}

        <StyledModalFooter customstyleModalFooter={this.props.customstyleModalFooter}>
          {this.props.footer}
        </StyledModalFooter>
      </StyledModal>
    );
  }
}

export default ModalContainer;