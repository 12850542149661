import React from 'react';
import { Th } from '../../shared/customTable.styled';

const PricesHeader = ({ columns }) => {
  return (
    <tr>
      {columns.map(({ title, width, align, paddingLeft }) => (
        <Th key={title} paddingLeft={paddingLeft} textAlign={align} width={width}>
          {title}
        </Th>
      ))}
    </tr>
  );
}

export default PricesHeader;
