import React, { Component }  from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { CreditContainer, UsedCreditBar, TitleCreditBar, SubtitleBox, CreditWarnText } from './creditBar.styled';
import { formatToBRL } from '@bit/wavfichacerta.fichacerta.utils.formatters';
import { CreditBarWrapper } from './users.styled';

export default class CustomerCreditBar extends Component {

  static defaultProps = {
    customer: ''
  }

  getAvailableCredit = () => {
    const { users } = this.props;
    if (Array.isArray(users) && users.length > 0) {
      return users.reduce((acc, user) => acc + Number(user.funcionario.credito), 0);
    }
    return 0;
  }

  getUsedCredit = (availableCredit, isPercentage = true) => {
    const { users } = this.props;
    if (Array.isArray(users) && users.length > 0) {
      const usedCredit = users.reduce((acc, user) => acc + user.funcionario.credito_utilizado, 0);
      const percentageUsage = (usedCredit/availableCredit)*100;
      if (isPercentage) {
        return `${percentageUsage}%`;
      }
      return usedCredit;
    }
    return '0%';
  }

  render() {
    const availableCredit = this.getAvailableCredit();
    const usage = this.getUsedCredit(availableCredit);
    const usageValue = this.getUsedCredit(availableCredit, false);

    return (
      <StyledRow justify="flex-start" align="flex-start">
        {/* <TitleCreditBar>Utilização {this.props.customer.toUpperCase()}</TitleCreditBar> */}
        {/* <CreditContainer hasColor={availableCredit > 0} activeColor="color_purple">
          {availableCredit > 0 && <UsedCreditBar width={usage} />}
        </CreditContainer> */}
        <StyledRow justify="space-around" padding="0 .5rem">
          {/* <CreditBarWrapper>
            <SubtitleBox colorBox="color_systemic_feedback_regular">
              Limite usado <SubtitleBox semiBold>&nbsp;R$ {formatToBRL(usageValue)}</SubtitleBox>
            </SubtitleBox>
            <CreditWarnText>*Soma dos créditos usados por todos os usuários.</CreditWarnText>
          </CreditBarWrapper> */}

          <CreditBarWrapper>
            <SubtitleBox colorBox="color_purple" className='disponivel-funcionarios'>
              Limite disponível de funcionários: <SubtitleBox semiBold>&nbsp;R$ {formatToBRL(availableCredit - usageValue)}</SubtitleBox>
            </SubtitleBox>
            {/* <CreditWarnText>*Soma dos limites disponíveis vinculados disponíveis.</CreditWarnText> */}
          </CreditBarWrapper>
        </StyledRow>
      </StyledRow>
    );
  }
}
