export const noRepeatproduct = (productId, products) => {
  const prodIndex = products.findIndex(prodId => prodId === productId);

  if (prodIndex === -1) {
    return [...products, productId];
  }
  return products;
};

export const removeProduct = (productId, products) => {
  const changedList = products.filter(prodId => prodId !== productId);

  return changedList;
};
