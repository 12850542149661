import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import CardOld from '@bit/wavfichacerta.fichacerta.cards.card';
import FormContent from '../../../../../components/shared/form/FormContent';
import getApplicantFields from './applicantFields';
import applicantFields from './fields';
import { getApplicantValues, getInitialValues } from './selectors';
import Person from '@bit/wavfichacerta.fichacerta.icons.person';
import styled from 'styled-components'

const Card = styled(CardOld)`
  svg, path {
    fill: #152b4e;
  }
`

const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 17px;
  left: 165px;

  @media (max-width: 800px) {
    position: static;
    margin-bottom: 10px;
    margin-left: 5px;
  }
`

class ApplicantForm extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      desconsiderarObito: null,
      desconsiderarPendenciaCPF: null,
      desconsiderarAcoesCriminais: null,
      desconsiderarAlertaFraude: null
    }
  }

  static defaultProps = {
    products: [1],
    entity: 'PF',
    editMode: false,
    tenancyType: undefined,
    onChangeCheckObito: () => null,
    onChangeCheckPendenciaCPF: () => null,
    onChangeCheckAcoesCriminais: () => null,
    onChangeCheckAlertaFraude: () => null
  }

  handleFields = () => {
    const { products, entity, changedValues, editMode, initialValues, tenancyType, isEditable } = this.props;

    if (editMode) {
      const { tipo_imovel } = initialValues;
      return getApplicantFields(products, entity, { ...changedValues, tipo_imovel }, isEditable);
    }

    if (tenancyType !== undefined) {
      return getApplicantFields(products, entity, { ...changedValues, tipo_imovel: tenancyType });
    }

    return getApplicantFields(products, entity, changedValues);
  }

  getFields = () => {
    const { disabledFields } = this.props
    const fieldsName = this.handleFields();

    return [{
      name: 'firstRow',
      fields: fieldsName.map((field) => (field in applicantFields) && {disabledFields: disabledFields, ...applicantFields[field]})
    }];
  }

  handleCheckObito = (e) => {
    const {desconsiderarObito} = this.state
    const {suspeitaObito} = this.props

    if(desconsiderarObito === null) {
      this.setState({desconsiderarObito: !suspeitaObito}, () => {
        this.props.onChangeCheckObito(!suspeitaObito)
      })
    } else {
      this.setState({desconsiderarObito: !desconsiderarObito}, () => {
        this.props.onChangeCheckObito(!desconsiderarObito)
      })
    }
  }

  handleCheckPendenciaCPF = (e) => {
    const {desconsiderarPendenciaCPF} = this.state
    const {pendenciaCPF} = this.props

    if(desconsiderarPendenciaCPF === null) {
      this.setState({desconsiderarPendenciaCPF: !pendenciaCPF}, () => {
        this.props.onChangeCheckPendenciaCPF(!pendenciaCPF)
      })
    } else {
      this.setState({desconsiderarPendenciaCPF: !desconsiderarPendenciaCPF}, () => {
        this.props.onChangeCheckPendenciaCPF(!desconsiderarPendenciaCPF)
      })
    }
  }

  handleCheckAcoesCriminais = (e) => {
    const {desconsiderarAcoesCriminais} = this.state
    const {temAcoesCriminais} = this.props

    if(desconsiderarAcoesCriminais === null) {
      this.setState({desconsiderarAcoesCriminais: !temAcoesCriminais}, () => {
        this.props.onChangeCheckAcoesCriminais(!temAcoesCriminais)
      })
    } else {
      this.setState({desconsiderarAcoesCriminais: !desconsiderarAcoesCriminais}, () => {
        this.props.onChangeCheckAcoesCriminais(!desconsiderarAcoesCriminais)
      })
    }
  }

  handleCheckAlertaFraude = (e) => {
    const {desconsiderarAlertaFraude} = this.state
    const {temAlertaFraude} = this.props

    if(desconsiderarAlertaFraude === null) {
      this.setState({desconsiderarAlertaFraude: !temAlertaFraude}, () => {
        this.props.onChangeCheckAlertaFraude(!temAlertaFraude)
      })
    } else {
      this.setState({desconsiderarAlertaFraude: !desconsiderarAlertaFraude}, () => {
        this.props.onChangeCheckAlertaFraude(!desconsiderarAlertaFraude)
      })
    }
  }

  handleTipoPretendenteDefault = () => {
    const element = document.getElementById('tipo_pretendente')
    const { entity } = this.props;
    if(!element || entity === 'PF' || element.value == 'OUTROS') return null
    element.value = 'OUTROS'
    element.disabled = true
    element.parentElement.style.backgroundColor = '#e9ecef'
    element.parentElement.style.borderColor = '#b0afaf'
    if ("createEvent" in document) {
      var evt = document.createEvent("HTMLEvents")
      evt.initEvent("change", true, true)
      element.dispatchEvent(evt)
    } else {
      element.fireEvent("onchange")
    }
  }

  render() {
    const { entity, master } = this.props;
    const cardTitle = (entity === 'PF')? 'Pretendente' : 'Empresa';
    this.handleTipoPretendenteDefault()

    return (
      <Card title={cardTitle} iconHeader={Person} iconLeft>
        <FormContent rows={this.getFields()} />

        {(this.props.editMode && this.props.suspeitaObito !== null && this.props.suspeitaObito !== undefined && master) &&
          <WrapperCheckbox>
              <input name='desconsiderarObito' style={{cursor: 'pointer'}} id='desconsiderarObito' type='checkbox' onChange={this.handleCheckObito} checked={this.state.desconsiderarObito !== null ? !this.state.desconsiderarObito : !this.props.suspeitaObito} value={!this.state.desconsiderarObito} />
              <label for='desconsiderarObito' style={{marginLeft: 4, marginBottom: 0, fontSize: 13, color: '#152b4e', fontWeight: 800, cursor: 'pointer', color: 'red'}}>Desconsiderar o resultado da Suspeita de Óbito</label>
            </WrapperCheckbox>
        }

        {(this.props.editMode && this.props.pendenciaCPF !== null && this.props.pendenciaCPF !== undefined) &&
          <WrapperCheckbox>
              <input name='desconsiderarPendenciaCPF' style={{cursor: 'pointer'}} id='desconsiderarPendenciaCPF' type='checkbox' onChange={this.handleCheckPendenciaCPF} checked={this.state.desconsiderarPendenciaCPF !== null ? !this.state.desconsiderarPendenciaCPF : !this.props.pendenciaCPF} value={!this.state.desconsiderarPendenciaCPF} />
              <label for='desconsiderarPendenciaCPF' style={{marginLeft: 4, marginBottom: 0, fontSize: 13, color: '#152b4e', fontWeight: 800, cursor: 'pointer', color: 'red'}}>{entity === 'PF' ? 'Desconsiderar o resultado de CPF PENDENTE DE REGULARIZAÇÃO' : 'Desconsiderar o resultado de CNPJ Inapto'}</label>
            </WrapperCheckbox>
        }
        <div style={{position: 'absolute', top: 0, display: 'flex'}}>
          {(this.props.editMode && this.props.temAcoesCriminais !== null && this.props.temAcoesCriminais !== undefined && master) &&
            <WrapperCheckbox style={{position: 'relative', marginRight: 20}}>
                <input name='desconsiderarAcoesCriminais' style={{cursor: 'pointer'}} id='desconsiderarAcoesCriminais' type='checkbox' onChange={this.handleCheckAcoesCriminais} checked={this.state.desconsiderarAcoesCriminais !== null ? !this.state.desconsiderarAcoesCriminais : !this.props.temAcoesCriminais} value={!this.state.desconsiderarAcoesCriminais} />
                <label for='desconsiderarAcoesCriminais' style={{marginLeft: 4, marginBottom: 0, fontSize: 13, color: '#152b4e', fontWeight: 800, cursor: 'pointer', color: 'red'}}>Desconsiderar ALERTA Criminal</label>
              </WrapperCheckbox>
          }

          {(this.props.editMode && this.props.temAlertaFraude !== null && this.props.temAlertaFraude !== undefined && master) &&
            <WrapperCheckbox style={{position: 'relative'}}>
                <input name='desconsiderarAlertaFraude' style={{cursor: 'pointer'}} id='desconsiderarAlertaFraude' type='checkbox' onChange={this.handleCheckAlertaFraude} checked={this.state.desconsiderarAlertaFraude !== null ? !this.state.desconsiderarAlertaFraude : !this.props.temAlertaFraude} value={!this.state.desconsiderarAlertaFraude} />
                <label for='desconsiderarAlertaFraude' style={{marginLeft: 4, marginBottom: 0, fontSize: 13, color: '#152b4e', fontWeight: 800, cursor: 'pointer', color: 'red'}}>Desconsiderar ALERTA de Fraude</label>
              </WrapperCheckbox>
          }
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    changedValues: getApplicantValues(state),
    initialValues: getInitialValues(state, ownProps.editMode, ownProps.entity),
    master: state.login.user.usuario.master
  }
};

const ApplicantFormWithReduxForm = reduxForm({
  form: 'applicantForm',
  enableReinitialize: true
})(ApplicantForm);

const ApplicantFormConnected = connect(mapStateToProps, null)(ApplicantFormWithReduxForm);

export default ApplicantFormConnected;

