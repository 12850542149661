import { formatCNPJ, formatCPF, formatDateToBR } from "../../../../../utils/formatter";

export const personalDataGiven = (data) => {
  let titleDoc = '';
  let doc = '';
  let name = '';
  let date = '';
  let motherName = '';
  doc = name = date = motherName = "Aguarde...";
  if (data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      const { cpf, cnpj, nome, nome_mae, data_nascimento } = data.pretendente;
      titleDoc = (cpf.length === 11)? 'CPF' : 'CNPJ';
      doc = (cpf.length === 11)? formatCPF(cpf) : formatCNPJ(cnpj);
      name = nome;
      date = (data_nascimento !== "" && data_nascimento !== undefined)? formatDateToBR(data_nascimento) : "00/00/0000";
      motherName = nome_mae;
    }
  }
  return [
    { title: titleDoc, data: doc },
    { title: 'Nome', data: name || 'Não fornecido' },
    { title: 'Nascimento', data: date || 'Não fornecido' },
    { title: 'Mãe', data: motherName || 'Não fornecido' }
  ];
}

export const personalDataChecked = (data) => {
  let titleDoc = '';
  let doc = '';
  let name = '';
  let date = '';
  let age = '';
  let motherName = '';
  let cpf = '';
  doc = name = date = motherName = "Aguarde...";

  if (data !== undefined && data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      cpf = data.pretendente.cpf || data.pretendente.cnpj;
      titleDoc = (cpf.length === 11)? 'CPF' : 'CNPJ';
    }

    if ("laudo" in data && data.laudo !== null && data.laudo !== undefined) {
      const { situacao_cpf, situacao_cnpj, veracidade_nome, veracidade_data_nascimento, veracidade_nome_mae } = data.laudo;
      doc = `${formatCPF(cpf)} (${(titleDoc === "CPF")? situacao_cpf.result : situacao_cnpj.result})`;
      name = veracidade_nome.result || 'Não encontrado';
      date = formatDateToBR(veracidade_data_nascimento.result.data_nascimento) !== "Invalid date"? formatDateToBR(typeof veracidade_data_nascimento.result == 'string' ? veracidade_data_nascimento.result : veracidade_data_nascimento.result.data_nascimento) : "Não encontrado";
      age = Number.isInteger(veracidade_data_nascimento.result.idade) ? ` (${veracidade_data_nascimento.result.idade} anos)` : '';
      motherName = veracidade_nome_mae.result || 'Não encontrado';
    }
  }

  return [
    { title: titleDoc, data: doc },
    { title: 'Nome', data: name },
    { title: 'Nascimento', data: date + age },
    { title: 'Mãe', data: motherName }
  ];
}

export const getDataValidation = (data) => {
  if (data !== undefined && data !== null) {
    const { laudo } = data;
    if (laudo !== undefined) {
      const searchKeys = ["situacao_cpf", "veracidade_nome", "veracidade_data_nascimento", "veracidade_nome_mae"];
      return searchKeys.map((key) => (laudo[key] !== undefined)? laudo[key].icon : "");
    }
  }
  return [];
}
