import React from 'react';
import styled from 'styled-components';
import LoadingGif from '../../../img/gif/ico-loading-blue-and-yellow-small.gif';

const IMGContainer = styled.img`
  width: ${props => props.width? props.width : '22px'};
`;

export default (props) => (
  <IMGContainer src={LoadingGif} />
)