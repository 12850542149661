import styled from 'styled-components';

export const ContainerBreadcrumb = styled.div`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
`;

export const UlBreadcrumb = styled.ul`
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
  text-transform: lowercase;

  & > li:first-child {
    padding-left: 0;
  }
  
  & > li:last-child {
    text-transform: uppercase;
    color: ${props => props.theme.color_gray};
  }
  
  & > li {
    font-size: 10px;
    padding-top: 2px;
    padding-left: 0;
    padding-right: 3px;
    color: ${props => props.theme.color_gray};

    & > a {
      color: ${props => props.theme.color_gray};

      &:hover {
        text-decoration: unset;
      }
    }

    &::after {
      color: ${props => props.theme.color_purple};
      font-weight: 600;
      content: '〉';
      position: relative;
      right: -3px;
      left: 5px;
      top: 2px;
      font-size: 13px;
    }
  }

  & > li:last-child {
    &::after { content: '' }
  }

  ${props => {
    const { getMedias: { iphones } } = props.theme;
    return `
      ${iphones()`
        background-color: transparent;
        padding-left: .5rem;
        padding-right: .5rem;
        `
      }
    `;
  }}
`;
