import { removeNonDigit } from '@bit/wavfichacerta.fichacerta.utils.normalizers';
import { validatePhone } from '@bit/wavfichacerta.fichacerta.utils.validators';

export const defaultNonRequiredPhoneWithoutDDI = (name, label, size = "") => ({
  name: name || 'telefone_principal',
  label: label || 'WITHOUT_LABEL',
  isRequired: false,
  size: size,
  type: 'maskedText',
  normalize: removeNonDigit,
  warn: validatePhone,
  placeholder: '(   ) _____-_____',
  props: {
    regex: [
      '(', /\d/, /\d/, ')',
      /\d/, /\d/, /\d/, /\d/, /\d/,
      '-',
      /\d/, /\d/, /\d/, /\d/
    ]
  }
});

