export const loginErrorHandler = (errorObj) => {
  if (errorObj.message.username) {
    return errorObj.message.username[0];
  }

  if (errorObj.message.email) {
    return errorObj.message.email[0];
  }

  if (errorObj.message.password) {
    return errorObj.message.password[0];
  }

  if (errorObj.message === 'Request failed with status code 500') {
    return 'Algo inesperado aconteceu. Entre em contato com o suporte: (21) 98734-6472';
  }

  if (errorObj.message === 'Network Error') {
    return 'Verifique sua conexão de internet.';
  }

  return errorObj.message;
};

export const statusHandler = (status) => {
  return (status >= 200 && status <= 422 ) || status == 433;
};
