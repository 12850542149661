import React, { Component } from 'react';
const IconFcAcoes = require('../../../img/png/ico-fc-acoes.png');

export default class FcAcoes extends Component {
  render() {
    return (
      <img src={IconFcAcoes} alt="" />
    )
  }
}
