import React, { PureComponent } from 'react'
import { Col } from 'reactstrap';
import RadioButton from './RadioButton'
import { StyledLabel } from './Inputs.styles'
import { StyledRow } from './sharedStyles.styles';

class Group extends PureComponent {
  static defaultProps = {
    onCheck: () => undefined
  }

  state = {
    checked: this.props.meta.initial
  }

  onCheck = (option) => (e) => {
    const { input, onCheck } = this.props;
    const { checked } = e.target;

    onCheck(checked, option);
    this.setState({ checked: option.value });
    input.onChange(option.value);
  }

  handleOptionsList = () => {
    const { input } = this.props;
    
    const mappedOption = this.props.options.map((option) => {
      return (
        <RadioButton
          key={option.value}
          disabled={this.props.disabled}
          id={`${input.name}_${option.value}`}
          name={input.name}
          label={option.label}
          onChange={this.onCheck(option)}
          checked={this.state.checked === option.value}
          value={option.value}
        />
      )
    });

    return mappedOption;
  }

  handleOptionsDirectionRender = () => {
    if (this.props.direction === 'column') {
      return (
        <Col style={{ paddingLeft: 0, marginLeft: 0 }}>
          {this.handleOptionsList()}
        </Col>
      );
    }

    return (
      <StyledRow justify="space-around" padding="0 45px 0 0">
        {this.handleOptionsList()}
      </StyledRow>
    );
  }

  render() {
    return (
      <Col className={this.props.size}>
        <Col style={{ padding: 0, marginBottom: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledLabel>{this.props.title || this.props.label}</StyledLabel>
            {/* {
              this.props.meta.dirty && this.props.input.value !== '' &&
              <StyledErrorSpanForCheckboxGroup>{this.props.meta.error}</StyledErrorSpanForCheckboxGroup>
            } */}
          </div>
        </Col>
        {this.handleOptionsDirectionRender()}
      </Col>
    );
  }
}

export default Group;
