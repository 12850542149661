import * as types from '../actions/types';
import {
  onCloseModal,
  onOpenModal
} from './reducers';

const initialState = {
  isOpen: false
}

export default (state = initialState, { type }) => {
  switch (type) {
    case types.OPEN_MODAL_INCOME_SECTION: 
      return onOpenModal(state);
    case types.CLOSE_MODAL_INCOME_SECTION:
      return onCloseModal(state);
    default: 
      return state;
  }
}
