import BaseTextInput from './TextInput';
import BaseTextInputDisabled from './TextInputDisabled';
import PasswordTextInput from './PasswordInput';
import CustomMaskedInput from './CustomMaskedInput';
import ComposedInputs from './ComposedInputs';
import SelectInput from './SelectInput';
import DynamicPhoneInputGroup from  './DynamicPhoneInputGroup';
import CheckboxGroup from '../shared/CheckBoxGroup';
import TextArea from './TextArea';
import RadioButtonGroup from './RadioButtonGroup';
import ActionButton from './ActionButtonForm';
import Checkbox from './CheckBox';
import DynamicPhoneGroup from './DynamicPhoneGroup';
import CEPField from './fields/CEPField';

export default {
  text: BaseTextInput,
  textDisabled: BaseTextInputDisabled,
  password: PasswordTextInput,
  textarea: TextArea,
  maskedText: CustomMaskedInput,
  composed: ComposedInputs,
  select: SelectInput,
  dynamicPhones: DynamicPhoneInputGroup,
  checkbox: CheckboxGroup,
  radio: RadioButtonGroup,
  actionButton: ActionButton,
  singleCheckbox: Checkbox,
  phonesGroup: DynamicPhoneGroup,
  cep: CEPField
};