import { toastr } from 'react-redux-toastr';
import * as types from './types';
import { actionCreator, generalActionTypes,extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { getEmployersList } from '../../../services/customer/customer';
import { resendEmailConfirmation, updateEmployeeType, updateEmployeeStatus } from '../../../services/customer/employees';
import { reset } from 'redux-form';
import { replace } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../../auth/login/actions/types.js';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const resetTable = () => actionCreator(types.USERS_LIST_RESET_DATA);
export const changeSort = (tag) => actionCreator(types.USERS_LIST_CHANGE_ORDER, tag);
export const changePage = (newPage) => actionCreator(types.USERS_LIST_CHANGE_PAGE, newPage);
export const changeMaxResults = (maxResults) => actionCreator(types.USERS_LIST_CHANGE_MAX_RESULTS, maxResults);

export const changeUserType = (data, id) => {
    return async (dispatch, getState) => {
      dispatch(actionCreator(types.CHANGE_USER_TYPE));
      try {
        const { login: {user: { token } } } = getState();
        const payload = await updateEmployeeType(token.access_token, data, id);
        if(payload.status !== 200) {
          if(payload.status === 401) {
            dispatch(actionCreator(LOGOUT_SUCCESS));
            dispatch(replace('/auth'));
            
          } else {
            toastr.warning("Error", extractErrorMessages(payload.data.message));
            dispatch(actionCreator(types.CHANGE_USER_TYPE_FAILED));
            dispatch(reset('changeTypeForm'))
          }
        } else {
          toastr.success('Sucesso!', payload.data.message);
          dispatch(actionCreator(types.CHANGE_USER_TYPE_SUCCESS));
          dispatch(reset('changeTypeForm'))
        }
      } catch( e) {
        dispatch(actionCreator(NON_HANDLED_ERROR, 'No momento não foi possivel atualizar o status do usuário.'))
        dispatch(reset('changeTypeForm'))
      }
    }
  }

export const getSendConfirmationMail = (id) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.RESEND_EMAIL_CONFIRMATION));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await resendEmailConfirmation(token.access_token, id);
      if(payload.status !== 200) {
        if(payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Reenvio de e-mail', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.RESEND_EMAIL_CONFIRMATION_FAILED));
        }
      }else {
        toastr.success("Reenvio de e-mail", payload.data.message)
        dispatch(actionCreator(types.RESEND_EMAIL_CONFIRMATION_SUCCES, payload.message));
        
        

      }
    }
    catch( error ) {
      dispatch(actionCreator(NON_HANDLED_ERROR, "Erro ao enviar e-mail de confirmação, tente novamente mais tarde!"))
    }
  }
}

export const getUsers = (userStatus, page) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.USERS_LIST_REQUEST));
    try {
      const { login: { user: { token } }, employersList: { pagination } } = getState();
      if(page) {
        pagination.currentPage = page
      }
      const payload = await getEmployersList(token.access_token, pagination, userStatus);
      if(payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Usuários', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.USERS_LIST_FAILED));
        }
      } else {
        dispatch(actionCreator(types.USERS_LIST_SUCCESS, payload.data));
        dispatch(actionCreator('employeesTable/GET_EMPLOYEES_SUCCESS', payload.data));
      }
    } catch (error) {
      dispatch(actionCreator(NON_HANDLED_ERROR, "Erro ao carregar os usuários, se persistir contate a FC ANALISE."));
    }
  }
}


export const changeUserStatus = (id, status, observation) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.CHANGE_USER_STATUS));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await updateEmployeeStatus(token.access_token, id, status, observation);
      if(payload.status !== 200) {
        if(payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Erro!', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.CHANGE_USER_STATUS_FAILED));
        }
      } else {
        toastr.success('Sucesso!', payload.data.message);
        dispatch(actionCreator(types.CHANGE_USER_STATUS_SUCCESS));
        
      }
    } catch( error ) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'No momento não foi possivel alterar o status do usuário!'))
    }
  }
}

// export const changeClientStatus = (id) => {
//   return async (dispatch, getState) => {
//     try {
//       const { login: { user: { token: { access_token } } }, form } = getState();
//       const { selectStatus, statusReason } = form.stateModalForm.values;
//       const payload = await setClientStatus(access_token, id, selectStatus, statusReason);

//       if(payload.status !== 200) {
//         if(payload.status === 401) {
//           dispatch(actionCreator(LOGOUT_SUCCESS));
//           dispatch(replace('/auth'));
//         } else {
//           toastr.error('Editar STATUS', 'Não foi possivel executar a ação');
//           dispatch(closeStatusModal());
//         }
//       } else {
//         dispatch(closeStatusModal());
//         toastr.success('Editar STATUS', 'Status editado com sucesso');
//         setTimeout(() => {
//           dispatch(getUsers());
//         }, 100);
//       }
//     }
//     catch (e) {
//       dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu. Tente mais tarde'));
//     }
//   }
// }
