import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { unregisterField, registerField, clearFields } from 'redux-form';
import inputHandler from '@bit/wavfichacerta.fichacerta.inputs-functions.input-handler';
import { maskedText } from '@bit/wavfichacerta.fichacerta.inputs.inputs';
import { defaultPhoneWithoutDDI } from '@bit/wavfichacerta.fichacerta.fields-templates.phone';
import styled from 'styled-components';
import { showPhoneFields } from './selector';
import Plus from '@bit/wavfichacerta.fichacerta.icons.plus';
import Minus from '@bit/wavfichacerta.fichacerta.icons.minus';

const SignButtonContainer = styled.div`
  cursor: pointer;
  position: relative;
  right: 2%;
  bottom: .3rem;
`;

class DynamicPhoneOperational extends PureComponent {
  state = {
    fields: this.props.phoneFields
  }

  handleComposedFields = (field) => {
    if ("after" in field) {
      return (
        <Fragment>
          { inputHandler({ maskedText })(field) }
          {field.after}
        </Fragment>
      )
    }

    return inputHandler({ maskedText })(field);
  }

  renderFields = () => {
    const { fields } = this.state;
    if (Array.isArray(fields) && fields.length > 0) {
      const fieldsToRender = fields.map((field, index) => {
        const newField = field;
        
        if (index === 1) {
          newField.after =  (
            <SignButtonContainer onClick={this.removeField}>
              <Minus width="1rem" height="1rem" fill="color_purple" />
            </SignButtonContainer>
          );
        } else if (fields.length === 1) {
          newField.after = (
            <SignButtonContainer onClick={this.addField}>
              <Plus width="1rem" height="1rem" fill="color_purple" />
            </SignButtonContainer>
          );
        } else {
          newField.after = null;
        }
        return this.handleComposedFields(newField);
      });
      return fieldsToRender;
    }

    return null;
  }

  addField = (e) => {
    e.preventDefault();
    const { size } = this.props;
    this.setState({ fields: [...this.state.fields, defaultPhoneWithoutDDI('telefone_adicional', 'Telefone adicional', size, false)]})
  }

  removeField = (e) => {
    e.preventDefault();
    this.setState((state) => {
      const popedFieldList = state.fields;
      popedFieldList.pop();
      return { fields: popedFieldList }
    })
    this.props.unregisterField(this.props.formName, 'telefone_adicional');
    this.props.clearFields(this.props.formName, true, true, 'telefone_adicional');
  }

  render() {
    return this.renderFields();
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    phoneFields: showPhoneFields(state, ownProps),
    requirements: state.requirements
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({ unregisterField, registerField, clearFields }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DynamicPhoneOperational);