import React, { PureComponent } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  CardAnalythic,
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  RecommendationBox,
  RecommendationText,
  RecommendationsColumn,
  IconRecommendation,
  SpanRecommendation,
  OtherDataList,
  SubtitleSemiBold,
  RiskText
} from '../analythic.styled';

import {
  getDeathSuspicius,
  getProfessionalGuarantor,
  getPastSearches,
  getRecommendations,
  getNumberOfCreditSearchesPhrase,
  getPastSeachesCommerce
} from './products/fcAnalise';
import MagnifyingGlass from '@bit/wavfichacerta.fichacerta.icons.magnifying-glass';
import ReportRecommendation from '@bit/wavfichacerta.fichacerta.icons.report-recommendation';
import { network } from '../../../../pages/operational/request/report/singleReport/actions';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../shared/TourTooltip';

class FichaCertaNetwork extends PureComponent {
  constructor(props){
    super(props)

    this.Network = React.createRef()
  }

  componentDidMount(){
    this.props.network(this.Network.current)
  }

  renderDataLine = (title, data) => (
    <AnalythicLine style={{color: '#000'}}>
      <LineTitle maxWidth="205px">{ title }</LineTitle>
      <span>{ data }</span>
    </AnalythicLine>
  )

  renderRecommendations = () => {
    const { data } = this.props;
    const recommendations = getRecommendations(data);
    if (Array.isArray(recommendations) && recommendations.length > 0) {
      return (
        <RecommendationBox>
          <BaseColumn xs={12} md={1} lg={1} xl={1}>
            <RecommendationText>Obs: </RecommendationText>
          </BaseColumn>
          <RecommendationsColumn xs={12} md={11} lg={11} xl={11}>
            {recommendations.map((item) => (
              <RecommendationText>
                <IconRecommendation><ReportRecommendation /></IconRecommendation>
                <SpanRecommendation>{item}</SpanRecommendation>
              </RecommendationText>
            ))}
          </RecommendationsColumn>
        </RecommendationBox>
      );
    }
    return null;
  }

  renderFichaCertaPastSearches = () => {
    const pastSearches = getPastSearches(this.props.data);
    if (Array.isArray(pastSearches)) {
      return (
        <OtherDataList>
          {pastSearches.map((item) => <li>{item}</li>)}
        </OtherDataList>
      )
    }

    return pastSearches || "";
  }

  renderMarketPastSearches = () => {
    const numberOfCreditSearchesPhrase = getNumberOfCreditSearchesPhrase(this.props.data);
    const pastSearches = getPastSeachesCommerce(this.props.data);
    const totalConsultasAnteriores = this.props.data.laudo && 'rede_ficha' in this.props.data.laudo && 'pesquisas_anteriores_api' in this.props.data.laudo.rede_ficha && this.props.data.laudo.rede_ficha.pesquisas_anteriores_api.result.total_consultas_credito || 0
    if (Array.isArray(pastSearches)) {
      return (
        <OtherDataList style={{color: totalConsultasAnteriores >= 7 ? '#795548' : '#000000'}}>
          <SubtitleSemiBold style={{fontWeight: 600}} className={`ignore-css-print bold-print`}>{numberOfCreditSearchesPhrase}</SubtitleSemiBold>
          {pastSearches.map((item) => <li className={`ignore-css-print bold-print`} style={{fontWeight: 600}}>{item}</li>)}
        </OtherDataList>
      )
    }

    return numberOfCreditSearchesPhrase || "";
  }

  renderResultFcRenda = (key) => {
    const { data } = this.props;
    const fcDoc = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {};
    const title = key === 'confirmacao_analise_consultas_anteriores' ? 'ANÁLISE CONSULTAS ANTERIORES' : 'CONFIRMAÇÃO PAGAMENTO DE ALUGUEL';
    if(!fcDoc.resultado || !fcDoc[key] || !fcDoc.ativo) {
      return null;
    }

    return(
      <div style={{border: '2px solid #152b4e', padding: '4px 10px', color: '#152b4e', fontSize: 12, marginTop: 6, width: '100%', fontWeight: 'bolder'}} className='result-fcdoc'>
        {title}: {fcDoc[key]}
      </div>
    )
  }

  render() {
    const { data } = this.props;
    const fcDoc = data.laudo.produtos.fcrenda;
    return (
      <CardAnalythic
        title="Rede FC de Informações"
        iconHeader={MagnifyingGlass}
        iconLeft={true}
      >
        {fcDoc.ativo === true && fcDoc.resultado && (fcDoc.confirmacao_analise_consultas_anteriores || fcDoc.confirmacao_pagamento_aluguel) &&
          <span className='font-13-print' style={{backgroundColor: '#152b4e', color: '#fff', fontWeight: 600, padding: '2px 6px', marginLeft: 4, position: 'absolute', top: 12, left: 272}}>
            FC DOC
          </span>
        }
        <StyledRow align="initial" margin="0 0 .5rem 0" className="font-13-print">
          <BaseColumn>
            <ContainerGray ref={this.Network}>
              <AnalythicList>
                { this.renderDataLine("Suspeita de óbito", getDeathSuspicius(data)) }
                <AnalythicLine style={{color: '#000'}}>
                  <LineTitle maxWidth="205px" style={{color: '#000'}}>Consultas recentes (Mercado)*</LineTitle>
                  <div>
                    {this.renderMarketPastSearches()}
                  </div>
                </AnalythicLine>
              </AnalythicList>
              <RiskText style={{fontStyle: 'italic', fontSize: 11, fontWeight: 500, color: '#152b4e'}}>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de consultas do pesquisado.</RiskText>
            </ContainerGray>
          </BaseColumn>
          {this.renderResultFcRenda('confirmacao_analise_consultas_anteriores')}
          {this.renderResultFcRenda('confirmacao_pagamento_aluguel')}
        </StyledRow>
        { this.renderRecommendations() }
        {/* <TourTooltip
          target="tooltip-rede-fc"
          style={{right: -3, top: -17}}
        />
        <ReactTooltip id="tooltip-rede-fc" place="left" type="dark" effect="float">
          <span>Aqui detalhamos as consultas realizadas neste CPF nos últimos 3 meses, caso <br />haja um volume acima de 7 tenha mais atenção a este cadastro, nestes casos <br />sugerimos acionar nosso suporte para esclarecer dúvidas sobre este assunto.</span>
        </ReactTooltip> */}
      </CardAnalythic>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  network
}, dispatch)

export default connect(null, mapDispatchToProps)(FichaCertaNetwork)