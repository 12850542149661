import React from 'react';
import { Th } from '@bit/wavfichacerta.fichacerta.tables.styles';

const TableHeader = () => (
  <tr style={{color: '#000'}}>
    <Th>
      Data
    </Th>

    <Th align="right">
      Valor
    </Th>

    <Th>
      Cartório
    </Th>
    <Th>
      Cidade/UF
    </Th>
  </tr>
);

export default TableHeader;
