import React, { PureComponent } from 'react';

import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import { PrimaryButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { Container, SpanTitle, GrayText } from '../form/fieldModal/formModalFields.styled';

class InsuficientCreditsModal extends PureComponent {

  renderFooter = () => (
    <StyledRow padding='0 3em' justify="center">
      <PrimaryButton data-testid="go-back-button" onClick={this.props.closeModal} >Fechar</PrimaryButton>
      {/* <PrimaryButton data-testid="go-back-button" onClick={this.props.submitVerLaudoAnterior} style={{marginLeft: 10}}>Ver o laudo anterior</PrimaryButton> */}
    </StyledRow>
  )

  renderHeader = () => (
    <SpanTitle customstyle="margin: auto;">
      ATENÇÃO
    </SpanTitle>
  )

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        size="md"
        onClose={this.props.closeModal}
        footer={this.renderFooter()}
        header={this.renderHeader()}
      >

        <Container customstyle="text-align: center; margin: 20px auto;">
          <GrayText>
            {/* <span style={{fontSize: 20}} >Ops!</span>
            <br/> */}
            <div style={{marginBottom: 14}}>Você precisa clicar em Cancelar Pagamento Pendente para poder incluir outra pessoa nesta solicitação e depois gerar novamente o processo de pagamento</div>
          </GrayText>
        </Container>

      </ModalContainer>
    );
  }
}

export default InsuficientCreditsModal;