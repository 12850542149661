import moment from 'moment';
import { isEmpty } from 'lodash';
import { formatToBRL, formatToBRLwithoutCents } from '../../../../../utils/formatter';

const isThereData = (data, childKey) => {
  if (!isEmpty(data)) {
    if (!isEmpty(data) && childKey in data) {
      return true;
    }

    return false;
  }

  return false;
};

export const getResultObj = (data, parentKey) => {
  if (isThereData(data, parentKey)) {
    const { [parentKey]: parentObj } = data;

    if (isThereData(parentObj, 'result')) {

      return parentObj.result;

    }

    return {};
  }

  return {};
}

const resumeReferObj = {
  total_ocorrencias: { title: "Total de ocorrências", name: 'total_ocorrencias' },
  valor_total_pendencia: { title: "Valor total pendente", name: 'valor_total_pendencia' },
  data_primeira_ocorrencia: { title: "Data da primeira", name: 'data_primeira_ocorrencia' },
  data_ultima_ocorrencia: { title: "Data da última", name: 'data_ultima_ocorrencia' },
  total_ocorrencias_acoes: { title: "Total de ações", name: 'total_ocorrencias' },
};

export const getFinancialConstraints = (data, childKey) => {
  const result = getResultObj(data, 'restricoes_financeiras');

  if (isThereData(result, childKey)) {
    return result[childKey];
  }

  return {};

}

export const pendingPaymentResume = (data, fields) => {
  const constraintObj = getFinancialConstraints(data, 'pendencias');
  let payload = [];

  if (isThereData(constraintObj, 'resumo')) {
    fields.forEach((field) => {
      let { name, title } = resumeReferObj[field];
      const { [name]: dataField } = constraintObj.resumo;

      if (name === 'valor_total_pendencia') {
        payload.push({ title, data: "R$ "+ formatToBRL(dataField) });
      } else {
        if (name === 'data_ultima_ocorrencia' || name === 'data_primeira_ocorrencia') {
          payload.push({ title, data: moment(dataField).format('MM/YYYY') });
        } else {
          payload.push({ title, data: dataField });
        }
      }

    });
  } else {
    fields.forEach((field) => {
      let { title } = resumeReferObj[field];

      payload.push({ title, data: 'Não informado' });
    });
  }

  return payload;
}

export const checkResume = (data, fields) => {
  const constraintObj = getFinancialConstraints(data, 'cheques');
  let payload = [];

  if (isThereData(constraintObj, 'resumo')) {
    fields.forEach((field) => {
      let { name, title } = resumeReferObj[field];
      const { [name]: dataField } = constraintObj.resumo;

      if (name === 'valor_total_pendencia') {
        payload.push({ title: '', data: '' });
      } else {
        if (name === 'data_ultima_ocorrencia' || name === 'data_primeira_ocorrencia') {
          payload.push({ title, data: moment(dataField).format('MM/YYYY') });
        } else {
          payload.push({ title, data: dataField });
        }
      }

    });
  } else {
    fields.forEach((field) => {
      let { title, name } = resumeReferObj[field];
      if (name === 'valor_total_pendencia') {
        payload.push({ title, data: '' });
      } else {
        payload.push({ title, data: 'Não informado' });
      }
    });
  }

  return payload;
}

export const protestsResume = (data, fields) => {
  const constraintObj = getFinancialConstraints(data, 'protestos');
  let payload = [];

  if (isThereData(constraintObj, 'resumo')) {
    fields.forEach((field) => {
      let { name, title } = resumeReferObj[field];
      const { [name]: dataField } = constraintObj.resumo;

      if (name === 'valor_total_pendencia') {
        payload.push({ title, data: "R$ " + formatToBRL(dataField) });
      } else {
        if (name === 'data_ultima_ocorrencia' || name === 'data_primeira_ocorrencia') {
          payload.push({ title, data: dataField ? moment(dataField).format('MM/YYYY') : 'Não informado' });
        } else {
          payload.push({ title, data: dataField });
        }
      }

    });
  } else {
    fields.forEach((field) => {
      let { title } = resumeReferObj[field];

      payload.push({ title, data: 'Não informado' });
    });
  }

  return payload;
}

export const acoesResume = (data, fields) => {
  const constraintObj = getFinancialConstraints(data, 'acoes');
  let payload = [];

  if (isThereData(constraintObj, 'resumo')) {
    fields.forEach((field) => {
      let { name, title } = resumeReferObj[field];
      const { [name]: dataField } = constraintObj.resumo;

      if (name === 'valor_total_pendencia') {
        payload.push({ title, data: "R$ " + formatToBRL(dataField) });
      } else {
        if (name === 'data_ultima_ocorrencia' || name === 'data_primeira_ocorrencia') {
          payload.push({ title, data: moment(dataField).format('MM/YYYY') });
        } else {
          payload.push({ title, data: dataField });
        }
      }

    });
  } else {
    fields.forEach((field) => {
      let { title } = resumeReferObj[field];

      payload.push({ title, data: 'Não informado' });
    });
  }

  return payload;
}

export const acoesCriminaisResume = (data, fields) => {
  const constraintObj = getFinancialConstraints(data, 'acoes_criminais');
  let payload = [];

  if (isThereData(constraintObj, 'resumo')) {
    fields.forEach((field) => {
      let { name, title } = resumeReferObj[field];
      const { [name]: dataField } = constraintObj.resumo;

      if (name === 'valor_total_pendencia') {
        payload.push({ title, data: "R$ " + formatToBRL(dataField) });
      } else {
        if (name === 'data_ultima_ocorrencia' || name === 'data_primeira_ocorrencia') {
          payload.push({ title, data: moment(dataField).format('MM/YYYY') });
        } else {
          payload.push({ title, data: dataField });
        }
      }

    });
  } else {
    fields.forEach((field) => {
      let { title } = resumeReferObj[field];

      payload.push({ title, data: 'Não informado' });
    });
  }

  return payload;
}

export const acoesCiveisResume = (data, fields) => {
  const constraintObj = getFinancialConstraints(data, 'acoes_civeis');
  let payload = [];

  if (isThereData(constraintObj, 'resumo')) {
    fields.forEach((field) => {
      let { name, title } = resumeReferObj[field];
      const { [name]: dataField } = constraintObj.resumo;

      if (name === 'valor_total_pendencia') {
        payload.push({ title, data: "R$ " + formatToBRL(dataField) });
      } else {
        if (name === 'data_ultima_ocorrencia' || name === 'data_primeira_ocorrencia') {
          payload.push({ title, data: moment(dataField).format('MM/YYYY') });
        } else {
          payload.push({ title, data: dataField });
        }
      }

    });
  } else {
    fields.forEach((field) => {
      let { title } = resumeReferObj[field];

      payload.push({ title, data: 'Não informado' });
    });
  }

  return payload;
}
