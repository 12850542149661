import styled from 'styled-components';
import Card from '@bit/wavfichacerta.fichacerta.cards.card';

import backgroundImage from './../../img/jpg/background-capa2.jpg'

export const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme['color_light-gray']};
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
`;

export const LoginContainerTransparent = styled.div`
  background-color: ${({bgColor}) => bgColor || 'rgba(40, 45, 64, 0.6)'};
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${({zIndex}) => zIndex || '1'};
  opacity: ${({opacity}) => opacity || '1'};
  background: linear-gradient(135deg, #2056acc2 50%, #152b4ee3 80%);
`

export const CardLogin = styled(Card)`
  padding: 0;
  width: 276px;
  box-shadow: none;
`;

export const LinkExteral = styled.a`
  color: #ff7200;
  text-decoration: none !important;
  font-size: .8rem;
  margin-top: 30px;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #aa00ff;
  }
`;
