import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { fetchCEP } from '../../../pages/validation/actions';

import handler from '@bit/wavfichacerta.fichacerta.inputs-functions.input-handler';
import { checkboxGroup } from '@bit/wavfichacerta.fichacerta.inputs.inputs';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';

class RenderFields extends PureComponent {

  renderField = (row) => {
    if (Array.isArray(row.fields) && row.fields.length > 0) {
      const { requirements, master } = this.props;
      return row.fields.map((field) => {
        return handler({ checkboxGroup })({ ...field, options: requirements[field.name], disabled: !master });
      });
    }
    return null;
  }

  renderLine = () => {
    const { fields } = this.props;
    if (Array.isArray(fields) && fields.length > 0) {
      return fields.map((row, index) => (
        <StyledRow key={row.name} align="flex-start" justify="flex-start" customstyle={`margin-top: ${index > 0? ".5rem": "0"};`}>
          {this.renderField(row)}
        </StyledRow>
      ));
    }
    return null;
  }

  render() {
    return (
      <Fragment>
        {this.renderLine()}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ fetchCEP }, dispatch);

const mapStateToProps = (state) => ({
  requirements: state.requirements
});

export default connect(mapStateToProps, mapDispatchToProps)(RenderFields);
