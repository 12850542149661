import { getValues } from '../selectors';
import { createSelector } from 'reselect';
import { omit } from 'lodash';
import { formatCPF, formatCNPJ } from '../../../../../utils/formatter';

const getTenancyValues = state => getValues(state, 'tenancyForm');
const getFormValue = state => getValues(state, 'applicantForm');

export const getPropertyType = createSelector(
  [getTenancyValues],
  values => ({ tipo_imovel: values.tipo_imovel })
);

const getApplicantDynamicValues = (values) => {
  const dynamicValues = {};

  if (values !== undefined) {
    if (values.residir !== undefined) {
      if (values.residir) {
        dynamicValues.residir = '1';
      } else {
        dynamicValues.residir = '0';
      }
    }

    if (values.participante !== undefined) {
      if (values.participante) {
        dynamicValues.participante = '1';
      } else {
        dynamicValues.participante = '0';
      }
    }
  }

  return dynamicValues;
}

export const getApplicantValues = createSelector(
  [getPropertyType, getFormValue],
  (propertyTypeValue, applicantFormValues) => {
    return ({ ...applicantFormValues, ...propertyTypeValue })
  }
);

export const getApplicantInitialValues = (values) => (
  {
    ...omit(values, ['emails', 'renda', 'telefones', 'endereco']),
    ...getApplicantDynamicValues(values),
  }
);

export const getApplicantPJInitialValues = (values) => ({
  ...omit(values, ['emails', 'faturamento', 'telefones', 'endereco']),
  razao_social: values.razao_social,
  nome_fantasia: values.nome
});

const getTenancyType = (values) => (
  {
    tipo_imovel: values.locacao.tipo_imovel
  }
);

export const getInitialValues = (state, editMode, entity) => {
  if (editMode) {
    const { requestResume: { currentApplicant: { pretendente } } } = state;
    const initialValues = pretendente !== undefined ? Array.isArray(pretendente) ? pretendente[0] : pretendente : {};
    
    if (initialValues !== undefined && (("cpf" in initialValues && entity === "PF") || ("cnpj" in initialValues && entity === "PJ")) ) {
      if (entity === "PF") {
        const initValues = { ...getTenancyType(state.requestResume.currentApplicant), ...getApplicantInitialValues(initialValues) };
        return { ...initValues, cpf: formatCPF(initValues.cpf) };
      }
      const initValues = { ...getApplicantPJInitialValues(initialValues) };
      return { ...initValues, cnpj: formatCNPJ(initValues.cnpj) };
    }
  }

  return {};
}
