import styled from 'styled-components';
import success from '../../../img/svg/success.svg';
import error from '../../../img/svg/error.svg';
import { BigScaleButton } from '../sharedStyles.styles';

export const ConfirmAction  = styled.img.attrs({ src: success, alt: 'successSVG' })`
  width: 18px;
  height: 18px;
` ;

export const CancelAction  = styled.img.attrs({ src: error, alt: 'cancelSVG' })`
  width: 18px;
  height: 18px;
` ;

export const EditabletableRowTr = styled.tr`
  td {
    padding: 2px !important;
  }
`;

export const RowActionButton = styled(BigScaleButton)`
  border: none;
  outline: none;
  background-color: transparent;
  width: auto;
  height: auto;
  padding: 0;
`;
