import React, { PureComponent, Fragment } from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import RequestBadge from '../../../../components/Operational/request/RequestBadge';
import {
  ContainerRequest,
  ContainerForm
} from './requestForm.styled';
import ConfirmExitModal from '../../../../components/Operational/request/ConfirmExitModal/ConfirmExitModal';

class RequestFormContainer extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      path: '/intranet/operacional/solicitacao/adicionar',
      locationState: {}
    }
    this.unBlock = null;
  }

  static defaultProps = {
    onNavigateBack: () => { }
  }

  componentDidMount() {
    const { history } = this.props;

    this.unBlock = history.block((location) => {
      this.setState({ showModal: true, path: location.pathname, locationState: location.state });
      return false;
    });
  }

  freeNavigate = (action) => {
    this.setState({ showModal: false }, () => {
      if (!action) {
        const { history } = this.props;
        this.unBlock();

        history.push({
          pathname: this.state.path,
          state: this.state.locationState
        });

        this.props.onNavigateBack();
      }
    })
  }

  render() {
    return (
      <Fragment>
        <ContainerRequest editMode={this.props.editMode}>
          <ContainerForm>
            <StyledRow customstyle='margin-bottom: 5px;display: block;margin-top: -20px;'>
              <BaseColumn customstyle='width: auto;display: inline-block;margin-right: 20px;'>
                <RequestBadge color="color_orange">Laranja: obrigatório</RequestBadge>
              </BaseColumn>
              <BaseColumn customstyle='width: auto;display: inline-block;'>
                <RequestBadge color="color_gray">Cinza: opcional</RequestBadge>
              </BaseColumn>
            </StyledRow>

            {this.props.children}

            {this.props.renderStepButton()}
          </ContainerForm>
        </ContainerRequest>
        {this.state.showModal && <ConfirmExitModal callBack={this.freeNavigate} />}
      </Fragment>
    );
  }
}

export default RequestFormContainer;
