import React, { Component } from 'react';

export default class Question extends Component {
  render() {
    return (
      <svg viewBox="0 0 426.667 426.667">
        <g>
          <g>
            <rect x="192" y="298.667" width="42.667" height="42.667"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M213.333,0C95.513,0,0,95.513,0,213.333s95.513,213.333,213.333,213.333s213.333-95.513,213.333-213.333
              S331.154,0,213.333,0z M213.333,388.053c-96.495,0-174.72-78.225-174.72-174.72s78.225-174.72,174.72-174.72
              c96.446,0.117,174.602,78.273,174.72,174.72C388.053,309.829,309.829,388.053,213.333,388.053z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M296.32,150.4c-10.974-45.833-57.025-74.091-102.858-63.117c-38.533,9.226-65.646,43.762-65.462,83.384h42.667
              c2.003-23.564,22.729-41.043,46.293-39.04s41.043,22.729,39.04,46.293c-4.358,21.204-23.38,36.169-45.013,35.413
              c-10.486,0-18.987,8.501-18.987,18.987v0v45.013h42.667v-24.32C279.787,241.378,307.232,195.701,296.32,150.4z"/>
          </g>
        </g>
      </svg>
    );
  }
}
