
export const GET_DATA_REQUEST = "tenancyForm/GET_DATA_REQUEST";
export const GET_DATA_SUCCESS = "tenancyForm/GET_DATA_SUCCESS";
export const GET_DATA_FAILED = "tenancyForm/GET_DATA_FAILED";

export const TENANCY_FORM_REQUESTING = 'tenancyForm/TENANCY_FORM_REQUESTING';
export const TENANCY_FORM_SUCCESS = 'tenancyForm/TENANCY_FORM_SUCCESS';
export const TENANCY_FORM_FAILED = 'tenancyForm/TENANCY_FORM_FAILED';

export const TENANCY_FORM_RESET = 'tenancyForm/TENANCY_FORM_RESET';
