import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getRequirements, createRequest, resetForm, sendFileUploadFcCompleta, removeFileUploadFcCompleta } from './actions';
import { openModal } from './incomeModal/actions';

import Header from '../../../../components/Header';
import { PageContainer } from '../../../../components/shared/sharedStyles.styles.js';
import BlueSteps from '../../../../components/Operational/request/BlueSteps';
import BeginningModal from './BeginningModal';

import TenancyForm from './TenancySection/TenancyForm';
import tenancyFields from './TenancySection/fields';

import ApplicantForm from './Applicant/ApplicantForm';
import applicantFields from './Applicant/fields';

import IncomeForm from './IncomeSection/IncomeForm';
import incomeFields from './IncomeSection/fields';

import { getRequestObj } from '../../../../utils/operational/requests/requestFormHelper';
import { getRequestStatus, getSubmitStatus, getValues } from './selectors';

import RequestStepButton from './RequestStepButton';
import RequestFormContainer from './RequestFormContainer';
import { Main, MainContainer } from './../resume/RequestResume';

import Tour from 'reactour'
import axios from 'axios';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../../components/shared/TourTooltip';

const Forms = ["tenancyForm", "applicantForm", 'incomeForm'];
const breadcrumb = [
  { link: '', label: 'operacional' },
  { link: '/intranet/operacional/solicitacao', label: 'Locações' }
];

const steps_pf = [
  {
    selector: '.Endereçox',
    content: 'Preencha as informações chave sobre a locação possibilitando o cálculo do risco.',
  },
  {
    selector: '.imóvel',
    content: 'Endereço e código do imóvel são campos opcionais, e não interferem no cálculo do risco.',
  },
  {
    selector: '.Aluguel',
    content: 'Aluguel + encargos são informações essenciais para a geração do Risco da Locação',
  },
  {
    selector: '.Tipo',
    content: 'O tipo de imóvel também é muito importante para calcularmos o risco da locação.',
  },
  {
    selector: '.Pretendente',
    content: 'Informe qual a condição do pretendente, se é o inquilino ou o fiador da locação',
  },
  {
    selector: '.Nome',
    content: 'Não se preocupe em inserir o nome completo, o sistema vai subscrever essa informação, apenas o 1° nome para facilitar sua identificação é o suficiente.',
  },
  {
    selector: '.CPF',
    content: 'CPF é a chave de busca para nossa análise',
  },
  {
    selector: '.PERFIL',
    content: <span>Você pode preencher com as informações iniciais que possui do seu pretendente, ou mesmo escolher a opção <b>renda não informada</b>. E fique tranquilo, este campo é editável.</span>,
  },
  {
    selector: '[data-testid="StepButton"]',
    content: 'Após preencher os dados, clique em Salvar',
  },
  // ...
]

const steps_pj = [
  {
    selector: '.Endereço',
    content: 'Endereço do imóvel não é campo obrigatório',
  },
  {
    selector: '.Aluguel',
    content: 'Embora não façamos o cálculo do risco FC para a consulta Pessoa Jurídica, os campos de aluguel + encargos se mantém obrigatórios',
  },
  {
    selector: '.Tipo',
    content: 'O mesmo vale para o tipo de imóvel',
  },
  {
    selector: '.Pretendente',
    content: 'Este campo vem preenchido como Outros automaticamente, pois o algoritmo FC é exclusivo para análise Pessoa Física, e, portanto, não é possível mudar a condição para inquilino ou fiador no sistema.',
  },
  {
    selector: '.Nome',
    content: 'Não se preocupe em preencher o nome completo da empresa, buscaremos essa informação nos bancos de dados externos.',
  },
  {
    selector: '.CNPJ',
    content: 'CNPJ é a chave de busca em nossa consulta',
  },
  {
    selector: '[data-testid="StepButton"]',
    content: 'Salve a sua solicitação para dar andamento às buscas ',
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class RequestForm extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      tourOpen: false,
      tourId: null
    }
    this.tenancyForm = React.createRef();
    this.applicantForm = React.createRef();
    this.locationForm = React.createRef();
    this.incomeForm = React.createRef();
    this.container = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { requesting: prevRequesting } = prevProps;
    const { currentStep } = this.state;
    const { requesting, submitStatus, history, requestId } = this.props;

    if (prevRequesting && !requesting && submitStatus !== undefined && !submitStatus && currentStep === 1) {
      this.container.current.unBlock();

      const { customer, products } = this.props.location.state;
      history.replace({
        pathname: '/intranet/operacional/solicitacao/resumo',
        state: { request: requestId, customer, products, addPretenderProduct: true }
      })
    }
  }

  componentDidMount() {
    const { location: { state }, history } = this.props;
    if (state !== undefined) {
      if (state.entity === undefined) {
        history.push('/intranet/operacional/solicitacao/adicionar');
      }
    } else {
      history.push('/intranet/operacional/solicitacao/adicionar');
    }
    const entity = (state && state.entity === "FISICA")? "PF" : "PJ";
    this.props.getRequirements(entity);
    this.getCheckOnboard()
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'form-pf-criar-solicitacao') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'form-pf-criar-solicitacao'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'form-pf-criar-solicitacao',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  get formValues() {
    const changed = Forms.reduce((acc, form) => {
      const changedValues = this.props[form];
      acc[form] = changedValues;

      return acc;
    }, {});
    return changed;
  }

  checkTypeIncome = () => {
    const {incomeForm} = this.props
    // const changedValues = this.incomeForm.current.props.changedValues;
    if (incomeForm !== undefined) {
      if ("renda_principal" in incomeForm || "outras_rendas" in incomeForm || "origem_renda" in incomeForm || "origem_renda_secundaria" in incomeForm || "avaliacao_financeira" in incomeForm) {
        const { renda_principal, outras_rendas, origem_renda, origem_renda_secundaria, avaliacao_financeira, limite_cartao_credito, movimentacao_bancaria_extratos} = incomeForm;
        if(avaliacao_financeira == 'renda') {
          if (renda_principal > 0 && (origem_renda !== undefined && origem_renda.toString() === "")) {
            return false;
          }else if((origem_renda && origem_renda.toString() !== "1") && !renda_principal){
            return false
          }

          if(origem_renda == 1 && renda_principal > 0) {
            return false
          }

          if (outras_rendas > 0 && (origem_renda_secundaria !== undefined && origem_renda_secundaria.toString() === "")) {
            return false;
          }else if((origem_renda_secundaria && origem_renda_secundaria.toString() !== "1") && !outras_rendas){
            return false
          }
        }

        if (avaliacao_financeira == 'card' && ((limite_cartao_credito !== undefined && limite_cartao_credito.toString() === "") || limite_cartao_credito === undefined)) {
          return false;
        }else if(avaliacao_financeira == 'card' && ((limite_cartao_credito == 0) || isNaN(limite_cartao_credito))){
          return false
        }

        if (avaliacao_financeira == 'extratos' && ((movimentacao_bancaria_extratos !== undefined && movimentacao_bancaria_extratos.toString() === "") || movimentacao_bancaria_extratos === undefined)) {
          return false;
        }else if(avaliacao_financeira == 'extratos' && ((movimentacao_bancaria_extratos == 0) || isNaN(movimentacao_bancaria_extratos))){
          return false
        }

        if(this.formValues.incomeForm.avaliacao_financeira == 'nao-informado'){
          this.formValues.incomeForm.avaliacao_financeira = 'nao-informado';
          this.formValues.incomeForm.origem_renda = 1;
          this.formValues.incomeForm.origem_renda_secundaria = "";
          this.formValues.incomeForm.renda_principal = 0;
          this.formValues.incomeForm.total_renda = 0;
        }
      }
    }
    return true;
  }

  sendRequestRegistration = () => {
    if (this.checkTypeIncome()) {
      const { location: { state: { customer: { id }, products, entity } }, createRequest, modulo_principal, modulo_secundario } = this.props;
      const entityRequest = (entity === "FISICA")? "PF" : "PJ";
      let arquivos = undefined
      let tipo_renda = undefined

      const requestObj = getRequestObj(id, products, this.formValues, entityRequest);
      console.log('requestobj1', requestObj)
      console.log('formvalue1', this.formValues)
      console.log('pp1', this.props)
      if(products[0] === 7 || products[0] === 8) {
        if(!modulo_principal.length) {
          alert('Necessário anexar documento')
          return;
        }

        if(products[0] === 7) {
          tipo_renda = 'principal'
        }
        if(modulo_principal.length) {
          arquivos = modulo_principal.map(file => {
            return {
              id: file.id,
              tipo_renda: tipo_renda
            }
          })
        }
      }
      console.log('pp2', {...requestObj, documentos: arquivos})
      // return;
      createRequest({...requestObj, documentos: arquivos});
    } else {
      this.props.openModal();
    }
  }

  getSteps = () => {
    const { history } = this.props;
    return [
      // { title: 'pesquisar cliente', status: 'color_green', onClick: () => history.push('/intranet/operacional/solicitacao/adicionar') },
      { title: 'escolher produto', status: 'color_green', onClick: () => history.goBack() },
      { title: 'formulário', status: 'color_purple', onClick: () => { } },
      { title: 'resumo da solicitação', status: 'color_border-light-gray', onClick: () => { } },
      { title: 'laudo', status: 'color_border-light-gray', onClick: () => { } }
    ]
  }

  onChangeStep = () => {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  goBack = () => {
    const { history } = this.props
    history.push({pathname: "/intranet/operacional/solicitacao/adicionar/selecionar-produto"})
  }

  renderStepButton = () => (
    <div style={{position: 'relative', width: 'max-content', margin: '0 auto'}}>
      <RequestStepButton
        forms={Forms}
        step={this.state.currentStep}
        onChangeStep={this.onChangeStep}
        sendRequestRegistration={this.sendRequestRegistration}
        sending={this.props.requesting}
        entity={this.props.location.state.entity}
        products={this.props.history.location.state.products}
        showBackButton={true}
        goBack={this.goBack}
        usedFields={
          {
            ...tenancyFields,
            ...applicantFields,
            ...incomeFields
          }
        }
      />
      {/* <TourTooltip
        target="tooltip-button-salvar"
        style={{right: -15, top: 0}}
      />
      <ReactTooltip id="tooltip-button-salvar" place="top" type="dark" effect="float">
        Após preencher os dados, clique em Salvar
      </ReactTooltip> */}
    </div>
  )

  render() {
    const { history: { location } } = this.props;

    if (location.state === undefined) {
      return null;
    }

    const { customer } = location.state;
    const entity = (location.state.entity === 'FISICA') ? 'PF' : 'PJ';
    const products = location.state.products;
    console.log('productx1', this.props)
    const steps = entity === 'PF' ? steps_pf : steps_pj;
    const customerName = customer.nome || undefined;
    return (
      <Main>
        <Header
          title="Formulário"
          breadcrumb={breadcrumb}
          actualLink="Formulário"
          subtitle={customerName}
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <BlueSteps steps={this.getSteps()} />
        <MainContainer>
          <RequestFormContainer
            ref={this.container}
            history={this.props.history}
            renderStepButton={this.renderStepButton}
          >
            <TenancyForm ref={this.tenancyForm} entity={entity} products={products} />
            <ApplicantForm ref={this.applicantForm} entity={entity} products={products} />
            {(entity === 'PF' && products[0] !== 5 && products[0] !== 7) && <IncomeForm ref={this.incomeForm} entity={entity} products={products} />}
            {(entity === 'PF' && products[0] === 7) && <IncomeForm ref={this.incomeForm} entity={entity} products={products} clientId={this.props.login.user.usuario.cliente_id} moduloAnexoCreateLocationFcCompleta={true} sendFileUpload={this.props.sendFileUploadFcCompleta} removeFileUpload={this.props.removeFileUploadFcCompleta} />}
            {(entity === 'PJ' && products[0] === 8) && <IncomeForm ref={this.incomeForm} entity={entity} products={products} clientId={this.props.login.user.usuario.cliente_id} moduloAnexoCreateLocationFcCompleta={true} sendFileUpload={this.props.sendFileUploadFcCompleta} removeFileUpload={this.props.removeFileUploadFcCompleta} />}
          </RequestFormContainer>
          {products[0] !== 5 &&
            <BeginningModal entity={entity} />
          }
        </MainContainer>
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
        />
      </Main>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createRequest,
  getRequirements,
  resetForm,
  sendFileUploadFcCompleta,
  removeFileUploadFcCompleta,
  openModal
}, dispatch);

const mapStateToProps = (state, ownProps) => {

  return {
    ...ownProps,
    requesting: getRequestStatus(state),
    submitStatus: getSubmitStatus(state),
    requestId: state.requestForm.requestId,
    incomeForm: getValues(state, "incomeForm"),
    tenancyForm: getValues(state, "tenancyForm"),
    applicantForm: getValues(state, "applicantForm"),
    modulo_principal: state.requestForm.modulo_principal,
    modulo_secundario: state.requestForm.modulo_secundario,
    login: state.login,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestForm);
