import { instance as axios } from '../axiosInstance';

const GET_CHARGE_URL = "/api/relatorio/extrato";
const GET_CHARGE_PRINT_URL = '/api/relatorio/extrato/print';
const GET_CHARGE_PRINT_EXCEL_URL = '/api/relatorio/extrato/print/excel';

const getHeader = (token) => (
  {
    'Authorization': `Bearer ${token}`
  }
);

const formatDate = (date) => {
  var data = new Date(date),
  day  = data.getDate().toString(),
  dayF = (day.length == 1) ? '0'+day : day,
  month  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
  monthF = (month.length == 1) ? '0'+month : month,
  yearF = data.getFullYear();
  return yearF+"/"+monthF+"/"+dayF;

}

export const getCharge = (token, data) => {
  const headers = getHeader(token)
  
  let params = {
    perPage: data.perPage,
    page: data.page,
  }
  
  if(data.start_date){
    params.start = formatDate(data.start_date);
  }
  if(data.end_date){
    params.end = formatDate(data.end_date);
  }
  return axios.get(GET_CHARGE_URL, {headers, params})
}

export const getChargePrint = (token, data) => {
  const headers = getHeader(token)

  let params = {}

  if(data.start_date){
    params.start = formatDate(data.start_date);
  }
  if(data.end_date){
    params.end = formatDate(data.end_date);
  }
  return axios.get(GET_CHARGE_PRINT_URL, {headers, params})
}

export const getChargePrintExcel = (token, data) => {
  const headers = getHeader(token)

  let params = {}

  if(data.start_date){
    params.start = formatDate(data.start_date);
  }
  if(data.end_date){
    params.end = formatDate(data.end_date);
  }
  return axios.get(GET_CHARGE_PRINT_EXCEL_URL, {headers, params, responseType: 'blob'})
}