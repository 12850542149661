import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import "./index.sass";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor, history } from './configStore';
import { ThemeProvider } from 'styled-components';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';
import ReduxToastr from 'react-redux-toastr';

//Theme
import colors from './utils/styles/colors';
import mediaTemplates from '@bit/wavfichacerta.fichacerta.styles-utils.media-templates';

//Routes
import Routes from './routes';

//AWS signin
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import WhatsappButton from './components/others/WhatsappButton';

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: awsConfig.aws_project_region,
  aws_pubsub_endpoint: process.env.REACT_APP_AWS_PUBSUB_ENDPOINT,
}));

Amplify.configure(awsConfig);

const theme = {
  ...colors,
  ...mediaTemplates,
  disabled: false,
  dynamicPhoneButtonColor: "color_white"
}

ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </ThemeProvider>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          progressBar
          closeOnToastrClick
        />
        <WhatsappButton

        />
      </PersistGate>
    </Provider>
    
    
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
