import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import Home from '../pages/home/Home';
import RefreshLogin from '../pages/auth/refresh/RefreshLogin';

export default () => (
  <Fragment>
    <Route exact path="/auth" component={Home} />
    <Route exact path="/refresh/:token" component={RefreshLogin} />
  </Fragment>
);