import { combineReducers } from 'redux';
import employees from './employees';
import products from './products';
import clients from './clients';

export default combineReducers({
  employees,
  clients,
  products
});
