import React, { Component }  from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { CreditContainer, UsedCreditBar, TitleCreditBar, SubtitleBox, CreditWarnText } from './creditBar.styled';
import { formatToBRL } from '@bit/wavfichacerta.fichacerta.utils.formatters';

export default class UsersCreditBar extends Component {

  static defaultProps = {
    customer: ''
  }

  getAvailableCredit = () => {
    const { users } = this.props;
    return users.funcionario.credito;
  }

  getUsedCredit = (availableCredit, isPercentage = true) => {
    const { users } = this.props;

      const usedCredit = users.funcionario.credito_utilizado;
      const percentageUsage = (usedCredit/availableCredit)*100;
      if (isPercentage) {
        return `${percentageUsage}%`;
      }
      return usedCredit;
  }

  render() {
    const availableCredit = this.getAvailableCredit();
    const usage = this.getUsedCredit(availableCredit);
    const usageValue = this.getUsedCredit(availableCredit, false);

    return (
      <StyledRow justify="flex-start" align="flex-start" style={{padding: '0 5px'}}>
        <CreditContainer hasColor={availableCredit > 0} activeColor="color_purple">
          {availableCredit > 0 && <UsedCreditBar width={usage} />}
        </CreditContainer>
        <StyledRow justify="space-around" padding="0 .5rem" style={{marginTop: 5}}>
          <div>
            <SubtitleBox colorBox="color_used">
              Usado: <SubtitleBox>&nbsp;R$ {formatToBRL(usageValue)}</SubtitleBox>
            </SubtitleBox>
          </div>

          <div>
            <SubtitleBox colorBox="color_purple">
              Disponível: <SubtitleBox>&nbsp;R$ {formatToBRL(availableCredit - usageValue)}</SubtitleBox>
            </SubtitleBox>
          </div>
        </StyledRow>
      </StyledRow>
    );
  }
}
