import React from 'react'
// import { SubmitButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
// import { InputColContainer } from '@bit/wavfichacerta.fichacerta.inputs-styles.inputs-styles';
// import Loading from '@bit/wavfichacerta.fichacerta.icons.loading';
import ModalExcludedFile from './ModalExcludedFile'
import { Select } from './../../../../../components/shared/selectInput.styles';
import { InputColContainer } from './../../../../../components/shared/Inputs.styles';
import icons from './../../../../../components/shared/treatedIcons';
import { SubmitButton } from './../../../../../components/sign-up/signup.styles';
import styled from 'styled-components'

const { Loading } = icons;
// const ModalExcludedFile = styled.div``
// const InputColContainer = styled.div``
// const Select = styled.select``

const WrapperTextRecommendation = styled.div`
	& p {
		&:before {
			content: 'ou';
			margin-right: 5px;
			font-weight: 600;
		}
		&:after {
			content: ',';
		}
	}
`

class Card extends React.Component {
	state = {
		files: [],
		documentSelected: null,
		filesChanged: []
	}

	inputRef = React.createRef()

	componentDidUpdate(prevProps, prevState) {
		// console.log("prevProps", prevProps)
		// console.log("props3", this.props)
		// console.log("prevState", prevState)
		// console.log("state3", this.state)
		const {files, documentSelected} = this.state
		if(documentSelected != prevState.documentSelected) {
			this.props.onChanged({
				// files: files,
				documentSelected: documentSelected
			})
		}
		
		// if(this.props.defaultFiles && this.props.defaultFiles.length != this.state.files.length) {
		// 	console.log('entrei aqui')
		// 	this.setState({files: this.props.defaultFiles})
		// }
	}

	handleChangeFiles = (e) => {
		const files = this.state.files
		// console.log('changed1', this.props.defaultFiles)
		let filesAfter = []
		Array.from(e.target.files).forEach(targetFile => {
			const countRepeatFile = files.filter(file => {
				return file.name === targetFile.name
			}).length

			if(countRepeatFile < 1) {
				filesAfter.push(targetFile)
			}
			
		});
		if(filesAfter.length > 0) {
			/// aqui retorna um callback para fazer a chamada quando novos arquivos são adicionados em filesAfter
			this.setState({filesChanged: filesAfter})
			this.props.onSelected(filesAfter)
		}

		filesAfter = [...files, ...filesAfter]

		// this.setState({files: filesAfter})
		this.inputRef.current.value = ''
	}

	triggerSelectFiles = () => {
		this.inputRef.current.click()
	}

	renderListFiles = () => {
		let {files} = this.state
		if(this.props.defaultFiles.length < 1) return null

		const listFiles = this.props.defaultFiles.map(file => (
			<React.Fragment key={file.id}>
				<div onClick={() => this.removeSinleFile(file)} style={{position: 'absolute', marginTop: -7, cursor: 'pointer'}} title='Remover arquivo'>x</div>
				<p key={file.name} style={{fontSize: 11, marginLeft: 20}}>{file.name.substring(0,30)+"..."}</p>
			</React.Fragment>
		))

		return <div className='list-files'>{listFiles}</div>
	}

	clearListFiles = () => {
		this.setState({files: []})
		this.inputRef.current.value = ''
	}

	removeSinleFile = (file) => {
		const files = this.props.defaultFiles.filter(defaultFile => {
			return defaultFile.name != file.name
		})

		const fileDeleted = this.props.defaultFiles.filter(defaultFile => {
			return defaultFile.name == file.name
		})[0]

		this.setState({files})
		this.inputRef.current.value = ''
		this.props.onExcluded(fileDeleted)
	}

	handleDocumentType = (e) => {
		this.setState({documentSelected: e.target.value})
	}

	renderOptions = () => {
		const options = this.props.documents.map(document => {
			return <option key={document.label.id} value={document.label.id}>{document.label.titulo}</option>
		})
		return options
	}

	renderLoading = () => {
		if (this.props.isLoading) {
			return (
				<div
					style={{
						position: 'absolute',
						backgroundColor: '#000',
						right: 0,
						left: 0,
						top: 0,
						bottom: 0,
						opacity: .9,
						display: 'flex',
						alignItems: 'center'
					}}
				>
					<Loading width="55px" height="55px" color="color_border-gray" margin="0 auto" />
				</div>
			)
		}
		return null
	}

	render() {

		console.log("current SATE0", this.state.files)
		return(
			<div className='box-file' style={{border: `1px solid ${this.props.isValid ? '#37c120' : '#ff7200'}`, display: 'flex', alignItems: 'center', position: 'relative', padding: '30px 10px 10px', color: '#7f7f7f'}}>
				<p style={{position: 'absolute', top: 0, left: 5, fontSize: 12}}>
					{this.props.label}
					<div style={{visibility: 'hidden'}}>*{this.props.phraseRecommendation}</div>
				</p>
				
				<div style={{width: '50%', textAlign: 'center'}}>
					<p style={{fontSize: 14, fontWeight: 500}}>Qual documento deseja anexar?</p>
					<InputColContainer underlinecolor="input_underline_default_color" style={{height: 26}}>
						<Select onChange={this.handleDocumentType} style={{width: '100%', height: 26, fontSize: 13}}>
							<option value=''>Selecione</option>
							{this.renderOptions()}
						</Select>
					</InputColContainer>
					<p style={{fontSize: 11, color: '#152b4e', marginTop: 5, marginBottom: 10}}>PDF, JPG, JPEG ou PNG</p>
					<SubmitButton
						disabled={!this.state.documentSelected}
						onClick={this.triggerSelectFiles}
						style={{width: 120, height: 34, padding: 0, fontSize: 11, color: '#fff', display: 'block', margin: '0 auto'}}
					>
							Escolher arquivos
					</SubmitButton>
				</div>
				<div style={{width: '50%', paddingLeft: 25}}>
					<input id='file' type='file' multiple ref={this.inputRef} onChange={this.handleChangeFiles} style={{display: 'none'}} />
					{this.renderListFiles()}
				</div>
				{this.renderLoading()}
			</div>
		)
	}
}

Card.defaultProps = {
	defaultFiles: [],
	callback: () => null,
	onChanged: () => null, // callback quando qualquer alteração é feita no card
	onExcluded: () => null, // callback quando algum anexo é excluido
	onSelected: () => null // callback quando novos arquivos são adicionados
}

class ModuleFilesAnexo extends React.Component {
	state = {
		modulo_principal: {files: [], documentSelected: null},
		modulo_secundario: {files: [], documentSelected: null},
		modalExcludedOpen: false,
		excludedData: {}
	}
	
	// componentDidMount() {
	// 	setTimeout(() => {
	// 		this.setState({
	// 			modulo_principal: {
	// 				...this.state.modulo_principal,
	// 				files: [
	// 					{id: 2, name: 'bola2.png'},
	// 					{id: 3, name: 'bola3.png'}
	// 				]
	// 			}
	// 		})
	// 		console.log('foi')
	// 	}, 5000)
	// 	console.log('montopu')
	// }

	// handleDocuments = (origem_renda_id) => {
	// 	const renda = this.props.origem_renda_documentos.filter(documento => {
	// 		return documento.label.id == origem_renda_id
	// 	})
	// 	return origem_renda_id && renda[0].documentos || []
	// }

	handleDocumentPhraseRecommendation = (origem_renda_id) => {
		const phraseRecommendation = this.props.origem_renda_documento_recomendacao.filter(documento => {
			return documento.value == origem_renda_id
		})
		return phraseRecommendation[0].documentos
	}

	handleOrigemLabel = (origem_renda_id) => {
		const renda = this.props.origem_renda_documento_recomendacao.filter(renda => {
			return renda.value == origem_renda_id
		})
		return origem_renda_id && renda[0].label
	}

	handleSelectedFiles = (files, modulo = 'principal') => {
		let formData = new FormData();
		let documentSelected = null
		const id_solicitacao = this.props.location.state.requestId
		const id_pretendente = this.props.location.state.applicantId

		for (var i = 0; i < files.length; i++) {
			formData.append('arquivo[]', files[i]);
		}

		if(modulo == 'principal') {
			documentSelected = this.state.modulo_principal.documentSelected
			this.setState({
				modulo_principal: {
					...this.state.modulo_principal,
					// files
				}
			})
		} else {
			documentSelected = this.state.modulo_secundario.documentSelected
			this.setState({
				modulo_secundario: {
					...this.state.modulo_secundario,
					// files
				}
			})
		}

		formData.append('renda', modulo == 'principal' ? 'principal' : 'renda');
		formData.append('documento', documentSelected);
		this.props.sendFileUpload(formData, modulo, id_solicitacao, id_pretendente)
	}
	
	handleExcludedFile = (file, modulo = 'principal') => {
		console.log('FILE1', file)
		const id_solicitacao = this.props.location.state.requestId
		const id_pretendente = this.props.location.state.applicantId
		this.setState({excludedData: {file, modulo, id_solicitacao, id_pretendente}})
		this.handleModalExcluded()
	}

	handleModalExcluded = () => {
		this.setState({modalExcludedOpen: !this.state.modalExcludedOpen})
	}

	confirmModalExcluded = () => {
		const {file, modulo, id_solicitacao, id_pretendente} = this.state.excludedData
		this.props.removeFileUpload(file, modulo, id_solicitacao, id_pretendente)
		this.handleModalExcluded()
	}

	handleChangeDocumentSelected = (documentType, modulo = 'principal') => {
		if(modulo == 'principal') {
			this.setState({
				modulo_principal: {
					...this.state.modulo_principal,
					documentSelected: documentType.documentSelected
				}
			})
		} else {
			this.setState({
				modulo_secundario: {
					...this.state.modulo_secundario,
					documentSelected: documentType.documentSelected
				}
			})
		}
	}

	render() {
		const origem_renda_id = this.props.changedValues.origem_renda
		const origem_renda_secundaria_id = this.props.changedValues.origem_renda_secundaria
		let text_recomendacao = 'frase 1, frase 2, frase 3'.split(',')
		const texto = text_recomendacao.map(texto => {
			return <p style={{margin: 0}}>{texto.trim()}</p>
		})

		console.log('props2', this.props)
		return(
				<div className='moduleFiles'>
					<div className='title' style={{display: 'flex', alignItems: 'center'}}>
						<hr style={{width: '100%'}} />
						<p style={{margin: '0 20px', color: '#152b4e', fontSize: 12, fontWeight: 500}}>ANEXAR</p>
						<hr style={{width: '100%'}} />
					</div>

					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						{origem_renda_id &&
							<div style={{width: '49%'}}>
								<Card
                                    label={this.handleOrigemLabel(origem_renda_id)}
									documents={this.props.origem_renda_documentos}
									onExcluded={(file)=> this.handleExcludedFile(file)}
									onChanged={(document) => this.handleChangeDocumentSelected(document)}
									onSelected={(files) => this.handleSelectedFiles(files)}
                                    phraseRecommendation={this.handleDocumentPhraseRecommendation(origem_renda_id)}
                                    // phraseRecommendation='eu, tu'
                                    defaultFiles={[...this.state.modulo_principal.files, ...this.props.modulo_principal]}
									// defaultDocument={''}
									isLoading={this.props.loadingFileUploadPrincipal}
									isValid={this.props.principalIsValid}
								/>
								<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 11, color: '#7f7f7f', marginTop: 10}}>
									<p style={{width: 100, textAlign: 'center', margin: 0}}>
										Documentos Recomendados	
									</p>
									<div>
										<div style={{width: 100, height: 1, backgroundColor: '#000'}}></div>
										<div></div>
									</div>
									<WrapperTextRecommendation style={{position: 'relative', paddingLeft: 5}}>
										{texto}
										<div style={{width: 1, position: 'absolute', top: 4, bottom: 3, left: 0, backgroundColor: '#000'}}></div>
									</WrapperTextRecommendation>
								</div>
							</div>
						}
						{origem_renda_secundaria_id &&
							<div style={{width: '49%'}}>
								<Card
                                    label={this.handleOrigemLabel(origem_renda_secundaria_id)}
									documents={this.props.origem_renda_documentos}
									onExcluded={(file)=> this.handleExcludedFile(file, 'secundario')}
									onChanged={(document) => this.handleChangeDocumentSelected(document, 'secundario')}
									onSelected={(files) => this.handleSelectedFiles(files, 'secundario')}
									phraseRecommendation={this.handleDocumentPhraseRecommendation(origem_renda_secundaria_id)}
                                    // phraseRecommendation='ola, ola2'
                                    defaultFiles={[...this.state.modulo_secundario.files, ...this.props.modulo_secundario]}
                                    // defaultDocument={''}
									isLoading={this.props.loadingFileUploadSecundario}
									isValid={this.props.secundarioIsValid}
								/>
								<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 11, color: '#7f7f7f', marginTop: 10}}>
									<p style={{width: 100, textAlign: 'center', margin: 0}}>
										Documentos Recomendados	
									</p>
									<div>
										<div style={{width: 100, height: 1, backgroundColor: '#000'}}></div>
										<div></div>
									</div>
									<WrapperTextRecommendation style={{position: 'relative', paddingLeft: 5}}>
										{texto}
										<div style={{width: 1, position: 'absolute', top: 4, bottom: 3, left: 0, backgroundColor: '#000'}}></div>
									</WrapperTextRecommendation>
								</div>
							</div>
						}
					</div>
					<ModalExcludedFile
						isOpen={this.state.modalExcludedOpen}
						onClose={this.handleModalExcluded}
						undo={this.confirmModalExcluded}
					/>		
				</div>
		)
	}
}

export default ModuleFilesAnexo