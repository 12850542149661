import React from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  ContainerGray,
  ConceptTitle,
  ConceptLine
} from '../analythic.styled';

export default (props) => (
  <ContainerGray style={props.style} className='font-13-print'>
    <ConceptTitle style={{paddingLeft: 0}}>Escala de Riscos e exemplos de Pareceres aplicáveis:</ConceptTitle>
    <StyledRow margin="0 .5rem 30px 0">
      <BaseColumn xs={12} md={12} lg={12} xl={12}>
        <ConceptLine><b style={{marginRight: 6}}>Risco Muito Baixo&nbsp; = </b>Aplicar políticas mais flexíveis de aprovação.</ConceptLine>
        <ConceptLine><b style={{marginRight: 6}}>Risco Baixo&nbsp; = </b>Aplicar políticas flexíveis de aprovação.</ConceptLine>
        <ConceptLine><b style={{marginRight: 6}}>Risco Médio&nbsp; = </b>Aplicar políticas padrões de aprovação.</ConceptLine>
      {/* </BaseColumn>
      <BaseColumn xs={12} md={6} lg={6} xl={6}> */}
        <ConceptLine><b style={{marginRight: 6}}>Risco Acima da Média&nbsp; = </b>Aplicar políticas de extrema cautela para aprovação.</ConceptLine>
        <ConceptLine><b style={{marginRight: 6}}>Risco Muito Alto&nbsp; = </b>Não recomendável a aprovação.</ConceptLine>
      </BaseColumn>
    </StyledRow>
    <ConceptLine>*Os pareceres são uma combinação entre o perfil de risco e as rendas dos analisados.</ConceptLine>
  </ContainerGray>
)
