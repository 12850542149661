import React, { PureComponent, Fragment } from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  BorderedBox,
  TitleBox,
  AnalythicOList,
  AnalythicLineItalic
} from '../analythic.styled';
import {
  getAmountData,
  getAmountRecommendations,
  getDocRecommendation
} from './products/fcAnalise';
import { income } from '../../../../pages/operational/request/report/singleReport/actions';
import { formatToBRL, formatDateToBR, formatCNPJ } from '../../../../utils/formatter';
import styled from 'styled-components'

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../shared/TourTooltip';

const WrapperCardResult = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  @media (max-width: 800px) {
    display: block;
  }
`

class AmountContainerPj extends PureComponent {
  constructor(props){
    super(props)

    this.income = React.createRef()
  }

  componentDidMount(){
    this.props.income(this.income.current)
  }

  formatTimes = (times) => {
    if (times === undefined || times === null) {
      return 0;
    }
    const timesString = times.toFixed(1);
    return timesString.replace(/\.(?!\.)/g, ",");
  }

  renderAmountInfo = () => {
    const { data } = this.props;
    const vezes = data.laudo.compatibilidade_renda.result.vezes
    const iraResidir = data.pretendente.residir
    const fcrenda = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    const origem = fcrenda.origem_renda_principal
    const amountInfo = getAmountRecommendations(data);
    const incompativel = data.laudo.parecer_sistemico[0].incompativel
    const removedRecomendation = 'É recomendável solicitar comprovação da renda informada.'
    let confirmedRecomendation = ''
    let confirmed = false
    if('resultado' in fcrenda && fcrenda.ativo) {
      const {resultado} = fcrenda
      switch(resultado.titulo) {
        case 'PERFIL FINANCEIRO CONFIRMADO':
          confirmed = true
          confirmedRecomendation = `A RENDA PESSOAL confirmada (${this.formatTimes(vezes)} VEZES O VALOR DA LOCAÇÃO) ${incompativel && 'não ' || ''}é compatível com esta locação.`
      }
    }

    if('resultado' in fcrenda && fcrenda.ativo === true) {
      const {resultado} = fcrenda
      switch(resultado.titulo) {
        case 'PERFIL FINANCEIRO CONFIRMADO':
          if((fcrenda.bandeira || fcrenda.numero_cartao) && origem == 13) {
            return (
              <Fragment>
                <TitleBox>{`Considerando uma locação de imóvel residencial para ${iraResidir ? 'uso próprio' : 'terceiros'}:`}</TitleBox>
                <AnalythicOList>
                  <AnalythicLineItalic>{`A RENDA ESTIMADA pelo LIMITE DO CARTÃO DE CRÉDITO (${this.formatTimes(vezes)} VEZES O VALOR DA LOCAÇÃO) ${incompativel && 'não ' || ''}é compatível com esta locação.`}</AnalythicLineItalic>
                </AnalythicOList>
              </Fragment>
            );
          }else if(origem == 15){
            return (
              <Fragment>
                <TitleBox>{`Considerando uma locação de imóvel residencial para ${iraResidir ? 'uso próprio' : 'terceiros'}:`}</TitleBox>
                <AnalythicOList>
                  <AnalythicLineItalic>{`A RENDA ESTIMADA por EXTRATOS BANCÁRIOS (${this.formatTimes(vezes)} VEZES O VALOR DA LOCAÇÃO) ${incompativel && 'não ' || ''}é compatível com esta locação.`}</AnalythicLineItalic>
                </AnalythicOList>
              </Fragment>
            );
          }
      }
    }

    if('resultado' in fcrenda && fcrenda.ativo === true) {
      if((fcrenda.bandeira || fcrenda.numero_cartao) && origem == 13) {
        return (
          <Fragment>
            <TitleBox>Documentação recomendada para comprovação da RENDA ESTIMADA informada:</TitleBox>
            <AnalythicOList>
              <AnalythicLineItalic>- Último extrato/fatura do cartão de crédito. Em caso de limite flexível, últimos 3 extratos/fatura.</AnalythicLineItalic>
            </AnalythicOList>
          </Fragment>
        );
      }
    }

    return (
      <Fragment>
        <TitleBox>{amountInfo.title}:</TitleBox>
        <AnalythicOList>
          {amountInfo.recommendations.map((rec) => (
            confirmed && rec == removedRecomendation ? null :
            'Verificar a possibilidade de composição de renda com outra(s) pessoa(s).' == rec && incompativel ?
            <AnalythicLineItalic>{rec}</AnalythicLineItalic> :
            'Verificar a possibilidade de composição de renda com outra(s) pessoa(s).' == rec ? null :
            <AnalythicLineItalic>{rec}</AnalythicLineItalic>
          ))}
        </AnalythicOList>
      </Fragment>
    );
  }
  
  renderResultFcRenda = (result) => {
    const { data } = this.props
    const fcrenda = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    let notConfirmed = false
    if('resultado' in fcrenda) {
      const {resultado} = fcrenda
      switch(resultado.titulo) {
        case 'PERFIL FINANCEIRO NÃO CONFIRMADO':
          notConfirmed = true
      }
    }
    return(
      <div style={{border: notConfirmed ? '2px solid #e04f5f' : '2px solid #152b4e', padding: '4px 10px', color: notConfirmed ? '#e04f5f' : '#152b4e', fontSize: 12, marginTop: 6, fontWeight: 'bolder'}} className='result-fcdoc'>
        {result}
      </div>
    )
  }

  renderDocRecommendation = () => {
    const { data } = this.props;
    const fcrenda = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    const recommendations = getDocRecommendation(data);

    if('resultado' in fcrenda && fcrenda.ativo === true) {
      const {resultado} = fcrenda
      switch(resultado.titulo) {
        case 'PERFIL FINANCEIRO CONFIRMADO':
        case 'PERFIL FINANCEIRO NÃO CONFIRMADO':
          return this.renderResultFcRenda(`${resultado.titulo}: ${resultado.descricao}`)
      }
    }

    if (Array.isArray(recommendations) && recommendations.length > 0) {
      return (
        <BorderedBox xs={12} md={12} lg={12} xl={12} margin=".5rem 0 0 0">
          <TitleBox>{`Documentação recomendada para comprovação da RENDA PESSOAL informada:`}</TitleBox>
          <AnalythicOList>
            { recommendations.map((item, index) => (<AnalythicLineItalic key={index} hideNumber>- {item}</AnalythicLineItalic>)) }
          </AnalythicOList>
        </BorderedBox>
      );
    }

    return null;
  }

  renderResultCreditCard = () => {
    const { data } = this.props;
    const fcrenda = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    const origem = fcrenda.origem_renda_principal

    if('resultado' in fcrenda && fcrenda.ativo === true) {
      if((fcrenda.bandeira || fcrenda.numero_cartao) && origem == 13) {
        return (
          <WrapperCardResult className="wrapperCardResult">
            <span style={{width: '100%'}}>
              <AnalythicLine style={{width: '98%', color: '#000'}}>
                <LineTitle maxWidth="150px" style={{left: 4, fontWeight: 600}}>Nº do cartão</LineTitle>
                <span>{fcrenda.numero_cartao}</span>
              </AnalythicLine>
            </span>
            <span style={{width: '100%'}}>
              <AnalythicLine style={{width: '98%', color: '#000'}}>
                <LineTitle maxWidth="150px" style={{left: 4, fontWeight: 600}}>Validade</LineTitle>
                <span>{fcrenda.validade}</span>
              </AnalythicLine>
            </span>
            <span style={{width: '100%'}}>
              <AnalythicLine style={{color: '#000'}}>
                <LineTitle maxWidth="150px" style={{left: 4, fontWeight: 600}}>Bandeira</LineTitle>
                <span>{fcrenda.bandeira}</span>
              </AnalythicLine>
            </span>
          </WrapperCardResult>)
      }
    }
    return null;
  }

  render() {
    const { data, isFcBasico } = this.props;
    const fcrenda  = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    if(!fcrenda.ativo || !fcrenda.resultado) {
      return null;
    }
    const origem_faturamento_titulo = fcrenda.origem_faturamento_titulo
    let receita_media_mensal = 0.00
    let capital_de_investimento = 0.00
    let patrimonio_estimado = 0.00

    if('resultado' in fcrenda && fcrenda.ativo === true) {
      if(fcrenda.receita_media_mensal) {
        receita_media_mensal = parseFloat(fcrenda.receita_media_mensal)
      }
      if(fcrenda.capital_de_investimento) {
        capital_de_investimento = parseFloat(fcrenda.capital_de_investimento)
      }
      if(fcrenda.patrimonio_estimado) {
        patrimonio_estimado = parseFloat(fcrenda.patrimonio_estimado)
      }
    }
    return (
      <StyledRow margin="0 0 .5rem 0" className="font-13-print">
        
          <BaseColumn xs={6} md={6} lg={6} xl={6} padding="0 .2rem">
            <ContainerGray ref={this.income}>
              <AnalythicList>
                <AnalythicLine style={{color: '#000'}}>
                  <LineTitle maxWidth="25%" className="line-title">Avaliação de Perfil por</LineTitle>
                  <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                    {origem_faturamento_titulo}
                  </span>
                </AnalythicLine>
                <AnalythicLine background="transparent" style={{color: '#000'}}>
                  <LineTitle maxWidth="25%" className="line-title">Receita Média Mensal</LineTitle>
                  <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                    {'R$ ' + formatToBRL(receita_media_mensal)}
                  </span>
                </AnalythicLine>
              </AnalythicList>
            </ContainerGray>
          </BaseColumn>
          <BaseColumn xs={6} md={6} lg={6} xl={6} padding="0 .2rem">
            <ContainerGray ref={this.income}>
              <AnalythicList>
                <AnalythicLine style={{color: '#000'}}>
                  <LineTitle maxWidth="250px">Capital de Investimento (empresa recente)</LineTitle>
                  <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                    {'R$ ' + formatToBRL(capital_de_investimento)}
                  </span>
                </AnalythicLine>
                <AnalythicLine background="transparent" style={{color: '#000'}}>
                  <LineTitle maxWidth="250px">Patrimônio Estimado</LineTitle>
                  <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                    {'R$ ' + formatToBRL(patrimonio_estimado)}
                  </span>
                </AnalythicLine>
              </AnalythicList>
            </ContainerGray>
          </BaseColumn>
          {/* { this.renderResultCreditCard() }

          {Array.isArray(amountInfo.recommendations) && amountInfo.recommendations.length > 0 && !isFcBasico &&
            <BorderedBox xs={12} md={12} lg={12} xl={12}>
              {this.renderAmountInfo()}
            </BorderedBox>
          }

          { this.renderDocRecommendation() } */}
      </StyledRow>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  income
}, dispatch)

export default connect(null, mapDispatchToProps)(AmountContainerPj)