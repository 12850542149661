import { toastr } from 'react-redux-toastr';
import * as types from './types';
import { actionCreator, generalActionTypes } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { getRequest } from '../../actions';
import { removeProduct } from '../../../../../../services/operational';
import { replace } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../../../../../auth/login/actions/types.js';


const { NON_HANDLED_ERROR } = generalActionTypes;

export const openModal = (idProduct, products, id, lastApplicant) => actionCreator(types.OPEN_MODAL, { idProduct, products, id, lastApplicant });
export const closeModal = () => actionCreator(types.CLOSE_MODAL);

export const deleteProduct = (requestId, applicantId, product, lastApplicant = true) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } } } = getState();
    try {
      const payload = await removeProduct(token.access_token, requestId, applicantId, product);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.error('Remover Pretendente', payload.data.message);
        }

      } else {
        dispatch(closeModal());
        dispatch(getRequest(requestId));
        toastr.success('Pretendente', 'Removido com sucesso');
      }
    } catch (error) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Infelizmente não podemos apagar este produto no momento, tente mais tarde.'));
    }
  }
}