import React from 'react';
import styled from 'styled-components';

const Badge = styled.span`
  font-size: 11px;
  color: ${props => props.colorText || props.theme.color_gray};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Bar = styled.div`
  width: 1rem;
  height: 3px;
  background-color: ${props => props.theme[props.color] || props.theme.color_gray};
  margin-right: .5rem;
`;

export default (props) => (
  <Badge>
    <Bar color={props.color} />{ props.children }
  </Badge>
);
