import { cpfValidation } from '@bit/wavfichacerta.fichacerta.utils.validators';
import { removeNonDigit } from '@bit/wavfichacerta.fichacerta.utils.normalizers'

/**
 * Field template for CPF field
 * 
 * @param size String
 */
export const defaultCPF = (size = "field-fc field-fc-xs") => ({
  name: "cpf",
  label: "CPF",
  isRequired: true,
  size,
  type: 'maskedText',
  validate: cpfValidation,
  normalize: removeNonDigit,
  props: {
    regex: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.',
      /\d/, /\d/, /\d/, '-', /\d/, /\d/
    ]
  }
});
