import React, { PureComponent } from 'react';
import {connect} from 'react-redux';

import fcAnalise from './products/fcAnalise';
import fcBasico from './products/fcBasico';
import fcAnaliseParecerLocacao from './products/fcAnaliseParecerLocacao';
import icons from '../../../shared/treatedIcons';
import {
  StatusBar,
  IconContainer,
  ResumeList,
  ResumeLi,
  ResumeText,
  TextContainer
} from './resumeTable.styles';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../shared/TourTooltip';

const { Triangle, Null, ReportAlert } = icons;

class ResumeTable extends PureComponent {
  static defaultProps = {
    lines: [],
    products: [1]
  }

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  scroll = (name) => {
    const { refs } = this.props
    const mainContainer = document.querySelector('.react-tabs__tab-panel--selected .full-port-view-container')
    const identification = document.querySelector('.react-tabs__tab-panel--selected [title="DADOS PESSOAIS (encontrados)"]')
    const network = document.querySelector('.react-tabs__tab-panel--selected [title="Rede FC de Informações"]')
    const income = document.querySelector('.react-tabs__tab-panel--selected [title="PERFIL FINANCEIRO"]')
    const socioEconomic = document.querySelector('.react-tabs__tab-panel--selected .socioEconomic')
    const company = document.querySelector('.react-tabs__tab-panel--selected .company')
    const risk = document.querySelector('.react-tabs__tab-panel--selected [title="RISCO DE INADIMPLÊNCIA"]')
    const restrictions = document.querySelector('.react-tabs__tab-panel--selected [title="AÇÕES JUDICIAIS e NEGATIVAÇÕES DE CRÉDITO*"]')
    const restrictionsAcoes = document.querySelector('.react-tabs__tab-panel--selected [title="AÇÕES DE DESPEJO E DE LOCAÇÃO*"]')
    const restrictionsAcoesCriminais = document.querySelector('.react-tabs__tab-panel--selected .acoes_criminais')
    const restrictionsAcoesCiveis = document.querySelector('.react-tabs__tab-panel--selected .acoes_civeis')

    console.log(name, mainContainer, refs)

    if(name === "SITUAÇÃO CPF"){
      mainContainer.scrollTop = identification.getClientRects()[0].top - mainContainer.getClientRects()[0].top
    }else if(name === "Suspeita de óbito" || name === "REDE FICHA CERTA" || name === "Consultas recentes (Mercado)*"){
      mainContainer.scrollTop = network.getClientRects()[0].top - mainContainer.getClientRects()[0].top - 50
    }else if(name === "RENDA INFORMADA" || name === "ORIGEM Perfil Financeiro"){
      mainContainer.scrollTop = income.getClientRects()[0].top - mainContainer.getClientRects()[0].top - 50
    }else if(name === "RENDA PRESUMIDA (Mercado)"){
      mainContainer.scrollTop = socioEconomic.getClientRects()[0].top - mainContainer.getClientRects()[0].top - 50
    }else if(name === "PART. EMPRESARIAL*"){
      mainContainer.scrollTop = company.getClientRects()[0].top - mainContainer.getClientRects()[0].top - 50
    }else if((name === "SCORE FC nesta locação*" || name === "Score de Mercado") && risk){
      mainContainer.scrollTop = risk.getClientRects()[0].top - mainContainer.getClientRects()[0].top - 50
    }else if((name === "NEGATIVAÇÕES DE CRÉDITO*" || name === "AÇÕES DE DESPEJO E DE LOCAÇÃO*") && restrictions){
      mainContainer.scrollTop = restrictions.getClientRects()[0].top - mainContainer.getClientRects()[0].top - 50
    }else if(name === "AÇÕES DE DESPEJO E DE LOCAÇÃO*" && restrictionsAcoes){
      mainContainer.scrollTop = restrictionsAcoes.getClientRects()[0].top - mainContainer.getClientRects()[0].top - 50
    }else if(name === "AÇÕES CRIMINAIS (v. Beta)*" && restrictionsAcoesCriminais){
      mainContainer.scrollTop = restrictionsAcoesCriminais.getClientRects()[0].top - mainContainer.getClientRects()[0].top - 50
    }else if(name === "AÇÕES CÍVEIS (v. Beta)*" && restrictionsAcoesCiveis){
      mainContainer.scrollTop = restrictionsAcoesCiveis.getClientRects()[0].top - mainContainer.getClientRects()[0].top - 50
    }
  }

  renderToolTips = (label) => {
    if(label === 'SCORE FC nesta locação') {
      return(
        <React.Fragment>
          <TourTooltip
            target="tooltip-score-fc-individual"
            style={{right: -10}}
          />
          <ReactTooltip id="tooltip-score-fc-individual" place="left" type="dark" effect="float">
            <span>Todos os fatores listados na síntese contribuem para a construção do <b>Score FC</b>, que é <b>dinâmico</b>, e <br />pode se alterar de acordo com o valor da locação pretendida e renda declarada.</span>
          </ReactTooltip>
        </React.Fragment>
      )
    }

    if(label === 'RENDAS INFORMADAS em conjunto') {
      return(
        <React.Fragment>
          <TourTooltip
            target="tooltip-rendas-conjunto-informadas"
            style={{right: -10}}
          />
          <ReactTooltip id="tooltip-rendas-conjunto-informadas" place="left" type="dark" effect="float">
            <span>Aqui você confere se o somatório da renda declarada das <br />pessoas é compatível com a locação.</span>
          </ReactTooltip>
        </React.Fragment>
      )
    }

    if(label === 'ORIGEM Perfil Financeiro ') {
      return(
        <React.Fragment>
          <TourTooltip
            target="tooltip-origem-perfil-financeiro"
            style={{right: -10}}
          />
          <ReactTooltip id="tooltip-origem-perfil-financeiro" place="left" type="dark" effect="float">
            <span>A origem da renda tem impacto sobre a análise dos perfis, preencha corretamente.</span>
          </ReactTooltip>
        </React.Fragment>
      )
    }

    if(label === 'RENDAS PRESUMIDAS em conjunto (Mercado)') {
      return(
        <React.Fragment>
          <TourTooltip
            target="tooltip-rendas-presumidas-mercado"
            style={{right: -10}}
          />
          <ReactTooltip id="tooltip-rendas-presumidas-mercado" place="left" type="dark" effect="float">
            <span>Aqui você confere a soma das rendas presumidas de mercado, caso <br />seja compatível com a locação você pode simplificar a <br />jornada em caso de risco baixo ou muito baixo.</span>
          </ReactTooltip>
        </React.Fragment>
      )
    }
    
    if(label === 'RISCO DE CRÉDITO em conjunto (Mercado)') {
      return(
        <React.Fragment>
          <TourTooltip
            target="tooltip-risco-credito-conjunto-mercado"
            style={{right: -10}}
          />
          <ReactTooltip id="tooltip-risco-credito-conjunto-mercado" place="left" type="dark" effect="float">
            <span>Risco de crédito é um resultado estático de mercado, como <br />essas pessoas performam no birô de crédito</span>
          </ReactTooltip>
        </React.Fragment>
      )
    }

    if(label === 'RISCO FC em conjunto (nesta locação)') {
      return(
        <React.Fragment>
          <TourTooltip
            target="tooltip-risco-fc-conjunto"
            style={{right: -10}}
          />
          <ReactTooltip id="tooltip-risco-fc-conjunto" place="left" type="dark" effect="float">
            <span>O Risco FC é uma tradução do quanto essas pessoas <br />representam de risco para a operação de locação.</span>
          </ReactTooltip>
        </React.Fragment>
      )
    }

    if(label === 'SCORE FC em conjunto (nesta locação)') {
      return(
        <React.Fragment>
          <TourTooltip
            target="tooltip-score-fc-conjunto"
            style={{right: -10}}
          />
          <ReactTooltip id="tooltip-score-fc-conjunto" place="left" type="dark" effect="float">
            <span>O <b>SCORE FC</b> é calculado pelo nosso algoritmo considerando as <br /> variáveis da locação, e outros dados em Big Data, por essa razão temos <br />uma escala de classificação própria que é diferente do <b>RISCO de CRÉDITO (Mercado)</b></span>
          </ReactTooltip>
        </React.Fragment>
      )
    }
    return null
  }

  renderLine = (name, status = 'neutro', message) => {
    const { data } = this.props
    const tipo_pretendente = data.pretendente ? data.pretendente.tipo_pretendente : null;
    let Icon = null;
    const fcrenda = data && data.laudo && 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    const origem = data && data.laudo ? data.laudo.principal_origem_renda.result.origem : null;
    const nameForScroll = name
    console.log('name1', name)
    console.log('name2', status)
    if((tipo_pretendente != 'INQUILINO' && tipo_pretendente != 'CONJUGE_INQUILINO') && name == 'IRÁ RESIDIR') {
      return null
    }

    if (status === "positivo" || status === 'triangule-green-light') {
      Icon = <Triangle color="#37c120" green rotate={"-90"} />;
    }

    if (status === 'triangule-green-dark') {
      Icon = <Triangle color="#006400" green rotate={"-90"} />;
    }

    if (status === "neutro" || status === "nulo") {
      Icon = <Null />;
    }

    if (status === 'triangule-yellow') {
      Icon = <Triangle color="#feda32" rotate={"90"} />;
    }

    if (status === 'triangule-orange') {
      Icon = <Triangle color="#ff7200" rotate={"90"} />;
    }

    if (status === "negativo" || status === 'triangule-red') {
      Icon = <Triangle color="#fe4d36" rotate={"90"} />;
    }

    if (status === 'triangule-alert-red') {
      Icon = <ReportAlert color="#006400" rotate={"-90"} customStyle='& > path { fill: #fe4d36 }' />;
    }

    if (status === "laranja-nulo") {
      Icon = <Null color="#ff7200" />;
    }

    if (status === "roxo-nulo") {
      Icon = <Null color="#673ab7" />;
    }

    if (status === "brown-nulo") {
      Icon = <Null color="#795548" />;
    }

    if (status === "negativo-nulo") {
      Icon = <Null color="#fe4d36" />;
    }

    if(name == 'ORIGEM Perfil Financeiro' && origem == 'Extratos Bancários'){
      message = 'Movimentação Bancária (extratos)'
    }

    if(name == 'RENDA PESSOAL informada' && (origem == 'Limite do Cartão de Crédito' || origem == 'Extratos Bancários')){
      name = 'RENDA ESTIMADA informada'
    }

    if(name == 'SITUAÇÃO CPF') {
      message = <span dangerouslySetInnerHTML={{__html: message}} />
    }

    if('resultado' in fcrenda && fcrenda.ativo === true) {
      const {resultado} = fcrenda
      if(name == 'RENDA INFORMADA' || name == 'RENDA ESTIMADA informada' || name == 'RENDA PESSOAL informada') {
        switch(resultado.titulo) {
          case 'PERFIL FINANCEIRO CONFIRMADO':
            name = 'RENDA CONFIRMADA'
            if(((fcrenda.limite_credito || fcrenda.numero_cartao) && fcrenda.origem_renda_principal == 13) || fcrenda.origem_renda_principal == 15) {
              name = 'RENDA ESTIMADA verificada'
            }
            break
          case 'PERFIL FINANCEIRO NÃO CONFIRMADO':
            name = 'RENDA NÃO CONFIRMADA'
            break
        }
      } else if(name == 'ORIGEM Perfil Financeiro') {
        switch(resultado.titulo) {
          case 'PERFIL FINANCEIRO CONFIRMADO':
            message = fcrenda.origem_renda_principal_titulo || fcrenda.origem_outra_renda_titulo
            if((fcrenda.limite_credito || fcrenda.numero_cartao) && fcrenda.origem_renda_principal == 13) {
              message = 'Limite do Cartão de Crédito'
            }else if(fcrenda.origem_renda_principal == 15){
              message = 'Movimentação Bancária (extratos)'
            }
            break
          case 'PERFIL FINANCEIRO NÃO CONFIRMADO':
            message = 'Não Confirmada'
            break
        }
      }
    }

    return (
      <ResumeLi status={status} name={name} onClick={() => this.scroll(nameForScroll)} style={{position: 'relative', display: !this.props.tipo ? 'inline-flex' : '', width: !this.props.tipo ? '100%' : ''}}>
        <TextContainer lg={!this.props.tipo ? 4 : 5} md={!this.props.tipo ? 4 : 5} xl={!this.props.tipo ? 4 : 5}>
          <StatusBar status={status} />
          <ResumeText style={{color: '#000'}}>{name}</ResumeText>
        </TextContainer>
        <IconContainer>{Icon}</IconContainer>
        <TextContainer>
          <ResumeText style={{color: '#000'}}>{message}</ResumeText>
        </TextContainer>
        {/* {this.renderToolTips(name)} */}
          
      </ResumeLi>
    );
  }

  renderLines = () => {
    let lines = [];
    lines = this.props.tipo === 'parecer_locacao' ? fcAnaliseParecerLocacao(this.props.data, this.props.tipo_imovel, this.props.laudos_sintese, this.props.fiadores) : fcAnalise(this.props.data);
    if(this.props.tipo === 'fcBasico') {
      lines = fcBasico(this.props.data)
    }
    console.log('linesdata1', this.props.parecer)
    return lines.map((line) => this.renderLine(line.label, line.status, line.message));
  }

  render() {
    return (
      <ResumeList ref={this.containerRef} style={{columnCount: !this.props.tipo ? 2 : '', columnGap: !this.props.tipo ? '0.4rem' : '', display: !this.props.tipo ? 'block' : ''}}>
        {this.renderLines()}
      </ResumeList>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  refs: state.singleReport
})

export default connect(mapStateToProps)(ResumeTable);