import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import Loading from '@bit/wavfichacerta.fichacerta.icons.loading-circle';
import { LoginContainer } from '../../home/home.styled';
import { CardRefresh, TitleRefresh } from './refreshLogin.styled';

const lightBackgroundTheme = (themeProps) => ({
  ...themeProps,
  input_label_color: themeProps.color_gray,
  input_color: themeProps.color_black,
  checkbox_color: themeProps.color_gray,
  dynamicPhoneButtonColor: "color_purple"
});

class RefreshLogin extends PureComponent {

  componentDidMount() {
    console.log('REFRESH TOKEN', this.props);
    const { match: { path, params } } = this.props;
    if (path === "/refresh/:token" && (typeof params === "object" && "token" in params && params.token !== "")) {
      //Refresh
    }
  }

  render() {
    return (
      <ThemeProvider theme={lightBackgroundTheme}>
        <LoginContainer>
          <BaseColumn sm={12} md={6} lg={6} xl={6} customstyle="display:flex;flex-direction: column;align-items: center;">
            <CardRefresh>
              <Loading width="5vw" />
              <TitleRefresh>Aguarde enquanto validamos seu login...</TitleRefresh>
            </CardRefresh>
          </BaseColumn>
        </LoginContainer>
      </ThemeProvider>
    );
  }
}

export default connect(null)(RefreshLogin);
