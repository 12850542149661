import React from 'react';
import { Th, ThCentered } from '@bit/wavfichacerta.fichacerta.tables.styles';
const TableHeader = (props) => (
  <tr style={{color: '#000'}}>
    <Th>
      Data
    </Th>

    <Th>
      Tipo
    </Th>

    {props.pretendente.cpf &&
      <React.Fragment>
        <Th>
          Modalidade
        </Th>

        <ThCentered>
          Avalista
        </ThCentered>
      </React.Fragment> || null
    }

    <Th align="right">
      Valor
    </Th>

    <Th>
      Contrato
    </Th>

    <Th>
      Credor
    </Th>

    <Th>
      Filial
    </Th>
  </tr>
);

export default TableHeader;
