import React, { PureComponent, Fragment } from "react";

import { BaseColumn } from "@bit/wavfichacerta.fichacerta.shared-styles.columns";
import {
  StyledCard,
  StyledRowMobile,
  StyledLineMobile,
  StyledTitleMobile,
  StyledCardBody
} from "./styled";
import { StyledHr } from "../../../components/Operational/AnalythicReport/TradeFinancialSection/styled";

import { formatToBRL } from '../../../utils/formatter';

class PricesMobileTable extends PureComponent {
  renderLine = (products, index) => {
      console.log(products)
    return products.map(product => {
      let desconto = product.desconto_selecionado === 1 ? 0 : product.descontos.filter(desconto => { return desconto.id === product.desconto_selecionado });
      return (
        <StyledCard>
          <StyledCardBody>
            <StyledRowMobile>
              <BaseColumn xs="4" sm="4" customstyle="padding: .5rem">
                <StyledTitleMobile>Produto</StyledTitleMobile>
                <StyledLineMobile color="#152b4e !important">{product.titulo}</StyledLineMobile>
              </BaseColumn>
              <BaseColumn xs="4" sm="4" customstyle="padding: .5rem">
                <StyledTitleMobile>Valor</StyledTitleMobile>
                <StyledLineMobile>R$ {formatToBRL(product.valor.slice(0,-3))} </StyledLineMobile>
              </BaseColumn>
              {
                desconto ? 
                <BaseColumn xs="4" sm="4" customstyle="padding: .5rem">
                    <StyledTitleMobile>Desconto</StyledTitleMobile>
                    <StyledLineMobile>R$ {formatToBRL(desconto[0].valor.slice(0,-3))}</StyledLineMobile>
                </BaseColumn>
                : null
              }
            </StyledRowMobile>
            <StyledHr />
            <StyledRowMobile>
              <BaseColumn xs="8" sm="8" customstyle="padding: .5rem">
                <StyledTitleMobile>Serviços</StyledTitleMobile>
                <StyledLineMobile>{product.servicos.length > 0 ? product.servicos.map(servico => `${servico.titulo}: R$ ${servico.valor}`) : "Sem serviços adicionais"}</StyledLineMobile>
              </BaseColumn>
              <BaseColumn xs="4" sm="4" customstyle="padding: .5rem">
                <StyledTitleMobile>Valor Final</StyledTitleMobile>
                <StyledLineMobile color="#152b4e !important">R$ {typeof desconto == 'number' ? formatToBRL(product.valor.slice(0,-3)) : formatToBRL(product.valor - desconto[0].valor)}</StyledLineMobile>
              </BaseColumn>
            </StyledRowMobile>
          </StyledCardBody>
        </StyledCard>
      );
    });
  };

  render() {
    const { products } = this.props;
    return (
      <Fragment>
        {this.renderLine(products)}
      </Fragment>
    );
  }
}


export default PricesMobileTable;
