import React, { PureComponent } from 'react';

import ModalContainer from '../../../../../components/shared/modal/ModalContainer';
import { PrimaryButton, StyledRow, SecundaryButton } from '../../../../../components/shared/sharedStyles.styles';
import { Container, SpanTitle, GrayText } from '../../../request/form/fieldModal/formModalFields.styled';
import LoadingCircle from '@bit/wavfichacerta.fichacerta.icons.loading-circle';
import Loading from '@bit/wavfichacerta.fichacerta.icons.loading';

class ModalReportFianca extends PureComponent {

  componentWillUnmount() {
    console.log('DESMOTANDO MODAL FIANCA')
  }

  renderFooter = () => {
    if(this.props.loading || this.props.error || this.props.success) {
      return null
    }

    return(
      <StyledRow padding='0 3em' justify="space-around">
        <PrimaryButton data-testid="send-data-button" onClick={this.props.onClose}>Cancelar</PrimaryButton>
        <PrimaryButton data-testid="go-back-button" onClick={this.props.undo}>Avançar</PrimaryButton>
      </StyledRow>
    )
  }

  renderHeader = () => (
    <SpanTitle customstyle="margin: auto;">
      SOLICITAR ORÇAMENTO
    </SpanTitle>
  )

  renderBody = () => {
    if(this.props.error) {
      return 'Ocorreu um erro interno ao enviar o email. Se persistir o erro, contate a FC ANALISE.'
    }
    if(this.props.success) {
      return 'Sua solicitação foi enviada com sucesso! Em breve nossa equipe dará o retorno.'
    }
    return 'Deseja prosseguir para confirmação do orçamento e aprovação do seguro fiança?'
  }

  renderLoading = () => {
    if(this.props.loading) {
      return <div style={{marginTop: 20, marginBottom: -10}}>
          <Loading width="30px" height="30px" margin="0 auto" color="color_border-gray" />
        </div>
    }
  }

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        size="md"
        onClose={this.props.onClose}
        footer={this.renderFooter()}
        header={this.renderHeader()}
        customstyle='
          @media (max-width: 800px) {
            h5 {
              font-weight: 400;
            }
          }
        '
        customstyleModalFooter='
          @media (max-width: 800px) {
            button {
              width: 121px;
              height: 33px;
              font-size: .88rem;
              background-color: #152b4e;
              padding: 0;
              margin-top: 0;
              margin: 0 9px;
            }

            .row {
              justify-content: center;
              padding: 0;
            }
          }
        '
      >

        <Container
          customstyle="
            text-align: center;
            margin: 20px auto;

            span {
              font-size: 12px;
              font-weight: 400 !important;
            }

            @media (max-width: 800px) {
              margin: 10px auto;
            }
          "
        >
          <GrayText>
            {this.renderBody()}
          </GrayText>
          {this.renderLoading()}
        </Container>

      </ModalContainer>
    );
  }
}

export default ModalReportFianca;