import moment, { utc } from 'moment';
import { toastr } from 'react-redux-toastr';

export const checkDate = (date, resumo) => {
  const before = moment(date).isSameOrBefore(resumo.periodo.end_date, "day");
  const after = moment(date).isSameOrAfter(resumo.periodo.start_date, "day");

  if(before && after){
    return true;
  } else {
    return false
  }
}

export const checkSomeDate = (requestsArray, resumo) => {
  return requestsArray.some(request => checkDate(request.data, resumo))
}

export const setFilter = ({radio_filter, initial_date, end_date, month_filter, year_filter}, changeFilters, getData) => {
  const stringInitialDate = initial_date.replace(/\D*/g, '');
  const stringEndDate = end_date.replace(/\D*/g, '');
  if(moment(initial_date, 'DD-MM-YYYY').isValid() && moment(end_date, 'DD-MM-YYYY').isValid() 
    && stringInitialDate.length === 8 && stringEndDate.length === 8){  
    if(radio_filter === "1"){
      var start_date = moment(initial_date, 'DD-MM-YYYY').toISOString();
      var final_date = moment(end_date, 'DD-MM-YYYY').toISOString();
      const initial_date_splited = initial_date.split('/');
      const end_date_splited = end_date.split('/');
      const initial_date_month = initial_date_splited[1];
      const initial_date_year = initial_date_splited[2];
      const end_date_month = end_date_splited[1];
      const end_date_year = end_date_splited[2];
      if(start_date > final_date){
        toastr.error("Erro!", "A data inicial é maior que a final.")
        return null;
      }
      if(initial_date_month !== end_date_month || initial_date_year !== end_date_year) {
        toastr.error("Erro!", "Selecione apenas datas do mesmo período (mês e ano).")
        return null;
      }
    }else{
      start_date = moment(`${year_filter ? year_filter : moment().format('YYYY')}-${month_filter ? Number(month_filter) + 1 : 1}-01`).toISOString();
      final_date = moment(moment(start_date).utc().endOf("month").format('YYYY-MM-DD')).toISOString()
    }
  
    changeFilters({start_date, end_date: final_date})
    getData();
  }else{
    toastr.error("Erro!", "A data informada no filtro é inválida.")
  }
  
}

export const getInitialValues = (resumo) => {
  if(!resumo || !resumo.periodo){ 
    return {radio_filter: "1"}
  }
  
  const initialValues = {
    radio_filter: "1",
    initial_date: moment(resumo.periodo.start_date).format("DD/MM/YYYY"),
    end_date: moment(resumo.periodo.end_date).format("DD/MM/YYYY")
  }

  return initialValues
}