import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { SubmitButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { getFieldsLabel, getRequiredFieldsWithError } from '@bit/wavfichacerta.fichacerta.forms-utils.extractors';
import Loading from '@bit/wavfichacerta.fichacerta.icons.loading';

export class StepButton extends PureComponent {

  static defaultProps = {
    onChangeStep: () => { },
    showBackButton: false,
    sending: false,
    label: undefined
  }

  onClickButton = () => {
    const { forms, allForms, usedFields, onChangeStep, entity } = this.props;

    if (allForms[forms[0]] !== undefined && typeof allForms === "object") {

      const emptyRequiredFields = getRequiredFieldsWithError(allForms, entity);
      const labels = getFieldsLabel(emptyRequiredFields, usedFields);

      if (labels.length > 0) {
        onChangeStep(allForms, labels);
      } else {
        onChangeStep(allForms);
      }

    }
  }

  getButtonSettings = () => {
    const { forms, currentStep, label } = this.props;
    if (label) {
      return label;
    }
    let formsQuantity = Array.isArray(forms) ? forms.length : 0;
    let labelButton = 'Avançar';

    if (formsQuantity === 1) {
      labelButton = 'Salvar';
    }

    if (currentStep === formsQuantity) {
      labelButton = 'Salvar';
    }
    return labelButton;
  }

  renderGoBackButton = () => {
    if (this.props.showBackButton) {
      return (<SubmitButton onClick={this.props.goBack} customstyle={{ color: 'white', marginRight: '1rem' }}>Cancelar</SubmitButton>)
    }
  }

  render() {
    return (
      <StyledRow justify="center" padding="1rem 0">
        {this.renderGoBackButton()}
        <SubmitButton
          disabled={this.props.sending}
          data-testid="StepButton"
          color="color_white"
          onClick={this.onClickButton}
        >
          {(this.props.sending) ?
            <Loading width="2.45rem" height="1.35rem" />
            :
            this.getButtonSettings()
          }
        </SubmitButton>
      </StyledRow>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  allForms: state.form
});

export default connect(mapStateToProps, null)(StepButton);