import * as types from '../actions/types';
import {
  onGetUsers,
  onGetUsersFailed,
  onGetUsersSuccess,
  onChangeSort,
  onChangePage,
  onChangeMaxResults,
  onResendMailConfirmation,
  onResendMailSuccess,
  onResendMailFailed,
  onChangeUserType,
  onChangeUserTypeSuccess,
  onChangeUserTypeFailed,
  onChangeUserStatus,
  onChangeUserStatusSuccess,
  onChangeUserStatusFailed
} from './reducers';

const initialState = {
  requesting: false,
  error: undefined,
  data: [],
  pagination: {
    sort: 'nome',
    currentPage: 1, 
    lastPage: 1, 
    resultsPerPage: "10", 
    filter: ""
  }
}

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case types.CHANGE_USER_STATUS:
      return onChangeUserStatus(state);
    case types.CHANGE_USER_STATUS_SUCCESS:
      return onChangeUserStatusSuccess(state, payload);
    case types.CHANGE_USER_STATUS_FAILED:
      return onChangeUserStatusFailed(state);
    case types.CHANGE_USER_TYPE:
      return onChangeUserType(state);
    case types.CHANGE_USER_TYPE_SUCCESS:
      return onChangeUserTypeSuccess(state, payload);
    case types.CHANGE_USER_TYPE_FAILED:
      return onChangeUserTypeFailed(state);
    case types.RESEND_EMAIL_CONFIRMATION:
      return onResendMailConfirmation(state);
    case types.RESEND_EMAIL_CONFIRMATION_SUCCES:
      return onResendMailSuccess(state, payload)
    case types.RESEND_EMAIL_CONFIRMATION_FAILED:
      return onResendMailFailed(state);
    case types.USERS_LIST_REQUEST:
      return onGetUsers(state);
    case types.USERS_LIST_SUCCESS:
      return onGetUsersSuccess(state, payload);
    case types.USERS_LIST_FAILED:
      return onGetUsersFailed(state);
    case types.USERS_LIST_CHANGE_ORDER:
      return onChangeSort(state, payload);
    case types.USERS_LIST_CHANGE_PAGE:
      return onChangePage(state, payload);
    case types.USERS_LIST_CHANGE_MAX_RESULTS:
      return onChangeMaxResults(state, payload);
    case types.USERS_LIST_RESET_DATA:
      return initialState;
    default:
      return state;
  }
}
