import { actionCreator, generalActionTypes,extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { toastr } from 'react-redux-toastr';
import { getReport, sendMailFianca } from '../../../../../services/operational';
import * as types from './types';
import { getPrintLaudo } from '../../../../../services/print';
import { replace } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../../../../../pages/auth/login/actions/types.js';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const saveReport = (report) => actionCreator(types.REPORT_SUCCESS, report);

export const prepareReport = (applicants, withIncludedApplicants = true) => actionCreator(types.PREPARE_REPORT, { applicants, withIncludedApplicants });
export const receiveReport = (report) => actionCreator(types.RECEIVE_REPORT, report);

export const retrieveReport = (solicitationId) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.REPORT_REQUESTNG));
    try {
      const payload = await getReport({ solicitation_id: solicitationId });
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(types.REPORT_FAILED, extractErrorMessages(payload.data.data)));
        }
      } else {
        //dispatch(actionCreator(types.REPORT_SUCCESS, payload.data));
      }
    } catch (error) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível carregar os dados do laudo, tente novamente mais tarde.'));
    }
  }
}

export const getPrintLaudoReq = (id) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } } } = getState();
    dispatch(actionCreator(types.PRINT_REQUESTING))
    try {
      const payload = await getPrintLaudo(token.access_token,id)
      
      if(payload.status !== 200) {
        if(payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Error', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.PRINT_FAILED, extractErrorMessages(payload.data.message)))
        }
      } else {
        dispatch(actionCreator(types.PRINT_SUCCESS, payload.data))
        return payload.data
      }
    } catch (e) {
      toastr.warning('Error', 'Não foi possivel finalizar sua impressão. Tente novamente mais tarde.');
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possivel finalizar sua impressão. Tente novamente mais tarde.'))
      return 'error';
    }
  }
}

export const actionSendMailFianca = (id_solicitacao, id_pretendente) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.SEND_MAIL_FIANCA));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await sendMailFianca(token.access_token, id_solicitacao, id_pretendente);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          // dispatch(actionCreator(types.REPORT_FAILED, extractErrorMessages(payload.data.data)));
          dispatch(actionCreator(types.SEND_MAIL_FIANCA_FAILED));
          dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível enviar o email, tente novamente mais tarde.'));
        }
      } else {
        dispatch(actionCreator(types.SEND_MAIL_FIANCA_SUCCESS));
        //dispatch(actionCreator(types.REPORT_SUCCESS, payload.data));
      }
    } catch (error) {
      dispatch(actionCreator(types.SEND_MAIL_FIANCA_FAILED));
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível enviar o email, tente novamente mais tarde.'));
    }
  }
}

export const actionResetSendMailFianca = () => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.SEND_MAIL_FIANCA_RESET));
  }
}

