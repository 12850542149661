import { instance as axios } from './axiosInstance';
import { statusHandler } from '../utils/loginHelper';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

const LOGIN_END_POINT = "/api/login";
const RECOVER_PASSWORD_END_POINT = '/api/password/email/send';
const RESET_PASSWRD_END_POINT = '/api/password/email/reset';
const REFRESH_TOKEN_END_POINT = '/oauth/token';
const LOGOUT_ENDPOINT = '/api/logout';
const DEFINE_PASSWORD_ENDPOINT = '/api/confirmation/registration';
const getRegisterConfirmationEndpoint = hash => `/api/confirmation/registration/${hash}`;
const USER_ENDPOINT = '/api/user';

const getHeader = (token) => (
  {
    'Authorization': `Bearer ${token}`
  }
);

export const logout = (token) => axios.post(LOGOUT_ENDPOINT, {}, { validateStatus: statusHandler, headers: getHeader(token) });

export const setPassword = (password, password_confirmation, token) => {
  const data = {
    hash: token,
    password,
    password_confirmation
  };

  return axios.post(DEFINE_PASSWORD_ENDPOINT, data, { validateStatus: statusHandler });
};

export const login = (username, password) => {
  const data = {
    grant_type: "password",
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    username,
    password
  };

  return axios.post(LOGIN_END_POINT, data, { validateStatus: statusHandler });
};

export const refreshToken = (refresh_token) => {
  const data = {
    grant_type: "refresh_token",
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    refresh_token,
    scope: '*'
  };

  return axios.post(REFRESH_TOKEN_END_POINT, data, { validateStatus: statusHandler });
};


export const sendEmailToRecoverPassword = (email) => {
  const data = {
    email
  };

  return axios.post(RECOVER_PASSWORD_END_POINT, data, { validateStatus: statusHandler });
};

export const resetPassword = (email, password, password_confirmation, token) => {
  const data = {
    email,
    password,
    password_confirmation,
    token
  };

  return axios.post(RESET_PASSWRD_END_POINT, data, { validateStatus: statusHandler });
};

export const userRegisterConfirmation = (hash) => {
  return axios.get(getRegisterConfirmationEndpoint(hash), { validateStatus: statusHandler });
};

export const getUser = (token) => {
  const headers = getHeader(token);
  return axios.get(USER_ENDPOINT, { headers });
}