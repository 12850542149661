import React, { Component } from 'react'

export default class Services extends Component {
  render() {
    return (
    <svg viewBox="0 0 124 124" className="nano">
        <path className="white" d="M102.1,33.9H82.3v-4c0-4.4-3.6-7.9-7.9-7.9H50.6c-4.4,0-7.9,3.6-7.9,7.9v4H22.9c-4.4,0-7.9,3.6-7.9,7.9v51.5 c0,4.4,3.6,7.9,7.9,7.9h79.2c4.4,0,7.9-3.6,7.9-7.9V41.8C110,37.4,106.4,33.9,102.1,33.9z M46.7,29.9c0-2.2,1.8-4,4-4h23.7 c2.2,0,4,1.8,4,4v4H46.7V29.9z M106,93.3c0,2.2-1.8,4-4,4H22.9c-2.2,0-4-1.8-4-4V64.4c1.2,0.7,2.5,1.1,4,1.1h31.7v5.9 c0,1.1,0.9,2,2,2h11.9c1.1,0,2-0.9,2-2v-5.9h31.7c1.4,0,2.8-0.4,4-1.1L106,93.3L106,93.3z M58.5,69.5v-7.9h7.9v7.9H58.5z M106,57.6 c0,2.2-1.8,4-4,4H70.4v-2c0-1.1-0.9-2-2-2H56.6c-1.1,0-2,0.9-2,2v2H22.9c-2.2,0-4-1.8-4-4V41.8c0-2.2,1.8-4,4-4h79.2 c2.2,0,4,1.8,4,4V57.6z"/>
    </svg>
    )
  }
}
