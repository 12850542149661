import React, { Component } from "react";
import { PropTypes } from 'prop-types';
import { FullColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { RowCenter, CircleContainer, TitleContainer, IconContainer } from './title.styles';
// import icons from '../../shared/treatedIcons';
import CustomTooltip from '@bit/wavfichacerta.fichacerta.tooltip.custom-tooltip';
const Icon = [];

class Title extends Component {
  handleIcon = () => {
    const { icon, iconPermission, message } = this.props;
    if (icon !== "" && icon !== undefined) {
      // const Icon = icons[icon];
      if (iconPermission) {
        if (message) {
          return (
              <CustomTooltip
                message={this.props.message}
                position="bottom-end"
                target="iconTitle"
              >
                <div id="iconTitle">
                  <IconContainer onClick={this.props.iconClick}>
                    <Icon />
                  </IconContainer>
                </div>
              </CustomTooltip>
          )
        }
      }


      return (
        <CustomTooltip message={this.props.message} position="bottom-end" target="iconTitle">
          <div id="iconTitle">
            <IconContainer onClick={this.props.iconClick}>
              <Icon />
            </IconContainer>
          </div>
        </CustomTooltip>
      );
    }


    return null;
  }

  render() {
    return (
      <RowCenter>
        <FullColumn>
          <CircleContainer>
            <TitleContainer>{this.props.title}</TitleContainer>
            {this.handleIcon()}
          </CircleContainer>
        </FullColumn>
      </RowCenter>
    );
  }
}

Title.propTypes = {
  title: PropTypes.string.isRequired
};

export default Title