import styled from 'styled-components';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';

export const InsuranceLine = styled(StyledRow)`
  padding: 0 8px;
  background-color: ${props => props.color? props.theme[props.color] : 'transparent'};
`;

export const ColumnInsurance = styled(BaseColumn)`
  padding: ${props => props.padding? props.padding : '0'};
  background-color: ${props => props.color? props.theme[props.color] : 'transparent'};
`;

export const InsuranceTitle = styled.label`
  font-size: .72rem;
  font-weight: 500;
  color: ${props => props.theme.color_gray};
`;
