import {
    OPEN_STATUS_MODAL,
    CLOSE_STATUS_MODAL
} from '../actions/types';

import {
    onOpenModalState,
    onCloseModalState
} from './reducers';

const initialState = {
    isVisible: false,
    selectedData: {}
};

export default (state = initialState, { type, payload }) => {
    switch(type){
        case OPEN_STATUS_MODAL:
            return onOpenModalState(state, payload);
        case CLOSE_STATUS_MODAL:
            return onCloseModalState(state);
        default:
            return state;
    }
}