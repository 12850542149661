export const onGetApplicantRequesting = state => (
  {
    ...state,
    currentApplicant: undefined,
    isLoading: true,
    error: undefined
  }
);

export const onGetApplicantSuccess = (state, payload) => (
  {
    ...state,
    currentApplicant: payload,
    isLoading: false,
    error: false
  }
);

export const onGetApplicantFailed = (state) => (
  {
    ...state,
    currentApplicant: undefined,
    isLoading: false,
    error: true
  }
);

export const onGetRequest = (state) => (
  {
    ...state,
    resume: {
      infos: {},
      isLoading: true,
      error: undefined
    }
  }
);

export const onGetRequestSuccess = (state, payload) => (
  {
    ...state,
    resume: {
      infos: payload,
      isLoading: false,
      error: false
    }
  }
);

export const onGetRequestFailed = (state, payload) => (
  {
    ...state,
    resume: {
      infos: {},
      isLoading: false,
      error: true
    }
  }
);

export const onAddApplicant = (state) => (
  {
    ...state,
    addApplicant: {
      isLoading: true,
      error: undefined
    }
  }
);

export const onAddApplicantSuccess = (state) => (
  {
    ...state,
    addApplicant: {
      isLoading: false,
      error: false
    }
  }
);

export const onAddApplicantFailed = (state, payload) => (
  {
    ...state,
    addApplicant: {
      isLoading: false,
      error: payload
    }
  }
);

export const onUpdateApplicant = (state) => (
  {
    ...state,
    editApplicant: {
      ...state.editApplicant,
      sending: true,
      sendingError: undefined
    }
  }
);

export const onUpdateApplicantFailed = (state, payload) => (
  {
    ...state,
    editApplicant: {
      ...state.editApplicant,
      sending: false,
      sendingError: payload
    }
  }
);

export const onUpdateApplicantSuccess = (state) => (
  {
    ...state,
    editApplicant: {
      ...state.editApplicant,
      sending: false,
      sendingError: undefined
    }
  }
);

export const onUpdateReportSettings = (state) => ({
  ...state,
  reportSettings: {
    ...state.reportSettings,
    isRequesting: true,
    error: undefined
  }
});

export const onUpdateReportSettingsSuccess = (state) => ({
  ...state,
  reportSettings: {
    ...state.reportSettings,
    isRequesting: false,
    error: false,
    success: true
  }
});

export const onUpdateReportSettingsFailed = (state) => ({
  ...state,
  reportSettings: {
    ...state.reportSettings,
    isRequesting: false,
    error: true,
    success: false
  }
});

export const onUpdateReportSettingsReset = (state) => ({
  ...state,
  reportSettings: {
    ...state.reportSettings,
    isRequesting: false,
    error: false,
    success: false
  }
});

export const onGetUserInfo = (state, payload) => ({
  ...state,
  userInfo: payload
})