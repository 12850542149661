import React, { PureComponent } from 'react';

import {
  FlexContainer,
  ContentBox,
  CardContainer,
  SyntethicColumn
} from '../../../../../components/Operational/SyntheticReport/syntheticReport.styles';
import ContainerLoading from '../../../../../components/Operational/ContainerLoading';
import icons from '../../../../../components/shared/treatedIcons';
import {ParecerText, Name, TabLink} from "./styled";
import Inquilino from '../../../../../components/shared/icons/Inquilino';
import Fiador from '../../../../../components/shared/icons/Fiador';
import Conjuge from '../../../../../components/shared/icons/Conjuge';
import Outro from '../../../../../components/shared/icons/Outro';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../../../components/shared/TourTooltip';

const {
  FaceGood,
  FaceVeryGood,
  FaceRegular,
  FaceInconsistent,
  FaceNotAdvisable,
  ReportAlert,
  Null
} = icons;

class IndividualSynthesis extends PureComponent {
  
  state = {
    showArrow: false,
    arrow: undefined
  }

  getStatusIcon = (status) => {
    switch (status) {
      case "PARECER_NIVEL_1":
        return <FaceVeryGood margin="0" width="3rem" height="4rem"/>;
      case "PARECER_NIVEL_2":
        return <FaceGood margin="0" width="3rem" height="4rem"/>;
      case "PARECER_NIVEL_3":
        return <FaceRegular margin="0" width="3rem" height="4rem"/>;
      case "PARECER_NIVEL_4":
        return <FaceInconsistent margin="0" width="3rem" height="4rem"/>;
      case "PARECER_NIVEL_5":
        return <FaceNotAdvisable margin="0" width="3rem" height="4rem"/>;
      default:
        return <ReportAlert margin="0" width="3rem" height="4rem"/>;
    }
  }

  getColor = (status) => {
    switch (status) {
      case 'PARECER_NIVEL_1':
        return "#00af24";
      case 'PARECER_NIVEL_2':
        return "#73cf2b";
      case 'PARECER_NIVEL_3':
        return "#ff7200";
      case 'PARECER_NIVEL_4':
        return "#ff7200";
      case "PARECER_NIVEL_5":
        return "#fe4d36";
      default:
        return "#d99600"
    }
  }

  getApplicantIcon = (sintese) => {
    const {applicants} = this.props
    const applicant = applicants.find(applicant => applicant.applicant === sintese.pretendente_id)

    if(applicant && applicant.data && applicant.data.pretendente) {
      return applicant.data.pretendente.tipo_pretendente.includes("CONJUGE") ? applicant.data.pretendente.tipo_pretendente.replace("_", " ").replace("CONJUGE", "CÔNJUGE") : applicant.data.pretendente.tipo_pretendente.replace("_", " ");
    }else
      return null
  }
  
  showArrow = element => {
    if (!this.state.showArrow) {
      this.setState({ showArrow: true, arrow: element.offsetHeight });
    }
  }

  getHeightTable = () => {
    if (this.state.arrow !== null && this.state.arrow !== undefined) {
      return this.state.arrow;
    }
    return 100;
  }

  renderLabelFcRenda = (fcrenda) => {
    if(Object.keys(fcrenda).length === 0) {return null}
    return(
      <Name style={{fontSize: "12px", position: 'relative'}} >
        <span style={{fontWeight: 500, color: '#000', textDecoration: !fcrenda.ativo && fcrenda.status == 'CONCLUIDO' && 'line-through #7f7f7f'}} >FC DOC</span>
        <div
          style={{
            position: 'absolute',
            top: 1,
            left: 66,
            padding: '0 4px',
            color: '#fff',
            fontSize: 10,
            fontWeight: 600,
            width: fcrenda.status != 'CONCLUIDO' && 84, 
            backgroundColor: fcrenda.status == 'CONCLUIDO' ? '#2abd11' : '#2a88ff',
            textDecoration: !fcrenda.ativo && fcrenda.status == 'CONCLUIDO' && 'line-through #7f7f7f'
          }
        }>
          {fcrenda.status == 'CONCLUIDO' ? 'Concluída' : 'Em andamento'}
        </div>
      </Name>
    )
  }

  renderLabelFcEmpresa = (fcrenda = {status: 'CONCLUIDO'}) => {
    return(
      <Name style={{fontSize: "12px", position: 'relative'}} >
        <span style={{fontWeight: 500, color: '#000'}} >FC EMPRESA</span>
        <div
          style={{
            position: 'absolute',
            top: 1,
            left: 86,
            padding: '0 4px',
            color: '#fff',
            fontSize: 10,
            fontWeight: 600,
            width: fcrenda.status != 'CONCLUIDO' && 84, 
            backgroundColor: fcrenda.status == 'CONCLUIDO' ? '#2abd11' : '#2a88ff'
          }
        }>
          {fcrenda.status == 'CONCLUIDO' ? 'Concluída' : 'Em andamento'}
        </div>
      </Name>
    )
  }

  renderIconFcEmpresa = (score) => {
    let Icon = null;
    let status = null;
    let color = null;
    if(score >= 850) {
      status = 'dark-green-circle';
    } else if(score >= 650 && score <= 849) {
      status = 'light-green-circle';
    } else if(score >= 500 && score <= 649) {
      status = 'yellow-circle';
    } else if(score >= 350 && score <= 499) {
      status = 'orange-circle';
    } else if(score >= 3 && score <= 349) {
      status = 'negative-circle';
    } else {
      status = 'nulo';
      switch(score) {
        case 2:
          score = 'Modelo de score não se aplica ao porte da empresa';
          break;
        case 1:
          score = 'Não possui informações relevantes para cálculo de score';
          break;
        case '-':
        case '0':
        case 0:
          score = 'Score não disponível';
          break;
      }
    }

    if(status == 'nulo') {
      Icon = <ReportAlert color='color_purple' />;
      color = '#152b4e';
    }
    if(status == 'orange-circle') {
      Icon = <Null color="#ff7200" />;
      color = '#ff7200';
    }
    if(status == 'negative-circle') {
      Icon = <Null color="#fe4d36" />;
      color = '#fe4d36';
    }
    if(status == 'dark-green-circle') {
      Icon = <Null color="#006400" />;
      color = '#006400';
    }
    if(status == 'light-green-circle') {
      Icon = <Null color="#37c120" />;
      color = '#37c120';
    }
    if(status == 'yellow-circle') {
      Icon = <Null color="#feda32" />;
      color = '#feda32';
    }
    return (
      <ContentBox align="center" justify="center" height="auto" className="score-mercado-pj" style={{position: 'relative'}}>
        <div style={{width: 15}}>{Icon}</div>
        <ParecerText style={{ fontWeight: 500, color: color}} className='bold-print'>SCORE Mercado: {score}</ParecerText>
        
        {/* <TourTooltip
          target="tooltip-score-mercado-pj"
          style={{right: -10, top: -15}}
        />
        <ReactTooltip id="tooltip-score-mercado-pj" place="top" type="dark" effect="float">
          <span>Entregamos o score de mercado, exceto para os casos de empresas <br />recém-constituídas, ou de grande porte que não terão essa informação disponível.</span>
        </ReactTooltip> */}
      </ContentBox>
    )
  }

  renderRazaoSocial = (id) => {
    const { applicants } = this.props;
    const applicant = applicants.filter(applicant => applicant.applicant === id)

    if(applicant.length) {
      return applicant[0].data.laudo && applicant[0].data.laudo.veracidade_nome.result
    }
    return null
  }

  render() {
    const { data, loading, changeTab, applicants, sinteses } = this.props;
    
    if (loading) {
      return (<ContainerLoading size="large" />);
    }
    // const sinteses = ("laudos_sintese" in data)? data.laudos_sintese : {};

    return (      
      <FlexContainer style={{flexWrap: "wrap"}}>
        {sinteses.map((sintese, index) => {
          index = sintese.index
          sintese = sintese.data
          return (
            <SyntethicColumn class="no-flex" className='box-parecer-individual' width="33%" direction="column" customStyle="display:flex;">
              <CardContainer cardStyle="padding: .5rem; margin: .3rem .3rem .1rem .3rem; min-height: auto; display:flex;flex-direction: row;">
                <ContentBox align="flex-start" justify="space-between" height="auto">
                  <Name style={{fontSize: '12px', color: '#000'}} ><span style={{fontWeight: 500}} className='bold-print'>{sintese.cpf && 'Nome encontrado' || 'Empresa'}: </span>{sintese.cpf && sintese.nome || sintese.cnpj && this.renderRazaoSocial(sintese.pretendente_id)}</Name>
                  <Name style={{fontSize: '12px', color: '#000'}} ><span style={{fontWeight: 500}} className='bold-print'>Pretendente a: </span>{this.getApplicantIcon(sintese)}</Name>
                  <Name style={{fontSize: '12px', color: '#000'}} ><span style={{fontWeight: 500}} className='bold-print'>{sintese.cpf && 'CPF' || 'CNPJ'}:</span> {sintese.cpf || sintese.cnpj}</Name>
                  {this.renderLabelFcRenda('produtos' in sintese ? sintese.produtos.fcrenda : {})}
                  {sintese.cnpj && this.renderLabelFcEmpresa()}
                </ContentBox>
                {sintese.cpf && 
                  <ContentBox align="center" height="auto">
                    {this.getStatusIcon(sintese.parecer[0].parecer_key)}
                    <ParecerText style={{ fontWeight: 500, color: this.getColor(sintese.parecer[0].parecer_key)}} >{sintese.parecer[0].parecer}</ParecerText>
                  </ContentBox> || this.renderIconFcEmpresa(sintese.parecer[0].score_serasa)
                }
              </CardContainer>
              <TabLink className='tab-link-pj' style={{width: 'max-content'}} onClick={() => changeTab(index + 1)} >Ver laudo completo</TabLink>
            </SyntethicColumn>
          )
        })}
      </FlexContainer>
    );
  }
}


export default IndividualSynthesis;