import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ThemeProvider } from 'styled-components';

import {
  changeStage,
  logoutUser
} from "../auth/login/actions";
import { isUserAlreadyLogged } from "./../../routes/selectors";
import Login from "../auth/login/Login";
import { LoginContainer, LoginContainerTransparent, CardLogin, LinkExteral } from './home.styled';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';

/*
var institutionalStaticSiteBaseURL = process.env.REACT_APP_BASE_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  institutionalStaticSiteBaseURL = process.env.STATIC_SITE_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  institutionalStaticSiteBaseURL = process.env.STATIC_SITE_STAG;
}

console.log('institutionalStaticSiteBaseURL', institutionalStaticSiteBaseURL);

console.log('process.env', JSON.stringify(process.env));
*/


const lightBackgroundTheme = (themeProps) => ({
  ...themeProps,
  input_label_color: themeProps.color_gray,
  input_color: themeProps['color_dark-gray'],
});

class ButtonAppBar extends PureComponent {
  state = {
    isOpen: false,
    wasRedirected: false,
    showExpiredHashMessage: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { redirect } = nextProps;

    if (redirect && !prevState.wasRedirected) {
      const {
        stage,
        changeStage,
        logoutUser,
        isLogged
      } = nextProps;

      if (isLogged) {
        logoutUser();

        return null;
      }

      changeStage(stage);

      return { ...prevState, wasRedirected: true };
    }

    return null;
  }

  componentDidMount() {
    if (this.props.isLogged) {
      const {
        history: { replace }
      } = this.props;
      replace("/intranet/dashboard");
    }
  }

  redirect = routeEndpoint => {
    const {
      history: { replace }
    } = this.props;

    replace(routeEndpoint);
  };

  closeExpiredMessageModal = () => {
    this.setState({ showExpiredHashMessage: false });
    this.props.resetLogin();
  };

  render() {
    return (
      <ThemeProvider theme={lightBackgroundTheme}>
        <LoginContainer>
          <LoginContainerTransparent bgColor='#000' zIndex='0' />
          <BaseColumn sm={12} md={6} lg={6} xl={6} customstyle="display:flex;flex-direction: column;align-items: center;z-index: 1;">
            <CardLogin>
              <Login redirect={this.redirect} />
            </CardLogin>
            <LinkExteral href="https://www.fcanalise.com.br">Voltar para o site</LinkExteral>
          </BaseColumn>
        </LoginContainer>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeStage,
      logoutUser,
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isLogged: isUserAlreadyLogged(state),
  confirmationSuccess: state.login.userConfirmed
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonAppBar);
