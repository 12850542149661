import * as types from '../actions/types';


export default (state = {}, {type, payload}) => {
  switch(type){
    case types.GET_PRICES_INFOS_SUCCESS:
      return {
        ...payload
      }
    default:
      return state
  }
}