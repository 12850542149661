import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.svg`
  width: ${props => props.width || "100%"};
  fill: ${props => props.color || props.theme.color_purple};
`;

export default class RoundedMoney extends Component {
  render() {
    return (
      <Container
        {...this.props}
        id="Camada_1"
        data-name="Camada 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 99 99"
      >
        <title>ico-renda</title>
        <path
          d="M56.7,90V82.4c-4.3-.1-8.8-1.4-11.5-3.3l1.8-5a20,20,0,0,0,10.8,3.2c5.3,0,8.9-3.1,8.9-7.3s-2.9-6.7-8.5-8.9c-7.7-3-12.4-6.4-12.4-13,0-6.2,4.4-11,11.3-12.1V28.4h4.6v7.3a19.83,19.83,0,0,1,9.7,2.6l-1.9,4.9a18.08,18.08,0,0,0-9.4-2.5c-5.8,0-7.9,3.4-7.9,6.5,0,3.9,2.8,5.9,9.3,8.6,7.7,3.1,11.6,7,11.6,13.7,0,5.9-4.1,11.5-11.9,12.8V90Z"
          transform="translate(-10.5 -12.5)"
        />
        <path
          d="M60,12.5A49.5,49.5,0,1,0,109.5,62,49.55,49.55,0,0,0,60,12.5ZM60,107a45,45,0,1,1,45-45A44.94,44.94,0,0,1,60,107Z"
          transform="translate(-10.5 -12.5)"
        />
      </Container>
    );
  }
}
