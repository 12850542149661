import {
  SELECT_EMPLOYEE_CREDIT,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILED,
  ON_UPDATE_EMPLOYEE_CREDIT
} from './types';
import {
  updateEmployeeCredit, 
  getEmployees,
} from '../../../../services/customer/employees';
import { LOGOUT_SUCCESS } from '../../../auth/login/actions/types.js';
import { replace } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import { generalActionTypes, extractErrorMessages } from '../../../../utils/reduxUtils';
import { actionCreator } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const selectEmployeeCredit = id => actionCreator(SELECT_EMPLOYEE_CREDIT, id);

export const onUpdateEmployeeCredit = (id, credit) => actionCreator(ON_UPDATE_EMPLOYEE_CREDIT, { id, credit });

export const editEmployeeCredit = (id, credit) => {
  return async (dispatch, getState) => {
    const {
      login: { user: { token } },
      client: { client: { infos } },
    } = getState();

    try {
      const payload = await updateEmployeeCredit(token.access_token, infos.id, id, credit);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.error('Editar', extractErrorMessages(payload.data.message));
          dispatch(getClientEmployees());
        }

      } else {
        dispatch(getClientEmployees());
        toastr.success('Editar CREDITO', 'Credito editado com sucesso');
      }
    } catch (e) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};

export const getClientEmployees = (status = null) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } }, client: { client: { infos } }, clientsTable: { employees: { pagination } } } = getState();

    try {
      dispatch(actionCreator(GET_EMPLOYEES_REQUEST));
      const payload = await getEmployees(token.access_token, infos.id, pagination, status);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(GET_EMPLOYEES_FAILED, payload.data.message));
          toastr.error('Lista de Usuários', extractErrorMessages(payload.data.message));
        }
      } else {
        dispatch(actionCreator(GET_EMPLOYEES_SUCCESS, payload.data));
      }
    } catch (e) {
      dispatch(actionCreator(GET_EMPLOYEES_FAILED, e.message));
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu'));
    }
  }
};
