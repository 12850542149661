import React, { Component } from 'react'

export default class Security extends Component {
  render() {
    return (
    <svg viewBox="0 0 124 124" className="nano">
        <path className="white" d="M61.1,65.7c-5.6,0-10.2,4.6-10.2,10.2c0,2.8,1.2,5.5,3.2,7.4v9.2c0,3.9,3.2,7,7,7c3.9,0,7-3.2,7-7v-9.2 c2-1.9,3.2-4.6,3.2-7.4C71.4,70.2,66.8,65.7,61.1,65.7z M64.8,79.7c-1,0.9-1.5,2.2-1.5,3.5v9.3c0,1.1-0.9,2.1-2.1,2.1 c-1.1,0-2.1-0.9-2.1-2.1v-9.3c0-1.3-0.6-2.6-1.5-3.5c-1.1-1-1.7-2.4-1.7-3.8c0-2.9,2.4-5.3,5.3-5.3c2.9,0,5.3,2.4,5.3,5.3 C66.4,77.3,65.8,78.7,64.8,79.7z"/>
        <path className="white" d="M91.1,52.2h-7.4V32.5C83.7,20.1,73.6,10,61.1,10c-12.4,0-22.5,10.1-22.5,22.5v19.7h-7.4c-4.1,0-7.4,3.3-7.4,7.4 v45.9c0,4.1,3.3,7.4,7.4,7.4h59.9c4.1,0,7.4-3.3,7.4-7.4V59.6C98.5,55.6,95.2,52.2,91.1,52.2z M43.6,32.5c0-9.7,7.9-17.6,17.6-17.6 c9.7,0,17.6,7.9,17.6,17.6v19.7H43.6V32.5z M93.6,105.6c0,1.4-1.1,2.5-2.5,2.5H31.2c-1.4,0-2.5-1.1-2.5-2.5V59.6 c0-1.4,1.1-2.5,2.5-2.5h59.9c1.4,0,2.5,1.1,2.5,2.5V105.6z"/>
    </svg>
    )
  }
}
