import styled from 'styled-components';
import { PrimaryButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';

export default styled(PrimaryButton)`
  font-size: 12px;
  width: auto;
  min-width: 5vw;
  height: auto;
  padding: .3vw;
  margin-bottom: 10px;
`;

export const PDFButton = styled(PrimaryButton)`
  font-size: 12px;
  font-weight: 500;
  width: auto;
  min-width: 5vw;
  height: auto;
  padding: .2vw;
  margin-bottom: 10px;
  margin-right: 5px;
  background-color: transparent;
  border: 1px solid #152b4e;
  color: #152b4e;

  &:hover {
    background-color: white;
    border: 1px solid #152b4e;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ButtonEndWrapper = styled.div`
  position: relative;
  width: 10%;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
`;

export const RequestButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: fixed;
  right: 20px;
  bottom: 30px;
  font-size: .8rem;
  margin-top: .5rem;
  z-index: 1;
  width: 45px;
  height: 45px;
  border-radius: 45px;

  ${({customstyle}) => customstyle}
`;
