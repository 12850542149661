
export const onRegisterUser = (state) => ({
  ...state,
  requesting: true,
  error: undefined
});

export const onRegisterUserSuccess = (state) => ({
  ...state,
  requesting: false,
  error: false
});

export const onRegisterUserFailed = (state) => ({
  ...state,
  requesting: false,
  error: true
});

export const onGetUserData = (state) => ({
  ...state,
  userData: {
    ...state.userData,
    requesting: true,
    error: undefined,
  }
});

export const onGetUserDataSuccess = (state, payload) => ({
  ...state,
  userData: {
    ...state.userData,
    requesting: false,
    error: false,
    data: payload
  }
});

export const onGetUserDataFailed = (state) => ({
  ...state,
  userData: {
    ...state.userData,
    requesting: false,
    error: true,
  }
});
