import React, { PureComponent } from 'react';
import moment from 'moment';

import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import { PrimaryButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { Container, SpanTitle, GrayText } from '../form/fieldModal/formModalFields.styled';
import { formatToBRL } from '../../../../utils/formatter';

class InsuficientCreditsModal extends PureComponent {

	state = {
		copied: false
	}

  renderFooter = () => (
		null
    // <StyledRow padding='0 3em' justify="center">
    //   <PrimaryButton data-testid="go-back-button" onClick={this.props.closeModal} >Voltar</PrimaryButton>
    //   <PrimaryButton data-testid="go-back-button" onClick={this.props.submitVerLaudoAnterior} style={{marginLeft: 10}}>Ver o laudo anterior</PrimaryButton>
    // </StyledRow>
  )

  renderHeader = () => (
    <SpanTitle customstyle="margin: auto;">
      Pagamento por Pix
      <div>(ID COBRANÇA: {this.props.pix.cobranca_id})</div>
    </SpanTitle>
  )

	copyText = () => {
    navigator.clipboard.writeText(this.props.pix.pix_copia_cola);
		this.setState({copied: true})
		setTimeout(() => {
			this.setState({copied: false})
		}, 3000)
  }

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        size="lg"
        onClose={this.props.closeModal}
        footer={this.renderFooter()}
        header={this.renderHeader()}
      >

        <Container customstyle="text-align: center; margin: 20px auto;">
          <GrayText>
						<div style={{textAlign: 'left', color: '#152b4e'}}>
              {/* <label>Justificativa:</label> */}
              <div style={{marginBottom: 14}}>Para usar o "Pix Copia e Cola" copie o código e cole para completar a transação.</div>
              <div style={{backgroundColor: '#f2f2f2', padding: 14, wordBreak: 'break-all', borderRadius: 4}}>{this.props.pix.pix_copia_cola}</div>
              <PrimaryButton data-testid="go-back-button" onClick={this.copyText} disabled={this.state.copied} style={{margin: '0 auto', display: 'block', marginTop: 14}} >{this.state.copied === true ? 'Copiado' : 'Copiar código Pix'}</PrimaryButton>
              
              <div style={{marginTop: 14}}>Código válido até: <b>{moment(this.props.pix.vencimento).format('DD/MM/YYYY H:mm:ss')}</b></div>
              <div>Valor: <b>R$ {formatToBRL(this.props.pix.valor_final)}</b></div>

              <div style={{marginTop: 14}}>1. Abra o app do seu banco</div>
              <div>2. Escolha a opção Pagar com Copia e Cola</div>
              <div>3. Confirme as informações e finalize o pagamento</div>
              <div>4. Aguarde e em breve você será notificado</div>
            </div>
						<div style={{display: 'flex', marginTop: 30}}>
							<label style={{color: '#152b4e', marginBottom: 0}}>Enviar este código para o email:</label>
							<input style={{display: 'block', width: '340px', borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderBottom: '1px solid #b0afaf', outline: 'none', marginLeft: 8, marginRight: 12}} onChange={this.props.onChangeValueEmail} value={this.props.emailValue} />
							<PrimaryButton data-testid="go-back-button" onClick={() => this.props.postEnviarEmailPix()} disabled={!this.props.emailValue} style={{display: 'block', padding: '2px 14px', minWidth: '5rem'}} >Enviar</PrimaryButton>
						</div>
          </GrayText>
        </Container>

      </ModalContainer>
    );
  }
}

export default InsuficientCreditsModal;