import {
  GET_APPLICANT_INFOS_FAILED,
  GET_APPLICANT_INFOS_REQUEST,
  GET_APPLICANT_INFOS_SUCCESS,
  RESET_APPLICANT_INFOS,
  GET_REQUEST_INFOS,
  GET_REQUEST_INFOS_FAILED,
  GET_REQUEST_INFOS_SUCCESS,
  ADD_APPLICANT,
  ADD_APPLICANT_FAILED,
  ADD_APPLICANT_SUCCESS,
  UPDATE_APPLICANT_INFOS_REQUEST,
  UPDATE_APPLICANT_INFOS_FAILED,
  UPDATE_APPLICANT_INFOS,
  PREPARE_REPORT_FAILED,
  PREPARE_REPORT_REQUEST,
  PREPARE_REPORT_SUCCESS,
  PREPARE_REPORT_RESET,
  GET_USER_INFO
} from '../actions/types';

import {
  onGetApplicantRequesting,
  onGetApplicantSuccess,
  onGetApplicantFailed,
  onGetRequestFailed,
  onGetRequest,
  onGetRequestSuccess,
  onAddApplicant,
  onAddApplicantSuccess,
  onAddApplicantFailed,
  onUpdateApplicant,
  onUpdateApplicantSuccess,
  onUpdateApplicantFailed,
  onUpdateReportSettings,
  onUpdateReportSettingsSuccess,
  onUpdateReportSettingsFailed,
  onUpdateReportSettingsReset,
  onGetUserInfo
} from './reducers';

const initialState = {
  currentApplicant: undefined,
  isLoading: false,
  error: undefined,
  reportSettings: {
    isRequesting: false,
    error: undefined,
    saved: false
  },
  resume: {
    isLoading: false,
    error: undefined,
    infos: {}
  },
  addApplicant: {
    isLoading: false,
    error: undefined
  },
  editApplicant: {
    sending: false,
    sendingError: undefined
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_APPLICANT_INFOS_SUCCESS:
      return onGetApplicantSuccess(state, payload);
    case GET_APPLICANT_INFOS_FAILED:
      return onGetApplicantFailed(state);
    case GET_APPLICANT_INFOS_REQUEST:
      return onGetApplicantRequesting(state);
    case RESET_APPLICANT_INFOS:
      return initialState;
    case GET_REQUEST_INFOS:
      return onGetRequest(state);
    case GET_REQUEST_INFOS_FAILED:
      return onGetRequestFailed(state);
    case GET_REQUEST_INFOS_SUCCESS:
      return onGetRequestSuccess(state, payload);
    case ADD_APPLICANT:
      return onAddApplicant(state);
    case ADD_APPLICANT_FAILED:
      return onAddApplicantFailed(state);
    case ADD_APPLICANT_SUCCESS:
      return onAddApplicantSuccess(state, payload);
    case UPDATE_APPLICANT_INFOS_REQUEST:
      return onUpdateApplicant(state);
    case UPDATE_APPLICANT_INFOS_FAILED:
      return onUpdateApplicantFailed(state, payload);
    case UPDATE_APPLICANT_INFOS:
      return onUpdateApplicantSuccess(state);
    case PREPARE_REPORT_REQUEST:
      return onUpdateReportSettings(state);
    case PREPARE_REPORT_SUCCESS:
      return onUpdateReportSettingsSuccess(state);
    case PREPARE_REPORT_FAILED:
      return onUpdateReportSettingsFailed(state);
    case PREPARE_REPORT_RESET:
      return onUpdateReportSettingsReset(state);
    case GET_USER_INFO:
      return onGetUserInfo(state, payload)
    default:
      return state;
  }
};