import styled from 'styled-components';
import { Td, Th, ThCentered } from '../../components/shared/table/styled';

export const BalanceCell = styled.div`
  width: 7vw;
`;

export const CreditInput = styled.input`
  text-align: center;
  border: ${props => props.editMode ? 'inherit' : 0};
  background-color: ${props => props.editMode ? props.theme.color_white : 'transparent'};
  width: 10vw;
`

export const TypeTd = styled(Td)`
  text-align: center;
  width: 8.5vw !important;
`;

export const AccessTd = styled(Td)`
  width: 3.3vw !important;
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};
`;

export const NameTd = styled(Td)`
  width: 14vw !important;
`;

export const FunctionTd = styled(Td)`
  width: 8.4vw !important;
  padding-left: 10px !important;
`;

export const StatusTd = styled(Td)`
  width: 130px !important;
`;

export const EditTd = styled(Td)`
  width: 4vw !important;
`;

export const ViewTd = styled(Td)`
  width: 4vw !important;
`;

export const BalanceTd = styled(Td)`
  width: 7.3vw !important;
`;

export const CreditTd = styled(Td)`
  width: 10vw !important;
`;

export const EditCreditTd = styled(Td)`
  width: 4vw !important;
`;

/**
 * Tags th
 */

export const TypeTh = styled(Th)`
  width: 8.5vw !important;
`;

export const AccessTh = styled(Th)`
   width: 3.3vw !important;
`;

export const NameTh = styled(Th)`
  width: 15vw !important;
  padding-left: 20px !important;
`;

export const FunctionTh = styled(Th)`
  width: 8.4vw !important;
`;

export const StatusTh = styled(ThCentered)`
  width: calc(9.7vw + 2px) !important;
`;

export const EditTh = styled(ThCentered)`
  width: 4vw !important;
`;

export const ViewTh = styled(ThCentered)`
  width: 4vw !important;
`;

export const BalanceTh = styled(Th)`
  width: 7.3vw !important;
`;

export const CreditTh = styled(Th)`
  width: 10vw !important;
`;

export const EditCreditTh = styled(Th)`
  width: 4vw !important;
`;

