import React, { Component, Fragment } from 'react';
import { BorderedButton, UnborderedButton, Tr, Td } from '../../components/shared/customTable.styled';
import CreditBar from './components/CreditBar';
import { HeaderBar, HeaderText } from './components/creditBar.styled';
import Pen from '../../components/shared/treatedIcons/Pen';
import { IconCell } from '../../components/shared/table/styled';
import { CancelAction, ConfirmAction, RowActionButton } from '../../components/shared/table/editableRow.style';
//import { mapStateToProps } from '../operational/request/resume/ModalDeleteLastProduct/ModalDeleteLastProduct';
import { connect } from "react-redux";
import { selectEmployeeCredit, onUpdateEmployeeCredit } from './actions/employees';
import { bindActionCreators } from "redux";
import { CreditTd, EditCreditTd, CreditInput } from './employeesTable.styles';
import { changeEmployeeCredit } from '../customers/register/actions';




class UsersRow extends Component {

  
renderHeaderCredit = (available, used) => (
    <HeaderBar>
      <HeaderText>usado R$ {used},00</HeaderText>
      <HeaderText>Disponivel R$ {available},00</HeaderText>
    </HeaderBar>
  )

  renderBorderedButton = () => {
    const { type, onClickType } = this.props;

    return (
      <BorderedButton onClick={onClickType}>{type.toLowerCase()}</BorderedButton>
    )
  }

  renderDisabledButton = () => {
    const { type } = this.props;

    return (
      <UnborderedButton disabled={true}>{type.toLowerCase()}</UnborderedButton>
    )
  }

  

  renderBorderedButtonStatus = () => {
    const { onClickStatus, status } = this.props;

    let colorButton = "";
    switch (status) {
      case "ATIVO":
        colorButton = "color_green";
      break;
      case "SUSPENSO":
        colorButton = "color_systemic_feedback_regular";
      break;
      default:
        colorButton = "color_red";
      break;
    }

    return (
      <BorderedButton onClick={onClickStatus} color={colorButton}>{status}</BorderedButton>
    )
  }

  renderUnborderedButtonStatus = () => {
    return null
  }

  handleIconCellRender = () => {
    const { disableCredit, editMode } = this.props;

    if (editMode) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '50px', width: '50px' }}>
          <RowActionButton onClick={this.onConfirmCredit}>
            <ConfirmAction />
          </RowActionButton>

          <RowActionButton onClick={this.disableEditMode}>
            <CancelAction />
          </RowActionButton>
        </div>
      );
    }

    return (
      <IconCell disabled={disableCredit ? disableCredit : undefined} size="auto" onClick={this.onSelectCreditToEdit}>
        <Pen fill="color_purple" width="17px" height="17px" />
      </IconCell>
    );
  }

  onConfirmCredit = (e) => {
    e.preventDefault();
    const { id, changeEmployeeCredit, selectEmployeeCredit } = this.props;
    changeEmployeeCredit(id, this.props.credit);
    selectEmployeeCredit(undefined);
  }

  disableEditMode = (e) => {
    e.preventDefault();
    const { selectEmployeeCredit, getUsers } = this.props;
    selectEmployeeCredit(undefined);
    getUsers();
  }

  onSelectCreditToEdit = (e) => {
    e.preventDefault();
    const { selectEmployeeCredit, id } = this.props;
    selectEmployeeCredit(id);
  }

  changeCredit = (e) => {
    const { target: { value } } = e;
    const { id , onUpdateEmployeeCredit } = this.props;
    onUpdateEmployeeCredit(id, value);
  }

  render() {
    console.log(this.props.userId, this.props.loggedId)
    const {onClickClock, nome, funcao, typeUser, credito, usedCreditPercentage, alignIcon, Icon, onClickEdit, condicao , credito_utilizado, credito_disponivel } = this.props;
    
    let ButtonType;
    let ButtonStatus;
    
    if(typeUser === "ADICIONAL") {
      ButtonType = this.renderDisabledButton()
      ButtonStatus = this.renderUnborderedButtonStatus()
      
    }
    else if(this.props.userId === this.props.loggedId)  {
      ButtonType = this.renderBorderedButton()
      ButtonStatus = this.renderUnborderedButtonStatus()
    
    } else {
      ButtonType = this.renderBorderedButton()
      ButtonStatus = this.renderBorderedButtonStatus()
    }
    
  return (
    <Fragment>
      <Tr clickable>
        <Td width="7rem" maxWidth="7rem">
          {ButtonType}
        </Td>
        <Td width="3rem" maxWidth="3rem" align={alignIcon}><Icon onClick={onClickClock} width="2rem" id={nome.replace(/ /g, '_')} /></Td>
        <Td onClick={onClickEdit} minWidth="205px" uppercase>{nome}</Td>
        <Td onClick={onClickEdit} minWidth="205px" uppercase>{funcao}</Td>
        {
          (condicao === "POSPAGO" && typeUser === "MASTER") && 
            (
              <div>
                <Td width="7rem" maxWidth="7rem" className="editar-creditos"> {this.handleIconCellRender()}</Td>
                <CreditTd>
                  <CreditInput
                    name="credit"
                    type="text"
                    value={credito}
                    onChange={this.changeCredit}
                    disabled={!this.props.editMode}
                  />
                </CreditTd>
                

                <Td onClick={onClickEdit} width="7rem" maxWidth="7rem">
                  {/* {this.renderHeaderCredit(credito_disponivel, credito_utilizado)}
                    <CreditBar availableCredit={credito} usagePercentage={usedCreditPercentage} /> */}
                    {credito_utilizado}
                </Td>
                <Td onClick={onClickEdit} width="7rem" maxWidth="7rem">
                  {/* {this.renderHeaderCredit(credito_disponivel, credito_utilizado)}
                    <CreditBar availableCredit={credito} usagePercentage={usedCreditPercentage} /> */}
                    {credito_disponivel}
                </Td>
              </div>
            )
        }
        <Td width="7rem" maxWidth="7rem" textAlign="center">{ButtonStatus}</Td>
      </Tr>
    </Fragment>
  ) 
}
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      selectEmployeeCredit,
      onUpdateEmployeeCredit,
      changeEmployeeCredit
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  editMode: state.clientsTable.employees.editableRow === ownProps.id,
});

export default connect(mapStateToProps,mapDispatchToProps)(UsersRow)


