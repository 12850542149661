import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
	margin: auto;
	padding-top: 15px;
`;

export const TitleModal = styled.h5`
	color: ${props => props.theme.color_purple};
	font-weight: 400;
`;

export const TextModal = styled.p`
  font-size: 14px;
  color: ${props => props.theme.color_gray};
	text-align: center;
	
	@media (max-width: 800px) {
		font-size: 12px;
	}
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    button {
			font-size: .88rem;
			width: 77px;
			min-width: 77px;
			margin: 0 8.5px;
			padding: 0;
    }
  }
`;
