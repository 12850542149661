import React from 'react';
import { Route, Switch } from 'react-router-dom'

import EditCustomer from '../../pages/customer/CustomerData/EditCustomer'
import ComercialData from '../../pages/customer/ComercialData/ComercialDataForm';
import Recomendations from '../../pages/customer/Recomendations/EditRecomendation'
import UsersTable from '../../pages/users/UsersTable';
import UsersForm from '../../pages/users/form/UsersForm';

export default () => (
  <Switch>
    <Route exact path="/intranet/cadastro/dados-cliente" component={EditCustomer} />
    <Route exact path="/intranet/cadastro/dados-comerciais" component={ComercialData} />
    <Route exact path="/intranet/cadastro/usuarios" component={UsersTable} />
    <Route exact path="/intranet/cadastro/usuarios/:process?/:id?" component={UsersForm} />
    <Route exact path="/intranet/cadastro/customizar-recomendacoes" component={Recomendations} />
  </Switch>
)