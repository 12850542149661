import React from 'react';
import { reduxForm, Field } from 'redux-form';
import inputHandler from '../../../components/shared/inputHandler';
import { StyledRow, FullColumn } from '../../../components/shared/sharedStyles.styles';
import { StyledLabel } from '../../../components/shared/Inputs.styles';
import { reasonField } from './modalStatusFields';
import { StyledSelect } from './users.styled';

const renderSelectField = (field) => {
  return (
    <StyledSelect {...field.input}>
      <option value="SELECIONE">SELECIONE</option>
      <option value="ATIVO">ATIVO</option>
      <option value="SUSPENSO">SUSPENSO</option>
      <option value="BLOQUEADO" >BLOQUEADO</option>
    </StyledSelect>
  )
}

const StatusModalForm = ({ oldStatus, options }) => {
  return (
    <FullColumn customStyle={{ display: 'flex', flexDirection: 'column' }}>
      <StyledRow>
        <Field name="statusSelect" component={renderSelectField} />
      </StyledRow>
      <StyledRow>
        <FullColumn customStyle={{ display: 'flex', flexDirection: 'column' }} >
          <StyledLabel customStyle={{ left: '15px', top: '2rem', position: 'relative' }}>
            Qual o motivo da mudança de status?
          </StyledLabel>
          {inputHandler(reasonField)}
        </FullColumn>
      </StyledRow>
    </FullColumn>
  );
}

export default reduxForm(
  {
    form: 'stateModalForm',
    enableReinitialize: true
  }
)(StatusModalForm);
