import React, { PureComponent } from 'react';
import TextInputContainer from './TextInputContainer';
import { InputColContainer, StyledErrorSpan } from './Inputs.styles';
import { shouldShowErrorMessge, getFieldUnderlineColor } from '../../utils/validations';
import { withTheme } from 'styled-components';

export default (type) => (WrappedInput) => {
  class InputWithValidation extends PureComponent {
    state = {
      showError: false,
      underlineColor: this.props.isRequired && !this.props.theme.disabled ? 'color_orange-dark' : 'input_underline_default_color'
    }


    static getDerivedStateFromProps(nextProps, prevState) {
      const { meta, isRequired, hasValidation, input: { value }, theme: { disabled } } = nextProps;
      const { error, warning, touched } = meta;

      if (disabled) {
        return null;
      }

      if (value !== '' || touched) {
        let underlineColor = prevState.underlineColor;
        let showError = prevState.showError;

        if (!isRequired) {

          if (hasValidation) {
            underlineColor = getFieldUnderlineColor(isRequired, warning, hasValidation, true);
            showError = shouldShowErrorMessge(isRequired, warning, hasValidation, true);
          }

        } else {
          underlineColor = getFieldUnderlineColor(isRequired, error, hasValidation, true);
          showError = shouldShowErrorMessge(isRequired, error, hasValidation, true);
        }

        return { underlineColor, showError };
      }


      return null;
    }


    getColor = () => {
      const { meta, isRequired, hasValidation } = this.props;
      const { error, warning } = meta;
      let color;

      if (!isRequired && hasValidation) {
        color = getFieldUnderlineColor(isRequired, warning, hasValidation, true);
      }

      color = getFieldUnderlineColor(isRequired, error, hasValidation, true);

      return color;
    }

    isErrorVisible = () => {
      const { meta, isRequired, hasValidation } = this.props;
      const { error } = meta;
      const showError = shouldShowErrorMessge(isRequired, error, hasValidation, true);

      return showError;
    }

    onBlur = (event) => {
      event.preventDefault();
      const showError = this.isErrorVisible();
      const color = this.getColor();

      this.setState({ showError, underlineColor: color });

    }

    onChange = (event) => {
      event.preventDefault();
      const { target: { value } } = event;
      const { input: { onChange } } = this.props;
      onChange(value);
    }

    handleWrappedInputProps = () => {
      const { input, isRequired, placeholder, theme: { disabled } } = this.props;
      const { value, name } = input;
      const props = {
        id: name,
        name,
        value,
        onChange: this.onChange,
        onBlur: this.onBlur,
        placeholder: placeholder,
        required: isRequired,
        disabled
      }

      if (type === 'maskedText') {
        return {
          ...props,
          mask: this.props.regex,
          moneyMask: this.props.moneyMask
        }
      }

      if (type === 'select') {
        return {
          ...props,
          options: this.props.options
        }
      }

      return props;
    }

    render() {
      const { input, meta, label, size, disabled } = this.props;
      return (
        <TextInputContainer label={label} htmlFor={input.name} size={size} type={type}>
          <InputColContainer
            underlinecolor={this.state.underlineColor}
            disabled={disabled}
          >
            <WrappedInput {...this.handleWrappedInputProps()} />
          </InputColContainer>
          {
            this.state.showError &&
            <StyledErrorSpan>{meta.error}</StyledErrorSpan>
          }

          {
            (meta.warning !== undefined) &&
            <StyledErrorSpan>{meta.warning}</StyledErrorSpan>
          }
        </TextInputContainer>
      );
    }
  };

  return withTheme(InputWithValidation);
};