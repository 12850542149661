import { formatPhone, formatCPF, formatCNPJ } from '@bit/wavfichacerta.fichacerta.utils.formatters';

const getPhones = (phonesObj) => {
  const principal = "principal" in phonesObj? phonesObj.principal : undefined;
  const adicional = "adicional" in phonesObj? phonesObj.adicional : undefined;
  return {
    telefone: (typeof principal === "object" && "numero" in principal)? formatPhone(principal.numero) : "",
    telefone_adicional: (typeof adicional === "object" && "numero" in adicional)? formatPhone(adicional.numero) : ""
  };
}

const getAditionalContacts = (data) => {
  const operational = "operacional" in data.contatos? data.contatos.operacional : {};
  const operacionalPhones = "telefones" in operational? operational.telefones : {};
  const financial = "financeiro" in data.contatos? data.contatos.financeiro : {};
  const financialPhones = "telefones" in financial? financial.telefones : {};
  const direction = "diretoria" in data.contatos? data.contatos.diretoria : {};
  const directionPhones = "telefones" in direction? direction.telefones : {};
  
  return {
    operacional_nome: operational.nome || null,
    operacional_email: operational.email || null,
    operacional_funcao: operational.funcao || null,
    operacional_telefone: "principal" in operacionalPhones? formatPhone(operacionalPhones.principal.numero) : null,
    financeiro_nome: financial.nome || null,
    financeiro_email: financial.email || null,
    financeiro_funcao: financial.funcao || null,
    financeiro_telefone: "principal" in financialPhones? formatPhone(financialPhones.principal.numero) : null,
    diretoria_nome: direction.nome || null,
    diretoria_email: direction.email || null,
    diretoria_funcao: direction.funcao || null,
    diretoria_telefone: "principal" in directionPhones? formatPhone(directionPhones.principal.numero) : null,
  }
}

const getInitialDataPF = (data) => ({
  ...data.endereco,
  ...getPhones(data.telefones),
  ...getAditionalContacts(data),
  nome: data.nome,
  identidade: data.identidade,
  orgao_expedidor: data.orgao_expedidor,
  cpf: formatCPF(data.cpf),
  tipo: data.tipo_pessoa_fisica,
  tratamentos: data.tratamento,
  creci: data.creci,
  website: data.website
});

const getInitialDataPJ = (data) => ({
  ...data.endereco,
  ...getPhones(data.telefones),
  ...getAditionalContacts(data),
  nome: data.nome,
  cnpj: formatCNPJ(data.cnpj),
  creci: data.creci,
  website: data.website
});

export const getInitialData = (entity, data) => {
  if (entity === "PF") {
    return getInitialDataPF(data);
  }
  return getInitialDataPJ(data);
}
