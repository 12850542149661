import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getRequirements, sendFileUploadFcCompleta, removeFileUploadFcCompleta } from '../form/actions';
import { addApplicantToRequest } from './actions';
import { getClientProducts } from '../actions';
import FormModalFields from '../form/fieldModal/FormModalFields';
import { addApplicantSuccessStatus, addApplicantLoadingFlag, getTenancyType } from './selectors';
import { getAllValues } from '../form/selectors';

import ApplicantForm from '../form/Applicant/ApplicantForm';
import applicantFields from '../form/Applicant/fields';

import IncomeForm from '../form/IncomeSection/IncomeForm';
import incomeFields from '../form/IncomeSection/fields';

import { PageContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';
import Header from '../../../../components/Header';
import { getApplicantObj, getApplicantPJObj } from '../../../../utils/operational/requests/requestFormHelper';

import RequestStepButton from '../form/RequestStepButton';
import RequestFormContainer from '../form/RequestFormContainer';
import EntityChoiceContent from '../../../../components/Operational/EntityChoiceContent';
import { StyledMainContainer } from '../../../../components/shared/entity-choice/entityChoice.styled.js';
import { Main, MainContainer } from './RequestResume';

import Tour from 'reactour'
import axios from 'axios';

const Forms = ["applicantForm", "incomeForm"];

const steps_selecionar_produto = [
  {
    selector: '.container-select-type',
    content: 'O produto Pessoa Física já vem selecionado, mas caso deseje inserir uma consulta PJ basta clicar em Pessoa Jurídica.',
  },
  {
    selector: '.container-cards-products',
    content: 'Selecione o produto disponível e avance',
  },
  // ...
]

const steps_form_pf = [
  {
    selector: '.Pretendente',
    content: 'Informe qual a condição do pretendente, se é o inquilino ou o fiador da locação',
  },
  {
    selector: '.Nome',
    content: 'Não se preocupe em inserir o nome completo, o sistema vai subscrever essa informação, apenas o 1° nome para facilitar sua identificação é o suficiente.',
  },
  {
    selector: '.CPF',
    content: 'CPF é a chave de busca para nossa análise',
  },
  {
    selector: '.PERFIL',
    content: <span>Você pode preencher com as informações iniciais que possui do seu pretendente, ou mesmo escolher a opção <b>renda não informada</b>. E fique tranquilo, este campo é editável.</span>,
  },
  {
    selector: '[data-testid="StepButton"]',
    content: 'Após preencher os dados, clique em Salvar',
  },
]

const steps_form_pj = [
  {
    selector: '.Pretendente',
    content: 'Este campo vem preenchido como Outros automaticamente, pois o algoritmo FC é exclusivo para análise Pessoa Física, e, portanto, não é possível mudar a condição para inquilino ou fiador no sistema.',
  },
  {
    selector: '.Nome',
    content: 'Não se preocupe em preencher o nome completo da empresa, buscaremos essa informação nos bancos de dados externos.',
  },
  {
    selector: '.CNPJ',
    content: 'CNPJ é a chave de busca em nossa consulta',
  },
  {
    selector: '[data-testid="StepButton"]',
    content: 'Salve a sua solicitação para dar andamento às buscas ',
  },
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class RequestForm extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      productsChoice: true,
      products: [1],
      entity: undefined,
      tourOpen: false,
      tourId: null
    }

    this.applicantForm = React.createRef();
    this.locationForm = React.createRef();
    this.incomeForm = React.createRef();
    this.container = React.createRef();
  }

  componentDidMount() {
    const { state } = this.props.location;
    const { clientId } = state;

    this.props.getClientProducts(clientId);
    this.getCheckOnboard();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.entity !== undefined && this.state.entity !== prevState.entity) {
      const entity = (this.state.entity === "FISICA")? "PF" : "PJ";
      this.props.getRequirements(entity);
    }
    return null;
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'selecionar-produto') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      let page = 'selecionar-produto'
      const { entity } = this.state;

      switch(entity) {
        case 'FISICA':
          page = 'form-pf-adicionar-pretendente'
          break;
        case 'JURIDICA':
          page = 'form-pj-adicionar-pretendente'
          break;
      }

      const data = {
        email: this.props.login.user.usuario.email,
        page,
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      let page = 'selecionar-produto'
      const { entity } = this.state;

      switch(entity) {
        case 'FISICA':
          page = 'form-pf-adicionar-pretendente'
          break;
        case 'JURIDICA':
          page = 'form-pj-adicionar-pretendente'
          break;
      }

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  get changedValues() {
    const changed = Forms.reduce((acc, form) => {
      const { changedValues } = this.props[form];

      return [...acc, changedValues];
    }, []);

    return changed;
  }

  mountAddApplicantObj = () => {
    const { products, entity } = this.state;

    const requestObj = {
      produtos: products,
      pretendente: entity === 'FISICA'? getApplicantObj(...this.changedValues) : getApplicantPJObj(...this.changedValues)
    };
    
    return requestObj;
  }

  sendRequestRegistration = () => {
    const { location: { state: { requestId, locacao, applicants } }, addApplicantToRequest, modulo_principal } = this.props;
    const { products } = this.state
    const requestObj = this.mountAddApplicantObj();
    let moveForLocationAddApplicant = false;
    this.container.current.unBlock();
    let arquivos = undefined
    let tipo_renda = undefined
    if(!locacao.tipo_imovel && (products[0] === 1 || products[0] === 3 || products[0] === 4)) {
      moveForLocationAddApplicant = true;

      this.props.history.push({
        pathname: '/intranet/operacional/solicitacao/resumo/locacao',
        state: { request: requestId, applicants, moveForLocationAddApplicant, data: requestObj }
      })
      return null
    }

    if(products[0] === 7 || products[0] === 8) {
      if(!modulo_principal.length) {
        alert('Necessário anexar documento')
        return;
      }
      if(products[0] === 7) {
        tipo_renda = 'principal'
      }
      if(modulo_principal.length) {
        arquivos = modulo_principal.map(file => {
          return {
            id: file.id,
            tipo_renda: tipo_renda
          }
        })
      }
    }

    addApplicantToRequest({...requestObj, documentos: arquivos}, requestId);
  }

  rollbackToProducts = () => {
    const { location, history } = this.props;
    history.replace({
      pathname: '/intranet/operacional/solicitacao/resumo/adicionar-pretendente',
      state: location.state
    });
  }

  renderStepButton = () => {
    const { productsChoice } = this.state;
    if (!productsChoice) {
      return (
        <RequestStepButton
          showBackButton
          withoutTenancy
          goBack={this.rollbackToProducts}
          forms={Forms}
          step={Forms.length}
          sending={this.props.isLoading}
          sendRequestRegistration={this.sendRequestRegistration}
          usedFields={
            {
              ...applicantFields,
              ...incomeFields
            }
          }
        />
      );
    }

    return null;
  }

  changeMode = (products, entity) => {
    this.setState({ products, entity, productsChoice: false });
  }

  goBackToProducts = () => {
    this.setState({ productsChoice: true, entity: undefined });
  }

  rollbackFromProducts = () => {
    const { location, history } = this.props;
    history.replace({
      pathname: '/intranet/operacional/solicitacao/resumo',
      state: { request: location.state.requestId }
    });
  }

  handleContent = () => {
    const { productsChoice, entity, products } = this.state;
    const { location: { state } } = this.props;
    console.log('lolo1', this.props)
    console.log('lolo2', this.state)
    const entityLabel = entity === "FISICA"? "PF" : "PJ";
    if (productsChoice) {
      return (
        <StyledMainContainer>
          <EntityChoiceContent
            showGoBack
            onGoBack={this.rollbackFromProducts}
            goForward={this.changeMode}
            list={this.props.list}
          />
        </StyledMainContainer>
      );
    }

    return (
      <RequestFormContainer
        ref={this.container}
        history={this.props.history}
        renderStepButton={this.renderStepButton}
        onNavigateBack={this.goBackToProducts}
        editMode={true}
      >
        <ApplicantForm tenancyType={state.tipo_imovel} ref={this.applicantForm} entity={entityLabel} products={[1]} />
        {(entityLabel === 'PF' && products[0] !== 5 && products[0] !== 7) && <IncomeForm ref={this.incomeForm} entity={entityLabel} products={[1]} />}
        {(entityLabel === 'PF' && products[0] === 7) && <IncomeForm ref={this.incomeForm} entity={entityLabel} products={products} clientId={this.props.location.state.clientId} moduloAnexoCreateLocationFcCompleta={true} sendFileUpload={this.props.sendFileUploadFcCompleta} removeFileUpload={this.props.removeFileUploadFcCompleta} />}
        {(entityLabel === 'PJ' && products[0] === 8) && <IncomeForm ref={this.incomeForm} entity={entityLabel} products={products} clientId={this.props.location.state.clientId} moduloAnexoCreateLocationFcCompleta={true} sendFileUpload={this.props.sendFileUploadFcCompleta} removeFileUpload={this.props.removeFileUploadFcCompleta} />}
        <FormModalFields />
      </RequestFormContainer>
    );
  }

  render() {
    const breadcrumb = [
      { link: '', label: 'operacional'},
      { link: '/intranet/operacional/solicitacao', label: 'Locações' },
      {
        link: {
          pathname: '/intranet/operacional/solicitacao/resumo',
          state: {request: this.props.location.state && this.props.location.state.requestId}
        },
        label: 'resumo'
      }
    ];

    const { entity } = this.state;
    let steps = steps_selecionar_produto;

    switch(entity) {
      case 'FISICA':
        steps = steps_form_pf;
        break;
      case 'JURIDICA':
        steps = steps_form_pj;
        break;
    }

    return (
      <Main>
        <Header
          title="Incluir Pretendente"
          actualLink="Incluir"
          breadcrumb={breadcrumb}
          subtitle={this.props.customer}
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <MainContainer>
          {this.handleContent()}
        </MainContainer>
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
          startAt={0}
        />
      </Main>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getRequirements,
  addApplicantToRequest,
  getClientProducts,
  sendFileUploadFcCompleta,
  removeFileUploadFcCompleta,
}, dispatch);

const mapStateToProps = (state, ownProps) => (
  {
    ...ownProps,
    isLoading: addApplicantLoadingFlag(state),
    success: addApplicantSuccessStatus(state),
    list: state.request.products.list,
    tenancyType: getTenancyType(state),
    incomeForm: getAllValues(state, "incomeForm"),
    applicantForm: getAllValues(state, "applicantForm"),
    modulo_principal: state.requestForm.modulo_principal,
    modulo_secundario: state.requestForm.modulo_secundario,
    login: state.login,
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(RequestForm);
