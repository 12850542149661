import React, { PureComponent } from 'react';

import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import { PrimaryButton, SecundaryButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { Container, SpanTitle, GrayText } from '../form/fieldModal/formModalFields.styled';

class RemoveApplicantModal extends PureComponent {

  renderFooter = () => (
    <StyledRow padding='0 3em' justify="space-around">
      <PrimaryButton data-testid="go-back-button" onClick={this.props.closeRemoveApplicantModal} >Não</PrimaryButton>
      <SecundaryButton data-testid="send-data-button" onClick={this.props.removeApplicant}>Sim</SecundaryButton>
    </StyledRow>
  )

  renderHeader = () => (
    <SpanTitle customstyle="margin: auto;">
      EXCLUIR <br/>
      PRETENDENTE
    </SpanTitle>
  )

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        size="md"
        onClose={this.props.closeRemoveApplicantModal}
        footer={this.renderFooter()}
        header={this.renderHeader()}
      >

        <Container customstyle="text-align: center; margin: 20px auto;">
          <GrayText>
            Tem certeza que deseja excluir este pretendente?
            <br />
            Todos os dados inseridos serão perdidos
          </GrayText>
        </Container>

      </ModalContainer>
    );
  }
}

export default RemoveApplicantModal;