import { simpleRequiredSelect, simpleRequiredText } from '../../../components/shared/fields'

export const selectField = (oldStatus, options) => simpleRequiredSelect({
  name: 'selectStatus',
  label: 'WITHOUT_LABEL',
  validate: (value) => {
    if (value !== oldStatus) {
      return undefined;
    }

    return 'O status não é diferente do já existente';
  },
  options,
  size: 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4'
});

export const reasonField = {
  ...simpleRequiredText({
    name: 'statusReason',
    label: 'WITHOUT_LABEL',
    warn: () => undefined,
    size: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'
  }),
  type: 'textarea'
};
