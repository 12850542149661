import {
  EDIT_PRODUCTS_ROW,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILED,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  RESET_REDUX_PRODUCTS
} from '../../types';

import {
  onEditProductRow,
  onGetProductsSuccess,
  onGetProductsFailed,
  onGetProducts,
  onAddProduct,
  onRemoveProduct,
  onUpdateProduct,
  onResetReduxProducts
} from './reducers';

const initialState = {
  requesting: false,
  error: undefined,
  editableRow: undefined,
  list: [],
  pagination: {
    sortBy: 'nome'
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case EDIT_PRODUCTS_ROW:
      return onEditProductRow(state, payload);
    case GET_PRODUCTS_REQUEST:
      return onGetProducts(state);
    case GET_PRODUCTS_SUCCESS:
      return onGetProductsSuccess(state, payload);
    case GET_PRODUCTS_FAILED:
      return onGetProductsFailed(state, payload);
    case ADD_PRODUCT:
      return onAddProduct(state, payload);
    case DELETE_PRODUCT:
      return onRemoveProduct(state, payload);
    case UPDATE_PRODUCT:
      return onUpdateProduct(state, payload);
    case RESET_REDUX_PRODUCTS:
        return onResetReduxProducts(state);
    default:
      return state;
  }
};