import React from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { HalfColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  ContainerGray,
  AnalythicList,
  ColoredSpan,
  AnalythicLine,
  LineTitle,
  RiskText
} from '../../analythic.styled';
import { ColoredColumn, TableTitle, Line } from '../styled';
import CheckConstraintTableCards from '../CheckConstraintTableCards';

import { checkResume, getResultObj } from '../products/fcAnalise';
// import Table from './Table';

const AvoidConstraints = ({ dataSource }) => {

  // const renderDataLine = ({ title, data }) => (
  //   <AnalythicLine key={(title + data)}>
  //     <LineTitle maxWidth="205px">{title}</LineTitle>
  //     <ColoredSpan>{data}</ColoredSpan>
  //   </AnalythicLine>
  // );

  // const renderResumeLines = (keys) => {
  //   const dataGiven = checkResume(dataSource, keys);
  //   return dataGiven.map((line) => renderDataLine(line));
  // };

  const source = getResultObj(dataSource, 'restricoes_financeiras');

  // const details = window.matchMedia('(max-width: 800px)').matches ? <CheckConstraintTableCards lines={"cheques" in source && source.cheques.detalhes} /> : <Table lines={"cheques" in source && source.cheques.detalhes} />

  if ((source !== undefined) && ("protestos" in source && source.protestos.info !== 'CONSTA') && ("cheques" in source && source.cheques.info !== 'CONSTA') && ("pendencias" in source && source.pendencias.info !== 'CONSTA')) {
    return (
      <ContainerGray margin="0 0 8px 0">
        <StyledRow customstyle="height: auto; justify-content: normal;" className='font-13-print'>
          <HalfColumn>
            <TableTitle style={{color: '#000'}}>PENDÊNCIAS FINANCEIRAS, CHEQUES e PROTESTOS</TableTitle>
          </HalfColumn>

          <HalfColumn>
            <TableTitle style={{color: '#000'}}>NÃO ENCONTRADO*</TableTitle>
          </HalfColumn>
          {/* <RiskText style={{fontStyle: 'italic', fontSize: 13}}>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de apontamentos do pesquisado.</RiskText> */}
        </StyledRow>
      </ContainerGray>
    )
  }

  return null

  // return (
  //   <ColoredColumn>
  //     <TableTitle>CHEQUES SEM FUNDOS</TableTitle>
  //     <StyledRow align="initial" margin="0 0 .5rem 0">
  //       <HalfColumn>
  //         <ContainerGray>
  //           <AnalythicList>
  //             {renderResumeLines(['total_ocorrencias', 'valor_total_pendencia'])}
  //           </AnalythicList>
  //         </ContainerGray>
  //       </HalfColumn>
  //       <HalfColumn>
  //         <ContainerGray>
  //           <AnalythicList>
  //             {renderResumeLines(['data_ultima_ocorrencia'])}
  //           </AnalythicList>
  //         </ContainerGray>
  //       </HalfColumn>
  //     </StyledRow>
  //     <Line />
  //     <TableTitle>DETALHAMENTO (MAIS RECENTES - ATÉ 5)</TableTitle>
  //     {details}
  //   </ColoredColumn>
  // )
};

export default AvoidConstraints;
