import React from 'react';
import styled from 'styled-components';

const StyledPath = styled.path`
  fill: ${props => props.theme[props.fill || 'color_white']};
`;

const StyledSVG = styled.svg`
  width: ${props => props.width};
  height: ${props => props.height};
`;

const Minus = ({ fill, width, height }) => (
  <StyledSVG width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.77 8.77">
    <g id="_8" data-name=" 8">
      <StyledPath fill={fill} d="M7.78,5.53H3.91a.27.27,0,0,0,0,.55H7.78a.27.27,0,0,0,0-.55Z" transform="translate(-1.46 -1.4)"/>
      <StyledPath fill={fill} d="M5.85,1.4a4.39,4.39,0,1,0,4.39,4.39A4.39,4.39,0,0,0,5.85,1.4Zm0,8.22A3.84,3.84,0,1,1,9.68,5.79,3.84,3.84,0,0,1,5.85,9.62Z" transform="translate(-1.46 -1.4)"/>
    </g>
  </StyledSVG>
);

export default Minus;