import { instance as axios } from './axiosInstance';

const END_POINT = "/api/administrador/data"

const getHeader = (token) => (
  {
    'Authorization': `Bearer ${token}`
  }
);


export const getFormRequirements = (token) => {
  const headers = getHeader(token)
  return axios.get(END_POINT, { headers });
};
