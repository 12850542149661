import React, {PureComponent} from 'react';
import { SubmitButton } from './buttons.styled';
import FormButton from './FormButton';

export default class Button extends PureComponent {

  send = () => {
    this.props.action(this.props.values);
  }

  render(){
    let nameButton = (this.props.icon === 'visualizar')? 'editar' : 'salvar';
    if(this.props.typeButton === 'rounded'){
      return (
        <FormButton
          proccess={this.props.icon}
          onClickButton={this.send} 
          formName={this.props.form}
          nameButton={nameButton} />
      )
    }else{
      return(
        <SubmitButton
          onClick={() => this.send()}
          customstyle={{ color: 'white'}}
        >
          {nameButton}
        </SubmitButton>
      )
    }
  }
}
