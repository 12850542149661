import React, { PureComponent } from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  CardAnalythic,
  SubtitleCard,
  SubtitleSemiBold,
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  RecommendationBox,
  RecommendationText,
  SpanRecommendation,
  RecommendationsColumn,
  IconRecommendation
} from '../analythic.styled';

import { personalDataChecked } from './products/fcAnalise';
import ReportRecommendation from '@bit/wavfichacerta.fichacerta.icons.report-recommendation';
import Person from '@bit/wavfichacerta.fichacerta.icons.person';
import { identification } from '../../../../pages/operational/request/report/singleReport/actions';

class IdentificationCard extends PureComponent {
  constructor(props){
    super(props)

    this.IdRef = React.createRef()
  }

  componentDidMount(){
    this.props.identification(this.IdRef.current)
  }

  renderDataLine = (title, data) => (
    <AnalythicLine key={(title+data)} style={{color: '#000'}}>
      <LineTitle md={3} lg={3} xl={3}>{ title }</LineTitle>
      <span data-testid={title}>{ data }</span>
    </AnalythicLine>
  )

  renderLinesChecked = () => {
    const lines = personalDataChecked(this.props.data);
    return lines.map((item) => this.renderDataLine(item.title, item.data));
  }

  getRecommendations = () => {
    const { data } = this.props;
    
    if (data !== undefined && data !== null && (typeof data === "object" && Object.keys(data).length > 0)) {
      const searchKeys = ["situacao_cpf", "veracidade_nome", "veracidade_data_nascimento", "veracidade_nome_mae"];
      const { laudo } = data;
      let recommendations = [];
      searchKeys.forEach((key) => {
        if (laudo[key] !== undefined && laudo[key] !== null) {
          recommendations = [...recommendations, ...laudo[key].recommendation];
        }
      });

      if (Array.isArray(recommendations) && recommendations.length > 0) {
        // Remove duplicates
        recommendations = recommendations.filter((rec, index) => recommendations.indexOf(rec) === index);
        return (
          <RecommendationBox>
            <BaseColumn xs={12} md={1} lg={1} xl={1}>
              <RecommendationText>Obs: </RecommendationText>
            </BaseColumn>
            <RecommendationsColumn xs={12} md={11} lg={11} xl={11}>
              {recommendations.map((item) => (
                <RecommendationText>
                  <IconRecommendation><ReportRecommendation/></IconRecommendation>
                  <SpanRecommendation>{item}</SpanRecommendation>
                </RecommendationText>
              ))}
            </RecommendationsColumn>
          </RecommendationBox>
        );
      }
    }
    return null;
  }

  renderResultFcRenda = (result) => {
    const { data } = this.props;
    const fcDoc = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {};
    if(!fcDoc.resultado || !fcDoc.confirmacao_identidade || !fcDoc.ativo) {
      return null;
    }

    return(
      <div style={{border: '2px solid #152b4e', padding: '4px 10px', color: '#152b4e', fontSize: 12, marginTop: 6, width: '100%', fontWeight: 'bolder'}} className='result-fcdoc'>
        {fcDoc.confirmacao_identidade}
      </div>
    )
  }

  render() {
    const { data } = this.props;
    const fcDoc = data.laudo.produtos.fcrenda;
    return (
      <CardAnalythic
        title="DADOS PESSOAIS (encontrados)"
        iconHeader={Person}
        iconLeft={true}
      >
        {fcDoc.ativo === true && fcDoc.resultado && fcDoc.confirmacao_identidade &&
          <span className='font-13-print' style={{backgroundColor: '#152b4e', color: '#fff', fontWeight: 600, padding: '2px 6px', marginLeft: 4, position: 'absolute', top: 10, left: 338}}>
            FC DOC
          </span>
        }
        <StyledRow align="initial" margin="0 0 .5rem 0" className="font-13-print">
          <BaseColumn md={12} lg={12} xs={12}>
            <ContainerGray ref={this.IdRef}>
              {/* <SubtitleCard>DADOS PESSOAIS <SubtitleSemiBold>ENCONTRADOS</SubtitleSemiBold></SubtitleCard> */}
              <AnalythicList style={{columnCount: 2, display: 'block', columnGap: '0.4rem'}}>
                { this.renderLinesChecked() }
              </AnalythicList>
            </ContainerGray>
          </BaseColumn>
          {this.renderResultFcRenda()}
        </StyledRow>
        { this.getRecommendations() }
      </CardAnalythic>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  identification
}, dispatch)

export default connect(null, mapDispatchToProps)(IdentificationCard)