import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeStage, loginClient, resetLogin } from './actions';
import { closeLogin, openSignUp } from '../../home/actions';

import LoginModal from './LoginModal';

import LoginForm from '../../../components/login/LoginForm';
import { getStage, getSignUpModalStatus } from './selectors';
import styled from 'styled-components'

const WrapperPopUp = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  @media (max-width: 799px) {
    img {
      width: 100%;
    }
    .wrapper-content {
      width: 90%;
    }
  }
`

/*
let institutionalSiteBaseURL = process.env.REACT_APP_BASE_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  institutionalSiteBaseURL = process.env.REACT_APP_SITE_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  institutionalSiteBaseURL = process.env.REACT_APP_SITE_STAG;
}
*/

class Login extends PureComponent {
  state = {
    popup: true
  }

  componentDidUpdate() {
    const { isLoginSuccess, redirect } = this.props;
    if (isLoginSuccess) {
      this.props.resetLogin();
      redirect('/intranet/dashboard');
    }
  }

  onLoginSubmit = (username, password) => {
    const { loginClient } = this.props;
    loginClient(username, password);
  }

  goToRecovery = () => {
    window.location.replace("https://site.fcanalise.com.br/process/recovery-password");
  }

  onClose = (e) => {
    e.preventDefault();
    this.props.changeStage('login');
    this.props.closeLogin();
  }

  closePopup = () => {
    this.setState({ popup: false })
  }

  handleContent = () => {
    return (
      <LoginForm
        onClose={this.onClose}
        goToRecovery={this.goToRecovery}
        onSubmit={this.onLoginSubmit}
      />
    );
  }

  handlePopup = () => {
    if(!this.state.popup) { return null }
    return(
      <WrapperPopUp>
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            backgroundColor: '#000',
            opacity: .5
          }}
        ></div>
        <div
          className='wrapper-content'
          style={{
            zIndex: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <div>
            <img
              onClick={this.closePopup}
              style={{
                width: '17px',
                float: 'right',
                marginBottom: 10,
                cursor: 'pointer'
              }}
              src={require('./../../../img/svg/ico-close-interno.svg')}
              alt="close"
            />
          </div>
          <img
            onClick={this.closePopup}
            width="360" height="360"
            src={require('./../../../img/png/aviso-feriado-carnaval-2023.png')}
            alt='banner-finaldeano-popup'
          />
        </div>
      </WrapperPopUp>
    )
  }

  render() {
    
    return (
      <Fragment>
        {this.handleContent()}
        {/* {this.handlePopup()} */}
        {/* <LoginModal /> */}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ resetLogin, closeLogin, changeStage, openSignUp, loginClient }, dispatch);

const mapStateToProps = (state, ownProps) => (
  {
    ...ownProps,
    stage: getStage(state),
    isModalVisible: getSignUpModalStatus(state),
    isLoginSuccess: state.login.loginError !== undefined && typeof state.login.loginError !== 'string'
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(Login);