import React, {Fragment} from 'react';
import CustomTable from '../../../../components/shared/table/CustomTable';
import ChargeTableHeader from './ChargeTableHeader';
import ChargeTableLines from './ChargeTableLines';
import {connect} from 'react-redux';
import PaginationFooter from '@bit/wavfichacerta.fichacerta.tables.pagination-footer';
//import Reconsulta from '../../../../components/shared/icons/Reconsulta';
import { Caption } from './ChargeStyles';
import { getChargeData, changePage, changePerPage } from '../actions';
import { bindActionCreators } from 'redux';

// Os comentarios dessa pagina estão esperando o back implementar paginação nessa consulta
const onChangePage = (page, changePage, getCharge) => {
  changePage(page);
  getCharge();
}

const onChangeResultsperPage = (perPage, changePerPage, getCharge) => {
  changePerPage(perPage);
  localStorage.setItem("resultsPerPageRequests", perPage);
  getCharge();
}

const ChargeTable = (props) => {
  const { master } = props.login.user.usuario
  return (
    <Fragment>
      <CustomTable

        maxSize="75vh"
        lines={props.requests}
        renderHeader={() => <ChargeTableHeader master={master} />}
        renderLine={(data, index) => <ChargeTableLines {...data} index={index} resumo={props.resume} master={master} />}
        loading={props.loading}
      />
      <PaginationFooter
        pagination={{currentPage: props.pagination.page, resultsPerPage: props.pagination.perPage, lastPage: props.pagination.lastPage}}
        changePage={(page) => onChangePage(page, props.changePage, props.getChargeData)}
        changeMaxResults={(perPage) => onChangeResultsperPage(perPage, props.changePerPage, props.getChargeData)}
      />
    </Fragment>
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({getChargeData, changePage, changePerPage}, dispatch)

const mapStateToProps = (state) => ({
  requests: state.chargeData.requests,
  pagination: state.chargeData.pagination,
  resume: state.chargeData.resume,
  loading: state.chargeData.isRequesting,
  login: state.login,
})

export default connect(mapStateToProps, mapDispatchToProps)(ChargeTable)