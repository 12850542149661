import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Tabs, TabPanel } from 'react-tabs';
import { PubSub } from 'aws-amplify';

import { retrieveReport, saveReport, receiveReport, prepareReport, getPrintLaudoReq, actionSendMailFianca, actionResetSendMailFianca } from './actions';
import { startReport, resetReport } from '../resume/actions';
import { getRequestingState, reportData, getReportApplicants } from './selector';

import Header from '../../../../components/Header';
import { ApplicantTab, MobileTabsContainer, TabsContainer, TabListContainerRequest, SeeMoreTab } from './report.styles';
import { SearchContainer } from '../../../../components/Header/header.styles';
import HeaderButton, { PDFButton } from '../../../../components/Header/ButtonsHeader/DefaultButton';
import SingleReport from './SingleReport';
import {
  PageContainer,
  TabsRow,
  BaseColumn
} from '../../../../components/shared/sharedStyles.styles';
import LoadingBlueOrange from '../../../../components/shared/icons/LoadingBlueOrange';
import Inquilino from '../../../../components/shared/icons/Inquilino';
import Fiador from '../../../../components/shared/icons/Fiador';
import Conjuge from '../../../../components/shared/icons/Conjuge';
import Outro from '../../../../components/shared/icons/Outro';
import Check from '@bit/wavfichacerta.fichacerta.icons.check';
import GeneralReport from './components/GeneralReport';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import SwipeableTabs from 'react-swipeable-tabs';
import { Main } from './../resume/RequestResume';
import ModalReportFianca from './components/ModalReportFianca'
import ModalReportPrint from './components/ModalReportPrint'
import ModalReportReviewNewReport from './components/ModalReportReviewNewReport'
import styled from 'styled-components'
import { bugsnagClient } from './../../../../routes'
import { detect } from 'detect-browser'
import moment from 'moment'
import { io } from "socket.io-client";
import axios from 'axios';
import Tour from 'reactour'
import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../../components/shared/TourTooltip';
import { instance as axiosInstance } from './../../../../services/axiosInstance';

const browser = detect();

const MainN = styled(Main)`
  
`

const steps = [
  {
    selector: '.title-parecer-locacao h5',
    content: 'Confira a análise em conjunto de todos os envolvidos na locação.',
    position: 'bottom'
  },
  {
    selector: '.box-parecer-locacao-geral',
    content: 'Entregamos recomendação exclusiva para locação, apontando o risco do grupo de inquilinos e/ou fiadores, e o quanto é possível ser flexível em seu processo de aprovação.',
  },
  {
    selector: '.sintese-parecer-locacao-geral',
    content: 'Na Síntese você confere os indicadores que contribuíram para o risco da locação, o impacto destes indicadores é o mesmo no grupo de inquilinos e de fiadores.',
  },
  {
    selector: '[name="RENDAS INFORMADAS em conjunto"]',
    content: 'Aqui você confere se o somatório da renda declarada das pessoas é compatível com a locação.',
  },
  {
    selector: '[name="ORIGEM Perfil Financeiro "]',
    content: 'A origem da renda tem impacto sobre a análise dos perfis, preencha corretamente.',
  },
  {
    selector: '[name="RENDAS PRESUMIDAS em conjunto (Mercado)"]',
    content: 'Aqui você confere a soma das rendas presumidas de mercado, caso seja compatível com a locação você pode simplificar a jornada em caso de risco baixo ou muito baixo.',
  },
  {
    selector: '[name="RISCO DE CRÉDITO em conjunto (Mercado)"]',
    content: 'Risco de crédito é um resultado estático de mercado, como essas pessoas performam no birô de crédito',
  },
  {
    selector: '[name="RISCO FC em conjunto (nesta locação)"]',
    content: 'O Risco FC é uma tradução do quanto essas pessoas representam de risco para a operação de locação.',
  },
  {
    selector: '[name="SCORE FC em conjunto (nesta locação)"]',
    content: <span>O <b>SCORE FC</b> é calculado pelo nosso algoritmo considerando as variáveis da locação, e outros dados em Big Data, por essa razão temos uma escala de classificação própria que é diferente do <b>RISCO de CRÉDITO (Mercado)</b></span>,
  },
  {
    selector: '[tabindex="1"]',
    content: 'Clicando nessas abas você terá acesso ao laudo individual, que te permitirá compreender a performance de cada pretendente.',
  },
  {
    selector: '.botao-imprimir-pdf',
    content: 'Aqui você pode salvar o laudo em seu dispositivo, ou mesmo imprimir.',
  },
  {
    selector: '.botao-resumo-solicitacao',
    content: <span>Para <b>editar</b> a sua análise ou <b>incluir</b> mais pretendentes, clique aqui.</span>,
  },
  // ...
]

const steps_geral_pj_sem_pf = [
  {
    selector: '.score-mercado-pj',
    content: 'Entregamos o score de mercado, exceto para os casos de empresas recém-constituídas, ou de grande porte que não terão essa informação disponível.'
  },
  {
    selector: '.tab-link-pj',
    content: 'Confira aqui os detalhes que detectamos sobre a empresa pesquisada.',
  },
  {
    selector: '[tabindex="1"]',
    content: 'Clicando nessas abas você terá acesso ao laudo individual, que te permitirá compreender a performance de cada pretendente.',
  },
  {
    selector: '.botao-imprimir-pdf',
    content: 'Aqui você pode salvar o laudo em seu dispositivo, ou mesmo imprimir.',
  },
  {
    selector: '.botao-resumo-solicitacao',
    content: <span>Para <b>editar</b> a sua análise ou <b>incluir</b> mais pretendentes, clique aqui.</span>,
  },
  // ...
]

const steps_geral_pj_com_pf = [
  {
    selector: '.title-parecer-locacao h5',
    content: 'Confira a análise em conjunto de todos os envolvidos na locação.'
  },
  {
    selector: '.box-parecer-locacao-geral',
    content: 'Entregamos recomendação exclusiva para locação, apontando o risco do grupo de inquilinos e/ou fiadores, e o quanto é possível ser flexível em seu processo de aprovação.',
  },
  {
    selector: '.sintese-parecer-locacao-geral',
    content: 'Na Síntese você confere os indicadores que contribuíram para o risco da locação, o impacto destes indicadores é o mesmo no grupo de inquilinos e de fiadores.',
  },
  {
    selector: '[name="RENDAS INFORMADAS em conjunto"]',
    content: 'Aqui você confere se o somatório da renda declarada das pessoas é compatível com a locação.',
  },
  {
    selector: '[name="ORIGEM Perfil Financeiro "]',
    content: 'A origem da renda tem impacto sobre a análise dos perfis, preencha corretamente.',
  },
  {
    selector: '[name="RENDAS PRESUMIDAS em conjunto (Mercado)"]',
    content: 'Aqui você confere a soma das rendas presumidas de mercado, caso seja compatível com a locação você pode simplificar a jornada em caso de risco baixo ou muito baixo.',
  },
  {
    selector: '[name="RISCO DE CRÉDITO em conjunto (Mercado)"]',
    content: 'Risco de crédito é um resultado estático de mercado, como essas pessoas performam no birô de crédito',
  },
  {
    selector: '[name="RISCO FC em conjunto (nesta locação)"]',
    content: 'O Risco FC é uma tradução do quanto essas pessoas representam de risco para a operação de locação.',
  },
  {
    selector: '[name="SCORE FC em conjunto (nesta locação)"]',
    content: <span>O <b>SCORE FC</b> é calculado pelo nosso algoritmo considerando as variáveis da locação, e outros dados em Big Data, por essa razão temos uma escala de classificação própria que é diferente do <b>RISCO de CRÉDITO (Mercado)</b></span>,
  },
  {
    selector: '.score-mercado-pj',
    content: 'Entregamos o score de mercado, exceto para os casos de empresas recém-constituídas, ou de grande porte que não terão essa informação disponível.'
  },
  {
    selector: '.tab-link-pj',
    content: 'Confira aqui os detalhes que detectamos sobre a empresa pesquisada.',
  },
  {
    selector: '[tabindex="1"]',
    content: 'Clicando nessas abas você terá acesso ao laudo individual, que te permitirá compreender a performance de cada pretendente.',
  },
  {
    selector: '.botao-imprimir-pdf',
    content: 'Aqui você pode salvar o laudo em seu dispositivo, ou mesmo imprimir.',
  },
  {
    selector: '.botao-resumo-solicitacao',
    content: <span>Para <b>editar</b> a sua análise ou <b>incluir</b> mais pretendentes, clique aqui.</span>,
  },
  // ...
]

const steps_individual = [
  {
    selector: '.react-tabs__tab-panel--selected .sintese-individual',
    content: 'Aqui entregamos indicadores que esclarecem o motivo do risco que o pretendente representa.',
  },
  {
    selector: '.react-tabs__tab-panel--selected [name="SCORE FC nesta locação"]',
    content: <span>Todos os fatores listados na síntese contribuem para a construção do <b>Score FC</b>, que é <b>dinâmico</b>, e pode se alterar de acordo com o valor da locação pretendida e renda declarada.</span>,
  },
  {
    selector: '.react-tabs__tab-panel--selected .dados-e-localizacao',
    content: 'Nesta área te entregamos dados pessoais, possíveis endereços, e telefones.',
  },
  {
    selector: '.react-tabs__tab-panel--selected [title="Rede FC de Informações"]',
    content: 'Aqui detalhamos as consultas realizadas neste CPF nos últimos 3 meses, caso haja um volume acima de 7 tenha mais atenção a este cadastro, nestes casos sugerimos acionar nosso suporte para esclarecer dúvidas sobre este assunto.',
  },
  {
    selector: '.react-tabs__tab-panel--selected .renda-informada',
    content: <span>Renda pessoal que você preencheu no ato da análise, <b>dica</b>: caso receba a comprovação de renda do pretendente e precise corrigir o valor lançado, é possível.</span>,
    position: 'top'
  },
  {
    selector: '.react-tabs__tab-panel--selected .renda-presumida',
    content: 'Detectamos a renda presumida de mercado de acordo com o perfil de consumo, ela pode te ajudar em seu processo de aprovação. Para mais dicas sobre este assunto, fale com nosso suporte.',
  },
  {
    selector: '.react-tabs__tab-panel--selected .company',
    content: 'Aqui demonstramos possíveis participações empresariais.',
  },
  // ...
]

const steps_individual_fcreport = [
  {
    selector: '.react-tabs__tab-panel--selected .sintese-individual',
    content: 'Aqui entregamos indicadores que esclarecem o motivo do risco que o pretendente representa.',
  },
  {
    selector: '.react-tabs__tab-panel--selected [name="SCORE FC nesta locação"]',
    content: <span>Todos os fatores listados na síntese contribuem para a construção do <b>Score FC</b>, que é <b>dinâmico</b>, e pode se alterar de acordo com o valor da locação pretendida e renda declarada.</span>,
  },
  {
    selector: '.react-tabs__tab-panel--selected .dados-e-localizacao',
    content: 'Nesta área te entregamos dados pessoais, possíveis endereços, e telefones.',
  },
  {
    selector: '.react-tabs__tab-panel--selected [title="Rede FC de Informações"]',
    content: 'Aqui detalhamos as consultas realizadas neste CPF nos últimos 3 meses, caso haja um volume acima de 7 tenha mais atenção a este cadastro, nestes casos sugerimos acionar nosso suporte para esclarecer dúvidas sobre este assunto.',
  },
  {
    selector: '.react-tabs__tab-panel--selected .renda-informada',
    content: <span>Renda pessoal que você preencheu no ato da análise, <b>dica</b>: caso receba a comprovação de renda do pretendente e precise corrigir o valor lançado, é possível.</span>,
    position: 'top'
  },
  {
    selector: '.react-tabs__tab-panel--selected .renda-presumida',
    content: 'Detectamos a renda presumida de mercado de acordo com o perfil de consumo, ela pode te ajudar em seu processo de aprovação. Para mais dicas sobre este assunto, fale com nosso suporte.',
  },
  {
    selector: '.react-tabs__tab-panel--selected .company',
    content: 'Aqui demonstramos possíveis participações empresariais.',
  },
  {
    selector: '.react-tabs__tab-panel--selected [title="AÇÕES JUDICIAIS e NEGATIVAÇÕES DE CRÉDITO*"] h5',
    content: 'Confira abaixo o detalhamento de eventuais informações restritivas detectadas.',
    position: 'top'
  },
  // ...
]

const steps_individual_pj = [
  {
    selector: '.react-tabs__tab-panel--selected .score-mercado',
    content: 'Confira aqui o Score de Mercado da Empresa Pesquisada.',
  },
  {
    selector: '.react-tabs__tab-panel--selected .informacoes-restritivas',
    content: 'Aqui citamos se houve detecção de restrições financeiras.',
  },
  {
    selector: '.react-tabs__tab-panel--selected .identificacao-localizacao',
    content: 'Confira aqui os dados de identificação e localização.',
  },
  {
    selector: '.react-tabs__tab-panel--selected .socios-diretores',
    content: 'Apresentamos aqui o quadro de sócios.',
  },
  {
    selector: '.react-tabs__tab-panel--selected [title="PERFIL FINANCEIRO"]',
    content: 'Detectamos faturamento presumido mensal.',
  },
  {
    selector: '.react-tabs__tab-panel--selected [title="AÇÕES JUDICIAIS e NEGATIVAÇÕES DE CRÉDITO*"] h5',
    content: 'Confira abaixo o detalhamento de eventuais informações restritivas detectadas.',
    position: 'top'
  },
  {
    selector: '.react-tabs__tab-panel--selected .acoes-despejo',
    content: 'Capturamos ações de despejo e inadimplemento de locação.',
  },
  {
    selector: '.react-tabs__tab-panel--selected .escalas-de-risco',
    content: 'Observe aqui a escala de risco de mercado, e identifique assim o risco que essa empresa representa para a locação pretendida.',
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class Report extends Component {
  state = {
    active: 0,
    openModalFianca: false,
    openModalPrint: false,
    requestingPrint: false,
    openModalReviewNewReport: false,
    openModalReviewNewReportCount: 0,
    showModalReviewNewReport: false,
    successReviewReport: false,
    requestingReviewReport: false,
    errorReviewReport: false,
    reviewValueNumber: 0,
    reviewValueComment: '',
    tourOpen: false,
    tourOpen_individual: false,
    loaded_individual: false,
    isTourOpened: false,
    countTourOpen: 0,
    tourId_geral: null,
    tourId_individual: null,
    hasPJ: false,
    hasPF: false,
    firstType: ''
  }

  handlerBrowserInfo = (resquestId = '', statusCode = '', responseData = '') => {
    const date = moment().format('DD/MM/YYYY (H:mm:ss)') + '\n'
    const ndate = new Date() + '\n'
    const zdate = new Date().getTimezoneOffset() + '\n'
    const id = resquestId ? `\n LAUDO (ID ${resquestId})` : ''
    const user = this.props.user ? `\n Usuario: ${this.props.user.nome} (${this.props.user.email})` : ''
    const code = statusCode ? `\n Status code: {${statusCode}}` : ''
    const data = responseData ? `\n Data: ${JSON.stringify(responseData)}` : ''

    if (browser) {
      const { name, version, os, type } = browser
      return `${date} ${ndate} ${zdate} Browser info: {${type}} {${name}} {${version}} {${os}} ${id} ${user} ${code} ${data}`
    }

    return `${date} ${ndate} ${zdate} Browser info: Não encontrado ${code} ${data}`
  }

  componentDidMount() {
    const { location: { state }, prepareReport, startReport, login } = this.props;
    const topic = process.env.NODE_ENV === "development" ? `reports/dev/${state.request}/+` : `reports/${process.env.REACT_APP_ENV}/${state.request}/+`;
    console.log('prop11', this.props)
    prepareReport(state.applicants, false)
    startReport(state.data, state.typeOfReport)
    const access_token = login.user.token.access_token;
    console.log('typeOfReport12', state.typeOfReport) // charge

    if(state.typeOfReport === 'charge' && !state.hasRating) {
      const show = this.handleVisibleRating();
      if(show) {
        this.setState({showModalReviewNewReport: true})
      }
    }


    if (state !== undefined && typeof state === "object" && "request" in state) {
      this.pubSubConnection = PubSub.subscribe(topic).subscribe({
        next: data => {
          this.props.receiveReport(data.value);
          console.log('lala11', data.value)
          if(data.value.pretendente.id === 0) {
            data.value.laudos_sintese.map((pretendente, index) => {
              if(index === 0) {
                this.setState({firstType: pretendente.cnpj ? 'PJ' : 'PF'})
              }
              if(pretendente.cnpj) {
                this.setState({hasPJ: true});
              } else {
                this.setState({hasPF: true});
              }
            })
            setTimeout(() => {
              this.setState({active: 0})
              this.getCheckOnboard()
            }, 100)
          } else {
            this.setState({loaded_individual: true})
          }
          
          if(this.state.showModalReviewNewReport) {
            this.toggleModalReviewNewReport();
          }
        },
        error: error => {
          console.log('ERR: ', error)
          if(error.error.errorCode == 7) {
            // this.toggleModalPrint();

            // const URL = `ws://localhost:3003`
            const URL = `wss://socket.ficharapida.rio.br`
            const socket = io(URL);

            socket.auth = {
              solicitationId: state.request,
              token: access_token
            }
        
            socket.on('connect', () => {
              console.log('connected', socket.id)
        
              socket.on('receive-report', (msg) => {
                console.log('msg received: ', msg)
                this.props.receiveReport(msg.data);
              })
            })
        
            socket.on('disconnect', (reason) => {
              console.log('reason: ', reason)
              console.log('disconnected', socket)
            })
        
            socket.on("connect_error", err => {
              console.log(err instanceof Error); // true
              console.log(err.message); // not authorized
              console.log(err.data); // { content: "Please retry later" }
              // if (err.message === "not authorized") {
              //   this.isLogged = false;
              // }
            });
          }
          const e = {
            errorCode: error.error.errorCode,
            errorMessage: error.error.errorMessage
          }
          bugsnagClient.notify(this.handlerBrowserInfo(state.request, e.errorCode, e.errorMessage));
        },
        close: () => console.log('Done'),
      });
    } else {
      this.props.history.goBack();
    }
  }

  componentWillUnmount() {
    this.props.resetReport();
    this.pubSubConnection.unsubscribe();
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'laudo-geral') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
        if(tourOpen) {
          this.setState({isTourOpened: true})
        }
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'laudo-geral'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboardGeral = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'laudo-geral',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId_geral: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboardGeral = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      if(step === 2) {
        document.querySelector('.box-parecer-locacao-geral').scrollIntoView()
      }
      if(step === 3) {
        document.querySelector('.sintese-parecer-locacao-geral').scrollIntoView({block: "start"})
      }

      const data = {
        tourId: this.state.tourId_geral,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboardIndividual = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'laudo-individual',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId_individual: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboardIndividual = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      if(step === 2) {
        document.querySelector('.react-tabs__tab-panel--selected [name="SCORE FC nesta locação"]').scrollIntoView()
      }
      if(step === 3) {
        document.querySelector('.react-tabs__tab-panel--selected .dados-e-localizacao').scrollIntoView({block: "end"})
      }
      if(step === 4) {
        document.querySelector('.react-tabs__tab-panel--selected [title="Rede FC de Informações"]').scrollIntoView({block: "end"})
      }
      if(step === 5) {
        document.querySelector('.react-tabs__tab-panel--selected .renda-informada').scrollIntoView({block: "center"})
      }
      if(step === 6) {
        document.querySelector('.react-tabs__tab-panel--selected .acoes-despejo').scrollIntoView()
      }
      if(step === 7) {
        document.querySelector('.react-tabs__tab-panel--selected .company').scrollIntoView(false)
      }

      const data = {
        tourId: this.state.tourId_individual,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  manageReport = () => {
    const { location: { state } } = this.props;
    if (state !== undefined && state !== null && typeof state === "object") {
      if ("request" in state) {
        const { request } = state;
        this.props.history.push({
          pathname: '/intranet/operacional/solicitacao/resumo',
          state: { request }
        });
      }
    }
  }

  getReportTitle = () => {
    const { location: { state } } = this.props;
    if (state !== undefined && state !== null && typeof state === "object") {
      if ("request" in state) {
        return `Laudo (ID ${state.request})`;
      }
    }

    return `Laudo`;
  }

  getReportSubtitle = () => {
    const { location: { state } } = this.props;
    if (state !== undefined && state !== null && typeof state === "object") {
      if ("customer" in state) {
        return state.customer;
      }
    }
    return "";
  }

  getCustomerName = () => {
    const { data, requesting } = this.props;
    if (data !== undefined && typeof data === "object") {
      if (!requesting) {
        return data.pretendente.nome;
      }
    }
    return "BUSCANDO...";
  }

  getRequestStatus = () => {
    
    const { data, requesting } = this.props;
    
    if (data !== undefined && typeof data === "object") {
      return requesting;
    }

    return true;
  }


  renderApplicantIcon = (applicant) => {
  
    switch(applicant){
      case "INQUILINO":
      let randomNumber = Math.floor(Math.random() * (10, 20));
     
      return (
        <Fragment>
          <Inquilino color="white" id={'popover-'+ randomNumber} margin="auto 2px"/>
          <UncontrolledPopover key={'popover-'+ randomNumber} target={'popover-'+ randomNumber} trigger="hover">
            <PopoverBody>
              Inquilino
            </PopoverBody>
          </UncontrolledPopover>
        </Fragment>
        );
      case "FIADOR":
      let randomNumber1 = Math.floor(Math.random() * (10, 20));
        return (
        <Fragment>
        <Fiador color="white" id={"popover2-"+ randomNumber1} margin="auto 2px"/>
        <UncontrolledPopover key={"popover2-"+ randomNumber1} target={"popover2-" + randomNumber1} trigger="hover">
          <PopoverBody>
            Fiador  
          </PopoverBody>  
        </UncontrolledPopover>
        </Fragment>
        );

      case "CONJUGE_INQUILINO":
      let randomNumber2 = Math.floor(Math.random() * (10, 20));
          return (
            <Fragment>
              <Conjuge id={"popover3-" + randomNumber2} margin="auto 2px"/>
                <UncontrolledPopover key={"popover3-" + randomNumber2} target={"popover3-" + randomNumber2} trigger="hover">
                  <PopoverBody>
                    Cônjuge Inquilino
                  </PopoverBody>
                </UncontrolledPopover>
            </Fragment>
          )

      case "CONJUGE_FIADOR":
      let randomNumber3 = Math.floor(Math.random() * (10, 20));
        return (
        <Fragment>
        <Conjuge id={"popover4-" + randomNumber3} margin="auto 2px"/>
        <UncontrolledPopover key={"popover4-" + randomNumber3} target={"popover4-" + randomNumber3} trigger="hover">
          <PopoverBody>
            Cônjuge Fiador
          </PopoverBody>
        </UncontrolledPopover> 
        </Fragment>
        )

      case "FIADOR_ESTUDANTE":
      let randomNumber5 = Math.floor(Math.random() * (10, 20));
        return (
        <Fragment>
        <Fiador id={"popover4-" + randomNumber5} margin="auto 2px"/>
        <UncontrolledPopover key={"popover4-" + randomNumber5} target={"popover4-" + randomNumber5} trigger="hover">
          <PopoverBody>
            Fiador de Estudante
          </PopoverBody>
        </UncontrolledPopover> 
        </Fragment>
        )

      case 'INQUILINO_ESTUDANTE':
      let randomNumber6 = Math.floor(Math.random() * (10, 20));
        return (
        <Fragment>
        <Inquilino id={"popover4-" + randomNumber6} margin="auto 2px"/>
        <UncontrolledPopover key={"popover4-" + randomNumber6} target={"popover4-" + randomNumber6} trigger="hover">
          <PopoverBody>
            Inquilino estudante
          </PopoverBody>
        </UncontrolledPopover>
        </Fragment>
        )

      case undefined:
        return null

      default:
      let randomNumber4 = Math.floor(Math.random() * (10, 20));
        return (
        <Fragment>
        <Outro id={"popover4-" + randomNumber4} margin="auto 2px"/>
        <UncontrolledPopover key={"popover4-" + randomNumber4} target={"popover4-" + randomNumber4} trigger="hover">
          <PopoverBody>
            Outro
          </PopoverBody>
        </UncontrolledPopover>
        </Fragment>
        )

    }
  }

  renderApplicantTabName = (nome) => {
    
    if(nome && nome.length >= 17){
      let nomeTratado = nome.substring(0, 17)
      
      return `${nomeTratado}...`}
    else
    return nome;
  }

  handleViewNewVersion = () => {
    const user = this.props.location.state.customer;
    const userPermited = [
      'IMOBILIARIA TESTE LTDA',
      'IMOBILIÁRIA TESTE LTDA',
      'KHCNX QICCT',
      'ABREU ADMINISTRADORA DE IMóVEIS E CONDOMíNIOS LTDA',
      'AE PATRIMôNIO',
      'ANCORA IMOBILIARIA LTDA',
      'BROGNOLI - CDA',
      'BROGNOLI - CONTINENTE',
      'BROGNOLI - DIVID (DIVID COMPARTILHAMENTOS DE IMOVEIS)',
      'BROGNOLI - ILHA (BROGNOLI IMOVEIS LTDA)',
      'ETICA EMPREENDIMENTOS LTDA',
      'FRIAS NETO CONSULTORIA E EMPREENDIMENTOS IMOBILIARIOS LTDA',
      'FUHRO SOUTO (F. SOUTO IMOVEIS LTDA)',
      'GRALHA LOCACAO E ADMINISTRACAO DE IMOVEIS LTDA',
      'HOME HUNTERS (BLUE NEGOCIOS IMOBILIARIOS – EIRELI)',
      'IMOBILIARIA AVENIDA',
      'REAL BENEFÍCIOS (REDE DE BENEFICIOS DOS AGENTES DO MERCADO IMOBILIARIO DO BRASIL)',
      'RENASCENCA (IMOPRET EMPREENDIMENTOS IMOBILIARIOS LTDA)',
      'ROCA ADMINISTRADORA DE IMÓVEIS EIRELLI',
      'TERRAZ ASSESSORIA IMOBILIÁRIA LTDA',
      'VALOR IMOBILIARIA LTDA',
      'FRANCIOSI CONSTRUTORA E IMOVEIS LTDA',
      'IVO IMÓVEIS (IVO CONSULTORIA DE IMOVEIS S S LTDA)'
    ];
    const newVersion = this.props.applicants[0] && this.props.applicants[0].data.tagFrontVersion ? this.props.applicants[0].data.tagFrontVersion[0] : null;
    const newVersionAll = this.props.applicants[0] && this.props.applicants[0].data.tagFrontVersion ? this.props.applicants[0].data.tagFrontVersion[1] : null;

    if((userPermited.includes(user) && newVersion === '1.0.0') || newVersionAll === '1.0.1') {
      return true;
    }
    return false;
  }

  handleChangeTab = (index) => {
    // document.getElementsByClassName(`tab-panel-id-${index}`)[0].scrollIntoView({ behavior: "smooth"})
    const offTop = document.getElementsByClassName(`tab-panel-id-${index}`)[0].offsetTop
    document.querySelector('#root > div').scrollTop = offTop - 95
    this.setState({active: index, tourOpen: false})
    if(index !== 0 && this.state.loaded_individual && this.state.countTourOpen === 0 && this.state.isTourOpened) {
      this.setState({tourOpen_individual: true, countTourOpen: 1, active: 1, isTourOpened: false})
    }
  }

  // Render Tabs
  renderApplicantTabs = () => {
    const { location: { state }, applicants } = this.props;
    if (state !== undefined && state !== null && typeof state === "object") {
      if ("applicants" in state) {
        return state.applicants.map((applicant, index) => {
          let nome = applicant.nome || applicant.razao_social;

          if(nome === 'SÍNTESE' && this.handleViewNewVersion()) {
            nome = 'PARECER'
          }
          
          // Get the data from reducer and check if still loading
          const applicantFound = applicants.find((line) => line.applicant === applicant.id);
          const reportPending = (applicantFound !== undefined)? applicantFound.isRequesting : true;
          return (
            <ApplicantTab key={applicant.id} className="tab" tabIndex={index} name={applicant.id} textTransform="initial !important">
              <button onClick={() => this.handleChangeTab(index)} className="button-link" style={{color: '#000'}}>
                {this.renderApplicantIcon(applicant.tipo_pretendente)} {this.renderApplicantTabName(nome)} {reportPending? <LoadingBlueOrange width="12px" /> : <Check width="12px" margin="0 .5rem" />}
              </button>
            </ApplicantTab>
          );
        })
      }
    }
    return null;
  }

  // Render Applicants containers
  renderApplicants = () => {
    const { applicants } = this.props;
    console.log('blabla1', applicants)
    return applicants.map((line, index) => (
      <TabPanel className={`tab-panel-id-${index} react-tabs__tab-panel`}>
        {line.data && line.data.pretendente && line.data.pretendente.id === 0
          ?
            <GeneralReport
              data={line.data}
              applicants={applicants}
              reportPending={line.isRequesting}
              manageReport={this.manageReport}
              // changeTab={(index) => this.setState({active: index})}
              changeTab={(index) => this.handleChangeTab(index)}
              toggleModalFianca={this.toggleModalFianca}
              customer={this.props.location.state.customer}
            />
          :
            <SingleReport
              data={line.data}
              reportPending={line.isRequesting}
              manageReport={this.manageReport}
              selected={this.state.active}
              changeTab={(val) => this.setState({active: val})}
              indexActive={this.state.active}
              currentApplicantIndex={index}
              toggleModalFianca={this.toggleModalFianca}
              customer={this.props.location.state.customer}
            />
        }
      </TabPanel>
    ));
  }
  
  printData = () => {
    const { location: { state }, getPrintLaudoReq } = this.props;
    const toastrOptions = {timeOut: 0}
    if (state !== undefined && state !== null && typeof state === "object") {
      if ("request" in state) {
        toastr.info("Aguarde alguns instantes, estamos gerando o arquivo de impressão.", toastrOptions)
        this.setState({requestingPrint: true})
        getPrintLaudoReq(state.request)
          .then(res => {
            if(res == 'error') {
              return;
            }
            window.open(res, '_blank')
            toastr.removeByType('info')
            this.setState({requestingPrint: false})
          })
          .catch(err => {
            toastr.removeByType('info')
            toastr.error(err.message)
            this.setState({requestingPrint: false})
          })

      }
    }
  }


  changeTab = () => {
    const { location: { state } } = this.props;
    
    if ("applicants" in state) {
        return state.applicants.map((applicant, index) => {

          return index.length;
        })
    }
  }

  changeTabPosition = (e) => {
    let offTop = 0
    
    switch(e) {
      case "next":
        this.setState({
          active: this.state.active >= (this.changeTab().length - 1) ? this.state.active : this.state.active + 1,
        })
        if(document.getElementsByClassName(`tab-panel-id-${this.state.active + 1}`)[0]) {
          offTop = document.getElementsByClassName(`tab-panel-id-${this.state.active + 1}`)[0].offsetTop
          document.querySelector('#root > div').scrollTop = offTop - 95
        }
        
        break
      case "return":
        this.setState({
          active: this.state.active == 0 ? this.state.active : this.state.active - 1
        })
        if(document.getElementsByClassName(`tab-panel-id-${this.state.active - 1}`)[0]) {
          offTop = document.getElementsByClassName(`tab-panel-id-${this.state.active - 1}`)[0].offsetTop
          document.querySelector('#root > div').scrollTop = offTop - 95
        }

        break
    }
    
  }

  toggleModalFianca = () => {
    this.setState({openModalFianca: true})
  }

  closeModalFianca = () => {
    this.setState({openModalFianca: false})
    this.props.actionResetSendMailFianca()
  }

  toggleModalPrint = () => {
    this.setState({openModalPrint: true})
  }

  closeModalPrint = () => {
    this.setState({openModalPrint: false})
    // this.props.actionResetSendMailPrint()
  }

  toggleModalReviewNewReport = () => {
    if(this.state.openModalReviewNewReportCount === 0) {
      this.setState({openModalReviewNewReport: true, openModalReviewNewReportCount: 1})
    }
  }

  closeModalReviewNewReport = async () => {
    this.setState({openModalReviewNewReport: false})

    if(!this.state.successReviewReport) {
      try {
        // const URL = 'http://localhost:3333/rating'
        const URL = 'https://api-v1.ficharapida.rio.br/rating'
  
        const data = {
          email: this.props.user.email,
          rating: 0,
          comment: '',
          name: this.props.user.nome,
          cargo: this.props.user.funcao,
          customer: this.props.location.state.customer
        }
  
        const result = await axios.post(URL, data);
        console.log('result', result)
  
      } catch (err) {
        console.log('ERR:', err)
      }
    }
  }

  handleSendMailFianca = () => {
    this.props.actionSendMailFianca(this.props.location.state.request)
  }

  renderName = () => {
    const nome = this.props.user.nome.split(' ')[0];
    return nome;
  }

  sendReview = async () => {
    try {
      // const URL = 'http://localhost:3333/rating'
      const URL = 'https://api-v1.ficharapida.rio.br/rating'
      this.setState({requestingReviewReport: true})

      const data = {
        email: this.props.user.email,
        rating: Number(this.state.reviewValueNumber),
        comment: this.state.reviewValueComment,
        name: this.props.user.nome,
        cargo: this.props.user.funcao,
        customer: this.props.location.state.customer
      }

      const result = await axios.post(URL, data);
      console.log('result', result)
      localStorage.setItem('trySecond', 10);
      this.setState({successReviewReport: true, requestingReviewReport: false})

    } catch (err) {
      console.log('ERR:', err)
      this.setState({requestingReviewReport: false, errorReviewReport: true})
    }
  }

  onChangeReviewValue = (event) => {
    this.setState({reviewValueNumber: event.target.value})
  }

  onChangeCommentValue = (event) => {
    this.setState({reviewValueComment: event.target.value})
  }

  handleVisibleRating = () => {
    let tryOne = Number(localStorage.getItem('tryOne'))
    let trySecond = Number(localStorage.getItem('trySecond'))
    console.log('tryOne12:', tryOne)
    console.log('trySecond12:', trySecond)
    if(trySecond === 0) {
      if(tryOne < 1) {
        tryOne++;
        localStorage.setItem('tryOne', tryOne);
      } else if(tryOne >=1) {
        trySecond = 1;
        localStorage.setItem('trySecond', trySecond);
        return true;
      }
    } else {
      if(trySecond === 5) {
        trySecond = 1;
        localStorage.setItem('trySecond', trySecond);
        return true;
      } else {
        trySecond++;
        localStorage.setItem('trySecond', trySecond);
      }
    }
    return false;
  }

  handleOpenTourCallback = (call) => {
    if(this.state.active === 0) {
      this.setState({tourOpen: call})
    } else {
      // this.setState({tourOpen_individual: call, active: 1})
      this.setState({tourOpen_individual: call})
    }
  }

  handleImpressao = () => {
    if(this.state.tourOpen || this.state.tourOpen_individual) {
      this.setState({tourOpen: false}, () => {
        setTimeout(() => {
          window.print()
        }, 100)
      })
    } else {
      window.print()
    }
  }

  getPrintRecibo = async () => {
    try {
      toastr.info("Aguarde alguns instantes, estamos gerando o arquivo de impressão.", {timeOut: 0, extendedTimeOut: 0})
      const token = this.props.login.user.token.access_token
      const requestId = this.props.location.state && this.props.location.state.request
      const URL = `/api/solicitacao/${requestId}/recibo`

      const headers = {
        'Authorization': `Bearer ${token}`
      }

      const result = await axiosInstance.get(URL, {headers});
      if(result.status === 401) {
        this.props.history.push('/auth')
      }
      window.open(result.data, '_blank')
      toastr.removeByType('info')
    } catch (err) {
      console.log('ERR:', err)
      toastr.error(err.message)
    }
  }

  render() {
    const breadcrumb = [
      { link: '', label: 'operacional'},
      { link: '/intranet/operacional/solicitacao', label: 'Locações' },
      {
        link: {
          pathname: '/intranet/operacional/solicitacao/resumo',
          state: {request: this.props.location.state && this.props.location.state.request}
        },
        label: 'resumo'
      }
    ];

    const nome = this.renderName();

    let steps_geral = steps;
    let steps_individualx = steps_individual;

    let pretendente = this.props.applicants[1] && this.props.applicants[1].data.pretendente ? this.props.applicants[1].data.pretendente : {produtos: {ativo: []}};
    if(this.state.active !== 0) {
      pretendente = this.props.applicants[this.state.active] && this.props.applicants[this.state.active].data.pretendente ? this.props.applicants[this.state.active].data.pretendente : {produtos: {ativo: []}};
    }

    const {
      produtos: { ativo }
    } = pretendente;

    // const productFcRenda = ativo.filter(product => product.id === 2)
    // const productFcAnalise = ativo.filter(product => product.id === 3)
    const productFcReport = ativo.filter(product => product.id === 1)
    const productFcEmpresa = ativo.filter(product => product.id === 4)

    if(productFcReport[0] && productFcReport[0].status !== 'INCLUIDO') {
      steps_individualx = steps_individual_fcreport;
    }

    if(this.state.hasPJ) {
      steps_geral = steps_geral_pj_sem_pf;
      // steps_individualx = steps_individual_pj;
    }

    if(this.state.hasPJ && this.state.hasPF) {
      steps_geral = steps_geral_pj_com_pf;
      // steps_individualx = steps_individual_pj;
    }

    // if(this.state.firstType === 'PJ') {
    //   steps_individualx = steps_individual_pj;
    // }
    if(productFcEmpresa[0]) {
      steps_individualx = steps_individual_pj;
    }

    let disableButtonPrintLaudo = true;
    if(this.props.applicants.length) {
      disableButtonPrintLaudo = !this.props.applicants.every(applicant => applicant.isRequesting === false);
    }

    return (
      <MainN className='reportMain'>
        <Tabs selectedTabClassName="active" selectedIndex={this.state.active} forceRenderTabPanel>
          <Header
            title={this.getReportTitle()}
            subtitle={this.getReportSubtitle()}
            isTab={true}
            breadcrumb={breadcrumb}
            actualLink="Laudo"
            height="79px"
            openTourCallback={this.handleOpenTourCallback}
            handleImpressao={this.handleImpressao}
            getPrintRecibo={this.getPrintRecibo}
            infos={this.props.location && this.props.location.state && this.props.location.state.infos}
            disableButtonPrintLaudo={disableButtonPrintLaudo}
          >
            <TabsRow justify="space-between" margin="0.4rem 0 0 0" className='header-steps'>
              <BaseColumn xs="9" sm="9" md="9" lg="9" xl="9">
                <SeeMoreTab onClick={() => this.changeTabPosition('return')}> « </SeeMoreTab>
                <TabListContainerRequest>
                  <TabsContainer>
                    <SwipeableTabs 
                      items={this.renderApplicantTabs()}
                      fitItems={false}
                      alignCenter={false}
                      borderColor="transparent"
                      activeItemIndex={this.state.active}
                      onItemClick={() => null}
                      noFirstLeftPadding={false}
                      noLastRightPadding={false}
                      borderWithRatio={0}
                      borderThickness={0}
                      itemStyle={{
                        padding: '3px',
                        paddingBottom: "0px",
                        borderTop: '1px solid #b1b1b1',
                        borderLeft: '1px solid #b1b1b1',
                        borderRight: '1px solid #b1b1b1',
                        marginBottom: '3px',
                        marginLeft: '3px'
                        }}
                      activeStyle={{
                        backgroundColor:'white'
                      }}
                    
                    />
                  </TabsContainer>
                </TabListContainerRequest>
                <SeeMoreTab onClick={() => this.changeTabPosition('next')}> » </SeeMoreTab>
              </BaseColumn>
              <BaseColumn xs="3" sm="3" md="3" lg="3" xl="3" customStyle="justify-content: flex-end;">
                <SearchContainer justify="flex-start" height="0px">
                  {/* <PDFButton onClick={this.handleImpressao} position={'relative'} className="botao-imprimir-pdf">Imprimir/Salvar PDF</PDFButton> */}
                  {/* <PDFButton onClick={this.printData} position={'relative'}>Imprimir/Salvar PDF</PDFButton> */}
                  <HeaderButton customstyle="padding: .3vw 10px !important;" onClick={this.manageReport} isRequest className="botao-resumo-solicitacao">Editar ou Incluir</HeaderButton>
                  <TourTooltip
                    target="tooltip-botao-laudo-resumo-solicitacao"
                    style={{right: -10, top: 0}}
                  />
                  <ReactTooltip id="tooltip-botao-laudo-resumo-solicitacao" place="left" type="dark" effect="float">
                    <span>Edite dados, e inclua novos pretendentes</span>
                  </ReactTooltip>
                </SearchContainer>
              </BaseColumn>
            </TabsRow>
          </Header>
          <MobileTabsContainer className="mobileTabsContainer">
            <SwipeableTabs 
              items={this.renderApplicantTabs()}
              fitItems={false}
              alignCenter={false}
              borderColor="transparent"
              activeItemIndex={this.state.active}
              onItemClick={() => null}
              noFirstLeftPadding={false}
              noLastRightPadding={false}
              borderWithRatio={0}
              borderThickness={0}
              itemStyle={{
                padding: '-5px',
                paddingBottom: "0px",
                borderBottom: '1px solid #b1b1b1',
                marginBottom: '3px',
                marginLeft: '5px'
                }}
              activeStyle={{
                backgroundColor:'#3B4465'
              }}
            
            />
          </MobileTabsContainer>
          { this.renderApplicants() }
        </Tabs>
        <ModalReportFianca
          undo={() => this.handleSendMailFianca()}
          isOpen={this.state.openModalFianca}
          onClose={this.closeModalFianca}
          loading={this.props.requestingMailFianca}
          error={this.props.errorMailFianca}
          success={this.props.successMailFianca}
        />
        <ModalReportPrint
          undo={() => this.printData()}
          isOpen={this.state.openModalPrint}
          onClose={this.closeModalPrint}
          loading={this.state.requestingPrint}
          error={this.props.errorMailFianca}
          success={this.props.successMailFianca}
        />
        {/* <ModalReportReviewNewReport
          undo={() => this.sendReview()}
          isOpen={this.state.openModalReviewNewReport}
          onClose={this.closeModalReviewNewReport}
          loading={this.state.requestingReviewReport}
          error={this.state.errorReviewReport}
          success={this.state.successReviewReport}
          nome={nome}
          onChangeReviewValue={this.onChangeReviewValue}
          onChangeCommentValue={this.onChangeCommentValue}
          reviewValueNumber={this.state.reviewValueNumber}
        /> */}
        <Tour
          steps={steps_geral}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboardGeral}
          onAfterOpen={this.postAfterOpenOnboardGeral}
          startAt={0}
        />
        <Tour
          steps={steps_individualx}
          isOpen={this.state.tourOpen_individual}
          onRequestClose={() => this.setState({tourOpen_individual: false})}
          getCurrentStep={this.postCurrentStepOnboardIndividual}
          onAfterOpen={this.postAfterOpenOnboardIndividual}
          startAt={0}
        />
      </MainN>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  prepareReport,
  startReport,
  retrieveReport,
  saveReport,
  receiveReport,
  resetReport,
  getPrintLaudoReq,
  actionSendMailFianca,
  actionResetSendMailFianca
}, dispatch);

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  requesting: getRequestingState(state),
  data: reportData(state),
  applicants: getReportApplicants(state),
  requestingMailFianca: state.report.requestingMailFianca,
  errorMailFianca: state.report.errorMailFianca,
  successMailFianca: state.report.successMailFianca,
  user: state.login.user.usuario,
  login: state.login
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);