import { combineReducers } from 'redux';
import configPersist from './persistConfig';
import { connectRouter } from 'connected-react-router';

// Reducers
import { reducer as toastrReducer } from 'react-redux-toastr';
import { reducer as formReducer } from "redux-form";

// import validation from '../pages/validation/reducer';
import requirements from '../pages/form-requirements/reducer';
import home from '../pages/home/reducer';
import login from '../pages/auth/login/reducer';

//MENU
import menu from '../pages/menu/reducer';

//Customer
import customerData from '../pages/customer/CustomerData/reducer';
import comercialData from '../pages/customer/ComercialData/reducers';
import employersList from '../pages/users/reducer';
import employerForm from '../pages/users/form/reducer';
import stateModal from '../components/shared/status-modal/reducer';

// Customer SAC
import sacUserData from '../pages/common/dashboard/reducer';

//Finances
import chargeData from '../pages/finances/charge/reducers';

//Requests
import requestsTable from '../pages/operational/table/reducer';
import fcfiancaTable from '../pages/operational/fcfianca/reducer';
import modalRequestFields from '../pages/operational/request/form/fieldModal/reducer';
import requestResume from '../pages/operational/request/resume/reducer';
import requestForm from '../pages/operational/request/form/reducer';
import tenancyForm from '../pages/operational/request/resume/TenancyEdit/reducer';
import request from '../pages/operational/request/reducer';
import report from '../pages/operational/request/report/reducer';
import singleReport from '../pages/operational/request/report/singleReport/reducer';
import modalIncomeSection from '../pages/operational/request/form/incomeModal/reducer';
import modalDeleteLastProduct from '../pages/operational/request/resume/ModalDeleteLastProduct/reducer';
import pricesTable from '../pages/prices/reducers'
import clientsTable from '../pages/users/actions/employees/reducer';


//plugin
// import reduxFormPlugin from './reduxFormPlugin';

const persistLogin = configPersist('login', { whitelist: ['user', 'logged'] }, login);

export default (history) => combineReducers({
  // validation,
  requirements,
  home,
  login: persistLogin,
  form: formReducer,
  router: connectRouter(history),
  toastr: toastrReducer,
  menu,
  clientsTable,
  customerData,
  comercialData,
  employersList,
  requestsTable,
  fcfiancaTable,
  modalRequestFields,
  requestResume,
  requestForm,
  tenancyForm,
  request,
  report,
  singleReport,
  modalIncomeSection,
  modalDeleteLastProduct,
  pricesTable,
  chargeData,
  employerForm,
  sacUserData,
  stateModal
});
