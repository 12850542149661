import React, { PureComponent } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getClientProducts } from './request/actions';
import { StyledMainContainer } from '../../components/shared/entity-choice/entityChoice.styled.js';
import { PageContainer } from '../../components/shared/sharedStyles.styles.js';
import BlueSteps from '../../components/Operational/request/BlueSteps';
import Header from '../../components/Header';
import EntityChoiceContent from '../../components/Operational/EntityChoiceContent';

import Tour from 'reactour'
import axios from 'axios';


const breadcrumb = [
  { link: '', label: 'operacional' },
  { link: '/intranet/operacional/solicitacao', label: 'Locações' }
];

const steps = [
  {
    selector: '.container-select-type',
    content: 'O produto Pessoa Física já vem selecionado, mas caso deseje inserir uma consulta PJ basta clicar em Pessoa Jurídica.',
  },
  {
    selector: '.container-cards-products',
    content: 'Selecione o produto disponível e avance',
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class EntityChoice extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      tourOpen: false,
      tourId: null
    }
  }

  componentDidMount() {
    const { getClientProducts } = this.props;
    getClientProducts();
    this.getCheckOnboard();
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'selecionar-produto') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'selecionar-produto',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  goForward = (products, entity) => {
    const { history, location } = this.props;
    const { state } = location;
    let customer = {};

    if (state !== undefined) {
      customer = state.customer;
    }

    history.push(
      {
        pathname: '/intranet/operacional/solicitacao/formulario',
        state: {
          entity,
          products,
          customer
        }
      }
    )
  }

  goBack = () => {
    const {history} = this.props;
    history.push({pathname: "/intranet/operacional/solicitacao"})
  }

  getSubtitle = () => {
    const { location: { state } } = this.props;
    if (state !== undefined) {
      if ("customer" in state) {
        return state.customer.nome;
      }
    }
    return undefined;
  }

  getSteps = () => {
    return [
      // { title: 'pesquisar cliente', status: 'color_green', onClick: () => history.replace('/intranet/operacional/solicitacao/adicionar') },
      { title: 'escolher produto', status: 'color_purple', onClick: () => { } },
      { title: 'formulário', status: 'color_border-light-gray', onClick: () => { } },
      { title: 'resumo da solicitação', status: 'color_border-light-gray', onClick: () => { } },
      { title: 'laudo', status: 'color_border-light-gray', onClick: () => { } }
    ]
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'selecionar-produto'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  render() {
    return (
      <PageContainer>
        <Header
          title="Escolher produto"
          subtitle={this.getSubtitle()}
          breadcrumb={breadcrumb}
          actualLink="Adicionar"
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <BlueSteps steps={this.getSteps()} />
        <StyledMainContainer>
          <EntityChoiceContent
            goForward={this.goForward}
            list={this.props.list}
            showGoBack={true}
            onGoBack={this.goBack}
            cancel={true}
          />
        </StyledMainContainer>
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
        />
      </PageContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ getClientProducts }, dispatch);

const mapStateToProps = (state, ownProps) => ({
  login: state.login,
  ...ownProps,
  ...state.request.products
})

export default connect(mapStateToProps, mapDispatchToProps)(EntityChoice);
