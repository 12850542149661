import React from 'react';
import inputTypes from './inputTypes';
import { Field } from 'redux-form';

const inputHandler = (props) => {
  
  if (props.type === 'composed') {
    const Composed = inputTypes[props.type];
    return <Composed key={props.name} {...props} />;
  }
  
  if (props.type === 'dynamicPhones') {
    const DynamicPhones = inputTypes[props.type];
    return <DynamicPhones key={props.name} {...props} />;
  }
  
  if(props.type === 'actionButton'){
    const ActionButton = inputTypes[props.type];
    return <ActionButton key={props.name} {...props} />;
  }
  
  if (props.type === 'phonesGroup') {
    const PhoneGroup = inputTypes[props.type];
    return <PhoneGroup key={props.name} {...props} />;
  }
  
  const InputComponent = inputTypes[props.type];
  
  return (
    
    <Field
      key={props.name}
      component={InputComponent}
      required={props.isRequired}
      immutableProps={['name', 'label']}
      {...props}
    />
  );
  
};

export default inputHandler;
