import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { StyledTextArea, StyledSubject } from './Card.styled';
import { messageFields } from './cardMessageFields';
import handler from '@bit/wavfichacerta.fichacerta.inputs-functions.input-handler';
import { text, composed, maskedText, textDisabled, textarea} from '@bit/wavfichacerta.fichacerta.inputs.inputs';

import {
  StyledRow
} from '@bit/wavfichacerta.fichacerta.shared-styles.rows';

import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { ButtonForm } from '../../../../components/shared/formStyles.styled';
import { bindActionCreators } from 'redux';
import { sendSacMessage } from '../actions';


class CardMessageForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clickedCardForm: false
    }
  }

renderFields = (fields) => {
    return fields.map((row, index) => (
      <StyledRow key={row.name} align="flex-start" justify="flex-start" customstyle={`margin-top: ${index > 0? ".5rem": "0"};`}>
        {
          row.fields.map((field) => {
            return handler({ text, composed, maskedText, textDisabled, textarea })(field);
          })
        }
      </StyledRow>
    ));
  }

  renderField = (field) => {
    return <StyledTextArea {...field.input} type="text"/>
  }

submitForm = () => {
  const { changedValues, sendSacMessage } = this.props;
  sendSacMessage(changedValues);
}


render() {
    const {changedValues} = this.props;
    let sendDisabled = true
    if(changedValues) {
      if(changedValues.assunto && changedValues.mensagem) {
        sendDisabled = false;
      }
    }

    return (
      <StyledRow>
        <BaseColumn lg={2} xl={2}>
          <StyledSubject>Assunto:</StyledSubject>
        </BaseColumn>
        <BaseColumn lg={10} xl={10}>
          {this.renderFields(messageFields)}
        </BaseColumn>
          <Field name="mensagem" component={this.renderField}/>
        <StyledRow justify='flex-end' align="flex-end">
          <ButtonForm onClick={this.props.toggleCard}>Cancelar</ButtonForm>
          &nbsp;
          <ButtonForm disabled={sendDisabled} onClick={() => {this.props.toggleCard(); this.submitForm() }}>Enviar</ButtonForm>
        </StyledRow>
      </StyledRow>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ sendSacMessage }, dispatch);


const mapStateToProps = (state) => ({
  requirements: state.requirements,
  changedValues: state.form.messageForm !== undefined? state.form.messageForm.values : {},
  
});

const Form = reduxForm({
  form: 'messageForm',
  enableReinitialize: true
})(CardMessageForm);

export default connect(mapStateToProps, mapDispatchToProps)(Form);