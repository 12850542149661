import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 31px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${(props) => {
    const { getMedias: { iphones } } = props.theme;
    return `
    ${iphones()`
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
        `
    }
    `;
  }}
`;

export const UserPanelContainer = styled.div`
  width: auto;
  align-self: flex-end;
  display: flex;
  flex-direction: row;

  ${(props) => {
    const { getMedias: { iphones } } = props.theme;
    return `
    ${iphones()`
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 0 0 42px 0;
        `
    }
    `;
  }}
`;

export const BoxNotification = styled.div`
  align-self: center;
  margin-right: 7px;

  & > ul {
    display: none;
  }

  & > div > svg {
    &:hover {
      ul < div < & {
        display: block;
      }
    }
  }
`;

export const BoxWelcome = styled.div`
  display: flex;
  flex-direction: row;

  & > div:last-child {
    & > ul { display: none; }
  }
`;

export const BoxExit = styled.div`
  align-self: center;

  ${(props) => {
    const { getMedias: { iphones } } = props.theme;
    return `
    ${iphones()`
      margin: 40px 0 0 140px;
      `
    }
  `;
  }};
`;

export const ContainerIcons = styled.div`
  width: 22px;
  position: relative;
  cursor: pointer;
`;

export const NotifyContainer = styled.div`
  position: absolute;
  top: -2px;
  right: 3px;
  width: 10px;
  height: 10px;

  & > svg { 
    width: .8rem;

    & > text { 
      font-size: 570px;
      font-family: 'Calibri'; 
    }
  }
`;

export const ContaineGuestIcon = styled.div`
  width: 26px;
  align-self: center;
  cursor: pointer;
  & > svg {
    & > circle, & > path {
      fill: none;
      stroke: ${props => props.theme.color_gray};
      stroke-width: 30;
    }
  }
`;

export const ContainerInfoUser = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: .5rem;
  align-self: center;
`;

export const SpanHello = styled.div`
  color: ${props => props.color && props.color || props.theme.color_gray};
  font-size: .68rem;
  line-height: .7rem;
`;
  
export const NameUser = styled.a`
  color: ${props => props.color && props.color || props.theme.color_gray} !important;
  font-weight: 600;
  padding-right: 10px !important;
  font-size: 13px;
  line-height: 10px;

  &:after {
    width: 10px;
    height: 4px;
  }
`;

export const ExitIconContainer = styled.div`
  width: 16px;
  font-size: 11px;
  line-height: 10px;
  color: ${props => props.color && props.color || props.theme.color_gray};
  cursor: pointer;

  & > svg {
    & > path {
      fill: ${props => props.color && props.color || props.theme.color_gray}
    }
  }
`;
