import React, { Component } from 'react'
import styled from 'styled-components';

export default class MessagesStatus extends Component {

  static defaultProps = { status : "" }

  getMessage(){
    const { name, status } = this.props
    switch (status) {
      case "ATIVO":
        return <Active color="color_green" name={name}/>
      case "SUSPENSO":
        return <Suspendend color="color_orange-dark" name={name}/>
      case "BLOQUEADO":
        return <Blocked color="color_red" name={name}/>
      case "INATIVO":
        return <Inactive color="color_gray" name={name}/>
      default:
        return null;
    }
  }

  render() {
    return (
      <div style={{visibility: this.props.status ? "visible" : "hidden"}} className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          {this.getMessage()}
        </div>
        </div>
    )
  }
}

const MessageContainer = styled.div`
  border: 1px solid ${props => props.theme[props.color]};
  color: ${props => props.theme[props.color]};
  padding: .5rem .5rem;
`;

const MessageParagraph = styled.p`
  font-size: .85rem;
  margin-bottom: 0;
`;

const Active = ({ name, color }) => (
  <MessageContainer color={color}>
    <MessageParagraph>
      <strong>Status ATIVO: </strong>
      o <strong>{name}</strong> tem acesso normal ao sistema, com suas permissões de acesso vinculado ao seu perfil.
    </MessageParagraph>
  </MessageContainer>
);


const Suspendend = ({ name, color }) => (
  <MessageContainer color={color}>
    <MessageParagraph>
      <strong>Status SUPENSO: </strong>
      o <strong>{name}</strong> tem acesso normal ao sistema com permissões de visualização apenas.
    </MessageParagraph>
  </MessageContainer>
);

const Blocked = ({ name, color }) => (
  <MessageContainer color={color}>
    <MessageParagraph>
      <strong>Status BLOQUEADO: </strong>
      o <strong>{name}</strong> não tem mais acesso ao sistema. Ele não poderá fazer o login.
    </MessageParagraph>
  </MessageContainer>
);

const Inactive = ({ name, color }) => (
  <MessageContainer color={color}>
    <MessageParagraph>
      <strong>Status INATIVO: </strong>
      o <strong>{name}</strong> não tem mais acesso ao sistema. Ele não poderá fazer o login.
    </MessageParagraph>
  </MessageContainer>
);