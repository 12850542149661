import React from 'react';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { ResumeSpan } from './ChargeStyles';

const Resume = (props) => {
  let renderResume = null
  const { master } = props
  if(props.resume && props.resume.produtos){
    renderResume = Object.keys(props.resume.produtos).map((key, index) => (
      <StyledRow>
        <BaseColumn xs="2">
          <ResumeSpan>{props.resume.produtos[key].produto_qtd}</ResumeSpan>
        </BaseColumn>
        <BaseColumn xs="6">
          <ResumeSpan style={{fontWeight: 400}}>{key}</ResumeSpan>
        </BaseColumn>
        <BaseColumn xs="4">
          {/* {master && <ResumeSpan>R$: {props.resume.produtos[key].produto_valor}</ResumeSpan>} */}
        </BaseColumn>
        <BaseColumn xs="2">
          <ResumeSpan>{props.resume.produtos[key].reconsultas_qtd}</ResumeSpan>
        </BaseColumn>
        <BaseColumn xs="6">
          <ResumeSpan style={{fontWeight: 400}}>Reconsultas</ResumeSpan>
        </BaseColumn>
        <BaseColumn xs="4">
          {/* {master && <ResumeSpan>R$: {props.resume.produtos[key].reconsultas_valor}</ResumeSpan>} */}
        </BaseColumn>
      </StyledRow>
    ))
  }

  return renderResume
}

export default Resume