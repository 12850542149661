import { createStore, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';

import configPersist from './persistConfig';
import { createBrowserHistory } from 'history';
import reducers from './reducers';

//Middlewares
import logger from 'redux-logger';
import thunk from "redux-thunk";
import dirtyFormField from '../middlewares/dirtyFormFields';

export const history = createBrowserHistory();

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
export const persistedReducers = configPersist('root', { whitelist: ['requirements'] }, reducers(history));

const middlewares = [];

if (process.env.REACT_APP_ENV !== 'prod') {
  middlewares.push(logger);
}

export const store = applyMiddleware(
  ...middlewares,
  dirtyFormField,
  thunk,
  routerMiddleware(history)
)(createStore)(connectRouter(history)(persistedReducers), devTools);

export const persistor = persistStore(store);
