import React from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { Title, LineTitle } from './report.styles';

export default (props) => (
  <StyledRow>
    <BaseColumn>
      <LineTitle />
    </BaseColumn>
    <Title {...props} style={{position: 'relative', ...props.style}}>{ props.children }</Title>
    <BaseColumn>
      <LineTitle />
    </BaseColumn>
  </StyledRow>
)
