import React from 'react';
import { BaseScaleButton, BigScaleButton, RegularScaleButton } from '../../shared/sharedStyles.styles'
import { withTheme } from 'styled-components';

const handleScaleSize = (size) => {
  if (size === 'md') {
    return RegularScaleButton;
  }

  if (size === 'lg') {
    return BigScaleButton;
  }

  return BaseScaleButton;
}
/**
 * this customProps are props for Components that will extend this component
 */
const ScaleButton = ({ scaleSize = 'lg', onClick, theme, children, disabled, ...customProps }) => {
  const Button = handleScaleSize(scaleSize);
  return (
    <Button
      borderColor="transparent"
      background="transparent"
      onClick={onClick}
      disabled={disabled === undefined ? theme.disabled : disabled}
      {...customProps}
    >
      {children}
    </Button>
  );
}

export default withTheme(ScaleButton);
