
export const onGetCustomerData = (state) => ({
  ...state,
  isRequesting: true,
  error: undefined
});

export const onGetCustomerDataSuccess = (state, payload) => ({
  ...state,
  isRequesting: false,
  error: false,
  data: payload
});

export const onGetCustomerDataFailed = (state) => ({
  ...state,
  isRequesting: false,
  error: true
});

export const onSaveCustomerData = (state) => ({
  ...state,
  isSaving: true,
  errorSaving: undefined
});

export const onSaveCustomerDataSuccess = (state) => ({
  ...state,
  isSaving: false,
  errorSaving: false
});

export const onSaveCustomerDataFailed = (state) => ({
  ...state,
  isSaving: false,
  errorSaving: true
});
