import {
  simpleNonRequiredText,
  nonRequiredCEP,
  simpleNonRequiredSelect,
  simpleDisabledText,
  simpleRequiredSelect,
  simpleNonRequiredMoney,
  simpleRequiredMoney
} from '../../../../../components/shared/fields';
import { CPJCNPJWarning } from '../../../../../utils/warnings';
import { onlyNumber } from '../../../../../utils/formatter';

export default {
  "codigo_imovel": simpleNonRequiredText({ size: 'field-fc-small field-fc-sm-6', name: 'codigo_imovel', label: 'Cód. do imóvel' }),
  "cep": nonRequiredCEP('field-fc-small field-fc-sm-6'),
  "logradouro": simpleNonRequiredText({ size: 'field-fc-medium field-fc-sm-9', name: 'logradouro', label: 'Endereço' }),
  "numero": simpleNonRequiredText({ size: 'field-fc-small field-fc-sm-3', name: 'numero', label: 'Número' }),
  "complemento": simpleNonRequiredText({ size: 'field-fc-small field-fc-sm-6', name: 'complemento', label: 'Complemento' }),
  "bairro": simpleNonRequiredText({ size: 'field-fc-small field-fc-sm-6', name: 'bairro', label: 'Bairro' }),
  "cidade": simpleNonRequiredText({ size: 'field-fc-small field-fc-sm-9', name: 'cidade', label: 'Cidade' }),
  "uf": simpleNonRequiredSelect({ size: 'field-fc-small field-fc-sm-3', name: 'uf', label: 'Estado' }),
  "aluguel": simpleRequiredMoney({ size: 'field-fc-small field-fc-sm-6', name: 'aluguel', label: 'Aluguel', placeholder: 'R$' }),
  "condominio": simpleNonRequiredMoney({ size: 'field-fc-small field-fc-sm-6', name: 'condominio', label: 'Condomínio', placeholder: 'R$' }),
  "iptu": simpleNonRequiredMoney({ size: 'field-fc-small field-fc-sm-6', name: 'iptu', label: 'IPTU (Mensal)', placeholder: 'R$' }),
  "total_locacao": simpleDisabledText({ size: 'field-fc-small field-fc-sm-6 read-only', name: 'total_locacao', label: 'Total da locação', placeholder: 'R$' }),
  "tipo_pretendente": simpleRequiredSelect({ size: 'field-fc-small', name: 'tipo_pretendente', label: "Pretendente a", placeholder: 'selecione' }),
  "tipo_imovel": simpleRequiredSelect({ size: 'field-fc-small field-fc-sm-6', name: 'tipo_imovel', label: "Tipo de imóvel", placeholder: 'selecione' }),
  "finalidade_imovel": simpleRequiredSelect({ size: 'field-fc-small', name: 'finalidade_imovel', label: "Finalidade do imóvel", placeholder: 'selecione' }),
  "uso_imovel": simpleRequiredSelect({ size: 'field-fc-small', name: 'uso_imovel', label: "Uso do imóvel", placeholder: 'selecione' }),
  "proprietario": simpleNonRequiredText({ size: 'field-fc-medium', name: 'proprietario', label: 'Proprietário' }),
  "cpf_proprietario": simpleNonRequiredText({ size: 'field-fc-medium', name: 'cpf_proprietario', label: 'CPF/CNPJ proprietário', warn: CPJCNPJWarning, normalize: onlyNumber })
};