import { toastr } from 'react-redux-toastr';
import { goBack, push } from 'connected-react-router';
import * as types from './types';
import { actionCreator, generalActionTypes,extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { getRequestData, updateTenancy } from '../../../../../../services/operational';
import { replace } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../../../../../auth/login/actions/types.js';

const { NON_HANDLED_ERROR } = generalActionTypes;


export const resetForm = () => actionCreator(types.TENANCY_FORM_RESET);

export const getRequest = (idRequest) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.GET_DATA_REQUEST));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await getRequestData(token.access_token, idRequest);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Editar locação', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.GET_DATA_FAILED));
        }
      } else {
        dispatch(actionCreator(types.GET_DATA_SUCCESS, payload.data.data));
      }
    } catch (error) {
      toastr.error('Editar locação', 'Não foi possível retornar os dados desta locação.');
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível retornar os dados desta locação.'));
    }
  }
}

export const saveTenancy = (id, requestObj, reInclusion) => {
  return async (dispatch, getState) => {
    try {
      const { login: { user: { token } } } = getState();

      dispatch(actionCreator(types.TENANCY_FORM_REQUESTING));
      const payload = await updateTenancy(token.access_token, id, requestObj);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(types.TENANCY_FORM_FAILED, extractErrorMessages(payload.data.message)));
          toastr.warning('Editar locação', extractErrorMessages(payload.data.message));
        }
      } else {
        toastr.success('Editar locação', payload.data.message);
        if(reInclusion){
          dispatch(push({pathname: "/intranet/operacional/solicitacao/resumo", state: {request: id}}))
        }else{
          dispatch(goBack());
        }
        setTimeout(() => {
          dispatch(actionCreator(types.TENANCY_FORM_SUCCESS));
        }, 100);
      }
    } catch (error) {
      toastr.error('Editar locação', 'Não foi possível salvar as informações no momento.');
      dispatch(actionCreator(types.TENANCY_FORM_FAILED, extractErrorMessages(error)));
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível salvar as informações no momento.'));
    }
  }
}
