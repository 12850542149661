import React, { PureComponent } from 'react';
import { debounce } from 'lodash';
import { DateContainerInput, DateInput } from './SearchStyles';

export default class DateSearchField extends PureComponent {
  static defaultProps = {
    submitSearch: () => console.warn('Missing callback function'),
    onKeyUp: () => {},
    onStartTyping: () => {},
    placeholder: ''
  }

  state = { prevText: '' }

  render() {
    return (
      <DateContainerInput>
        <DateInput
          onChange={e => this.props.submitSearch(e.target.value)}
          placeholder={this.props.placeholder}
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        />
      </DateContainerInput>
    );
  }
}
