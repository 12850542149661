import React, { Component } from "react";
import {
  BorderedButton,
  UnborderedButton,
} from "../../components/shared/customTable.styled";
import CreditBar from "./components/CreditBar";
import { HeaderBar, HeaderText, CreditBarMobileWrapper } from "./components/creditBar.styled";
import {
  StyledCard,
  StyledCardBody,
  StyledRowMobile,
  StyledTitleMobile,
  StyledLineMobile,
  StyledHr
} from "./components/styled";
import { BaseColumn } from "@bit/wavfichacerta.fichacerta.shared-styles.columns";

export default class UsersRow extends Component {
  renderHeaderCredit = (available, used) => (
    <HeaderBar>
      <HeaderText>R$ {available} / R$ {used}</HeaderText>
    </HeaderBar>
  );

  renderBorderedButton = () => {
    const { type, onClickType } = this.props;

    return (
      <BorderedButton onClick={onClickType}>
        {type.toLowerCase()}
      </BorderedButton>
    );
  };

  renderDisabledButton = () => {
    const { type } = this.props;

    return (
      <UnborderedButton disabled={true}>{type.toLowerCase()}</UnborderedButton>
    );
  };

  renderBorderedButtonStatus = () => {
    const { onClickStatus, status } = this.props;

    let colorButton = "";
    switch (status) {
      case "ATIVO":
        colorButton = "color_green";
        break;
      case "SUSPENSO":
        colorButton = "color_systemic_feedback_regular";
        break;
      default:
        colorButton = "color_red";
        break;
    }

    return (
      <BorderedButton onClick={onClickStatus} color={colorButton}>
        {status}
      </BorderedButton>
    );
  };

  renderUnborderedButtonStatus = () => {
    return null;
  };

  render() {
    console.log(this.props.userId, this.props.loggedId);
    const {
      onClickClock,
      nome,
      funcao,
      typeUser,
      credito,
      usedCreditPercentage,
      Icon,
      onClickEdit,
      condicao,
      credito_utilizado,
      credito_disponivel
    } = this.props;

    let ButtonType;
    let ButtonStatus;

    if (typeUser === "ADICIONAL") {
      ButtonType = this.renderDisabledButton();
      ButtonStatus = this.renderUnborderedButtonStatus();
    } else if (this.props.userId === this.props.loggedId) {
      ButtonType = this.renderBorderedButton();
      ButtonStatus = this.renderUnborderedButtonStatus();
    } else {
      ButtonType = this.renderBorderedButton();
      ButtonStatus = this.renderBorderedButtonStatus();
    }

    return (
      <StyledCard>
        <StyledCardBody>
          <StyledRowMobile>
            <BaseColumn xs="12" sm="12">
              <StyledTitleMobile>Nome</StyledTitleMobile>
              <StyledLineMobile onClick={onClickEdit}>{nome}</StyledLineMobile>
            </BaseColumn>
          </StyledRowMobile>
          <StyledHr />
          <StyledRowMobile>
            <BaseColumn xs="10" sm="10">
              <StyledTitleMobile>Função</StyledTitleMobile>
              <StyledLineMobile>{funcao}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="2" sm="2">
              <StyledLineMobile>
                <Icon
                  onClick={onClickClock}
                  width="2rem"
                  id={nome.replace(/ /g, "_")}
                />
              </StyledLineMobile>
            </BaseColumn>
          </StyledRowMobile>
          <StyledHr />
          <StyledRowMobile>
            <BaseColumn xs="4" sm="4">
              <StyledTitleMobile>Tipo</StyledTitleMobile>
              <StyledLineMobile>{ButtonType}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="4" sm="4">
              <StyledTitleMobile>Status</StyledTitleMobile>
              <StyledLineMobile>{ButtonStatus}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="4" sm="4">
              <StyledTitleMobile>Consumo</StyledTitleMobile>
              <StyledLineMobile>
                {condicao === "POSPAGO" && typeUser === "MASTER" && (
                  <CreditBarMobileWrapper>
                    {this.renderHeaderCredit(
                      credito_disponivel,
                      credito_utilizado
                    )}
                    <CreditBar
                      availableCredit={credito}
                      usagePercentage={usedCreditPercentage}
                    />
                  </CreditBarMobileWrapper>
                )}
              </StyledLineMobile>
            </BaseColumn>
          </StyledRowMobile>
        </StyledCardBody>
      </StyledCard>
    );
  }
}
