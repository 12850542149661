import styled from 'styled-components';

export const CreditContainer = styled.div`
  width: 100%;
  height: .5rem;
  border-radius: 5px;
  ${props => {
    if (props.hasColor) {
      return `background-color: ${props.activeColor? props.theme[props.activeColor] : props.theme.color_purple};`;
    } else {
      return `
        border: 1px solid ${props.theme.color_gray};
        background-color: ${props.theme.color_white};
      `;
    }
  }}
`;

export const TextIcon = styled.h2`
    text-transform: uppercase;
    font-size: 11px;
    color: #6c757d;
    font-weight: bold;

    @media (max-width: 800px) {
      font-weight: 400;
    }
  `

export const UsedCreditBar = styled.div`
  width: ${props => props.width};
  background-color: ${props => props.activeColor? props.theme[props.activeColor] : props.theme['color_systemic_feedback_regular']};
  height: .5rem;
  border-radius: 5px;

  @media (max-width: 800px) {
    background-color: ${props => props.activeColor? props.theme[props.activeColor] : props.theme['color_used']};
  }
`;


export const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderText = styled.span`
  font-size: 11px;
  color: ${props => props.theme.color_gray};
`;

export const TitleCreditBar = styled.div`
  font-size: 12px;
  color: ${props => props.theme.color_gray};
  font-weight: 500;
`;

export const SubtitleBox = styled.div`
  font-size: 12px;
  color: ${props => props.theme.color_gray};
  display: flex;
  ${props => props.isColumn? `flex-direction: column;` : `flex-direction: row;`}

  ${props => {
    if (props.semiBold) {
      return `font-weight: 500;`;
    }
  }}

  ${props => {
    if (props.colorBox) {
      return `
        &:before {
          width: .65rem;
          height: .6rem;
          background-color: ${props.theme[props.colorBox]};
          content: ' ';
          align-self: center;
          margin-right: .3rem;
        }
      `;
    }
  }}

  .text-hidden-desktop {
    display: none;
  }

  @media (max-width: 800px) {
    font-size: 11px;
    font-weight: 400;

    &:before {content: none;}

    .text-hidden-mobile {
      display: none;
    }
    .text-hidden-desktop {
      display: block;
    }
  }
`;

export const LateralBar = styled.div`
  display: none;
  color: #7f7f7f;
  font-size: 10px;
  margin-left: 3px;

  @media (max-width: 800px) {
    display: block;
  }
`

export const CreditWarnText = styled.div`
  margin-left: 11px;
  font-size: 10px;
  color: ${props => props.theme.color_gray};
`;
