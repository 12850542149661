import React, { PureComponent, Fragment } from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  TitleBox,
  BorderedBox,
  AnalythicOList,
  AnalythicLineItalic,
  SubtitleCard,
  SubtitleSemiBold
} from '../analythic.styled';
import {
  getProfileGiven,
  getProfileRecommendations
} from './products/fcAnalise';
import { socioEconomic } from '../../../../pages/operational/request/report/singleReport/actions';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../shared/TourTooltip';

class ProfileContainer extends PureComponent {
  constructor(props){
    super(props)

    this.economicProfile = React.createRef()
  }

  componentDidMount(){
    this.props.socioEconomic(this.economicProfile.current)
  }
  
  renderRecommendations = () => {
    const { data } = this.props;
    const recommendations = getProfileRecommendations(data);

    return (
      <Fragment>
        <TitleBox style={{color: '#000'}}>{recommendations.title}:</TitleBox>
        <AnalythicOList>
          {recommendations.obs_list.map((item) => (<AnalythicLineItalic style={{color: '#000'}}>{item}</AnalythicLineItalic>))}
        </AnalythicOList>
      </Fragment>
    );
  }
  
  render() {
    const { data } = this.props;
    const profile = getProfileGiven(data);
    const recommendations = getProfileRecommendations(data);
    const productFcReport = data.pretendente.produtos.ativo.filter(product => product.id === 1 && product.status !== 'INCLUIDO')
    const productFcDoc = data.pretendente.produtos.ativo.filter(product => product.id === 2 || product.id === 7)
    const productFcEssencial = data.pretendente.produtos.ativo.filter(product => product.id === 3 && product.status !== 'INCLUIDO')
    const productFcEmpresa = data.pretendente.produtos.ativo.filter(product => product.id === 4)
    const productFcBasico = data.pretendente.produtos.ativo.filter(product => product.id === 5)
    let titleOcupacao = profile.job
    let titleEscolaridade = profile.scholarity
    let titleEstadoCivil = profile.maritalStatus
    if(productFcDoc.length && productFcDoc[0].ativo && data.laudo.produtos.fcrenda.resultado && data.laudo.produtos.fcrenda.funcao) {
      titleOcupacao = data.laudo.produtos.fcrenda.funcao + ' (editado)'
    }
    if(productFcDoc.length && productFcDoc[0].ativo && data.laudo.produtos.fcrenda.resultado && data.laudo.produtos.fcrenda.escolaridade) {
      titleEscolaridade = data.laudo.produtos.fcrenda.escolaridade + ' (editado)'
    }
    if(productFcDoc.length && productFcDoc[0].ativo && data.laudo.produtos.fcrenda.resultado && data.laudo.produtos.fcrenda.estado_civil) {
      titleEstadoCivil = data.laudo.produtos.fcrenda.estado_civil + ' (editado)'
    }

    return (
      <StyledRow margin="1rem 0 0 0" className='font-13-print'>
        <ContainerGray ref={this.economicProfile} className="socioEconomic">
          <StyledRow align="initial" margin="0">
            <SubtitleCard style={{color: '#000'}}>DADOS SOCIOECONÔMICOS <SubtitleSemiBold>ENCONTRADOS</SubtitleSemiBold> NO MERCADO</SubtitleCard>          
            <StyledRow>
            <SubtitleCard style={{color: '#000'}}><i>(Podem não representar a situação atual do pretendente)</i></SubtitleCard>
              <StyledRow>
              <BaseColumn padding="0 .2rem">
                  <AnalythicList>
                    <AnalythicLine className="renda-presumida" style={{color: '#000'}}>
                      <LineTitle maxWidth="205px">RENDA PRESUMIDA (Mercado)</LineTitle>
                      <span style={{position: 'relative'}}>
                        {profile.amount}

                        {/* <TourTooltip
                          target="tooltip-renda-mensal-presumida"
                          style={{right: -25, top: -10}}
                        />
                        <ReactTooltip id="tooltip-renda-mensal-presumida" place="top" type="dark" effect="float">
                          <span>Detectamos a renda presumida de mercado de acordo com o perfil de consumo, ela pode <br /> te ajudar em seu processo de aprovação. Para mais dicas sobre este assunto, fale com nosso suporte.</span>
                        </ReactTooltip> */}
                      </span>
                    </AnalythicLine>
                  </AnalythicList>
                </BaseColumn>
                <BaseColumn padding="0 .2rem">
                  <AnalythicList>
                    <AnalythicLine style={{color: '#000'}}>
                      <LineTitle maxWidth="85px">Ocupação</LineTitle>
                      <span>{titleOcupacao}</span>
                    </AnalythicLine>
                  </AnalythicList>
                </BaseColumn>
              </StyledRow>

              <StyledRow>
                <BaseColumn padding="0 .2rem">
                  <AnalythicList>
                    <AnalythicLine style={{color: '#000'}} background="transparent">
                      <LineTitle maxWidth="205px">Escolaridade</LineTitle>
                      <span>{titleEscolaridade}</span>
                    </AnalythicLine>
                  </AnalythicList>
                </BaseColumn>
                <BaseColumn padding="0 .2rem">
                  <AnalythicList>
                    <AnalythicLine style={{color: '#000'}} background="transparent">
                      <LineTitle maxWidth="85px">Estado civil</LineTitle>
                      <span>{titleEstadoCivil}</span>
                    </AnalythicLine>
                  </AnalythicList>
                </BaseColumn>
              </StyledRow>

              {Array.isArray(recommendations.obs_list) && recommendations.obs_list.length > 0 &&
                <BorderedBox xs={12} md={12} lg={12} xl={12}>
                  { this.renderRecommendations() }
                </BorderedBox>
              }
              
            </StyledRow>
          </StyledRow>
        </ContainerGray>
      </StyledRow>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  socioEconomic
}, dispatch)

export default connect(null, mapDispatchToProps)(ProfileContainer)