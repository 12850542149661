import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};

  & > circle { fill: #ff7200; }
  & > path:nth-child(1) { fill: #471e02; }
  & > path:nth-child(2) { fill: #471d02; }
  & > path:nth-child(3) { fill: #471e02; }
`;

export default (props) => (
  <SVG {...props} viewBox="0 0 137.25 137.25">
    <circle class="cls-1" cx="68.63" cy="68.63" r="68.63"/>
    <path class="cls-2" d="M77.49,91q18.49,0,37,0c2.25,0,5.26-.59,5.4,2.78.16,3.82-3.07,3.19-5.47,3.19q-36.74,0-73.48,0c-2.33,0-5.31.41-5.43-2.79-.14-3.65,3-3.2,5.51-3.2Z" transform="translate(-9.17 -6)"/>
    <path class="cls-3" d="M51.36,67.84c0,2,.08,4,0,6a4.69,4.69,0,0,1-4.72,4.84,4.66,4.66,0,0,1-5.21-4.34,93.09,93.09,0,0,1,0-13.43c.2-2.86,2.28-4.36,5.2-4.24s4.59,1.78,4.74,4.72C51.45,63.52,51.37,65.68,51.36,67.84Z" transform="translate(-9.17 -6)"/>
    <path class="cls-4" d="M104,67a46.69,46.69,0,0,1,0-6c.37-2.86,2.19-4.4,5.13-4.34,2.7.06,4.58,1.49,4.74,4.15a127.11,127.11,0,0,1,0,13.44c-.15,2.95-2.39,4.49-5.09,4.42A4.72,4.72,0,0,1,104,73.93c-.15-2.32,0-4.65,0-7Z" transform="translate(-9.17 -6)"/>
  </SVG>
);
