import { nonRequiredCEP } from '@bit/wavfichacerta.fichacerta.fields-templates.cep';
import { simpleRequiredSelect } from '@bit/wavfichacerta.fichacerta.fields-templates.select';
import { simpleNonRequiredText } from '@bit/wavfichacerta.fichacerta.fields-templates.simple-text';
import { defaultNonRequiredPhoneWithoutDDI } from '../../../../../components/shared/fcFields';

export default {
  "cep_residencial": nonRequiredCEP('field-fc-small', '', 'CEP residencial', 'cep_residencial'),
  "cep": nonRequiredCEP('field-fc-small', '', 'CEP', 'cep_residencial'),
  "logradouro": simpleNonRequiredText({ size: 'field-fc-medium', name: 'logradouro', label: 'Endereço' }),
  "numero": simpleNonRequiredText({ size: 'field-fc-small', name: 'numero', label: 'Número' }),
  "complemento": simpleNonRequiredText({ size: 'field-fc-small', name: 'complemento', label: 'Complemento' }),
  "bairro": simpleNonRequiredText({ size: 'field-fc-small', name: 'bairro', label: 'Bairro' }),
  "cidade": simpleNonRequiredText({ size: 'field-fc-small', name: 'cidade', label: 'Cidade' }),
  "uf": simpleRequiredSelect({ size: 'field-fc-small', name: 'uf', label: 'Estado' }),
  "telefone": defaultNonRequiredPhoneWithoutDDI('telefone.numero', 'Telefone residencial', 'field-fc-medium'),
  "celular": defaultNonRequiredPhoneWithoutDDI('celular.numero', 'Celular', 'field-fc-medium'),
  "email": simpleNonRequiredText({ size: 'field-fc-medium', name: 'email', label: 'E-mail' }),
  "telefones": { type: 'dynamicPhonesRequest', size: "field-fc-medium" }
};