import * as types from '../actions/types';
import {
  closeModal,
  openModal
} from './reducers';

const initialState = {
  isOpen: false,
  idProduct: undefined,
  products: [],
  applicantId: undefined
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.OPEN_MODAL:
      return openModal(state, payload);
    case types.CLOSE_MODAL:
      return closeModal(state);
    default:
      return state;
  }
}
