import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import LogoutSVG from "../../components/Header/UserPanel/svg/Logout";
import Icons from '../../components/Menu/Icons';
import DrawerIcon from '../../components/Menu/svg/DrawerIcon';
import close from '../../img/svg/ico-close-interno.svg';
import iconUserMenuMobile from './../../img/png/ico-user-menu-mobile.png'
import logoPng from './../../img/png/Logo-fc-analise-so-fc.png'
import { logoutUser } from '../../pages/auth/login/actions';
import { 
  BoxExit, 
  CollapsableContainer, 
  ExitIconContainer, 
  ExitTitleContainer, 
  Lightbox, 
  MainNav, 
  MainTitle, 
  MobileNewRequestContainer, 
  NameUser, 
  NewRequestContainer, 
  Sidebar, 
  SpanHello, 
  StyledBrand, 
  StyledNav, 
  StyledNavbarToggler, 
  TextRequest, 
  TitleRequestButton, 
  UserIconContainer 
} from './menu.styles';
import MenuCollapsableItem from './MenuCollapsableItem';
import menuItems, { menuItemsMobile, menuItemsSemParecer, menuItemsUserMaster } from './menuItems';

import { toastr } from 'react-redux-toastr';

class Menu extends Component {
  state = {
    toggle: false,
    isMobile: false
  }

  componentDidMount(){
    this.setState({
      isMobile: window.matchMedia('(max-width: 800px)').matches
    });
  }

  renderName = () => {
    const { login } = this.props;
    let name = '';
    if (typeof login === 'object' && login) {
      if (login.user && "usuario" in login.user) {
        const { user: { usuario } } = login;
        name = usuario.nome.split(' ')[0];
      }
    }
    return name;
  }

  renderUserId = () => {
    const { login } = this.props;
    let userId = '';
    if (typeof login === 'object' && login) {
      if (login.user && "usuario" in login.user) {
        const { user: { usuario } } = login;
        userId = usuario.id;
      }
    }
    return userId;
  }

  mapMenuItems = () => {
    const { login } = this.props;
    let menu = menuItemsSemParecer;
    if(login.user.usuario.master) {
      menu = menuItemsUserMaster
    }
    if(login.user.usuario.recomendacao_ativada && login.user.usuario.master) {
      menu = menuItems
    }
    const menuItemsOnReady = this.state.isMobile && menuItemsMobile || menu
    if (Array.isArray(menuItemsOnReady) && menuItemsOnReady.length > 0) {
      const mappedItems = menuItemsOnReady.map(item => (
        <MenuCollapsableItem
          resetMenu={this.resetMenu}
          key={item.title}
          title={item.title}
          subItems={item.subItems}
        />
      ));
      return mappedItems;
    }

    return null;
  }

  toggle = () => {
    this.setState({
      toggle: !this.state.toggle
    });
  }

  getTitleName = () => {
      const { userData } = this.props;
      if (Object.keys(userData).length > 0) {
        return userData.pessoa.nome;
      }
    return "...";
  }

  getTitleRequestId = () => {
    const { routerState } = this.props;
    if (routerState.location.state !== undefined) {
      return routerState.location.state.request || routerState.location.state.requestId;
    }
  return "...";
}

  resetMenu = () => {
    this.toggle();
  }
  
  
  handleTitleMenu = () => {
    const paths = [
      {pathname: '^/intranet/dashboard$', title: 'dashboard'},
      {pathname: '^/intranet/operacional/solicitacao/adicionar/selecionar-produto$', title: 'escolha o produto', backArrow: false},
      {pathname: '^/intranet/operacional/solicitacao$', title: 'locações', backArrow: false}, 
      {pathname: '^/intranet/cadastro/dados-cliente$', title: 'dados do cliente', backArrow: false},
      {pathname: '^/intranet/cadastro/dados-comerciais$', title: 'dados comerciais', backArrow: false},
      {pathname: '^/intranet/cadastro/customizar-recomendacoes$', title: 'pareceres', backArrow: false},
      {pathname: '^/intranet/cadastro/usuarios/editar/.*$', title: this.getTitleName(), backArrow: false},
      {pathname: '^/intranet/cadastro/usuarios/adicionar$', title: "adicionar usuário", backArrow: false},
      {pathname: '^/intranet/cadastro/usuarios$', title: 'usuários', backArrow: false},
      {pathname: '^/intranet/operacional/solicitacao/formulario.*$', title: 'formulário', backArrow: false},
      {pathname: '^/intranet/operacional/solicitacao/resumo/pretendente.*$', title: `editar pretendente`, backArrow: {pathname: '/intranet/operacional/solicitacao/resumo', state:{ request: this.getTitleRequestId()}}},
      {pathname: '^/intranet/operacional/solicitacao/resumo.*$', title: `resumo da locação (ID ${this.getTitleRequestId()})`, backArrow: '/intranet/operacional/solicitacao'},
      {pathname: '^/intranet/operacional/solicitacao/laudo$', title: `laudo (ID ${this.getTitleRequestId()})`, backArrow: {pathname: '/intranet/operacional/solicitacao/resumo', state:{ request: this.getTitleRequestId()}}},
      // {pathname: '^/intranet/consumo/precos$', title: 'preços', backArrow: false},
      {pathname: '^/intranet/garantias/fcfianca$', title: 'fc fiança', backArrow: false},
      {pathname: '^.*$', title: 'default'},
    ];
    return paths.filter(path => {
      return window.location.pathname.match(path.pathname);
    })[0].title
  }

  handleBackArrow = () => {
    const paths = [
      {pathname: '^/intranet/dashboard$', title: 'dashboard'},
      {pathname: '^/intranet/operacional/solicitacao/adicionar/selecionar-produto$', title: 'escolha o produto', backArrow: false},
      {pathname: '^/intranet/operacional/solicitacao$', title: 'locações', backArrow: false}, 
      {pathname: '^/intranet/cadastro/dados-cliente$', title: 'dados do cliente', backArrow: false},
      {pathname: '^/intranet/cadastro/dados-comerciais$', title: 'dados comerciais', backArrow: false},
      {pathname: '^/intranet/cadastro/customizar-recomendacoes$', title: 'recomendações', backArrow: false},
      {pathname: '^/intranet/cadastro/usuarios$', title: 'usuários', backArrow: false},
      {pathname: '^/intranet/cadastro/usuarios/.*$', title: this.getTitleName(), backArrow: false},
      {pathname: '^/intranet/operacional/solicitacao/resumo/pretendente.*$', title: `editar pretendente`, backArrow: false},
      {pathname: '^/intranet/operacional/solicitacao/formulario.*$', title: 'formulário', backArrow: false},
      {pathname: '^/intranet/operacional/solicitacao/resumo.*$', title: `resumo da locação (ID ${this.getTitleRequestId()})`, backArrow: '/intranet/operacional/solicitacao'},
      {pathname: '^/intranet/operacional/solicitacao/laudo$', title: `laudo (ID ${this.getTitleRequestId()})`, backArrow: {pathname: '/intranet/operacional/solicitacao/resumo', state:{ request: this.getTitleRequestId()}}},
      // {pathname: '^/intranet/consumo/precos$', title: 'preços', backArrow: false},
      {pathname: '^.*$', title: 'default'},
    ];

    return paths.filter(path => {
      return window.location.pathname.match(path.pathname);
    })[0].backArrow
  }

  handleUsersSuspenso = () => {
    const status = this.props.login.user.usuario.status
    const statusCliente = this.props.customer && this.props.customer.status

    if(statusCliente === 'SUSPENSO' || statusCliente === 'INATIVO' || status === 'SUSPENSO') {
      toastr.warning('Status', 'Sua conta está SUSPENSA')
    } else {
      this.props.history.push({pathname: '/intranet/operacional/solicitacao/adicionar/selecionar-produto'});
      this.toggle()
    }
    
  }

  render() {
    let backArrow = this.handleBackArrow();
    let logo;

    if (backArrow && this.state.isMobile) {
       logo = <Link to={backArrow}><Icons.BackArrow /></Link>;
    } else {
       logo = <Link to="/intranet/dashboard">
                <img alt="" src={logoPng} style={{height: 28, position: 'absolute', top: 20, left: 10}} className='no-expanded-logo' />
                <img alt="" src={require('./../../img/png/logo-fc-analise.png')} style={{display: 'none'}} className='expanded-logo' />
              </Link>;
    }
    return (
      <MainNav expand="md" fixed>
        <Lightbox onClick={this.toggle} toggle={this.state.toggle} />
        <StyledBrand>
          {logo}
        </StyledBrand>
        <MainTitle>
          {this.handleTitleMenu()}
        </MainTitle>
        <StyledNavbarToggler onClick={this.toggle}>
          <DrawerIcon />
        </StyledNavbarToggler>
        <NewRequestContainer onClick={this.handleUsersSuspenso} className='tour-nova-solicitacao' >
          <TextRequest>+</TextRequest>
          <TitleRequestButton>NOVA LOCAÇÃO</TitleRequestButton>
        </NewRequestContainer>
        <CollapsableContainer isOpen={this.state.toggle} className="no-flex" navbar>
          <StyledNav className="ml-auto" navbar>
            {this.mapMenuItems()}
          </StyledNav>
        </CollapsableContainer>
        <Sidebar toggle={this.state.toggle}>
          <span style={{position: 'absolute', right: 147, top: 14}} onClick={() => this.toggle()}>
            <img style={{width: '17px'}} src={close} alt="close" />
          </span>
          <CollapsableContainer isOpen={true} navbar>
            <StyledNav className="ml-auto" navbar>
              <UserIconContainer>
                <img alt="" src={iconUserMenuMobile} style={{width: 20}} />
              </UserIconContainer>
              <SpanHello color="white">Olá,&nbsp;
                {/* <Link to={`/intranet/cadastro/usuarios/editar/${this.renderUserId()}`} onClick={this.toggle}> */}
                  <NameUser color="white" role="button">
                    {this.renderName()}
                  </NameUser>
                {/* </Link> */}
              </SpanHello>
              <MobileNewRequestContainer onClick={this.handleUsersSuspenso} >
                <TextRequest>+</TextRequest>
                <TitleRequestButton>NOVA LOCAÇÃO</TitleRequestButton>
              </MobileNewRequestContainer>
              {this.mapMenuItems()}
              <BoxExit>
                <ExitIconContainer fill="white" role="button" onClick={this.props.logoutUser}>
                  <LogoutSVG /> <ExitTitleContainer>Sair</ExitTitleContainer>
                </ExitIconContainer>
              </BoxExit>
            </StyledNav>
          </CollapsableContainer>
        </Sidebar>
      </MainNav>     
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ logoutUser }, dispatch)

const mapStateToProps = (state) => ({
  userData: state.employerForm.userData.data,
  login: state.login,
  customer: state.customerData.data.cliente,
  routerState: state.router
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);