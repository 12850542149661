import { uniq } from 'lodash';

import fcRapidaRules from './rules/fcRapidaRulesVeredito';

const incomeFieldsPF = [
  'renda_principal_review',
  'origem_renda_review',
  'outras_rendas_review',
  'origem_renda_secundaria_review',
  'total_renda_review',
  // 'compor_renda'
];

const incomeFieldsPJ = [
  'faturamento_mensal',
  'tipo_negocio',
  'natureza_juridica'
];

const productsPF = [{
  id: 1,
  name: 'FCRAPIDA',
  fields: [
    'renda_principal_review',
    'origem_renda_review',
    'outras_rendas_review',
    'origem_renda_secundaria_review',
    'total_renda_review',
    // 'compor_renda'
  ]
}];

const productsPJ = [{
  id: 1,
  name: 'FCRAPIDA',
  fields: [
    'faturamento_mensal',
    'tipo_negocio',
    'natureza_juridica'
  ]
}];

export default (productsSent = [1], entity = 'PF', changedValues) => {
  if (entity === 'PF' || entity === "PJ") {
    const fieldsByProducts = productsSent.reduce((acc, id) => {
      if (entity === "PF") {
        const productsIndex = productsPF.findIndex(prd => prd.id === id);

        if (productsIndex !== -1) {
          return [...acc, ...productsPF[productsIndex].fields];
        }
      } else if (entity === "PJ") {
        const productsIndex = productsPJ.findIndex(prd => prd.id === id);

        if (productsIndex !== -1) {
          return [...acc, ...productsPJ[productsIndex].fields];
        }
      }

      return acc;
    }, []);

    let fieldsMerged = uniq(fieldsByProducts);

    // FC RAPIDA
    if (productsSent.indexOf(1) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    if (entity === "PF") {
      return incomeFieldsPF.filter((field) => fieldsMerged.indexOf(field) >= 0);
    } else if (entity === "PJ") {
      return incomeFieldsPJ.filter((field) => fieldsMerged.indexOf(field) >= 0);
    }
  }
  
  return [];
}
