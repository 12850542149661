import styled from 'styled-components';
import { FullColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { StyledRow } from "@bit/wavfichacerta.fichacerta.shared-styles.rows";
import { Card, CardBody } from 'reactstrap';

export const ColoredColumn = styled(FullColumn)`
  background-color: ${props => props.theme['color_light-gray']};
  margin-bottom: 16px;
`;

export const TableTitle = styled.p`
  font-size: 13px !important;
  color: ${props => props.theme.color_gray};
  margin: 8px;
  padding: 5px;
  width: auto;
`;

export const Line = styled.hr`
  margin: 0;
  border: 1px solid ${props => props.theme.color_report_hr} !important; 
`;

export const StyledCard = styled(Card)`
  width: 100%;
  margin: .5rem 0;
  background-color: white;
  border-left: 3px solid #152b4e;
  padding: .5rem .5rem;
  font-size: 11px;
  box-shadow: 0px 0px 9px #dadada;
  min-width: 288px;
`;

export const StyledCardBody = styled(CardBody)`
  flex: 1 1 auto;
  padding: 0 !important;
`;

export const StyledRowMobile = styled(StyledRow)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: self-start;
  font-size: 11px;
  margin: .1rem;
`;

export const StyledTitleMobile = styled.div`
  color: #7f7f7f;
  font-size: 11px;
`;

export const StyledLineMobile = styled.p`
  color: #7f7f7f !important;
  font-weight: 400;
  margin: .1rem;
`;

export const StyledHr = styled.hr`
  margin-top: 0;
  margin-bottom: .5rem;
  border-top: 1px solid #7f7f7f40;
`;