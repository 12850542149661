import Person from '@bit/wavfichacerta.fichacerta.icons.person';
import ReportRecommendation from '@bit/wavfichacerta.fichacerta.icons.report-recommendation';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { risk } from '../../../../pages/operational/request/report/singleReport/actions';
import { AnalythicLine, CardAnalythic, ContainerGray, DivisorGray, IconRecommendation, LineTitle, RecommendationBox, RecommendationsColumn, RecommendationText, SistemicFeedbackTitle, SpanRecommendation, AnalythicTextItalic } from '../analythic.styled';
import ApplicableConceptsPj from './ApplicableConceptsPj';
import { getRecommendation, getRisks, getSystemicFeeback } from './products/fcAnalise';
import icons from '../../../shared/treatedIcons';

const { Triangle, Null, ReportAlert, ChartLine } = icons;

class RiskProfile extends PureComponent {
  constructor(props){
    super(props)

    this.risk = React.createRef()
  }

  componentDidMount(){
    this.props.risk(this.risk.current)
  }

  renderObservations = () => {
    const { data } = this.props;
    const recommendations = getRecommendation(data);
    const risks = getRisks(data);
    console.log('risks',risks)
    console.log('recommendations',recommendations)

    if(data && 'laudo' in data && data.laudo.parecer_sistemico[0]) {
      const parecer_key = data.laudo.parecer_sistemico[0].parecer_key
      if(parecer_key == 'BUREAU_DISPONIVEL_BASE' || parecer_key == 'BUREAU_DISPONIVEL_BASE_BOTH') {
        if ((Array.isArray(recommendations) && recommendations.length > 0) 
        || (Array.isArray(risks) && risks.length > 0)) {
          return (
            <RecommendationBox>
              <BaseColumn xs={12} md={1} lg={1} xl={1}>
                <RecommendationText>Obs: </RecommendationText>
              </BaseColumn>
              <RecommendationsColumn xs={12} md={11} lg={11} xl={11}>
                {
                  (Array.isArray(recommendations) && recommendations.length > 0) ?
                  recommendations.map((item) => (
                    item ?
                  <RecommendationText>
                    <IconRecommendation><ReportRecommendation /></IconRecommendation>
                    <SpanRecommendation style={{fontWeight: 700}} dangerouslySetInnerHTML={{__html: item}} />
                  </RecommendationText>
                  : null
                )) : null }
                {
                  (Array.isArray(risks) && risks.length > 0) ?
                  risks.map((item) => (
                    item ?
                  <RecommendationText>
                    <IconRecommendation><ReportRecommendation /></IconRecommendation>
                    <SpanRecommendation dangerouslySetInnerHTML={{__html: item}} />
                  </RecommendationText>
                  : null
                )) : null }
              </RecommendationsColumn>
            </RecommendationBox>
          );
        }
      }
    }
    return null;
  }

  renderLines = () => {
    const { data } = this.props;
    const systemicFeedback = getSystemicFeeback(data);
    const systemicFeedbackKey = getSystemicFeeback(data, 'parecer_key');
    const scoreFc = data.laudo.parecer_sistemico[0].score_fc
    const {parecer_key, parecer, score_serasa} = data.laudo.parecer_sistemico[0]
    let score = score_serasa

    let nao_confirmado = false
    let Icon = null;
    let status = null;

    if(score >= 850) {
      status = 'dark-green-circle';
    } else if(score >= 650 && score <= 849) {
      status = 'light-green-circle';
    } else if(score >= 500 && score <= 649) {
      status = 'yellow-circle';
    } else if(score >= 350 && score <= 499) {
      status = 'orange-circle';
    } else if(score >= 3 && score <= 349) {
      status = 'negative-circle';
    } else {
      status = 'nulo';
      switch(score) {
        case 2:
          score = 'Modelo de score não se aplica ao porte da empresa';
          break;
        case 1:
          score = 'Não possui informações relevantes para cálculo de score';
          break;
        case '-':
        case '0':
        case 0:
          score = 'Score não disponível';
          break;
      }
    }

    if(status == 'nulo') {
      Icon = <ReportAlert />;
    }
    if(status == 'orange-circle') {
      Icon = <Null color="#ff7200" />;
    }
    if(status == 'negative-circle') {
      Icon = <Null color="#fe4d36" />;
    }
    if(status == 'dark-green-circle') {
      Icon = <Null color="#006400" />;
    }
    if(status == 'light-green-circle') {
      Icon = <Null color="#37c120" />;
    }
    if(status == 'yellow-circle') {
      Icon = <Null color="#feda32" />;
    }

    if(data.laudo.produtos && Object.keys(data.laudo.produtos.fcrenda).length && data.laudo.produtos.fcrenda.ativo) {
      if(data.laudo.produtos.fcrenda.resultado && data.laudo.produtos.fcrenda.resultado.titulo == 'PERFIL FINANCEIRO NÃO CONFIRMADO') {
        nao_confirmado = true
      }
    }

    if (Array.isArray(systemicFeedback)) {
      return systemicFeedback.map((feedback, index) => {
        const lineColor = index % 2 === 0? undefined : "transparent";
        return (
          <StyledRow customstyle={{width: '98%'}} key={index}>
            {/* <BaseColumn xs="12" sm="12" md="6" lg="6" xl="6" margin="20px 0 0 0">
              <AnalythicLine height="32px" margin="1px 0 1px -5px" padding=".4rem" background={lineColor}>
                <LineTitle maxWidth="205px">PARECER INDIVIDUAL</LineTitle>
                <SistemicFeedbackTitle type={systemicFeedbackKey[index]}>{ feedback }</SistemicFeedbackTitle>
              </AnalythicLine>
            </BaseColumn> */}
            <BaseColumn xs="12" sm="12" margin="20px 0 0 0" customstyle='@media (max-width: 800px) {.fc-points{margin-top: 7px !important;}}'>
              <AnalythicLine height="32px" margin="1px 0 1px 5px" padding=".4rem" background={lineColor} style={{color: '#000'}}>
                <LineTitle maxWidth="11.5%">SCORE de Mercado</LineTitle>
                <div style={{width: 15, marginRight: 5, alignSelf: 'center'}}>{Icon}</div>
                <SistemicFeedbackTitle type={'other'} style={{fontWeight: 400, color: '#000'}}>{ score }</SistemicFeedbackTitle>
                {status !== 'nulo' && <SistemicFeedbackTitle type={'other'} className='fc-points' style={{fontWeight: 500, fontSize: 11, marginLeft: -13, marginTop: 1, color: '#000'}}>(de 0 a 1000)</SistemicFeedbackTitle>}
              </AnalythicLine>
            </BaseColumn>
          </StyledRow>
        );
      });
    }
    return null;
  }

  renderResultFcRenda = (result) => {
    const { data } = this.props;
    const fcDoc = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {};
    if(!fcDoc.resultado || !fcDoc.parecer_fc || !fcDoc.ativo) {
      return null;
    }

    return(
      <div style={{border: '2px solid #152b4e', padding: '4px 10px', color: '#152b4e', fontSize: 12, marginTop: 6, width: '100%', zIndex: 1, fontWeight: 'bolder'}} className='result-fcdoc'>
        PARECER FC: {fcDoc.parecer_fc}
      </div>
    )
  }

  render() {
    const { data } = this.props;
    const fcDoc = data.pretendente.produtos.ativo.filter(product => product.id === 6 || product.id === 8)

    return (
      <CardAnalythic
        title="RISCO DE MERCADO"
        iconHeader={ChartLine}
        iconLeft={true}
      >
        {/* {fcDoc.length > 0 && fcDoc[0].ativo === true && data.laudo.produtos.fcrenda.resultado && data.laudo.produtos.fcrenda.parecer_fc &&
          <span style={{backgroundColor: '#152b4e', color: '#fff', fontWeight: 600, padding: '2px 6px', marginLeft: 4, position: 'absolute', top: 11, left: 220}}>
            FC DOC
          </span>
        } */}

        <StyledRow align="initial" margin="0 0 .5rem 0">
          <ContainerGray ref={this.risk}>
            <StyledRow align="initial" margin="0">
              { this.renderLines() }
              <BaseColumn xs={12} md={12} lg={12} xl={12}>
                { this.renderObservations() }
                <AnalythicTextItalic style={{marginLeft: 15, marginTop: 1, color: '#000'}}>
                  O score acima é um cálculo de risco estático e geral de mercado.
                  Não houve aplicação de nenhuma inteligência de risco específica para locação, nem levou em consideração o tipo e valores da locação.
                </AnalythicTextItalic>
                <br />
              </BaseColumn>
            </StyledRow>
          </ContainerGray>
          <BaseColumn xs={12} md={12} lg={12} xl={12}><DivisorGray /></BaseColumn>
          <ApplicableConceptsPj Null={Null} />
          {/* {this.renderResultFcRenda()} */}
        </StyledRow>
      </CardAnalythic>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  risk
}, dispatch)

export default connect(null, mapDispatchToProps)(RiskProfile)