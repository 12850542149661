import React, { Component }  from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { CreditContainer, UsedCreditBar, TextIcon, SubtitleBox, LateralBar } from './DashCreditBar.styled';
import { formatToBRL } from '@bit/wavfichacerta.fichacerta.utils.formatters';
import CompanyIcon from '../../../../components/shared/icons/svg/CompanyIcon';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';

export default class CompanyCreditBar extends Component {

  static defaultProps = {
    customer: ''
  }

  getAvailableCredit = () => {
    const { users } = this.props;
    if (Array.isArray(users) && users.length > 0) {
      return users.reduce((acc, user) => acc + user.funcionario.credito, 0);
    }
    return 0;
  }

  getUsedCredit = (availableCredit, isPercentage = true) => {
    const { users } = this.props;
    if (Array.isArray(users) && users.length > 0) {
      const usedCredit = users.reduce((acc, user) => acc + user.funcionario.credito_utilizado, 0);
      const percentageUsage = (usedCredit/availableCredit)*100;
      if (isPercentage) {
        return `${percentageUsage}%`;
      }
      return usedCredit;
    }
    return '0%';
  }

  render() {
    const availableCredit = this.getAvailableCredit();
    const usage = this.getUsedCredit(availableCredit);
    const usageValue = this.getUsedCredit(availableCredit, false);

    return (
      <StyledRow justify="flex-start" align="flex-start">
        <StyledRow justify="space-around" padding="0 .5rem" margin="5vh 0 0 0" customstyle='@media (max-width: 800px){padding: 0;margin: 1.5vh 0 0 0;svg {width: 24px;}}@media (max-width: 349px){svg {display: none;}}'>
        <BaseColumn>
          <TextIcon>Total</TextIcon>
        </BaseColumn>
          <StyledRow>
            <BaseColumn xs={2}>
              <CompanyIcon width={'40px'} />
            </BaseColumn>
            <BaseColumn xs={10} sm={10} md={10} lg={10} xl={10} customstyle='@media (max-width: 349px){max-width: 100%;flex: 0 0 100%;}'>
              <BaseColumn lg={12} xl={12} customstyle="display: flex;flex-direction: row;justify-content: space-between;@media (max-width: 800px){justify-content: flex-end;}">
                <SubtitleBox colorBox="color_systemic_feedback_regular">
                  <span className='text-hidden-mobile'>usado</span><SubtitleBox semiBold>&nbsp;R$ <span className='text-hidden-mobile'>{formatToBRL(usageValue)}</span><span className='text-hidden-desktop'>{formatToBRL(usageValue).split(',')[0]}</span></SubtitleBox>
                </SubtitleBox>
                <LateralBar>/</LateralBar>
                <SubtitleBox colorBox="color_purple">
                  <span className='text-hidden-mobile'>disponível</span> <SubtitleBox semiBold>&nbsp;R$ <span className='text-hidden-mobile'>{formatToBRL(availableCredit - usageValue)}</span><span className='text-hidden-desktop'>{formatToBRL(availableCredit - usageValue).split(',')[0]}</span></SubtitleBox>
                </SubtitleBox>
              </BaseColumn>

              <BaseColumn lg={12} xl={12}>
                <CreditContainer hasColor={availableCredit > 0} activeColor="color_purple">
                  {availableCredit > 0 && <UsedCreditBar width={usage} />}
                </CreditContainer>
              </BaseColumn>
            </BaseColumn>
          </StyledRow>
        </StyledRow>
      </StyledRow>
    );
  }
}
