/* eslint-disable */
import bugsnag from '@bugsnag/js'
export const bugsnagClient = bugsnag('48aec8596f7e0bff64380351e3356805');
/* eslint-disable */

import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import App from './pages/App'
import IntranetContainer from './routes/IntranetContainer';

// Cadastro e Login
import authRoutes from './routes/auth';

// Rotas gerais
import commonRoutes from './routes/common';

// Cadastros
import customerRoutes from './routes/module_1/customer';

//Operational
import operationalRoutes from './routes/module_2/request';
import fcfiancaRoutes from './routes/module_2/fcfianca';

// Financeiro
import financeRoutes from './routes/module_3';

//bugsnag
import bugsnagReact from '@bugsnag/plugin-react';
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

export default class Routes extends Component {
  render() {
    return (
      <ErrorBoundary>
        <App>
          <Route path="/">
            <Fragment>
              <IntranetContainer>
                {commonRoutes()}
                {customerRoutes()}
                {operationalRoutes()}
                {fcfiancaRoutes()}
                {financeRoutes()}
              </IntranetContainer>
              {authRoutes()}
            </Fragment>
          </Route>
        </App>
      </ErrorBoundary>
    )
  }
};
