import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';

import { getCustomerData, saveForm, resetData } from './actions';
import { getRequirements } from '../../form-requirements/actions';
import { getInitialData, recommendationDataExtractor } from './selector';
import { customerDataExtractor } from '../../../utils/extractors/CustomerExtractor';

import { PageContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';
import Header from '../../../components/Header';
import CustomerForm from './CustomerForm';
import { ContainerForm, FormWrapper } from '../../../components/shared/formStyles.styled';
import { Main, MainContainer } from '../../operational/request/resume/RequestResume';

import Tour from 'reactour'
import axios from 'axios';
import { instance as axiosInstance } from './../../../services/axiosInstance';

const steps = [
  {
    selector: '.dados-cadastrais',
    content: <span>Aqui é possível conferir os dados cadastrais da imobiliária</span>,
  },
  {
    selector: '[title="Contatos Adicionais"]',
    content: 'Caso precise atualizar os contatos das áreas, acione o nosso suporte.',
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class Recomendations extends PureComponent {

  constructor(props) {
    super(props);
    this.customerForm = React.createRef();

    this.state = {
      tourOpen: false,
      tourId: null,
      recomendacoes: {},
      recomendacoesDefault: {},
      initialValues: {},
      usar_recomendacao_customizada: 0,
      successRequest: false,
    }
  }

  componentDidMount() {
    this.props.getRequirements();
    this.props.getCustomerData();
    // this.getCheckOnboard()
    this.getRecommendations()
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  getRecommendations = async () => {
    try {
      const URL = '/api/administrador/recomendacoes'

      const token = this.props.login.user.token.access_token

      const headers = {
        'Authorization': `Bearer ${token}`
      }

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axiosInstance.get(URL, {headers});
      console.log('nova2', result)
      if(result.status === 401) {
        this.props.history.push('/auth')
      }
      // result.data.recomendacoes
      this.setState({recomendacoes: result.data.customizada, usar_recomendacao_customizada: result.data.usar_recomendacao_customizada, recomendacoesDefault: result.data.default, initialValues: getInitialData('PF', result.data.customizada), successRequest: true})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  putRecommendations = async (data, usar_recomendacao_customizada) => {
    try {
      const URL = '/api/administrador/recomendacoes'

      const token = this.props.login.user.token.access_token

      const headers = {
        'Authorization': `Bearer ${token}`
      }

      // const data = {
      //   email: this.props.login.user.usuario.email
      // }

      const result = await axiosInstance.put(URL, {recomendacoes: data, usar_recomendacao_customizada: usar_recomendacao_customizada == 'true' ? 1 : usar_recomendacao_customizada == 'false' ? 0 : undefined}, {headers});
      console.log('nova3', result)
      // alert('Pareceres atualizados!')
      
      if(result.status === 401) {
        this.props.history.push('/auth')
      }
      toastr.success('Sucesso!', 'Pareceres atualizados!');
      // this.setState({recomendacoes: result.data.customizada, recomendacoesDefault: result.data.default, successRequest: true})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'dados-do-cliente') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'dados-do-cliente'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'dados-do-cliente',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  submitForm = (changedValues) => {
    const entity = 'PF'
    const data = recommendationDataExtractor(entity, changedValues, this.state.recomendacoes);
    // this.props.saveForm(data);
    console.log('data1', data)
    const values = Object.assign({}, this.state.initialValues, changedValues)
    this.setState({initialValues: values})
    this.putRecommendations(data, changedValues.usar_recomendacao_customizada);
  }

  submitFieldForm = (changedValues) => {
    console.log('vv1', changedValues)
    // const v = {...this.state.recomendacoes, ...changedValues }
    const values = Object.assign({}, this.state.initialValues, changedValues)
    this.setState({initialValues: values})
    const data = recommendationDataExtractor('PF', values, this.state.recomendacoes);
    this.putRecommendations(data)
  }

  sendSwitchForAPI = (value) => {
    this.putRecommendations(undefined, value);
  }

  renderFormByEntity = () => {
    const { data } = this.props;

    if (typeof data === "object" && Object.keys(data).length > 0) {
      // const entity = ("cnpj" in data)? "PJ" : "PF";
      const entity = 'PF'
      return (
        <FormWrapper>
          <CustomerForm
            entity={entity}
            initialValues={{...this.state.initialValues, usar_recomendacao_customizada: this.state.usar_recomendacao_customizada ? 'true' : 'false'}}
            recomendacoesDefault={getInitialData(entity, this.state.recomendacoesDefault)}
            onSubmitForm={this.submitForm}
            onSubmitFieldForm={this.submitFieldForm}
            sendSwitchForAPI={this.sendSwitchForAPI}
          />
        </FormWrapper>
      );
    }
    return null;
  }

  renderLoading = () => {
    return (
      <div style={{textAlign: 'center'}}>
        <Main>
          <Header
            title="PARECERES DE APROVAÇÃO"
            actualLink="Pareceres de aprovação"
            openTourCallback={(call) => this.setState({tourOpen: call})}
          />
        </Main>
      </div>
    )
  }

  render() {
    if(!this.state.successRequest) {
      return this.renderLoading();
    }

    return (
      <Main>
        <Header
          title="PARECERES DE APROVAÇÃO"
          actualLink="Pareceres de aprovação"
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <MainContainer>
          <ContainerForm>
            {this.renderFormByEntity()}
          </ContainerForm>
        </MainContainer>
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
        />
      </Main>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getRequirements, getCustomerData, saveForm, resetData }, dispatch);

const mapStateToProps = (state) => ({
  data: state.customerData.data.cliente,
  login: state.login,
})

export default connect(mapStateToProps, mapDispatchToProps)(Recomendations);
