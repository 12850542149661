import React, {Fragment} from 'react';
import { StyledRow, HalfColumn, FullColumn } from '../../../../shared/sharedStyles.styles';
import {
  ContainerGray,
  AnalythicList,
  ColoredSpan,
  AnalythicLine,
  LineTitle
} from '../../analythic.styled';
import {LinesContainer, LawsuitLine} from './lawsuitStyled.js';
import { Line } from '../styled';
import moment from 'moment'

export default ({acao, acoesLength, index, color, fontWeight}) => {
    let origem = [];
    let origemResult = '';
    if(acao.origem.corte) {
      origem.push(acao.origem.corte);
    }
    if(acao.origem.tipo) {
      origem.push(acao.origem.tipo);
    }
    if(acao.origem.instancia) {
      origem.push(acao.origem.instancia);
    }
    if(acao.origem.comarca) {
      origem.push(acao.origem.comarca);
    }
    if(acao.origem.vara) {
      origem.push(acao.origem.vara);
    }
    origemResult = origem.toString().replaceAll(',', ' / ');

    return(
        <Fragment>
            <StyledRow align="initial" margin="0 0 0 0" className='font-13-print'>
                <FullColumn>
                    <ContainerGray>
                        <AnalythicList>
                            <LinesContainer background="rgba(0, 0, 0, 0.05)">
                                <LawsuitLine>
                                    <LineTitle maxWidth="205px" minWidth="205px" style={{color: '#000'}}>Tipo do processo</LineTitle>
                                    <ColoredSpan color={color} fontWeight={fontWeight}>{acao.tipo}</ColoredSpan>
                                </LawsuitLine>
                                <LawsuitLine>
                                    <LineTitle maxWidth="205px" minWidth="205px" style={{color: '#000'}}>Autor/Demandante</LineTitle>
                                    <ColoredSpan color={color} fontWeight={fontWeight}>{acao.autores.map((autor, index, autores) => autores.length - 1 === index ? autor : `${autor} / `)}{acao.autores.length == 0 && 'Não encontrado'}</ColoredSpan>
                                </LawsuitLine>
                            </LinesContainer>
                            <LinesContainer background="transparent">
                                <LawsuitLine>
                                    <LineTitle maxWidth="205px" minWidth="205px" style={{color: '#000'}}>Assunto</LineTitle>
                                    <ColoredSpan color={color} fontWeight={fontWeight}>{acao.assunto || 'Não encontrado'}</ColoredSpan>
                                </LawsuitLine>
                                <LawsuitLine>
                                    <LineTitle maxWidth="205px" minWidth="205px" style={{color: '#000'}}>Réu/Demandado</LineTitle>
                                    <ColoredSpan color={color} fontWeight={fontWeight}>{acao.reus.map((reu, index, reus) => reus.length - 1 === index ? reu : `${reu} / `)}</ColoredSpan>
                                </LawsuitLine>
                            </LinesContainer>
                            <LinesContainer background="rgba(0, 0, 0, 0.05)">
                                <LawsuitLine>
                                    <LineTitle maxWidth="205px" minWidth="205px" style={{color: '#000'}}>Número do processo</LineTitle>
                                    <ColoredSpan color={color} fontWeight={fontWeight}>{acao.numero}</ColoredSpan>
                                </LawsuitLine>
                                <LawsuitLine>
                                    <LineTitle maxWidth="205px" minWidth="205px" style={{color: '#000'}}>Data de distribuição</LineTitle>
                                    <ColoredSpan color={color} fontWeight={fontWeight}>{acao.data_distribuicao == 'Não encontrado' || !acao.data_distribuicao ? 'Não encontrado' : moment(acao.data_distribuicao, "DD-MM-YYYY").format("DD/MM/YYYY")}</ColoredSpan>
                                </LawsuitLine>
                            </LinesContainer>
                            <LinesContainer background="transparent">
                                <LawsuitLine>
                                    <LineTitle maxWidth="205px" minWidth="205px" style={{color: '#000'}}>Origem</LineTitle>
                                    <ColoredSpan color={color} fontWeight={fontWeight}>{origemResult}</ColoredSpan>
                                </LawsuitLine>
                                <LawsuitLine>
                                    <LineTitle maxWidth="205px" minWidth="205px" style={{color: '#000'}}>{acao.situacao ? 'Movimentação/Situação' : 'Movimentação'}</LineTitle>
                                    <ColoredSpan color={color} fontWeight={fontWeight}>{acao.data_movimentacao == 'Não encontrado' || !acao.data_movimentacao ? 'Não encontrado' : moment(acao.data_movimentacao, "DD-MM-YYYY").format("DD/MM/YYYY")} {acao.situacao ? `(${acao.situacao.toLowerCase()})` : ''}</ColoredSpan>
                                </LawsuitLine>
                            </LinesContainer>
                        </AnalythicList>
                    </ContainerGray>
                </FullColumn>
            </StyledRow>
            {(acoesLength < 2 || acoesLength - 1 == index) && <Line /> || <Line theme={{color_report_hr: '#fff'}} borderWidth='4px'  style={{border: '4px solid #fff !important'}} />}
        </Fragment>
    )
}