import { uniq } from 'lodash';

import fcRapidaRules from './rules/fcRapidaRules';

const tenancyFieldsPF = [
  'codigo_imovel',
  'cep',
  'logradouro',
  'numero',
  'complemento',
  'bairro',
  'cidade',
  'uf',
  'aluguel',
  'condominio',
  'iptu',
  'total_locacao',
  'tipo_imovel'
];

const tenancyFieldsPJ = [
  'codigo_imovel',
  'cep',
  'logradouro',
  'numero',
  'complemento',
  'bairro',
  'cidade',
  'uf',
  'aluguel',
  'condominio',
  'iptu',
  'total_locacao',
  'tipo_imovel'
];

const productsPF = [
  {
    id: 1,
    name: 'FCRAPIDA',
    fields: [
      'codigo_imovel',
      'cep',
      'logradouro',
      'numero',
      'complemento',
      'bairro',
      'cidade',
      'uf',
      'aluguel',
      'condominio',
      'aluguel',
      'condominio',
      'iptu',
      'total_locacao',
      'tipo_imovel'
    ]
  },
  {
    id: 3,
    name: 'FCRAPIDA',
    fields: [
      'codigo_imovel',
      'cep',
      'logradouro',
      'numero',
      'complemento',
      'bairro',
      'cidade',
      'uf',
      'aluguel',
      'condominio',
      'aluguel',
      'condominio',
      'iptu',
      'total_locacao',
      'tipo_imovel'
    ]
  },
  {
    id: 5,
    name: 'FCBASICO',
    fields: [
      'codigo_imovel',
      'cep',
      'logradouro',
      'numero',
      'complemento',
      'bairro',
      'cidade',
      'uf',
      'aluguel',
      'condominio',
      'aluguel',
      'condominio',
      'iptu',
      'total_locacao',
      'tipo_imovel'
    ]
  },
  {
    id: 7,
    name: 'FCCOMPLETA',
    fields: [
      'codigo_imovel',
      'cep',
      'logradouro',
      'numero',
      'complemento',
      'bairro',
      'cidade',
      'uf',
      'aluguel',
      'condominio',
      'aluguel',
      'condominio',
      'iptu',
      'total_locacao',
      'tipo_imovel'
    ]
  }
];

const productsPJ = [
  {
    id: 1,
    name: 'FCRAPIDA',
    fields: [
      'codigo_imovel',
      'cep',
      'logradouro',
      'numero',
      'complemento',
      'bairro',
      'cidade',
      'uf',
      'aluguel',
      'condominio',
      'aluguel',
      'condominio',
      'iptu',
      'total_locacao',
      'tipo_imovel'
    ]
  },
  {
    id: 4,
    name: 'FCEMPRESA',
    fields: [
      'codigo_imovel',
      'cep',
      'logradouro',
      'numero',
      'complemento',
      'bairro',
      'cidade',
      'uf',
      'aluguel',
      'condominio',
      'aluguel',
      'condominio',
      'iptu',
      'total_locacao',
      'tipo_imovel'
    ]
  },
  {
    id: 8,
    name: 'FCCOMPLETA',
    fields: [
      'codigo_imovel',
      'cep',
      'logradouro',
      'numero',
      'complemento',
      'bairro',
      'cidade',
      'uf',
      'aluguel',
      'condominio',
      'aluguel',
      'condominio',
      'iptu',
      'total_locacao',
      'tipo_imovel'
    ]
  }
];

export default (productsSent = [1], entity = 'PF', changedValues) => {

  // PESSOA FÍSICA
  if (entity === 'PF' || entity === "PJ") {
    const fieldsByProducts = productsSent.reduce((acc, id) => {
      if (entity === "PF") {
        const productsIndex = productsPF.findIndex(prd => prd.id === id);

        if (productsIndex !== -1) {
          return [...acc, ...productsPF[productsIndex].fields];
        }
      } else if (entity === "PJ") {
        const productsIndex = productsPJ.findIndex(prd => prd.id === id);

        if (productsIndex !== -1) {
          return [...acc, ...productsPJ[productsIndex].fields];
        }
      }

      return acc;
    }, []);

    let fieldsMerged = uniq(fieldsByProducts);

    // FC RAPIDA
    if (productsSent.indexOf(1) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    // FC ESSENCIAL
    if (productsSent.indexOf(3) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    // FC BASICO
    if (productsSent.indexOf(5) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    // FC COMPLETA PF
    if (productsSent.indexOf(7) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    // FC COMPLETA PJ
    if (productsSent.indexOf(8) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    if (entity === "PF") {
      return tenancyFieldsPF.filter((field) => fieldsMerged.indexOf(field) >= 0);
    }
    return tenancyFieldsPJ.filter((field) => fieldsMerged.indexOf(field) >= 0);
  }
  
  return [];
}
