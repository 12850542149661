export const onOpenEmployeeModal = (state, { mode, employee }) => (
  {
    ...state,
    employeeModal: {
      ...state.employeeModal,
      isVisible: true,
      mode: mode,
      selectedEmployee: employee,
    }
    
  }
);

export const onCloseEmployeeModal = (state) => (
  {
    ...state,
    employeeModal: {
      ...state.employeeModal,
      isVisible: false,
      mode: undefined,
      selectedEmployee: {}
    }
  }
);

export const onRequestEmployee = (state) => (
  {
    ...state,
    employeeModal: {
      ...state.employeeModal,
      requesting: true,
      error: undefined,
    }
  }
);

export const onRequestEmployeeSuccess = (state, payload) => (
  {
    ...state,
    employeeModal: {
      ...state.employeeModal,
      selectedEmployee: payload,
      requesting: false,
      error: false,
    }
  }
);

export const onRequestEmployeeFailed = (state, payload) => (
  {
    ...state,
    employeeModal: {
      ...state.employeeModal,
      requesting: false,
      error: payload,
    }
  }
);

export const onGetEmployee = (state, payload) => (
  {
    ...state,
    employeeModal: {
      ...state.employeeModal,
      list: payload
    }
  }
);

export const onPickEmployeeCredit = (state, payload) => (
  {
    ...state,
    editableRow: payload
  }
);

export const onGetEmployeesRequest = (state) => (
  {
    ...state,
    requesting: true,
    error: undefined
  }
);

export const onGetEmployeesSuccess = (state, payload) => (
  {
    ...state,
    list: payload.data,
    error: false,
    requesting: false,
    pagination: {
      ...state.pagination,
      totalCount: payload.pagination.total,
			lastPage: payload.pagination.last_page,
			currentPage: payload.pagination.current_page,
			resultsPerPage: parseInt(payload.pagination.per_page, 10),
    }
  }
);

export const onEmployeeRegisterFailed = (state, payload) => (
  {
    ...state,
    requesting: false,
    error: payload
  }
);

export const onGetEmployeesFailed = (state, payload) => (
  {
    ...state,
    requesting: false,
    error: payload
  }
);

export const onOpenTypeEmployeeModal = (state, payload) => (
  {
    ...state,
    typeModal: {
      isVisible: true,
      selectedEmployee: payload
    }
  }
);

export const onCloseTypeEmployeeModal = (state) => (
  {
    ...state,
    typeModal: {
      isVisible: false,
      selectedEmployee: {}
    }
  }
);

export const onOpenStatusEmployeeModal = (state, payload) => (
  {
    ...state,
    statusModal: {
      isVisible: true,
      selectedEmployee: payload
    }
  }
);

export const onCloseStatusEmployeeModal = (state) => (
  {
    ...state,
    statusModal: {
      isVisible: false,
      selectedEmployee: {}
    }
  }
);

export const onEmployeeChangeModalClose = (state) => (
  {
    ...state,
    isEmailChangingModalOpen: false,
    emailToChange: undefined
  }
);

export const onEmployeeChangeModalOpen = (state, payload) => (
  {
    ...state,
    isEmailChangingModalOpen: true,
    data: payload
  }
);

export const updateEmployeeCredit = (state, { id, credit }) => {
  const { list } = state;
  const newList = list.map((employee) => {
    if (employee.funcionario.id === id) {
      employee.funcionario.credito = credit;
    }

    return employee;
  });

  return {
    ...state,
    list: newList
  };
}

export const onChangePage = (state, payload) => (
	{
		...state,
		pagination: {
			...state.pagination,
			currentPage: payload
		}
	}
);

export const onChangeResultsPerPage = (state, payload) => (
	{
		...state,
		pagination: {
			...state.pagination,
			resultsPerPage: payload,
			currentPage: 1
		}
	}
);

export const onResetPagination = (state) => (
	{
		...state,
		pagination: {
			totalCount: undefined,
			numberOfPages: undefined,
			lastPage: undefined,
			currentPage: 1,
			resultsPerPage: state.pagination.resultsPerPage,
			sortBy: 'nome',
			nextPage: '',  
			previousPage: '',
			filter: '',
			status: 'ATIVO'
		}
	}
);