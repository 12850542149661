import * as types from '../actions/types';
import {
  onGetChargeData,
  onGetChargeDataSuccess,
  onGetChargeDataFailed,
  onChangePage,
  onChangePerPage,
  onChangeFilters,
  onGetEmptyChargeData,
  onResetFilters
} from './reducers';

const initialState = {
  isRequesting: false,
  error: false,
  resume: {},
  requests: {},
  filters: {},
  pagination: {
    page: 1,
    perPage: localStorage.getItem("resultsPerPageRequests") || "10"
  }
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case types.CHARGE_DATA_REQUEST:
      return onGetChargeData(state);
    case types.CHARGE_DATA_SUCCESS:
      return onGetChargeDataSuccess(state, payload);
    case types.CHARGE_DATA_FAILED:
      return onGetChargeDataFailed(state);
    case types.CHANGE_PAGE:
      return onChangePage(state, payload);
    case types.CHANGE_PER_PAGE:
      return onChangePerPage(state, payload);
    case types.CHANGE_FILTERS:
      return onChangeFilters(state, payload, initialState);
    case types.EMPTY_CHARGE_DATA:
      return onGetEmptyChargeData(state);
    case types.RESET_FILTERS:
      return onResetFilters(state, initialState)
    default:
      return state;
  }
}