import React from 'react'
import { FormGroup } from 'reactstrap';
import { StyledTextInputContainer, StyledLabel } from './Inputs.styles';
import ReactTooltip from 'react-tooltip'
import TourTooltip from './TourTooltip';

const handleLabel = (label, htmlFor, type) => {
  let marginBottom = '2px';
  let visibility = 'visible';

  if (label === 'WITHOUT_LABEL') {
    visibility = 'hidden';
  }

  if (type !== 'select') {
    marginBottom = '6px';
  }

  if (label === undefined) {
    return null;
  }

  return <StyledLabel color="color_purple" style={{ visibility, marginBottom }} htmlFor={htmlFor}>{label}</StyledLabel>
};

const renderTooltip = (label) => {
  if(label === 'Cód. do imóvel') {
    return(
      <React.Fragment>
        <TourTooltip
          target="tooltip-input-cod-imovel"
          style={{left: 82}}
        />
        <ReactTooltip id="tooltip-input-cod-imovel" place="right" type="dark" effect="float">
          Endereço e código do imóvel são campos opcionais, e não interferem no cálculo do risco.
        </ReactTooltip>
      </React.Fragment>
    )
  }

  if(label === 'Aluguel') {
    return(
      <React.Fragment>
        <TourTooltip
          target="tooltip-input-aluguel"
          style={{left: 42}}
        />
        <ReactTooltip id="tooltip-input-aluguel" place="right" type="dark" effect="float">
          {window.location.pathname === '/intranet/operacional/solicitacao/resumo/locacao' &&
            <span>É possível <b>editar</b> o endereço do <br />imóvel, valor do aluguel + encargos</span> ||
            'Aluguel + encargos são informações essenciais para a geração do Risco da Locação'
          }
        </ReactTooltip>
      </React.Fragment>
    )
  }

  if(label === 'Tipo de imóvel') {
    return(
      <React.Fragment>
        <TourTooltip
          target="tooltip-input-tipo-imovel"
          style={{left: 80}}
        />
        <ReactTooltip id="tooltip-input-tipo-imovel" place="right" type="dark" effect="float">
          {window.location.pathname === '/intranet/operacional/solicitacao/resumo/locacao' &&
            <span>Caso precise você pode <b>alterar</b> o tipo de imóvel.</span> ||
            <span>O tipo de imóvel também é muito importante <br /> para calcularmos o risco da locação.</span>
          }
        </ReactTooltip>
      </React.Fragment>
    )
  }

  // if(label === 'Pretendente a') {
  //   return(
  //     <React.Fragment>
  //       <TourTooltip
  //         target="tooltip-input-tipo-pretendente"
  //         style={{right: -10}}
  //       />
  //       <ReactTooltip id="tooltip-input-tipo-pretendente" place="right" type="dark" effect="float">
  //         <span>Informe qual a condição do pretendente, se é o inquilino ou o fiador da locação</span>
  //       </ReactTooltip>
  //     </React.Fragment>
  //   )
  // }

  if(label === 'Nome') {
    return(
      <React.Fragment>
        <TourTooltip
          target="tooltip-input-nome"
          style={{left: 33}}
        />
        <ReactTooltip id="tooltip-input-nome" place="right" type="dark" effect="float">
          <span>Não se preocupe em inserir o nome <br />completo, o sistema vai subscrever essa informação, apenas <br /> o 1° nome para facilitar sua identificação é o suficiente.</span>
        </ReactTooltip>
      </React.Fragment>
    )
  }

  // if(label === 'CPF') {
  //   return(
  //     <React.Fragment>
  //       <TourTooltip
  //         target="tooltip-input-cpf"
  //         style={{right: -10}}
  //       />
  //       <ReactTooltip id="tooltip-input-cpf" place="right" type="dark" effect="float">
  //         <span>CPF é a chave de busca para nossa análise</span>
  //       </ReactTooltip>
  //     </React.Fragment>
  //   )
  // }

  if(label === 'PERFIL FINANCEIRO por') {
    return(
      <React.Fragment>
        <TourTooltip
          target="tooltip-input-perfil-financeiro"
          style={{right: -10}}
        />
        <ReactTooltip id="tooltip-input-perfil-financeiro" place="right" type="dark" effect="float">
          <div style={{height: 'max-content'}}>Você pode preencher com as informações iniciais que possui do seu pretendente, ou mesmo escolher a opção <b>renda não informada</b>. E fique tranquilo, este campo é editável.</div>
        </ReactTooltip>
      </React.Fragment>
    )
  }

  return null
}

const TextInputContainer = ({ htmlFor, children, label, size, type, customStyle }) => (
  <StyledTextInputContainer className={`${size}`}>
    <FormGroup style={{ marginBottom: 0, backgroundColor: 'transparent' }} cols="true" className={`${label}`}>
      {handleLabel(label, htmlFor, type)}
      {children}
      
    </FormGroup>
    {renderTooltip(label)}
  </StyledTextInputContainer>
);

TextInputContainer.defaultProps = {
  isRequired: false,
  renderCustomInput: () => null,
  customStyle: {}
}

export default TextInputContainer;
