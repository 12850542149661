import { filterClients } from '../../../../../../utils/clientsTableHelper';

export const onGetClientsSuccess = (state, payload) => {
  return {
    ...state,
    requesting: false,
    error: undefined,
    list: filterClients(payload.data || []),
    pagination: {
      ...state.pagination,
      totalCount: payload.pagination.total,
      lastPage: payload.pagination.last_page,
      currentPage: payload.pagination.current_page,
      resultsPerPage: parseInt(payload.pagination.per_page, 10),
    }
  }
}


export const onGetClientsFailed = (state, payload) => (
  {
    ...state,
    requesting: false,
    error: payload
  }
);

export const onGetClients = (state, payload) => (
  {
    ...state,
    requesting: true,
    error: payload
  }
);

export const onStatusModalOpen = (state) => (
  {
    ...state,
    isVisible: true
  }
);

export const onStatusModalClose = (state) => (
  {
    ...state,
    isVisible: false
  }
);

export const saveSelectedCustomer = (state, payload) => (
  {
    ...state,
    selectedCustomer: payload
  }
);

export const onChangeSort = (state, payload) => ({
  ...state,
  pagination: {
    ...state.pagination,
    sortBy: payload
  }
});

export const onChangePage = (state, payload) => (
  {
    ...state,
    pagination: {
      ...state.pagination,
      currentPage: payload
    }
  }
);

export const onChangeResultsPerPage = (state, payload) => (
  {
    ...state,
    pagination: {
      ...state.pagination,
      resultsPerPage: payload,
      currentPage: 1
    }
  }
);

export const onResetPagination = (state) => (
  {
    ...state,
    pagination: {
      totalCount: undefined,
      numberOfPages: undefined,
      lastPage: undefined,
      currentPage: 1,
      resultsPerPage: state.pagination.resultsPerPage,
      sortBy: 'nome',
      nextPage: '',  
      previousPage: '',
      filter: '',
      type: 'confirmado'
    }
  }
);

export const onChangeTab = (state, payload) => ({
  ...state,
  pagination: {
    ...state.pagination,
    totalCount: undefined,
    type: payload,
    currentPage: 1
  }
});

export const onSetFilter = (state, payload) => ({
  ...state,
  pagination: {
    ...state.pagination,
    filter: payload,
    currentPage: 1
  }
});
