
const formatTimes = (times) => {
  if (times === undefined || times === null) {
    return 0;
  }
  const timesString = times.toFixed(1);
  return timesString.replace(/\.(?!\.)/g, ",");
}

export default (data) => {
  const { laudo, locacao, pretendente, extra } = data;
  const reportObj = (laudo !== undefined && typeof laudo === "object")? laudo : {}
  const {
    principal_origem_renda,
    perfil_socioeconomico,
    compatibilidade_renda,
    parecer_sistemico,
    score_serasa,
    situacao_cpf,
    suspeita_obito,
    rede_ficha,
    restricoes_financeiras,
    participacao_empresarial,
    veracidade_data_nascimento,
    produtos
  } = reportObj;
  const {score_fc, parecer_key, parecer} = parecer_sistemico[0]
  const totalConsultasAnteriores = rede_ficha.pesquisas_anteriores_api.result.total_consultas_credito

  const productFcAnalysisPlus = pretendente.produtos.ativo.filter(produto => produto.id === 1 && produto.status !== 'INCLUIDO')
  const productFcAnalysis = pretendente.produtos.ativo.filter(produto => produto.id === 3)
  const productFcCompletaPF = pretendente.produtos.ativo.filter(produto => produto.id === 7 && produto.status !== 'INCLUIDO')

  let nao_confirmado = false

  if(produtos && Object.keys(produtos.fcrenda).length && produtos.fcrenda.ativo) {
    if(produtos.fcrenda.resultado && produtos.fcrenda.resultado.titulo == 'PERFIL FINANCEIRO NÃO CONFIRMADO') {
      nao_confirmado = true
    }
  }

  // Renda presumida
  const incomeDeclarated = (compatibilidade_renda !== undefined && compatibilidade_renda.result !== null)? compatibilidade_renda.result.vezes : 0;
  // Renda informada
  const incomePresumed = (perfil_socioeconomico !== undefined && perfil_socioeconomico.result !== null)? perfil_socioeconomico.result.vezes : 0;
  console.log('aqui2', parecer_sistemico)
  // Serasa score
  let iconScoreFc = "negativo";
  if (score_fc !== undefined) {
    if ((score_fc >= 401 && score_fc <= 650) || (score_fc == 'Falta de Score Mercado')) {
      iconScoreFc = "neutro";
    } else if (score_fc >= 651 && score_fc <= 1000) {
      iconScoreFc = "positivo";
    }
  }

  let parecerRiscoCreditoMercado = 'MUITO ALTO';
  let iconRiscoCreditoMercado = 'triangule-red';
  if(score_serasa >= 850) {
    parecerRiscoCreditoMercado = 'MUITO BAIXO';
    iconRiscoCreditoMercado = 'triangule-green-dark'
  } else if(score_serasa >= 650 && score_serasa <= 849) {
    parecerRiscoCreditoMercado = 'BAIXO';
    iconRiscoCreditoMercado = 'triangule-green-light'
  } else if(score_serasa >= 400 && score_serasa <= 649) {
    parecerRiscoCreditoMercado = 'MÉDIO';
    iconRiscoCreditoMercado = 'triangule-yellow'
  } else if(score_serasa >= 200 && score_serasa <= 399) {
    parecerRiscoCreditoMercado = 'ACIMA DA MÉDIA';
    iconRiscoCreditoMercado = 'triangule-orange'
  } else if(score_serasa >= 0 && score_serasa <= 199) {
    parecerRiscoCreditoMercado = 'MUITO ALTO';
    iconRiscoCreditoMercado = 'triangule-red'
  }

  let parecerRiscoIndividualLocacao = 'MUITO ALTO';
  let iconRiscoIndividualLocacao = 'triangule-red';
  if(parecer_key === 'PARECER_NIVEL_1') {
    parecerRiscoIndividualLocacao = 'MUITO BAIXO';
    iconRiscoIndividualLocacao = 'triangule-green-dark'
    iconScoreFc = 'triangule-green-dark'
  } else if(parecer_key === 'PARECER_NIVEL_2') {
    parecerRiscoIndividualLocacao = 'BAIXO';
    iconRiscoIndividualLocacao = 'triangule-green-light'
    iconScoreFc = 'triangule-green-light'
  } else if(parecer_key === 'PARECER_NIVEL_3') {
    parecerRiscoIndividualLocacao = 'MÉDIO';
    iconRiscoIndividualLocacao = 'triangule-yellow'
    iconScoreFc = 'triangule-yellow'
  } else if(parecer_key === 'PARECER_NIVEL_4') {
    parecerRiscoIndividualLocacao = 'ACIMA DA MÉDIA';
    iconRiscoIndividualLocacao = 'triangule-orange'
    iconScoreFc = 'triangule-orange'
  } else if(parecer_key === 'PARECER_NIVEL_5') {
    parecerRiscoIndividualLocacao = 'MUITO ALTO';
    iconRiscoIndividualLocacao = 'triangule-red'
    iconScoreFc = 'triangule-red'
  }

  const pareceres = [
    'PARECER_NIVEL_1',
    'PARECER_NIVEL_2',
    'PARECER_NIVEL_3',
    'PARECER_NIVEL_4',
    'PARECER_NIVEL_5'
  ];
  if(!pareceres.includes(parecer_key)) {
    parecerRiscoIndividualLocacao = parecer;
    iconRiscoIndividualLocacao = 'neutro';
    iconScoreFc = 'neutro'
  }

  if(parecer_key === 'ALERTA_ACAO_CRIMINAL') {
    iconRiscoIndividualLocacao = 'triangule-alert-red'
    // iconRiscoCreditoMercado = 'triangule-alert-red'
    // parecerRiscoCreditoMercado = 'ALERTA – Verificar ações criminais'
    iconScoreFc = 'triangule-alert-red'
  }

  if(parecer_key === 'ALERTA_FRAUDE_CRIMINAL') {
    iconRiscoIndividualLocacao = 'triangule-alert-red'
    // iconRiscoCreditoMercado = 'triangule-alert-red'
    // parecerRiscoCreditoMercado = 'ALERTA – Verificar ações criminais. Possibilidade de FRAUDE em virtude de excessivas consultas. Recomendamos a análise da documentação e das consultas, o que pode ser solicitado através do FC DOC.'
    iconScoreFc = 'triangule-alert-red'
  }

  if(parecer_key === 'ALERTA_FRAUDE') {
    iconRiscoIndividualLocacao = 'triangule-alert-red'
    // iconRiscoCreditoMercado = 'triangule-alert-red'
    // parecerRiscoCreditoMercado = 'ALERTA – Possibilidade de FRAUDE em virtude de excessivas consultas. Recomendamos a análise da documentação e das consultas, o que pode ser solicitado através do FC DOC.'
    iconScoreFc = 'triangule-alert-red'
  }

  let iconIdade = 'triangule-red'
  let idade = ''
  if(veracidade_data_nascimento.result.idade) {
    idade = veracidade_data_nascimento.result.idade
    if(idade >= 46) {
      iconIdade = 'triangule-green-dark'
    } else if(idade >= 30 && idade <= 45) {
      iconIdade = 'triangule-green-light'
    } else if(idade >= 25 && idade <= 29) {
      iconIdade = 'triangule-yellow'
    } else if(idade >= 18 && idade <= 24) {
      iconIdade = 'triangule-orange'
    } else if(idade <= 17) {
      iconIdade = 'triangule-red'
    }
  }

  let origemPrincipal = ''
  let iconOrigemPrincipal = 'neutro'
  if(principal_origem_renda.result.origem) {
    origemPrincipal = principal_origem_renda.result.origem
    switch(origemPrincipal) {
      case 'Funcionário Privado Registrado (CLT)':
      case 'Funcionário Público (CLT)':
        iconOrigemPrincipal = 'triangule-green-light'
        break;
      case 'Funcionário Público (Estatutário)':
      case 'Aposentado ou Pensionista':
      case 'Militar':
        iconOrigemPrincipal = 'triangule-green-dark'
        break;
      case 'Limite do Cartão de Crédito':
      case 'Profissional Liberal ou Autônomo':
      case 'Extratos Bancários':
      case 'Pensão Alimentícia ou Judicial':
      case 'Estagiário ou Bolsista':
      case 'Outro':
      case 'Renda Não Informada':
        iconOrigemPrincipal = 'triangule-yellow'
        break;
      case 'Funcionário Privado Registrado (CLT)':
        iconOrigemPrincipal = 'triangule-orange'
        break;
      case 'Funcionário Privado Registrado (CLT)':
        iconOrigemPrincipal = 'triangule-red'
        break;
    }
  }

  let titleTypeTenancy = "",
      messageTypeTenancy = "",
      willComposeAmount = "";

  if (locacao !== undefined && locacao !== null && typeof locacao === "object" && Object.keys(locacao).length > 0) {

    if(locacao.tipo_imovel === "RESIDENCIAL") {
      let customProfile = false
      if('customProfile' in extra && extra.customProfile.value) {
        customProfile = true
      }

      titleTypeTenancy = "IRÁ RESIDIR";
      messageTypeTenancy = pretendente.residir ? `SIM ${!customProfile && '(Renda padrão 3x)' || ''}` : `NÃO ${!customProfile && '(Renda padrão 4x)' || ''}`;
      
    } else {

      titleTypeTenancy = "PARTICIPARÁ DO NEGÓCIO";
      messageTypeTenancy = pretendente.participante ? "SIM (Renda padrão 4x)" : "NÃO (Renda padrão 4x)";
    }

  }

  if (pretendente !== undefined && pretendente !== null && typeof pretendente === "object" && Object.keys(pretendente).length > 0) {
    willComposeAmount = pretendente.compor_renda;
  }

  let acoes_criminais_desconsideradas_pelo = pretendente.acoes_criminais_desconsideradas_pelo === 'CLIENTE' ? ` (Alerta Criminal desconsiderado pelo Cliente)` : ''
  let alerta_fraude_desconsiderado_pelo = pretendente.alerta_fraude_desconsiderado_pelo === 'CLIENTE' ? ` (Alerta de Fraude desconsiderado pelo Cliente)` : ''

  let objectReturn = [
    {
      label: "SITUAÇÃO CPF",
      status: (situacao_cpf !== undefined && situacao_cpf !== null)? situacao_cpf.icon : "neutro",
      message: (situacao_cpf !== undefined && situacao_cpf !== null)? (situacao_cpf.recommendation[0] == "Regularização verificada pelo cliente")? "Regular (Regularização verificada pelo cliente)" : (situacao_cpf.recommendation[0] == "Regularização verificada pelo Analista FC") ? 'Regular (Verificada pelo Analista FC)' : situacao_cpf.note && situacao_cpf.note.length ? `${situacao_cpf.result} <b style="color: #fe4d36;">(${situacao_cpf.note[0]})</b>` : situacao_cpf.result : ""
    },
    {
      label: "IDADE",
      status: iconIdade,
      message: idade ? idade == 'Não encontrado' ? idade : idade + ' anos' : ''
    },
    {
      label: "Suspeita de ÓBITO",
      status: situacao_cpf !== undefined? suspeita_obito.icon : "neutro",
      message: (situacao_cpf !== undefined && suspeita_obito.result)? "SIM" : "NÃO"
    },
    // {
    //   label: "REDE FICHA CERTA",
    //   status: rede_ficha !== undefined? rede_ficha.result.icon : "neutro",
    //   message: (rede_ficha !== undefined && data.laudo.rede_ficha.result.icon === "positivo") ? "NADA CONSTA" : "POSSUI INFORMAÇÃO"
    // },
    {
      label: "Consultas recentes (Mercado)*",
      status: (rede_ficha !== undefined && totalConsultasAnteriores >= 7 && totalConsultasAnteriores <= 20) ? "laranja-nulo" : (rede_ficha !== undefined && totalConsultasAnteriores >= 21) ? "negativo" : "neutro",
      message: (rede_ficha !== undefined && totalConsultasAnteriores == 1) ? `${totalConsultasAnteriores} consulta*` : (rede_ficha !== undefined && totalConsultasAnteriores > 1) ? `${totalConsultasAnteriores} consultas*${alerta_fraude_desconsiderado_pelo}` : "Não encontrado*"
    },
    {
      label: titleTypeTenancy,
      status: "neutro",
      message: messageTypeTenancy
    },
    {
      label: "RENDA PESSOAL informada",
      status: compatibilidade_renda !== undefined? incomeDeclarated == 0 ? "negativo" : data.laudo.compatibilidade_renda.icon : "neutro",
      message: `${formatTimes(incomeDeclarated)} vezes a locação`
    },
    {
      label: "ORIGEM Perfil Financeiro",
      status: (principal_origem_renda !== undefined && principal_origem_renda.result.origem) ? iconOrigemPrincipal : "neutro",
      message: principal_origem_renda.result.origem ? principal_origem_renda.result.origem : "Não informado"
    },
    {
      label: "RENDA PRESUMIDA (Mercado)",
      status: (perfil_socioeconomico !== undefined && perfil_socioeconomico !== null)? perfil_socioeconomico.icon : "neutro",
      message: `${formatTimes(incomePresumed)} vezes a locação`
    },
    {
      label: "PART. EMPRESARIAL*",
      status: (participacao_empresarial !== undefined && participacao_empresarial !== null)? participacao_empresarial.icon : "neutro",
      message: (participacao_empresarial !== undefined && participacao_empresarial !== null && Array.isArray(participacao_empresarial.result) && participacao_empresarial.result.length > 0)? "ENCONTRADA*" : "NÃO ENCONTRADA*"
    },
    {
      label: "RISCO de CRÉDITO (Mercado)",
      status: iconRiscoCreditoMercado,
      message: parecerRiscoCreditoMercado
    },
    {
      label: "RISCO FC nesta locação",
      status: iconRiscoIndividualLocacao,
      message: parecerRiscoIndividualLocacao
    },
    {
      label: `${(parecer_key == 'PARECER_NAO_APURADO' || parecer == 'NÃO APURADO' || nao_confirmado) ? 'Score de Mercado' : 'SCORE FC nesta locação'}`,
      status: iconScoreFc,
      message: `${score_fc} ${(score_fc != 'Falta de Score Mercado' && score_fc != 'Não apurado' && score_fc != 'ALERTA – Verificar ações criminais' && score_fc != 'ALERTA – Verificar ações criminais. Possibilidade de FRAUDE em virtude de excessivas consultas. Recomendamos a análise da documentação e das consultas, o que pode ser solicitado através do FC DOC.' && score_fc != 'ALERTA – Possibilidade de FRAUDE em virtude de excessivas consultas. Recomendamos a análise da documentação e das consultas, o que pode ser solicitado através do FC DOC.') && '(de 0 a 1000)' || ''}`
    },
    {
      label: "NEGATIVAÇÕES DE CRÉDITO*",
      status: (restricoes_financeiras !== undefined && restricoes_financeiras !== null) && (restricoes_financeiras.result.cheques.info === 'CONSTA' || restricoes_financeiras.result.pendencias.info === 'CONSTA' || restricoes_financeiras.result.protestos.info === 'CONSTA')? 'negativo' : "positivo",
      message: (restricoes_financeiras !== undefined && restricoes_financeiras !== null) && (restricoes_financeiras.result.cheques.info === 'CONSTA' || restricoes_financeiras.result.pendencias.info === 'CONSTA' || restricoes_financeiras.result.protestos.info === 'CONSTA')? "ENCONTRADO*" : "NÃO ENCONTRADO*"
    },
    {
      label: "AÇÕES DE DESPEJO E DE LOCAÇÃO*",
      status: (restricoes_financeiras !== undefined && restricoes_financeiras !== null && restricoes_financeiras.result.acoes.info === 'CONSTA')? 'negativo' : "positivo",
      message: (restricoes_financeiras !== undefined && restricoes_financeiras !== null && restricoes_financeiras.result.acoes.info === 'CONSTA')? "ENCONTRADO*" : "NÃO ENCONTRADO*"
    },
    // {
    //   label: "AÇÕES DE DESPEJO E DE LOCAÇÃO*",
    //   status: (restricoes_financeiras !== undefined && restricoes_financeiras !== null)? restricoes_financeiras.icon : "neutro",
    //   message: (restricoes_financeiras !== undefined && restricoes_financeiras !== null && restricoes_financeiras.icon === "negativo")? "ENCONTRADO" : "NÃO ENCONTRADO"
    // },
    {
      label: "AÇÕES CRIMINAIS (v. Beta)*",
      status: (restricoes_financeiras !== undefined && restricoes_financeiras !== null && 'acoes_criminais' in restricoes_financeiras.result && restricoes_financeiras.result.acoes_criminais.info === 'CONSTA')? 'roxo-nulo' : "neutro",
      message: (restricoes_financeiras !== undefined && restricoes_financeiras !== null && 'acoes_criminais' in restricoes_financeiras.result && restricoes_financeiras.result.acoes_criminais.info === 'CONSTA')? `ENCONTRADO*${acoes_criminais_desconsideradas_pelo}` : "NÃO ENCONTRADO*"
    },
    {
      label: "AÇÕES CÍVEIS (v. Beta)*",
      status: (restricoes_financeiras !== undefined && restricoes_financeiras !== null && 'acoes_civeis' in restricoes_financeiras.result && restricoes_financeiras.result.acoes_civeis.info === 'CONSTA')? 'brown-nulo' : "neutro",
      message: (restricoes_financeiras !== undefined && restricoes_financeiras !== null && 'acoes_civeis' in restricoes_financeiras.result && restricoes_financeiras.result.acoes_civeis.info === 'CONSTA')? "ENCONTRADO*" : "NÃO ENCONTRADO*"
    },
    // {
    //   label: "IRÁ COMPOR RENDA",
    //   status: "neutro",
    //   message: willComposeAmount? "SIM" : "NÃO"
    // },
  ]

  console.log('ok1', restricoes_financeiras)

  if(!('total_consultas_credito' in rede_ficha.pesquisas_anteriores_api.result)) {
    objectReturn = objectReturn.filter(item => item.label != 'Consultas recentes (Mercado)*')
  }

  if(productFcAnalysis.length && !productFcAnalysisPlus.length) {
    objectReturn = objectReturn.filter(item => item.label != 'NEGATIVAÇÕES DE CRÉDITO*')
  }

  // if(productFcAnalysis.length && !productFcAnalysisPlus.length) {
  //   objectReturn = objectReturn.filter(item => item.label != 'AÇÕES DE DESPEJO E DE LOCAÇÃO*')
  // }

  // if(productFcAnalysisPlus.length) {
  //   objectReturn = objectReturn.filter(item => item.label != 'AÇÕES DE DESPEJO E DE LOCAÇÃO*')
  // }

  if(!('acoes_criminais' in restricoes_financeiras.result)) {
    objectReturn = objectReturn.filter(item => item.label != 'AÇÕES CRIMINAIS (v. Beta)*')
  }

  if(!productFcAnalysisPlus.length && !productFcCompletaPF.length) {
    objectReturn = objectReturn.filter(item => item.label != 'AÇÕES CÍVEIS (v. Beta)*')
  }

  if(!('acoes_civeis' in restricoes_financeiras.result)) {
    objectReturn = objectReturn.filter(item => item.label != 'AÇÕES CÍVEIS (v. Beta)*')
  }

  return objectReturn
}
