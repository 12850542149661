import { isValid } from 'cpf';
import CNPJ from 'cnpj';
import moment from 'moment';

export const warnDate = (value) => {
	if (value && value !== undefined && value !== null) {
		if (value.length === 10) {
			if (!moment(value, "YYYY-MM-DD", true).isValid()) {
				return "Informe uma data válida.";
			}
		}
	}

	return undefined;
}

export const warnPhone = (value) => {
	if (value) {
		if (value.length < 10) {
			return "Informe um telefone válido.";
		}
	}
	return undefined;
}

export const warnEmail = (value) => {
	if (value !== undefined && value !== "") {
		const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'g');
		const isValid = regex.test(value);
		if (!isValid) {
			return 'E-mail de formato inválido.';
		}
	}

	return undefined;
}

export const cepWarning = value => {
	let error = "CEP deve ter 8 digitos";

	if (value !== undefined) {
		if (value.length === 8) {
			error = undefined;
		}
		return error;
	}

	return undefined;
};

export const CPJCNPJWarning = (value) => {
	if (value !== undefined && value !== "" && value !== null) {
		if (value.length < 11) {
			return 'O CPF/CNPJ inválido.';
		} if (value.length === 11) {
			if (!isValid(value)) {
				return 'CPF inválido!';
			}
		} else if (value.length > 11 && value.length < 14) {
			return 'O CPF/CNPJ inválido.';
		} else if (value.length >= 14) {
			if (!CNPJ.validate(value)) {
				return 'CNPJ inválido!';
			}
		}
	}

	return undefined;
}