import React from 'react';
import { Tr, Th } from '../../../../components/shared/customTable.styled';

const ChargeTableHeader = (props) => {
  return (
    <Tr>
      <Th>
        ID
      </Th>
      <Th style={{position: "absolute", left: "50px"}}> 
        CÓD. IMÓVEL
      </Th>
      <Th style={{position: "absolute", left: "170px"}}>
        ENDEREÇO
      </Th>
      {/* {props.master &&
        <Th style={{position: "absolute", right: "45px"}}>
          TOTAL
        </Th>
      } */}
      <Th style={{position: "absolute", right: "30px"}}>
      </Th>
    </Tr>
  )
}

export default ChargeTableHeader