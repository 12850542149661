
export const getRisks = (data) => {
  let risks = [];

  if (data !== undefined && data !== null && typeof data === "object") {
    if ("laudo" in data) {
      const { laudo } = data;

      if ("parecer_sistemico" in laudo) {
        const { parecer_sistemico } = laudo;

        if (Array.isArray(parecer_sistemico) && parecer_sistemico.length > 0) {
          risks = parecer_sistemico.filter((parecer) => parecer.risco !== "").map((parecer) => parecer.risco);
        }
      }
    }
  }

  return risks;
}

export const getDicaOuro = (data) => {
  let dicaOuro = [];

  if (data !== undefined && data !== null && typeof data === "object") {
    if ("laudo" in data) {
      const { laudo } = data;

      if ("parecer_sistemico" in laudo) {
        const { parecer_sistemico } = laudo;

        if (Array.isArray(parecer_sistemico) && parecer_sistemico.length > 0) {
          dicaOuro = parecer_sistemico.filter((parecer) => parecer.dica_ouro !== "").map((parecer) => parecer.dica_ouro);
        }
      }
    }
  }

  return dicaOuro;
}

export const getSystemicFeeback = (data, key = 'parecer') => {
  let systemicFeeback = [];

  if (data !== undefined && data !== null && typeof data === "object") {
    if ("laudo" in data) {
      const { laudo } = data;

      if ("parecer_sistemico" in laudo) {
        const { parecer_sistemico } = laudo;
        if (Array.isArray(parecer_sistemico)) {
          systemicFeeback = parecer_sistemico.map((feeback) => feeback[key]);
        } else if (typeof parecer_sistemico === "object") {
          systemicFeeback.push(parecer_sistemico[key]);
        }
      }
    }
  }

  return systemicFeeback;
}

export const getSytemicRecomendation = (data) => {
  let recommendations = [];

  if (data !== undefined && data !== null && typeof data === "object") {
    if ("laudo" in data) {
      const { laudo } = data;

      if ("parecer_sistemico" in laudo) {
        const { parecer_sistemico } = laudo;     
        if (Array.isArray(parecer_sistemico)) {
          recommendations = parecer_sistemico
            .map((feedback) => {
              if (Array.isArray(feedback.recomendacao) && feedback.recomendacao.length > 0) {
                return feedback.recomendacao[0];
              }
              
              if (typeof feedback.recomendacao === "string") {
                return feedback.recomendacao;
              }
              return undefined
          });
        } else {
          if (typeof parecer_sistemico === "object") {
            if (Array.isArray(parecer_sistemico.recomendacao) && parecer_sistemico.recomendacao.length > 0) {
              return parecer_sistemico.recomendacao[0];
            }
            
            if (typeof parecer_sistemico.recomendacao === "string") {
              return parecer_sistemico.recomendacao;
            }
          }
        }
      }
    }
  }
  return recommendations;
}

export const getRecommendation = (data) => {
  let recommendations = [];

  if (data !== undefined && data !== null && typeof data === "object") {
    if ("laudo" in data) {
      const { laudo } = data;

      if ("parecer_sistemico" in laudo) {
        const { parecer_sistemico } = laudo;
        const filteredData = parecer_sistemico.filter((feedback) => {
          if (Array.isArray(feedback.recomendacao) && feedback.recomendacao.length >= 1) {
            return true;
          }
          return false;
        });
        let finalAr = [];
        if (Array.isArray(filteredData)) {
          filteredData.forEach((feedback) => {
            console.log('feedback',feedback)
            finalAr = [...finalAr, ...feedback.recomendacao];
          });
        }
        recommendations = finalAr;
      }
    }
  }

  return recommendations;
}
