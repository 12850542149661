import { ChoseEntityButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { MainContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { Fragment } from 'react';
import styled, { css } from 'styled-components';

export const StyledChoseEntityButton = styled(ChoseEntityButton)`
  display: inline-block;
  border-color: #152b4e;
  background-color: #fff;
  border-radius: 0px;
  padding: 0.375rem 1.65rem;
  transform: scale(1);
  font-size: .88rem;
  height: 2rem !important;
  width: 10rem !important;
  margin: 0 0 20px 0 !important;
  color: #152b4e;

  &:hover {
    color: #fff;
    background-color: #152b4e;
    border-color: #152b4e;
  }

  ${props => css`${props.selected &&
    `
    color: #fff;
    background-color: #152b4e;
    cursor: default;
    `
  }`}

  ${props => css`${props.styles}`};
`;

export const StyledFragment = styled(Fragment)`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  ${props => css`${props.styles}`};
`;

export const StyledMainContainer = styled(MainContainer)`
  padding: 2rem 0 0 0 !important;
  ${props => css`${props.styles}`};

  @media (max-width: 800px){
    padding: 20% 0 0 0 !important;
  }
`;

export const StyledRowComponent = styled(StyledRow)`
  padding: 0 0 6.1rem 0 !important;
  ${props => css`${props.styles}`};

  @media (max-width: 800px){
    padding: 0 0 15% 0 !important;
  }
`;

