import React, { Component } from "react";
import styled from 'styled-components';

const SVG = styled.svg`
  fill: ${props => props.color || props.theme.color_gray};
`;

export default class GuestSVG extends Component {
  render() {
    return (
      <SVG viewBox="0 0 600 600" {...this.props}>
        <circle className="st0" cx="300" cy="300" r="265" />
        <circle className="st0" cx="300" cy="230" r="115" />
        <path
          className="st0"
          d="M106.8,481.4c37.9-106.7,155.1-162.5,261.8-124.6c58.2,20.7,103.9,66.4,124.6,124.6"
        />
      </SVG>
    );
  }
}
