import React from 'react';

import IdentificationCard from './IdentificationSection/Identification';
import IdentificationCardPj from './IdentificationSection/IdentificationPj';
import MercadoCardPj from './MercadoSection/MercadoPj';
import LocationCard from './LocationSection/Location';
import LocationCardPj from './LocationSection/LocationPj';
import SociosDiretoresCardPj from './SociosDiretoresSection/SociosDiretoresPj';
import FichaCertaNetwork from './FichaCertaNetworkSection/FichaCertaNetwork';
import TradeFinancial from './TradeFinancialSection/TradeFinancial';
import TradeFinancialAcoes from './TradeFinancialSection/TradeFinancialAcoes';
import TradeFinancialAcoesCriminais from './TradeFinancialSection/TradeFinancialAcoesCriminais';
import RentAndJob from './RentAndJobSection/RentAndJob';
import RentAndJobPj from './RentAndJobSection/RentAndJobPj';
import RiskProfile from './RiskProfileSection/RiskProfile';
import RiskProfilePj from './RiskProfileSection/RiskProfilePj';
import ContainerLoading from '../ContainerLoading';

import {
  CardAnalythic,
  SubtitleCard,
  SubtitleSemiBold,
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  RecommendationBox,
  RecommendationText,
  SpanRecommendation,
  RecommendationsColumn,
  IconRecommendation
} from './analythic.styled';

import ReportRecommendation from '@bit/wavfichacerta.fichacerta.icons.report-recommendation';

import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  FlexContainer
} from '../SyntheticReport/syntheticReport.styles';
import { StyledRow } from '../../shared/sharedStyles.styles';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../shared/TourTooltip';

const renderLoading = () => [
  <ContainerLoading key={1} size="medium" showFirstIcon={true} showSecondIcon={true} showText={false} lightAlert />,
  <ContainerLoading key={2} size="extra-large" showFirstIcon={true} showSecondIcon={true} showText={false} margin="1rem auto auto auto" lightAlert />,
  <ContainerLoading key={3} size="small" showFirstIcon={true} showSecondIcon={false} showText={false} margin="1rem auto auto auto" lightAlert />,
  <ContainerLoading key={4} size="extra-large" showFirstIcon={true} showSecondIcon={true} showText={false} margin="1rem auto auto auto" lightAlert />,
  <ContainerLoading key={5} size="small" showFirstIcon={true} showSecondIcon={true} showText={false} margin="1rem auto auto auto" lightAlert />,
  <ContainerLoading key={6} size="medium" showFirstIcon={true} showSecondIcon={true} showText={false} margin="1rem auto auto auto" lightAlert />
]

const getRecommendationsDicaOuro = (props) => {
  const { data } = props;

  if (data !== undefined && data !== null && (typeof data === "object" && Object.keys(data).length > 0)) {
    const { laudo } = data;

    if (Array.isArray(laudo.parecer_sistemico) && laudo.parecer_sistemico.length > 0 && laudo.parecer_sistemico[0].dica_ouro) {
      return (
        <RecommendationBox customstyle='margin-bottom: 10px !important;width: 99.6%;'>
          <BaseColumn xs={12} md={1} lg={1} xl={1}>
            <RecommendationText style={{color: '#000'}}>Obs: </RecommendationText>
          </BaseColumn>
          <RecommendationsColumn xs={12} md={11} lg={11} xl={11}>
            <RecommendationText>
              <IconRecommendation><ReportRecommendation/></IconRecommendation>
              <SpanRecommendation style={{color: '#000'}} dangerouslySetInnerHTML={{__html: data.laudo.parecer_sistemico[0].dica_ouro}} />
            </RecommendationText>
          </RecommendationsColumn>
        </RecommendationBox>
      );
    }
  }
  return null;
}

export default (props) => {
   
  if(props.loading) { return renderLoading() }
  
  const isPF = props.data.pretendente.cpf ? true : false;

  console.log('tomax1', props)

  return (
    <FlexContainer>
      <StyledRow>
        {isPF &&
          <div style={{width: '100%'}}>
            <div className="dados-e-localizacao" style={{width: '100%'}}>
              <BaseColumn xs={12} sm={12} xl={12} className='identificationCard'>
                <IdentificationCard data={props.data} />
                {getRecommendationsDicaOuro(props)}
              </BaseColumn>
            
              <BaseColumn xs={12} sm={12} xl={12} className='locationCard'>
                <LocationCard data={props.data} />
              </BaseColumn>
            </div>
          </div>
        }
        {!isPF &&
          <React.Fragment>
            <BaseColumn xs={12} cols={12} className='identificationCardPj'>
              <MercadoCardPj data={props.data} />
            </BaseColumn>
            <div className="identificacao-localizacao" style={{width: '100%'}}>
              <BaseColumn xs={12} cols={12} className='identificationCardPj'>
                <IdentificationCardPj data={props.data} />

                {/* <TourTooltip
                  target="tooltip-localizacao"
                  style={{right: 0, top: -15}}
                />
                <ReactTooltip id="tooltip-localizacao" place="left" type="dark" effect="float">
                  <span>Confira aqui os dados de identificação e localização.</span>
                </ReactTooltip> */}
              </BaseColumn>
              <BaseColumn xs={12} cols={12} className='locationCardPj'>
                <LocationCardPj data={props.data} />
              </BaseColumn>
            </div>
            <BaseColumn xs={12} cols={12} className='locationCardPj socios-diretores'>
              <SociosDiretoresCardPj data={props.data} />

              {/* <TourTooltip
                target="tooltip-socios-diretores"
                style={{right: 0, top: -15}}
              />
              <ReactTooltip id="tooltip-socios-diretores" place="left" type="dark" effect="float">
                <span>Apresentamos aqui o quadro de sócios.</span>
              </ReactTooltip> */}
            </BaseColumn>
          </React.Fragment>
        }
        
        <BaseColumn xs={12} cols={12}>
          {isPF && <FichaCertaNetwork data={props.data} />}
          {isPF && <RentAndJob data={props.data} />}
          {!isPF && <RentAndJobPj data={props.data} />}
          <TradeFinancial data={props.data} />
          {isPF && <TradeFinancialAcoes data={props.data} />}
          {isPF && <TradeFinancialAcoesCriminais data={props.data} />}
          {/* {isPF && <RiskProfile data={props.data} />} */}
          {!isPF && <RiskProfilePj data={props.data} />}
        </BaseColumn>
      </StyledRow>
    </FlexContainer>
  ) 
};
