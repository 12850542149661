import React, { Component } from 'react'

export default class Financial extends Component {
  render() {
    return (
    <svg viewBox="0 0 124 124" className="nano">
        <path className="white" d="M57.7,91v-7.6c-4.3-0.1-8.8-1.4-11.5-3.3l1.8-5c2.7,1.8,6.6,3.2,10.8,3.2c5.3,0,8.9-3.1,8.9-7.3 c0-4.1-2.9-6.7-8.5-8.9c-7.7-3-12.4-6.4-12.4-13c0-6.2,4.4-11,11.3-12.1v-7.6h4.6v7.3c4.5,0.2,7.6,1.3,9.7,2.6l-1.9,4.9 c-1.6-0.9-4.6-2.5-9.4-2.5c-5.8,0-7.9,3.4-7.9,6.5c0,3.9,2.8,5.9,9.3,8.6c7.7,3.1,11.6,7,11.6,13.7c0,5.9-4.1,11.5-11.9,12.8V91 H57.7z"/>
        <path className="white" d="M61,13.5c-27.3,0-49.5,22.2-49.5,49.5c0,27.3,22.2,49.5,49.5,49.5s49.5-22.2,49.5-49.5 C110.5,35.7,88.3,13.5,61,13.5z M61,108c-24.9,0-45-20.1-45-45c0-24.9,20.1-45,45-45s45,20.1,45,45C106,87.9,85.9,108,61,108z"/>
    </svg>
    )
  }
}
