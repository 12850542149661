import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';

// import CardOld from '../../../../../components/shared/card/Card';
import CardOld from '@bit/wavfichacerta.fichacerta.cards.card';
import icons from '../../../../../components/shared/treatedIcons';
import IncomeModal from '../incomeModal/IncomeModal';
import { getInitialValues } from './selectors';

import getIncomeFields from './incomeFields';
import incomeFields from './fields';

import { getValues } from '../selectors';
import { formatToBRL } from '../../../../../utils/formatter';
import styled from 'styled-components'

const Card = styled(CardOld)`
  .box-floater {
    position: absolute;
    top: -16px;
    left: 0;
    width: 100%;
    height: 20px;
    background-color: #fff;
  }

  .wrapper {
    display: flex;
  }

  h5 {
    display: none;
  }

  @media (max-width: 800px) {
    .wrapper {
      display: block;

      > div {
        width: 100% !important;
      }

      textarea {
        margin-left: 0 !important;
        width: 100% !important;
        margin-top: 20px;
      }
    }
  }
`

const List = styled.div`
  position: absolute;
  width: 100%;
  bottom: 68px;
  background-color: #152b4e;
  z-index: 1;

  > div {
    :hover {
      background-color: #152b4e;
    }
  }
`

const { RoundedMoney } = icons;

class Select extends React.Component {
  state = {
    showList: false,
    selectedItem: this.props.analises[0].resultado || null
  }

  renderOptions = () => {
    if(!this.state.showList) return null
    const { analises, resultado_fcrenda } = this.props
    let selectedItem = this.state.selectedItem
    // if(analises.length) {
    //   selectedItem = analises[0].resultado
    // }
    console.log('selectedItem', selectedItem)

    return resultado_fcrenda.map(resultado => {
      console.log('resultado1', resultado)
      const titulo = `${resultado.label.titulo}${resultado.label.descricao && `: ${resultado.label.descricao}`}`
      return <div
        key={resultado.label.id}
        value={resultado.label.id}
        // selected={selectedItem == resultado.label.id}
        style={{
          padding: '10px 20px'
        }}
        onClick={() => this.selectOption(resultado.label)}
      >
        {titulo}
      </div>
    })
  }

  handleToggleList = () => {
    this.setState({showList: !this.state.showList})
  }

  selectOption = (value, titulo) => {
    this.handleToggleList()
    console.log('selected', value)
    this.setState({selectedItem: value}, () => {
      this.props.onChange({target: {value: value, titulo}})
    })
  }

  render() {
    const titulo = `${this.state.selectedItem.titulo}${this.state.selectedItem.descricao && `: ${this.state.selectedItem.descricao}`}`
    return(
      <div style={{position: 'relative', width: '64%', height: 68, fontSize: 13, backgroundColor: '#e9ecef', color: '#000', cursor: 'default'}}>
        <div style={{height: '100%', display: 'flex', alignItems: 'center', paddingLeft: 20, paddingRight: 20}}>{this.state.selectedItem.titulo && titulo || ''}</div>
        <List>
          {this.renderOptions()}
        </List>
        
      </div>
    )
  }
}

Select.defaultProps = {
  onChange: () => null
}

class IncomeForm extends PureComponent {
  state = {
    selectedItem: {},
    descritionResult: this.props.analises[0].descricao || ''
  }
  static defaultProps = {
    products: [1],
    entity: 'PF',
    editMode: false,
    handleSelectedResult: () => null,
    handleDescritionResult: () => null
  }

  calcTotalIncome = () => {
    const { entity, changedValues } = this.props;
    if (changedValues !== undefined && typeof changedValues === 'object') {
      if (entity === 'PF') {
        const { renda_principal, outras_rendas, total_renda } = changedValues;
        let total = 0;
        if (renda_principal !== undefined) {
          const other_income = (outras_rendas === undefined) ? 0 : outras_rendas;
          total = other_income + renda_principal;
          total = formatToBRL(total);
          if (total !== total_renda) {
            this.props.change('total_renda', total);
          }
        }
      }
    }
  }

  getFields = () => {
    this.calcTotalIncome();
    const { products, entity, changedValues } = this.props;
    const fieldsName = getIncomeFields(products, entity, changedValues);
    return [{
      name: 'firstRow',
      fields: fieldsName.map((field) => (field in incomeFields) && incomeFields[field])
    }];
  }

  handleChangeResultado = ({target: {value}}) => {
    this.setState({selectedItem: value})
    this.props.handleSelectedResult(value)
  }

  handleChangeDescrition = ({target: {value}}) => {
    this.setState({descritionResult: value})
    this.props.handleDescritionResult(value)
  }

  render() {
    const { entity, changedValues, origem_renda_documento_recomendacao, origem_renda_documentos, label } = this.props;
    let cardTitle = (entity === 'PF')? 'Renda mensal e profissão' : 'Dados financeiros';
    if(this.props.cardTitle) cardTitle = this.props.cardTitle

    return (
      <Card title={cardTitle} iconHeader={RoundedMoney} iconLeft label={label} style={{position: 'relative', marginTop: -8, paddingTop: 4}}>
        {/* <FormContent rows={this.getFields()} /> */}
        <div className='wrapper'>
          <Select analises={this.props.analises} resultado_fcrenda={this.props.resultado_fcrenda} onChange={this.handleChangeResultado} onClick={this.handleToggleList} />
          <textarea maxlength={200} onChange={this.handleChangeDescrition} value={this.state.descritionResult} disabled={true} style={{height: 68, width: '35%', marginLeft: '1%', background: this.state.selectedItem.id == 3 || this.state.selectedItem.id == 9 ? false : '#e9ecef' }}></textarea>
        </div>
        {/* <IncomeModal /> */}
        <div className='box-floater'></div>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  changedValues: getValues(state, 'incomeForm'),
  initialValues: getInitialValues(state, ownProps.editMode, ownProps.entity),
  origem_renda_documento_recomendacao: state.requirements.origem_renda,
  origem_renda_documentos: state.requirements.origem_renda_documento,
  modulo_principal: state.requestForm.modulo_principal,
  modulo_secundario: state.requestForm.modulo_secundario,
  loadingAllFiles: state.requestForm.loadingAllFiles,
  loadingFileUploadPrincipal: state.requestForm.loadingFileUploadPrincipal,
  loadingFileUploadSecundario: state.requestForm.loadingFileUploadSecundario,
  resultado_fcrenda: state.requirements.resultado_fcrenda,
  analises: state.requestResume.currentApplicant.pretendente[0].renda ? state.requestResume.currentApplicant.pretendente[0].renda.analises : state.requestResume.currentApplicant.pretendente[0].faturamento.analises
});

const IncomeFormWithReduxForm = reduxForm({
  form: 'incomeForm',
  enableReinitialize: true
})(IncomeForm)

const IncomeFormConnected = connect(mapStateToProps, null)(IncomeFormWithReduxForm);

export default IncomeFormConnected;