import React from 'react'
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: 1.5rem;
`;
const StyledPath = styled.path`
  fill: ${props => props.theme.color_orange};
`;

const ClockSVG = (props) => (
    <StyledSVG {...props} viewBox="0 0 512 512">
      <StyledPath d="M437.019,74.981C388.667,26.629,324.38,0,256,0S123.333,26.63,74.981,74.981S0,187.62,0,256    s26.629,132.667,74.981,181.019C123.332,485.371,187.62,512,256,512s132.667-26.629,181.019-74.981    C485.371,388.667,512,324.38,512,256S485.371,123.332,437.019,74.981z M256,470.636C137.65,470.636,41.364,374.35,41.364,256    S137.65,41.364,256,41.364S470.636,137.65,470.636,256S374.35,470.636,256,470.636z" />
      <StyledPath d="M341.221,311.97l-64.538-64.537V114.809c0-11.422-9.259-20.682-20.682-20.682c-11.422,0-20.682,9.26-20.682,20.682V256    c0,5.486,2.179,10.746,6.058,14.625l70.594,70.595c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.586-2.019,14.626-6.058    C349.297,333.142,349.297,320.047,341.221,311.97z" data-original="#2C2F33" data-old_color="#6ac259" />
    </StyledSVG>
    
);


export default ClockSVG;