import * as types from './types';
import { getPricesInfo } from '../../../services/prices';
import { actionCreator, extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { toastr } from 'react-redux-toastr';
import { replace } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../../../pages/auth/login/actions/types.js';

export const getPrices = () => {
  return async (dispatch, getState) => {
    const { login: { user: { token: { access_token } } } } = getState();
    try {
      const payload = await getPricesInfo(access_token);
      if(payload.status !== 200){
        if(payload.status === 401){
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        }else{
          toastr.warning('Error', extractErrorMessages(payload.data.message));
        }
      }else{
        dispatch(actionCreator(types.GET_PRICES_INFOS_SUCCESS, payload.data.data));
      }
    } catch(error) {
      dispatch(actionCreator(types.GET_PRICES_INFOS_FAILED, ''));
      toastr.error('Preços', 'Algo inesperado aconteceu, entre em contato com a FC ANALISE!');
    }
  }
}