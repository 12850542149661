export const onGetCustomerProductsRequest = (state) => (
  {
    ...state,
    products: {
      isLoading: true,
      error: undefined,
      list: []
    }
  }
);

export const onGetCustomerProductsFailed = (state) => (
  {
    ...state,
    products: {
      isLoading: false,
      error: true,
      list: []
    }
  }
);

export const onGetCustomerProductsSuccess = (state, payload) => (
  {
    ...state,
    products: {
      isLoading: false,
      error: false,
      list: payload
    }
  }
);
