import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { fetchCEP } from '../../pages/validation/actions';

import handler from '@bit/wavfichacerta.fichacerta.inputs-functions.input-handler';
import { text, select, composed, maskedText, textDisabled, checkboxGroup, cep } from '@bit/wavfichacerta.fichacerta.inputs.inputs';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';

class RenderFields extends PureComponent {

  renderField = (row) => {
    if (Array.isArray(row.fields) && row.fields.length > 0) {
      const { requirements } = this.props;
      return row.fields.map((field) => {
        switch (field.type) {
          case "select":
            if (requirements[field.name] !== undefined) {
              return handler({ select })({ ...field, options: requirements[field.name] });
            }
            return handler({ select })({ ...field });
          case "checkboxGroup":
            return handler({ checkboxGroup })({ ...field, options: requirements[field.name] });
          case "cep":
            const { fetchCEP } = this.props;
            return handler({ cep })({ ...field, fetchCEP });
          default:
            return handler({ text, composed, maskedText, textDisabled })(field);
        }
      });
    }
    return null;
  }

  renderLine = () => {
    const { fields } = this.props;
    if (Array.isArray(fields) && fields.length > 0) {
      return fields.map((row, index) => (
        <StyledRow key={row.name} align="flex-start" justify="flex-start" customstyle={`margin-top: ${index > 0? ".5rem": "0"};`}>
          {this.renderField(row)}
        </StyledRow>
      ));
    }
    return null;
  }

  render() {
    return (
      <Fragment>
        {this.renderLine()}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ fetchCEP }, dispatch);

const mapStateToProps = (state) => ({
  requirements: state.requirements
});

export default connect(mapStateToProps, mapDispatchToProps)(RenderFields);
