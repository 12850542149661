import React, { Component, Fragment } from 'react';

import {
  FlexContainer,
  ContentBox,
  TitleContainer,
  CardContainer,
  SyntethicColumn
} from '../../../../../components/Operational/SyntheticReport/syntheticReport.styles';
import ContainerLoading from '../../../../../components/Operational/ContainerLoading';
import Person from '../../../../../components/shared/icons/Person';
import ReportAlert from '../../../../../components/shared/treatedIcons/ReportAlert';
import {ParecerText, Name, RiskContainer} from "./styled"

import ReactTooltip from 'react-tooltip'
import TourTooltip from './../../../../../../src/components/shared/TourTooltip';

class LocationReport extends Component {
  
  state = {
    showArrow: false,
    arrow: undefined
  }

  getColor = (status) => {
    switch (status) {
      case 'RISCO BAIXO':
        return "#73cf2b";
      case 'RISCO MUITO BAIXO':
        return "#00af24";
      case 'RISCO MÉDIO':
        return "#feda32";
      case 'RISCO ACIMA DA MÉDIA':
        return "#ff7200";
      case 'RISCO MUITO ALTO':
      case 'ALERTA':
      case 'ALERTA FC':
        return "#fe4d36";
      default:
        return "#7f7f7f"
    }
  }
  
  showArrow = element => {
    if (!this.state.showArrow) {
      this.setState({ showArrow: true, arrow: element.offsetHeight });
    }
  }

  getHeightTable = () => {
    if (this.state.arrow !== null && this.state.arrow !== undefined) {
      return this.state.arrow;
    }
    return 100;
  }

  replaceTextParecer = text => {
    if(!text) return null
    return text.replaceAll(':', ':<br />').replaceAll('.', '.<br />')
  }

  getFeebackFianca = (textFianca) => {
    if(!textFianca) return null

    const exist = textFianca.indexOf('Clique aqui')
    const isPreAprovado = textFianca.indexOf('Perfil pré-aprovado')
    if(exist >= 0) {
      const button = <a style={{textDecoration: 'underline', color: isPreAprovado >=0 && '#152b4e' || '#7f7f7f'}} href="javascript:void(0)" onClick={this.handleSendMailFianca}>Clique aqui</a>
      const textStart = textFianca.substr(0, exist)
      const textEnd = textFianca.substr(exist + 11)
      return (
        <React.Fragment>
          <ParecerText>
            <br />
            <b style={{color: isPreAprovado >=0 && '#152b4e'}}>FC FIANÇA:</b> <b dangerouslySetInnerHTML={{__html: textStart}} />{button}<span dangerouslySetInnerHTML={{__html: textEnd}} />
          </ParecerText>
          <br />
        </React.Fragment>
      )
    }

  }

  handleSendMailFianca = (e) => {
    e.preventDefault()
    this.props.toggleModalFianca()
  }

  render() {
    const { data, loading } = this.props;
    
    if (loading) {
      return (<ContainerLoading size="large" />);
    }
    const pareceres = ("parecer_locacao" in data)? data.parecer_locacao : {};
    const {parecer_inquilinos, parecer_fiadores} = pareceres
    const inquilinoColor = this.getColor(parecer_inquilinos.parecer)
    const fiadorColor = this.getColor(parecer_fiadores.parecer)

    return (
      <CardContainer>
        <FlexContainer style={{flexWrap: "wrap", position: 'relative'}} className="box-parecer-locacao-geral">
          <SyntethicColumn className='box-parecer-locacao box-parecer-locacao-inquilinos' width="49%" customstyle="display:flex;position: relative;">     
            {parecer_inquilinos.parecer &&
              <Fragment>
                {/* <ContentBox align="center" justify="center" width="35%">
                  {(parecer_inquilinos.parecer_key === 'ALERTA_ACAO_CRIMINAL' || parecer_inquilinos.parecer_key === 'ALERTA_FRAUDE_CRIMINAL' || parecer_inquilinos.parecer_key === 'ALERTA_FRAUDE' || parecer_inquilinos.parecer_key === 'ALERTA_FCDOC') &&
                    <ReportAlert color={inquilinoColor} customStyle='& > path { fill: #fe4d36 }' width='40px' height='40px' margin='0px'/> ||
                    <Person color={inquilinoColor} />
                  }
                  <ParecerText color={inquilinoColor} className='ignore-css-print bold-print locacao-parecer-text'>{parecer_inquilinos.parecer}</ParecerText>
                </ContentBox> */}
                <ContentBox align="center" width="100%">
                  <TitleContainer marginBottom="1.5rem">INQUILINOS</TitleContainer>
                  {(parecer_inquilinos.parecer_key === 'ALERTA_ACAO_CRIMINAL' || parecer_inquilinos.parecer_key === 'ALERTA_FRAUDE_CRIMINAL' || parecer_inquilinos.parecer_key === 'ALERTA_FRAUDE' || parecer_inquilinos.parecer_key === 'ALERTA_FCDOC') &&
                    <ReportAlert color={inquilinoColor} customStyle='& > path { fill: #fe4d36 }' width='28px' height='28px' margin='0px'/> ||
                    <Person width='28px' color={inquilinoColor} />
                  }
                  <ParecerText color={inquilinoColor} className='ignore-css-print bold-print locacao-parecer-text'>{parecer_inquilinos.parecer}</ParecerText>
                  <ParecerText className='locacao-parecer-text' style={{marginTop: 14, color: '#000'}} dangerouslySetInnerHTML={{__html: '' + parecer_inquilinos.aprovados + ''}} />
                  {this.getFeebackFianca(parecer_inquilinos.fc_fianca)}
                </ContentBox>
              </Fragment>
            }

            {/* {parecer_inquilinos.parecer && !parecer_fiadores.parecer &&
              <Fragment>
                <TourTooltip
                  target="tooltip-parecer-locacao-geral"
                  style={{right: -10}}
                />
                <ReactTooltip id="tooltip-parecer-locacao-geral" place="left" type="dark" effect="float">
                  <span>Entregamos recomendação exclusiva para locação, apontando o <br />risco do grupo de inquilinos e/ou fiadores, e o quanto é <br />possível ser flexível em seu processo de aprovação.</span>
                </ReactTooltip>
              </Fragment>
            } */}
          </SyntethicColumn>

          <SyntethicColumn className='box-parecer-locacao box-parecer-locacao-fiadores' width="49%" customstyle="display:flex;position: relative;">
            {parecer_fiadores.parecer &&
              <Fragment>
                {/* <ContentBox align="center" justify="center" width="35%" >
                  {(parecer_fiadores.parecer_key === 'ALERTA_ACAO_CRIMINAL' || parecer_fiadores.parecer_key === 'ALERTA_FRAUDE_CRIMINAL' || parecer_fiadores.parecer_key === 'ALERTA_FRAUDE' || parecer_fiadores.parecer_key === 'ALERTA_FCDOC') &&
                    <ReportAlert color={fiadorColor} customStyle='& > path { fill: #fe4d36 }' width='40px' height='40px' margin='0px'/> ||
                    <Person color={fiadorColor} />
                  }
                  <ParecerText color={fiadorColor} className='ignore-css-print bold-print locacao-parecer-text'>{parecer_fiadores.parecer}</ParecerText>
                </ContentBox> */}
                <ContentBox align="center" width="100%" >
                  <TitleContainer marginBottom="1.5rem">FIADORES</TitleContainer>
                  {(parecer_fiadores.parecer_key === 'ALERTA_ACAO_CRIMINAL' || parecer_fiadores.parecer_key === 'ALERTA_FRAUDE_CRIMINAL' || parecer_fiadores.parecer_key === 'ALERTA_FRAUDE' || parecer_fiadores.parecer_key === 'ALERTA_FCDOC') &&
                    <ReportAlert color={fiadorColor} customStyle='& > path { fill: #fe4d36 }' width='28px' height='28px' margin='0px'/> ||
                    <Person width='28px' color={fiadorColor} />
                  }
                  <ParecerText color={fiadorColor} className='ignore-css-print bold-print locacao-parecer-text'>{parecer_fiadores.parecer}</ParecerText>
                  <ParecerText className='locacao-parecer-text' style={{marginTop: 14, color: '#000'}} dangerouslySetInnerHTML={{__html: '' + parecer_fiadores.aprovados + ''}} />
                  {this.getFeebackFianca(parecer_fiadores.fc_fianca)}
                  {parecer_fiadores.observacao_complementar && <ParecerText style={{fontSize: "12px", fontWeight: 400, color: '#000'}} dangerouslySetInnerHTML={{__html: '<b>' + this.replaceTextParecer(parecer_fiadores.observacao_complementar) + '</b>'}} /> || null}
                </ContentBox>
              </Fragment>
            }

            {/* {parecer_fiadores.parecer &&
              <Fragment>
                <TourTooltip
                  target="tooltip-parecer-locacao-geral"
                  style={{right: -10}}
                />
                <ReactTooltip id="tooltip-parecer-locacao-geral" place="left" type="dark" effect="float">
                  <span>Entregamos recomendação exclusiva para locação, apontando o <br />risco do grupo de inquilinos e/ou fiadores, e o quanto é <br />possível ser flexível em seu processo de aprovação.</span>
                </ReactTooltip>
              </Fragment>
            } */}
          </SyntethicColumn>

          <SyntethicColumn className='box-parecer-locacao' width="49%" style={{flexDirection: "column"}}>
            {!parecer_inquilinos.nao_aprovados_1 && !parecer_inquilinos.nao_aprovados_2 && parecer_inquilinos.nao_aprovados &&
              <RiskContainer dangerouslySetInnerHTML={{__html: parecer_inquilinos.nao_aprovados}} />
            }
            {parecer_inquilinos.nao_aprovados_1 &&
              <RiskContainer dangerouslySetInnerHTML={{__html: parecer_inquilinos.nao_aprovados_1}} />
            }
            {parecer_inquilinos.nao_aprovados_2 &&
              <RiskContainer dangerouslySetInnerHTML={{__html: parecer_inquilinos.nao_aprovados_2}} />
            }
          </SyntethicColumn>

          <SyntethicColumn className='box-parecer-locacao' width="49%" style={{flexDirection: "column"}}>
            {!parecer_fiadores.nao_aprovados_1 && !parecer_fiadores.nao_aprovados_2 && parecer_fiadores.nao_aprovados &&
              <RiskContainer dangerouslySetInnerHTML={{__html: parecer_fiadores.nao_aprovados}} />
            }
            {parecer_fiadores.nao_aprovados_1 &&
              <RiskContainer dangerouslySetInnerHTML={{__html: parecer_fiadores.nao_aprovados_1}} />
            }
            {parecer_fiadores.nao_aprovados_2 &&
              <RiskContainer dangerouslySetInnerHTML={{__html: parecer_fiadores.nao_aprovados_2}} />
            }
          </SyntethicColumn>

          {pareceres.risco &&
            <SyntethicColumn width="100%" style={{marginTop: "5px"}}>
              <ContentBox width="100%">
                <Name style={{color: '#152b4e'}} className='bold-print'>{pareceres.risco}</Name>
              </ContentBox>
            </SyntethicColumn> || null
          }

        </FlexContainer>
      </CardContainer>
    );
  }
}


export default LocationReport;