import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales';

export const onlyNumber = (text) => {
  if (text === undefined) {
    return undefined;
  }
  return text.replace(/\D+/g, '');
}
export const formatToBRL = (amount) => {
  numeral.locale('pt-br');

  return numeral(amount).format('0.0,');
};


export const formatToBRLwithoutCents = (amount) => {
  //numeral.locale('pt-br');

  //return numeral(amount).format('0.0');
  return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export const formatToUS = (amount) => {
  return numeral(amount).format('0,0');
}

export const formatToUSOnlyDecimal = (amount) => amount.replace(/,/g, 'brl').replace(/\./, '').replace(/brl/g, '.');
export const formatCPF = (cpf) => {
  if(!cpf) { return null };
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};
export const formatCNPJ = (cnpj) => {
  if(!cnpj) { return null };
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};
export const formatDateToUS = (dateBR) => {
  if (dateBR !== undefined && dateBR !== null && dateBR.trim() !== "") {
    const date = dateBR.split('/');
    return (date[2] + "-" + date[1] + "-" + date[0]).replace(/_/g, '');
  }
  return "";
}
export const formatDateToBR = (value, hours) => {
  if (moment(value).isValid() && value !== undefined && value !== null ) {
    if(hours){
      return moment(value).format('DD/MM/YYYY HH:mm')
    }
    return moment(value).format('DD/MM/YYYY');

  }
  return value;
}

export const formatAddress = (addressObj) => {
  const { logradouro, numero, complemento, uf, cep, bairro, cidade } = addressObj;
  let address = "";

  if (logradouro !== undefined && logradouro !== null && logradouro !== "")
    address += logradouro;
  
  if (numero !== undefined && numero !== null && numero !== "")
    address += `, ${numero}`;
  
  if (complemento !== undefined && complemento !== null && complemento !== "")
    address += ` ${complemento}`;

  if (bairro !== undefined && bairro !== null && bairro !== "")
    address += ` ${bairro}`;

  if (cidade !== undefined && cidade !== null && cidade !== "")
    address += ` - ${cidade}`;

  if (uf !== undefined && uf !== null && uf !== "")
    address += ` - ${uf}`;

  if (cep !== undefined && cep !== null && cep !== "")
    address += ` - CEP: ${cep}`;

  return address || '-';
}

export const formatPhone = (phone) => {
  if (phone !== undefined && phone !== null) {
    if (typeof phone === "string") {
      if (phone.length === 10)
        return phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");

      if (phone.length === 11)
        return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }
  }

  return phone;
}
