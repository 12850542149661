import { instance as axios } from './axiosInstance';
import { statusHandler } from '../utils/loginHelper';

const GET_PRICES_END_POINT = '/api/precos/produto?relations=true';
const GET_ATTENDANCES_END_POINT = '/api/precos/servico';
const GET_DELIVERY_END_POINT = '/api/precos/localidade';
const putProduct = (produto) => `/api/precos/produto/${produto}`;
const putAttendance = (atendimento) => `/api/precos/servico/${atendimento}`;
const putDelivery = (entrega) => `/api/precos/localidade/${entrega}`;
const getPricesInfoEndPoint = "/api/administrador?information=true";

const getHeader = (token) => (
  {
    'Authorization': `Bearer ${token}`
  }
);

export const getPricesInfo = (token) => {
    const headers = getHeader(token);

    return axios.get(getPricesInfoEndPoint, {headers})
}

export const getProducts = (token) => {
    const headers = getHeader(token);

    return axios.get(GET_PRICES_END_POINT, { validateStatus: statusHandler, headers });
}

export const getAttendances = (token) => {
    const headers = getHeader(token);

    return axios.get(GET_ATTENDANCES_END_POINT, { validateStatus: statusHandler, headers });
}

export const getDeliveries = (token) => {
    const headers = getHeader(token);

    return axios.get(GET_DELIVERY_END_POINT, { validateStatus: statusHandler, headers });
}

export const editProduct = (token, product) => {
    const headers = getHeader(token);
    const data = {
        _method:"PUT",
        titulo: product.nome,
        valor: product.valor,
        desconto_padrao: product.descontoPadrao,
        desconto_especial: product.descontoEspecial
    }

    return axios.put(putProduct(product.id), data, { validateStatus: statusHandler, headers });
}

export const editAttendance = (token, attendance) => {
    const headers = getHeader(token);

    const data = {
        _method: "PUT",
        titulo: attendance.titulo,
        valor: attendance.valor
    }

    return axios.put(putAttendance(attendance.id), data, { validateStatus: statusHandler, headers});
}

export const editDelivery = (token, delivery) => {
    const headers = getHeader(token);
    const data = {
        _method: "PUT",
        titulo: delivery.titulo,
        descricao: delivery.descricao,
        valor: delivery.valor
    }

    return axios.put(putDelivery(delivery.id), data, { validateStatus: statusHandler, headers });
}