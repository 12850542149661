import React, { PureComponent } from 'react';
import styled from 'styled-components';

const SVGContainer = styled.svg`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  padding: ${props => props.padding || '0'};
  fill: ${props => props.color || props.theme.color_gray};

  ${props => {
    if (props.isCircle) {
      return `
        animation-play-state: running; 
        animation-delay: 0s; 
        background: none;
      `;
    }
  }}
`;

export default class LoadingCircle extends PureComponent {
  render() {
    return (
      <SVGContainer {...this.props} viewBox="0 0 100 100">
        <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 44 0 0 1 10 50" transform="rotate(342.627 50 52)" style={{ animationPlayState: 'running', animationDelay: '0s' }}>
          <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 52;360 50 52" keyTimes="0;1" dur="0.7s" begin="0s" repeatCount="indefinite" style={{ animationPlayState: 'running', animationDelay: '0s'}}/>
        </path>
      </SVGContainer>
    );
  }
}
