import { simpleNonRequiredDate } from '@bit/wavfichacerta.fichacerta.fields-templates.date';
import { simpleRequiredSelect, simpleNonRequiredSelect } from '@bit/wavfichacerta.fichacerta.fields-templates.select';
import { simpleNonRequiredText, simpleRequiredText, simpleDisabledText } from '@bit/wavfichacerta.fichacerta.fields-templates.simple-text';
import { defaultCPF, defaultCNPJ } from '@bit/wavfichacerta.fichacerta.fields-templates.identification';

export default {
  tipo_pretendente: simpleRequiredSelect({ size: 'field-fc-small field-fc-sm-8', name: 'tipo_pretendente', label: 'Pretendente a' }),
  residir: simpleRequiredSelect({ size: 'field-fc-small field-fc-sm-4', name: 'residir', label: 'Irá residir?' }),
  participante: simpleRequiredSelect({ size: 'field-fc-small', name: 'participante', label: 'Part. do negócio?' }),
  nome: simpleRequiredText({ size: 'field-fc-medium field-fc-sm-8', name: 'nome', label: 'Nome' }),
  nome_disabled: simpleDisabledText({ size: 'field-fc-medium field-fc-sm-8', name: 'nome', label: 'Nome' }),
  cpf: defaultCPF('field-fc-small field-fc-sm-4'),
  cpf_disabled: simpleDisabledText({ size: 'field-fc-medium field-fc-sm-4', name: 'cpf', label: 'CPF' }),
  data_nascimento: simpleNonRequiredDate({ size: 'field-fc-small', name: 'data_nascimento', label: 'Data de Nascimento' }),
  nome_mae: simpleNonRequiredText({ size: 'field-fc-medium', name: 'nome_mae', label: 'Nome da mãe' }),
  uso_imovel: simpleRequiredSelect({ size: 'field-fc-small', name: 'uso_imovel', label: 'Uso do imóvel' }),
  razao_social: simpleRequiredText({ size: 'field-fc-medium', name: 'razao_social', label: 'Nome da Empresa' }),
  razao_social_disabled: simpleDisabledText({ size: 'field-fc-medium', name: 'razao_social', label: 'Nome da Empresa' }),
  nome_fantasia: simpleNonRequiredText({ size: 'field-fc-medium', name: 'nome_fantasia', label: 'Nome fantasia' }),
  cnpj: defaultCNPJ('field-fc-medium'),
  cnpj_disabled: simpleDisabledText({ size: 'field-fc-medium', name: 'cnpj', label: 'CNPJ' }),
  tempo_fundacao: simpleNonRequiredSelect({ size: 'field-fc-medium', name: 'tempo_fundacao', label: 'Tempo de fundação' })
};