import { formatPhone, formatCPF } from '@bit/wavfichacerta.fichacerta.utils.formatters';

export const getInitialValues = (state) => {
  const { employerForm: { userData } } = state;
  if (typeof userData.data === "object" && Object.keys(userData.data).length > 0) {
    const { data } = userData;
    let accessType = [];
    if (data.funcionario.operacional) {
      accessType.push("operacional");
    }

    if (data.funcionario.financeiro) {
      accessType.push("financeiro");
    }

    return {
      tipo_usuario: data.funcionario.master? "MASTER" : "ADICIONAL",
      nome: data.pessoa.nome,
      cpf: formatCPF(data.pessoa.cpf),
      identidade: data.pessoa.identidade,
      orgao_expedidor: data.pessoa.orgao_expedidor,
      funcao: data.funcionario.funcao,
      email: data.usuario.email,
      telefone: formatPhone(data.telefones.principal.numero),
      credit: `R$ ${data.funcionario.credito},00`,
      total: `R$ ${data.funcionario.credito},00`
    }
  }
  return {};
}
