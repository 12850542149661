import * as types from './types';
import { LOGOUT_SUCCESS } from '../../../../pages/auth/login/actions/types.js';
import { toastr } from 'react-redux-toastr';
import { actionCreator, generalActionTypes,extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { getCustomer, updateCustomerData } from '../../../../services/customer/customer';
import { replace } from 'connected-react-router';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const resetData = () => actionCreator(types.RESET_DATA);

export const getCustomerData = () => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.CUSTOMER_DATA_REQUEST));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await getCustomer(token.access_token);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(types.CUSTOMER_DATA_FAILED));
        }
      } else {
        dispatch(actionCreator(types.CUSTOMER_DATA_SUCCESS, payload.data.data));
      }
    } catch (error) {
      dispatch(actionCreator(NON_HANDLED_ERROR));
    }
  }
}

export const saveForm = (data) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.CUSTOMER_SAVE_REQUEST));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await updateCustomerData(token.access_token, data);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          // Mensagem falha
          toastr.warning("Dados do cliente", extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.CUSTOMER_SAVE_FAILED));
        }
      } else {
        // Mensagem sucesso
        toastr.success("Dados do cliente", payload.data.message);
        dispatch(actionCreator(types.CUSTOMER_SAVE_SUCCESS));
        dispatch(getCustomerData());
      }
    } catch (error) {
      dispatch(actionCreator(NON_HANDLED_ERROR, "No momento não foi possível salvar os dados."));
    }
  }
}
