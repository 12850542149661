import styled from 'styled-components';
import { PrimaryButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';

export const Container = styled.div`
  width: 65%;
  margin: auto;

  @media (max-width: 800px) {
    margin-top: 15px;

    button {
      width: 77px;
      height: 33px;
      font-size: .88rem;
      background-color: #152b4e;
      margin-top: 0;
      margin: 0 9px;
    }
  }
`;

export const Title = styled.h5`
  color: ${props => props.theme.color_purple};
  font-weight: 400;
`;

export const Description = styled.p`
  margin-bottom: 1rem;
  font-size: 12px;
  text-align: center;
  color: ${props => props.theme.color_gray};
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const CancelButton = styled(PrimaryButton)`
  min-width: 5rem;
  padding: 0.375rem 1.65rem;
`;

export const ConfirmButton = styled(PrimaryButton)`
  min-width: 5rem;
  padding: 0.375rem 1.65rem;
  background-color: ${props => props.theme.color_red};
  &:hover { background-color: ${props => props.theme.color_red}; }
`;