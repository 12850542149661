
export const onOpenModal = (state) => ({
  ...state,
  isOpen: true
});

export const onCloseModal = (state) => ({
  ...state,
  isOpen: false
});
