import axios from 'axios';

let baseURL = process.env.REACT_APP_BASE_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  baseURL = process.env.REACT_APP_BASE_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  baseURL = process.env.REACT_APP_BASE_URL_STAG;
} else if (process.env.REACT_APP_ENV === 'fcrenda') {
  baseURL = process.env.REACT_APP_BASE_URL_FCRENDA;
}

console.log("baseURL: ", baseURL);
console.log("REACT_APP_ENV: ", process.env.REACT_APP_ENV);
console.log("NODE_ENV: ", process.env.NODE_ENV);

export const instance = axios.create({
  baseURL,
  timeout: 300000,
  validateStatus: (status) => {
    return status >= 200 && status <= 422;
  }
});

