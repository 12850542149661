import React from 'react';
import { FullPortViewContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';

const App = ({ children }) => (
  <FullPortViewContainer>
    {children}
  </FullPortViewContainer>
);

export default App;
