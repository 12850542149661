import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Requests from '../../pages/operational/Requests';
import EntityChoice from '../../pages/operational/EntityChoice';
import RequestForm from '../../pages/operational/request/form/RequestForm';
import RequestResume from '../../pages/operational/request/resume/RequestResume';
import AddApplicant from '../../pages/operational/request/resume/AddApplicant';
import TenancyForm from '../../pages/operational/request/resume/TenancyEdit/TenancyEditForm';
import EditableFrom from '../../pages/operational/request/resume/EditableForm';
import Report from '../../pages/operational/request/report/Report';
import FormFcRenda from '../../pages/operational/request/resume/AddFcRenda';
import FormFcDocPJ from '../../pages/operational/request/resume/AddFcDocPj';
import FormFcAnalysisPlus from '../../pages/operational/request/resume/FormFcAnalysisPlus';

export default () => (
  <Switch>
    <Route exact path="/intranet/operacional/solicitacao" component={Requests} />
    <Route exact path="/intranet/operacional/solicitacao/adicionar/selecionar-produto" component={EntityChoice} />
    <Route exact path="/intranet/operacional/solicitacao/formulario/:id?" component={RequestForm} />
    <Route exact path="/intranet/operacional/solicitacao/resumo" component={RequestResume} />
    <Route exact path="/intranet/operacional/solicitacao/resumo/adicionar-pretendente" component={AddApplicant} />
    <Route exact path="/intranet/operacional/solicitacao/resumo/adicionar/fc-renda" component={FormFcRenda} />
    <Route exact path="/intranet/operacional/solicitacao/resumo/adicionar/fc-doc-pj" component={FormFcDocPJ} />
    <Route exact path="/intranet/operacional/solicitacao/resumo/adicionar/fc-analise-mais" component={FormFcAnalysisPlus} />
    <Route exact path="/intranet/operacional/solicitacao/resumo/locacao" component={TenancyForm} />
    <Route exact path="/intranet/operacional/solicitacao/resumo/pretendente" component={EditableFrom} />
    <Route exact path="/intranet/operacional/solicitacao/laudo" component={Report} />
  </Switch>
);
