import React, { PureComponent } from 'react';
import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import {
  Container,
} from './filterModal.styled'
import { CardTitle } from './Card.styled'

export default class FilterModal extends PureComponent {

  static defaultProps = {
    message: '',
    callBack: () => {}
  }

  renderHeader = () => (
    <CardTitle>
        Filtrar
    </CardTitle>
  )

  render() {
    return (
      <ModalContainer
        onClose={this.props.toggleModal}
        isOpen={this.props.isOpen}
        header={this.renderHeader()}
      >
        <Container>
          {this.props.children}
        </Container>
      </ModalContainer>
    );
  }
}
