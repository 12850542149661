import * as types from '../actions/types';
import {
  onSendRequest,
  onSendRequestFailed,
  onSendRequestSuccess,
  onSendFileUploadPrincipal,
  onSendFileUploadSecundario,
  loadingSendFileUploadPrincipal,
  loadingSendFileUploadSecundario,
  onExcludedFileUploadPrincipal,
  onExcludedFileUploadSecundario
} from './reducers';

const initialState = {
  requesting: false,
  error: undefined,
  requestId: undefined,
  modulo_principal: [],
  modulo_secundario: [],
  loadingAllFiles: false,
  loadingFileUploadPrincipal: false,
  loadingFileUploadSecundario: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.REQUEST_FORM_SEND:
      return onSendRequest(state);
    case types.REQUEST_FORM_SUCCESS:
      return onSendRequestSuccess(state, payload);
    case types.REQUEST_FORM_FAILED:
      return onSendRequestFailed(state);
    case types.LOADING_POST_FILE_UPLOAD:
      if(payload.modulo == 'principal') return loadingSendFileUploadPrincipal(state, payload);
      return loadingSendFileUploadSecundario(state, payload);
    case types.POST_FILE_UPLOAD:
      if(payload.modulo == 'principal') return onSendFileUploadPrincipal(state, payload.files);
      return onSendFileUploadSecundario(state, payload.files);
    case types.DELETE_FILE_UPLOAD:
      if(payload.modulo == 'principal') return onExcludedFileUploadPrincipal(state, payload.newFiles);
      return onExcludedFileUploadSecundario(state, payload.newFiles);
    case types.REQUEST_FORM_RESET:
      return initialState;
    default:
      return state;
  }
}
