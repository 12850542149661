import {
  OPEN_EMPLOYEE_MODAL,
  CLOSE_EMPLOYEE_MODAL,
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAILED,
  GET_EMPLOYEES,
  SELECT_EMPLOYEE_CREDIT,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  REGISTER_EMPLOYEE_FAILED,
  GET_EMPLOYEES_FAILED,
  OPEN_TYPE_EMPLOYEE_MODAL,
  CLOSE_TYPE_EMPLOYEE_MODAL,
  OPEN_STATUS_EMPLOYEE_MODAL,
  CLOSE_STATUS_EMPLOYEE_MODAL,
  OPEN_EMPLOYEE_CHANGE_MODAL,
  CLOSE_EMPLOYEE_CHANGE_MODAL,
  ON_UPDATE_EMPLOYEE_CREDIT,
  CHANGE_PAGE,
  CHANGE_RESULTS_PER_PAGE,
  RESET_PAGINATION
} from '../../types';

import {
  onOpenEmployeeModal,
  onCloseEmployeeModal,
  onRequestEmployeeFailed,
  onRequestEmployeeSuccess,
  onRequestEmployee,
  onGetEmployee,
  onPickEmployeeCredit,
  onEmployeeRegisterFailed,
  onGetEmployeesRequest,
  onGetEmployeesSuccess,
  onGetEmployeesFailed,
  onCloseTypeEmployeeModal,
  onOpenTypeEmployeeModal,
  onCloseStatusEmployeeModal,
  onOpenStatusEmployeeModal,
  onEmployeeChangeModalOpen,
  onEmployeeChangeModalClose,
  updateEmployeeCredit,
  onChangePage,
  onChangeResultsPerPage,
  onResetPagination
} from './reducers';

const initialState = {
  editableRow: undefined,
  list: [],
  requesting: false,
  error: undefined,
  isEmailChangingModalOpen: false,
  emailToChange: undefined,
  employeeModal: {
    selectedEmployee: {},
    requesting: false,
    error: undefined,
    isVisible: false,
    mode: undefined
  },
  typeModal: {
    isVisible: false,
    selectedEmployee: {}
  },
  statusModal: {
    isVisible: false,
    selectedEmployee: {}
  },
  pagination: {
    totalCount: undefined,
		numberOfPages: undefined,
		lastPage: undefined,
		currentPage: 1,
		resultsPerPage: localStorage.getItem("resultsPerPageContributors") || '10',
		sortBy: 'nome',
		nextPage: '',
		previousPage: '',
		filter: '',
		status: 'ATIVO'
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_TYPE_EMPLOYEE_MODAL:
      return onOpenTypeEmployeeModal(state, payload);
    case CLOSE_TYPE_EMPLOYEE_MODAL:
      return onCloseTypeEmployeeModal(state);
    case OPEN_STATUS_EMPLOYEE_MODAL:
      return onOpenStatusEmployeeModal(state, payload);
    case CLOSE_STATUS_EMPLOYEE_MODAL:
      return onCloseStatusEmployeeModal(state);
    case OPEN_EMPLOYEE_MODAL:
      return onOpenEmployeeModal(state, payload);
    case CLOSE_EMPLOYEE_MODAL:
      return onCloseEmployeeModal(state);
    case GET_EMPLOYEE_REQUEST:
      return onRequestEmployee(state);
    case GET_EMPLOYEE_SUCCESS:
      return onRequestEmployeeSuccess(state, payload);
    case GET_EMPLOYEE_FAILED:
      return onRequestEmployeeFailed(state, payload);
    case GET_EMPLOYEES:
      return onGetEmployee(state, payload);
    case SELECT_EMPLOYEE_CREDIT:
      return onPickEmployeeCredit(state, payload);
    case GET_EMPLOYEES_REQUEST:
      return onGetEmployeesRequest(state);
    case GET_EMPLOYEES_SUCCESS:
      return onGetEmployeesSuccess(state, payload);
    case GET_EMPLOYEES_FAILED:
      return onGetEmployeesFailed(state, payload);
    case REGISTER_EMPLOYEE_FAILED:
      return onEmployeeRegisterFailed(state, payload);
    case CLOSE_EMPLOYEE_CHANGE_MODAL:
      return onEmployeeChangeModalClose(state);
    case OPEN_EMPLOYEE_CHANGE_MODAL:
      return onEmployeeChangeModalOpen(state, payload);
    case ON_UPDATE_EMPLOYEE_CREDIT:
      return updateEmployeeCredit(state, payload)
    case CHANGE_PAGE:
			return onChangePage(state, payload);
    case CHANGE_RESULTS_PER_PAGE:
        return onChangeResultsPerPage(state, payload);
    case RESET_PAGINATION:
			return onResetPagination(state);
    default:
      return state;
  }
};