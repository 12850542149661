import { toastr } from 'react-redux-toastr';
import * as types from './types';
import { actionCreator, generalActionTypes,extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { getDataRequirements, createRequests, postFileUpload, postFileUploadFcCompleta, deleteFileUpload, addProductFcRenda } from '../../../../../services/operational';
import { GET_REQUIREMENTS } from '../../../../form-requirements/actions/types';
import { replace, goBack, push } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../../../../auth/login/actions/types.js';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const resetForm = () => actionCreator(types.REQUEST_FORM_RESET);

export const getRequirements = (entity = 'PF') => {
  return async (dispatch, getState) => {
    try {
      const { login: { user: { token } } } = getState();
      const payload = await getDataRequirements(token.access_token);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Formulário', extractErrorMessages(payload.data.message));
        }
      } else {
        let requirements = {};
        if (entity === 'PF') {
          requirements = {
            tipo_pretendente: payload.data.data.tipo_pretendente_pf,
            tipo_imovel: payload.data.data.tipo_imovel_pf,
            // compor_renda: payload.data.data.compor,
            ...payload.data.data
          };
        } else {
          requirements = {
            tipo_pretendente: payload.data.data.tipo_pretendente_pj,
            tipo_imovel: payload.data.data.tipo_imovel_pf,
            ...payload.data.data
          };
        }
        requirements = {
          ...requirements,
          uf: payload.data.data.estados,
          origem_renda_secundaria: payload.data.data.origem_renda
        };
        dispatch(actionCreator(GET_REQUIREMENTS, requirements));
      }
    } catch (error) {
      toastr.error('Formulário', 'Não foi possível trazer os dados para o formulário.');
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível trazer os dados para o formulário.'));
    }
  }
}

export const createRequest = (data) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.REQUEST_FORM_SEND));
    try {
      const { login: { user: { token } } } = getState();
      console.log('data25', data)
      const payload = await createRequests(token.access_token, data);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Solicitação', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.REQUEST_FORM_FAILED));
        }
      } else {
        toastr.success('Solicitação', extractErrorMessages(payload.data.message));
        dispatch(actionCreator(types.REQUEST_FORM_SUCCESS, payload.data.id));
      }
    } catch (error) {
      toastr.error('Solicitação', 'Não foi possível processar a sua solicitação no momento.');
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível processar a sua solicitação no momento.'));
    }
  }
}

export const sendFileUpload = (data, modulo, id_solicitacao, id_pretendente, id_produto) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: true}));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await postFileUpload(token.access_token, data, id_solicitacao, id_pretendente, id_produto);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Solicitação', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.REQUEST_FORM_FAILED));
        }
      } else {
        // toastr.success('Solicitação', extractErrorMessages(payload.data.message));
        toastr.success('Solicitação', 'Anexo salvo');
        console.log('payload1', payload)
        dispatch(actionCreator(types.POST_FILE_UPLOAD, {modulo, files: payload.data}));
      }
    } catch (error) {
      console.log('ERRO DE UPLOAD', error);
      toastr.error('Solicitação', 'Não foi possível processar a sua solicitação no momento.');
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível processar a sua solicitação no momento.'));
      dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: false}));
    }
  }
}

export const sendFileUploadFcCompleta = (data, modulo, id_produto) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: true}));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await postFileUploadFcCompleta(token.access_token, data, id_produto);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Solicitação', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.REQUEST_FORM_FAILED));
        }
      } else {
        // toastr.success('Solicitação', extractErrorMessages(payload.data.message));
        toastr.success('Solicitação', 'Anexo salvo');
        console.log('payload1', payload)
        dispatch(actionCreator(types.POST_FILE_UPLOAD, {modulo, files: payload.data}));
      }
    } catch (error) {
      console.log('ERRO DE UPLOAD', error);
      toastr.error('Solicitação', 'Não foi possível processar a sua solicitação no momento.');
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível processar a sua solicitação no momento.'));
      dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: false}));
    }
  }
}

export const getAllFileUpload = (data, modulo, id_solicitacao, id_pretendente, id_produto) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: true}));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await postFileUpload(token.access_token, data, id_solicitacao, id_pretendente, id_produto);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Solicitação', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.REQUEST_FORM_FAILED));
        }
      } else {
        // toastr.success('Solicitação', extractErrorMessages(payload.data.message));
        toastr.success('Solicitação', 'Anexo salvo');
        console.log('payload1', payload)
        dispatch(actionCreator(types.POST_FILE_UPLOAD, payload.data));
      }
    } catch (error) {
      toastr.error('Solicitação', 'Não foi possível processar a sua solicitação no momento.');
      dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: false}));
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível processar a sua solicitação no momento.'));
    }
  }
}

export const removeFileUpload = (data, modulo, id_solicitacao, id_pretendente, id_arquivo) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: true}));
    try {
      const { login: { user: { token } }, requestForm: {modulo_principal, modulo_secundario} } = getState();
      const payload = await deleteFileUpload(token.access_token, data, id_solicitacao, id_pretendente, id_arquivo);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Solicitação', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.REQUEST_FORM_FAILED));
        }
      } else {
        // toastr.success('Solicitação', extractErrorMessages(payload.data.message));
        toastr.success('Solicitação', payload.data.message);
        console.log('payload11111', payload)
        let newFiles = []
        if(modulo == 'principal') {
          newFiles = modulo_principal.filter(file => {
            return file.id != data.id
          })
        } else {
          newFiles = modulo_secundario.filter(file => {
            return file.id != data.id
          })
        }
        // dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: false}));
        dispatch(actionCreator(types.DELETE_FILE_UPLOAD, {modulo, newFiles}));
        
      }
    } catch (error) {
      toastr.error('Solicitação', 'Não foi possível processar a sua solicitação no momento.');
      dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: false}));
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível processar a sua solicitação no momento.'));
    }
  }
}

export const removeFileUploadFcCompleta = (data, modulo, id_solicitacao, id_pretendente, id_arquivo) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: true}));
    try {
      const { login: { user: { token } }, requestForm: {modulo_principal, modulo_secundario} } = getState();
      const payload = await deleteFileUpload(token.access_token, data, id_solicitacao, id_pretendente, id_arquivo);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Solicitação', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.REQUEST_FORM_FAILED));
        }
      } else {
        // toastr.success('Solicitação', extractErrorMessages(payload.data.message));
        toastr.success('Solicitação', payload.data.message);
        console.log('payload11111', payload)
        let newFiles = []
        if(modulo == 'principal') {
          newFiles = modulo_principal.filter(file => {
            return file.id != data.id
          })
        } else {
          newFiles = modulo_secundario.filter(file => {
            return file.id != data.id
          })
        }
        // dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: false}));
        dispatch(actionCreator(types.DELETE_FILE_UPLOAD, {modulo, newFiles}));
        
      }
    } catch (error) {
      toastr.error('Solicitação', 'Não foi possível processar a sua solicitação no momento.');
      dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: false}));
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível processar a sua solicitação no momento.'));
    }
  }
}

export const createProductFcRenda = (data, id_solicitacao, id_pretendente, id_produto) => {
  return async (dispatch, getState) => {
    // dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: true}));
    dispatch(actionCreator(types.REQUEST_FORM_SEND));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await addProductFcRenda(token.access_token, data, id_solicitacao, id_pretendente, id_produto);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Solicitação', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.REQUEST_FORM_FAILED));
        }
      } else {
        // toastr.success('Solicitação', extractErrorMessages(payload.data.message));
        toastr.success('Solicitação', `Produto ${id_produto == 1 ? 'report' : id_produto == 3 ? 'essencial' : 'renda'} criado.`);
        console.log('payload success', payload)
        dispatch(actionCreator(types.POST_PRODUCT_FC_RENDA));
        dispatch(push({pathname: "/intranet/operacional/solicitacao/resumo", state: {request: id_solicitacao, addPretenderProduct: true}}))
        // dispatch(goBack());
      }
    } catch (error) {
      toastr.error('Solicitação', 'Não foi possível processar a sua solicitação no momento.');
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível processar a sua solicitação no momento.'));
      // dispatch(actionCreator(types.LOADING_POST_FILE_UPLOAD, {modulo, loading: false}));
    }
  }
}