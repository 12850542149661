import Icons from '../../components/Menu/Icons';

const menuItems = [
  {
    title: "Cadastro",
    subItems: [
      { title: 'Dados do cliente', slug: 'dados-cliente' },
      { title: 'Dados comerciais', slug: 'dados-comerciais' },
      { title: 'Usuários', slug: 'usuarios' },
      { title: 'Pareceres', slug: 'customizar-recomendacoes' }
    ]
  },
  {
    title: "Consumo",
    subItems: [
      // { title: 'Preços', slug: 'precos' },
      { title: 'Relatório', slug: 'relatorio' }
      
    ]
  },
  {
    title: 'Operacional',
    subItems: [
      {title: 'Locações', slug: 'solicitacao'}
    ]
  },
  {
    title: 'Garantias',
    subItems: [
      {title: 'FC Fiança', slug: 'fcfianca'}
    ]
  },
  {
    title: 'Suporte',
    subItems: [
      {title: `(21)98734-6472`, slug: 'wpp', icon: Icons.WPP()},
      // {title: `(21)2222-4998`, slug: 'tel', icon: Icons.Telefone()},
      {title: `fcanalise@fichacerta.com.br`, slug: 'email', icon: Icons.Email()},
    ]
  }
]

const menuItemsUserMaster = [
  {
    title: "Cadastro",
    subItems: [
      { title: 'Dados do cliente', slug: 'dados-cliente' },
      { title: 'Dados comerciais', slug: 'dados-comerciais' },
      { title: 'Usuários', slug: 'usuarios' },
    ]
  },
  {
    title: "Consumo",
    subItems: [
      // { title: 'Preços', slug: 'precos' },
      { title: 'Relatório', slug: 'relatorio' }
      
    ]
  },
  {
    title: 'Operacional',
    subItems: [
      {title: 'Locações', slug: 'solicitacao'}
    ]
  },
  {
    title: 'Garantias',
    subItems: [
      {title: 'FC Fiança', slug: 'fcfianca'}
    ]
  },
  {
    title: 'Suporte',
    subItems: [
      {title: `(21)98734-6472`, slug: 'wpp', icon: Icons.WPP()},
      // {title: `(21)2222-4998`, slug: 'tel', icon: Icons.Telefone()},
      {title: `fcanalise@fichacerta.com.br`, slug: 'email', icon: Icons.Email()},
    ]
  }
]

const menuItemsSemParecer = [
  {
    title: "Cadastro",
    subItems: [
      { title: 'Dados do cliente', slug: 'dados-cliente' },
      { title: 'Dados comerciais', slug: 'dados-comerciais' },
      { title: 'Usuários', slug: 'usuarios' }
    ]
  },
  {
    title: "Consumo",
    subItems: [
      { title: 'Relatório', slug: 'relatorio' }
      
    ]
  },
  {
    title: 'Operacional',
    subItems: [
      {title: 'Locações', slug: 'solicitacao'}
    ]
  },
  {
    title: 'Garantias',
    subItems: [
      {title: 'FC Fiança', slug: 'fcfianca'}
    ]
  },
  {
    title: 'Suporte',
    subItems: [
      {title: `(21)98734-6472`, slug: 'wpp', icon: Icons.WPP()},
      // {title: `(21)2222-4998`, slug: 'tel', icon: Icons.Telefone()},
      {title: `fcanalise@fichacerta.com.br`, slug: 'email', icon: Icons.Email()},
    ]
  }
]

const menuItemsMobile = [
  {
    title: "Cadastro",
    subItems: [
      { title: 'Em breve' },
      { title: 'Usuários', slug: 'usuarios' }
    ]
  },
  {
    title: "Consumo",
    subItems: [
      // { title: 'Preços', slug: 'precos' },
      { title: 'Em breve! Relatório' }
    ]
  },
  {
    title: 'Operacional',
    subItems: [
      {title: 'Locações', slug: 'solicitacao'}
    ]
  },
  {
    title: 'Suporte',
    subItems: [
      {title: `(21)98734-6472`, slug: 'wpp', icon: Icons.WPP()},
      // {title: `(21)2222-4998`, slug: 'tel', icon: Icons.Telefone()},
      {title: `fcanalise@fichacerta.com.br`, slug: 'email', icon: Icons.Email()},
    ]
  }
]

export { menuItemsMobile, menuItemsSemParecer, menuItemsUserMaster };
export default menuItems;