export const REGISTER_CLIENT_REQUEST = 'client/REGISTER_CLIENT_REQUEST';
export const REGISTER_CLIENT_SUCCESS = 'client/REGISTER_CLIENT_SUCCESS';
export const REGISTER_CLIENT_FAILED = 'client/REGISTER_CLIENT_FAILED';
export const OPEN_PRE_REGISTERED_MODAL = 'client/OPEN_PRE_REGISTERED_MODAL';
export const ClOSE_PRE_REGISTERED_MODAL = 'client/ClOSE_PRE_REGISTERED_MODAL';
export const GET_CLIENT_REQUEST = 'client/GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = 'client/GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILED = 'client/GET_CLIENT_FAILED';
export const UPDATE_AVALIABLE_CREDIT_BALANCE = 'client/UPDATE_AVALIABLE_CREDIT_BALANCE';
export const UPDATE_NOT_LINK_CREDIT_BALANCE = 'client/UPDATE_NOT_LINK_CREDIT_BALANCE';
export const UPDATE_EMPLOYEE_CREDIT = 'client/UPDATE_EMPLOYEE_CREDIT';
export const SAVE_COLLABORATOR = 'client/SAVE_COLLABORATOR';
export const CLEAN_COLLABORATOR = 'client/CLEAN_COLLABORATOR';
export const SAVE_STATUS_EDIT = 'client/SAVE_STATUS_EDIT';
export const CLEAN_CLIENT_CACHE = 'client/CLEAN_CLIENT_CACHE';
