import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { unregisterField, registerField, clearFields } from 'redux-form';
import inputHandler from './inputHandler';
import { defaultPhone } from './fields';
import minus from '../../img/svg/icon-minus.svg';
import plus from '../../img/svg/icon-plus.svg';
import styled from 'styled-components';

const SignButtonContainer = styled.div`
  position: absolute;
  top: 0;
  padding-top: 20%;
  bottom: 0;
  right: 1rem;
`;

const MinusSVG = styled.img.attrs({ src: minus, alt: 'minusSVG' })`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  fill: ${props => props.theme.color_white};
`

const PlusSVG = styled.img.attrs({ src: plus, alt: 'minusSVG' })`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  fill: ${props => props.theme.color_white};
`

class DynamicInputRow extends PureComponent {
  static defaultProps = {
    requirements: {}
  }
  state = {
    fields: this.props.phoneFields
  }

  handleComposedFields = (field) => {
    const { ddi } = this.props.requirements;
    const firtsFieldName = field.innerInputs[0].name;

    if (firtsFieldName.search(/ddi/) > -1) {
      return inputHandler({...field, options: ddi });
    }

    return inputHandler(field);
  }

  renderFields = () => {
    const { fields } = this.state;
    if (Array.isArray(fields) && fields.length > 0) {
      const fieldsToRender = fields.map((field, index) => {
        const newField = field;
        
        if (index === 1) {
          newField.after =  (
            <SignButtonContainer onClick={this.removeField}>
              <MinusSVG />
            </SignButtonContainer>
          );
        } else if (fields.length === 1) {
          newField.after = (
            <SignButtonContainer onClick={this.addField}>
              <PlusSVG />
            </SignButtonContainer>
          );
        } else {
          newField.after = null;
        }
  
        return this.handleComposedFields(newField);
      });
  
      return fieldsToRender
    }

    return null;
  }

  addField = (e) => {
    e.preventDefault();
    this.setState({ fields: [...this.state.fields, defaultPhone('ddi_adicional', 'telefone_adicional', 'Telefone Adicional')]})
  }

  removeField = (e) => {
    e.preventDefault();
    this.setState((state) => {
      const popedFieldList = state.fields;
      popedFieldList.pop();
      return { fields: popedFieldList }
    })
    this.props.unregisterField(this.props.formName, 'ddi_adicional');
    this.props.unregisterField(this.props.formName, 'telefone_adicional');
    this.props.clearFields(this.props.formName, true, true, 'ddi_adicional', 'telefone_adicional')
  }

  render() {
    return (
      <Fragment>
        {this.renderFields()}
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    phoneFields: [defaultPhone('ddi_principal', 'telefone_principal')],
    requirements: state.requirements
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({ unregisterField, registerField, clearFields }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DynamicInputRow);
