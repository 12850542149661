import React, { PureComponent } from 'react'
import { Col } from 'reactstrap';
import CheckBox from './CheckBox';
import { StyledErrorSpanForCheckboxGroup, StyledLabel } from './Inputs.styles';
import { BaseColumn, StyledRow } from './sharedStyles.styles';

class Group extends PureComponent {
  static defaultProps = {
    onCheck: () => undefined
  }

  onCheck = (option) => (e) => {
    const { input, onCheck } = this.props;
    let newValues = [...input.value];
    const { checked } = e.target;
    if (checked) {
      newValues.push(option.value);
    } else {
      newValues = newValues.filter(value => value !== option.value)
    }

    onCheck(checked, option);
    input.onChange(newValues);
  }

  getBoxColor = () => {
    const { input, required } = this.props;
    if (required) {
      if (Array.isArray(input.value) && input.value.length > 0) {
        return 'color_green';
      }

      return 'color_orange';
    }

    return 'checkbox_color';
  }

  handleOptionsList = () => {
    const { input } = this.props;

    const mappedOption = this.props.options.map((option) => (
      <CheckBox
        boxColor={this.getBoxColor()}
        key={option.value}
        disabled={this.props.disabled}
        name={`${input.name}_${option.value}`}
        checked={input.value.indexOf(option.value) !== -1}
        label={option.label}
        onChange={this.onCheck(option)}
      />
    ));

    return mappedOption;
  }

  handleOptionsDirectionRender = () => {
    if (this.props.direction === 'column') {
      return (
        <BaseColumn>
          {this.handleOptionsList()}
        </BaseColumn>
      );
    }

    return (
      <StyledRow justify="flex-start">
        {this.handleOptionsList()}
      </StyledRow>
    );
  }

  render() {
    return (
      <Col className={this.props.size}>
        <Col style={{ padding: 0, marginBottom: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledLabel>{this.props.title || 'Serviços de Interesse (no mínimo um)'}</StyledLabel>
            {
              this.props.meta.dirty && this.props.input.value.length === 0 &&
              <StyledErrorSpanForCheckboxGroup>Escolha pelo menos uma opção</StyledErrorSpanForCheckboxGroup>
            }
          </div>
        </Col>
        {this.handleOptionsDirectionRender()}
      </Col>
    );
  }
}

export default Group;
