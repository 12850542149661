import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};

  .cls-1 { fill: #feda32; }
  .cls-2 { fill: #413304; }
  .cls-3 { fill: #443405; }
  .cls-4 { fill: #433305; }
`;

export default (props) => (
  <SVG {...props} viewBox="0 0 137 137">
    <circle class="cls-1" cx="68.5" cy="68.5" r="68.5"/>
    <path class="cls-2" d="M76.53,112.45c-12.28,0-23.08-7.3-27.08-18.42-.8-2.23-1.72-4.75,1.22-6,2.78-1.21,4.23.6,5.24,3,3.81,9.22,10.62,14.43,20.71,14.39,10.27,0,17.15-5.42,21-14.78,1-2.4,2.35-4.3,5.21-2.91s2.09,3.7,1.17,6C99.44,105.41,89.15,112.42,76.53,112.45Z" transform="translate(-8 -4)"/>
    <path class="cls-3" d="M51.36,67.84c0,2,.08,4,0,6a4.69,4.69,0,0,1-4.72,4.84,4.66,4.66,0,0,1-5.21-4.34,93.09,93.09,0,0,1,0-13.43c.2-2.86,2.28-4.36,5.2-4.24s4.59,1.78,4.74,4.72C51.45,63.52,51.37,65.68,51.36,67.84Z" transform="translate(-9.17 -6)"/>
    <path class="cls-4" d="M104,67a46.69,46.69,0,0,1,0-6c.37-2.86,2.19-4.4,5.13-4.34,2.7.06,4.58,1.49,4.74,4.15a127.11,127.11,0,0,1,0,13.44c-.15,2.95-2.39,4.49-5.09,4.42A4.72,4.72,0,0,1,104,73.93c-.15-2.32,0-4.65,0-7Z" transform="translate(-9.17 -6)"/>
  </SVG>
)
