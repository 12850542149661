import React, { PureComponent } from 'react';

import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import { PrimaryButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { Container, SpanTitle, GrayText } from '../form/fieldModal/formModalFields.styled';

class EditsCountModal extends PureComponent {

  renderFooter = () => (
    <StyledRow padding='0 3em' justify="center">
      <PrimaryButton data-testid="go-back-button" onClick={this.onSubmit} >{this.props.count > 0 ? "Continuar" : "Fechar"}</PrimaryButton>
    </StyledRow>
  )

  renderHeader = () => (
    <SpanTitle customstyle="margin: auto;">
      ATENÇÃO
    </SpanTitle>
  )

  onSubmit = () => {
    if(this.props.count > 0){
      this.props.continue(false)
    }else{
      this.props.closeModal()
    }
  }

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        size="sm"
        onClose={this.props.closeModal}
        footer={this.renderFooter()}
        header={this.renderHeader()}
      >

        <Container customstyle="text-align: center; margin: 20px auto;">
          <GrayText>
            {this.props.count > 0 
              ?
                <div>
                  <span>Essa será a {(this.props.count - 31) * -1}ª de 30 alterações de dados do laudo possíveis.</span>
                  <br/><br/>
                  <span>Após a 30ª alteração, será necessário solicitar uma RECONSULTA, ou um novo produto.</span>
                </div>
              :
                <div>
                  <span>O laudo já foi alterado 30 vezes (número máximo permitido). Sendo assim, não é possível fazer edições</span>
                  <br/><br/>
                  <span>Para realizar novas edições será necessário solicitar uma RECONSULTA, ou um novo produto.</span>
                </div>
            }
          </GrayText>
        </Container>

      </ModalContainer>
    );
  }
}

export default EditsCountModal;