import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getRequirements } from '../form/actions';
import { openModal } from '../form/fieldModal/actions';
import { getApplicantInfos, updateApplicantInfos } from './actions';
import { openModal as openModalIncomeSection } from '../form/incomeModal/actions';
import FormModalFields from '../form/fieldModal/FormModalFields';
import { getSuccessStatus, getLoadingFlag, getSendingStatus } from './selectors';

import ApplicantForm from '../form/Applicant/ApplicantForm';
import applicantFields from '../form/Applicant/fields';

import locationFields from '../form/LocationSection/fields';

import IncomeForm from '../form/IncomeSection/IncomeForm';
import incomeFields from '../form/IncomeSection/fields';

import { PageContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';
import Header from '../../../../components/Header';
import { getChangedValues } from '../../../../utils/formHelper';
import { getApplicantEditObj, getApplicantPJEditObj } from '../../../../utils/operational/requests/requestFormHelper';
import { LoadingOperational } from '../../../../components/Operational/operational.styles';

import { ContainerGifLoading } from '@bit/wavfichacerta.fichacerta.tables.styles';

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { FullColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import RequestStepButton from '../form/RequestStepButton';
import Container from '../form/Container';
import BlockNavigationContainer from '@bit/wavfichacerta.fichacerta.forms.block-navigation-container';
import { SubmitButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import LoadingCircle from '@bit/wavfichacerta.fichacerta.icons.loading-circle';
import { getAllValues } from '../form/selectors';
import { Main, MainContainer } from './RequestResume';

import Tour from 'reactour'
import axios from 'axios';

const Forms = ["applicantForm", "incomeForm"];

const steps_renda_pessoal = [
  {
    selector: '.PERFIL.FINANCEIRO',
    content: <span>Nesta tela é possível <b>mudar</b> o <b>perfil financeiro</b> a ser avaliado.</span>,
  },
  {
    selector: '.Renda.principal',
    content: 'Você pode ajustar a renda quando for necessário.',
  },
  {
    selector: '.Origem.Renda',
    content: <span>Atenção ao preenchimento da <b>origem da renda</b>, ela tem influência no risco da locação.</span>,
  },
  {
    selector: '.Outras.rendas',
    content: 'Caso o pretendente tenha mais de uma origem de renda, preencha aqui o valor e sua respectiva origem.',
  },
  // ...
]

const steps_extratos = [
  {
    selector: '.PERFIL.FINANCEIRO',
    content: <span>Perfil financeiro indicado para renda informal.</span>,
  },
  {
    selector: '.receitas.mensais',
    content: 'Recomendamos o lançamento do valor médio das receitas dos últimos 90 dias.',
  },
  // ...
]

const steps_cartao_credito = [
  {
    selector: '.PERFIL.FINANCEIRO',
    content: <span>Avalia a capacidade financeira através do limite do cartão de crédito.</span>,
  },
  {
    selector: '.Valor',
    content: <span>Informe o <b>limite total</b>, e não apenas o valor disponível para compra. O sistema fará um cálculo de <b>renda estimada</b>.</span>,
  },
  // ...
]

const steps_nao_informado = [
  {
    selector: '.PERFIL.FINANCEIRO',
    content: <span>Você pode optar em <b>não informar a renda</b> o sistema calculará o risco baseado na <b>renda presumida</b>, nos casos de não detecção, ou insuficiência para aprovação, solicitaremos a renda declarada para geração do risco.</span>,
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class EditableForm extends PureComponent {

  constructor(props) {
    super(props);
    this.applicantForm = React.createRef();
    this.locationForm = React.createRef();
    this.incomeForm = React.createRef();
    this.container = React.createRef();

    this.state = {
      obito: null,
      pendencia: null,
      acoesCriminais: null,
      alertaFraude: null,
      tourOpen: false,
      tourId: null
    }
  }

  componentDidMount() {
    const { state } = this.props.location;
    const { requestId, applicantId, entity } = state;

    this.props.getApplicantInfos(requestId, applicantId);
    this.props.getRequirements(entity);
    this.getCheckOnboard()
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'form-pf-editar-pretendente') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'form-pf-editar-pretendente'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'form-pf-editar-pretendente',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  changedValues = () => {
    const changed = Forms.reduce((acc, form) => {
      if (this.props[form]) {
        const { changedValues, initialValues } = this.props[form];
        const values = getChangedValues(initialValues, changedValues);
        if (values === null) {
          return acc;
        }

        return {
          ...acc,
          [form]: values
        };
      }

      return acc;
    }, {});
    return changed;
  }

  checkTypeIncome = () => {
    const {incomeForm: {changedValues}} = this.props
    
    if (changedValues !== undefined) {
      if ("renda_principal" in changedValues || "outras_rendas" in changedValues || "origem_renda" in changedValues || "origem_renda_secundaria" in changedValues || "avaliacao_financeira" in changedValues) {
        const { renda_principal, outras_rendas, origem_renda, origem_renda_secundaria, avaliacao_financeira, limite_cartao_credito, movimentacao_bancaria_extratos } = changedValues;
        if(avaliacao_financeira == 'renda') {
          if (renda_principal > 0 && ((origem_renda !== undefined && origem_renda.toString() === "") || origem_renda === undefined)) {
            return false;
          }else if((origem_renda && origem_renda.toString() !== "1") && !renda_principal){
            return false
          }

          if(origem_renda == 1 && renda_principal > 0) {
            return false
          }

          if (outras_rendas > 0 && ((origem_renda_secundaria !== undefined && origem_renda_secundaria.toString() === "") || origem_renda_secundaria === undefined)) {
            return false;
          }else if((origem_renda_secundaria && origem_renda_secundaria.toString() !== "1") && !outras_rendas){
            return false
          }
        }

        if (avaliacao_financeira == 'card' && ((limite_cartao_credito !== undefined && limite_cartao_credito.toString() === "") || limite_cartao_credito === undefined)) {
          return false;
        }else if(avaliacao_financeira == 'card' && ((limite_cartao_credito == 0) || isNaN(limite_cartao_credito))){
          return false
        }

        if (avaliacao_financeira == 'extratos' && ((movimentacao_bancaria_extratos !== undefined && movimentacao_bancaria_extratos.toString() === "") || movimentacao_bancaria_extratos === undefined)) {
          return false;
        }else if(avaliacao_financeira == 'extratos' && ((movimentacao_bancaria_extratos == 0) || isNaN(movimentacao_bancaria_extratos))){
          return false
        }
      }
    }
    return true;
  }

  updateApplicant = () => {
    if (this.checkTypeIncome()) {
      const { requestId, applicantId, entity } = this.props.location.state;
      const { applicantForm } = this.props
      let obito = undefined
      let pendencia = undefined
      let acoesCriminais = undefined
      let alertaFraude = undefined
      if(applicantForm.initialValues) {
        if(this.state.obito !== null && applicantForm.initialValues.suspeita_obito !== this.state.obito) {
          obito = this.state.obito
        }
        if(this.state.pendencia !== null && applicantForm.initialValues.cpf_pendente !== this.state.pendencia) {
          pendencia = this.state.pendencia
        }
        if(this.state.acoesCriminais !== null && applicantForm.initialValues.tem_acoes_criminais !== this.state.acoesCriminais) {
          acoesCriminais = this.state.acoesCriminais
        }
        if(this.state.alertaFraude !== null && applicantForm.initialValues.tem_alerta_fraude !== this.state.alertaFraude) {
          alertaFraude = this.state.alertaFraude
        }
      }
      this.container.current.unBlock();
      const applicant = (entity === "PF")? getApplicantEditObj({...this.changedValues(), ...this.props.incomeForm}) : getApplicantPJEditObj(this.changedValues);

      if(entity === 'PF' && applicant.tipo_pretendente === 'INQUILINO') {
        if(applicant.participante === undefined || applicant.participante === null) {
          applicant.participante = '1';
        }
      }

      this.props.updateApplicantInfos(
        requestId,
        applicantId,
        {
          pretendente: {...applicant, suspeita_obito: obito, cpf_pendente: pendencia, tem_acoes_criminais: acoesCriminais, tem_alerta_fraude: alertaFraude}
        }
      );
    } else {
      this.props.openModalIncomeSection();
    }
  }


  renderStepButton = () => {
    const { isLoading, success, sending } = this.props;
    const { reInclusion, requestId, applicantId, entity, selectedProduct, applicants } = this.props.location.state
    if (!isLoading && success && !reInclusion) {
      return (
        <RequestStepButton
          showBackButton
          withoutTenancy
          goBack={this.props.history.goBack}
          forms={Forms}
          step={Forms.length}
          sending={sending}
          sendRequestRegistration={this.updateApplicant}
          usedFields={
            {
              ...applicantFields,
              ...locationFields,
              ...incomeFields
            }
          }
        />
      );
    }else if(reInclusion){
      const applicant = (entity === "PF")? getApplicantEditObj({ ...this.changedValues(), ...this.props.incomeForm }) : getApplicantPJEditObj(this.changedValues);

      return (
        <StyledRow justify="center" padding="1rem 0">
          <SubmitButton 
            onClick={() => this.props.history.push({
              pathname: '/intranet/operacional/solicitacao/resumo',
              state: { request: requestId, applicant, applicantId }
            })}
            customstyle={{ color: 'white', marginRight: '1rem' }}>
            Cancelar
          </SubmitButton>
          <SubmitButton color="color_white" onClick={() => this.props.history.push({
            pathname: '/intranet/operacional/solicitacao/resumo/locacao',
            state: { request: requestId, applicant, applicantId, reInclusion, selectedProduct, applicants }
          })} >
            Avançar
          </SubmitButton>
        </StyledRow>
      )
    }

    return null;
  }

  handleContent = () => {
    const { isLoading, success, applicantForm } = this.props;
    const { state } = this.props.location;
    const ativo = state.products.ativo;
    let suspeitaObito = null
    let pendenciaCPF = null
    let temAcoesCriminais = null
    let temAlertaFraude = null

    console.log('eueu1', this.props)
    console.log('eueu2', this.state)

    if(applicantForm.initialValues) {
      suspeitaObito = applicantForm.initialValues.suspeita_obito
      pendenciaCPF = applicantForm.initialValues.cpf_pendente
      temAcoesCriminais = applicantForm.initialValues.tem_acoes_criminais
      temAlertaFraude = applicantForm.initialValues.tem_alerta_fraude
    }

    const productFcEssencial = ativo.filter(product => product.id === 3)
    const productFcReport = ativo.filter(product => product.id === 1)
    const productFcBasico = ativo.filter(product => product.id === 5)

    if (isLoading) {
      return (
        <LoadingOperational>
          <ContainerGifLoading>
            <LoadingCircle />
          </ContainerGifLoading>
        </LoadingOperational>
      );
    }

    if (success) {
      return (
        <Fragment>
          <div style={{position: 'relative'}}>
            <ApplicantForm editMode ref={this.applicantForm} entity={state.entity} products={[1]} isEditable={state.products.ativo[0].status !== "INCLUIDO"} suspeitaObito={suspeitaObito} onChangeCheckObito={(value) => this.setState({obito: value})} pendenciaCPF={pendenciaCPF} onChangeCheckPendenciaCPF={(value) => this.setState({pendencia: value})} onChangeCheckAcoesCriminais={(value) => this.setState({acoesCriminais: value})} temAcoesCriminais={temAcoesCriminais} onChangeCheckAlertaFraude={(value) => this.setState({alertaFraude: value})} temAlertaFraude={temAlertaFraude}/>
            {(state.entity === 'PF' && (productFcEssencial[0] || productFcReport[0])) && <IncomeForm editMode ref={this.incomeForm} entity={state.entity} products={[1]} />}
          </div>
        </Fragment>
      );
    }

    return (
      <StyledRow align="center" justify="center">
        <FullColumn>
          <h5>
            Não foi possivel carregar esse pretendente.<br />
            Por Favor, entre em contato com a FC ANALISE!
          </h5>
        </FullColumn>
      </StyledRow>
    );
  }

  areThereChangedValues = () => {
    return Object.keys(this.changedValues).length === 0;
  }

  render() {
    console.log('ppp1', this.props)
    const {incomeForm: {changedValues}} = this.props
    const breadcrumb = [
      { link: '', label: 'operacional'},
      { link: '/intranet/operacional/solicitacao', label: 'Locações' },
      {
        link: {
          pathname: '/intranet/operacional/solicitacao/resumo',
          state: {request: this.props.location.state.requestId}
        },
        label: 'resumo'
      }
    ];

    let steps = steps_renda_pessoal;

    if(changedValues && changedValues.avaliacao_financeira) {
      if(changedValues.avaliacao_financeira === 'extratos') {
        steps = steps_extratos;
      } else if(changedValues.avaliacao_financeira === 'card') {
        steps = steps_cartao_credito;
      } else if(changedValues.avaliacao_financeira === 'nao-informado') {
        steps = steps_nao_informado;
      }
    }

    console.log('sxx1', this.state)
    console.log('sxx2', this.props)

    return (
      <Main>
        <Header
          title="Editar Pretendente"
          actualLink="Editar"
          breadcrumb={breadcrumb}
          subtitle={this.props.customer}
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <BlockNavigationContainer
          ref={this.container}
          history={this.props.history}
          blockNavigation={this.areThereChangedValues}
        >
          <MainContainer>
            <Container renderStepButton={this.renderStepButton}>
              {this.handleContent()}
            </Container>
          </MainContainer>
        </BlockNavigationContainer>
        <FormModalFields />
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
          startAt={0}
        />
      </Main>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getRequirements,
  openModal,
  getApplicantInfos,
  updateApplicantInfos,
  openModalIncomeSection
}, dispatch);

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isLoading: getLoadingFlag(state),
    success: getSuccessStatus(state),
    sending: getSendingStatus(state),
    incomeForm: getAllValues(state, "incomeForm"),
    applicantForm: getAllValues(state, "applicantForm"),
    login: state.login,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditableForm);
