import React from 'react';
import {
  ColoredSpan
} from '../../analythic.styled';
import { Td } from '@bit/wavfichacerta.fichacerta.tables.styles';
import moment from 'moment';
import { formatToBRL } from '../../../../../utils/formatter';

const TableLine = (props) => {
  const {
    tipo_pefin,
    modalidade_credito,
    data,
    cpf_avalista,
    valor,
    numero_contrato,
    empresa,
    praca,
    pretendente
  } = props;

  return (
    <tr>
      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{moment(data).format('MM/YYYY')}</ColoredSpan>
      </Td>

      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{tipo_pefin}</ColoredSpan>
      </Td>

      {pretendente.cpf &&
        <React.Fragment>
          <Td bordercolor="transparent">
            <ColoredSpan color='color_brown'>{modalidade_credito}</ColoredSpan>
          </Td>

          <Td align="center" bordercolor="transparent">
            <ColoredSpan color='color_brown'>{cpf_avalista}</ColoredSpan>
          </Td>
        </React.Fragment> || null
      }

      <Td align="right" bordercolor="transparent">
        <ColoredSpan color='color_brown'>R$ {formatToBRL(valor)}</ColoredSpan>
      </Td>

      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{numero_contrato}</ColoredSpan>
      </Td>

      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{empresa}</ColoredSpan>
      </Td>

      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{praca}</ColoredSpan>
      </Td>


    </tr>
  );
}

TableLine.defaultProps = {
  quantidade: 0,
  tipo_pefin: "",
  modalidade_credito: "",
  data: "",
  cpf: "",
  valor: "",
  numero_contrato: "",
  empresa: "",
  praca: "",
  sub_judice: ""
};

export default TableLine;
