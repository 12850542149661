import React from "react";
import styled, { css } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.width? props.width : '100%'};
  height: ${props => props.height? props.height : '100%'};
  margin: ${props => props.margin? props.margin : 'auto'};
  & > g > path:nth-child(1) {
    fill: ${props => props.theme.color_purple};
  }

  & > g > path:nth-child(2) {
    fill: transparent;
  }
  ${props => css`${props.customStyle}`};
`;

export default (props) => (
  <StyledSVG viewBox="0 0 409.58 302.08" {...props}>
    <g>
      <path d="M413.6,173.8c-2.52,2.65-4.8,5.59-7.6,7.91Q331.25,243.47,256.4,305.1c-5,4.16-9.18,4.8-13.48,2.18s-5-6.59-5-11.05q0-29.39,0-58.79c0-5.84,0-5.76-5.68-6.07-42.61-2.31-83.22-12-120.42-33.54C69.54,173.29,39.38,138.11,20.59,93.08,10.71,69.39,4.78,44.81,4,19.07c-.12-4.27.88-8,4.53-10.49,5.13-3.52,11.14-1.78,15,4C29,20.71,34,29.07,40,36.68c24.8,31.35,57.6,50.4,95.67,61.05,26.87,7.52,54.39,10.22,82.17,10.94,6.52.17,13,0,20.18,0,0-1.46,0-3,0-4.54,0-19.73-.09-39.46.1-59.19a17.12,17.12,0,0,1,2.19-8.65c3.27-5.16,9.73-5.58,15-1.27,10.35,8.4,20.57,17,30.84,25.45q59.6,49.33,119.16,98.72c3.09,2.57,5.5,6,8.22,9ZM258.49,64.15V68q0,24.6,0,49.2c0,8.44-4,12.52-12.49,12.42-12.26-.15-24.53-.3-36.79-.72-29.53-1-58.67-4.66-87-13.45C87.12,104.52,56.61,86.32,31.79,59a4.93,4.93,0,0,0-1-.67l-.94.55C33.69,69.19,37,79.73,41.48,89.78c23,51.78,61.37,87.2,114.63,106.43,28.87,10.42,58.84,14.62,89.4,15.19,9.21.17,12.92,4,12.92,13.25q0,23.8,0,47.6c0,1.24.12,2.48.23,4.39l128.45-106Z" transform="translate(-4.02 -6.76)"/>
      <path class="cls-1" d="M258.49,64.15,387.13,170.67l-128.45,106c-.1-1.92-.23-3.16-.23-4.39q0-23.8,0-47.6c0-9.29-3.72-13.08-12.92-13.25-30.56-.57-60.53-4.77-89.4-15.19C102.84,177,64.47,141.56,41.48,89.78c-4.46-10-7.79-20.59-11.64-30.91l.94-.55a4.93,4.93,0,0,1,1,.67c24.82,27.33,55.33,45.53,90.41,56.42,28.34,8.79,57.48,12.44,87,13.45,12.26.42,24.52.57,36.79.72,8.45.1,12.48-4,12.49-12.42q0-24.6,0-49.2Z" transform="translate(-4.02 -6.76)"/>
    </g>
  </StyledSVG>
);
