export const GET_APPLICANT_INFOS_REQUEST = 'requestResume/GET_APPLICANT_INFOS_REQUEST';
export const GET_APPLICANT_INFOS_SUCCESS = 'requestResume/GET_APPLICANT_INFOS_SUCCESS';
export const GET_APPLICANT_INFOS_FAILED = 'requestResume/GET_APPLICANT_INFOS_FAILED';
export const RESET_APPLICANT_INFOS = 'requestResume/RESET_APPLICANT_INFOS';
export const GET_REQUEST_INFOS = 'requestResume/GET_REQUEST_INFOS';
export const GET_REQUEST_INFOS_FAILED = 'requestResume/GET_REQUEST_INFOS_FAILED';
export const GET_REQUEST_INFOS_SUCCESS = 'requestResume/GET_REQUEST_INFOS_SUCCESS';
export const ADD_APPLICANT = 'productsChoice/ADD_APPLICANT';
export const ADD_APPLICANT_FAILED = 'productsChoice/ADD_APPLICANT_FAILED';
export const ADD_APPLICANT_SUCCESS = 'productsChoice/ADD_APPLICANT_SUCCESS';
export const UPDATE_APPLICANT_INFOS_REQUEST = 'editApplicant/UPDATE_APPLICANT_INFOS_REQUEST';
export const UPDATE_APPLICANT_INFOS_FAILED = 'editApplicant/UPDATE_APPLICANT_INFOS_FAILED';
export const UPDATE_APPLICANT_INFOS = 'editApplicant/UPDATE_APPLICANT_INFOS';
export const PREPARE_REPORT_REQUEST = "requestResume/PREPARE_REPORT_REQUEST";
export const PREPARE_REPORT_SUCCESS = "requestResume/PREPARE_REPORT_SUCCESS";
export const PREPARE_REPORT_FAILED  = "requestResume/PREPARE_REPORT_FAILED";
export const PREPARE_REPORT_RESET  = "requestResume/PREPARE_REPORT_RESET";
export const GET_USER_INFO  = "requestResume/GET_USER_INFO";
export const POST_FILE_UPLOAD = "requestForm/POST_FILE_UPLOAD";