import React, { PureComponent } from 'react';
import { Col } from 'reactstrap';

import {
  CardboardContainer,
  HeaderCardboardContainer,
  LabelHeader,
  IconHeader,
  BodyContainer,
  TitleSpan,
  FooterContainer,
  IconFooter,
  PriceContainer,
  DescriptionParagraph
} from './productCardboard.styles';
import { formatToBRL } from '../../utils/formatter';
import Add from '../shared/icons/Add';
import Verify from '../shared/icons/Verify';
import Document from '../shared/treatedIcons/Document';
import CustomTrash from '@bit/wavfichacerta.fichacerta.icons.custom-trash';
import FcRapida from '@bit/wavfichacerta.fichacerta.icons.fc-rapida';

const icons = {
  Add,
  Verify,
  Document,
  CustomTrash,
  FcRapida
}

class ProductCardBoard extends PureComponent {
  static defaultProps = {
    active: false,
    included: false,
    selected: false,
    title: '',
    isTrashVisible: true,
    onClickTrash: () => { },
    onSelect: () => { },
    price: '',
    description: ''
  }

  getStatusName = () => {
    const { included, selected } = this.props;
    if (included) {
      return "Incluído";
    } else {
      if (selected) {
        return "Selecionado";
      } else {
        return "Incluir";
      }
    }
  }

  getColorsProduct = (id) => {
    switch(id) {
      case 1:
        return '#ff7200'
      case 5:
        return '#660099'
      case 7:
      case 8:
        return '#795548'
    }
  }

  render() {
    const { icon, included, isTrashVisible, selected, active, id } = this.props;
    const Icon = icons[icon];
    const alignFooter = included && isTrashVisible ? 'space-between' : 'flex-end';
    const inserted = (included || selected);
    const price = parseFloat(this.props.price);
    return (
      <Col xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
        <CardboardContainer data-testid="CardboardContainer" included={inserted} onClick={this.props.onSelect} status='INCLUIR' id={id}>
          <HeaderCardboardContainer>
            <LabelHeader style={{color: this.getColorsProduct(id)}} included={inserted}>{this.getStatusName()}</LabelHeader>
            <IconHeader selected={selected}>{inserted && active ? <Verify /> : null}</IconHeader>
          </HeaderCardboardContainer>

          <BodyContainer data-testid="BodyContainer">
            <span style={{position: 'relative'}}>
              <Icon color={inserted ? 'color_green' : 'color_purple'} width="2.8rem" style={{fill: this.getColorsProduct(id)}} customStyle='& path, line { stroke: #660099; }' />
              {(id === 1 || id === 7) &&
                <IconHeader selected={selected} style={{position: 'absolute', top: -7, left: 9.5, fontSize: 32,fontWeight: 300, color: id === 7 ? '#795548' : '#ff7200'}}>
                  +
                </IconHeader>
              }
            </span>
            <TitleSpan included={inserted} style={{color: this.getColorsProduct(id), marginTop: 16}} >{this.props.title}</TitleSpan>
          </BodyContainer>

          <FooterContainer align={alignFooter}>
            {
              inserted && isTrashVisible &&
              <IconFooter onClick={this.props.onClickTrash}>
                <CustomTrash width=".9rem" color="color_green" />
              </IconFooter>
            }
            {/* <PriceContainer included={true} style={{color: this.getColorsProduct(id)}}>
              R$ {formatToBRL(price)}
            </PriceContainer> */}
          </FooterContainer>

          <DescriptionParagraph>
            {this.props.description}
          </DescriptionParagraph>

        </CardboardContainer>
      </Col>
    );
  }
}

export default ProductCardBoard;
