export const onGetChargeData = (state) => ({
  ...state,
  isRequesting: true,
  error: false
});

export const onGetChargeDataSuccess = (state, payload) => ({
  ...state,
  isRequesting: false,
  error: false,
  resume: payload.resumo,
  requests: payload.solicitacoes.data,
  pagination: {
    ...state.pagination,
    ...payload.solicitacoes.pagination,
    page: payload.solicitacoes.pagination.current_page,
    perPage: payload.solicitacoes.pagination.per_page,
    lastPage: payload.solicitacoes.pagination.last_page
  }
});

export const onGetEmptyChargeData = (state) => ({
  ...state,
  isRequesting: false,
  error: false,
  resume: {},
  requests: {}
})

export const onGetChargeDataFailed = (state) => ({
  ...state,
  isRequesting: false,
  error: true
});

export const onChangePage = (state, page) => ({
  ...state,
  pagination: {
    ...state.pagination,
    page
  }
})

export const onChangePerPage = (state, perPage) => ({
  ...state,
  pagination: {
    ...state.pagination,
    perPage
  }
})

export const onChangeFilters = (state, filters, initialState) => ({
  ...state,
  pagination: initialState.pagination,
  filters
})

export const onResetFilters = (state, initialState) => ({
  ...state,
  ...initialState
})