import React from 'react';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import FilterField from './FilterField';
import DateSearchField from './DateSearchField';
import {FilterIcon, FilterButton, FilterText} from './SearchStyles'
import filter from '../../../img/svg/ico-filter.svg';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../shared/TourTooltip';

const SearchGroup = ({setFilter, endDate, startDate, filterDate, hiddenInputFilter}) => {
    const onFormSubmit = e => {
      e.preventDefault()
      filterDate()
    }

    return (
      <BaseColumn customstyle="display: flex;justify-content: flex-start;align-items: center;" xs="12" sm="12" md="8" lg="10" xl="10">
        <form onSubmit={onFormSubmit} style={{width: '100%', display: 'flex'}}>
          {!hiddenInputFilter &&
            <BaseColumn className="box-search" customstyle="display: flex;justify-content: flex-start;align-items: center;" xs="12" sm="12" md="4" lg="4" xl="4">
                <FilterIcon src={filter} />
                <FilterField
                    type="text"
                    placeholder="Filtrar id, endereço, pretendente e CPF/CNPJ..."
                    submitSearch={setFilter}
                    noIcon={true}
                />
                <TourTooltip
                  target="tooltip-input-pesquisar-pretendente"
                  style={{right: -10, top: 5}}
                />
                <ReactTooltip id="tooltip-input-pesquisar-pretendente" place="top" type="dark" effect="float">
                  Neste campo você pode pesquisar o pretendente analisado.
                </ReactTooltip>
            </BaseColumn>
          }

          <BaseColumn customstyle="margin: 1.5rem;display: flex;justify-content: flex-start;align-items: center;" xs="12" sm="12" md="4" lg="4" xl="4">
              <FilterIcon src={filter} style={{marginRight: "5px"}} />
              <FilterText style={{fontWeight: 600, fontSize: "13px"}}>
                  De 
              </FilterText>
              <DateSearchField
                  type="text"
                  placeholder="dd/mm/aaaa"
                  submitSearch={startDate}
                  />
              <FilterText style={{fontWeight: 600, fontSize: "13px"}}> a </FilterText>
              <DateSearchField
                  type="text"
                  placeholder="dd/mm/aaaa"
                  submitSearch={endDate}
                  />
              <FilterButton onClick={onFormSubmit} >
                Filtrar
              </FilterButton>
          </BaseColumn>
        </form>
      </BaseColumn>
    )
}

export default SearchGroup