import React, { Component, Fragment } from "react";
import { reduxForm, Field } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  changeUserStatus,
  getUsers,
  changeSort,
  changePage,
  changeMaxResults,
  resetTable,
  getSendConfirmationMail,
  changeUserType
} from "./actions";
import { getCustomerData } from "../customer/CustomerData/actions";
import {
  getUsersFromState,
  getStatusPermission,
  getStatusOptions
} from "./selector";
import { StyledRow } from "@bit/wavfichacerta.fichacerta.shared-styles.rows";
import { Row } from "reactstrap";
import { PageContainer } from "@bit/wavfichacerta.fichacerta.shared-styles.containers";
import CustomTable from "@bit/wavfichacerta.fichacerta.tables.custom-table";
import Header from "../../components/Header";
import ClockSVG from "../../components/shared/treatedIcons/Clock";
import LikeSVG from "../../components/shared/treatedIcons/Like";
import ArrowSVG from "../../components/CollapsableContainer/img/ArrowDown";
import { HeaderBar, HeaderText } from "./components/creditBar.styled";
import {
  GrayLine,
  LimitTitle,
  LimitTitleBold,
  ButtonBar,
  NewUserButtonWrapper,
  StyledSelect,
  StyledMainContainer,
  StyledMobileRow,
  StyledBaseColumn,
  AccordionButton
} from "./components/users.styled";
import PaginationFooter from "../../components/shared/table/PaginationFooter";
import CustomerCreditBar from "./components/CustomerCreditBar";
import FcCreditBar from "./components/FcCreditBar";
import { formatToBRL } from "@bit/wavfichacerta.fichacerta.utils.formatters";
import HeaderUsersTable from "./components/HeaderUsersTable";
import { StyledInput } from "@bit/wavfichacerta.fichacerta.inputs-styles.inputs-styles";
import UsersRow from "./UsersRow";
import UsersCard from "./UsersCard";
import {
  ModalTitle,
  Container,
  Content
} from "../../components/shared/modal/styled";
import ModalContainer from "../../components/shared/modal/ModalContainer";
import { ButtonForm } from "../../components/shared/formStyles.styled";
import AddIcon from "../../components/shared/icons/AddIcon";
import ChangeStatusModal from "./components/changeStatusModal";
import UsersCreateSuccessModal from "./modals/UsersCreateSuccessModal";
import { Main, MainContainer } from './../operational/request/resume/RequestResume';
import { selectEmployeeCredit } from './actions/employees';

import Tour from 'reactour'
import axios from 'axios'

const steps_master = [
  {
    selector: '.limit-title',
    content: 'Este é o limite total da imobiliária',
  },
  {
    selector: '.total-utilizado',
    content: 'Este é o valor consumido até o momento, a visão aqui é mensal, então este valor de consumo será zerado a cada virada de mês',
  },
  {
    selector: '.disponivel-alocar',
    content: 'Aqui você verifica o valor disponível para atribuir a usuários ',
  },
  {
    selector: '.disponivel-funcionarios',
    content: 'Neste item você observa o valor total do limite disponível não utilizado que está distribuído entre os usuários',
  },
  {
    selector: '.editar-creditos',
    content: 'Neste campo você pode ajustar o limite de cada um dos usuários, aumentando ou diminuindo o valor atribuído a eles',
  },
  {
    selector: 'button[color="color_green"]',
    content: 'Clicando nesta opção você pode suspender ou bloquear um usuário na FC.',
  },
  {
    selector: '.botao-novo-usuario',
    content: 'Crie quantos usuários precisar para o seu time',
  },
  {
    selector: '.box-file select',
    content: 'Cada usuário precisa de um e-mail diferente, pois ele é o passaporte de entrada na FC. Evite o vazamento de senhas, criando um usuário para cada integrante do seu time.',
  },
  // ...
]

const steps_adicional = [
  {
    selector: 'table tbody',
    content: 'Confira aqui a lista de usuários ativos na sua imobiliária.',
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class UsersTable extends Component {
  constructor(props) {
    super(props);
    this.toggleMailModal = this.toggleMailModal.bind(this);
    this.state = {
      modalMailOpen: false,
      modalTypeOpen: false,
      modalStatusOpen: false,
      lineMail: {},
      lineType: {},
      lineStatus: {},
      typeModal: false,
      statusModal: false,
      updateButtonDisabled: true,
      usersCreateSuccessModal_isOpen: false,
      isMobile: window.matchMedia("(max-width: 800px)").matches,
      accordionOpen: window.matchMedia("(max-width: 800px)").matches ? false : true,
      tourOpen: false,
      tourId: null,
      tabSelected: 'tab-ativos'
    };
  }

  componentDidMount() {
    this.props.getCustomerData();
    this.props.getUsers();

    this.renderModalAfterProcess();
    this.getCheckOnboard();
  }

  // Reset user table
  componentWillUnmount() {
    this.props.resetTable();
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'usuarios') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'usuarios'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    if(this.state.tourId) {
      return;
    }

    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'usuarios',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  // Method called when the user clicks on the header column
  clickOnSortHeader = tag => {
    this.props.changeSort(tag);
    // this.props.getUsers();
    const tabId = this.state.tabSelected
    if(tabId === 'tab-suspensos') {
      this.props.getUsers('suspenso');
    } else if(tabId === 'tab-bloqueados') {
      this.props.getUsers('bloqueado');
    } else {
      this.props.getUsers('ativo');
    }
  };

  // Method called when the user change the page
  changePage = newPage => {
    this.props.changePage(newPage);
    // this.props.getUsers();
    const tabId = this.state.tabSelected
    if(tabId === 'tab-suspensos') {
      this.props.getUsers('suspenso');
    } else if(tabId === 'tab-bloqueados') {
      this.props.getUsers('bloqueado');
    } else {
      this.props.getUsers('ativo');
    }
  };

  // Method called when the user change the max results per page
  changeMaxResults = newMaxResults => {
    this.props.changeMaxResults(newMaxResults);
    this.props.getUsers();
  };

  // CONVERT TO STYLED COMPONENT!!
  renderHeaderCredit = (available, used) => (
    <HeaderBar>
      <HeaderText>usado R$ {available},00</HeaderText>
      <HeaderText>disponível R$ {used},00</HeaderText>
    </HeaderBar>
  );

  goToEditForm = employerId => {
    this.props.history.push(`/intranet/cadastro/usuarios/editar/${employerId}`);
  };

  toggleAccordion = () => {
    this.setState({
      accordionOpen: !this.state.accordionOpen
    });
  };

  toggleMailModal(Icon, LikeSVG, ClockSVG, lineMail) {
    const { typeUser } = this.props;

    if (Icon !== ClockSVG || typeUser === "ADICIONAL") {
      return null;
    }

    this.setState({
      modalMailOpen: true,
      lineMail
    });
  }

  toggleTypeModal(lineType) {
    // if (lineType.type === "MASTER") {
    //   return 1;
    // }

    this.setState({
      modalTypeOpen: true,
      lineType
    });
  }

  toggleStatusModal(lineStatus) {
    this.setState({
      modalStatusOpen: true,
      lineStatus
    });
  }

  renderStatusModal = () => {
    return (
      <ChangeStatusModal
        opened={this.state.modalStatusOpen}
        closeStatusModal={this.closeStatusModal}
        {...this.state.lineStatus}
      />
    );
  };

  renderLine = line => {
    const { customer, typeUser } = this.props;

    const {
      funcionario: { credito, credito_utilizado, credito_disponivel, id }
    } = line;

    const Icon = line.usuario.verificado ? LikeSVG : ClockSVG;

    const alignIcon = line.usuario.verificado ? "initial" : "middle";

    const type = line.funcionario.master ? "MASTER" : "ADICIONAL";

    // Calc percentage of used credit
    let usedCreditPercentage = "0%";
    if (credito > 0 && credito_utilizado > 0) {
      const creditPercentage = (credito_utilizado / credito) * 100;
      usedCreditPercentage = `${creditPercentage}%`;
    }

    // Color of status button

    const lineItems = {
      nome: line.pessoa.nome,
      funcao: line.funcionario.funcao,
      email: "usuario" in line ? line.usuario.email : "-",
      status: line.pessoa.status,
      type,
      alignIcon,
      id,
      Icon,
      LikeSVG,
      ClockSVG,
      credito_utilizado,
      credito_disponivel,
      credito,
      usedCreditPercentage,
      typeUser,
      condicao: customer.condicao
    };

    return this.state.isMobile ? (
      <UsersCard
        key={id}
        userId={id}
        loggedId={this.props.customer.id}
        onClickEdit={() => this.goToEditForm(id)}
        onClickStatus={e => this.toggleStatusModal(line)}
        onClickClock={() =>
          this.toggleMailModal(Icon, LikeSVG, ClockSVG, lineItems)
        }
        onClickType={() => this.toggleTypeModal(lineItems)}
        {...lineItems}
      />
    ) : (
      <UsersRow
        selectEmployeeCredit={selectEmployeeCredit}
        getUsers={this.props.getUsers}
        disableCredit={this.props.disableCredit}
        key={id}
        userId={id}
        loggedId={this.props.customer.id}
        onClickEdit={() => this.goToEditForm(id)}
        onClickStatus={e => this.toggleStatusModal(line)}
        credit={credito}
        onClickClock={() =>
          this.toggleMailModal(Icon, LikeSVG, ClockSVG, lineItems)
        }
        onClickType={() => this.toggleTypeModal(lineItems)}
        {...lineItems}
      />
    );
  };

  renderHeaderTypeModal = () => <ModalTitle>Atualizar Tipo</ModalTitle>;

  renderFooterTypeModal = () => {
    return (
      <StyledRow>
        <Row>
          <ButtonForm onClick={this.submitChangeType}>Atualizar</ButtonForm>
        </Row>
      </StyledRow>
    );
  };

  submitChangeType = e => {
    e.preventDefault();

    const { changedValues, changeUserType } = this.props;

    if (changedValues === undefined) {
      return this.closeTypeModal();
    }
    changeUserType(changedValues.typeSelect, this.state.lineType.id);
    this.props.getUsers();
    this.closeTypeModal();
  };

  closeMailModal = () => this.setState({ modalMailOpen: false });

  closeTypeModal = () => this.setState({ modalTypeOpen: false });

  closeStatusModal = () => this.setState({ modalStatusOpen: false });

  resendMail(e) {
    e.preventDefault();
    this.props.getSendConfirmationMail(this.state.lineMail.id);
    this.props.getUsers();
    this.closeMailModal();
  }

  renderSelectField = field => {
    return (
      <StyledSelect {...field.input}>
        <option value="">Selecione</option>
        <option value="ADICIONAL">ADICIONAL</option>
        <option value="MASTER">MASTER</option>
      </StyledSelect>
    );
  };

  renderModalAfterProcess = () => {
    const { state } = this.props.history.location;
    if (state && "process" in state) {
      if (state.process === "adicionar") {
        this.setState({ usersCreateSuccessModal_isOpen: true });
      }
      this.props.history.replace({ state: undefined });
    }
  };

  handleCreateSuccessModal = () => {
    this.setState({
      usersCreateSuccessModal_isOpen: !this.state.usersCreateSuccessModal_isOpen
    });
  };

  changeTabs = (e) => {
    const tabId = e.target.id
    if(tabId === 'tab-suspensos') {
      this.props.getUsers('suspenso', 1);
    } else if(tabId === 'tab-bloqueados') {
      this.props.getUsers('bloqueado', 1);
    } else {
      this.props.getUsers('ativo', 1);
    }
    this.setState({tabSelected: tabId})
    console.log('eaqui', e.target.id)
  }

  render() {
    const { list, customer, requesting, pagination, typeUser } = this.props;
    const generalLimit = customer !== undefined ? customer.credito : 0;

    if (!Array.isArray(list) || customer === undefined) {
      return null;
    }

    const steps = this.props.login.user.usuario.master ? steps_master : steps_adicional;

    return (
      <Fragment>
        <Main>
          <Header title="Usuários" actualLink="Usuários" openTourCallback={(call) => this.setState({tourOpen: call})} master={typeUser === "MASTER" ? true : false}/>
          <MainContainer>
            {customer.condicao === "POSPAGO" && typeUser === "MASTER" && (
              <StyledMobileRow>
                {this.state.isMobile ? (
                <AccordionButton orientation={this.state.accordionOpen} onClick={this.toggleAccordion}>
                  <ArrowSVG fill="#ffffff" width="14px" height="14px" />
                </AccordionButton> ) : null}
                <StyledRow>
                  <GrayLine />
                  <LimitTitle className="limit-title">
                    LIMITE GERAL:{" "}
                    <LimitTitleBold>
                      R$ {formatToBRL(generalLimit)}
                    </LimitTitleBold>
                  </LimitTitle>
                  <GrayLine />
                  {this.state.isMobile ? (
                    <NewUserButtonWrapper>
                      <AddIcon
                        boxShadow="0px 0px 4px gray"
                        width="45px"
                        position="absolute"
                        onClick={() =>
                          this.props.history.push(
                            "/intranet/cadastro/usuarios/adicionar"
                          )
                        }
                      />
                    </NewUserButtonWrapper>
                  ) : (
                    <ButtonBar
                      className="botao-novo-usuario"
                      onClick={() =>
                        this.props.history.push(
                          "/intranet/cadastro/usuarios/adicionar"
                        )
                      }
                    >
                      Novo usuário
                    </ButtonBar>
                  )}
                </StyledRow>
                {this.state.accordionOpen ? (
                  <StyledRow justify="space-between" align="flex-start">
                    <StyledBaseColumn>
                      <FcCreditBar
                        usage={customer.credito_utilizado}
                        available={customer.credito_disponivel}
                      />
                    </StyledBaseColumn>
                    <StyledBaseColumn xs={12} md={7} lg={7} xl={7}>
                      <CustomerCreditBar
                        customer={customer.nome}
                        users={this.props.list}
                      />
                    </StyledBaseColumn>
                  </StyledRow>
                ) : null}

                <StyledRow>
                  <GrayLine margin="1rem 0 0 0" />
                </StyledRow>
              </StyledMobileRow>
            )}

            {customer.condicao === "PREPAGO" && typeUser === "MASTER" && (
              <ButtonBar
                onClick={() =>
                  this.props.history.push(
                    "/intranet/cadastro/usuarios/adicionar"
                  )
                }
              >
                Novo usuário
              </ButtonBar>
            )}

            {this.props.login.user.usuario.master &&
              <div style={{display: 'flex', marginTop: 30, borderBottom: '2px solid #6d6d6d'}}>
                <div>USUÁRIOS</div>
                <div style={{display: 'flex', marginLeft: 40, marginBottom: -2}}>
                  <div id="tab-ativos" className={this.state.tabSelected === 'tab-ativos' && 'active'} onClick={this.changeTabs} style={{color: '#37c120', fontSize: 12, cursor: 'pointer', padding: '4px 12px'}}>ATIVOS</div>
                  <div id="tab-suspensos" className={this.state.tabSelected === 'tab-suspensos' && 'active'} onClick={this.changeTabs} style={{marginLeft: 10, color: '#d99601', fontSize: 12, cursor: 'pointer', padding: '4px 12px'}}>SUSPENSOS</div>
                  <div id="tab-bloqueados" className={this.state.tabSelected === 'tab-bloqueados' && 'active'} onClick={this.changeTabs} style={{marginLeft: 10, color: '#d80027', fontSize: 12, cursor: 'pointer', padding: '4px 12px'}}>BLOQUEADOS</div>
                </div>
              </div>
            }

            {this.state.isMobile ? (
              <CustomTable
                maxSize="initial"
                lines={this.props.list}
                renderHeader={() => null}
                renderLine={this.renderLine}
                loading={requesting}

              />
            ) : (
              <CustomTable
                maxSize="57vh"
                lines={this.props.list}
                renderHeader={() => (
                  <HeaderUsersTable
                    changeOrder={this.clickOnSortHeader}
                    condition={this.props.customer.condicao}
                    typeUser={typeUser}
                  />
                )}
                renderLine={this.renderLine}
                loading={requesting}
              />
            )}
            <PaginationFooter
              pagination={pagination}
              changeMaxResults={this.changeMaxResults}
              changePage={this.changePage}
              allowMaxPerPage={false}
            />
            {this.renderStatusModal()}

            <ModalContainer
              isOpen={this.state.modalMailOpen}
              onClose={this.closeMailModal}
              size="sm"
            >
              <Container>
                <Content>
                  <StyledRow>
                    <ModalTitle>{this.state.lineMail.nome}</ModalTitle>
                  </StyledRow>
                  <StyledInput
                    disabled={true}
                    placeholder={this.state.lineMail.email}
                  />
                  <br />
                  <ButtonBar onClick={e => this.resendMail(e)}>
                    reenviar e-mail de confirmação
                  </ButtonBar>
                </Content>
              </Container>
            </ModalContainer>

            <ModalContainer
              isOpen={this.state.modalTypeOpen}
              onClose={this.closeTypeModal}
              header={this.renderHeaderTypeModal()}
              footer={this.renderFooterTypeModal()}
              size="sm"
            >
              <StyledRow customStyle={{ margin: "20px 10px" }}>
                <Field name="typeSelect" component={this.renderSelectField} />
              </StyledRow>
            </ModalContainer>

            {window.innerWidth <= 800 && (
              <UsersCreateSuccessModal
                isOpen={this.state.usersCreateSuccessModal_isOpen}
                closeModal={this.handleCreateSuccessModal}
              />
            )}
          </MainContainer>
        </Main>
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsers,
      getCustomerData,
      changeSort,
      changePage,
      changeMaxResults,
      resetTable,
      getSendConfirmationMail,
      changeUserType,
      changeUserStatus,
      selectEmployeeCredit
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  statusOptions: getStatusOptions(state),
  requesting: state.employersList.requesting,
  list: getUsersFromState(state),
  customer: state.customerData.data.cliente,
  pagination: state.employersList.pagination,
  typeUser: state.login.user.usuario.master ? "MASTER" : "ADICIONAL",
  statusPermission: getStatusPermission(state),
  login: state.login,
  changedValues:
    state.form.changeTypeForm !== undefined
      ? state.form.changeTypeForm.values
      : {}
});

UsersTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTable);

export default reduxForm({ form: "changeTypeForm" })(UsersTable);

//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersTable));
