import styled from 'styled-components';
import { Row } from 'reactstrap';

export const RowCenter = styled(Row)`
  margin: 0;
  padding: 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: center;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;

  ${(props) => {
    const { getMedias: { iphones } } = props.theme;
    return `
      ${iphones()`
          margin-top: 1rem;
          margin-bottom: 1rem;
        `
      }
    `;
  }}
`;

export const CircleContainer = styled.div`
  width: auto;
  height: auto;
  border-radius: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TitleContainer = styled.h1`
  margin-bottom: 0;
  text-align: center;
  font-size: 1rem;
  color: ${props => props.theme.color_purple};
  text-transform: uppercase;
  padding-top: .25rem;
  font-weight: 600;
`;

export const Col = styled.div`
  display: flex;
  justify-content: center;
`;

export const IconContainer = styled.div`
  width: 1rem;
  margin-left: .3rem;
  cursor: pointer;
`;