import Clock from './Clock';
import Edit from './Edit';
import Like from './Like';
import Eye from './Eye';
import AddClient from './AddClient';
import BlueTrash from './BlueTrash';
import GrayTrash from './GrayTrash';
import GreenTrash from './GreenTrash';
import Plus from './Plus';
import Minus from './Minus';
import Lock from './Lock';
import Question from './Question';
import MagnifyingGlass from './MagnifyingGlass';
import BackArrow from './BackArrow';
import OrderArrow from './OrderArrow';
import Loading from './Loading';
import LoadingCircle from './LoadingCircle';
import LoadingBlueOrange from './LoadingBlueOrange';
import FowardArrow from './FowardArrow';
import Check from './Check';
import Close from './Close';
import Verify from './Verify';
import Add from './Add';
import FcAcoes from './FcAcoes';
import FcFraude from './FcFraude';
import FcRapida from './FcRapida';
import FcRenda from './FcRenda';
import CustomTrash from './CustomTrash';
import House from './House';
import Location from './Location';
import Person from './Person';
import RoundedMoney from './RoundedMoney';
import FingerDown from './FingerDown';
import FingerUp from './FingerUp';
import Null from './Null';
import FaceGood from './FaceGood';
import FaceInconsistent from './FaceInconsistent';
import FaceNotAdvisable from './FaceNotAdvisable';
import FaceVeryGood from './FaceVeryGood';
import FaceRegular from './FaceRegular';
import ReportAlert from './ReportAlert';
import ReportRecommendation from './ReportRecommendation';
import Block from './Block';
import Triangle from './Triangle';
import AddIcon from './AddIcon';
import ChartLine from './ChartLine';
import Document from './Document';

export default {
  AddIcon,
  Clock,
  Block,
  Triangle,
  Edit,
  Like,
  Eye,
  AddClient,
  BlueTrash,
  GrayTrash,
  Plus,
  Minus,
  Lock,
  Question,
  MagnifyingGlass,
  BackArrow,
  OrderArrow,
  Loading,
  LoadingCircle,
  LoadingBlueOrange,
  FowardArrow,
  Check,
  Close,
  Verify,
  Add,
  FcAcoes,
  FcFraude,
  FcRapida,
  FcRenda,
  GreenTrash,
  CustomTrash,
  House,
  Location,
  Person,
  RoundedMoney,
  FingerDown,
  FingerUp,
  Null,
  FaceGood,
  FaceInconsistent,
  FaceNotAdvisable,
  FaceVeryGood,
  FaceRegular,
  ReportAlert,
  ReportRecommendation,
  ChartLine,
  Document
};
