import React, { Fragment } from "react";
import { Title, ContainerButtons, ResumeButton } from "./requestResume.styles";
import RealtyCard from "../../../../components/Operational/RealtyCard";
import RequestsCard from "../../../../components/Operational/RequestsCard";
import Reconsulta from "../../../../components/shared/icons/Reconsulta";
import ModalContainer from "./../../../../components/shared/modal/ModalContainer";
import { PDFButton } from '../../../../components/Header/ButtonsHeader/DefaultButton';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../../components/shared/TourTooltip';

/**
 * Filter the applicant products by the product status
 *
 * @param {Array} applicants
 * @param {String} includedProducts
 */
const filterApplicants = (applicants, includedProducts = true) => {
  let products = [];
  if (Array.isArray(applicants) && applicants.length > 0) {
    // Grouping applicants products in the same array
    applicants.forEach(applicant => {
      products = [
        ...products,
        {
          ativos: [...applicant.produtos.ativo],
          reconsulta: applicant.produtos.reconsulta
        }
      ];
    });
    // Counting and calculating the total of the products
    if (Array.isArray(products) && products.length > 0) {
      const productsReduced = products.reduce(
        (acc, product) => {
          if (product.ativos.length > 0) {
            product.ativos.map(ativo => {
              switch (ativo.id) {
                case 1:
                  acc.FCReportName = 'FC REPORT';
                  if (includedProducts) {
                    if (ativo.status === "INCLUIDO") {
                      acc.FCReportConsulta += 1;
                      acc.FCReportTotal = acc.FCReportTotal + Number(ativo.valor);
                    } else if (ativo.status === "REINCLUIDO") {
                      acc.FCReportReconsulta += 1;
                      acc.FCReportReconsultaTotal = acc.FCReportReconsultaTotal + Number(ativo.valor);
                    }
                  } else {
                    if (product.reconsulta.length > 0) {
                      product.reconsulta.map(item => {
                        if (
                          item.status !== "REINCLUIDO" &&
                          item.status !== "INCLUIDO" &&
                          item.id === 1
                        ) {
                          acc.FCReportReconsulta += 1;
                          acc.FCReportReconsultaTotal = acc.FCReportReconsultaTotal + Number(item.valor);
                        }
                      });
                    }
                    if (
                      product.ativos.length > 0 &&
                      ativo.status &&
                      ativo.status !== "INCLUIDO"
                    ) {
                      acc.FCReportConsulta += 1;
                      acc.FCReportTotal = acc.FCReportTotal + Number(ativo.valor);
                    }
                  }
                  break;

                case 2:
                  acc.FCRendaName = 'FC DOC';
                  if (includedProducts) {
                    if (ativo.status === "INCLUIDO") {
                      acc.FCRendaConsulta += 1;
                      acc.FCRendaTotal = acc.FCRendaTotal + Number(ativo.valor);
                    } else if (ativo.status === "REINCLUIDO") {
                      acc.FCRendaReconsulta += 1;
                      acc.FCRendaReconsultaTotal = acc.FCRendaReconsultaTotal + Number(ativo.valor);
                    }
                  } else {
                    if (product.reconsulta.length > 0) {
                      product.reconsulta.map(item => {
                        if (
                          item.status !== "REINCLUIDO" &&
                          item.status !== "INCLUIDO" &&
                          item.id === 2
                        ) {
                          acc.FCRendaReconsulta += 1;
                          acc.FCRendaReconsultaTotal = acc.FCRendaReconsultaTotal + Number(item.valor);
                        }
                      });
                    }
                    if (
                      product.ativos.length > 0 &&
                      ativo.status &&
                      ativo.status !== "INCLUIDO"
                    ) {
                      acc.FCRendaConsulta += 1;
                      acc.FCRendaTotal = acc.FCRendaTotal + Number(ativo.valor);
                    }
                  }
                  break;

                case 3:
                  acc.FCEssencialName = 'FC ESSENCIAL';
                  if (includedProducts) {
                    if (ativo.status === "INCLUIDO") {
                      acc.FCEssencialConsulta += 1;
                      acc.FCEssencialTotal = acc.FCEssencialTotal + Number(ativo.valor);
                    } else if (ativo.status === "REINCLUIDO") {
                      acc.FCEssencialReconsulta += 1;
                      acc.FCEssencialReconsultaTotal = acc.FCEssencialReconsultaTotal + Number(ativo.valor);
                    }
                  } else {
                    if (product.reconsulta.length > 0) {
                      product.reconsulta.map(item => {
                        if (
                          item.status !== "REINCLUIDO" &&
                          item.status !== "INCLUIDO" &&
                          item.id === 3
                        ) {
                          acc.FCEssencialReconsulta += 1;
                          acc.FCEssencialReconsultaTotal = acc.FCEssencialReconsultaTotal + Number(item.valor);
                        }
                      });
                    }
                    if (
                      product.ativos.length > 0 &&
                      ativo.status &&
                      ativo.status !== "INCLUIDO"
                    ) {
                      acc.FCEssencialConsulta += 1;
                      acc.FCEssencialTotal = acc.FCEssencialTotal + Number(ativo.valor);
                    }
                  }
                  break;
                
                case 4:
                  acc.FCEmpresaName = 'FC EMPRESA';
                  if (includedProducts) {
                    if (ativo.status === "INCLUIDO") {
                      acc.FCEmpresaConsulta += 1;
                      acc.FCEmpresaTotal = acc.FCEmpresaTotal + Number(ativo.valor);
                    } else if (ativo.status === "REINCLUIDO") {
                      acc.FCEmpresaReconsulta += 1;
                      acc.FCEmpresaReconsultaTotal = acc.FCEmpresaReconsultaTotal + Number(ativo.valor);
                    }
                  } else {
                    if (product.reconsulta.length > 0) {
                      product.reconsulta.map(item => {
                        if (
                          item.status !== "REINCLUIDO" &&
                          item.status !== "INCLUIDO" &&
                          item.id === 4
                        ) {
                          acc.FCEmpresaReconsulta += 1;
                          acc.FCEmpresaReconsultaTotal = acc.FCEmpresaReconsultaTotal + Number(item.valor);
                        }
                      });
                    }
                    if (
                      product.ativos.length > 0 &&
                      ativo.status &&
                      ativo.status !== "INCLUIDO"
                    ) {
                      acc.FCEmpresaConsulta += 1;
                      acc.FCEmpresaTotal = acc.FCEmpresaTotal + Number(ativo.valor);
                    }
                  }
                  break;

                case 5:
                  acc.FCBasicoName = 'FC BASICO';
                  if (includedProducts) {
                    if (ativo.status === "INCLUIDO") {
                      acc.FCBasicoConsulta += 1;
                      acc.FCBasicoTotal = acc.FCBasicoTotal + Number(ativo.valor);
                    } else if (ativo.status === "REINCLUIDO") {
                      acc.FCBasicoReconsulta += 1;
                      acc.FCBasicoReconsultaTotal = acc.FCBasicoReconsultaTotal + Number(ativo.valor);
                    }
                  } else {
                    if (product.reconsulta.length > 0) {
                      product.reconsulta.map(item => {
                        if (
                          item.status !== "REINCLUIDO" &&
                          item.status !== "INCLUIDO" &&
                          item.id === 5
                        ) {
                          acc.FCBasicoReconsulta += 1;
                          acc.FCBasicoReconsultaTotal = acc.FCBasicoReconsultaTotal + Number(item.valor);
                        }
                      });
                    }
                    if (
                      product.ativos.length > 0 &&
                      ativo.status &&
                      ativo.status !== "INCLUIDO"
                    ) {
                      acc.FCBasicoConsulta += 1;
                      acc.FCBasicoTotal = acc.FCBasicoTotal + Number(ativo.valor);
                    }
                  }
                  break;
                
                case 6:
                  acc.FCDocPJName = 'FC DOC';
                  if (includedProducts) {
                    if (ativo.status === "INCLUIDO") {
                      acc.FCDocPJConsulta += 1;
                      acc.FCDocPJTotal = acc.FCDocPJTotal + Number(ativo.valor);
                    } else if (ativo.status === "REINCLUIDO") {
                      acc.FCDocPJReconsulta += 1;
                      acc.FCDocPJReconsultaTotal = acc.FCDocPJReconsultaTotal + Number(ativo.valor);
                    }
                  } else {
                    if (product.reconsulta.length > 0) {
                      product.reconsulta.map(item => {
                        if (
                          item.status !== "REINCLUIDO" &&
                          item.status !== "INCLUIDO" &&
                          item.id === 6
                        ) {
                          acc.FCDocPJReconsulta += 1;
                          acc.FCDocPJReconsultaTotal = acc.FCDocPJReconsultaTotal + Number(item.valor);
                        }
                      });
                    }
                    if (
                      product.ativos.length > 0 &&
                      ativo.status &&
                      ativo.status !== "INCLUIDO"
                    ) {
                      acc.FCDocPJConsulta += 1;
                      acc.FCDocPJTotal = acc.FCDocPJTotal + Number(ativo.valor);
                    }
                  }
                  break;

                case 7:
                  acc.FCCompletaPFName = 'FC COMPLETA';
                  if (includedProducts) {
                    if (ativo.status === "INCLUIDO") {
                      acc.FCCompletaPFConsulta += 1;
                      acc.FCCompletaPFTotal = acc.FCCompletaPFTotal + Number(ativo.valor);
                    } else if (ativo.status === "REINCLUIDO") {
                      acc.FCCompletaPFReconsulta += 1;
                      acc.FCCompletaPFReconsultaTotal = acc.FCCompletaPFReconsultaTotal + Number(ativo.valor);
                    }
                  } else {
                    if (product.reconsulta.length > 0) {
                      product.reconsulta.map(item => {
                        if (
                          item.status !== "REINCLUIDO" &&
                          item.status !== "INCLUIDO" &&
                          item.id === 7
                        ) {
                          acc.FCCompletaPFReconsulta += 1;
                          acc.FCCompletaPFReconsultaTotal = acc.FCCompletaPFReconsultaTotal + Number(item.valor);
                        }
                      });
                    }
                    if (
                      product.ativos.length > 0 &&
                      ativo.status &&
                      ativo.status !== "INCLUIDO"
                    ) {
                      acc.FCCompletaPFConsulta += 1;
                      acc.FCCompletaPFTotal = acc.FCCompletaPFTotal + Number(ativo.valor);
                    }
                  }
                  break;
                
                case 8:
                  acc.FCCompletaPJName = 'FC COMPLETA';
                  if (includedProducts) {
                    if (ativo.status === "INCLUIDO") {
                      acc.FCCompletaPJConsulta += 1;
                      acc.FCCompletaPJTotal = acc.FCCompletaPJTotal + Number(ativo.valor);
                    } else if (ativo.status === "REINCLUIDO") {
                      acc.FCCompletaPJReconsulta += 1;
                      acc.FCCompletaPJReconsultaTotal = acc.FCCompletaPJReconsultaTotal + Number(ativo.valor);
                    }
                  } else {
                    if (product.reconsulta.length > 0) {
                      product.reconsulta.map(item => {
                        if (
                          item.status !== "REINCLUIDO" &&
                          item.status !== "INCLUIDO" &&
                          item.id === 8
                        ) {
                          acc.FCCompletaPJReconsulta += 1;
                          acc.FCCompletaPJReconsultaTotal = acc.FCCompletaPJReconsultaTotal + Number(item.valor);
                        }
                      });
                    }
                    if (
                      product.ativos.length > 0 &&
                      ativo.status &&
                      ativo.status !== "INCLUIDO"
                    ) {
                      acc.FCCompletaPJConsulta += 1;
                      acc.FCCompletaPJTotal = acc.FCCompletaPJTotal + Number(ativo.valor);
                    }
                  }
                  break;

                default:
                  break;
              }
            });
          }
          return acc;
        },
        {
          FCEssencialConsulta: 0,
          FCEssencialReconsulta: 0,
          FCReportConsulta: 0,
          FCReportReconsulta: 0,
          FCRendaConsulta: 0,
          FCRendaReconsulta: 0,
          FCDocPJConsulta: 0,
          FCDocPJReconsulta: 0,
          FCEmpresaConsulta: 0,
          FCEmpresaReconsulta: 0,
          FCBasicoConsulta: 0,
          FCBasicoReconsulta: 0,
          FCCompletaPFConsulta: 0,
          FCCompletaPFReconsulta: 0,
          FCCompletaPJConsulta: 0,
          FCCompletaPJReconsulta: 0,
          FCEssencialTotal: 0,
          FCEssencialReconsultaTotal: 0,
          FCReportTotal: 0,
          FCReportReconsultaTotal: 0,
          FCRendaTotal: 0,
          FCRendaReconsultaTotal: 0,
          FCCompletaPFTotal: 0,
          FCCompletaPFReconsultaTotal: 0,
          FCCompletaPJTotal: 0,
          FCCompletaPJReconsultaTotal: 0,
          FCDocPJTotal: 0,
          FCDocPJReconsultaTotal: 0,
          FCEmpresaTotal: 0,
          FCEmpresaReconsultaTotal: 0,
          FCBasicoTotal: 0,
          FCBasicoReconsultaTotal: 0,
          FCEssencialName: "",
          FCReportName: "",
          FCRendaName: "",
          FCDocPJName: "",
          FCEmpresaName: "",
          FCBasicoName: "",
          FCCompletaPFName: "",
          FCCompletaPJName: "",
        }
      );
      return generateLine(productsReduced);
    }
  }
  return [];
};

const generateLine = linesInfo => {
  let lines = [];
  if (linesInfo.FCEssencialConsulta) {
    lines.push({
      title: (
        <span>{`${linesInfo.FCEssencialName} ${
          linesInfo.FCEssencialConsulta > 1 ? `(${linesInfo.FCEssencialConsulta}x)` : ""
        }`}</span>
      ),
      total: linesInfo.FCEssencialTotal
    });
  }
  if (linesInfo.FCReportConsulta) {
    lines.push({
      title: (
        <span>{'FC REPORT'}{` ${
          linesInfo.FCReportConsulta > 1 ? `(${linesInfo.FCReportConsulta}x)` : ""
        }`}</span>
      ),
      total: linesInfo.FCReportTotal
    });
  }
  if (linesInfo.FCRendaConsulta) {
    lines.push({
      title: (
        <span>{`${linesInfo.FCRendaName} ${
          linesInfo.FCRendaConsulta > 1 ? `(${linesInfo.FCRendaConsulta}x)` : ""
        }`}</span>
      ),
      total: linesInfo.FCRendaTotal
    });
  }
  if (linesInfo.FCEmpresaConsulta) {
    lines.push({
      title: (
        <span>{`${linesInfo.FCEmpresaName} ${
          linesInfo.FCEmpresaConsulta > 1 ? `(${linesInfo.FCEmpresaConsulta}x)` : ""
        }`}</span>
      ),
      total: linesInfo.FCEmpresaTotal
    });
  }
  if (linesInfo.FCDocPJConsulta) {
    lines.push({
      title: (
        <span>{`${linesInfo.FCDocPJName} ${
          linesInfo.FCDocPJConsulta > 1 ? `(${linesInfo.FCDocPJConsulta}x)` : ""
        }`}</span>
      ),
      total: linesInfo.FCDocPJTotal
    });
  }
  if (linesInfo.FCBasicoConsulta) {
    lines.push({
      title: (
        <span>{`${linesInfo.FCBasicoName} ${
          linesInfo.FCBasicoConsulta > 1 ? `(${linesInfo.FCBasicoConsulta}x)` : ""
        }`}</span>
      ),
      total: linesInfo.FCBasicoTotal
    });
  }
  if (linesInfo.FCCompletaPFConsulta) {
    lines.push({
      title: (
        <span>{`${linesInfo.FCCompletaPFName} ${
          linesInfo.FCCompletaPFConsulta > 1 ? `(${linesInfo.FCCompletaPFConsulta}x)` : ""
        }`}</span>
      ),
      total: linesInfo.FCCompletaPFTotal
    });
  }
  if (linesInfo.FCCompletaPJConsulta) {
    lines.push({
      title: (
        <span>{`${linesInfo.FCCompletaPJName} ${
          linesInfo.FCCompletaPJConsulta > 1 ? `(${linesInfo.FCCompletaPJConsulta}x)` : ""
        }`}</span>
      ),
      total: linesInfo.FCCompletaPJTotal
    });
  }
  if (linesInfo.FCEssencialReconsulta) {
    lines.push({
      title: (
        <span>
          {`${linesInfo.FCEssencialName} ${
            linesInfo.FCEssencialReconsulta > 1 ? `(${linesInfo.FCEssencialReconsulta}x)` : ""
          }`}{" "}
          <Reconsulta />
        </span>
      ),
      total: linesInfo.FCEssencialReconsultaTotal
    });
  }
  if (linesInfo.FCReportReconsulta) {
    lines.push({
      title: (
        <span>
          {'FC REPORT'}
          {` ${
            linesInfo.FCReportReconsulta > 1 ? `(${linesInfo.FCReportReconsulta}x)` : ""
          }`}{" "}
          <Reconsulta />
        </span>
      ),
      total: linesInfo.FCReportReconsultaTotal
    });
  }
  if (linesInfo.FCRendaReconsulta) {
    lines.push({
      title: (
        <span>
          {`${linesInfo.FCRendaName} ${
            linesInfo.FCRendaReconsulta > 1 ? `(${linesInfo.FCRendaReconsulta}x)` : ""
          }`}{" "}
          <Reconsulta />
        </span>
      ),
      total: linesInfo.FCRendaReconsultaTotal
    });
  }
  if (linesInfo.FCEmpresaReconsulta) {
    lines.push({
      title: (
        <span>
          {`${linesInfo.FCEmpresaName} ${
            linesInfo.FCEmpresaReconsulta > 1 ? `(${linesInfo.FCEmpresaReconsulta}x)` : ""
          }`}{" "}
          <Reconsulta />
        </span>
      ),
      total: linesInfo.FCEmpresaReconsultaTotal
    });
  }
  if (linesInfo.FCDocPJReconsulta) {
    lines.push({
      title: (
        <span>
          {`${linesInfo.FCDocPJName} ${
            linesInfo.FCDocPJReconsulta > 1 ? `(${linesInfo.FCDocPJReconsulta}x)` : ""
          }`}{" "}
          <Reconsulta />
        </span>
      ),
      total: linesInfo.FCDocPJReconsultaTotal
    });
  }
  if (linesInfo.FCBasicoReconsulta) {
    lines.push({
      title: (
        <span>
          {`${linesInfo.FCBasicoName} ${
            linesInfo.FCBasicoReconsulta > 1 ? `(${linesInfo.FCBasicoReconsulta}x)` : ""
          }`}{" "}
          <Reconsulta />
        </span>
      ),
      total: linesInfo.FCBasicoReconsultaTotal
    });
  }
  if (linesInfo.FCCompletaPFReconsulta) {
    lines.push({
      title: (
        <span>
          {`${linesInfo.FCCompletaPFName} ${
            linesInfo.FCCompletaPFReconsulta > 1 ? `(${linesInfo.FCCompletaPFReconsulta}x)` : ""
          }`}{" "}
          <Reconsulta />
        </span>
      ),
      total: linesInfo.FCCompletaPFReconsultaTotal
    });
  }
  if (linesInfo.FCCompletoPJReconsulta) {
    lines.push({
      title: (
        <span>
          {`${linesInfo.FCCompletoPJName} ${
            linesInfo.FCCompletoPJReconsulta > 1 ? `(${linesInfo.FCCompletoPJReconsulta}x)` : ""
          }`}{" "}
          <Reconsulta />
        </span>
      ),
      total: linesInfo.FCCompletoPJReconsultaTotal
    });
  }
  return lines;
};

const hasEditedProductStatus = applicants => {
  if (Array.isArray(applicants) && applicants.length > 0) {
    const appFound = applicants.filter(
      line =>
        line.produtos.ativo.findIndex(product => product.status === "EDITADO") >
        -1
    );
    return appFound.length > 0;
  }
  return false;
};

const showTotal = arr => {
  if (Array.isArray(arr) && arr.length > 0) {
    let total = 0;
    arr.forEach(line => {
      total += line.total;
    });
    return "R$ " + total;
  }
  return "R$ 0,00";
};

const renderIconCart = () => {
  return(
    <svg style={{width: 20, marginLeft: 4, marginBottom: 2, display: 'inline-block'}} fill="none" height="16" viewBox="0 0 30 27" width="30" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.39999 1.70001H6.60001" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
      <path d="M6.60001 1.70001L11 18.9" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
      <path d="M11.8 18.9H28.3" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
      <path d="M13.8 25.7C15.4569 25.7 16.8 24.3569 16.8 22.7C16.8 21.0432 15.4569 19.7 13.8 19.7C12.1431 19.7 10.8 21.0432 10.8 22.7C10.8 24.3569 12.1431 25.7 13.8 25.7Z" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
      <path d="M25.3 25.7C26.9568 25.7 28.3 24.3569 28.3 22.7C28.3 21.0432 26.9568 19.7 25.3 19.7C23.6431 19.7 22.3 21.0432 22.3 22.7C22.3 24.3569 23.6431 25.7 25.3 25.7Z" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
      <path d="M25.7 14.6H11.3C10.7 14.6 10.1 14.2 10 13.6L8.1 6.90001C7.9 6.00001 8.49999 5.20001 9.39999 5.20001H27.5C28.4 5.20001 29.1 6.10001 28.8 6.90001L26.9 13.6C26.9 14.2 26.4 14.6 25.7 14.6Z" stroke="#152b4e" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
    </svg>
  )
}

const hasPretenderSolicitado = (arr) => {
  let hasProduct = false
  arr.map(pretender => {
    pretender.produtos.ativo.map(product => {
      if(product.status !== 'INCLUIDO') {
        hasProduct = true
      }
    })
  })
  return hasProduct
}

const renderButtonCancelarPagamentoPendente = (infos, putCancelarCobrancaAVista, toggleModalPix) => {
  let hasAPagar = null;
  if(infos.cliente_forma_pagamento_id !== 3) {
    return hasAPagar
  }
  infos.pretendentes.map(pretendente => {
    pretendente.produtos.ativo.map(product => {
      if(product.cobranca) {
        if(product.cobranca.status === 'à pagar') {
          hasAPagar = true
        }
      }
    })
  })
  if(hasAPagar) {
    return(
      <ContainerButtons>
        <ResumeButton onClick={toggleModalPix} disabled={false} style={{ backgroundColor: '#152b4e', color: '#fff', fontWeight: 500, width: 'auto', padding: '.25rem 2.3rem .25rem 2.3rem'}}>
          Acessar pix para <br /> pagamento
          {/* {renderIconCart()} */}
        </ResumeButton>
        <ResumeButton onClick={putCancelarCobrancaAVista} disabled={false} style={{ backgroundColor: '#c3c3c3', color: '#fff', fontWeight: 500, width: 'auto' }}>
          Cancelar Pagamento <br /> Pendente
          {/* {renderIconCart()} */}
        </ResumeButton>
      </ContainerButtons>
    )
  }
  return hasAPagar;
}

const ResumeSideContent = ({
  onClick,
  editMode,
  Wrapper,
  infos,
  onClickLocation,
  showDoneBtn = false,
  doneLoading,
  onClickShowReport,
  soTemFcCompletaSemAnalise,
  allOcultos,
  handleSubmitGetPrint,
  putCancelarCobrancaAVista,
  toggleModalPix,
  ...props
}) => (
  <Fragment>
    <Wrapper customstyle="@media (max-width: 800px) {background: #f8f6f6;}">
      <Title>Imóvel</Title>{console.log('tomaxs2', infos)}
      <RealtyCard
        onEditLocation={onClickLocation}
        infos={infos}
        address={infos.locacao.endereco[0]}
      />
    </Wrapper>
    {window.innerWidth >= 801 ? (
      <React.Fragment>
        <Wrapper padding={editMode ? "0 20px 0 20px" : "0"}>
          <RequestsCard
            title="Já solicitados"
            lines={filterApplicants(infos.pretendentes, false)}
            lightTheme={true}
            hasButton={true}
            hasEdited={
              hasEditedProductStatus(infos.pretendentes) || infos.locacao.update
            }
            btnDisabled={doneLoading || soTemFcCompletaSemAnalise || allOcultos}
            onClickBtnTitle={isUpdate => onClickShowReport(isUpdate)}
          />
          {hasPretenderSolicitado(infos.pretendentes) && infos.recibo &&
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 16, marginBottom: 4}}>
              <span style={{fontSize: 14, marginTop: 2, color: '#7f7f7f'}}>RECIBO DA SOLICITAÇÃO</span>
              <PDFButton onClick={handleSubmitGetPrint} disabled={false} className='imprimir-pdf' style={{marginRight: 0}}>Imprimir/PDF</PDFButton>
            </div> || null
          }
        </Wrapper>

        <Wrapper padding={editMode ? "0 20px 0 20px" : "0"}>
          <RequestsCard
            title="A Solicitar"
            lines={filterApplicants(infos.pretendentes, true)}
            // customstyleTitle='color: #ff7200;'
          />
        </Wrapper>

        {renderButtonCancelarPagamentoPendente(infos, putCancelarCobrancaAVista, toggleModalPix)}

        {(!editMode && showDoneBtn && renderButtonCancelarPagamentoPendente(infos) === null) && (
          <ContainerButtons>
            <ResumeButton
              onClick={onClick}
              disabled={doneLoading} 
              className="botao-processar-analise botao-ver-analise"
              customstyle="background-color: #ff7200;color: #152b4e;font-weight: 500;font-size: .88rem;width: auto;position: relative;"
            >
              {infos.cliente_forma_pagamento_id === 3 ? <Fragment>Pagar para Processar <br />ANÁLISE</Fragment> : 'Processar ANÁLISE'}
              {renderIconCart()}

              <TourTooltip
                target="tooltip-botao-processar-analise"
                style={{right: -10}}
              />
              <ReactTooltip id="tooltip-botao-processar-analise" place="left" type="dark" effect="float">
                <span>Clique aqui para processar a NOVA SOLICITAÇÃO.</span>
              </ReactTooltip>
            </ResumeButton>
          </ContainerButtons>
        )}
      </React.Fragment>
    ) : null}

    <Wrapper
      customstyle="
        display: flex;
        justify-content: center;
        position: fixed;
        background-color: #f8f6f6;
        bottom: 0;
        padding: 8px 2.5%;

        button {
          width: 120px;
          height: 33px;
          font-size: .88rem;
        }

        @media (min-width: 801px) {
          display: none;
        }
      "
    >
      <ResumeButton
        onClick={props.handleModalMobile}
        disabled={doneLoading}
        customstyle="
          margin: 0 7px;padding: 0;
          position: relative;
          background: transparent;
          border: 1px solid #152b4e;
          color: #152b4e;

          .total {
            position: absolute;
            top: 0;
            font-size: 10px;
            left: 4px;
          }
          .ico {
            position: absolute;
            right: 3px;
            top: -2px;
            transform: rotate(-45deg);
            width: 15px;

            img {
              width: 100%;
            }
          }
          .price {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 13px;
          }
        "
      >
        <span className="total">
          {(filterApplicants(infos.pretendentes, false)[0] &&
            filterApplicants(infos.pretendentes, true)[0] &&
            "A SOLICITAR") ||
            (filterApplicants(infos.pretendentes, false)[0] &&
              "JÁ SOLICITADO") ||
            (filterApplicants(infos.pretendentes, true)[0] && "TOTAL")}
        </span>
        <span className="ico">
          <img src={require("./../../../../img/png/ico-arrow-expandir.png")} />
        </span>
        <span className="price">
          {(filterApplicants(infos.pretendentes, false)[0] &&
            filterApplicants(infos.pretendentes, true)[0] &&
            showTotal(filterApplicants(infos.pretendentes, true))) ||
            (filterApplicants(infos.pretendentes, false)[0] &&
              showTotal(filterApplicants(infos.pretendentes, false))) ||
            (filterApplicants(infos.pretendentes, true)[0] &&
              showTotal(filterApplicants(infos.pretendentes, true)))}
        </span>
      </ResumeButton>

      {filterApplicants(infos.pretendentes, false)[0] && (
        <React.Fragment>
          {hasEditedProductStatus(infos.pretendentes) ||
          infos.locacao.update ? (
            <ResumeButton
              onClick={() =>
                onClickShowReport(
                  hasEditedProductStatus(infos.pretendentes) ||
                    infos.locacao.update
                )
              }
              customstyle="margin: 0 7px;padding: 0;"
            >
              Atualizar ANÁLISE
            </ResumeButton>
          ) : (
            <ResumeButton
              className="botao-ver-analise"
              onClick={() =>
                onClickShowReport(
                  hasEditedProductStatus(infos.pretendentes) ||
                    infos.locacao.update
                )
              }
              customstyle="margin: 0 7px;padding: 0;"
            >
              Ver ANÁLISE
            </ResumeButton>
          )}
        </React.Fragment>
      )}
      {!editMode && showDoneBtn && (
        <ResumeButton
          onClick={onClick}
          disabled={doneLoading}
          customstyle="margin: 0 7px;padding: 0;background-color: #ff7200;color: #152b4e;width: auto;"
        >
          Processar ANÁLISE
        </ResumeButton>
      )}
    </Wrapper>

    <ModalContainer
      isOpen={props.modalMobileIsOpen}
      onClose={props.handleModalMobile}
    >
      <Wrapper customstyle="margin-top: 14px;padding: 0 30px; span {margin-left: 0;} > div:nth-child(2) {margin-top: 20px;}">
        <Wrapper padding={editMode ? "0 20px 0 20px" : "0"}>
          <RequestsCard
            title="Já solicitados"
            lines={filterApplicants(infos.pretendentes, false)}
            lightTheme={true}
            hasEdited={
              hasEditedProductStatus(infos.pretendentes) || infos.locacao.update
            }
            btnDisabled={doneLoading}
            onClickBtnTitle={isUpdate => onClickShowReport(isUpdate)}
          />
        </Wrapper>

        <Wrapper padding={editMode ? "0 20px 0 20px" : "0"}>
          <RequestsCard
            title="A Solicitar"
            lines={filterApplicants(infos.pretendentes, true)}
            // customstyleTitle='color: #ff7200;'
          />
        </Wrapper>
      </Wrapper>
    </ModalContainer>
  </Fragment>
);

export default ResumeSideContent;
