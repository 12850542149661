import React, { PureComponent, Fragment } from 'react';
import { Container } from '../report.styles';
import Title from '../TitleCategory';
import GeneralReportHeader from './GeneralReportHeader';
import LocationReport from './LocationReport';
import IndividualSynthesis from './IndividualSynthesis';
import { MainContainer } from './../../resume/RequestResume';
import {
  FlexContainer,
  ContentBox,
  TitleContainer,
  Arrow,
  CardContainer,
  SyntethicColumn
} from './../../../../../../src/components/Operational/SyntheticReport/syntheticReport.styles';
import {
  ResumeText
} from './../../../../../../src/components/Operational/SyntheticReport/ResumeTable/resumeTable.styles';
import ResumeTable from './../../../../../../src/components/Operational/SyntheticReport/ResumeTable/ResumeTable';
import ApplicableConcepts from './../../../../../../src/components/Operational/AnalythicReport/RiskProfileSection/ApplicableConcepts';
import ReactTooltip from 'react-tooltip'
import TourTooltip from './../../../../../../src/components/shared/TourTooltip';

export default class GeneralReport extends PureComponent {

  // Avoid errors with data props
  getData = () => {
    const { data } = this.props;
    if (data === undefined || data === null || (typeof data === "object" && Object.keys(data).length === 0)) {
      return {};
    }
    return data;
  }

  // Avoid errors in the loading prop
  getLoadingStatus = () => {
    const { reportPending } = this.props;
    
    if (typeof reportPending !== "boolean") {
      return true;
    }
    return reportPending;
  }

  handleViewNewVersion = () => {
    const user = this.props.customer;
    const userPermited = [
      'IMOBILIARIA TESTE LTDA',
      'IMOBILIÁRIA TESTE LTDA',
      'KHCNX QICCT',
      'ABREU ADMINISTRADORA DE IMóVEIS E CONDOMíNIOS LTDA',
      'AE PATRIMôNIO',
      'ANCORA IMOBILIARIA LTDA',
      'BROGNOLI - CDA',
      'BROGNOLI - CONTINENTE',
      'BROGNOLI - DIVID (DIVID COMPARTILHAMENTOS DE IMOVEIS)',
      'BROGNOLI - ILHA (BROGNOLI IMOVEIS LTDA)',
      'ETICA EMPREENDIMENTOS LTDA',
      'FRIAS NETO CONSULTORIA E EMPREENDIMENTOS IMOBILIARIOS LTDA',
      'FUHRO SOUTO (F. SOUTO IMOVEIS LTDA)',
      'GRALHA LOCACAO E ADMINISTRACAO DE IMOVEIS LTDA',
      'HOME HUNTERS (BLUE NEGOCIOS IMOBILIARIOS – EIRELI)',
      'IMOBILIARIA AVENIDA',
      'REAL BENEFÍCIOS (REDE DE BENEFICIOS DOS AGENTES DO MERCADO IMOBILIARIO DO BRASIL)',
      'RENASCENCA (IMOPRET EMPREENDIMENTOS IMOBILIARIOS LTDA)',
      'ROCA ADMINISTRADORA DE IMÓVEIS EIRELLI',
      'TERRAZ ASSESSORIA IMOBILIÁRIA LTDA',
      'VALOR IMOBILIARIA LTDA',
      'FRANCIOSI CONSTRUTORA E IMOVEIS LTDA',
      'IVO IMÓVEIS (IVO CONSULTORIA DE IMOVEIS S S LTDA)'
    ];
    const newVersion = this.props.data.tagFrontVersion ? this.props.data.tagFrontVersion[0] : null
    const newVersionAll = this.props.data.tagFrontVersion ? this.props.data.tagFrontVersion[1] : null

    if((userPermited.includes(user) && newVersion === '1.0.0') || newVersionAll === '1.0.1') {
      return true;
    }
    return false;
  }

  render() {
    const dataSingleReport = this.getData();
    const reportPending = this.getLoadingStatus();
    let hasPF = false;
    let sintesesPF = [];
    let sintesesPJ = [];

    const data = {
      laudo: {
        parecer_sistemico: [{}],
        principal_origem_renda: {
          result: {}
        }
      },
      pretendente: {}
    }

    const sinteses = ("laudos_sintese" in dataSingleReport)? dataSingleReport.laudos_sintese : [];

    sinteses.map((pretendente, i) => {
      if(pretendente.cpf) {
        hasPF = true
        sintesesPF.push({data: pretendente, index: i})
      } else {
        sintesesPJ.push({data: pretendente, index: i})
      }
    })

    let laudo_inquilinos = [];
    let laudo_fiadores = [];
    let scoreConjuntoInquilinos = 0;
    let scoreConjuntoFiadores = 0;
    let hasParecerLoacaoInquilinos = Object.keys(dataSingleReport.parecer_locacao.parecer_inquilinos).length ? true : false;
    let hasParecerLoacaoFiadores = Object.keys(dataSingleReport.parecer_locacao.parecer_fiadores).length ? true : false;
    let parecerLoacaoInquilinos = Object.keys(dataSingleReport.parecer_locacao.parecer_inquilinos).length ? dataSingleReport.parecer_locacao.parecer_inquilinos.parecer : false;
    let parecerLoacaoFiadores = Object.keys(dataSingleReport.parecer_locacao.parecer_fiadores).length ? dataSingleReport.parecer_locacao.parecer_fiadores.parecer : false;


    this.props.applicants.map(applicant => {
      console.log('applicantx1',applicant)
      if('pretendente' in applicant.data) {

        
        const pretendenteId = applicant.data.pretendente.id;
        const tipo_pretendente = applicant.data.pretendente.tipo_pretendente;

        if(pretendenteId !== 0) {
          switch(tipo_pretendente) {
            case 'INQUILINO':
            case 'CONJUGE_INQUILINO':
              laudo_inquilinos = [...laudo_inquilinos, applicant];
              scoreConjuntoInquilinos = dataSingleReport.parecer_locacao.parecer_inquilinos.score_conjunto;
              break;
            case 'FIADOR':
            case 'CONJUGE_FIADOR':
            case 'FIADOR_ESTUDANTE':
              laudo_fiadores = [...laudo_fiadores, applicant];
              scoreConjuntoFiadores = dataSingleReport.parecer_locacao.parecer_fiadores.score_conjunto;
              break;
          }
        }
      }
    })
    
    return (
      <Fragment>
        <GeneralReportHeader data={dataSingleReport} loading={reportPending} data-testid="headerReport" />

        <MainContainer>
          {hasPF &&
            <Fragment>
              <span className="title-parecer-locacao">
                <Title className='color-black-print'>
                  Parecer da locação

                  <TourTooltip
                    target="tooltip-sintese-parecer-locacao-geral"
                    style={{right: -10}}
                  />
                  <ReactTooltip id="tooltip-sintese-parecer-locacao-geral" place="bottom" type="dark" effect="float">
                    <span style={{textTransform: 'initial'}}>Quer entender melhor algum item do Laudo FC? <br />Clique no tour e saiba mais.</span>
                  </ReactTooltip>
                </Title>
              </span>
              <LocationReport data={dataSingleReport} loading={reportPending} toggleModalFianca={this.props.toggleModalFianca} />
            </Fragment>
          }

          <div className="sintese-parecer-locacao-geral" style={{display: 'flow-root', position: 'relative', backgroundColor: hasParecerLoacaoInquilinos && hasParecerLoacaoFiadores ? '#f8f6f6' : null}}>
            {hasParecerLoacaoInquilinos && this.handleViewNewVersion() &&
              <SyntethicColumn width="50%" className='syntethicColumn-3' customstyle="display:inline-block;flex-direction: row;position: relative;">
                <ContentBox width="100%" ref={this.showArrow} className="card-resumo-analise-individual card-resumo-analise-individual-inquilinos">
                  <TitleContainer marginBottom="15px">RESUMO DOS INQUILINOS considerados</TitleContainer>
                  {/* <ResumeText style={{fontWeight: 400, padding: 0, marginBottom: 5, color: '#152b4e'}}>*O SCORE FC LOCAÇÃO é resultado da análise em conjunto dos inquilinos citados no parecer acima.</ResumeText> */}
                  {/* <ResumeText style={{fontWeight: 400, padding: 0, marginBottom: 10, color: '#152b4e'}}>**Todas as informações abaixo são consideradas no score e risco para esta locação.</ResumeText> */}
                  <ResumeTable data={ dataSingleReport.parecer_locacao.parecer_inquilinos } tipo_imovel={dataSingleReport.locacao.tipo_imovel} tipo="parecer_locacao" />
                  {/* <ApplicableConcepts /> */}
                </ContentBox>
                {/* {this.state.showArrow && <Arrow className='arrow-card-analise-individual' heightArrow={cardAnaliseIndividual[this.props.indexActive - 1] && cardAnaliseIndividual[this.props.indexActive - 1].offsetHeight || 388} />} */}
                {/* {!hasParecerLoacaoFiadores &&
                  <Fragment>
                    <TourTooltip
                      target="tooltip-sintese-parecer-locacao-geral"
                      style={{right: -10}}
                    />
                    <ReactTooltip id="tooltip-sintese-parecer-locacao-geral" place="left" type="dark" effect="float">
                      <span>Na Síntese você confere os indicadores que <br />contribuíram para o risco da locação, o impacto destes <br />indicadores é o mesmo no grupo de inquilinos e de fiadores.</span>
                    </ReactTooltip>
                  </Fragment>
                } */}
                
                {/* <ResumeText style={{padding: 0, marginTop: 5, color: '#152b4e', fontStyle: 'italic'}}>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de apontamentos do pesquisado.</ResumeText> */}
              </SyntethicColumn>
            || null}

            {hasParecerLoacaoFiadores && this.handleViewNewVersion() &&
              <SyntethicColumn width="50%" className='syntethicColumn-3' customstyle="display:inline-block;float:right;flex-direction: row;position: relative;">
                <ContentBox width="100%" ref={this.showArrow} className="card-resumo-analise-individual card-resumo-analise-individual-fiadores">
                  <TitleContainer marginBottom="15px">RESUMO DOS FIADORES considerados</TitleContainer>
                  {/* <ResumeText style={{fontWeight: 400, padding: 0, marginBottom: 5, color: '#152b4e'}}>*O SCORE FC LOCAÇÃO é resultado da análise em conjunto dos fiadores citados no parecer acima.</ResumeText> */}
                  {/* <ResumeText style={{fontWeight: 400, padding: 0, marginBottom: 10, color: '#152b4e'}}>**Todas as informações abaixo são consideradas no score e risco para esta locação.</ResumeText> */}
                  <ResumeTable data={ dataSingleReport.parecer_locacao.parecer_fiadores } tipo_imovel={dataSingleReport.locacao.tipo_imovel} laudos_sintese={dataSingleReport.laudos_sintese} tipo="parecer_locacao" fiadores={true} />
                  {/* <ApplicableConcepts /> */}
                </ContentBox>
                {/* {this.state.showArrow && <Arrow className='arrow-card-analise-individual' heightArrow={cardAnaliseIndividual[this.props.indexActive - 1] && cardAnaliseIndividual[this.props.indexActive - 1].offsetHeight || 388} />} */}
                {/* <TourTooltip
                  target="tooltip-sintese-parecer-locacao-geral"
                  style={{right: -10}}
                />
                <ReactTooltip id="tooltip-sintese-parecer-locacao-geral" place="left" type="dark" effect="float">
                  <span>Na Síntese você confere os indicadores que <br />contribuíram para o risco da locação, o impacto destes <br />indicadores é o mesmo no grupo de inquilinos e de fiadores.</span>
                </ReactTooltip> */}
                {/* <ResumeText style={{padding: 0, marginTop: 5, color: '#152b4e', fontStyle: 'italic'}}>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de apontamentos do pesquisado.</ResumeText> */}
              </SyntethicColumn>
            || null}
            {/* {(hasParecerLoacaoInquilinos || hasParecerLoacaoFiadores) &&
              <ApplicableConcepts style={{paddingLeft: 14, paddingBottom: 6, marginTop: -14, display: 'flex', flexDirection: 'column', width: '100%'}} /> || null
            } */}
          </div>
          {(hasParecerLoacaoInquilinos || hasParecerLoacaoFiadores) &&
            <ResumeText style={{padding: 0, marginTop: 5, color: '#152b4e', fontStyle: 'italic', display: 'inline'}} className='bold-print wrapper-font-print'>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de apontamentos do pesquisado. <span style={{color: '#fe4d36'}}> {dataSingleReport.parecer_locacao.alerta_acoes_riogrande_do_sul}</span></ResumeText> || null
          }
          <br />
          <br />

          {!this.handleViewNewVersion() && sintesesPF.length &&
            <Fragment>
              <Title>PARECERES INDIVIDUAIS</Title>
              <IndividualSynthesis sinteses={sintesesPF} applicants={this.props.applicants} data={dataSingleReport} loading={reportPending} changeTab={this.props.changeTab} />
            </Fragment>
          || null}
          {sintesesPJ.length &&
            <Fragment>
              <Title className='color-black-print'>FC EMPRESAS</Title>
              <IndividualSynthesis sinteses={sintesesPJ} applicants={this.props.applicants} data={dataSingleReport} loading={reportPending} changeTab={this.props.changeTab} />
            </Fragment>
          || null}
        </MainContainer>
      </Fragment>
    );
  }
}