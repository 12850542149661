import React from "react";
import { BaseColumn } from "@bit/wavfichacerta.fichacerta.shared-styles.columns";
import { StyledCard, StyledRowMobile, StyledLineMobile, StyledTitleMobile, StyledCardBody, StyledHr } from "./styled";
import { formatToBRL } from '../../../../utils/formatter';

const ProtestTableCards = ({ lines }) => {
  return lines.map(line => {
    return (
      <StyledCard>
        <StyledCardBody>
          <StyledRowMobile>
            <BaseColumn xs="6" sm="6">
              <StyledTitleMobile>Data</StyledTitleMobile>
              <StyledLineMobile>{line.data}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="6" sm="6">
              <StyledTitleMobile>Valor</StyledTitleMobile>
              <StyledLineMobile>R$ {formatToBRL(line.valor)}</StyledLineMobile>
            </BaseColumn>
          </StyledRowMobile>
          <StyledHr/>
          <StyledRowMobile>
            <BaseColumn xs="6" sm="6">
              <StyledTitleMobile>Cartório</StyledTitleMobile>
              <StyledLineMobile>{line.cartorio}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="6" sm="6">
              <StyledTitleMobile>Cidade/UF</StyledTitleMobile>
              <StyledLineMobile>{line.cidade}</StyledLineMobile>
            </BaseColumn>
          </StyledRowMobile>
        </StyledCardBody>
      </StyledCard>
    );
  });
};

export default ProtestTableCards;
