import {
  CHANGE_STAGE,
  RESET_STATE,
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SAVE_RESET_PASSWORD_TOKEN,
  SAVE_EMAIL_TO_RESET_PASSWORD,
  SAVE_SET_PASSWORD_TOKEN,
  SET_PASSWORD,
  SET_PASSWORD_FAILED,
  SET_PASSWORD_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILED,
  LOGOUT_SUCCESS,
  USER_REGISTER_CONFIRMATION_SUCCESS,
  USER_REGISTER_CONFIRMATION_FAILED
} from '../actions/types';

import {
  onChangeStage,
  resetLogin,
  onLoginProccessing,
  onLoginSuccess,
  onLoginError,
  saveResetToken,
  saveEmailToResetPassword,
  saveSetPasswordToken,
  onSetPasswordRequest,
  onStePasswordFailed,
  onSetPasswordSuccess,
  onRefreshToken,
  onRefreshTokenFailed,
  onLogout,
  onRegisterConfirmationSuccess,
  onRegisterConfirmationFailed
} from './reducers';

const initialState = {
  stage: 'login',
  loginError: undefined,
  loginLoading: false,
  resetPasswordToken: undefined,
  setPasswordToken: undefined,
  emailToResetPassword: undefined,
  setPasswordLoading: false,
  setPasswordError: undefined,
  logged: false,
  userConfirmed: undefined,
  user: {
    perfil: {},
    token: {}
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_STAGE:
      return onChangeStage(state, payload);
    case RESET_STATE:
      return resetLogin(state);
    case LOGIN_REQUEST:
      return onLoginProccessing(state);
    case LOGIN_SUCCESS:
      return onLoginSuccess(state, payload);
    case LOGIN_FAILED:
      return onLoginError(state, payload);
    case SAVE_RESET_PASSWORD_TOKEN:
      return saveResetToken(state, payload);
    case SAVE_SET_PASSWORD_TOKEN:
      return saveSetPasswordToken(state, payload);
    case SAVE_EMAIL_TO_RESET_PASSWORD:
      return saveEmailToResetPassword(state, payload);
    case SET_PASSWORD:
      return onSetPasswordRequest(state);
    case SET_PASSWORD_FAILED:
      return onStePasswordFailed(state, payload);
    case SET_PASSWORD_SUCCESS:
      return onSetPasswordSuccess(state);
    case REFRESH_TOKEN_SUCCESS:
      return onRefreshToken(state, payload);
    case LOGOUT_SUCCESS:
      return onLogout(state);
    case REFRESH_TOKEN_FAILED:
      return onRefreshTokenFailed(state);
    case USER_REGISTER_CONFIRMATION_SUCCESS:
      return onRegisterConfirmationSuccess(state);
    case USER_REGISTER_CONFIRMATION_FAILED:
    return onRegisterConfirmationFailed(state);
    default:
      return state;
  }
};