import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getRequest, saveTenancy, resetForm } from './actions';
import { updateApplicantInfos, addApplicantToRequest } from '../actions';
import { getRequirements, createProductFcRenda } from '../../form/actions';
import { openModal } from '../../form/fieldModal/actions';
import { getInitialValues, getCustomer, getSendingStatus } from './selector';

import Header from '../../../../../components/Header';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { PageContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers'
import FormModalFields from '../../form/fieldModal/FormModalFields';
import Container from '../../form/Container';

import TenancyForm from '../../form/TenancySection/TenancyForm';
import tenancyFields from '../../form/TenancySection/fields';
import tenancyFieldsFcBasico from '../../form/TenancySection/fieldsFcBasico';
import { getTenancyObj } from '../../../../../utils/operational/requests/requestFormHelper';
import { getChangedValues } from '../../../../../utils/formHelper';

import RequestStepButton from '../../form/RequestStepButton';
import BlockNavigationContainer from '@bit/wavfichacerta.fichacerta.forms.block-navigation-container';
import { SubmitButton } from '../../../../../components/sign-up/signup.styles';
import { getAllValues } from '../../form/selectors';
import { Main, MainContainer } from './../RequestResume';

import Tour from 'reactour'
import axios from 'axios';

const Forms = ["tenancyForm"];

const steps = [
  {
    selector: '.Aluguel',
    content: <span>É possível <b>editar</b> o endereço do imóvel, valor do aluguel + encargos</span>,
  },
  {
    selector: '.Tipo',
    content: <span>Caso precise você pode <b>alterar</b> o tipo de imóvel.</span>,
  },
  {
    selector: '[data-testid="StepButton"]',
    content: <span>E lembre-se de <b>salvar</b> caso realize alguma alteração nas informações citadas.</span>,
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class TenancyEditForm extends PureComponent {

  constructor(props) {
    super(props);
    this.tenancyForm = React.createRef();
    this.container = React.createRef();

    this.state = {
      block: false,
      tourOpen: false,
      tourId: null
    }
  }

  componentDidMount() {
    const { location: { state }, getRequirements, getRequest } = this.props;

    if (state === undefined || (typeof state === "object" && state.request === undefined)) {
      this.props.history.goBack();
    }

    getRequirements();
    getRequest(state.request);
    this.getCheckOnboard()
    setTimeout(() => {
      this.triggerChangeEvent()
    }, 2000)
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'editar-locacao') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'editar-locacao'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'editar-locacao',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  /* método criado pra forçar event change por causa de bug causado no primeiro loader da página que
  está negando o evento e não alterando os valores quando selecionado parcialmente e
  apagado dos campos de valor monetário. */
  triggerChangeEvent = () => {
    const aluguel = document.getElementById('aluguel');
    const condominio = document.getElementById('condominio');
    const iptu = document.getElementById('iptu');
    if(aluguel) {
      aluguel.value = aluguel.value
      aluguel.dispatchEvent(new Event('change'));
    }
    if(condominio) {
      condominio.value = condominio.value
      condominio.dispatchEvent(new Event('change'));
    }
    if(iptu) {
      iptu.value = iptu.value
      iptu.dispatchEvent(new Event('change'));
    }
  }

  get changedValues() {
    if (this.props.tenancyForm) {
      const { changedValues, initialValues } = this.props.tenancyForm;

      return getChangedValues(initialValues, changedValues);
    }

    return null;
  }

  formatRentValues = (values) => {
    let newValues = {...values}
    if("aluguel" in newValues && !newValues.aluguel){
      newValues.aluguel = 0.00
    }

    if("condominio" in newValues && !newValues.condominio){
      newValues.condominio = 0.00
    }

    if("iptu" in newValues && !newValues.iptu){
      newValues.iptu = 0.00
    }

    return newValues
  }

  changeStep = () => {
    const { location: { state: { request, applicantId, moveForLocation, moveForLocationAddApplicant, productId, data } } } = this.props;
    const tenancyForm = this.formatRentValues(this.changedValues);
    if (tenancyForm !== undefined && typeof tenancyForm === 'object') {
      const requestObj = getTenancyObj(tenancyForm || {});
      this.container.current.unBlock();
      if(moveForLocation) {
        this.props.createProductFcRenda(data, request, applicantId, productId);
      }
      if(moveForLocationAddApplicant) {
        this.props.addApplicantToRequest(data, request);
      }
      this.props.saveTenancy(request, requestObj);
    }
  }

  getInitialValues = () => {
    const { initialValues } = this.props;
    if (typeof initialValues === 'object' && Object.keys(initialValues).length > 0) {
      const { aluguel, condominio, iptu, cpf_cnpj_proprietario, endereco } = initialValues;
      let enderecoObj = {
        cep: "",
        logradouro: "",
        bairro: "",
        cidade: "",
        uf: "",
        numero: "",
        complemento: ""
      };

      if (endereco !== undefined && Array.isArray(endereco) && endereco.length > 0) {
        enderecoObj = initialValues.endereco[0];
      }
      
      return {
        ...initialValues,
        ...enderecoObj,
        cpf_proprietario: cpf_cnpj_proprietario,
        aluguel: parseFloat(aluguel),
        condominio: parseFloat(condominio),
        iptu: parseFloat(iptu)
      }
    }
    return {};
  }

  goBack = () => {
    this.props.history.goBack();
  }

  reConsult = async () => {
    const { request, applicantId, applicant, reInclusion, selectedProduct } = this.props.location.state;
    const tenancyForm = this.changedValues;
    await this.setState({block: true})
    const requestObj = getTenancyObj(tenancyForm || {});
    this.props.updateApplicantInfos(request, applicantId, {pretendente: applicant}, reInclusion, selectedProduct);
    this.props.saveTenancy(request, requestObj, reInclusion);
  }

  renderStepButton = () => {
    const { reInclusion } = this.props.location.state
    if(reInclusion){
      return (
        <StyledRow justify="center" padding="1rem 0">
          <SubmitButton 
            onClick={this.props.history.goBack}
            customstyle={{ color: 'white', marginRight: '1rem' }}>
            Voltar
          </SubmitButton>
          <SubmitButton color="color_white" onClick={() => this.reConsult()}>
            Reconsultar
          </SubmitButton>
        </StyledRow>
      )
    }else{
      return (
        <RequestStepButton
          showBackButton
          editTenancy
          goBack={this.goBack}
          forms={Forms}
          step={Forms.length}
          sendRequestRegistration={this.changeStep}
          usedFields={tenancyFields}
          sending={this.props.requesting}
        />
      );
    }
  }

  areThereChangedValues = () => {
    if(this.props.location.state.reInclusion){
      return this.state.block
    }else{
      return this.changedValues === null;
    }
  }

  render() {
    if (Object.keys(this.props.initialValues).length === 0) {
      return null;
    }
    const {location: {state: {reInclusion, applicants, moveForLocation, moveForLocationAddApplicant}}} = this.props

    const breadcrumb = [
      { link: '', label: 'operacional'},
      { link: '/intranet/operacional/solicitacao', label: 'Locações' },
      {
        link: {
          pathname: '/intranet/operacional/solicitacao/resumo',
          state: {request: this.props.location.state.request}
        },
        label: 'resumo'
      }
    ];

    console.log('state1', this.state)
    console.log('state2', this.props)

    let products = [1];
    let soTemFcBasico = true
    applicants.map(pretendente => {
      if (pretendente.produtos) {
        let produtoFcReport = pretendente.produtos.ativo.filter(produto => produto.id === 1)
        let produtoFcEssencial = pretendente.produtos.ativo.filter(produto => produto.id === 3)
        let produtoFcEmpresa = pretendente.produtos.ativo.filter(produto => produto.id === 4)
        let produtoFcBasico = pretendente.produtos.ativo.filter(produto => produto.id === 5)
        let produtoFcCompletaPF = pretendente.produtos.ativo.filter(produto => produto.id === 7)
        let produtoFcCompletaPJ = pretendente.produtos.ativo.filter(produto => produto.id === 8)

        if(produtoFcEssencial.length || produtoFcReport.length || produtoFcEmpresa.length || produtoFcCompletaPF.length || produtoFcCompletaPJ.length) {
          soTemFcBasico = false
          console.log('entrei1', soTemFcBasico)
        }
      }
    });

    if(soTemFcBasico) {
      products = [5];
    }

    if(moveForLocation || moveForLocationAddApplicant) {
      products = [1];
    }

    return (
      <Main>
        <Header
          title="Editar Locação"
          actualLink="Editar Locação"
          subtitle={this.props.customer}
          breadcrumb={breadcrumb}
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <MainContainer>
          <BlockNavigationContainer
            ref={this.container}
            history={this.props.history}
            blockNavigation={() => true}
            modalMessage={reInclusion ? "Se você voltar vai perder as alterações feitas." : undefined}
          >
            <Container renderStepButton={this.renderStepButton}>
              <TenancyForm ref={this.tenancyForm} edit={true} initialValues={this.getInitialValues()} products={products} />
              <FormModalFields />
            </Container>
          </BlockNavigationContainer>
        </MainContainer>
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
        />
      </Main>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getRequest,
  getRequirements,
  openModal,
  resetForm,
  saveTenancy,
  updateApplicantInfos,
  createProductFcRenda,
  addApplicantToRequest
}, dispatch);

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  initialValues: getInitialValues(state),
  customer: getCustomer(state),
  tenancyForm: getAllValues(state, "tenancyForm"),
  requesting: getSendingStatus(state),
  login: state.login,
})

export default connect(mapStateToProps, mapDispatchToProps)(TenancyEditForm);
