import React from 'react';
import ReactTooltip from 'react-tooltip'

import TourTooltip from '../shared/TourTooltip';

const WhatsappButton = () => (
  <a href="https://api.whatsapp.com/send?phone=5521987346472" 
      class="whatsapp-button" 
      target="_blank">
      <img src="https://i.ibb.co/VgSspjY/whatsapp-button.png" alt="botão whatsapp"/>
      {window.location.pathname !== '/auth' &&
        <React.Fragment>
          <TourTooltip
            target="tooltip-button-whatsapp"
            style={{right: -5}}
          />
          <ReactTooltip id="tooltip-button-whatsapp" place="left" type="dark" effect="float">
            <span>Caso precise de orientação no uso <br /> da FC, ou mesmo dicas do nosso <br /> time, basta chamar um especialista<br /> FC por aqui.</span>
          </ReactTooltip>
        </React.Fragment>
      }
  </a>
);

export default WhatsappButton;
