import React, { Component } from 'react'

export default class Register extends Component {
  render() {
    return (
        <svg viewBox="0 0 124 124" className="nano">
        <polygon className="white" points="94.3,105.9 19.3,105.7 19.1,30.9 77.7,30.9 82.6,25.5 14.1,25.5 14.1,110.9 99.5,110.9 99.5,38.9 94.2,44.9 "/>
        <path className="white" d="M99.6,13.2c4.8,0.4,8.6,4,9.4,8.6l-4.4,4.9l-9.5-8.5l9.5,8.5l-9.5-8.5L99.6,13.2L99.6,13.2z"/>
        <polygon className="white" points="47.5,81.1 60.5,76.3 100.4,31.7 90.9,23.2 51.2,67.7 "/>
    </svg>
    )
  }
}
