import { createSelector } from 'reselect';

const getErrorFlag = state => state.requestResume.error;
export const getLoadingFlag = state => state.requestResume.isLoading;

export const getSuccessStatus = createSelector(
  [getErrorFlag, getLoadingFlag],
  (error, isLoading) => !isLoading && !error && error !== undefined
);


const addApplicantErrorFlag = state => state.requestResume.addApplicant.error;
export const addApplicantLoadingFlag = state => state.requestResume.addApplicant.isLoading;

export const addApplicantSuccessStatus = createSelector(
  [addApplicantErrorFlag, addApplicantLoadingFlag],
  (error, isLoading) => !isLoading && !error && error !== undefined
);

export const getTenancyType = (state) => {
  const { locacao } = state.requestResume.resume.infos;

  return locacao !== undefined ? locacao.tipo_imovel : '';
};

export const getSendingStatus = state => state.requestResume.editApplicant.sending;

export const loadingLiteEditApplicant = state => state.requestResume.currentApplicant === undefined;