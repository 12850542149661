import React, { PureComponent, Fragment, Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Field, Form } from 'formik';
import handler from '@bit/wavfichacerta.fichacerta.inputs-functions.input-handler';
import { fetchCEP } from '../../validation/actions';

import { text, select, composed, maskedText, textDisabled, cep } from '@bit/wavfichacerta.fichacerta.inputs.inputs';
import { SubtitleCard } from '../../../components/Customer/customerData.styled';
import { BaseColumn, HalfColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { ButtonForm } from '../../../components/shared/formStyles.styled';
import { CancelAction, ConfirmAction, RowActionButton } from '../../../components/shared/table/editableRow.style';
import {
  StyledRow,
  FormContentRow
} from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import Card from '@bit/wavfichacerta.fichacerta.cards.card';
import { getChangedValues } from '../../../utils/formHelper';

import CollapsableContainer from '../../../components/CollapsableContainer';
import {
  identificationFields,
  addressFields,
  aditionalContactsFirstFields,
  aditionalContactsSecondFields
} from './fieldsPF';
import {
  identificationFields as identificationPJ,
  addressFields as addressPJ,
  aditionalContactsFirstFields as aditionalContactsFirstFieldsPJ,
  aditionalContactsSecondFields as aditionalContactsSecondFieldsPJ,
  aditionalContactsThirdFields as aditionalContactsThirdFieldsPJ
} from './fieldsPJ';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../components/shared/TourTooltip';

import styled from 'styled-components';

const WrapperCard = styled(Card)`
  h5 {
    text-transform: initial;
  }
`;

class CustomerForm extends PureComponent {
  state = {
    selectedItem: {
      id: 3
    }
  }

  static defaultProps = {
    requirements: {}
  }

  // renderFields = (fields) => {
  //   const { requirements } = this.props;
  //   return fields.map((row, index) => (
  //     <StyledRow key={row.name} align="flex-start" justify="flex-start" customstyle={`margin-top: ${index > 0? ".5rem": "0"};`}>
  //       {
  //         row.fields.map((field) => {
  //           if (field.type === "select") {
  //             return handler({ select })({ ...field, options: requirements[field.name] });
  //           } else if (field.type === "cep") {
  //             const { fetchCEP } = this.props;
  //             return handler({ cep })({ ...field, fetchCEP });
  //           }
  //           return handler({ text, composed, maskedText, textDisabled })(field);
  //         })
  //       }
  //     </StyledRow>
  //   ));
  // }

  onConfirmField = (isChanged, values) => {
    if(!isChanged) {
      return null;
    }
    this.props.onSubmitFieldForm(values)
  }

  onCancelField = (value, fieldName, setFieldValue) => {
    if(!value) {
      return null;
    }
    setFieldValue(fieldName, value)
  }

  renderFields = (values, setFieldValue, fields) => {
    let initialText = 'Inquilino(s) considerado(s):';
    if(fields[0].name.includes('_fiadores')) {
      initialText = 'Fiador(es) considerado(s):';
    }
    // console.log('values11', values.locacao_residencial_inquilinos_parecer_nivel_1_aprovado)
    let keys = Object.keys(this.props.initialValues)
    let changedValues = {}
    keys.map(key => {
      if(this.props.initialValues[key] !== values[key]) {
        changedValues[key] = this.props.initialValues[key];
      }
    })

    return fields.map(field => {
      return(
        <div style={{marginBottom: 24, position: 'relative'}}>
          <label for={field.name} style={{display: 'block', fontSize: 12, fontWeight: 500, marginBottom: 2}}>{field.label}</label>
          <Field disabled={values.usar_recomendacao_customizada === 'false' ? true : false} id={field.name} as='textarea' name={field.name} maxlength={500} style={{height: 130, width: '95%', background: this.state.selectedItem.id == 3 || this.state.selectedItem.id == 9 ? false : '#e9ecef', textIndent: initialText == 'Fiador(es) considerado(s):' ? 198 : 208, fontSize: 15, cursor: values.usar_recomendacao_customizada === 'false' ? 'no-drop': null}}></Field>
          <span className={values[field.name].length === 500 ? 'headShake' : null} style={{fontSize: 14, marginRight: 0, bottom: -16, right: 24, position: 'absolute', color: values[field.name].length === 500 ? 'red' : null}}>{values[field.name].length}/500</span>
          <div style={{position: 'absolute', top: 23, left: 3, color: values.usar_recomendacao_customizada === 'false' ? '#545454' : 'fieldtext', backgroundColor: values.usar_recomendacao_customizada === 'false' ? 'transparent' : '#d8d8d8', fontSize: 15, cursor: values.usar_recomendacao_customizada === 'false' ? 'no-drop' : null}}>{initialText}</div>
          <div style={{position: 'absolute', top: 'calc(50% - 20px)', right: -20, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', width: 50, height: 50}}>
            <div onClick={() => this.onConfirmField(changedValues[field.name], {[field.name]: values[field.name]})} style={{height: 18, display: 'flex', cursor: 'pointer'}}>
              <ConfirmAction />
              {!changedValues[field.name] &&
                <div style={{background: 'gray', width: 18, height: 18, position: 'absolute', opacity: 0.7, borderRadius: '50%', cursor: 'no-drop'}}>
                </div>
              }
            </div>

            <div onClick={() => this.onCancelField(changedValues[field.name], field.name, setFieldValue)} style={{height: 18, display: 'flex', cursor: 'pointer'}}>
              <CancelAction />
              {!changedValues[field.name] &&
                <div style={{background: 'gray', width: 18, height: 18, position: 'absolute', opacity: 0.7, borderRadius: '50%', cursor: 'no-drop'}}>
                </div>
              }
            </div>
          </div>
        </div>
      )
    })
  }

  renderFieldsDefault = (fields) => {
    let initialText = 'Inquilino(s) considerado(s):';
    if(fields[0].name.includes('_fiadores')) {
      initialText = 'Fiador(es) considerado(s):';
    }
    return fields.map(field => {
      return(
        <div style={{marginBottom: 24, position: 'relative'}}>
          <label for={field.name} style={{display: 'block', fontSize: 12, fontWeight: 500, marginBottom: 2}}>{field.label}</label>
          <textarea disabled id={field.name} as='textarea' value={this.props.recomendacoesDefault[field.name]} maxlength={500} style={{height: 130, width: '100%', background: this.state.selectedItem.id == 3 || this.state.selectedItem.id == 9 ? false : '#e9ecef', textIndent: initialText == 'Fiador(es) considerado(s):' ? 198 : 208, cursor: 'no-drop', fontSize: 15}}></textarea>
          <div style={{position: 'absolute', top: 23, left: 3, color: '#545454', cursor: 'no-drop', fontSize: 15}}>{initialText}</div>
        </div>
      )
    })
  }

  renderTitleParecerFc = () => {
    return(
      <div>
        <span style={{position: 'relative'}}>
          Parecer FC
          <TourTooltip
            target="pareceres-parecer-fc"
            style={{right: -22, top: -14}}
          />
          <ReactTooltip id="pareceres-parecer-fc" place="top" type="dark" effect="float">
            <span>Para quem deseja manter os pareceres padrões da FC.</span>
          </ReactTooltip>
        </span>
        <hr />
      </div>
    )
  }

  renderTitleParecerCliente = () => {
    return(
      <div>
        <span style={{position: 'relative'}}>
          Parecer CLIENTE
          <TourTooltip
            target="pareceres-parecer-cliente"
            style={{right: -22, top: -14}}
          />
          <ReactTooltip id="pareceres-parecer-cliente" place="top" type="dark" effect="float">
            <span>Para customizar os pareceres de acordo com a sua política de aprovação.</span>
          </ReactTooltip>
        </span>
        <hr />
      </div>
    )
  }

  handleSwitchForAPI = (e, handleChange) => {
    const { sendSwitchForAPI } = this.props;
    const { value } = e.target;
    sendSwitchForAPI(value);
    handleChange(e);
  }
  
  render() {
    const { initialValues, master } = this.props;

    return (
      <StyledRow margin="3vh 0">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            await new Promise((r) => setTimeout(r, 500));
            // console.log(JSON.stringify(values, null, 2));
            // alert(JSON.stringify(values, null, 2));
            const { onSubmitForm } = this.props;
            onSubmitForm(values);
          }}
        >
          {({ values, handleChange, isSubmitting, setFieldValue }) => (
            <Fragment>
              <BaseColumn>
                <div className="dados-cadastrais">
                  <WrapperCard title="ORIENTAÇÕES" padding="1rem 1rem 0 1rem">
                    <CollapsableContainer open={false}>
                      {/* <h5>Residencial</h5> */}
                      <FormContentRow>
                        {/* {entity === "PF"? this.renderFields(identificationFields(master)) : this.renderFields(identificationPJ(master))} */}

                        
                        {/* <div style={{display: 'flex', flexDirection: 'column', padding: 15, boxShadow: '1px 0px 5px #CECECE'}}> */}
                          <p style={{fontSize: 15}}>- A customização dos pareceres de aprovação (PARECER DA LOCAÇÃO) estão disponíveis apenas para as análises de PESSOA FÍSICA (FC ESSENCIAL ou FC REPORT), tanto para LOCAÇÃO RESIDENCIAL, quanto para LOCAÇÃO NÃO RESIDENCIAL;</p>
                          <p style={{fontSize: 15}}>- As consultas de FC BASICO e de FC EMPRESA não contam com o motor de análise FC (Score e Risco FC e PARECER DA LOCAÇÃO), por esta razão não possuem a opção de customização dos pareceres;</p>
                          <p style={{fontSize: 15}}>- Todos os clientes têm a opção de manter ou retornar (à qualquer momento) para o retorno dos PARECERES DA LOCAÇÃO padronizados, oferecidos pela expertise FC;</p>
                          <p style={{fontSize: 15}}>- Apenas usuários MASTER do cliente poderão customizar os PARECERES DA LOCAÇÃO ou retornar para os PARECERES DA LOCAÇÃO padronizados, oferecidos pela expertise FC.</p>
                        {/* </div> */}
                      </FormContentRow>
                    </CollapsableContainer>
                  </WrapperCard>

                  {/* <label style={{display: 'block'}} for="cars">Utilizar:</label> */}
                  <Form style={{display: 'flex', marginBottom: 5, marginTop: 20}}>
                    <div style={{marginRight: 20}}>
                      {/* <label style={{marginBottom: 0, display: 'block', fontSize: 12, fontWeight: 500}} for="html"> Parecer FC</label> */}
                      <Field onChange={(e) => this.handleSwitchForAPI(e, handleChange)} type="radio" id="html" name="usar_recomendacao_customizada" value="false"></Field>
                      <label style={{marginLeft: 5, fontSize: 15}} for="html"> Manter</label><br />
                    </div>
                    <div>
                      {/* <label style={{marginBottom: 0, display: 'block', fontSize: 12, fontWeight: 500}} for="css"> Parecer CLIENTE</label> */}
                      <Field onChange={(e) => this.handleSwitchForAPI(e, handleChange)} type="radio" id="css" name="usar_recomendacao_customizada" value="true"></Field>
                      <label style={{marginLeft: 5, fontSize: 15}} for="css"> Editar</label><br />
                    </div>
                  </Form>

                  <WrapperCard title="LOCAÇÃO RESIDENCIAL – Pessoa Física – INQUILINOS" padding="1rem 1rem 0 1rem">
                    <CollapsableContainer open={false}>
                      {/* <h5>Residencial</h5> */}
                      <FormContentRow style={{alignItems: 'start'}}>
                        {/* {entity === "PF"? this.renderFields(identificationFields(master)) : this.renderFields(identificationPJ(master))} */}

                        
                        <div style={{display: 'flex', flexDirection: 'column', width: '45%', padding: 15, boxShadow: '1px 0px 5px #CECECE'}}>
                          {this.renderTitleParecerFc()}
                          <Form>
                            {this.renderFieldsDefault([
                              {
                                label: 'MUITO BAIXO ou BAIXO - Acima de 5,7x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_1_aprovado'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - De 2,9x a 5,6x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_1_aprovado_2'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - Abaixo de 2,9x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_1_incompativel'
                              },
                              {
                                label: 'MÉDIO - Acima de 2,9x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_3_aprovado'
                              },
                              {
                                label: 'MÉDIO - Abaixo de 2,9x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_3_incompativel'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Acima de 2,9x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_4_aprovado'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Abaixo de 2,9x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_4_incompativel'
                              },
                              {
                                label: 'MUITO ALTO - Qualquer renda',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_5_aprovado'
                              },
                            ])}
                          </Form>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', width: '45%', padding: 15, marginLeft: 80, boxShadow: '1px 0px 5px #CECECE'}}>
                          {this.renderTitleParecerCliente()}
                          <Form>
                            {this.renderFields(values, setFieldValue, [
                              {
                                label: 'MUITO BAIXO ou BAIXO - Acima de 5,7x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_1_aprovado'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - De 2,9x a 5,6x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_1_aprovado_2'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - Abaixo de 2,9x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_1_incompativel'
                              },
                              {
                                label: 'MÉDIO - Acima de 2,9x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_3_aprovado'
                              },
                              {
                                label: 'MÉDIO - Abaixo de 2,9x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_3_incompativel'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Acima de 2,9x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_4_aprovado'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Abaixo de 2,9x',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_4_incompativel'
                              },
                              {
                                label: 'MUITO ALTO - Qualquer renda',
                                name: 'locacao_residencial_inquilinos_parecer_nivel_5_aprovado'
                              },
                            ])}
                          </Form>
                        </div>
                      </FormContentRow>
                    </CollapsableContainer>
                  </WrapperCard>
                  
                  <WrapperCard title="LOCAÇÃO RESIDENCIAL – Pessoa Física – FIADORES" padding="1rem 1rem 0 1rem">
                    <CollapsableContainer open={false}>
                      <FormContentRow style={{alignItems: 'start'}}>
                        {/* {entity === "PF"? this.renderFields(addressFields(master)) : this.renderFields(addressPJ(master))} */}
                        
                        <div style={{display: 'flex', flexDirection: 'column', width: '45%', padding: 15, boxShadow: '1px 0px 5px #CECECE'}}>
                          {this.renderTitleParecerFc()}
                          <Form>
                            {this.renderFieldsDefault([
                              {
                                label: 'MUITO BAIXO ou BAIXO - Acima de 5,7x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_1_aprovado'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - De 2,9x a 5,6x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_1_aprovado_2'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - Abaixo de 2,9x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_1_incompativel'
                              },
                              {
                                label: 'MÉDIO - Acima de 2,9x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_3_aprovado'
                              },
                              {
                                label: 'MÉDIO - Abaixo de 2,9x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_3_incompativel'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Acima de 2,9x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_4_aprovado'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Abaixo de 2,9x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_4_incompativel'
                              },
                              {
                                label: 'MUITO ALTO - Qualquer renda',
                                name: 'locacao_residencial_fiadores_parecer_nivel_5_aprovado'
                              },
                            ])}
                          </Form>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', width: '45%', padding: 15, marginLeft: 80, boxShadow: '1px 0px 5px #CECECE'}}>
                          {this.renderTitleParecerCliente()}
                          <Form>
                            {this.renderFields(values, setFieldValue, [
                              {
                                label: 'MUITO BAIXO ou BAIXO - Acima de 5,7x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_1_aprovado'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - De 2,9x a 5,6x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_1_aprovado_2'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - Abaixo de 2,9x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_1_incompativel'
                              },
                              {
                                label: 'MÉDIO - Acima de 2,9x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_3_aprovado'
                              },
                              {
                                label: 'MÉDIO - Abaixo de 2,9x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_3_incompativel'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Acima de 2,9x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_4_aprovado'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Abaixo de 2,9x',
                                name: 'locacao_residencial_fiadores_parecer_nivel_4_incompativel'
                              },
                              {
                                label: 'MUITO ALTO - Qualquer renda',
                                name: 'locacao_residencial_fiadores_parecer_nivel_5_aprovado'
                              },
                            ])}
                          </Form>
                        </div>
                      </FormContentRow>
                    </CollapsableContainer>
                  </WrapperCard>

                  <WrapperCard title="LOCAÇÃO RESIDENCIAL – Pessoa Física – FIADORES DE ESTUDANTE" padding="1rem 1rem 0 1rem" style={{position: 'relative'}}>
                    <CollapsableContainer open={false}>
                      <FormContentRow style={{alignItems: 'start'}}>
                        <div style={{display: 'flex', flexDirection: 'column', width: '45%', padding: 15, boxShadow: '1px 0px 5px #CECECE'}}>
                          {this.renderTitleParecerFc()}
                          <Form>
                            {this.renderFieldsDefault([
                              {
                                label: 'MUITO BAIXO ou BAIXO - Acima de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - Abaixo de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_1_incompativel'
                              },
                              {
                                label: 'MÉDIO - Acima de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_3_aprovado'
                              },
                              {
                                label: 'MÉDIO - Abaixo de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_3_incompativel'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Acima de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_4_aprovado'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Abaixo de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_4_incompativel'
                              },
                              {
                                label: 'MUITO ALTO - Qualquer renda',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_5_aprovado'
                              },
                            ])}
                          </Form>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', width: '45%', padding: 15, marginLeft: 80, boxShadow: '1px 0px 5px #CECECE'}}>
                          {this.renderTitleParecerCliente()}
                          <Form>
                            {this.renderFields(values, setFieldValue, [
                              {
                                label: 'MUITO BAIXO ou BAIXO - Acima de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - Abaixo de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_1_incompativel'
                              },
                              {
                                label: 'MÉDIO - Acima de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_3_aprovado'
                              },
                              {
                                label: 'MÉDIO - Abaixo de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_3_incompativel'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Acima de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_4_aprovado'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Abaixo de 3,9x',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_4_incompativel'
                              },
                              {
                                label: 'MUITO ALTO - Qualquer renda',
                                name: 'locacao_residencial_fiadores_estudante_parecer_nivel_5_aprovado'
                              },
                            ])}
                          </Form>
                        </div>
                      </FormContentRow>
                    </CollapsableContainer>
                  </WrapperCard>

                  


                  <WrapperCard title="LOCAÇÃO NÃO RESIDENCIAL – Pessoa Física – INQUILINOS" padding="1rem 1rem 0 1rem">
                    <CollapsableContainer open={false}>
                      {/* <h5>Residencial</h5> */}
                      <FormContentRow style={{alignItems: 'start'}}>
                        {/* {entity === "PF"? this.renderFields(identificationFields(master)) : this.renderFields(identificationPJ(master))} */}

                        
                        <div style={{display: 'flex', flexDirection: 'column', width: '45%', padding: 15, boxShadow: '1px 0px 5px #CECECE'}}>
                          {this.renderTitleParecerFc()}
                          <Form>
                            {this.renderFieldsDefault([
                              {
                                label: 'MUITO BAIXO ou BAIXO - Acima de 5,7x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - De 2,9x a 5,6x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado_2'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_1_incompativel'
                              },
                              {
                                label: 'MÉDIO - Acima de 2,9x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_3_aprovado'
                              },
                              {
                                label: 'MÉDIO - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_3_incompativel'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Acima de 2,9x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_4_aprovado'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_4_incompativel'
                              },
                              {
                                label: 'MUITO ALTO - Qualquer renda',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_5_aprovado'
                              },
                            ])}
                          </Form>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', width: '45%', padding: 15, marginLeft: 80, boxShadow: '1px 0px 5px #CECECE'}}>
                          {this.renderTitleParecerCliente()}
                          <Form>
                            {this.renderFields(values, setFieldValue, [
                              {
                                label: 'MUITO BAIXO ou BAIXO - Acima de 5,7x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - De 2,9x a 5,6x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado_2'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_1_incompativel'
                              },
                              {
                                label: 'MÉDIO - Acima de 2,9x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_3_aprovado'
                              },
                              {
                                label: 'MÉDIO - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_3_incompativel'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Acima de 2,9x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_4_aprovado'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_4_incompativel'
                              },
                              {
                                label: 'MUITO ALTO - Qualquer renda',
                                name: 'locacao_nao_residencial_inquilinos_parecer_nivel_5_aprovado'
                              },
                            ])}
                          </Form>
                        </div>
                      </FormContentRow>
                    </CollapsableContainer>
                  </WrapperCard>
                  
                  <WrapperCard title="LOCAÇÃO NÃO RESIDENCIAL – Pessoa Física – FIADORES" padding="1rem 1rem 0 1rem">
                    <CollapsableContainer open={false}>
                      <FormContentRow style={{alignItems: 'start'}}>
                        {/* {entity === "PF"? this.renderFields(addressFields(master)) : this.renderFields(addressPJ(master))} */}
                        
                        <div style={{display: 'flex', flexDirection: 'column', width: '45%', padding: 15, boxShadow: '1px 0px 5px #CECECE'}}>
                          {this.renderTitleParecerFc()}
                          <Form>
                            {this.renderFieldsDefault([
                              {
                                label: 'MUITO BAIXO ou BAIXO - Acima de 5,7x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - De 2,9x a 5,6x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado_2'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_1_incompativel'
                              },
                              {
                                label: 'MÉDIO - Acima de 2,9x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_3_aprovado'
                              },
                              {
                                label: 'MÉDIO - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_3_incompativel'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Acima de 2,9x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_4_aprovado'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_4_incompativel'
                              },
                              {
                                label: 'MUITO ALTO - Qualquer renda',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_5_aprovado'
                              },
                            ])}
                          </Form>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', width: '45%', padding: 15, marginLeft: 80, boxShadow: '1px 0px 5px #CECECE'}}>
                          {this.renderTitleParecerCliente()}
                          <Form>
                            {this.renderFields(values, setFieldValue, [
                              {
                                label: 'MUITO BAIXO ou BAIXO - Acima de 5,7x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - De 2,9x a 5,6x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado_2'
                              },
                              {
                                label: 'MUITO BAIXO ou BAIXO - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_1_incompativel'
                              },
                              {
                                label: 'MÉDIO - Acima de 2,9x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_3_aprovado'
                              },
                              {
                                label: 'MÉDIO - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_3_incompativel'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Acima de 2,9x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_4_aprovado'
                              },
                              {
                                label: 'ACIMA DA MÉDIA - Abaixo de 2,9x',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_4_incompativel'
                              },
                              {
                                label: 'MUITO ALTO - Qualquer renda',
                                name: 'locacao_nao_residencial_fiadores_parecer_nivel_5_aprovado'
                              },
                            ])}
                          </Form>
                        </div>
                      </FormContentRow>
                    </CollapsableContainer>
                  </WrapperCard>
                </div>

              </BaseColumn>
            
              <StyledRow margin="0 0 2vh 0">
                {master && <Form><ButtonForm disabled={isSubmitting}>{isSubmitting}Salvar Alterações</ButtonForm></Form>}
              </StyledRow>
            </Fragment>
          )}
        </Formik>
      </StyledRow>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ fetchCEP }, dispatch);

const mapStateToProps = (state) => ({
  requirements: state.requirements,
  changedValues: state.form.customerForm !== undefined? state.form.customerForm.values : {},
  master: state.login.user.usuario.master,
  isSaving: state.customerData.isSaving
});

// const Form = reduxForm({
//   form: 'customerForm',
//   enableReinitialize: true
// })(CustomerForm);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);
