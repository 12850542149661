import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getRequirements } from '../../form-requirements/actions'
import { registerEmployer, getUserInfo, saveUser, resetForm } from './actions'
import { getInitialValues } from './selector'

import Header from '../../../components/Header'
import UsersFormWrapper from './components/UsersFormWrapper'
import { userDataExtractor } from '../../../utils/extractors/CustomerExtractor'
import { getChangedValues } from '../../../utils/formHelper'

import UsersCreditBar from './../components/UsersCreditBar'

import UsersCreateCancelModal from './modals/UsersCreateCancelModal'

import Tour from 'reactour'
import axios from 'axios'

const steps = [
  {
    selector: 'table tbody',
    content: 'Nesta tela é possível observar os dados cadastrais de cada usuário.',
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

const PageContainer = styled.div`
  width: calc(100% - 65px);
  float: right;

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 42px;
  }
`

const MainContainer = styled.main`
  width: 81%;
  min-width: 1177px;
  margin: 0 auto;

  @media (max-width: 1255px) {
    width: 98%;
    min-width: 98%;
  }
`

class UsersForm extends PureComponent {
  state = {
    usersCreateCancelModal_isOpen: false,
    tourOpen: false,
    tourId: null
  }

  static getDerivedStateFromProps(props) {
    const { match: { params }, requesting, errorSaving } = props;

    if (params.process === "adicionar" || params.process === "editar") {
      if (!requesting && errorSaving === false) {
        props.history.replace('/intranet/cadastro/usuarios', { process: params.process });
      }
    }

    return null;
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    if (params.process !== "adicionar" && params.process !== "editar") {
      this.props.history.goBack();
    }

    if (params.process === "editar") {
      if (params.id !== undefined) {
        // Get specific user
        this.props.getUserInfo(params.id);
      } else {
        this.props.history.goBack();
      }
    }

    this.props.getRequirements();
    this.getCheckOnboard();
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  getCheckOnboard = async () => {
    const { match: { params } } = this.props;
    //TODO: caso coloque tour na página de ADICIONAR USUÁRIO retirar esse if
    if (params.process !== "editar") {
      return;
    }

    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'usuarios-individual') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'usuarios-individual'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    if(this.state.tourId) {
      return;
    }

    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'usuarios-individual',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  submitForm = () => {
    const { initialValues, changedValues, match: { params } } = this.props;
    if (params.process === "adicionar" && changedValues !== undefined) {
      const values = userDataExtractor(changedValues);
      this.props.registerEmployer(values);
    }

    if (params.process === "editar" && changedValues !== undefined) {
      const { usuario: { id } } = this.props.userData;
      const values = getChangedValues(initialValues, changedValues);
      const extractedObj = userDataExtractor(values);
      this.props.saveUser(id, extractedObj);
    }
  }

  disableSubmit = () => {
    const { valid, requesting, match: { params }, initialValues, changedValues } = this.props;
    const values = getChangedValues(initialValues, changedValues);
    if (!valid || requesting || (params.process === "editar" && values !== null && typeof values === "object" && Object.keys(values).length === 0)) {
      return true;
    }

    return false;
  }

  getHeaderTitle = () => {
    const { match: { params } } = this.props;
    if (params.process === "editar") {
      const { userData } = this.props;
      if (Object.keys(userData).length > 0) {
        return userData.pessoa.nome;
      }
    }

    return "Adicionar usuário";
  }

  handleCancelModal = () => {
    this.setState({usersCreateCancelModal_isOpen: !this.state.usersCreateCancelModal_isOpen})
  }

  render() {
    const { match: { params }, initialValues, changedValues, userData, idUser, typeUser } = this.props;
    if (params.process === "editar" && Object.keys(initialValues).length === 0) {
      return null;
    }
    let typeUserEdit = undefined;
    console.log('cvs1', typeUser)

    // Check if the logged user is the same of the form
    let loggedUser = false;

    if (params.process === "editar") {
      loggedUser = userData.usuario.id === idUser;
      typeUserEdit = userData.funcionario.master? "MASTER" : "ADICIONAL";
    }

    const formValues = getChangedValues(initialValues, changedValues);

    return (
      <PageContainer>
        <Header
          title={this.getHeaderTitle()}
          openTourCallback={(call) => this.setState({tourOpen: call})}
          forceRenderButtonTour={params.process === 'editar' ? true : false}
        />
        <MainContainer style={{paddingTop: 16}}>
          {
            window.innerWidth <= 800 && params.process === "editar" &&
              <div style={{borderBottom: '1px solid #0000001a', paddingBottom: 25, marginBottom: 30}}>
                <h6 style={{fontSize: 12, textAlign: 'center', fontWeight: 400, color: '#7f7f7f', marginTop: 10}}>LIMITE DISPONÍVEL</h6>
                <UsersCreditBar
                  users={this.props.userData}
                />
              </div>
          }
          <UsersFormWrapper
            changedValues={changedValues}
            onGoBack={() => this.props.history.goBack()}
            onSubmit={this.submitForm}
            submitDisabled={this.disableSubmit()}
            editMode={params.process === "editar"}
            isMaster={this.props.typeUser === "MASTER"}
            isLoggedUser={loggedUser}
            typeUserEdit={typeUserEdit}
            typeUser={typeUser}
            formValues={formValues}
            handleCancelModal={this.handleCancelModal}
          />
        </MainContainer>
        <UsersCreateCancelModal
          isOpen={this.state.usersCreateCancelModal_isOpen}
          closeModal={this.handleCancelModal}
          onGoBack={this.props.history.goBack}
        />
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
        />
      </PageContainer>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match: { params } } = ownProps;

  //New user
  if (params.process === "adicionar") {
    return {
      initialValues: { credit: 0, total: "0,00" },
      changedValues: state.form.customerUsersForm !== undefined? state.form.customerUsersForm.values : {},
      requesting: state.employerForm.requesting,
      errorSaving: state.employerForm.error,
      typeUser: state.login.user.usuario.master? "MASTER" : "ADICIONAL",
    }
  }

  //Edit user
  const initialValues = getInitialValues(state);
  return {
    userData: state.employerForm.userData.data,
    initialValues,
    changedValues: state.form.customerUsersForm !== undefined? state.form.customerUsersForm.values : {},
    typeUser: state.login.user.usuario.master? "MASTER" : "ADICIONAL",
    idUser: state.login.user.usuario.id,
    requesting: state.employerForm.requesting,
    errorSaving: state.employerForm.error,
    login: state.login,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getRequirements,
  registerEmployer,
  getUserInfo,
  saveUser,
  resetForm
}, dispatch)

const Form = reduxForm({
  form: 'customerUsersForm',
  enableReinitialize: true
})(UsersForm);

export default connect(mapStateToProps, mapDispatchToProps)(Form);
