import moment from 'moment'

export const isUserAlreadyLogged = state => {
    const { login: { logged, user: { token: { access_token, expire_token } } } } = state
    if (typeof expire_token === 'string') {
        console.log('entered')
        var expiresOn
        try { expiresOn = moment(JSON.parse(expire_token)).format() }
        catch (e) { expiresOn = moment(expire_token).format() }
    }
    else var expiresOn = expire_token
    
    console.log('expiresOn', expiresOn)
    console.log('expire_token', expire_token)
    console.log('acess_token', access_token)
    console.log('condition:', logged && access_token !== undefined && expiresOn > moment().format())
    console.log('logged', logged)
    console.log('current date', moment().format())

    return logged && access_token !== undefined;
}
