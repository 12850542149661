import * as types from '../actions/types';
import {
  onOpenModal,
  onCloseModal,
  onOpenImportantFieldsModal,
  onCloseImportantFieldsModal
} from './reducers';

const initialState = {
  isOpen: false,
  fields: [],
  importantFields: {
    isOpen: false,
    fields: []
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.OPEN_MODAL:
      return onOpenModal(state, payload);
    case types.CLOSE_MODAL:
      return onCloseModal(state);
    case types.OPEN_IMPORTANT_FIELDS_MODAL:
      return onOpenImportantFieldsModal(state, payload);
    case types.CLOSE_IMPORTANT_FIELDS_MODAL:
      return onCloseImportantFieldsModal(state);
    default:
      return state;
  }
}
