import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import { PrimaryButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { closeModal } from './actions';
import { Container, ListModal, SpanTitle, ContainerButton } from './formModalFields.styled';

class FormModalFields extends PureComponent {

  renderFieldsName = () => {
    const { fields } = this.props;
    if (Array.isArray(fields) && fields.length > 0) {
      return fields.map((field, idx) => <li key={idx}>{field}</li>);
    }
    return null;
  }

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        size="md"
        onClose={this.props.closeModal}
      >
        <Container>
          <SpanTitle data-testid="SpanTitle">Preencha corretamente os seguintes campos obrigatórios:</SpanTitle>
          <ListModal>
            {this.renderFieldsName()}
          </ListModal>
        </Container>
        <ContainerButton onClick={this.props.closeModal}>
          <PrimaryButton>{'Fechar'}</PrimaryButton>
        </ContainerButton>
      </ModalContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeModal
}, dispatch);

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  fields: state.modalRequestFields.fields,
  isOpen: state.modalRequestFields.isOpen
})

export default connect(mapStateToProps, mapDispatchToProps)(FormModalFields);
