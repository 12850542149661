import React, { PureComponent } from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { HalfColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  ContainerGray,
  AnalythicList,
  ColoredSpan,
  AnalythicLine,
  LineTitle,
} from '../../analythic.styled';
import { ColoredColumn, TableTitle, Line } from '../styled';

import { pendingPaymentResume, getResultObj } from '../products/fcAnalise';
import Table from './Table';
import FinancialTableCards from '../FinancialTableCards';
import { restrictions } from '../../../../../pages/operational/request/report/singleReport/actions';

class FinancialConstraints extends PureComponent {
  constructor(props){
    super(props);

    this.restrictions = React.createRef();
    this.state = {
      isMobile: false
    }
  }

  

  componentDidMount(){
    this.props.restrictions(this.restrictions.current);
    this.setState({
      isMobile: window.matchMedia('(max-width: 800px)').matches
    });
  }

  renderDataLine = ({ title, data }) => (
    <AnalythicLine key={(title + data)}>
      <LineTitle maxWidth="205px" style={{color: '#000'}}>{title}</LineTitle>
      <ColoredSpan className='ignore-css-print bold-print' color='color_brown'>{data}</ColoredSpan>
    </AnalythicLine>
  );

  renderResumeLines = (keys) => {
    const dataGiven = pendingPaymentResume(this.props.dataSource, keys);
    return dataGiven.map((line) => this.renderDataLine(line));
  };

  render(){


    const source = getResultObj(this.props.dataSource, 'restricoes_financeiras');

    const details = this.state.isMobile ? <FinancialTableCards lines={(source !== undefined && "pendencias" in source) && source.pendencias.detalhes} /> : <Table lines={(source !== undefined && "pendencias" in source) && source.pendencias.detalhes} pretendente={this.props.pretendente} />

    if (source !== undefined && "pendencias" in source && source.pendencias.info !== 'CONSTA') {
      return null
      // return (
      //   <ContainerGray margin="0 0 8px 0" ref={this.restrictions} >
      //     <StyledRow customStyle="height: 27px">
      //       <HalfColumn>
      //         <TableTitle>PENDÊNCIAS FINANCEIRAS</TableTitle>
      //       </HalfColumn>
      //       <HalfColumn>
      //         <TableTitle>NADA CONSTA</TableTitle>
      //       </HalfColumn>
      //     </StyledRow>
      //   </ContainerGray>
      // )
    }

    return (
      <ColoredColumn>
        <TableTitle style={{color: '#000'}}>PENDÊNCIAS FINANCEIRAS</TableTitle>
        <StyledRow align="initial" margin="0 0 .5rem 0" className='font-13-print'>
          <HalfColumn>
            <ContainerGray ref={this.restrictions} >
              <AnalythicList>
                {this.renderResumeLines(['total_ocorrencias', 'valor_total_pendencia'])}
              </AnalythicList>
            </ContainerGray>
          </HalfColumn>
          <HalfColumn>
            <ContainerGray>
              <AnalythicList>
                {this.renderResumeLines(['data_primeira_ocorrencia', 'data_ultima_ocorrencia'])}
              </AnalythicList>
            </ContainerGray>
          </HalfColumn>
        </StyledRow>
        <Line />
        <TableTitle style={{color: '#000'}}>DETALHAMENTO (MAIS RECENTES - ATÉ 10)</TableTitle>
          {details}
      </ColoredColumn>
    )
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  restrictions
}, dispatch)

export default connect(null, mapDispatchToProps)(FinancialConstraints);