import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  width: ${props => props.width || '100%'};
  enable-background: new 0 0 512 512;

  & > circle { fill: ${props => props.color ? props.color : props.theme.color_gray}; }
`;

export default (props) => (
  <SVG {...props}x="0px" y="0px" viewBox="0 0 591 591">
    <circle cx="295.5" cy="295.5" r="295.5"/>
  </SVG>
)
