import React, { PureComponent } from 'react';

import Card from '@bit/wavfichacerta.fichacerta.cards.card';
import CollapsableContainer from '../../../components/CollapsableContainer';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { InsuranceLine, ColumnInsurance, InsuranceTitle } from './insurance.styled';
import insuranceFields from './insuranceFields';

export default class InsuranceSection extends PureComponent {

  render() {
    return (
      <StyledRow margin="1rem 0 0 0">
        <Card title="Seguros">
          <CollapsableContainer open={false}>
            <InsuranceLine>
              <ColumnInsurance xs={12} sm={12} md={4} lg={4} xl={4} color="color_gray-white" padding="16px">
                {insuranceFields.responsavel_comercial}
              </ColumnInsurance>
            </InsuranceLine>

            <InsuranceLine margin="1rem 0 0 0">
              <ColumnInsurance xs={12} sm={12} md={3} lg={3} xl={3}>
                <InsuranceTitle>Seguro Fiança</InsuranceTitle>
              </ColumnInsurance>
              <ColumnInsurance xs={12} sm={12} md={9} lg={9} xl={9} padding="8px">
                {insuranceFields.obs_fianca}
              </ColumnInsurance>
            </InsuranceLine>

            <InsuranceLine color="color_gray-white">
              <ColumnInsurance xs={12} sm={12} md={3} lg={3} xl={3}>
                <InsuranceTitle>Seguro Imobiliário</InsuranceTitle>
              </ColumnInsurance>
              <ColumnInsurance xs={12} sm={12} md={9} lg={9} xl={9} padding="8px">
              {insuranceFields.obs_imobiliario}
              </ColumnInsurance>
            </InsuranceLine>

            <InsuranceLine >
              <ColumnInsurance xs={12} sm={12} md={3} lg={3} xl={3}>
                <InsuranceTitle>Título de Capitalização</InsuranceTitle>
              </ColumnInsurance>
              <ColumnInsurance xs={12} sm={12} md={9} lg={9} xl={9} padding="8px">
              {insuranceFields.obs_titulo}
              </ColumnInsurance>
            </InsuranceLine>
          </CollapsableContainer>
        </Card>
      </StyledRow>
    );
  }
}

