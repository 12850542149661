import styled from 'styled-components';
import { Table } from 'reactstrap';
import ScaleButton from '../buttons/ScaleButton';
import { StyledRow } from '../../shared/sharedStyles.styles'


export const HeaderTitleContainer = styled.div`
  width: ${props => props.width || 'auto'};
  border-top: 0;
  position: relative;
  padding-bottom: .3rem;
  border-bottom: 2px solid ${props => {
    if (props.border) {
      return props.theme[props.color] || props.theme.color_gray
    }

    return props.theme.color_white;
  }};
  text-align: ${props => props.align || 'left'};
  padding-left: ${props => props.paddingLeft || '0'};
  padding-right: 0;
`;

export const HeaderTitle = styled.span`
  font-size: .94rem;
  color: ${props => props.theme[props.color || 'color_gray']};
  padding: 0;
  font-weight: bold;
`;

export const TableBody = styled.div`
  width: ${props => props.width || 'auto'} !important;
  margin-bottom: 10px;

  ${StyledRow}:nth-child(even) {
    background-color: ${props => props.theme['color_light-gray']};
  }

  & div.col {
    padding-left: 0 !important;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: ${props => props.width || 'auto'};
`;

export const TableContainer = styled.table`
  width: ${props => props.width || 'auto'};
  height: ${props => props.height};
  overflow-y: scroll;
  border-bottom: 2px solid ${props => props.theme.color_gray};
  padding-bottom: 20px;
`;

export const StyledTable = styled(Table)`
  height: auto;
  max-height: 45vh;
  width: 100% !important;
  overflow-y: scroll;
  overflow-x: hidden;
  color: ${props => props.theme.color_gray};

  &:focus {
    outline: none
  }

  tbody {
    display: block;
    height: auto;
    max-height: ${props => props.maxSize === "default" ? '64vh' : props.maxSize};
    overflow-y: auto;
    overflow-x: hidden;
  }

  table-layout: fixed !important;
  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  & thead {
    ${props => {
    if (props.items >= 9) {
      return `width: calc(100% - 1em);`;
    } else {
      return `width: 100%;`;
    }
  }}
    border-bottom: 2px solid ${props => props.theme.color_gray};
    tr {
      th {
        font-size: .94rem;
        border-top: 0;
        border-color: ${props => props.theme.color_gray};
        position: relative;
        padding-bottom: .3rem;
      }
    }
  }
`;

export const StyledTableForReport = styled(Table)`
  height: auto;
  max-height: 45vh;
  width: 100% !important;
  overflow-y: scroll;
  overflow-x: hidden;
  color: ${props => props.theme.color_gray};

  &:focus {
    outline: none
  }

  tbody {
   
  }

  thead, tbody tr {
    
  }

  & thead {
    border-bottom: .5px solid ${props => props.theme.color_gray};
    tr {
      th {
        font-size: .85rem !important;
        border-top: 0;
        border-color: ${props => props.theme.color_gray};
        position: relative;
        padding-bottom: .3rem !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        font-weight: 400 !important;
      }
    }
  }
`;

export const StyledTableBodyForReport = styled.tbody`
  border-bottom: 1px solid ${props => props.theme.color_gray};
  & td {
    height: 36px !important;
    font-size: .8rem !important;
    font-weight: 400 !important;
    border-top: 0;
    vertical-align: middle;
    position: relative;
    z-index: 0;
    padding: 10px !important;
  }
`;

export const NameCell = styled.div`
  min-width: 400px !important;
  padding-left: 10px !important;
  text-transform: uppercase;
  cursor: pointer;
`;

export const NameCellMediumSize = styled.div`
  min-width: 250px !important;
  padding-left: 10px !important;
`;

export const NameCellSmallSize = styled.div`
  min-width: 180px !important;
  padding-left: 10px !important;
  text-transform: uppercase;
`;

export const AccessCell = styled.div`
  min-width: 40px !important;
  padding: ${props => props.padding || 0} !important;
`;

export const TypeCell = styled.div`
  min-width: 30px !important;
  text-align: left;
  padding-left: 5px;
`;

export const ProfileCell = styled.div`
  min-width: 220px;
  padding-left: .5rem;
`;

export const DescriptionCell = styled.div`
  width: 300px;
  padding-left: .5rem;
`;

export const CategoryCell = styled.div`
  min-width: 150px;
  padding-left: .5rem;
`;

export const EmployeeTypeCell = styled(ScaleButton)`
  min-width: 100px !important;
  text-align: center;
  border: 1px solid;
  padding: 5px 5px;
  font-size: .85rem;
  font-weight: bold;
  margin: auto 5px;

  &:focus{
    outline: none;
  }

  ${props => {
    if (props.status === 'MASTER') {
      return `
        border-color: ${props.theme.color_purple};
        color: ${props.theme.color_purple};
      `;
    }

    return `
      border-color: ${props.theme.color_green};
      color: ${props.theme.color_green};
    `;

  }}
`;

export const StatusCell = styled(ScaleButton)`
  width: ${props => props.width || 'auto'}!important;
  min-width: 110px !important;
  text-align: center;
  border: 1px solid;
  padding: 5px 5px;
  font-size: .85rem;
  font-weight: bold;
  margin: auto 5px;
  
  &:focus{
    outline: none;
  }

  ${props => {
    if (props.status === 'ATIVO') {
      return `
        border-color: ${props.theme.color_green};
        color: ${props.theme.color_green};
      `;
    }

    if (props.status === 'INATIVO') {
      return `
        border-color: ${props.theme.color_grey};
        color: ${props.theme.color_grey};
      `;
    }

    if (props.status === 'SUSPENSO') {
      return `
        border-color: ${props.theme['color_orange-dark']};
        color: ${props.theme['color_orange-dark']};
      `;
    }

    if (props.status === 'BLOQUEADO') {
      return `
        border-color: ${props.theme.color_red};
        color: ${props.theme.color_red};
      `;
    }

  }}
`;

export const ConditionCell = styled.div`
  min-width: 100px !important;
  padding-left: 10px;
  text-align: left;
`;

export const FunctionCell = styled.div`
  min-width: 100px !important;
  text-align: left;
  padding-left: 4px !important;
  text-transform: uppercase;
`;

export const IconCell = styled(ScaleButton)`
  width: ${props => props.size || '50px'} !important;
  padding: ${props => props.padding || '0'};
  text-align: center;
  border: none;
  background-color: transparent;
  outline: none;

  &:focus{
    outline: none;
  }
  
  ${(props) => {
    const { getMedias: { laptops, iphones, desktops } } = props.theme;
    return `
      ${laptops(`${props.margin ? 'margin-left: 2rem;' : ''}`)``}
      ${iphones(`${props.margin ? 'margin-left: 1rem;' : ''}`)``}
      ${desktops(`${props.margin ? 'margin-left: 2rem;' : ''}`)``}
    `;
  }};
`;

export const RegisterCell = styled.div`
  padding-left: 10px;
  text-align: left;
`;


export const StyledTableBody = styled.tbody`
  border-bottom: 2px solid ${props => props.theme.color_gray};
  overflow-y: scroll;
  overflow-x: hidden;
  & td {
    height: 2.8rem !important;
    font-size: .8rem;
    font-weight: 400;
    border-top: 0;
    vertical-align: middle;
    position: relative;
    z-index: 0;
    padding: 1px 0 1px 0;
  }

  ${props => {
    if (props.loading) {
      return `
        overflow-y: hidden !important;
        min-height: 35vh;
        max-height: 64vh;
      `;
    }
  }}
`;

export const Th = styled.th`
  width: ${props => `${props.width} !important` || 'auto'};
  font-size: .94rem;
  border-top: 0;
  border-color: ${props => props.theme[props.color] || props.theme.color_gray} !important;
  position: relative;
  padding-bottom: .3rem;
  border-bottom: 0;
  text-align: ${props => props.align || 'left'};
  padding-left: ${props => props.paddingLeft || '0'} !important;
  padding-right: 0 !important;
`;

export const ThCentered = styled.th`
  font-size: .94rem;
  border-top: 0;
  position: relative;
  padding-bottom: .3rem;
  border-bottom: 1;
  border-color: ${props => props.theme[props.color] || props.theme.color_gray} !important;
  text-align: center;
  width: ${props => `${props.width} !important` || 'auto'};
  padding-left: ${props => props.paddingLeft || '0'} !important;
  padding-right: 0 !important;
`;

export const Td = styled.td`
  width: ${props => `${props.width} !important` || 'auto'};
  font-size: .8rem;
  font-weight: 400;
  border-top: 0;
  vertical-align: middle;
  position: relative;
  z-index: 0;
  padding: 5px 0 5px 0;
  border-right: ${props => props.theme[props.bordercolor || 'color_white']} solid .1rem;
  text-align: ${props => props.align || 'left'};
`;

export const ContainerCell = styled.div`
  width: ${props => props.width || '11rem'};
  padding-left: ${props => props.paddingLeft || '0'};
  text-align: ${props => props.textAlign || 'left'};
`;

export const OrderArrowContainer = styled.div`
  cursor: pointer;
  display: flex;
  & > svg {
    width: .9vw;
    height: 1vw;
    margin-left: 5px;
  }
`;

export const ContainerBody = styled.div`
  ${props => {
    if (props.loading) {
      return `
        width: 100%;
        height: auto;
        min-height: 10vh;
        background-color: ${props => props.theme.color_white};
        filter: blur(0px);

        & > tr > td {
          border-color: ${props => props.theme.color_white};
        }
      `;
    }
  }}
`;

export const LoadingContainer = styled.div`
  background-color: #ffffffad;
  position: absolute;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  bottom: 2vh;
  min-height: 10vh;
  max-height: ${props => props.maxHeight || '64vh'};
  z-index: 997;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerGifLoading = styled.div`
  width: 3rem;
  height: 3rem;
  background-color: ${props => props.theme.color_white};
  box-shadow: 0 0 5px 1px ${props => props.theme['color_light-gray']};
`;

export const Tr = styled.tr`
  &:hover {
  cursor: ${props => props.clickable? 'pointer' : 'initial'};
    background-color: ${props => props.theme['table_line-hover']} !important;
  }
`;