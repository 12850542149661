import { Modal, ModalHeader, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { RegularScaleButton } from '../sharedStyles.styles';

export const StyledModal = styled(Modal)`
  div.modal-content {
    border-radius: 0;
    
  }

  h5 {
    text-align: center;
  }

  @media (min-width: 992px) {
    &.modal-lg {
      max-width: 900px;
    }
  }

  ${({customstyle}) => customstyle}
`;

export const ContainerMessage = styled.div`
  text-align: center;
  color: ${props => props.theme['color_dark-gray']};
`;

export const StyledModalHeader = styled(ModalHeader)`
  border: none;
  display: flex;
  justify-content: center;
  padding-bottom: 0;
`;

export const StyledModalFooter = styled(ModalFooter)`
  border: none;

  ${({customstyleModalFooter}) => customstyleModalFooter}
`;

export const ModalClose = styled.div`
  font-size: .95rem;
  text-align: center;
  font-weight: 200;
  background-color: transparent;
  border: 1px solid transparent;
  color: ${props => props.theme['color_gray']};
  padding: 8px 14px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: .3rem;
  right: .8rem;
`;

export const CancelButton = styled(RegularScaleButton)`
  background-color: ${props => props.backColor ?  props.backColor : props.theme.color_red};
  color: ${props => props.theme.color_white};
  border: 1px solid transparent;
`;

export const ConfirmButton = styled(RegularScaleButton)`
  background-color: ${props => props.disabled ? props.theme.color_gray : props.theme.color_purple};
  margin-left: 3rem;
  color: ${props => props.theme.color_white};
  border: 1px solid transparent;
`;

export const ModalTitle = styled.span`
  text-transform: uppercase;
  color: ${props => props.theme.color_purple};
`;

export const ContentContainer = styled.div`
  width: 90%;
  margin: auto;
`;

export const Title = styled.h5`
  text-transform: uppercase;
  color: ${props => props.theme.color_red};
`;

export const Container = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerFooter = styled.div`
  width: 40%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.span`
  text-align: center;
  color: ${props => props.theme.color_purple};
  font-weight: 500;
  padding: 1rem 0;
`;

export const Cancel = styled(CancelButton)`
  align-self: flex-start;
  padding: 5px 14px;
`;

export const Confirm = styled(ConfirmButton)`
  align-self: flex-end;
  padding: 5px 14px;
  margin-left: 0;
`;
