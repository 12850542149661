import {
  GET_APPLICANT_INFOS_FAILED,
  GET_APPLICANT_INFOS_REQUEST,
  GET_APPLICANT_INFOS_SUCCESS,
  RESET_APPLICANT_INFOS,
  GET_REQUEST_INFOS,
  GET_REQUEST_INFOS_FAILED,
  GET_REQUEST_INFOS_SUCCESS,
  ADD_APPLICANT,
  ADD_APPLICANT_FAILED,
  ADD_APPLICANT_SUCCESS,
  UPDATE_APPLICANT_INFOS_FAILED,
  UPDATE_APPLICANT_INFOS_REQUEST,
  UPDATE_APPLICANT_INFOS,
  PREPARE_REPORT_REQUEST,
  PREPARE_REPORT_SUCCESS,
  PREPARE_REPORT_FAILED,
  PREPARE_REPORT_RESET,
  GET_USER_INFO,
  POST_FILE_UPLOAD
} from './types';

import { getApplicant, updateApplicant, getRequestById, addApplicant, removeApplicant, updateReport, getUserInfo, removeReinclusionProduct, chargeRequest } from '../../../../../services/operational';
import { actionCreator, generalActionTypes,extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { toastr } from 'react-redux-toastr';
import { goBack, replace } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../../../../auth/login/actions/types.js';

import { bugsnagClient } from './../../../../../routes'

const { NON_HANDLED_ERROR } = generalActionTypes;

export const resetApplicantInfos = () => actionCreator(RESET_APPLICANT_INFOS);

export const getUserInfos = () => {
  return async (dispatch, getState) => {
    const { login: { user: { token: { access_token } } } } = getState();
    try {
      const payload = await getUserInfo(access_token);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        }
      } else {
        dispatch(actionCreator(GET_USER_INFO, payload.data));
      }
    } catch (e) {
      dispatch(actionCreator(GET_APPLICANT_INFOS_FAILED, ''));
      toastr.error('Editar Pretendente', 'Algo inesperado aconteceu, entre em contato com a FC ANALISE!!');
      bugsnagClient.notify(e);
    }
  }
};

export const getApplicantInfos = (requestId, applicantId) => {
  return async (dispatch, getState) => {
    const { login: { user: { token: { access_token } } } } = getState();

    try {
      dispatch(actionCreator(GET_APPLICANT_INFOS_REQUEST));
      const payload = await getApplicant(access_token, requestId, applicantId);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.error('Editar Pretendente', extractErrorMessages(payload.data.message));
          bugsnagClient.notify(payload.data.message);
        }
      } else {
        const renda = payload.data.data.pretendente[0].renda || {}
        const faturamento = payload.data.data.pretendente[0].faturamento || {}
        if('principal' in renda && renda.principal.documentos.length > 0) {
          dispatch(actionCreator(POST_FILE_UPLOAD, {modulo: 'principal', files: renda.principal.documentos}));
        }
        if('outra' in renda && renda.outra.documentos.length > 0) {
          dispatch(actionCreator(POST_FILE_UPLOAD, {modulo: 'secundario', files: renda.outra.documentos}));
        }
        if('documentos' in faturamento && faturamento.documentos.length > 0) {
          dispatch(actionCreator(POST_FILE_UPLOAD, {modulo: 'principal', files: faturamento.documentos}));
        }
        dispatch(actionCreator(GET_APPLICANT_INFOS_SUCCESS, payload.data.data));
      }
    } catch (e) {
      dispatch(actionCreator(GET_APPLICANT_INFOS_FAILED, ''));
      toastr.error('Editar Pretendente', 'Algo inesperado aconteceu, entre em contato com a FC ANALISE!!!');
      bugsnagClient.notify(e);
    }
  }
};

export const updateApplicantInfos = (requestId, applicantId, data, reInclusion, selectedProduct) => {
  return async (dispatch, getState) => {
    const { login: { user: { token: { access_token } } } } = getState();

    try {
      dispatch(actionCreator(UPDATE_APPLICANT_INFOS_REQUEST));
      const payload = await updateApplicant(access_token, requestId, applicantId, data, reInclusion, selectedProduct);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(UPDATE_APPLICANT_INFOS_FAILED, extractErrorMessages(payload.data.message)));
          toastr.warning('Editar Pretendente', extractErrorMessages(payload.data.message));
        }
      } else {
        if(!reInclusion && !data.cancelGoBack){
          dispatch(goBack());
        }
        toastr.success('Pretendente', 'Editado com sucesso');
        dispatch(actionCreator(UPDATE_APPLICANT_INFOS));
      }
    } catch (e) {
      bugsnagClient.notify(e);
      toastr.error('Editar Pretendente', 'Algo inesperado aconteceu, entre em contato com a FC ANALISE!');
      dispatch(actionCreator(UPDATE_APPLICANT_INFOS_FAILED, extractErrorMessages(e)));
    }
  }
};

export const getRequest = (requestId) => {
  return async (dispatch, getState) => {
    const { login: { user: { token: { access_token } } } } = getState();

    try {
      dispatch(actionCreator(GET_REQUEST_INFOS));
      const payload = await getRequestById(access_token, requestId);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.error('Resumo', extractErrorMessages(payload.data.message));
        }
      } else {
        dispatch(actionCreator(GET_REQUEST_INFOS_SUCCESS, payload.data.data));
      }
    } catch (e) {
      dispatch(actionCreator(GET_REQUEST_INFOS_FAILED, ''));
      toastr.error('Resumo', 'Algo inesperado aconteceu, entre em contato com a FC ANALISE!');
    }
  }
};

export const addApplicantToRequest = (data, requestId) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } } } = getState();

    try {
      dispatch(actionCreator(ADD_APPLICANT));
      const payload = await addApplicant(token.access_token, data, requestId);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Pretendente', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(ADD_APPLICANT_FAILED, payload.data.message));
        }

      } else {
        toastr.success('Pretendente', 'Adicionado com sucesso');
        dispatch(goBack());
        setTimeout(() => {
          dispatch(actionCreator(ADD_APPLICANT_SUCCESS));
        }, 100);
      }
    } catch (e) {
      toastr.error('Pretendente', 'Algo inesperado aconteceu, entre em contato com a FC ANALISE!');
      dispatch(actionCreator(ADD_APPLICANT_FAILED, ''));
    }
  }
};

export const resetReinclusion = (requestId, applicantId, productId = 1) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } } } = getState();
    try {
      const payload = await removeReinclusionProduct(token.access_token, requestId, applicantId, productId);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.error('Remover Reinclusão', payload.data.message);
        }

      } else {
          dispatch(getRequest(requestId));

        toastr.success('Reinclusão', 'Removida com sucesso');
      }
    } catch (e) {
      toastr.error('Remover Reinclusão', e.message);
    }
  }
}

export const removeApplicantFromRequest = (requestId, applicantId, isLastApplicant = false) => {
  return async (dispatch, getState) => {
    const { login: { user: { token } } } = getState();

    try {
      const payload = await removeApplicant(token.access_token, requestId, applicantId);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.error('Remover Pretendente', payload.data.message);
        }

      } else {

        if (isLastApplicant) {
          dispatch(replace('/intranet/operacional/solicitacao'));
        } else {
          dispatch(getRequest(requestId));
        }

        toastr.success('Pretendente', 'Removido com sucesso');
      }
    } catch (e) {
      toastr.error('Remover Pretendente', e.message);
    }
  }
}

export const startReport = (body, type) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(PREPARE_REPORT_REQUEST));
    try {
      const { login: { user: { token } } } = getState();
      let payload
      const chargePayload = await chargeRequest(body, token.access_token, type)
      if(chargePayload.status === 200){
        payload = await updateReport(body, token.access_token);
        if (payload.status !== 200) {
          toastr.warning('Processar ANÁLISE', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(PREPARE_REPORT_FAILED));
        } else {
          dispatch(actionCreator(PREPARE_REPORT_SUCCESS));
        }
      }else{
        if (chargePayload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Processar ANÁLISE', extractErrorMessages(chargePayload.data.message));
          dispatch(actionCreator(PREPARE_REPORT_FAILED));
        }
        return
      }
    } catch (error) {
      toastr.error('Processar ANÁLISE', error);
      dispatch(actionCreator(NON_HANDLED_ERROR, error));
    }
  }
}

export const resetReport = () => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(PREPARE_REPORT_RESET));
  }
}