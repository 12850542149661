import styled, { css } from 'styled-components';
import { Row, Col } from 'reactstrap';
import { TabList, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

export const DisabledOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: ${props => props.theme.color_white};
  top: 0;
  botom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
  z-index: 99;
`;

export const StyledSpan = styled.span`
  font-size: ${props => props.size || '1em'};
  color: ${props => props.theme[props.color || 'color_black']};
  text-align: ${props => props.align};
  ${props => css`${props.styles}`};
`;

export const FullPortViewContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: visible;
  overflow-x: hidden;
`;

export const PageContainer = styled.div`
  width: calc(100% - 63px);
  float: right;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 800px) {
    width:  100%;
    float: none;
  }
`;

export const StyledRow = styled(Row)`
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  width: 100%;
  ${props => css`${props.customStyle}`};

  @media(max-width: 800px){
    justify-content: ${props => props.justify || 'center'};
  }
`;

export const TabsRow = styled(Row)`
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  width: 100%;
  ${props => css`${props.customStyle}`};

  @media(max-width: 800px){
    position: fixed;
    top: 35px;
    left: 0px;
    background-color: #3B4465;
    z-index: 1;
  }
`;

export const BaseColumn = styled(Col)`
  display: flex;
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
  background-color: ${props => props.theme[props.background || 'transparent']};
  ${props => css`${props.customStyle}`};
`;

export const FullColumn = styled(BaseColumn).attrs({
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  col: 12
})``;

export const HalfColumn = styled(BaseColumn).attrs({
  xl: 6,
  lg: 6,
  md: 6,
  sm: 6,
  col: 6
})``;

export const MediumColumn = styled(BaseColumn).attrs({
  xl: 4,
  lg: 4,
  md: 4,
  sm: 4,
  col: 4
})``;

export const SmallColumn = styled(BaseColumn).attrs({
  xl: 3,
  lg: 3,
  md: 3,
  sm: 3,
  col: 3
})``;

export const ExtraSmallColumn = styled(BaseColumn).attrs({
  xl: 2,
  lg: 2,
  md: 2,
  sm: 2,
  col: 2
})``;

export const LargeColumn = styled(BaseColumn).attrs({
  xl: 8,
  lg: 8,
  md: 8,
  sm: 8,
  col: 8
})``;

export const BaseScaleButton = styled.button`
  font-size: .95em;
  text-align: center;
  font-weight: 200;
  background-color: ${props => props.theme[props.background || 'color_white']};
  border: 1px solid ${props => props.theme[props.borderColor || 'color_gray']};
  color: ${props => props.theme[props.color || 'color_gray']};
  padding: ${props => props.padding || '8px 14px'};
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  outline: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    path {
      fill: ${props => props.theme.color_gray};
    }
    polygon {
      fill:${props => props.theme.color_gray};

      path {
        fill: ${props => props.theme.color_gray};
      }
    }

    &:hover {
      transform: none;
    }  
  }

  &:hover {
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.03);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.03);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.03);
    transition: all 200ms ease-in;
    transform: scale(1.03);
  };
  ${props => css`${props.customStyle}`};
`;

export const RegularScaleButton = styled(BaseScaleButton)`
  &:hover {
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
    transform: scale(1.1);
  }
`;

export const BigScaleButton = styled(BaseScaleButton)`
  &:hover {
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.2);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.2);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.2);
    transition: all 200ms ease-in;
    transform: scale(1.2);
  }
`;

export const StyledBlueButton = styled(BaseScaleButton)`
  background-color: ${props => {
    if (props.disabled) {
      return props.theme.color_gray;
    }

    return props.theme.color_purple;
  }};
  padding: .4rem 1.5rem .4rem 1.5rem;
  border-radius: 0;
  font-weight: 400;
  border-color: transparent;
  color: ${props => props.theme.color_white};
  ${props => css`${props.customStyle}`};
`;

export const ChoseEntityButton = styled(BaseScaleButton)`
  background-color: ${props => props.theme[props.selected ? 'color_purple' : 'transparent']};
  border-radius: 0;
  border-color: ${props => props.theme[props.borderColor || 'color_white']};
  border-width: 1px;
  color: ${props => props.theme[props.selected ? 'color_white' : props.color]};
  text-align: center;

  ${(props) => {
    const { getMedias: { iphones } } = props.theme;
    return `
      ${iphones()`
          height: 4rem;
          width:  10rem;
          margin-top: 5rem
        `
      }
    `;
  }}

  &:hover {
    color: ${props => props.theme.color_white};
    background-color: ${props => props.theme.color_purple};
    border-color: ${props => props.theme.color_purple};
  }
`;

export const PrimaryButton = styled(BaseScaleButton)`
  min-width: 7rem;
  background-color: ${props => props.background || props.theme['color_purple']};
  border-radius: 0;
  border-color: ${props => props.theme['color_white']};
  border-width: 1px;
  color: ${props => props.theme['color_white']};
  text-align: center;

  @media(max-width: 800px){
    font-size: 1.3rem;
    height: 2rem;
    width:  100%;
  }

  &:hover{
    border: 0;
    border-color: transparent;
    background-color: ${props => props.background || props.theme['color_purple']};
  }
`;

export const SecundaryButton = styled(BaseScaleButton)`
  min-width: 7rem;
  background-color: ${props => props.background || props.theme.color_red};
  border-radius: 0;
  border-color: ${props => props.theme['color_white']};
  border-width: 1px;
  color: ${props => props.theme['color_white']};
  text-align: center;

  ${(props) => {
    const { getMedias: { iphones } } = props.theme;
    return `
      ${iphones()`
          font-size: 1.3rem;
          height: 4rem;
          width:  10rem;
          margin-top: 5rem
        `
      }
    `;
  }}

  &:hover{
    border: 0;
    border-color: transparent;
    background-color: ${props => props.background || props.theme.color_red};
  }
`;

export const ChoseEntityLabel = styled.p`
  align-self: center;
  color: ${props => props.theme[props.color || 'color_white']};
  margin-bottom: 0.5rem;

  ${(props) => {
    const { getMedias: { laptops, iphones, desktops } } = props.theme;
    return `
      ${laptops()`
          margin-top: 2rem;
        `
      }
      ${iphones()`
          margin-top: 1rem;
        `
      }
      ${desktops()`
          margin-top: 2rem;
        `
      }
    `;
  }}
`;

export const GrayLink = styled.a`
  color: ${props => props.theme.color_gray};
  &:hover {
    color: ${props => props.theme.color_gray};
    text-decoration: none;
  }
`;

export const HeaderButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
`;

export const CustomLink = styled(Link)`
  color: ${props => ((props.color) ? props.theme[props.color] : props.theme.color_white)};

  &:hover {
    color: ${props => ((props.hoverColor) ? props.theme[props.hoverColor] : props.theme.color_white)};
    text-decoration: ${props => props.textDecoration || "none"};
  }
`;

export const MainContainer = styled.main`
  width: 81%;
  margin: auto;

  @media(max-width: 800px){
    padding: 0;
  }

  @media(min-width: 799px){
    padding-left: 0;
    padding-right: 0;
    padding-top: 1rem;
  }
`;

export const RoundButtonWithShadowContainer = styled.div`
  text-align: center;
  display: inline-block;
  font-size: .7rem;
  color: ${props => props.theme.color_gray};

  &:disabled {
    cursor: default;
    svg {
      path {
        fill: ${props => props.theme.color_gray};
      }
    }  
  }

  ${props => props.customStyle};
`;

export const RoundButtonWithShadowAnchor = styled(BaseScaleButton)`
  height: 1.2rem;
  display: block;
  width: ${props => props.width || '2rem'};
  height: ${props => props.height || '2rem'};
  border-radius: 100%;
  background-color: ${props => props.theme.color_beige};
  border: 1px solid;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: ${props => props.margin || 'auto'};
  box-shadow: 0px 0px 15px 0px ${props => props.theme['color_gray-X11-gray']};
  border-color: transparent;

  &:hover {
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
    transform: scale(1.1);
  };

  &:disabled {
    cursor: default;
    svg {
      path {
        fill: ${props => props.theme.color_gray};
      };
    };

    &:hover {
      transform: none;
    }  
  }
`;

export const FormContentRow = styled(Row)`
  margin: 0 0 1rem 0;
  padding-left: 0;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-end;

  @media (max-width: 800px) {
    margin-top: -22px;
  }
`;
export const TitleWithLine = styled.h3`
  margin-top: 1rem;
	position: relative;
	text-align: center;
	z-index: 1;
	color: ${props => props.theme.color_purple};

	&:before {
		border-top: 2px solid ${props => props.theme.color_purple};
		content: "";
		margin: 0 auto;
		position: absolute;
		top: 50%; left: 0; right: 0; bottom: 0;
		width: 100%;
		z-index: -1;
	}

	& > span {
		background: ${props => props.theme.color_white};
		padding: 0 15px;
	}
`;

export const TabListContainer = styled(TabList)`
  margin-bottom: 0;
  padding-left: 0;

  @media (min-height: 900px){
    margin-top: 35px;
  }

  ${(props) => {
    const { getMedias: { iphones } } = props.theme;
    return `
      ${iphones()`
        position: unset;
        top: 0;
        margin-top: 0;
        `
      }
    `;
  }}
`;

export const TabListContainerRequest = styled(TabList)`
  margin-bottom: 0;
  padding-left: 0;
  position: relative;
  top: 0.6vh;
  
  @media (min-height: 900px)  {
    top: 0.42vh;
  }

  @media (min-height: 1024px)  {
    top: 0.4vh;
  }

  @media (min-height: 1080px)  {
    top: 0.36vh;
  }

  ${(props) => {
    const { getMedias: { iphones } } = props.theme;
    return `
      ${iphones()`
        position: unset;
        top: 0;
        margin-top: 0;
        `
      }
    `;
  }}
`;

export const DefaultTab = styled(Tab)`
  padding: 0 .5rem !important;
  & > button {
    cursor: pointer;
    border: 0;
    background-color: transparent;
    outline: none;
    font-size: 12px !important;
    text-transform: ${props => props.textTransform || 'uppercase'};
  }
`;

export const ColoredTab = styled(DefaultTab)`
  padding: 0 .5rem !important;
  &.active {
  
    & > button {
      font-size: 12px !important;
      font-weight: 600;
      color: ${props => {
    switch (props.name) {
      case 'ATIVOS':
        return props.theme.color_green;
      case 'SUSPENSOS':
        return props.theme.color_orange;
      case 'BLOQUEADOS':
        return props.theme.color_red;
      default:
        return props.theme.color_purple;
    }
  }} !important;
    }
  }
`;
