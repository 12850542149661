import { uniq } from 'lodash';

import fcRapidaRules from './rules/fcRapidaRules';

const applicantFieldsPF = [
  'tipo_pretendente',
  'residir',
  'participante',
  'nome',
  'nome_disabled',
  'cpf',
  'cpf_disabled'
];

const applicantFieldsPJ = [
  'tipo_pretendente',
  // 'uso_imovel',
  'razao_social',
  'razao_social_disabled',
  // 'nome_fantasia',
  'cnpj',
  'cnpj_disabled',
  // 'tempo_fundacao'
];

const productsPF = [
  {
    id: 1,
    name: 'FCRAPIDA',
    fields: [
      'tipo_pretendente',
      'residir',
      'participante',
      'nome',
      'cpf',
    ]
  },
  {
    id: 3,
    name: 'FCRAPIDA',
    fields: [
      'tipo_pretendente',
      'residir',
      'participante',
      'nome',
      'cpf',
    ]
  },
  {
    id: 5,
    name: 'FCRAPIDA',
    fields: [
      'tipo_pretendente',
      'residir',
      'participante',
      'nome',
      'cpf',
    ]
  },
  {
    id: 7,
    name: 'FCCOMPLETA',
    fields: [
      'tipo_pretendente',
      'residir',
      'participante',
      'nome',
      'cpf',
    ]
  }
];

const productsPJ = [
  {
    id: 1,
    name: 'FCRAPIDA',
    fields: [
      'tipo_pretendente',
      'uso_imovel',
      'razao_social',
      'nome_fantasia',
      'cnpj',
      'tempo_fundacao'
    ]
  },
  {
    id: 4,
    name: 'FCEMPRESA',
    fields: [
      'tipo_pretendente',
      'uso_imovel',
      'razao_social',
      // 'nome_fantasia',
      'cnpj',
      // 'tempo_fundacao'
    ]
  },
  {
    id: 8,
    name: 'FCCOMPLETA',
    fields: [
      'tipo_pretendente',
      'uso_imovel',
      'razao_social',
      // 'nome_fantasia',
      'cnpj',
      // 'tempo_fundacao'
    ]
  }
];

const applicantFieldsPFEdit = [
  'tipo_pretendente',
  'residir',
  'participante',
  'nome_disabled',
  'cpf_disabled',
];

const applicantFieldsPJEdit = [
  'tipo_pretendente',
  'uso_imovel',
  'razao_social_disabled',
  // 'nome_fantasia',
  'cnpj_disabled',
  // 'tempo_fundacao'
];

export default (productsSent = [1], entity = 'PF', changedValues, editMode = false) => {
  let fieldsByProducts = [];
  if (entity === 'PF' || entity === "PJ") {
    if (editMode) {
      fieldsByProducts = (entity === "PF")? applicantFieldsPFEdit : applicantFieldsPJEdit;
    } else {

      fieldsByProducts = productsSent.reduce((acc, id) => {
        if (entity === "PF") {
          const productsIndex = productsPF.findIndex(prd => prd.id === id);
    
          if (productsIndex !== -1) {
            return [...acc, ...productsPF[productsIndex].fields];
          }
        } else if (entity === "PJ") {
          const productsIndex = productsPJ.findIndex(prd => prd.id === id);
    
          if (productsIndex !== -1) {
            return [...acc, ...productsPJ[productsIndex].fields];
          }
        }

        return acc;
      }, []);
    }
    
    let fieldsMerged = uniq(fieldsByProducts);

    // FC RAPIDA
    if (productsSent.indexOf(1) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    // FC ESSENCIAL
    if (productsSent.indexOf(3) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    // FC BASICO
    if (productsSent.indexOf(5) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    // FC COMPLETA
    if (productsSent.indexOf(7) >= 0) {
      const fieldsToRemove = fcRapidaRules(entity, changedValues);
      fieldsMerged = fieldsMerged.filter((field) => fieldsToRemove.indexOf(field) < 0);
    }

    if (entity === "PF") {
      return applicantFieldsPF.filter((field) => fieldsMerged.indexOf(field) >= 0);
    } else if (entity === "PJ") {
      return applicantFieldsPJ.filter((field) => fieldsMerged.indexOf(field) >= 0);
    }
  }

  return [];
}
