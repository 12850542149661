import {
  OPEN_LOGIN,
  OPEN_SIGNUP,
  CLOSE_LOGIN,
  CLOSE_SIGNUP
} from './types';
import { actionCreator } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';

export const openSignUp = () => actionCreator(OPEN_SIGNUP);
export const openLogin = () => actionCreator(OPEN_LOGIN);
export const closeSignUp = () => actionCreator(CLOSE_SIGNUP);
export const closeLogin = () => actionCreator(CLOSE_LOGIN);
