import React, { PureComponent } from 'react';
import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import {
  Title,
  Container,
  Content,
  ContainerFooter,
  Cancel,
  Confirm
} from './confirmExitModal.styled'

export default class ConfirmExitModal extends PureComponent {

  static defaultProps = {
    message: '',
    callBack: () => {}
  }

  state = { isOpen: true }

  closeModal = (action = true) => {
    this.setState({ isOpen: false }, () => {
      this.props.callBack(action);
    });
  }

  renderHeader = () => (<Title>Atenção!</Title>)

  renderFooter = () => (
    <ContainerFooter>
      <Cancel onClick={() => this.closeModal()}>Cancelar</Cancel>
      <Confirm onClick={() => this.closeModal(false)}>Sair</Confirm>
    </ContainerFooter>
  )

  render() {
    return (
      <ModalContainer
        onClose={this.closeModal}
        isOpen={this.state.isOpen}
        header={this.renderHeader()}
        footer={this.renderFooter()}
      >
        <Container>
          <Content>
            Ao sair, todos os dados do formulário serão perdidos.<br />
            Deseja prosseguir?
          </Content>
        </Container>
      </ModalContainer>
    );
  }
}
