import React from "react";
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.width? props.width : '1.5rem'};
`;
const StyledPath = styled.path`
  fill: ${props => props.theme.color_purple};
`;

const StyledPlygon = styled.polygon`
  fill: ${props =>props.theme.color_purple};
`;

const EditSVG  = (props) => (
  <StyledSVG viewBox="0 0 632 667" {...props} >
    <StyledPlygon
      points="514,588 95,587 94,169 421.5,169 448.5,139 66.1,139.1 66.1,615.9 542.9,615.9 543,214 513.5,247.3 
    "
    />
    <StyledPath
      d="M543.7,70.1c26.6,2.2,47.8,22.2,52.4,48.1l-24.6,27.6l-53.3-47.3l53.3,47.3l-53.3-47.3L543.7,70.1L543.7,70.1
    z"
    />
    <StyledPlygon
      points="252.8,449.6 325.4,422.6 547.8,173.4 495,126.2 273.1,374.6"
    />
    <line className="st1" x1="566.5" y1="93.7" x2="516.3" y2="149.9" />
  </StyledSVG>
);

export default EditSVG;
