import React, { Component } from 'react'
import {
  StyledTableBodyForReport,
  StyledTableForReport,
  ContainerBody,
  LoadingContainer,
  ContainerGifLoading
} from '@bit/wavfichacerta.fichacerta.tables.styles';
import LoadingCircle from '@bit/wavfichacerta.fichacerta.icons.loading-circle';

class CustomTable extends Component {
  static defaultProps = {
    loading: false,
    maxSize: 'default'
  }

  mapLines = () => {
    const { lines } = this.props;
    if (Array.isArray(lines) && lines.length > 0) {
      const mappedLines = this.props.lines.map(this.props.renderLine);


      return mappedLines;
    }
    return null;
  }

  render() {
    const itemsQuantity = (Array.isArray(this.props.lines) && this.props.lines.length) || 0;
    return (
      <ContainerBody loading={this.props.loading}>
        {this.props.loading &&
          <LoadingContainer>
            <ContainerGifLoading>
              <LoadingCircle />
            </ContainerGifLoading>
          </LoadingContainer>
        }
        <StyledTableForReport hover striped responsive maxSize={this.props.maxSize} items={itemsQuantity}>
          <thead>
            {this.props.renderHeader()}
          </thead>
          <StyledTableBodyForReport loading={this.props.loading}>

            {this.mapLines()}

          </StyledTableBodyForReport>
        </StyledTableForReport>
      </ContainerBody>
    );
  }
}

export default CustomTable;
