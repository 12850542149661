import React from 'react';
import styled from 'styled-components';

const StyledPath = styled.path`
  fill: ${props => props.theme[props.fill || 'color_white']};
`;

const StyledSVG = styled.svg`
  width: ${props => props.width};
  height: ${props => props.height};
`;

const Plus = ({ fill, width, height }) => (
  <StyledSVG width={width} height={height} viewBox="0 0 13.28 13.28">
    <g id="_8" data-name=" 8">
      <StyledPath fill={fill} class="cls-1" d="M8.4,1.92A6.64,6.64,0,1,0,15,8.56,6.64,6.64,0,0,0,8.4,1.92Zm0,12.45a5.81,5.81,0,1,1
      ,5.81-5.81A5.81,5.81,0,0,1,8.4,14.37Zm2.49-6.22H8.81V6.07a.42.42,0,0,0-.83,0V8.15H5.91a.42.42,0,0,0,0,
      .83H8v2.08a.42.42,0,0,0,.83,0V9h2.08a.42.42,0,0,0,0-.83Z" transform="translate(-1.76 -1.92)"/>
    </g>
  </StyledSVG>
);

export default Plus;
