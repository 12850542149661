import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  width: ${props => props.width || '100%'};

  & > circle { fill: #73cf2b; }
  & > path:nth-child(1) { fill: #183204; }
  & > path:nth-child(2) { fill: #183104; }
  & > path:nth-child(3) { fill: #183204; }
`;

export default (props) => (
  <SVG {...props} viewBox="0 0 137.75 137.75">
    <circle class="cls-1" cx="68.88" cy="68.88" r="68.88"/>
    <path class="cls-2" d="M76.53,112.45c-12.28,0-23.08-7.3-27.08-18.42-.8-2.23-1.72-4.75,1.22-6,2.78-1.21,4.23.6,5.24,3,3.81,9.22,10.62,14.43,20.71,14.39,10.27,0,17.15-5.42,21-14.78,1-2.4,2.35-4.3,5.21-2.91s2.09,3.7,1.17,6C99.44,105.41,89.15,112.42,76.53,112.45Z" transform="translate(-8 -4)"/>
    <path class="cls-3" d="M50.07,62.87c0,2.15.15,4.32,0,6.46a4.54,4.54,0,0,1-4.61,4.37c-2.85.17-5-1.42-5.23-4.23a102.18,102.18,0,0,1,0-13.41c.16-2.92,2.28-4.36,5.12-4.32A4.41,4.41,0,0,1,50,55.92a67.06,67.06,0,0,1,0,7Z" transform="translate(-8 -4)"/>
    <path class="cls-2" d="M112.94,62.8a50.8,50.8,0,0,1,0,6c-.35,3-2,5.06-5.18,4.93s-4.85-1.92-4.86-5.15c0-4,0-8,0-11.93,0-3,1.64-4.68,4.55-4.87,3.18-.21,5,1.6,5.43,4.61a49.44,49.44,0,0,1,.05,6.45Z" transform="translate(-8 -4)"/>
  </SVG>
);
