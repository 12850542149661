import React, { Component } from 'react'

export default class Report extends Component {
  render() {
    return (
    <svg viewBox="0 0 124 124" className="nano">
        <path className="white" d="M26.9,16v92h48l21.9-21.7V16H26.9z M30.6,19.9h62.2v63.6H74.1c-1.2,0-2,1-2,2v18.6H30.6L30.6,19.9L30.6,19.9z M76,101.4V87.3h14.1L76,101.4z"/>
        <rect x="41" y="33.2" className="white" width="41.3" height="3.9"/>
        <rect x="41" y="49.9" className="white" width="41.3" height="3.9"/>
        <rect x="41" y="66.5" className="white" width="41.3" height="3.9"/>
    </svg>
    )
  }
}
