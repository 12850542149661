import React, { PureComponent } from 'react'
import { Col, Row } from 'reactstrap';
import { StyledLabel } from './Inputs.styles';
import { ActionButton } from './actionButtonForm.styles';

class ActionButtonForm extends PureComponent {

  render() {
    return (
      <Col className={this.props.size}>
        <Col style={{ padding: 0, marginBottom: '.5rem', marginTop: '1.5rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <StyledLabel>{this.props.label}</StyledLabel>
          </div>
        </Col>
        <Row>
          <ActionButton type="button" onClick={this.props.onClick}>{this.props.value}</ActionButton>
        </Row>
      </Col>
    );
  }
}

export default ActionButtonForm;
