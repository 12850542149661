import styled from 'styled-components';
import { CancelButton, ConfirmButton } from '@bit/wavfichacerta.fichacerta.modals.styles';

export const Title = styled.h5`
  text-transform: uppercase;
  color: ${props => props.theme.color_red};
`;

export const Container = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerFooter = styled.div`
  width: 40%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.span`
  text-align: center;
  color: ${props => props.theme.color_purple};
  font-weight: 500;
  padding: 1rem 0;
`;

export const Cancel = styled(CancelButton)`
  align-self: flex-start;
  padding: 5px 14px;
`;

export const Confirm = styled(ConfirmButton)`
  align-self: flex-end;
  padding: 5px 14px;
  margin-left: 0;
`;
