import React, { PureComponent } from 'react';

import Card from '@bit/wavfichacerta.fichacerta.cards.card';
import CollapsableContainer from '../../../components/CollapsableContainer';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import RenderFields from '../../shared/RenderFields';

import analysisFields from './analysisFields';

export default class AnalysisSection extends PureComponent {

  render() {
    return (
      <StyledRow margin="1rem 0 0 0">
        <Card title="Análise cadastral">
          <CollapsableContainer>
            <RenderFields fields={analysisFields} />
          </CollapsableContainer>
        </Card>
      </StyledRow>
    );
  }
}

