import { removeNonDigit, date, money } from "../../../utils/normalizers";
import { amountMask } from '../../../utils/defaultMasks';
import { empityValidation, cpfValidation, emailValidation, cnpjValidation, cepValiadtion, validateDate, validatePhone, validateEmailConfirmationField } from '../../../utils/validations';
import { warnPhone, warnEmail, cepWarning } from "../../../utils/warnings";
import { omit } from 'lodash';
import { formatToBRL, formatDateToBR } from "../../../utils/formatter";

const sizes = {
  generalMedium: 'col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6',
  ddi: 'col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12',
  phone: 'col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'
};

export const defaultCEP = (size = sizes.generalMedium, path = '', label = 'CEP', name = 'cep') => (
  {
    name,
    label,
    isRequired: true,
    size: size,
    type: 'cep',
    normalize: removeNonDigit,
    validate: cepValiadtion,
    props: {
      regex: [
        /\d/, /\d/, /\d/, /\d/, /\d/,
        '-',
        /\d/, /\d/, /\d/
      ],
      path
    }
  }
);

export const nonRequiredCEP = (size = sizes.generalMedium, path = '', label = 'CEP', name = 'cep') => (
  {
    name,
    label,
    size: size,
    type: 'cep',
    normalize: removeNonDigit,
    warn: cepWarning,
    props: {
      regex: [
        /\d/, /\d/, /\d/, /\d/, /\d/,
        '-',
        /\d/, /\d/, /\d/
      ],
      path
    }
  }
);

export const defaultPhone = (phoneName, label, size = sizes.generalMedium) => (
  {
    name: phoneName || 'telefone_principal',
    label,
    isRequired: true,
    size,
    type: 'maskedText',
    validate: validatePhone,
    normalize: removeNonDigit,
    placeholder: '(   ) _____-_____',
    props: {
      regex: [
        '(', /\d/, /\d/, ')',
        /\d/, /\d/, /\d/, /\d/, /\d/,
        '-',
        /\d/, /\d/, /\d/, /\d/
      ]
    }
  }
);

export const defaultPhoneWithoutDDI = (name, label, size = sizes.generalMedium, required = true) => {
  let aditionalParams = {
    validate: validatePhone
  };
  if (!required) {
    aditionalParams = {
      warn: validatePhone
    }
  }
  return {
    name: name || 'telefone_principal',
    label: label || 'WITHOUT_LABEL',
    isRequired: required,
    size: size,
    type: 'maskedText',
    normalize: removeNonDigit,
    placeholder: '(   ) _____-_____',
    props: {
      regex: [
        '(', /\d/, /\d/, ')',
        /\d/, /\d/, /\d/, /\d/, /\d/,
        '-',
        /\d/, /\d/, /\d/, /\d/
      ]
    },
    ...aditionalParams
  }
}

export const defaultNonRequiredPhone = (phoneName, label, size = sizes.generalMedium) => (
  {
    name: phoneName || 'telefone',
    label,
    isRequired: false,
    size,
    type: 'maskedText',
    normalize: removeNonDigit,
    warn: warnPhone,
    placeholder: '(   ) _____-_____',
    props: {
      regex: [
        '(', /\d/, /\d/, ')',
        /\d/, /\d/, /\d/, /\d/, /\d/,
        '-',
        /\d/, /\d/, /\d/, /\d/
      ]
    }
  }
);

export const defaultCPF = (size = sizes.generalMedium) => (
  {
    name: 'cpf',
    label: 'CPF',
    isRequired: true,
    size: size,
    type: 'maskedText',
    normalize: removeNonDigit,
    props: {
      regex: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.',
        /\d/, /\d/, /\d/, '-', /\d/, /\d/
      ]
    },
    validate: cpfValidation
  }
);

export const defaultEmail = (size = sizes.generalMedium) => (
  {
    name: 'email',
    label: 'E-mail',
    isRequired: true,
    size: size,
    type: 'text',
    validate: emailValidation,
    placeholder: 'Digite seu e-mail'
  }
);

export const nonRequiredEmail = () => {
  const emailField = defaultEmail();
  const newEmailField = omit(emailField, ['validate']);

  return { ...newEmailField, isRequired: false, hasValidation: false, warn: warnEmail };
};

export const defaultEmailConfirmation = (size = sizes.generalMedium) => (
  {
    name: 'email_confirmation',
    label: 'Confirmar e-mail',
    isRequired: true,
    size: size,
    type: 'text',
    placeholder: 'Confirme seu e-mail',
    validate: validateEmailConfirmationField
  }
);

export const defaultCNPJ = (size = sizes.generalMedium) => (
  {
    name: 'cnpj',
    label: 'CNPJ',
    isRequired: true,
    size: size,
    type: 'maskedText',
    normalize: removeNonDigit,
    props: {
      regex: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/',
        /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/
      ]
    },
    validate: cnpjValidation
  }
);

export const simpleRequiredText = ({
  size = sizes.generalMedium,
  name,
  label,
  validate = empityValidation,
  placeholder = ''
}) => (
    {
      name,
      label,
      isRequired: true,
      size: size,
      type: 'text',
      placeholder,
      validate
    }
  );

export const simpleNonRequiredText = ({
  size = sizes.generalMedium,
  name,
  label,
  format = null,
  warn = null,
  normalize = null,
  placeholder = '',
  permission
}) => (
    {
      name,
      label,
      isRequired: false,
      size: size,
      type: 'text',
      value: "",
      warn,
      normalize,
      format,
      placeholder,
      permission
    }
  );

export const simpleNonRequiredCheck = ({
  size = sizes.generalMedium,
  name,
  label,
  warn = empityValidation
}) => (
    {
      name,
      label,
      isRequired: false,
      size: size,
      type: 'singleCheckbox',
      warn
    }
  );

export const simpleRequiredSelect = ({
  size = sizes.generalMedium,
  name,
  label,
  validate = empityValidation,
  placeholder = '',
  options = []
}) => (
    {
      name,
      label,
      isRequired: true,
      size: size,
      type: 'select',
      placeholder,
      validate,
      options
    }
  );

export const simpleNonRequiredSelect = ({
  size = sizes.generalMedium,
  name,
  label,
  placeholder = '',
  options = [],
  warn = null,
}) => (
    {
      name,
      label,
      isRequired: false,
      size: size,
      type: 'select',
      placeholder,
      warn,
      options
    }
  );

export const defaultComposedName = (size, fieldSizes, label = 'Nome', names = ["tratamentos", "nome"]) => (
  {
    type: 'composed',
    name: 'composedName',
    size,
    innerInputs: [
      {
        name: names[0],
        label: label,
        isRequired: false,
        size: fieldSizes[0],
        type: 'select'
      },
      {
        name: names[1],
        label: 'WITHOUT_LABEL',
        isRequired: true,
        size: fieldSizes[1],
        type: 'text',
        validate: empityValidation,
        placeholder: ''
      }
    ]
  }
);

export const defaultAddressFields = (addressSizes) => (
  [
    {
      name: 'endereco',
      label: 'Endereço',
      isRequired: true,
      size: addressSizes.addressField,
      type: 'text',
      hasValidation: true,
      validate: empityValidation
    },
    {
      name: 'numero',
      label: 'Número',
      isRequired: true,
      size: addressSizes.numberField,
      type: 'text',
      validate: empityValidation,
    },
    {
      name: 'complemento',
      label: 'Comp.',
      isRequired: false,
      size: addressSizes.complementField,
      type: 'text'
    },
    {
      name: 'bairro',
      label: 'Bairro',
      isRequired: true,
      size: addressSizes.neighborhood,
      type: 'text',
      hasValidation: true,
      validate: empityValidation
    },
    {
      name: 'cidade',
      label: 'Cidade',
      isRequired: true,
      size: addressSizes.cityField,
      type: 'text',
      hasValidation: true,
      validate: empityValidation,
    },
    {
      name: 'estado',
      label: 'Estado',
      isRequired: true,
      size: addressSizes.stateField,
      type: 'text',
      hasValidation: true,
      validate: empityValidation,
    }
  ]
);

export const simpleRequiredRadioButton = (options = [], label, name, size = sizes.generalMedium, direction = 'column') => ({
  name: name,
  label: label,
  isRequired: true,
  disabled: false,
  size: size,
  type: 'radio',
  value: '',
  validate: empityValidation,
  options,
  direction
});

export const simpleNonRequiredRadioButton = (options = [], label, name, size = sizes.generalMedium, direction = 'column') => ({
  name: name,
  label: label,
  isRequired: false,
  disabled: false,
  size: size,
  type: 'radio',
  validate: () => undefined,
  options,
  direction
});

export const simpleActionButton = ({
  name,
  label,
  value = ''
}) => ({
  name,
  label,
  type: 'actionButton',
  value
})

export const simpleDisabledText = ({
  size = sizes.generalMedium,
  name,
  label,
  placeholder = ''
}) => (
    {
      name,
      label,
      disabled: true,
      size: size,
      type: 'textDisabled',
      placeholder
    }
  );

export const simpleRequiredDate = ({ name, label, validate = validateDate, size = sizes.generalMedium }) => ({
  name,
  label,
  size,
  isRequired: true,
  type: 'maskedText',
  validate,
  normalize: date,
  format: formatDateToBR,
  props: {
    regex: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  }
});

export const simpleNonRequiredDate = ({ name, label, size = sizes.generalMedium, warnDate = validateDate }) => ({
  name,
  label,
  size,
  isRequired: false,
  type: 'maskedText',
  value: "",
  normalize: date,
  format: formatDateToBR,
  warn: (value) => value !== undefined ? warnDate(value) : value,
  hasValidation: true,
  props: {
    regex: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  }
});

export const simpleNonRequiredMoney = ({ name, label, size = sizes.generalMedium }) => ({
  name,
  label,
  size,
  type: 'maskedText',
  format: (value) => value !== undefined && value !== "" ? formatToBRL(value) : "",
  normalize: money,
  props: {
    regex: amountMask(),
    moneyMask: true
  }
});

export const simpleRequiredMoney = ({ name, label, size = sizes.generalMedium }) => ({
  name,
  label,
  size,
  isRequired: true,
  type: 'maskedText',
  format: (value) => value !== undefined && value !== "" ? formatToBRL(value) : "",
  normalize: money,
  validate: empityValidation,
  props: {
    regex: amountMask(),
    moneyMask: true
  }
});

export const composedNonRequiredTime = ({
  nameFrom = 'from',
  label = '',
  nameTo = 'to',
  size = sizes.generalMedium,
  sizeFrom = sizes.generalMedium,
  sizeTo = sizes.generalMedium
}) => ({
  type: 'composed',
  name: 'composedRangedTime',
  size,
  innerInputs: [
    {
      name: nameFrom,
      label: label,
      isRequired: false,
      size: sizeFrom,
      type: 'maskedText',
      placeholder: 'De --:--',
      value: "00:00",
      props: {
        regex: [/[0-2]/, /\d/, ':', /[0-5]/, /\d/]
      }
    },
    {
      name: nameTo,
      label: 'WITHOUT_LABEL',
      isRequired: false,
      size: sizeTo,
      type: 'maskedText',
      placeholder: 'Até --:--',
      value: "00:00",
      props: {
        regex: [/[0-2]/, /\d/, ':', /[0-5]/, /\d/]
      }
    }
  ]
});

export const simpleNonRequiredTime = ({ name, label, size }) => ({
  name,
  label,
  isRequired: false,
  size,
  type: 'maskedText',
  placeholder: '--:--',
  value: "00:00",
  props: {
    regex: [/[0-2]/, /\d/, ':', /[0-5]/, /\d/]
  }
});

export const simpleRequiredSecret = ({ name, label, size = sizes.generalMedium, validate = empityValidation }) => ({
  name,
  label,
  isRequired: true,
  size,
  type: 'password',
  placeholder: '',
  validate
});

export const simpleNonRequiredSecret = ({ name, label, size = sizes.generalMedium, validate = null }) => ({
  name,
  label,
  isRequired: false,
  size,
  type: 'password',
  placeholder: '',
  warn: validate
});
