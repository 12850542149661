export const onOpenSignUp = state => (
  {
    ...state,
    openSignUp: true
  }
);

export const onOpenLogin = state => (
  {
    ...state,
    openLogin: true
  }
);

export const onCloseSignUp = state => (
  {
    ...state,
    openSignUp: false
  }
);

export const onCloseLogin = state => (
  {
    ...state,
    openLogin: false
  }
);
