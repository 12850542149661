import React, { PureComponent } from 'react';
import { SistemicContainer,SistemicTitle, SistemicSubtitle, SistemicSubtitleSmall } from './systemicSight.styles';
import icons from '../../shared/treatedIcons';

const {
  FaceGood,
  FaceVeryGood,
  FaceRegular,
  FaceInconsistent,
  FaceNotAdvisable,
  ReportAlert
} = icons;
   
class SystemicSight extends PureComponent {

  getStatusIcon = (status) => {
    switch (status) {
      case "PARECER_NIVEL_1":
        return <FaceVeryGood width="3rem" height="7rem"/>;
      case "PARECER_NIVEL_2":
        return <FaceGood width="3rem" height="7rem"/>;
      case "PARECER_NIVEL_3":
        return <FaceRegular width="3rem" height="7rem"/>;
      case "PARECER_NIVEL_4":
        return <FaceInconsistent width="3rem" height="7rem"/>;
      case "PARECER_NIVEL_5":
        return <FaceNotAdvisable width="3rem" height="7rem"/>;
      default:
        return <ReportAlert width="3rem" height="7rem"/>;
    }
  }

  getFeedbackTitles = () => {
    const { parecer } = this.props;

    if (Array.isArray(parecer) && parecer.length > 0) {
      return parecer.map((item) => (
        <SistemicTitle status={item.parecer_key}>
          { item.parecer }
        </SistemicTitle>
      ));
    }

    return null;
  }

  getFeebackRecommendations = () => {
    const { parecer } = this.props;
    let onlyOneFeedbackStatusKey = ['PARECER_NIVEL_1', 'PARECER_NIVEL_2', 'PARECER_NIVEL_3', 'PARECER_NIVEL_4', 'PARECER_NIVEL_5'];
    
    if (Array.isArray(parecer) && parecer.length === 1) {
      if (onlyOneFeedbackStatusKey.findIndex((feedback) => feedback === parecer[0].parecer_key) > -1) {
        return parecer.map((item) => {
          if(Array.isArray(item.recomendacao) && item.recomendacao.length === 0){
            return (
              <SistemicSubtitle dangerouslySetInnerHTML={{__html: ""}} />
            )
          } else {
            return (
               <SistemicSubtitle dangerouslySetInnerHTML={{__html: (Array.isArray(item.recomendacao) && item.recomendacao.length > 0) && item.recomendacao }} />
            )
          }
        }
       );  
      }
    }

    if (Array.isArray(parecer) && parecer.length > 0) {
      return parecer.map((item) => {
        if(!item.recomendacao) return null
        return(
          <SistemicSubtitle dangerouslySetInnerHTML={{__html: (Array.isArray(item.recomendacao) && item.recomendacao.length > 0) && item.recomendacao }} />
        )
      });
    }

    return null;
  }

  getFeebackRisks = () => {
    const { parecer } = this.props;

    if (Array.isArray(parecer) && parecer.length > 0) {
      const feebackWithRisk = parecer.filter((item) => (typeof item === "object" && "risco" in item));
      if (Array.isArray(feebackWithRisk) && feebackWithRisk.length > 0) {
        return feebackWithRisk.map((feeback) => (
          <SistemicSubtitleSmall>
            { feeback.risco }
          </SistemicSubtitleSmall>
        ));
      }
    }

    return null;
  }

  getFeebackFianca = () => {
    const { parecer } = this.props;

    if (Array.isArray(parecer) && parecer.length > 0 && parecer[0].fc_fianca) {
      const exist = parecer[0].fc_fianca.indexOf('Clique aqui')
      const isPreAprovado = parecer[0].fc_fianca.indexOf('Perfil pré-aprovado')
      if(exist >= 0) {
        const button = <a style={{textDecoration: 'underline', color: isPreAprovado >=0 && '#152b4e' || '#7f7f7f'}} href="javascript:void(0)" onClick={this.handleSendMailFianca}>Clique aqui</a>
        const textStart = parecer[0].fc_fianca.substr(0, exist)
        const textEnd = parecer[0].fc_fianca.substr(exist + 11)
        return (
          <SistemicSubtitleSmall>
            <br />
            <b style={{color: isPreAprovado >= 0 && '#152b4e'}}>FC FIANÇA:</b> <b dangerouslySetInnerHTML={{__html: textStart}} />{button}<span dangerouslySetInnerHTML={{__html: textEnd}} />
          </SistemicSubtitleSmall>
        )
      }
    }
  }

  handleSendMailFianca = (e) => {
    e.preventDefault()
    this.props.toggleModalFianca()
    console.log('submited')
  }

  render() {
    const { parecer } = this.props;
    const statusFirstFeedbackKey = (Array.isArray(parecer) && parecer.length > 0)? parecer[0].parecer_key : "";
    return (
      <SistemicContainer>
        { this.getStatusIcon(statusFirstFeedbackKey) }
        { this.getFeedbackTitles() }
        { this.getFeebackRecommendations() }
        {/* { this.getFeebackRisks() } */}
        { this.getFeebackFianca() }
        <br/>
        <SistemicSubtitleSmall>
          { parecer[0] && parecer[0].observacao_complementar }
        </SistemicSubtitleSmall>
      </SistemicContainer>
    );
  }
}

export default SystemicSight;
