import moment from "moment";
import { isValid } from "cpf";
import CNPJ from "cnpj";
//import Plus from "../components/shared/treatedIcons/Plus";

export const cepValiadtion = value => {
  let error = "CEP deve ter 8 digitos";

  if (value !== undefined) {
    if (typeof value === "string" && value.length === 8) {
      error = undefined;
    }
  }

  return error;
};

export const empityValidation = value => {
  let error = undefined;

  if (typeof value !== "undefined" && value !== null) {
    if (Array.isArray(value) && value.length === 0) {
      error = "campo obrigatório";
    }

    if (typeof value === "string" && value.trim() === "") {
      error = "campo obrigatório";
    }
  } else {
    error = "campo obrigatório";
  }

  return error;
};

export const booleanFieldValidation = value =>
  value !== undefined ? undefined : "Preencher com pelo menos uma opção";

export const checkboxGroupValidation = values => {
  if (typeof values === "number") {
    return undefined;
  }

  if (Array.isArray(values) && values.length > 0) {
    return undefined;
  }

  return "O campo precisa ser preenchido";
};

export const emailValidation = value => {
  const regex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "g"
  );
  const isValid = regex.test(value);

  if (isValid) {
    return undefined;
  }

  return "E-mail de formato invalido";
};

export const emailWarn = value => {
  if (value !== undefined && value.trim() !== "") {
    return emailValidation(value);
  }

  return undefined;
};

export const validateExtraContacts = (obj, fieldName) => {
  const keys = Object.keys(obj);

  const isValid = keys.reduce((valid, contact) => {
    const { nome, email, telefones } = obj[contact];

    const { principal } = telefones;

    if (!valid) {
      return valid;
    }

    if (principal.numero !== "" || email !== "") {
      return nome !== "";
    }

    return valid;
  }, true);

  if (isValid) {
    return {};
  }

  return { [fieldName]: "Nome é obrigatório ao preencher email ou trelefone" };
};

export const validateEmailConfirmation = values => {
  const { email_confirmation, email } = values;

  if (
    email_confirmation === email &&
    emailValidation(email_confirmation) === undefined
  ) {
    return {};
  }

  const error = { email_confirmation: "E-mail não confere" };

  return error;
};

export const validateEmailConfirmationField = (value, allValues) => {
  const { email } = allValues;

  if (value === email && emailValidation(value) === undefined) {
    return undefined;
  }

  return "E-mail não confere com o já preenchido";
};

export const cpfValidation = cpf => {
  if (cpf !== undefined && isValid(cpf)) {
    return undefined;
  }
  if (cpf === undefined) {
    return "Campo precisa ser preenchido";
  }
  return "CPF invalido";
};

export const cnpjValidation = value => {
  if (value !== undefined && CNPJ.validate(value)) {
    return undefined;
  }
  if (value === undefined) {
    return "Campo precisa ser preenchido";
  }
  return "CNPJ invalido";
};

const handleFieldFillStatus = (isRequired, error, hasValidation, dirty) => {
  if (dirty) {
    if (isRequired) {
      if (error !== undefined) {
        return "REQUIRED_ERROR";
      }

      return "VALID";
    }

    if (!isRequired && hasValidation) {
      if (error !== undefined) {
        return "NOT_REQUIRED_VALIDATION_ERROR";
      }

      return "NOT_REQUIRED_VALID";
    }

    return "NON_REQUIRED";
  }

  if (isRequired) {
    return "REQUIRED";
  }

  return "NON_REQUIRED";
};

export const getFieldUnderlineColor = (
  isRequired,
  error,
  hasValidation,
  dirty
) => {
  const fieldStatus = handleFieldFillStatus(
    isRequired,
    error,
    hasValidation,
    dirty
  );
  if (fieldStatus === "REQUIRED") {
    return "color_orange-dark";
  }

  if (fieldStatus === "REQUIRED_ERROR") {
    return "color_dark-red";
  }

  if (fieldStatus === "VALID") {
    return "color_green";
  }

  return "input_underline_default_color";
};

export const shouldShowErrorMessge = (
  isRequired,
  error,
  hasValidation,
  dirty,
  active
) => {
  const fieldStatus = handleFieldFillStatus(
    isRequired,
    error,
    hasValidation,
    dirty,
    active
  );

  if (
    fieldStatus === "REQUIRED_ERROR" ||
    fieldStatus === "NOT_REQUIRED_VALIDATION_ERROR"
  ) {
    return true;
  }

  return false;
};

export const validatePasswordCreation = password => {
  const newState = {};

  if (password !== undefined && password !== "") {
    if (password.search(/\d/g) !== -1) {
      newState.hasNumber = true;
    } else {
      newState.hasNumber = false;
    }

    if (password.search(/[A-Z]/g) !== -1) {
      newState.upperCase = true;
    } else {
      newState.upperCase = false;
    }

    if (password.search(/[a-z]/g) !== -1) {
      newState.lowerCase = true;
    } else {
      newState.lowerCase = false;
    }

    if (password.length >= 6) {
      newState.numberOfChar = true;
    } else {
      newState.numberOfChar = false;
    }

    return newState;
  }

  return {
    numberOfChar: false,
    lowerCase: false,
    upperCase: false,
    hasNumber: false
  };
};

export const validatePassword = value => {
  if (value !== undefined) {
    if (value.search(/\s/g) !== -1) {
      return "A senha não deve ter espaços.";
    }

    if (value.search(/\d/g) === -1) {
      return "A senha deve conter no mínimo um número.";
    }

    if (value.search(/[A-Z]/g) === -1) {
      return "A senha deve conter no mínimo uma letra maiúscula.";
    }

    if (value.search(/[a-z]/g) === -1) {
      return "A senha deve conter no mínimo uma letra minúscula.";
    }
  } else {
    return "Mínimo: 8 caracteres, uma letra maíuscula, uma letra minúscula e um número.";
  }

  return undefined;
};

export const validateConfirmPassword = (value, allValues, props, name) => {
  if (value !== undefined) {
    const section = name.split(".");
    if ("senha" in allValues[section[0]]) {
      const { senha } = allValues[section[0]];
      if (senha !== value) return "As senhas não conferem!";

      return undefined;
    }
    return "As senhas não conferem!";
  }

  return "A confirmação de senha não deve ser vazia.";
};

export const validateDate = value => {
  if (value) {
    let date = value.replace(/\//g, '').replace(/_/g, "").replace(/-/g, "");

    if (date.length === 8) {
      if (!moment(value).isValid) {
        return "Informe uma data válida";
      }
    } else {
      return "Informe uma data no formato DIA/MÊS/ANO";
    }
  } else {
    return "Informe uma data.";
  }
  return undefined;
};

export const validatePhone = value => {
  if (value) {
    if (value === "") {
      return "Informe um telefone.";
    }

    if (value.length < 10) {
      return "Informe um telefone válido.";
    }
  } else {
    return "Informe um telefone.";
  }
  return undefined;
};
