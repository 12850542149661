import React from 'react';
import { Tr, Th } from '../../../../components/shared/customTable.styled';

const SubTableHeader = (props) => {
  return (
  <div style={{ display: 'flex', justifyContent: 'space-around'}}>
    <Th width="10rem">
      PRETENDENTE
    </Th>
    <Th width="10rem">
      SOLICITAÇÃO
    </Th>
    <Th width="10rem">
      PRODUTO
    </Th>
    {/* {props.master &&
      <Th width="10rem">
          VALOR
      </Th>
    } */}
  </div>
  )
}

export default SubTableHeader