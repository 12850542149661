
export const onOpenModal = (state, payload) => ({
  ...state,
  isOpen: true,
  fields: payload
});

export const onCloseModal = (state) => ({
  ...state,
  isOpen: false,
  fields: []
});

export const onOpenImportantFieldsModal = (state, payload) => ({
  ...state,
  importantFields: {
    isOpen: true,
    fields: payload
  }
});

export const onCloseImportantFieldsModal = (state) => ({
  ...state,
  importantFields: {
    isOpen: false,
    fields: []
  }
});
