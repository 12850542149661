import styled from 'styled-components';
import { BaseScaleButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { LoadingContainer } from '@bit/wavfichacerta.fichacerta.tables.styles';
import { transcode } from 'buffer';

export const Subtitle = styled.p`
  font-size: 11px;
  font-size: .75rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: ${props => props.align ? props.align : 'left'};
  color: ${props => props.theme.color_purple};
  margin: ${props => props.margin || 0};
`;

export const SubtitleNoMargin = styled(Subtitle)`
  display: flex;
  align-self: flex-start;
  width: ${props => props.width || 'auto'};
  font-size: 11px;
  margin: 0;
`;

export const CardText = styled.p`
  @media(max-width: 800px){
    width: 62%; 

  }

  font-size: 11px;
  text-align: ${props => props.align ? props.align : 'left'};
  color: ${props => props.theme[props.color || 'color_gray']};
  margin: ${props => props.margin || 0} !important;
  max-width: 290px;
  max-height: 35px;
  margin: 0 auto;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 400;

  ${({customstyle}) => customstyle}
`;

export const CardTextNoMargin = styled(CardText)`
  margin: 0;
  font-size: 12px; 
`;

export const TextContainer = styled.div`
  font-size: 11px;
  width: 100%;
  display: flex;
  justify-content: ${props => props.align ? props.align : 'flex-start'};
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
  align-items: center;

  @media(max-width: 800px){
    justify-content: ${props => props.alignMobile ? props.alignMobile : 'flex-start'};
    flex-direction: ${props => props.flexDirectionMobile ? props.flexDirectionMobile : 'row'};
  }
`;

export const TextBold = styled.span`
  font-weight: 600;
`;

export const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Separator = styled.hr`
  width: 100%;
  border: 0;
  height: .05rem;
  margin-top: .2rem;
  margin-bottom: .5rem;
  background-color: ${props => props.theme['color_border-light-gray']}; 
`;

export const CardButton = styled(BaseScaleButton)`
  border: 0;
  padding: 0;
  & > svg {
    width: 1.3rem;
    
    & path, polygon {
      fill: #152b4e;
    }
  }

  &:active {background-color: transparent;border-color: transparent;}
`;

export const LoadingOperational = styled(LoadingContainer)`
  width: calc(100% - 20%);
  bottom: unset;
`;
