import React from 'react';
import { MaxPerPageCol, Typography } from './footer.styles';
import { StyledRow } from '../sharedStyles.styles';
import MaxOfResultsSelect from './MaxOfResultsSelect'; 
import { PDFButton } from '../../../components/Header/ButtonsHeader/DefaultButton';

const MaxPerPage = ({ onSelect, max, data }) => {
  return(
  <MaxPerPageCol>
    <StyledRow justify="flex-start" margin="1px 10px 1px 0px">
    
    {data.print ? <PDFButton>Imprimir/Salvar PDF</PDFButton> : '' }
    
    <Typography>
        Resultados por página:
      </Typography>
      <MaxOfResultsSelect onChange={onSelect} value={max} />
    </StyledRow>
    
  </MaxPerPageCol>
)};

export default MaxPerPage;
