import * as types from '../actions/types';
import {
  onSendCustomerMessage,
  onSendCustomerMessageSuccess,
  onSendCustomerMessageFailed
} from './reducers';

const initialState = {
  isRequesting: false,
  error: undefined,
  isSending: false,
  errorSending: undefined,
  data: {}
};

export default (state = initialState) => {
  switch(types) {
    case types.CUSTOMER_SEND_MESSAGE:
      return onSendCustomerMessage(state);
    case types.CUSTOMER_SEND_SUCCESS:
      return onSendCustomerMessageSuccess(state);
    case types.CUSTOMER_SEND_FAILED:
      return onSendCustomerMessageFailed(state);
    default:
      return state;
  }
}