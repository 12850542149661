import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { FullColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { PrimaryButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { closeImportantFieldsModal } from './actions';
import { Container, OrderedList, SpanTitle, BlueText, GrayText } from './formModalFields.styled';

const titles = {
  tenancyForm: 'LOCAÇÃO',
  applicantForm: 'PRETENDENTE',
  incomeForm: 'RENDA MENSAL E PROFISSÃO'
};

class ImportantFieldsModal extends PureComponent {
  static defaultProps = {
    entity: 'PF'
  }

  renderFieldsName = (fields) => {
    if (Array.isArray(fields) && fields.length > 0) {
      return fields.map((field, idx) => (
        <li key={idx}>
          {field}
        </li>
      )
      );
    }
    return null;
  }

  renderSection = () => {
    const { fields } = this.props;
    const sections = Object.keys(fields).map((formName) => {
      if (Array.isArray(fields[formName]) && fields[formName].length > 0) {
        return (
          <div key={formName}>
            <BlueText>{titles[formName]}</BlueText>
            <OrderedList customstyle="margin-bottom: 10px">
              {this.renderFieldsName(fields[formName])}
            </OrderedList>
          </div>
        );
      }

      return null;
    });

    return sections;
  }

  renderFooter = () => (
    <StyledRow padding='0 3em' justify="space-around">
      <PrimaryButton data-testid="go-back-button" onClick={this.props.closeImportantFieldsModal} >Voltar</PrimaryButton>
      <PrimaryButton data-testid="send-data-button" onClick={this.props.sendData}>Avançar</PrimaryButton>
    </StyledRow>
  )

  renderHeader = () => (
    <SpanTitle>
      ATENÇÃO
    </SpanTitle>
  )

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        size="md"
        onClose={this.props.closeImportantFieldsModal}
        footer={this.renderFooter()}
        header={this.renderHeader()}
      >

        <Container>
          <GrayText data-testid="gray-text">Os seguintes campos não foram preenchidos:</GrayText>
          <FullColumn>
            {this.renderSection()}
          </FullColumn>
          <GrayText>
            Preenchê-los nos ajudará a gerar um laudo mais assertivo. <br />Gostaria de AVANÇAR MESMO ASSIM?
          </GrayText>
        </Container>

      </ModalContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeImportantFieldsModal
}, dispatch);

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  fields: state.modalRequestFields.importantFields.fields,
  isOpen: state.modalRequestFields.importantFields.isOpen,
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportantFieldsModal);
