import React, { Component } from 'react'
import {
  StyledTableBody, 
  StyledTable, 
  ContainerBody,
  LoadingContainer,
  ContainerGifLoading
} from './styled';
import icons from '../treatedIcons';

const { LoadingCircle } = icons;

class CustomTable extends Component {
  static defaultProps = {
    loading: false,
    maxSize: 'default'
  }
  
  mapLines = () => {
    const { lines } = this.props;
    if (Array.isArray(lines) && lines.length > 0) {
      const mappedLines = this.props.lines.map(this.props.renderLine);


      return mappedLines;
    }
    return null;
  }

  render() {
    const itemsQuantity = (Array.isArray(this.props.lines) && this.props.lines.length) || 0;
    return(
      <ContainerBody loading={this.props.loading}>
      {this.props.loading &&
              <LoadingContainer>
                <ContainerGifLoading>
                  <LoadingCircle />
                </ContainerGifLoading>
              </LoadingContainer>
            }
      <StyledTable striped responsive maxSize={this.props.maxSize} items={itemsQuantity}>
        <thead>
          {this.props.renderHeader()}
        </thead>
        <StyledTableBody loading={this.props.loading}>
            
            {this.mapLines()}
          
        </StyledTableBody>
      </StyledTable>
      </ContainerBody>
    );
  }
}

export default CustomTable;
