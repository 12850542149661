import React, { PureComponent } from 'react';

import ModalContainer from '../shared/modal/ModalContainer';
import { PrimaryButton, StyledRow, SecundaryButton } from '../shared/sharedStyles.styles';
import { Container, SpanTitle, GrayText } from '../../pages/operational/request/form/fieldModal/formModalFields.styled';

class UndoReinclusionModal extends PureComponent {

  renderFooter = () => (
    <StyledRow padding='0 3em' justify="space-around">
      <SecundaryButton data-testid="send-data-button" onClick={this.props.onClose}>NÃO</SecundaryButton>
      <PrimaryButton data-testid="go-back-button" onClick={this.props.undo} >SIM</PrimaryButton>
    </StyledRow>
  )

  renderHeader = () => (
    <SpanTitle customstyle="margin: auto;">
      CANCELAR RECONSULTA
    </SpanTitle>
  )

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        size="md"
        onClose={this.props.onClose}
        footer={this.renderFooter()}
        header={this.renderHeader()}
        customstyle='
          @media (max-width: 800px) {
            h5 {
              font-weight: 400;
            }
          }
        '
        customstyleModalFooter='
          @media (max-width: 800px) {
            button {
              width: 121px;
              height: 33px;
              font-size: .88rem;
              background-color: #152b4e;
              padding: 0;
              margin-top: 0;
              margin: 0 9px;
            }

            .row {
              justify-content: center;
              padding: 0;
            }
          }
        '
      >

        <Container
          customstyle="
            text-align: center;
            margin: 20px auto;

            span {
              font-size: 12px;
              font-weight: 400 !important;
            }

            @media (max-width: 800px) {
              margin: 10px auto;
            }
          "
        >
          <GrayText>
            Deseja cancelar a reinclusão deste produto?
          </GrayText>
        </Container>

      </ModalContainer>
    );
  }
}

export default UndoReinclusionModal;