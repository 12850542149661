import styled from 'styled-components';

export const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  justify-content: space-between;
`;

export const StepHr = styled.div`
  width: auto;
  max-width: 100%;
  height: 0;
  flex-grow: 1;
  background-color: ${props => props.active? props.theme['color_light-green'] : props.theme['color_border-gray']};
  border: .1rem solid ${props => props.active? props.theme['color_light-green'] : props.theme['color_border-gray']};
`;
