import React from 'react';
import Styled from 'styled-components';

const StyledSvg = Styled.svg`
  width: ${props => props.width ? props.width : '15px'};
  fill: ${props => props.color ? props.color : props.theme.color_gray};
  display: ${props => props.display ? props.display : "inline-block"};
  margin: ${props => props.margin ? props.margin : "-3px 0 0 0"};
  
  &:active:after,
  &:active:before {
    transform: rotate(180deg);
  }
`;

export default (props) => (
  <StyledSvg {...props} viewBox="0 0 284.929 284.929">
    <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
		L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
		c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
		c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"/>
  </StyledSvg>
)