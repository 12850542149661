
export const getRequestingState = (state) => {
  const { requesting, error, data } = state.report;

  if (!requesting && error === undefined && Object.keys(data).length > 0) {
    return requesting;
  }

  return true;
}

export const reportData = state => state.report.data;
export const getReportApplicants = (state) => state.report.applicants;
