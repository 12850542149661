import { change } from 'redux-form';
import { FETCH_CEP_FAIL, CEP_REQUEST, CEP_SUCCESS, CEP_RESET_STATE, REGISTER_DIRTY_FIELD } from './types';
import { cepRequest } from '../../../services/cep';
import { actionCreator } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { toastr } from 'react-redux-toastr';

export const resetCEPState = () => actionCreator(CEP_RESET_STATE);
export const registerDirtyField = (fieldPath) => actionCreator(REGISTER_DIRTY_FIELD, fieldPath);

export const fetchCEP = (cep , formName, path) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(CEP_REQUEST));
      toastr.info('Procurando CEP');
      const payload = await cepRequest(cep, 'VIA_CEP');

      if (payload.data && payload.data.erro) {
        dispatch(actionCreator(FETCH_CEP_FAIL));
        toastr.warning('CEP não encontrado, por favor digite o endereço');
      }

      let section = '';
      if(path){
        section = `${path}.`;
      }
      dispatch(actionCreator(CEP_SUCCESS));
      dispatch(change(formName, `${section}logradouro`, payload.data.logradouro));
      dispatch(change(formName, `${section}bairro`, payload.data.bairro));
      dispatch(change(formName, `${section}cidade`, payload.data.localidade));
      dispatch(change(formName, `${section}uf`, payload.data.uf));
    } catch(e) {
      console.log(e)
    }
  };
};
