import { filterProducts, updateProductById } from '../../../../../../utils/clientsTableHelper';

export const onEditProductRow = (state, payload) => (
  {
    ...state,
    editableRow: payload
  }
);

export const onGetProductsSuccess = (state, payload) => (
  {
    ...state,
    requesting: false,
    error: undefined,
    list: payload
  }
)


export const onGetProductsFailed = (state, payload) => (
  {
    ...state,
    requesting: false,
    error: payload
  }
);

export const onGetProducts = (state, payload) => (
  {
    ...state,
    requesting: true,
    error: payload
  }
);

export const onAddProduct = (state, payload) => (
  {
    ...state,
    list: [...state.list, payload]
  }
);

export const onRemoveProduct = (state, payload) => (
  {
    ...state,
    list: filterProducts(state.list, payload)
  }
);

export const onUpdateProduct = (state, payload) => (
  {
    ...state,
    list: updateProductById(state.list, payload)
  }
);

export const onResetReduxProducts = (state) => (
  {
    ...state,
    list: []
  }
);