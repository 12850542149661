import React, { PureComponent, Fragment } from 'react';

import { StepsContainer, BoxContainer, StepHr } from './steps.styles';
import StepBox from './StepBox';
import Loading from '@bit/wavfichacerta.fichacerta.icons.loading';
import Check from '../shared/treatedIcons/Check';

class Steps extends PureComponent {

  static defaultProps = {
    items: []
  }

  renderStep = () => {
    const { items, fcrenda, status } = this.props;
    let steps = null;

    if(items.length > 0){
      steps = items.map((item, index) => {
        console.log("item", fcrenda);
        const hasNext = items[index+1];
        const activePrevLine = (items[index-1] && items[index-1].checked && item.checked);
        const activeNextLine = (items[index+1] && items[index+1].checked && item.checked);
        return (
          <BoxContainer key={index}>
            {index > 0 && <StepHr active={activePrevLine} />}
            <StepBox checked={item.id === 1 && true} className='ignore-css-print stepbox-print' style={{color: '#000'}}>
              {item.label} {item.checked? <Check width="10px" color={item.id === 1 && '#7f7f7f' || "#fff"} /> : <Loading width="18px" height="18px" margin="0" color={item.id === 1 && 'color_gray'} />}
            </StepBox>

            {Object.keys(fcrenda).length > 0 && fcrenda.ativo &&
              <Fragment>
                <div style={{backgroundColor: item.id === 1 && '#ffefcb' || '#152b4e', height: 3, width: '100%'}}></div>
                <StepBox checked={item.id === 1 && true} className='ignore-css-print stepbox-print' style={{color: '#000'}}>
                  FC DOC {item.checked && status === "CONCLUIDO"? <Check width="10px" color={item.id === 1 && '#7f7f7f' || "#fff"} /> : <Loading width="18px" height="18px" margin="0" color={item.id === 1 && 'color_gray'} />}
                </StepBox>
              </Fragment>
            }
            
            {hasNext && <StepHr active={activeNextLine} />}
          </BoxContainer>
        );
      });
    }

    return steps;
  }

  render() {

    return (
      <StepsContainer className="reportHeader">
        {this.renderStep()}
      </StepsContainer>
    );
  }
}

export default Steps;