import React, { PureComponent, Fragment } from 'react'
import inputHandler from './inputHandler';
import { Row, Col } from 'reactstrap';
import { StyledLabel } from './Inputs.styles';

class ComposedInputs extends PureComponent {
  renderFields = () => {
    const { innerInputs, after } = this.props;
    const mappedFields = innerInputs.map(input => {
      if (input.type === 'select') {
        return  inputHandler({ ...input, options: this.props.options});
      }

      return (
        <Fragment key={input.name}>
          {inputHandler(input)}
          {after}
        </Fragment>
      );
    });

    return mappedFields;
  }

  render() {
    const { size, label } = this.props;
    return (
      <Col style={{ margin: 0, padding: 0}} className={size}>
        <StyledLabel style={{ position: 'absolute', bottom: '30px', left: '12px'}}>{label}</StyledLabel>
        <Row style={{ margin: 0, padding: 0, width: '100%'}}>
          {this.renderFields()}
        </Row>
      </Col>
    );
  }
}

ComposedInputs.defaultProps = {
  isRequired: false
}

export default ComposedInputs;

