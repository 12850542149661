import {
    simpleNonRequiredMoney,
    simpleNonRequiredSelect,
    simpleRequiredText,
    simpleDisabledText,
    simpleRequiredSelect,
    simpleRequiredMoney
  } from '../../../../../components/shared/fields';
  
  export default {
    "renda_principal_review": simpleNonRequiredMoney({ name: 'renda_principal_review', label: 'Renda principal', size: 'field-fc-small field-fc-sm-4' }),
    "origem_renda_review": simpleNonRequiredSelect({ size: 'field-fc-medium field-fc-sm-8', name: 'origem_renda_review', label: 'Origem' }),
    "outras_rendas_review": simpleNonRequiredMoney({ name: 'outras_rendas_review', label: 'Outras rendas', size: 'field-fc-small field-fc-sm-4' }),
    "origem_renda_secundaria_review": simpleNonRequiredSelect({ size: 'field-fc-medium field-fc-sm-8', name: 'origem_renda_secundaria_review', label: 'Origem' }),
    "total_renda_review": simpleDisabledText({ size: 'field-fc-small field-fc-sm-4', name: 'total_renda_review', label: 'Renda pessoal total' }),
    "compor_renda_review": simpleRequiredSelect({ size: 'field-fc-small', name: 'compor_renda_review', label: 'Irá compor renda?' }),
    "faturamento_mensal": simpleRequiredMoney({ size: 'field-fc-medium', name: 'faturamento_mensal', label: 'Faturamento bruto médio mensal' }),
    "tipo_negocio": simpleRequiredSelect({ size: 'field-fc-medium', name: 'tipo_negocio', label: 'Tipo de negócio' }),
    "natureza_juridica": simpleRequiredSelect({ size: 'field-fc-medium', name: 'natureza_juridica', label: 'Natureza jurídica' }),

    "limite_cartao_credito_review": simpleRequiredMoney({ name: 'limite_cartao_credito_review', label: 'Valor do limite', size: 'field-fc-small field-fc-sm-4' }),
    "numero_cartao_credito_review": simpleRequiredText({ name: 'numero_cartao_credito_review', label: 'Nº do cartão', size: 'field-fc-medium field-fc-sm-8' }),
    "validade_cartao_credito_review": simpleRequiredText({ name: 'validade_cartao_credito_review', label: 'Validade', size: 'field-fc-small field-fc-sm-4' }),
    "bandeira_cartao_credito_review": simpleRequiredText({ name: 'bandeira_cartao_credito_review', label: 'Bandeira', size: 'field-fc-small field-fc-sm-4' }),
  
    "movimentacao_bancaria_extratos_review": simpleRequiredMoney({ name: 'movimentacao_bancaria_extratos_review', label: 'Valor médio das receitas mensais (confirmado)', size: 'field-fc-small field-fc-sm-4' }),

  };