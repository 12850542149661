import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Header from "../../../components/Header";
import HeaderButton, {
  ButtonEndWrapper,
  RequestButton
} from "../../../components/Header/ButtonsHeader/DefaultButton";
import { StyledSpan, StyledMainComponent } from "./../requests.styled";
import { StyledRow } from "@bit/wavfichacerta.fichacerta.shared-styles.rows";
import { PageContainer } from "@bit/wavfichacerta.fichacerta.shared-styles.containers";
import FcFiancaTable from "./FcFiancaTable";
import RequestsMobileTable from "./../table/RequestsMobileTable";
import FcFiancaMobileTable from "./FcFiancaMobileTable";
import SearchGroup from "../../../components/Header/Search/SearchGroup";
import SearchGroupMobile from "../../../components/Header/Search/SearchGroupMobile";
import {
  setFilter,
  setFinalDate,
  setStartDate,
  getFCFiancaRequests,
  changePage
} from "./actions";
import { getLoadingStatus } from "./../table/selector";
import FilterModal from './../modal/FilterModal';
import { MobileFilterButton } from "../../../components/Header/Search/SearchStyles";
import AddIcon from "../../../components/shared/icons/AddIcon";
import { Main, MainContainer } from './../request/resume/RequestResume';
import CheckBox from '../../../components/shared/CheckBox'
import styled from 'styled-components'

const breadcrumb = [{ link: "", label: "Operacional" }];

const WrapperCheckbox = styled.div`
  @media(max-width: 801px) {
    margin-left: 10px;
    margin-top: 16px;
  }
`

class FCFianca extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      print: false,
      isMobile: false,
      toggle: false,
      checked: false
    }
  }

  componentDidMount() {
    this.setState({
      isMobile: window.matchMedia("(max-width: 800px)").matches
    });
  }

  addRequest = () => {
    const { history } = this.props;
    history.push(
      "/intranet/operacional/solicitacao/adicionar/selecionar-produto"
    );
  };

  onFilter = text => {
    const { setFilter } = this.props;
    setFilter(text);
  };

  onSetStartDate = date => {
    this.props.setStartDate(date);
  };

  onSetEndDate = date => {
    this.props.setFinalDate(date);
  };

  onFilterDate = () => {
    this.props.changePage(1);
    this.props.getFCFiancaRequests(this.state.checked);
  };

  onChangeCheckbox = () => {
    this.setState({
      checked: !this.state.checked
    }, () => {
      this.onFilterDate()
    })
  }

  toggle = () => {
    this.setState({
      toggle: !this.state.toggle
    })
  }

  adjustToPrint = () => {
    this.setState({
      print: true
    });

    setTimeout(() => {
      window.print();
    }, 2000);

    setTimeout(() => {
      window.location.reload();
    }, 2500);
  };

  render() {
    return (
      <Main>
        <Header
          breadcrumb={breadcrumb}
          title="FC FIANÇA"
          actualLink="FCFiança"
        />
        <MainContainer>
          <StyledRow justify="space-between" customstyle="margin: 0 0 35px 0; height: 25px;">
            <MobileFilterButton onClick={this.toggle.bind(this)} >
                Filtrar
            </MobileFilterButton>
            <WrapperCheckbox style={{marginBottom: -6}}>
              <CheckBox checked={this.state.checked} onChange={this.onChangeCheckbox} label="Exibir Concluídas"></CheckBox>
            </WrapperCheckbox>
            { !this.state.isMobile && <SearchGroup
                endDate={this.onSetEndDate}
                startDate={this.onSetStartDate}
                setFilter={this.onFilter}
                filterDate={this.onFilterDate}
                hiddenInputFilter
            /> }
            {this.state.isMobile && 
            <FilterModal toggleModal={this.toggle} isOpen={this.state.toggle}>
              <SearchGroupMobile
                endDate={this.onSetEndDate}
                startDate={this.onSetStartDate}
                setFilter={this.onFilter}
                filterDate={this.onFilterDate}
                hiddenInputFilter
              />
            </FilterModal> }
              {this.state.isMobile && (
                <RequestButton>
                  <AddIcon margin="0" position="absolute" boxShadow="0px 0px 4px gray" width="45px" onClick={this.addRequest} />
                </RequestButton>
              )}
              {/* {!this.state.isMobile && (
                <ButtonEndWrapper onClick={this.addRequest}>
                  <HeaderButton>
                    <StyledSpan color="color_white" styles="margin: 0 5px">
                      Nova Locação
                    </StyledSpan>
                  </HeaderButton>
                </ButtonEndWrapper>
              )} */}
          </StyledRow>
          {this.state.isMobile && <div style={{padding: '0 2.5%'}}><FcFiancaMobileTable complete={this.state.checked} /></div>}
          {!this.state.isMobile && <FcFiancaTable complete={this.state.checked} />}
        </MainContainer>
      </Main>
    );
  }
}

function MapStateToProps(state) {
  return {
    date: state.fcfiancaTable.pagination.date,
    requesting: getLoadingStatus(state)
  };
}

const MapDispatchToProps = dispatch =>
  bindActionCreators(
    { setFilter, setFinalDate, setStartDate, getFCFiancaRequests, changePage },
    dispatch
  );

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(FCFianca);
