import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default (key, configs, reducer) => {
  const config =  {
    key,
    storage,
    ...configs
  };

  return persistReducer(config, reducer);
};
