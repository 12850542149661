import React, { Component } from "react";

import ModalContainer from "@bit/wavfichacerta.fichacerta.modals.modal-container";
import { PrimaryButton } from "@bit/wavfichacerta.fichacerta.shared-styles.buttons";
import { StyledRow } from "@bit/wavfichacerta.fichacerta.shared-styles.rows";
import {
  Container,
  SpanTitle,
  GrayText
} from "../form/fieldModal/formModalFields.styled";

class FCCompletaProcessModal extends Component {
  renderFooter = status => {
    const { fromWhere } = this.props;

    if(fromWhere === 'finalize'){
      if (status) {
        return status[0][0] !== "CONCLUIDO" ? (
          <StyledRow padding="0 3em" justify="center">
            <PrimaryButton
              data-testid="go-back-button"
              onClick={this.props.justCloseModal}
            >
              Voltar
            </PrimaryButton>
            <div style={{ width: "20px" }}></div>
            <PrimaryButton
              data-testid="advance-button"
              onClick={() => this.props.closeModalAndGo('charge')}
            >
              Avançar
            </PrimaryButton>
          </StyledRow>
        ) : (
          <StyledRow padding="0 3em" justify="center">
            <PrimaryButton
              data-testid="go-back-button"
              onClick={() => this.props.closeModalAndGo('charge')}
            >
              OK
            </PrimaryButton>
          </StyledRow>
        );
      }
    } else {
      return (
        <StyledRow padding="0 3em" justify="center">
          <PrimaryButton
            data-testid="go-back-button"
            onClick={this.props.justCloseModal}
          >
            Voltar
          </PrimaryButton>
          <div style={{ width: "20px" }}></div>
          <PrimaryButton
            data-testid="advance-button"
            onClick={() => this.props.closeModalAndGo('show')}
          >
            Avançar
          </PrimaryButton>
        </StyledRow>
      )
    }
  };

  renderHeader = () => (
    <SpanTitle customstyle="margin: auto;">ATENÇÃO</SpanTitle>
  );

  renderText = statuses => {
    if (statuses) {
      const selection = statuses.find(status => status[1] !== 'CONCLUIDO')
      switch (this.props.fromWhere) {
        case "finalize":
          return this.props.soTemFcCompletaSemAnalise
            ? "Você acaba de solicitar pelo menos uma FC COMPLETA. O prazo para retorno é de em média 1 (uma) hora, das 9h às 17h. O laudo será gerado após a conclusão da analise dos documentos anexados."
            : "Você tem pelo menos uma FC COMPLETA com a solicitação ainda não finalizada. O laudo que será gerado agora não contempla o resultado desta(s) FC COMPLETA.";
        default:
          return selection[1] === "INCLUIDO" && selection[0] === "CONCLUIDO"
            ? "Você tem pelo menos uma FC COMPLETA com a solicitação ainda não finalizada. O laudo que será gerado agora não contempla o resultado desta(s) FC COMPLETA."
            : "Você tem pelo menos uma FC COMPLETA ainda sem conclusão. O laudo que será gerado agora não contempla o resultado desta(s) FC COMPLETA.";
      }
    }
  };

  render() {
    return (
      <ModalContainer
        isOpen={this.props.isOpen}
        size="md"
        onClose={this.props.justCloseModal}
        footer={this.renderFooter(this.props.status)}
        header={this.renderHeader()}
      >
        <Container customstyle="text-align: center; margin: 20px auto;">
          <GrayText>{this.renderText(this.props.status)}</GrayText>
        </Container>
      </ModalContainer>
    );
  }
}

export default FCCompletaProcessModal;
