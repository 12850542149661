import React, {Fragment, Component} from 'react';
import { Tr, Td } from '../../../../components/shared/customTable.styled';
import DownArrow from '../../../../components/shared/icons/DownArrow';
import {Collapse} from 'reactstrap';
import SubTableHeader from './SubTableHeader';
import SubTableLines from './SubTableLines';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import CustomTable from '@bit/wavfichacerta.fichacerta.tables.custom-table';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';

class ChargeTableLines extends Component {
  state = {
    isOpen: {}
  }

  addressMounter = () => {
    const {locacao: {endereco}} = this.props;
    if(endereco[0])
    return `${endereco[0].logradouro ? endereco[0].logradouro + ', ' : ''} ${endereco[0].numero ? endereco[0].numero + ' - ' : ''} ${endereco[0].complemento ? endereco[0].complemento + ' - ' : ''} ${endereco[0].bairro ? endereco[0].bairro + ', '  : ''} ${endereco[0].cidade ? endereco[0].cidade + ' / ' : ''} ${endereco[0].uf || ''}`
    else
      return null;
  }

  handleCollapse = () => {
    const isOpen = {
      ...this.state.isOpen,
      [this.props.id]: !this.state.isOpen[this.props.id]
    }
    this.setState({isOpen})
  }

  render(){
    if(!Array.isArray(this.props.pretendentes) || !this.props.locacao){
      return null
    }
    
    const {id, valor_pago, pretendentes, index, resumo, locacao, solicitante, master} = this.props

    return (
      <Fragment>
        <Tr onClick={this.handleCollapse} >
          <Td width="50px" bordercolor="color_white" customStyle={{ textAlign: 'center'}}>
            {id}
          </Td>
          <Td width="120px" bordercolor="color_white" customStyle={{ textAlign: 'center'}}>
            {locacao.codigo_imovel}
          </Td>
          <Td width="auto" bordercolor="color_white">
            {this.addressMounter()}
          </Td>
          {/* {master &&
            <Td width="80px" bordercolor="color_white">
              {valor_pago}
            </Td>
          } */}
          <Td width="30px" bordercolor="color_white">
            <DownArrow />
          </Td>
        </Tr>
        <Collapse isOpen={this.state.isOpen[id]} >
          <div className={index % 2 ? "even" : "odd"}>
            <StyledRow>
              <BaseColumn xs="12" style={{paddingLeft: 50, paddingRight: 30}}>
                <CustomTable
                  lines={pretendentes}
                  renderHeader={() => <SubTableHeader master={master} />}
                  renderLine={(line) => SubTableLines({...line, resumo, solicitante, master})}
                  loading={false}
                />
              </BaseColumn>
            </StyledRow>
          </div>
        </Collapse>
      </Fragment>
    )
  }
}

export default ChargeTableLines