import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  transform: scaleY(-1);
  width: ${props => props.width || '100%'};

  & > path:nth-child(1) { fill: ${props => props.theme['color_dark-green']}; }
  & > path:nth-child(2) { fill: ${props => props.theme['color_extra-light-green']}; }
  & > path:nth-child(3) { fill: ${props => props.theme.color_green}; }
`;

export default (props) => (
  <SVG {...props} viewBox="0 0 204.85 196.21">
    <path class="cls-1" d="M84.52,120.68c-.09,4.55-2.43,5.78-6.27,5.76-18.66-.09-37.32,0-56-.05-4.77,0-6.07-1.35-6.07-6.24q0-49.18,0-98.35c0-5,1.3-6.37,6.4-6.38q27.59,0,55.17,0c5.4,0,6.57,1.21,6.63,6.53.07,6.75,0,6.66,6.28,4.67A53.92,53.92,0,0,1,107.18,24c25.32,0,50.64.21,76-.08,14.49-.17,24.26,13,19.47,25.8-.57,1.51-.18,2.23,1.13,3.2,7.93,5.92,10.28,13,7.45,22.41-.48,1.61-.19,2.23,1.08,3.19,9.73,7.32,11.51,17.85,4.57,27.55-1.12,1.56-.9,2.36,0,3.72,4.83,6.93,5.54,14.31,1.72,21.86s-10.4,11.63-19,11.7c-16.26.12-32.52,0-48.78.05H146c1.93,6.82,3.85,13.29,5,19.94,1.94,10.92,2.82,21.72-3.16,31.79-4.5,7.57-9.84,14.44-19.17,16.17-6.21,1.15-11.78-1.18-16.82-4.7-1.84-1.29-1.93-3.37-1.92-5.41,0-6.8-.18-13.6.07-20.39a26.38,26.38,0,0,0-2.73-12.64c-6.82-14.41-13.41-28.94-20.14-43.4C86.61,123.49,86.34,122,84.52,120.68Z" transform="translate(-16.2 -15.39)"/>
    <path class="cls-2" d="M143.9,32.63c12.93,0,25.86.11,38.79-.05,5.14-.06,9.22,1.44,11.57,6.15,2.24,4.47,1,8.42-2.1,12.16-2.63,3.2-1.67,6,2.23,7.39,10.27,3.75,12.06,9.29,6,18.53-2.22,3.38-1,5.93,3.44,7.24,6.25,1.84,9.29,6.23,8.31,12.17-.53,3.19-2.4,5.49-5,7.38-3.69,2.68-3.71,5-.35,8.06,5.26,4.79,6.74,9.41,4.72,14.85S204.83,135,197.92,135c-18.53,0-37.05,0-55.58,0-6.24,0-7.67,2-5.59,7.88a148.06,148.06,0,0,1,7.3,32.64c1,9.14-5.54,21.86-13.65,26.46-3.78,2.14-7.2.67-10.68-.7-1.27-.5-1.11-1.67-1.11-2.73a102.22,102.22,0,0,1,.05-10.39c1.12-11-1.7-20.84-6.72-30.59-6.39-12.41-11.92-25.28-17.63-38-1.5-3.35-3.39-6-7-7.15-2.42-.78-3-2.35-3-4.83q.14-32.59,0-65.18c0-2.91,1.08-4.31,3.51-5.43a45.84,45.84,0,0,1,19.7-4.37C119.65,32.68,131.78,32.63,143.9,32.63Z" transform="translate(-16.2 -15.39)"/>
    <path class="cls-3" d="M25,70.4c0-14.13.09-28.27-.07-42.4,0-3.13.82-4.11,4-4.07,14.4.17,28.8.19,43.2,0,3.53,0,4.26,1.25,4.08,4.4-1.53,26.76-.45,53.54-.77,80.31a28.28,28.28,0,0,0,.06,3.6c.63,5.41.65,5.41-4.59,5.41-14,0-28-.1-42,.07-3.25,0-4-1-4-4.11C25,99.2,25,84.8,25,70.4Z" transform="translate(-16.2 -15.39)"/>
  </SVG>
);
