import styled, { css } from 'styled-components';
import { BaseScaleButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons'

export const SubmitButton = styled(BaseScaleButton)`
  background-color: ${props => {
    if (props.disabled) {
      return props.theme.color_gray;
    }

    return props.theme.color_purple;
  }};
  padding: .4rem 1.5rem .4rem 1.5rem;
  border-radius: 0;
  font-weight: 400;
  border-color: transparent;
  ${props => css`${props.customstyle}`};
`;

export const BtHeader = styled.button`
  outline: 0;
  border: 0;
  background: transparent;
  text-align: center;
  display: inline-block;
  font-size: .7rem;
  color: ${props => props.theme.color_gray};

  &:focus { outline: 0; }

  &:disabled {
    & > a {
      cursor: initial;
      & > svg {
        fill: ${props => props.theme.color_gray};
      }
      &:hover {
        transform: unset;
      }
    }
  }
`;

export const ButtonLink = styled.a`
  height: 1.2rem;
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: ${props => props.theme.color_beige} !important;
  border: 1px solid;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  box-shadow: 0px 0px 4px ${props => props.theme['color_gray-X11-gray']};
  border-color: transparent;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  cursor: pointer;

  &:hover {
    transition: all 200ms ease-in;
    transform: scale(1.1);
  }
`;
