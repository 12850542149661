import React, { PureComponent } from 'react';

import Card from '@bit/wavfichacerta.fichacerta.cards.card';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { CardText, CardButton, Subtitle } from './operational.styles';
import { formatToBRL } from '../../utils/formatter'
import CustomTolltip from '@bit/wavfichacerta.fichacerta.tooltip.custom-tooltip';
import Edit from '@bit/wavfichacerta.fichacerta.icons.edit';
import styled, { createGlobalStyle } from 'styled-components'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import iconDots from './../../img/png/ico-dots-vertical.png'
import TourTooltip from '../shared/TourTooltip';

const GlobalStyle = createGlobalStyle`
  @media (max-width: 800px) {
    .hidden-in-mobile {
      display: none !important;
    }
  }
`

const StyledRealtyCard = styled(Card)`
  @media (max-width: 800px) {
    box-shadow: none;
    padding: 0 2.5% 1.5%;
    padding-top: 0;

    .wrapper-box-info {
      display: flex;
      justify-content: space-between;

      .box-info {
        ${Subtitle} {
          font-size: 10px;
          font-weight: 500;
          color: #7f7f7f;
        }

        ${CardText} {
          margin-left: 0 !important;
          margin-top: 0 !important;
          font-size: 10.5px;
        }
      }
      .lateral-bar {
        width: 1px;
        height: 31px;
        background-color: #7f7f7f;
      }
    }
  }
`

class RealtyCard extends PureComponent {
  state = {
    dropdownOpen: false
  }

  getLocationValue = () => {
    const { aluguel, iptu, condominio } = this.props.infos.locacao;
    const rent = parseFloat(aluguel) || 0;
    const tax = parseFloat(iptu) || 0;
    const fee = parseFloat(condominio) || 0;
    const total = `R$ ${formatToBRL(rent + fee + tax)}`

    return total;
  }

  get addressNumber() {
    const { address } = this.props;
    if (address !== undefined && typeof address === "object") {
      const { numero } = this.props.address;
      return (numero !== null && numero !== undefined) ? `, ${numero}` : "";
    }
    return "";
  }

  get address() {
    const { address } = this.props;
    if (address !== undefined && typeof address === "object") {
      const { logradouro } = this.props.address;
      return (logradouro !== null && logradouro !== undefined) ? `${logradouro} ` : "";
    }
    return "";
  }

  get extra() {
    const { address } = this.props;
    if (address !== undefined && typeof address === "object") {
      const { complemento } = this.props.address;
      return (complemento !== null && complemento !== undefined) ? ` - ${complemento}` : "";
    }
    return "";
  }

  get fullAddress() {
    return (this.address + this.addressNumber + this.extra) || 'Não informado';
  }

  get propertyCode() {
    const { locacao } = this.props.infos;
    return locacao.codigo_imovel || 'Não informado';
  }

  tipoDeImovel() {
    const { locacao } = this.props.infos;

    

    switch(locacao.tipo_imovel) {
      case('NAO_RESIDENCIAL'):
        return 'Não Residencial'
      default:
        return locacao.tipo_imovel || 'Não informado'
    }
  }

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  handleOcultado = (text) => {
    const pretendentes = this.props.infos.pretendentes
    const allOculto = pretendentes.every((pretendente) => Boolean(pretendente.oculto) === true);
    if(allOculto) {
      if(!text) {
        return true;
      }
      return 'OCULTADO';
    }
    return text;
  }


  render() {
    
    return (
      <StyledRealtyCard padding=".5rem">
        <GlobalStyle />
        <StyledRow align="flex-end" margin="0 0 .5rem 0">
          <BaseColumn md={10} lg={10} xl={10}>
            <CustomTolltip
              message={this.handleOcultado(this.fullAddress)}
              position="bottom-end"
              target="reqestAddress"
              innerstyle="width: 13rem; height: auto!important;"
              customstyle="opacity: 1 !important"
            >
              <CardText id="reqestAddress" color="color_purple" customstyle='@media (max-width: 800px) {font-size: 12px;color: #7f7f7f;}'>{this.handleOcultado(this.fullAddress)}</CardText>
            </CustomTolltip>
          </BaseColumn>
          <BaseColumn
            customstyle="
              align-self: flex-start;
              
              @media(max-width: 800px) {
                position: absolute;
                width: auto;
                right: 20px;
                top: 2px;
                
                button {
                  background-color: transparent;
                }

                .dropdown-item {
                  display: flex;
                  align-items: center;
                  font-size: 13px;
                  color: #152b4e;

                  svg {
                    margin: 0;
                    width: 13px;
                    margin-top: -3px;
                    margin-right: 5px;
                  }
                }
              }
            "
            md={2}
            lg={2}
            xl={2}
          >
            <CardButton className="botao-editar-locacao">
              {window.innerWidth <= 800 ?
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                  <DropdownToggle tag="span">
                    <img src={iconDots} style={{height: 14}} />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.props.onEditLocation}><Edit /> Editar</DropdownItem>
                  </DropdownMenu>
                </Dropdown> :
                // <Edit onClick={this.props.onEditLocation} />
                <span onClick={!this.handleOcultado() && this.props.onEditLocation} style={{fontSize: 10, color: '#152b4e', fontWeight: 400, textDecoration: 'underline', cursor: this.handleOcultado() && 'no-drop'}} >EDITAR</span>
              }
              <TourTooltip
                target="tooltip-editar-locacao"
              />
            </CardButton>
          </BaseColumn>
        </StyledRow>

        <div className='wrapper-box-info'>
          <div className='box-info'>
            <Subtitle>Código do Imóvel<span className='hidden-in-mobile'>:</span></Subtitle>
            <CardText margin="3px 0 5px 8px">{this.handleOcultado(this.propertyCode)}</CardText>
          </div>

          <div className='lateral-bar'></div>

          <div className='box-info hidden-in-mobile'>
            <Subtitle>Tipo de Imóvel:</Subtitle>
            <CardText margin="3px 0 5px 8px">{this.handleOcultado(this.tipoDeImovel())}</CardText>
          </div>

          <div className='box-info'>
            <Subtitle>Aluguel + Encargos<span className='hidden-in-mobile'>:</span></Subtitle>
            <CardText margin="3px 0 5px 8px">{this.handleOcultado(this.getLocationValue())}</CardText>
          </div>

          <div className='lateral-bar'></div>
          
          <div className='box-info'>
            <Subtitle>Status <span className='hidden-in-mobile'>do pedido:</span></Subtitle>
            <CardText margin="3px 0 5px 8px">{this.handleOcultado(this.props.infos.status)}</CardText>
          </div>
        </div>
      </StyledRealtyCard>
    );
  }
}

export default RealtyCard;
