import React, { PureComponent, Fragment } from 'react';

import {
  IdTd,
  CustomersTd,
  NameTd,
  AdressTd,
  RequestRow,
  DateTd,
  BaseEllipsisSpan,
  CountIndicatorSpan
} from './../../../../components/Operational/table/requestTable.styled';
import { StyledRow } from '../../../../components/shared/sharedStyles.styles';
import RequestTableToolTip from './../../../../components/Operational/table/RequestTableToolTip';
import moment from 'moment';
import { formatCNPJ, formatCPF, formatAddress } from '../../../../utils/formatter';

class RequestLine extends PureComponent {

  getApplicantNames = () => {
    const { pretendentes } = this.props;
    if (Array.isArray(pretendentes) && pretendentes.length > 0) {
      return pretendentes.map((applicant) => applicant.nome);
    }
    return [];
  }

  getApplicantDocs = () => {
    const { pretendentes } = this.props;
    if (Array.isArray(pretendentes) && pretendentes.length > 0) {
      return pretendentes.map((applicant) => {
        if ("cpf" in applicant) {
          return formatCPF(applicant.cpf);
        }

        if ("cnpj" in applicant) {
          return formatCNPJ(applicant.cnpj);
        }

        return undefined
      });
    }
    return [];
  }

  getAdress = () => {
    const endereco = {
      bairro: this.props.bairro,
      cidade: this.props.cidade,
      cep: this.props.cep,
      complemento: this.props.complemento,
      logradouro: this.props.logradouro,
      numero: this.props.numero
    }

    if( endereco ){
      return `${formatAddress(endereco)}`
    }else{
      return "-"
    }
  }

  render() {
    const applicantNames = this.getApplicantNames();
    const applicantDocs = this.getApplicantDocs();
    const adress = this.getAdress();
    
    return (
      <RequestRow onClick={this.props.onGoToResume} >
        <IdTd>
          <BaseEllipsisSpan>{this.props.id}</BaseEllipsisSpan>
        </IdTd>
        {/* <CustomersTd>
          <StyledRow justify="flex-start">
            
            {this.props.cliente_nome.length >= 30 
              ?
                <RequestTableToolTip
                  key={(new Date().getTime() + 1).toString()}
                  message={this.props.cliente_nome}
                  index={this.props.index}
                  length={this.props.length}
                  type="customerName"
                >
                  <BaseEllipsisSpan id={`customerName${this.props.index}`}>{this.props.cliente_nome}</BaseEllipsisSpan>    
                </RequestTableToolTip>
              :
                <BaseEllipsisSpan>{this.props.cliente_nome}</BaseEllipsisSpan>
            }
          </StyledRow>
        </CustomersTd> */}
        <DateTd>
          <BaseEllipsisSpan>{this.props.cod_imovel}</BaseEllipsisSpan>
        </DateTd>
        <AdressTd>
            {adress.length > 45 
              ?
                <RequestTableToolTip
                 key={(new Date().getTime() + 2).toString()}
                  message={adress}
                  index={this.props.index}
                  length={this.props.length}
                  type="adress"
                >
                  <CountIndicatorSpan color={"color_gray"} id={`adress${this.props.index}`}>{adress.substr(0, 45)}...</CountIndicatorSpan>
                </RequestTableToolTip>
              :
                adress
            }
        </AdressTd>
        <NameTd>
          <StyledRow justify="flex-start">
            {/* <BaseEllipsisSpan>{applicantNames.length > 0? applicantNames[0] : ""}</BaseEllipsisSpan> */}
            {/* <RequestTableToolTip
              key={(new Date().getTime() + 3).toString()}
              message={applicantNames}
              extraData={applicantDocs}
              index={this.props.index}
              length={this.props.length}
              type="applicantName"
            > */}
              <CountIndicatorSpan color={"color_gray"} id={`applicantName${this.props.index}`}>{this.props.status}</CountIndicatorSpan>
            {/* </RequestTableToolTip> */}
          </StyledRow>
        </NameTd>
        <DateTd>
          <span>{moment(this.props.created_at).format('DD/MM/YYYY HH:mm')}</span>
        </DateTd>
      </RequestRow>
    );
  }
}
export default RequestLine;
