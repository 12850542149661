import { toastr } from 'react-redux-toastr';
import * as types from './types';
import { actionCreator, generalActionTypes,extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { fcfiancaRequests } from '../../../../services/operational';
import { replace } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../../../../pages/auth/login/actions/types.js';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const changePage = (page) => actionCreator(types.FCFIANCA_CHANGE_PAGE, page)
export const changePerPage = (perPage) => actionCreator(types.FCFIANCA_CHANGE_PER_PAGE, perPage)
export const onChangeSort = (order) => actionCreator(types.FCFIANCA_CHANGE_SORT, order)
export const setFilter = (filter) => actionCreator(types.FCFIANCA_SET_FILTER, filter)
export const setFinalDate = (date) => actionCreator(types.FCFIANCA_SET_FINAL_DATE, date)
export const setStartDate = (date) => actionCreator(types.FCFIANCA_SET_START_DATE, date)
export const resetRequests = () => actionCreator(types.FCFIANCA_RESET_REQUESTS)

export const getFCFiancaRequests = (complete) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.FCFIANCA_REQUESTS_LOADING));
    console.log('entrei aqui1', complete)
    try {
      const { login: { user: { token } }, fcfiancaTable: {pagination} } = getState();
      const payload = await fcfiancaRequests(token.access_token, pagination, complete);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('FCFiança', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.FCFIANCA_REQUESTS_ERROR));
        }
      } else {
        dispatch(actionCreator(types.FCFIANCA_REQUESTS_DONE, payload.data));
      }
    } catch (error) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Não foi possível processar a informação no momento.'));
      dispatch(actionCreator(types.FCFIANCA_REQUESTS_ERROR));
    }
  }
}
