import React from 'react'
import { StyledLabel, StyledCheck, CheckboxContainer } from './Inputs.styles';
import { withTheme } from 'styled-components';

const handleInputChange = (input, value, customChange) => () => {
  if (customChange) {
    customChange(input)
  } else {
    input.onChange(value);
  }
};

const handleInputCheck = (inputValue, comparator) => {
  if (comparator !== undefined) {
    return comparator(inputValue);
  }

  return inputValue;
}

const CheckBox = ({ customstyle, boxColor, label, onChange, checked, isRequired, disabled, input, id, comparator, name, customChange, theme, labelStyles }) => (
  <CheckboxContainer customstyle={customstyle}>
    <StyledCheck
      id={name}
      boxColor={boxColor}
      name={name}
      value={id}
      onChange={input ? handleInputChange(input, id, customChange) : onChange}
      checked={input && comparator ? handleInputCheck(input.value, comparator) : checked}
      required={isRequired}
      disabled={disabled || theme.disabled}
      type="checkbox"
    />
    <StyledLabel htmlFor={name}>
      <span style={{ marginLeft: '20px', ...labelStyles }}>
        {label || 'LABEL'}
      </span>
    </StyledLabel>
  </CheckboxContainer>
);

CheckBox.defaultProps = {
  isRequired: false
};

export default withTheme(CheckBox);