import styled from 'styled-components'
import Card from '@bit/wavfichacerta.fichacerta.cards.card'
import { MainContainer as MC, PageContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';
import { Link } from 'react-router-dom';

export const MainContainer = styled(MC)`
      @media (max-width: 800px) {
            padding-left: 0;
            width: 95%;
            margin-top: 33%;
      }
`;

export const StyledLink = styled(Link)`
      width: 48%;
      text-decoration: none;
      &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
      }
`;

export const DashCard = styled(Card) `
      display: block;
      max-width: 350px;
      height: 260px;
      margin-right: 33px;
      margin-bottom: 25px;
      overflow: hidden;
     
     &:hover {
            border: 1px solid #152b4e;
            cursor: pointer;
      }

      @media (max-width: 800px) {
            height: 160px;
            margin-bottom: 15px;
            padding: .8rem;
            box-shadow: 0px 0px 4px #e0e0e0;

            .main-icon {
                  margin-right: -38px;
                  transform: scalex(-1);
                  margin-top: 28px;
            }
            .sac-icon {
                  margin-top: 36px;
            }
      }
`

export const CardContainer = styled.div`
      width: 740px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      `

export const CardTitle = styled.h1`
      display: block;
      font-size: 15px;
      text-transform: uppercase;
      color: #152b4e;
      text-align: center;
      
      @media (max-width: 800px) {
            text-align: left;
            font-weight: 400;
      }

      @media (max-width: 349px) {
            margin-bottom: 0;
      }
`
export const CardDescription = styled.p`
      display: block;
      font-size: 1.7vh;
      color: #7f7f7f;
      text-align: center;
      margin-top: 0;
      margin-bottom: 16px;
      
`

export const StyledTextArea = styled.textarea`
      font-size: 12px;
      resize: none;
      width: 100%;
      margin-top: 5%;
      height: 109px;
      margin-bottom: 4%;
`
export const StyledSubject = styled.h2`
      font-size: 12px;
      color: #6c757d
`
export const StyledPageContainer = styled(PageContainer)`
      @media (max-width: 800px){
            width: 100%;
      }
`