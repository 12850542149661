import React, { PureComponent, Fragment } from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  BorderedBox,
  TitleBox,
  AnalythicOList,
  AnalythicLineItalic
} from '../analythic.styled';
import {
  getAmountData,
  getAmountRecommendations,
  getDocRecommendation
} from './products/fcAnalise';
import { income } from '../../../../pages/operational/request/report/singleReport/actions';
import { formatToBRL, formatDateToBR, formatCNPJ } from '../../../../utils/formatter';
import styled from 'styled-components'

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../../shared/TourTooltip';

const WrapperCardResult = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  @media (max-width: 800px) {
    display: block;
  }
`

class AmountContainer extends PureComponent {
  constructor(props){
    super(props)

    this.income = React.createRef()
  }

  componentDidMount(){
    this.props.income(this.income.current)
  }

  formatTimes = (times) => {
    if (times === undefined || times === null) {
      return 0;
    }
    const timesString = times.toFixed(1);
    return timesString.replace(/\.(?!\.)/g, ",");
  }

  renderAmountInfo = () => {
    const { data } = this.props;
    const vezes = data.laudo.compatibilidade_renda.result.vezes
    const iraResidir = data.pretendente.residir
    const fcrenda = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    const origem = fcrenda.origem_renda_principal
    const amountInfo = getAmountRecommendations(data);
    const incompativel = data.laudo.parecer_sistemico[0].incompativel
    const removedRecomendation = 'É recomendável solicitar comprovação da renda informada.'
    let confirmedRecomendation = ''
    let confirmed = false
    if('resultado' in fcrenda && fcrenda.ativo) {
      const {resultado} = fcrenda
      switch(resultado.titulo) {
        case 'PERFIL FINANCEIRO CONFIRMADO':
          confirmed = true
          confirmedRecomendation = `A RENDA PESSOAL confirmada (${this.formatTimes(vezes)} VEZES O VALOR DA LOCAÇÃO) ${incompativel && 'não ' || ''}é compatível com esta locação.`
      }
    }

    if('resultado' in fcrenda && fcrenda.ativo === true) {
      const {resultado} = fcrenda
      switch(resultado.titulo) {
        case 'PERFIL FINANCEIRO CONFIRMADO':
          if((fcrenda.bandeira || fcrenda.numero_cartao) && origem == 13) {
            return (
              <Fragment>
                <TitleBox style={{color: '#000'}}>{`Considerando uma locação de imóvel residencial para ${iraResidir ? 'uso próprio' : 'terceiros'}:`}</TitleBox>
                <AnalythicOList>
                  <AnalythicLineItalic style={{color: '#000'}}>{`A RENDA ESTIMADA pelo LIMITE DO CARTÃO DE CRÉDITO (${this.formatTimes(vezes)} VEZES O VALOR DA LOCAÇÃO) ${incompativel && 'não ' || ''}é compatível com esta locação.`}</AnalythicLineItalic>
                </AnalythicOList>
              </Fragment>
            );
          }else if(origem == 15){
            return (
              <Fragment>
                <TitleBox style={{color: '#000'}}>{`Considerando uma locação de imóvel residencial para ${iraResidir ? 'uso próprio' : 'terceiros'}:`}</TitleBox>
                <AnalythicOList>
                  <AnalythicLineItalic style={{color: '#000'}}>{`A RENDA ESTIMADA por EXTRATOS BANCÁRIOS (${this.formatTimes(vezes)} VEZES O VALOR DA LOCAÇÃO) ${incompativel && 'não ' || ''}é compatível com esta locação.`}</AnalythicLineItalic>
                </AnalythicOList>
              </Fragment>
            );
          }
      }
    }

    if('resultado' in fcrenda && fcrenda.ativo === true) {
      if((fcrenda.bandeira || fcrenda.numero_cartao) && origem == 13) {
        return (
          <Fragment>
            <TitleBox style={{color: '#000'}}>Documentação recomendada para comprovação da RENDA ESTIMADA informada:</TitleBox>
            <AnalythicOList>
              <AnalythicLineItalic style={{color: '#000'}}>- Último extrato/fatura do cartão de crédito. Em caso de limite flexível, últimos 3 extratos/fatura.</AnalythicLineItalic>
            </AnalythicOList>
          </Fragment>
        );
      }
    }

    return (
      <Fragment>
        <TitleBox style={{color: '#000'}}>{amountInfo.title}:</TitleBox>
        <AnalythicOList>
          {amountInfo.recommendations.map((rec) => (
            confirmed && rec == removedRecomendation ? null :
            'Verificar a possibilidade de composição de renda com outra(s) pessoa(s).' == rec && incompativel ?
            <AnalythicLineItalic style={{color: '#000'}}>{rec}</AnalythicLineItalic> :
            'Verificar a possibilidade de composição de renda com outra(s) pessoa(s).' == rec ? null :
            <AnalythicLineItalic style={{color: '#000'}}>{rec}</AnalythicLineItalic>
          ))}
        </AnalythicOList>
      </Fragment>
    );
  }
  
  renderResultFcRenda = (result) => {
    const { data } = this.props
    const fcrenda = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    let notConfirmed = false
    if('resultado' in fcrenda) {
      const {resultado} = fcrenda
      switch(resultado.titulo) {
        case 'PERFIL FINANCEIRO NÃO CONFIRMADO':
          notConfirmed = true
      }
    }
    return(
      <div style={{border: notConfirmed ? '2px solid #e04f5f' : '2px solid #152b4e', padding: '4px 10px', color: notConfirmed ? '#e04f5f' : '#152b4e', fontSize: 12, marginTop: 6, fontWeight: 'bolder'}} className='result-fcdoc'>
        {result}
      </div>
    )
  }

  renderDocRecommendation = () => {
    const { data } = this.props;
    const fcrenda = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    const recommendations = getDocRecommendation(data);

    if('resultado' in fcrenda && fcrenda.ativo === true) {
      const {resultado} = fcrenda
      switch(resultado.titulo) {
        case 'PERFIL FINANCEIRO CONFIRMADO':
        case 'PERFIL FINANCEIRO NÃO CONFIRMADO':
          return this.renderResultFcRenda(`${resultado.titulo}: ${resultado.descricao}`)
      }
    }

    if (Array.isArray(recommendations) && recommendations.length > 0) {
      return (
        <BorderedBox xs={12} md={12} lg={12} xl={12} margin=".5rem 0 0 0">
          <TitleBox style={{color: '#000'}}>{`Documentação recomendada para comprovação da RENDA PESSOAL informada:`}</TitleBox>
          <AnalythicOList>
            { recommendations.map((item, index) => (<AnalythicLineItalic style={{color: '#000'}} key={index} hideNumber>- {item}</AnalythicLineItalic>)) }
          </AnalythicOList>
        </BorderedBox>
      );
    }

    return null;
  }

  renderResultCreditCard = () => {
    const { data } = this.props;
    const fcrenda = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    const origem = fcrenda.origem_renda_principal

    if('resultado' in fcrenda && fcrenda.ativo === true) {
      if((fcrenda.bandeira || fcrenda.numero_cartao) && origem == 13) {
        return (
          <WrapperCardResult className="wrapperCardResult">
            <span style={{width: '100%'}}>
              <AnalythicLine style={{width: '98%', color: '#000'}}>
                <LineTitle maxWidth="150px" style={{left: 4, fontWeight: 600}}>Nº do cartão</LineTitle>
                <span>{fcrenda.numero_cartao}</span>
              </AnalythicLine>
            </span>
            <span style={{width: '100%'}}>
              <AnalythicLine style={{width: '98%', color: '#000'}}>
                <LineTitle maxWidth="150px" style={{left: 4, fontWeight: 600}}>Validade</LineTitle>
                <span>{fcrenda.validade}</span>
              </AnalythicLine>
            </span>
            <span style={{width: '100%'}}>
              <AnalythicLine style={{color: '#000'}}>
                <LineTitle maxWidth="150px" style={{left: 4, fontWeight: 600}}>Bandeira</LineTitle>
                <span>{fcrenda.bandeira}</span>
              </AnalythicLine>
            </span>
          </WrapperCardResult>)
      }
    }
    return null;
  }

  renderResultFCDoc = () => {
    const { data, isFcBasico } = this.props;
    const fcdoc  = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    if(!fcdoc.ativo || !fcdoc.resultado) {
      return null
    }
    let totalRendas = parseFloat(fcdoc.renda_principal) + parseFloat(fcdoc.outra_renda)
    let renda_principal = fcdoc.renda_principal ? parseFloat(fcdoc.renda_principal) : 0.00
    let outra_renda = fcdoc.outra_renda ? parseFloat(fcdoc.outra_renda) : 0.00
    let address = []
    let telefone = ''
    let origem_outra_renda_titulo = 'Não informado'
    if(outra_renda) {
      origem_outra_renda_titulo = fcdoc.origem_outra_renda_titulo
    }
    if(fcdoc.telefone) {
      const ddd = fcdoc.telefone.substr(0,2)
      const number = fcdoc.telefone.substr(2)
      let numberCol1 = ''
      let numberCol2 = ''
      if(number.length === 8) {
        numberCol1 = number.substr(0,4)
        numberCol2 = number.substr(4)
      } else {
        numberCol1 = number.substr(0,5)
        numberCol2 = number.substr(5)
      }
      telefone = `(${ddd}) ${numberCol1}-${numberCol2}`
    }
    if(fcdoc.logradouro) {
      if(fcdoc.numero) {
        address.push(`${fcdoc.logradouro}, ${fcdoc.numero}`)
      } else {
        address.push(fcdoc.logradouro)
      }
    }
    // if(fcdoc.numero) {
    //   address.push(fcdoc.numero)
    // }
    if(fcdoc.bairro) {
      address.push(fcdoc.bairro)
    }
    if(fcdoc.cidade) {
      address.push(fcdoc.cidade)
    }
    if(fcdoc.uf) {
      address.push(fcdoc.uf)
    }
    address = address.join(' - ')
    return(
      <Fragment>
        <StyledRow>
          <BaseColumn padding="0 .2rem">
            <AnalythicList>
              <AnalythicLine style={{color: '#000'}}>
                <LineTitle maxWidth="125px">Renda principal</LineTitle>
                <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                  {'R$ ' + formatToBRL(renda_principal)}
                  <span style={{marginLeft: 4}}>({fcdoc.origem_renda_principal_titulo})</span>
                </span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn>
          <BaseColumn padding="0 .2rem">
            <AnalythicList>
              <AnalythicLine style={{color: '#000'}}>
                <LineTitle maxWidth="125px">Outras rendas</LineTitle>
                <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                  {'R$ ' + formatToBRL(outra_renda)}
                  <span style={{marginLeft: 4}}>({origem_outra_renda_titulo})</span>
                </span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn>
          {/* <BaseColumn padding="0 .2rem">
            <AnalythicList>
              <AnalythicLine>
                <LineTitle maxWidth="205px">Origem</LineTitle>
                <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                  {fcdoc.origem_renda_principal_titulo}
                </span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn> */}
        </StyledRow>

        <StyledRow>
          {/* <BaseColumn padding="0 .2rem">
            <AnalythicList>
              <AnalythicLine background="transparent">
                <LineTitle maxWidth="205px">Origem</LineTitle>
                <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                  {origem_outra_renda_titulo}
                </span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn> */}
        </StyledRow>

        {/* <StyledRow>
          <BaseColumn padding="0 .2rem">
            <AnalythicList>
              <AnalythicLine>
                <LineTitle maxWidth="205px">Renda pessoal total</LineTitle>
                <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                  {'R$ ' + formatToBRL(totalRendas)}
                </span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn>
        </StyledRow> */}

        <StyledRow>
          <BaseColumn padding="0 .2rem">
            <AnalythicList>
              <AnalythicLine background="transparent" style={{color: '#000'}}>
                <LineTitle maxWidth="125px">Empresa</LineTitle>
                <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                  {fcdoc.empresa}
                </span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn>
          <BaseColumn padding="0 .2rem">
            <AnalythicList>
              <AnalythicLine background="transparent" style={{color: '#000'}}>
                <LineTitle maxWidth="125px">Função</LineTitle>
                <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                  {fcdoc.funcao}
                </span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn>
        </StyledRow>

        <StyledRow style={{paddingBottom: 4}}>
          <BaseColumn padding="0 .2rem">
            <AnalythicList>
              <AnalythicLine style={{color: '#000'}}>
                <LineTitle maxWidth="125px">Endereço</LineTitle>
                <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                  {address}
                </span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn>
          <BaseColumn padding="0 .2rem">
            <AnalythicList>
              <AnalythicLine style={{color: '#000'}}>
                <LineTitle maxWidth="125px">Telefone</LineTitle>
                <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                  {telefone}
                </span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn>
        </StyledRow>
      </Fragment>
    )
  }

  render() {
    const { data, isFcBasico } = this.props;
    const fcrenda  = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {}
    const {origem} = data.laudo.principal_origem_renda.result
    const amountInfo = getAmountRecommendations(data);
    let titleRenda = 'RENDA PESSOAL informada'
    let mount = 0.00
    let labelTitle = '(informada)'

    if(origem == 'Limite do Cartão de Crédito' || origem == 'Extratos Bancários'){
      titleRenda = 'RENDA ESTIMADA informada'
      labelTitle = '(estimada informada)'
    }

    if('resultado' in fcrenda && fcrenda.ativo === true) {
      const {resultado} = fcrenda
      switch(resultado.titulo) {
        case 'PERFIL FINANCEIRO CONFIRMADO':
          titleRenda = 'RENDA PESSOAL confirmada'
          labelTitle = '(confirmada)'
          if(((fcrenda.bandeira || fcrenda.numero_cartao) && fcrenda.origem_renda_principal == 13) || fcrenda.origem_renda_principal == 15) {
            titleRenda = 'RENDA ESTIMADA verificada'
            labelTitle = '(estimada verificada)'
          }
          break
        case 'PERFIL FINANCEIRO NÃO CONFIRMADO':
          titleRenda = 'RENDA PESSOAL não confirmada'
          labelTitle = '(não confirmada)'
          if(((fcrenda.bandeira || fcrenda.numero_cartao) && fcrenda.origem_renda_principal == 13) || fcrenda.origem_renda_principal == 15) {
            titleRenda = 'RENDA ESTIMADA verificada'
            labelTitle = '(estimada verificada)'
          }
          break
      }

      if(fcrenda.renda_principal) {
        mount = mount + parseFloat(fcrenda.renda_principal)
      }
      if(fcrenda.outra_renda) {
        mount = mount + parseFloat(fcrenda.outra_renda)
      }
      if((fcrenda.bandeira || fcrenda.numero_cartao) && fcrenda.origem_renda_principal == 13) {
        mount = parseFloat(fcrenda.limite_credito)
      }
    }
    return (
      <StyledRow margin="0 0 .5rem 0" className="font-13-print">
        <ContainerGray ref={this.income}>
          <BaseColumn xs={12} md={12} lg={12} xl={12}>
            <AnalythicList>
              <AnalythicLine background="transparent" style={{color: '#000'}}>
                <LineTitle maxWidth="128px">
                  {/* {titleRenda} */}
                  RENDA PESSOAL
                </LineTitle>
                <span style={{marginLeft: 10, position: 'relative'}} className="renda-informada">
                  {titleRenda == 'RENDA PESSOAL informada' || titleRenda == 'RENDA ESTIMADA informada' ? getAmountData(data) : titleRenda == 'RENDA PESSOAL confirmada' || titleRenda == 'RENDA ESTIMADA informada' || titleRenda == 'RENDA ESTIMADA verificada' ? 'R$ ' + formatToBRL(mount) : `R$ 0,00`}
                  <span style={{marginLeft: 4}}>{labelTitle}</span>
                  {/* <TourTooltip
                    target="tooltip-renda-pessoal-informada"
                    style={{right: -15, top: -20}}
                  />
                  <ReactTooltip id="tooltip-renda-pessoal-informada" place="top" type="dark" effect="float">
                    <span>Renda pessoal que você preencheu no ato da análise, <b>dica</b>: caso receba a <br />comprovação de renda do pretendente e precise corrigir o valor lançado, é possível.</span>
                  </ReactTooltip> */}
                </span>
              </AnalythicLine>
            </AnalythicList>
          </BaseColumn>

          { this.renderResultFCDoc() }
          { this.renderResultCreditCard() }

          {Array.isArray(amountInfo.recommendations) && amountInfo.recommendations.length > 0 && !isFcBasico &&
            <BorderedBox xs={12} md={12} lg={12} xl={12}>
              {this.renderAmountInfo()}
            </BorderedBox>
          }

          { this.renderDocRecommendation() }
        </ContainerGray>
      </StyledRow>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  income
}, dispatch)

export default connect(null, mapDispatchToProps)(AmountContainer)