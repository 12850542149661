import * as types from '../actions/types';
import {
  onSaveComercialData,
  onSaveComercialDataSuccess,
  onSaveComercialDataFailed
} from './reducers';

const initialState = {
  isSaving: false,
  error: undefined
}

export default (state = initialState, { type }) => {
  switch (type) {
    case types.SAVE_COMERCIAL_DATA_REQUEST:
      return onSaveComercialData(state);
    case types.SAVE_COMERCIAL_DATA_SUCCESS:
      return onSaveComercialDataSuccess(state);
    case types.SAVE_COMERCIAL_DATA_FAILED:
      return onSaveComercialDataFailed(state);
    default:
      return state;
  }
}
