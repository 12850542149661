import React, { PureComponent } from 'react';

import { StepBoxContainer } from './stepBox.styled';

class StepBox extends PureComponent {
  static defaultProps = {
    checked: false
  }
  
  render() {
    const { checked, className } = this.props;
    return (
      <StepBoxContainer checked={checked} className={className}>{this.props.children}</StepBoxContainer>
    );
  }
}

export default StepBox;
