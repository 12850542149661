import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Menu from '../pages/menu/Menu'
import styled, { ThemeProvider } from 'styled-components';
import { Redirect, withRouter } from 'react-router-dom';

import { getRequirements } from '../pages/form-requirements/actions';
import { isUserAlreadyLogged } from './selectors';

const lightBackgroundTheme = (themeProps) => ({
  ...themeProps,
  input_label_color: themeProps.color_gray,
  input_color: themeProps.color_black,
  input_disabled_background: '#e9ecef',
  checkbox_color: themeProps.color_gray,
  dynamicPhoneButtonColor: "color_purple"
});

const GeneralStyles = styled.div`
  .field-fc {
    margin-top: 0 !important;
    padding: 0 3px;
    & input { height: 3.5vh !important; font-size: 12px !important; }
    & select { height: 3.5vh; font-size: 12px; }
    & label { 
      margin-top: 0;
      margin-bottom: 0 !important;
      font-size: .72rem;
      color: ${props => props.theme.color_gray};
    }
  }

  .field-fc
  input:disabled {
    background-color: ${props => props.theme.input_disabled_background};
  }
`;

class IntranetContainer extends PureComponent {
  componentDidUpdate() {
    const { isLogged, history: { replace, action }, location: { pathname } } = this.props;
    if (!isLogged && (action === 'POP' || action === 'PUSH') && pathname !== '/auth') {
      replace('/auth');
    } else if (isLogged && action === 'REPLACE' && pathname === '/intranet/dashboard') {
      this.props.getRequirements();
    }
  }
  componentDidMount() {
    const { location: { pathname } } = this.props;
    if (pathname === '/intranet/dashboard') {
      this.props.getRequirements();
    }
  }

  renderProtectedContent = () => {
    const { location: { pathname }, isLogged } = this.props;
    if (isLogged) {
      if (pathname === '/') {
        return <Redirect from="/" to='/intranet/dashboard' />
      }

      return (
        <Fragment>
          <Menu history={this.props.history} />
          {this.props.children}
        </Fragment>
      );
    }
    if (pathname !== '/auth' && pathname.search(/\/refresh/) === -1) {
      return <Redirect from="/" to='/auth' />
    }
  }

  render() {
    return (
      <ThemeProvider theme={lightBackgroundTheme}>
        <GeneralStyles>
          {this.renderProtectedContent()}
        </GeneralStyles>
      </ThemeProvider>
    )
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({ getRequirements }, dispatch);
const mapStateToProps = (state, ownProps) => (
  {
    ...ownProps,
    isLogged: isUserAlreadyLogged(state)
  }
);
const ConnectedIntranetContainer = connect(mapStateToProps, mapDispatchToProps)(IntranetContainer);
export default withRouter(ConnectedIntranetContainer);
