import { simpleRequiredText, simpleDisabledText } from '@bit/wavfichacerta.fichacerta.fields-templates.simple-text';
import { onlyNumber } from '../../../utils/formatter';

const sizes = {
  common: "field-fc col-md-6 col-sm-6 col-xl-6"
}

export default (hasIndication = false, master = true) => {
  let fields = [simpleDisabledText({ name: "como_conheceu", label: "Como conheceu a Ficha Certa?", size: sizes.common })];

  if (hasIndication) {
    fields = [...fields, simpleDisabledText({ name: "quem_indicou", label: "Quem indicou?", size: sizes.common })];
  }

  if(master){
    fields = [...fields, {...simpleRequiredText({ name: "numero_imoveis", label: "N° de imóveis administrados", size: sizes.common }), normalize: onlyNumber}];
  }else{
    fields = [...fields, {...simpleDisabledText({ name: "numero_imoveis", label: "N° de imóveis administrados", size: sizes.common }), normalize: onlyNumber}];
  }


  return [
    {
      name: 'firstLine',
      fields
    }
  ];
}

