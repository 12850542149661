import styled, { css } from 'styled-components';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { ButtonForm } from '../../../components/shared/formStyles.styled'
import { MainContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import PaginationFooter from '@bit/wavfichacerta.fichacerta.tables.pagination-footer';

export const GrayLine = styled(BaseColumn)`
  height: .05rem;
  background-color: ${props => props.theme['color_border-light-gray']};
  margin: ${props => props.margin? props.margin : '1rem 0'};

  @media(max-width: 800px){
    display: none;
  }
`;

export const LimitTitle = styled.div`
  padding: 0 1rem;
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color_gray};

  @media(max-width: 800px){
    padding: .5rem 0 .7rem 0;
  }
`;

export const LimitTitleBold = styled.span`
  font-size: 15px;
  font-weight: 500;
`;

export const ButtonBar = styled(ButtonForm)`
  padding: .25rem .5rem;
  font-size: 12px !important;
  font-weight: 300;
  margin-left: .5rem;
`;

export const StyledSelect = styled.select`
  margin-top: 10%;
  margin-bottom: 10%;
  width: 50%;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px ${props => props.theme.color_gray};
  color: ${props => props.theme.color_gray};
  font-size: 14px;
  &:focus {
    outline: none;
    
  }`;

  export const StyledMainContainer = styled(MainContainer)`
    @media(max-width: 800px){
      margin-top: 55px;
      padding: 0;
      width: 95%;
    }

    tbody {
      border-bottom: none;
    }
  `;

  export const StyledMobileRow = styled(StyledRow)`
    @media(max-width: 800px){
      background-color: #f8f6f6 !important;
      margin-bottom: 20px;
      position: relative;
    }
  `;
  export const StyledBaseColumn = styled(BaseColumn)`
    padding: 0 3rem 0 0;
    @media(max-width: 800px){
      padding: 0.5rem;
    }
  `;

  export const CreditBarWrapper = styled.div`
    width: 50%;
  `;

  export const NewUserButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    right: 20px;
    bottom: 30px;
    font-size: .8rem;
    margin-top: .5rem;
    padding: .25rem 1.5rem .25rem 1.5rem;
    z-index: 1;
    background-color: #ffffff;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    ${({customstyle}) => customstyle}
`;

export const StyledPaginationFooter = styled(PaginationFooter)`
  div {
    background: blue !important;
  }
  ${props => css`
    width: 100px !important;
    background-color: red !important;
  `};
`
export const AccordionButton = styled.div`
  position: absolute;
  bottom: -12px;
  background-color: rgb(224, 224, 224);
  width: 22px;
  height: 22px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transform: ${props => props.orientation ? 'rotate(180deg)' : 'none' };
`;