
export const USER_FORM_SAVE_REQUESTING = 'userForm/USER_FORM_SAVE_REQUESTING';
export const USER_FORM_SAVE_SUCCESS = 'userForm/USER_FORM_SAVE_SUCCESS';
export const USER_FORM_SAVE_FAILED = 'userForm/USER_FORM_SAVE_FAILED';

export const USER_FORM_EMPLOYER_DATA_REQUESTING = "userForm/USER_FORM_EMPLOYER_DATA_REQUESTING";
export const USER_FORM_EMPLOYER_DATA_SUCCESS = "userForm/USER_FORM_EMPLOYER_DATA_SUCCESS";
export const USER_FORM_EMPLOYER_DATA_FAILED = "userForm/USER_FORM_EMPLOYER_DATA_FAILED";

export const USER_FORM_RESET = "userForm/USER_FORM_RESET";
