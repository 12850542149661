import { getFormRequirements } from '../../../services/defaultSelectValues';
import { GET_REQUIREMENTS } from './types';
import { actionCreator, generalActionTypes } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const getRequirements = () => {
  return async (dispatch, getState) => {
    try {
      const { login: { user: { token } } } = getState();
      const requirements = await getFormRequirements(token.access_token);
      if (requirements.status === 200) {
        const { pesquisa: { como_conheceu, servicos_interesse } } = requirements.data.data;
        const uf = requirements.data.data.estados;
        dispatch(actionCreator(GET_REQUIREMENTS, { como_conheceu, servicos_interesse, uf, ...requirements.data.data }));
      }
    } catch (error) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'No momento não foi possível retornar os dados.'));
    }
  }
}