import { formatCNPJ, formatCPF, formatDateToBR, formatToBRL } from "../../../../../utils/formatter";

export const personalDataGiven = (data) => {
  let titleDoc = '';
  let doc = '';
  let name = '';
  let date = '';
  let motherName = '';
  doc = name = date = motherName = "Aguarde...";
  if (data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      const { cpf, cnpj, nome, nome_mae, data_nascimento } = data.pretendente;
      titleDoc = (cpf.length === 11)? 'CPF' : 'CNPJ';
      doc = (cpf.length === 11)? formatCPF(cpf) : formatCNPJ(cnpj);
      name = nome;
      date = (data_nascimento !== "" && data_nascimento !== undefined)? formatDateToBR(data_nascimento) : "00/00/0000";
      motherName = nome_mae;
    }
  }
  return [
    { title: titleDoc, data: doc },
    { title: 'Nome', data: name || 'Não fornecido' },
    { title: 'Nascimento', data: date || 'Não fornecido' },
    { title: 'Mãe', data: motherName || 'Não fornecido' }
  ];
}

export const personalDataChecked = (data) => {
  let score = '';
  let status = '';
  let value = '';

  if (data !== undefined && data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    console.log('data11', data)

    if ("laudo" in data && data.laudo !== null && data.laudo !== undefined) {
      const { score_serasa } = data.laudo;
      score = score_serasa;
      value = score + ' (de 0 a 1000)';
      if(score >= 850) {
        status = 'dark-green-circle';
      } else if(score >= 650 && score <= 849) {
        status = 'light-green-circle';
      } else if(score >= 500 && score <= 649) {
        status = 'yellow-circle';
      } else if(score >= 350 && score <= 499) {
        status = 'orange-circle';
      } else if(score >= 3 && score <= 349) {
        status = 'negative-circle';
      } else {
        status = 'nulo';
        switch(score) {
          case 2:
            value = 'Modelo de score não se aplica ao porte da empresa';
            break;
          case 1:
            value = 'Não possui informações relevantes para cálculo de score';
            break;
          case '-':
          case '0':
          case 0:
            value = 'Score não disponível';
            break;
        }
      }
    }
  }

  return [
    { title: 'SCORE (Mercado)', data: { value, status }}
  ];
}

export const personalDataCheckedBoth = (data) => {
  let titleDoc = '';
  let doc = '';
  let name = '';
  let date = '';
  let age = '';
  let motherName = '';
  let cpf = '';
  let fundacao = '';
  let naturezaJuridica = '';
  let cnaeAtividade = '';
  let capitalSocial = '';
  let restricao = '';
  doc = name = date = motherName = "Aguarde...";

  if (data !== undefined && data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      cpf = data.pretendente.cpf || data.pretendente.cnpj;
      titleDoc = (cpf.length === 11)? 'CPF' : 'CNPJ';
    }

    if ("laudo" in data && data.laudo !== null && data.laudo !== undefined) {
      const { situacao_cpf, situacao_cnpj, veracidade_nome, veracidade_data_nascimento, veracidade_nome_mae, restricoes_financeiras } = data.laudo;
      doc = `${formatCPF(cpf)} (${(titleDoc === "CPF")? situacao_cpf.result : situacao_cnpj.result})`;
      name = veracidade_nome.result || 'Não encontrado';
      date = formatDateToBR(veracidade_data_nascimento.result.data_nascimento) !== "Invalid date"? formatDateToBR(typeof veracidade_data_nascimento.result == 'string' ? veracidade_data_nascimento.result : veracidade_data_nascimento.result.data_nascimento) : "Não encontrado";
      age = Number.isInteger(veracidade_data_nascimento.result.idade) ? ` (${veracidade_data_nascimento.result.idade} anos)` : '';
      motherName = veracidade_nome_mae.result || 'Não encontrado';
      fundacao = veracidade_nome.fundacao && formatDateToBR(veracidade_nome.fundacao) || 'Não encontrado';
      naturezaJuridica = veracidade_nome.naturezaJuridica || 'Não encontrado';
      cnaeAtividade = veracidade_nome.cnaeAtividade || 'Não encontrado';
      capitalSocial = veracidade_nome.capitalSocial && 'R$' + formatToBRL(veracidade_nome.capitalSocial) || 'Não encontrado';
      restricao = restricoes_financeiras.icon === "negativo" ? "Encontrado" : "Não encontrado"
    }
  }

  return [
    { title: 'Informações Restritivas',
      data: {
        value: restricao,
        status: restricao == 'Encontrado' ? 'negativo' : 'positivo'
      }
    }
  ];
}

export const getDataValidation = (data) => {
  if (data !== undefined && data !== null) {
    const { laudo } = data;
    if (laudo !== undefined) {
      const searchKeys = ["situacao_cpf", "veracidade_nome", "veracidade_data_nascimento", "veracidade_nome_mae"];
      return searchKeys.map((key) => (laudo[key] !== undefined)? laudo[key].icon : "");
    }
  }
  return [];
}
