import React, { Component } from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  width: 25px;
  fill: ${props => props.theme.color_red};
`;

export default class Lock extends Component {
  render(){
    return (
      <SVG viewBox="0 0 512 512">
        <g>
          <g>
            <path d="M400,188h-36.037v-82.23c0-58.322-48.449-105.77-108-105.77c-59.551,0-108,47.448-108,105.77V188H112
              c-33.084,0-60,26.916-60,60v204c0,33.084,26.916,60,60,60h288c33.084,0,60-26.916,60-60V248C460,214.916,433.084,188,400,188z
              M187.963,105.77c0-36.266,30.505-65.77,68-65.77s68,29.504,68,65.77V188h-136V105.77z M420,452c0,11.028-8.972,20-20,20H112
              c-11.028,0-20-8.972-20-20V248c0-11.028,8.972-20,20-20h288c11.028,0,20,8.972,20,20V452z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M256,286c-20.435,0-37,16.565-37,37c0,13.048,6.76,24.51,16.963,31.098V398c0,11.045,8.954,20,20,20
              c11.045,0,20-8.955,20-20v-43.855C286.207,347.565,293,336.08,293,323C293,302.565,276.435,286,256,286z"/>
          </g>
        </g>
      </SVG>
    );
  }
}