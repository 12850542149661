export const isItemOpen = (state, props) => {
  const { menu: { selectedItems } } = state;
  const { title } = props;

  const itemIndex = selectedItems.indexOf(title);

  return itemIndex !== -1;
};

export const getMenuItems = state => Object.keys(state.access.menu);

