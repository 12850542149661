import styled from 'styled-components';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';

export const StepsContainer = styled.div`
  margin: auto;

  @media (min-width: 801px) {
    width: 98%;
    min-width: 98%;
  }

  @media (min-width: 1256px) {
    width: 81%;
    min-width: 1177px;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding-left: 0;
    margin-top: 42px;
  }

  ${({customstyle}) => customstyle}
`;

export const MobileStyledRow = styled(StyledRow)`
  @media (max-width: 800px){
    display: flex;
    align-items: baseline;
    justify-content: center;
  }
`;

export const StepContainer = styled(BaseColumn)`
  cursor: pointer;
  padding: 0px 3px 3px 3px;
  text-align: center;

  & > div {
    border-color: ${props => props.theme[props.status]};
    color: ${props => props.theme[props.status]};
  }

  & > hr {
    border-color: ${props => props.theme[props.status]};
  }
`;

export const LineStep = styled.hr`
  display: flex;
  align-itms baseline;
  margin-bottom: -1px;
  border-top: 3px solid;
`;

export const TextContainer = styled.div`
  margin-top: -14px;

  @media (max-width: 800px){
      margin-top: 10px;
  }
`;

export const RoundedCircle = styled.div`
  text-align: center;
  padding: 2px;
  background-color: white;
  display: inline-block;
  border-width: initial !important;
  border: 1px solid;
  border-radius: 50%;
  
  @media (max-width: 800px){
       display: none;
  }
`;

export const StepText = styled.div`
  font-size: 12px;
  padding: 0 3px 3px 3px;
  margin-top: -5px;

  @media (max-width: 800px){
       display: none;
  }
`;