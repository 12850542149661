import styled from 'styled-components';

export const HeaderCardboardContainer = styled.div`
  width: 100%;
  height: 16px;
  margin: .25rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LabelHeader = styled.span`
  font-size: 10px;

  ${(props) => {
    if (props.included) {
      return `color: ${props.theme.color_green};`;
    } else {
      return `color: ${props.theme.color_purple};`;
    }
  }}
`;

export const IconHeader = styled.div`
  height: 1rem;

  & > img {
    vertical-align: top;
    height: 1rem;
  }

  ${props => props.selected && `
    height: .9rem;
    width: .9rem;
    border: 1px solid ${props.theme.color_green};

    & > img {
      vertical-align: top;
      height: 1rem;
      position: relative;
      bottom: .3rem;
    }
  `}
  
`;

export const IconHeaderLoading = styled.div`
  position: relative;
  bottom: .3rem;
  left: .2rem;

  @media (max-width: 800px) {
    bottom: 10px;
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  max-height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.4 !important;
  opacity: ${props => props.opacityOn ? '.5' : '1'};
  & > img {
    width: 3rem;
  }
`;

export const TitleSpan = styled.span`
  margin: .5rem 0 .5rem 0;
  font-size: 12.5px;
  text-transform: uppercase;
  ${(props) => {
    if (props.included) {
      return `color: ${props.theme.color_green};`;
    } else {
      return `color: ${props.theme.color_purple};`;
    }
  }}

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const IconFooter = styled.div`
  &:hover {
    & > svg {
      transition: all 200ms ease-in;
      transform: scale(1.2);
    }

    z-index: 2;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  height: 1.2rem;
  max-height: 1.2rem;
  display: flex;
  justify-content: ${props => props.align ? props.align : 'space-between'};
  align-items: center;
  opacity: ${props => props.opacityOn ? '.5' : '1'};
`;

export const PriceContainer = styled.div`
  font-size: 12.5px;
  ${(props) => {
    if (props.included) {
      return `color: ${props.theme.color_purple};`;
    } else {
      return `color: ${props.theme.color_gray};`;
    }
  }}
`;

export const DescriptionParagraph = styled.p`
  color: ${props => props.theme.color_white};
  position: absolute;
  width: 8rem;
  line-height: 1.3;
  font-size: 12.5px;
`;

export const CardboardContainer = styled.div`
  width: 120px;
  height: 130px;
  padding: 4px 7px 6px 7px;
  border: 1px solid ${props => props.theme['color_border-light-gray']};
  transition: .5s;
  cursor: pointer;
  
  & > p {
    display: none;
  }

  @media (min-width: 801px) {
    &:hover {
      cursor: pointer;
      background-color: ${props => {
        switch (props.status) {
          case "INCLUIDO":
            return props.theme.color_gray;
          case "INCLUIDA":
            return props.theme.color_gray;
          case "EDITADO":
            return props.theme.color_gray;
          case "SOLICITADO":
            return props.theme.color_gray;
          case "ANDAMENTO":
            return props.theme.color_gray;
          case "CONCLUIDO":
            if((props.editCount === 0 || props.productId === 1 || props.productId === 3 || props.productId === 4 || props.productId === 7 || props.productId === 8) && (props.statusFcRenda != 'SOLICITADO' && props.statusFcRenda != 'ANDAMENTO')){
              return props.theme.color_purple;
            }
            return props.theme.color_gray;
          case "PENDENTE":
            return props.theme.color_gray;
          case "REINCLUIDO":
            if(props.productId === 1) {
              return props.theme.color_gray;
            }
            return props.theme.color_purple;
          case "INCLUIR":
            if(props.price == 'xx.xx') {
              return props.theme.color_gray;
            }
            return props.theme.color_purple;
          default:
            return props.theme.color_purple;
        }
      }};

      ${props => {
        if (props['data-testid'] == 'CardboardContainer' && (props.id === 1) && (props.statusFcRenda != 'SOLICITADO' && props.statusFcRenda != 'ANDAMENTO')) {
          return `
            background-color: #ff7200;
            color: red !important;

            ${FooterContainer} {
              & > div {
                background-color: #ff7200 !important;
              }
            }
          `;
        }
      }};

      ${props => {
        if (props['data-testid'] == 'CardboardContainer' && props.id === 5 && (props.statusFcRenda != 'SOLICITADO' && props.statusFcRenda != 'ANDAMENTO')) {
          return `
            background-color: #660099;
            color: red !important;

            ${FooterContainer} {
              & > div {
                background-color: #660099 !important;
              }
            }
          `;
        }
      }};

      ${props => {
        if (props['data-testid'] == 'CardboardContainer' && (props.id === 7 || props.id === 8) && (props.statusFcRenda != 'SOLICITADO' && props.statusFcRenda != 'ANDAMENTO')) {
          return `
            background-color: #795548;
            color: red !important;

            ${FooterContainer} {
              & > div {
                background-color: #795548 !important;
              }
            }
          `;
        }
      }};

      ${FooterContainer} {
        position: absolute;
        bottom: 2px;
        right: 17px;
        color: ${props => props.theme.color_white};
        opacity: 1;
        width: 115px;
        & > div {
          background-color: ${props => (props.status === "CONCLUIDO" && props.editCount === 0) || ((props.status === "CONCLUIDO" || props.status === "REINCLUIDO") && (props.productId === 1 || props.productId === 3 || props.productId === 4)) && (props.statusFcRenda != 'SOLICITADO' && props.statusFcRenda != 'ANDAMENTO') || props.status === "INCLUIR" ? props.theme['color_purple'] : props.theme['color_gray']};
          color: ${props => props.theme.color_white} !important;
        }

        ${IconFooter} {
          & path{
            fill: ${props => props.theme.color_white} !important;
          }
        }
      }

      ${BodyContainer}, ${HeaderCardboardContainer} {
        display: none;
      }

      ${TitleSpan} {
        color: ${props => props.theme["color_white"]};
      }

      & > p {
        display: block;
        width: 70%;
        line-height: 1.2;
      }
    }
  }
`;

export const CardboardResume = styled(CardboardContainer)`
  height: 119px;
  background-color: ${props => props.theme['color_light-gray']};
  cursor: ${({title, editCount, status, price, statusFcRenda}) => (title == 'FC DOC' && editCount > 0) || ((title == 'FC ESSENCIAL' || title == 'FC REPORT' || title == 'FC EMPRESA' || title == 'FC BASICO' || title == 'FC COMPLETA') && ((status == 'INCLUIDO' || status == 'REINCLUIDO') || (statusFcRenda == 'SOLICITADO' || statusFcRenda == 'ANDAMENTO')) || price == 'xx.xx') ? 'default !important' : null};
  cursor: ${({oculto}) => (oculto) ? 'no-drop !important' : null};

  @media (max-width: 800px) {
    position: relative;
    height: 33px;
    width: 108px;

    ${({status}) => status == 'INCLUIR' && 'border-color: #152b4e;'}

    ${BodyContainer} {
      margin-top: -16px;
      display: block;

      .icon-resume {
        display: none;
      }
    }

    ${FooterContainer} {
      position: absolute;
      top: 0;
      right: 2px;
      width: auto;
      height: auto;

      ${IconFooter} {
        display: none;
      }
    }
  }
`;

export const HeaderCardboardResume = styled(HeaderCardboardContainer)`
  height: 15px;
  justify-content: flex-end;
`;

export const LabelHeaderResume = styled(LabelHeader)`
  width: auto;
  max-width: 100%;
  padding: 0 4px 0 4px;
  position: absolute;
  top: 5px;
  left: 13px;
  color: ${
    props => {
      switch (props.status) {
        case "INCLUIR":
          return props.theme.color_purple;
          default:
          return props.theme.color_white;
      }
    }
  };
  background-color: ${props => {
    switch (props.status) {
      case "INCLUIDO":
        return props.theme.color_purple;
      case "INCLUIDA":
        return props.theme.color_purple;
      case "EDITADO":
        return props.theme.color_edited;
      case "SOLICITADO":
        return props.theme.color_requested;
      case "ANDAMENTO":
        return props.theme['color_in-progress'];
      case "CONCLUIDO":
        return props.theme['color_done'];
      case "PENDENTE":
        return props.theme['color_pending'];
      case "INCLUIR":
        return 'none';
      default:
        return props.theme.color_purple;
    }
  }};

  @media (max-width: 800px) {
    left: 0;
    top: 0;
    font-size: 9px;
    width: 70%;
    max-width: 70%;
  }
`;

export const TitleProduct = styled(TitleSpan)`
  margin: .25rem 0 0 0;
  color: ${props => props.theme.color_purple};
`;

export const PriceResume = styled(PriceContainer)`
  font-size: 11px;
  color: ${props => props.theme.color_purple};
 
  @media (max-width: 800px) {
    font-size: 9px;
    margin-top: 29px;
  }
`;
