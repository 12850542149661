import styled from 'styled-components';
import magnifying from '../../../img/svg/magnifying-glass.svg';
import MaskedInput from 'react-text-mask';

export const ContainerInput = styled.div`
  width: 17vw;

  &:after {
    content: url(${magnifying});
    width: .5rem;
    height: .5rem;
    position: absolute;
    left: 60%;
  }
`;

export const Input = styled.input`
  width: 100%;
  outline-width: 0;
  font-size: .9rem;
  font-family: 'Open Sans';
  background: transparent;
  border: transparent;
  border-bottom: 1px solid ${props => props.theme['color_border-gray-secondary']};
  padding-left: 8px;
  color: ${props => props.theme.color_gray};

  &:focus {
    background: ${props => props.theme.color_white};
  }
`;

export const DateInput = styled(MaskedInput)`
  width: 100%;
  outline-width: 0;
  font-size: .8rem;
  font-family: 'Open Sans';
  background: transparent;
  border: transparent;
  border-bottom: 1px solid ${props => props.theme['color_border-gray-secondary']};
  padding-left: 8px;
  color: ${props => props.theme.color_gray};

  ::-webkit-input-placeholder { /* Chrome */
    color: ${props => props.theme["color_border-gray"]};
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: ${props => props.theme["color_border-gray"]};
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: ${props => props.theme["color_border-gray"]};
    opacity: 1;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: ${props => props.theme["color_border-gray"]};
    opacity: 1;
  }
`;

export const DateContainerInput = styled.div`
  width: 40%;
  display: inline-block;
`;

export const FilterContainerInput = styled.div`
  width: 100%;
  display: inline-block;
`;

export const FilterIcon = styled.img`
  height: .9rem;
`;

export const FilterInput = styled.input`
  width: 100%;
  outline-width: 0;
  font-size: .8rem;
  font-family: 'Open Sans';
  background: transparent;
  border: transparent;
  border-bottom: 1px solid ${props => props.theme['color_border-gray-secondary']};
  padding-left: 8px;
  color: ${props => props.theme.color_gray};

  &:focus {
    background: ${props => props.theme.color_white};
  }

  ::-webkit-input-placeholder { /* Chrome */
    color: ${props => props.theme["color_border-gray"]};
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: ${props => props.theme["color_border-gray"]};
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: ${props => props.theme["color_border-gray"]};
    opacity: 1;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: ${props => props.theme["color_border-gray"]};
    opacity: 1;
  }

`;

export const FilterButton = styled.button`
  cursor: pointer;
  margin-left: 10px;
  padding: .25rem .5rem;
  background-color: white;
  color: #152b4e;
  border: 1px solid #152b4e;
  transition: all 100ms ease-in;
  transform: scale(1.05);
  border-radius: 0;
  font: 500 .8rem 'Arial' ;
  line-height: 1;
  height: 20px;
`

export const MobileFilterButton = styled.button`
  @media(min-width: 801px){
    display: none;
  }

  @media(max-width: 800px){
    position: absolute;
    top: 65px;
    right: 12px;
  }

  cursor: pointer;
  padding: .25rem .5rem;
  background-color: white;
  color: #152b4e;
  border: 1px solid #152b4e;
  transition: all 100ms ease-in;
  transform: scale(1.05);
  border-radius: 0;
  font: 500 .8rem 'Arial' ;
  line-height: 1;
  height: 20px;
`

export const FilterText = styled.span`
  color: ${props => props.theme.color_gray};
  font-size: 12px;
  font-family: 'Open Sans';
`;