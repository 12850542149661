import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Container = styled.svg`
  width: ${props => props.width || '100%'};
  & > path { fill: ${props => props.color || props.theme['color_purple']}; }
`;

export default class Person extends PureComponent {

  render() {
    return (
      <Container viewBox="0 0 49.25 47.4">
        <path d="M26.4,49.81c-6.79,0-13.59-.13-20.38.06-3.36.09-4.15-1.29-3.74-4.33.95-7,8-13.21,15.74-13.42q8.79-.23,17.58,0c7.76.21,14.77,6.38,15.73,13.43.41,3.05-.4,4.41-3.75,4.32C40.52,49.68,33.46,49.81,26.4,49.81Zm22.07-2.75c.32-6.3-5.25-12.11-12.1-12.39-6.38-.26-12.79-.27-19.17,0-6.85.29-12.41,6.15-12,12.39Z" transform="translate(-2.17 -2.47)" />
        <path d="M26.56,28.26a12.93,12.93,0,0,1-13-12.88,13.11,13.11,0,0,1,26.21.28A12.78,12.78,0,0,1,26.56,28.26ZM26.57,5a10.55,10.55,0,0,0-10.5,10.71,11.1,11.1,0,0,0,10.61,10.4c5.5,0,10.8-5.23,10.78-10.73A10.77,10.77,0,0,0,26.57,5Z" transform="translate(-2.17 -2.47)" />
      </Container>
    );
  }
};
