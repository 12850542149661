import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, change } from 'redux-form';

// import CardOld from '../../../../../components/shared/card/Card';
import CardOld from '@bit/wavfichacerta.fichacerta.cards.card';
import icons from '../../../../../components/shared/treatedIcons';
import FormContent from '../../../../../components/shared/form/FormContent';
import IncomeModal from '../incomeModal/IncomeModal';
import { getInitialValues } from './selectors';

import getIncomeFields from './incomeFieldsVeredito';
import getIncomeCardFieldsVeredito from './incomeCardFieldsVeredito';
import getIncomeExtractFieldsVeredito from './incomeExtractFieldsVeredito';
import incomeFields from './fieldsVeredito';

import { getValues } from '../selectors';
import { formatToBRL } from '../../../../../utils/formatter';
import ModuleFilesAnexo from './moduleFilesAnexo'
import ModuleFilesAnalise from './moduleFilesAnalise'
import styled from 'styled-components'

const Card = styled(CardOld)`
  .field-fc-small,
  .field-fc-medium {
    margin-top: 10px;
  }
`

const { RoundedMoney } = icons;

class IncomeFormReview extends PureComponent {

  static defaultProps = {
    products: [1],
    entity: 'PF',
    editMode: false
  }

  calcTotalIncome = () => {
    const { entity, changedValues } = this.props;
    if (changedValues !== undefined && typeof changedValues === 'object') {
      if (entity === 'PF') {
        const { renda_principal_review, outras_rendas_review, total_renda_review } = changedValues;
        let total = 0;
        if (renda_principal_review !== undefined) {
          const other_income = (outras_rendas_review === undefined || isNaN(outras_rendas_review)) ? 0 : outras_rendas_review;
          total = other_income + renda_principal_review;
          total = formatToBRL(total);
          if (total !== total_renda_review) {
            this.props.change('total_renda_review', total);
          }
        }
      }
    }
  }

  getFields = (fieldsForm = 'renda') => {
    this.calcTotalIncome();
    const { products, entity, changedValues, disabledFields } = this.props;
    let fieldsName = getIncomeFields(products, entity, changedValues);
    if(fieldsForm == 'card') {
      fieldsName = getIncomeCardFieldsVeredito(products, entity, changedValues);
    }else if(fieldsForm == 'extratos') {
      fieldsName = getIncomeExtractFieldsVeredito(products, entity, changedValues);
    }
    return [{
      name: 'firstRow',
      fields: fieldsName.map((field) => (field in incomeFields) && {disabled: true, ...incomeFields[field]})
    }];
  }

  render() {
    const { entity, changedValues, origem_renda_documento_recomendacao, origem_renda_documentos, label, analises } = this.props;
    let cardTitle = (entity === 'PF')? 'RESULTADO FC DOC' : 'Dados financeiros';
    if(this.props.cardTitle) cardTitle = this.props.cardTitle

    return (
      <Card title={cardTitle} iconHeader={RoundedMoney} style={{position: 'relative'}} iconLeft>
        {analises[0].origem_renda_principal != 13 && analises[0].origem_renda_principal != 15 &&
          <FormContent rows={this.getFields()} />
        }
        {analises[0].origem_renda_principal == 13 &&
          <FormContent rows={this.getFields('card')} />
        }
        {analises[0].origem_renda_principal == 15 &&
          <FormContent rows={this.getFields('extratos')} />
        }
        {this.props.moduloAnexo &&
          <ModuleFilesAnexo
            changedValues={changedValues}
            origem_renda={changedValues.origem_renda}
            origem_renda_secundaria={changedValues.origem_renda_secundaria}
            origem_renda_documento_recomendacao={origem_renda_documento_recomendacao || []}
            origem_renda_documentos={origem_renda_documentos}
            sendFileUpload={this.props.sendFileUpload}
            removeFileUpload={this.props.removeFileUpload}
            modulo_principal={this.props.modulo_principal}
            modulo_secundario={this.props.modulo_secundario}
            loadingAllFiles={this.props.loadingAllFiles}
            loadingFileUploadPrincipal={this.props.loadingFileUploadPrincipal}
            loadingFileUploadSecundario={this.props.loadingFileUploadSecundario}
            location={this.props.location}
            principalIsValid={this.props.principalIsValid}
            secundarioIsValid={this.props.secundarioIsValid}
          /> || null
        }

        {this.props.moduloAnalise &&
          <ModuleFilesAnalise
            changedValues={changedValues}
            origem_renda={changedValues.origem_renda}
            origem_renda_secundaria={changedValues.origem_renda_secundaria}
            origem_renda_documento_recomendacao={origem_renda_documento_recomendacao || []}
            origem_renda_documentos={origem_renda_documentos}
            movimentacao_bancaria_extratos_review={changedValues.origem_renda} 
            sendFileUpload={this.props.sendFileUpload}
            removeFileUpload={this.props.removeFileUpload}
            modulo_principal={this.props.modulo_principal}
            modulo_secundario={this.props.modulo_secundario}
            loadingAllFiles={this.props.loadingAllFiles}
            loadingFileUploadPrincipal={this.props.loadingFileUploadPrincipal}
            loadingFileUploadSecundario={this.props.loadingFileUploadSecundario}
            location={this.props.location}
            principalIsValid={this.props.principalIsValid}
            secundarioIsValid={this.props.secundarioIsValid}
          /> || null
        }

        {/* <IncomeModal /> */}
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  changedValues: getValues(state, 'incomeFormReview'),
  initialValues: getInitialValues(state, ownProps.editMode, ownProps.entity),
  origem_renda_documento_recomendacao: state.requirements.origem_renda,
  origem_renda_documentos: state.requirements.origem_renda_documento,
  modulo_principal: state.requestForm.modulo_principal,
  modulo_secundario: state.requestForm.modulo_secundario,
  loadingAllFiles: state.requestForm.loadingAllFiles,
  loadingFileUploadPrincipal: state.requestForm.loadingFileUploadPrincipal,
  loadingFileUploadSecundario: state.requestForm.loadingFileUploadSecundario,
  analises: state.requestResume.currentApplicant.pretendente[0].renda ? state.requestResume.currentApplicant.pretendente[0].renda.analises : state.requestResume.currentApplicant.pretendente[0].faturamento.analises
});

const IncomeFormWithReduxForm = reduxForm({
  form: 'incomeFormReview',
  enableReinitialize: true
})(IncomeFormReview)

const IncomeFormConnected = connect(mapStateToProps, null)(IncomeFormWithReduxForm);

export default IncomeFormConnected;