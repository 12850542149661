import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import inputHandler from '../inputHandler';
import { handleSelectFields } from '../../../utils/formHelper';
import { FormContentRow } from '../sharedStyles.styles';
import DynamicPhoneOperational from '../../Operational/forms/fields/DynamicPhoneOperational';

import styled from 'styled-components'
import { bindActionCreators } from 'redux';
import { fetchCEP } from '../../../pages/validation/actions';

export const Teste = styled.div`
  & > div {
    flex-basis: unset;
    flex-grow: unset;
  }
  
  width: ${props => props.double ? "25%" : "12.5%"};
`;

class FormContent extends PureComponent {
  static defaultProps = {
    headers: [],
    extraData: {},
    additionalRequirements: {},
    requirements: {}
  }
  
  state = this.props.extraData
  
  handleFields = (field) => {
    const { requirements } = this.props;
    requirements.editMode = this.props.editMode
    let input = inputHandler(field);

    if (field.type === 'composed') {
      input = inputHandler({ ...field, options: handleSelectFields(field.innerInputs[0], requirements) });
    }

    if (Array.isArray(field.options) && field.options.length === 0) {
      input = inputHandler({ ...field, options: handleSelectFields(field, requirements) });
    }

    // if(field.type === "cep") {
    //   input = inputHandler({ cep })({ ...field, fetchCEP });
    // }

    // # Solução para retirar o Selecione do campo de UF nas versões de 800px pra baixo
    if(field.name == 'uf' && window.innerWidth <= 800) {
      const options = input.props.options.map(option => {
        if(option.label == 'Selecione') {
          option.label = ''
        }
        return option
      })

      input = {
        ...input,
        props: {
          ...input.props,
          options
        }
      }
    }
    
    return input;
  }

  renderField = (field) => {
    
    if (field.type === "dynamicPhonesRequest") {
      return <DynamicPhoneOperational {...field} field={field} />
    }
    
    return this.handleFields(field)
  }

  handleRowContainer = () => this.props.RowContainer || FormContentRow

  renderFormRows = () => {
    const { rows, headers, renderRows } = this.props;
    if (renderRows) {
      return renderRows(this.handleFields);
    }

    if (Array.isArray(rows) && rows.length > 0) {
      const mappedRows = rows.map((row, index) => {
        const Container = this.handleRowContainer();
        return (
          <Fragment key={row.name}>
            {headers[index]}
            <Container key={row.name}>
              {row.fields.map(this.renderField)}
              {this.props.renderNextToRow}
            </Container>
          </Fragment>
        );
      });

      return mappedRows;
    }

    return null;
  }

  render() {
    const { WrappedComponent } = this.props;

    if (WrappedComponent) {
      return (
        <WrappedComponent {...this.props}>
          {this.renderFormRows()}
        </WrappedComponent>
      );
    }

    return (
      <Fragment>
        {this.renderFormRows()}
      </Fragment>
    ); 
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ fetchCEP }, dispatch);

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    requirements: { ...state.requirements, ...ownProps.additionalRequirements }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContent);
