import React, { PureComponent } from 'react';
import PageNumber from './PageNumber';
import {
  PaginationCol,
  BoundaryClickableContainer,
  BoundaryContent,
  BoundaryTitle,
  PaginationContent,
  PaginationClickableContainer,
  PaginationClickableContent
} from './footer.styles';
import { StyledRow } from '../sharedStyles.styles';

class Pagination extends PureComponent {
  state = {
    lastPage: 1,
    firstPage: 1,
    perPage: undefined,
    allPages: this.props.totalPages,
    search: this.props.filter
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { totalPages, resultsPerPage, currentPage, filter } = nextProps;
    const { perPage, allPages, search } = prevState;

    if (currentPage === 1) {
      if (resultsPerPage !== perPage || allPages !== totalPages) {
        return { firstPage: 1, lastPage: totalPages >= 5 ? 5 : totalPages, perPage: resultsPerPage, allPages: totalPages };
      }
  
      if (search !== filter) {
        return { firstPage: 1, lastPage: totalPages >= 5 ? 5 : totalPages, perPage: resultsPerPage, search: filter };
      }

      return null;
    }

    return null;
  }
  
  changePage = (newPage) => {
    const { totalPages, changePage, currentPage } = this.props;
    let lastPage = this.state.lastPage;
    let firstPage = this.state.firstPage;
    
    if (newPage !== currentPage) {
      if (newPage >= 4 && newPage < totalPages) {

        if (newPage >= (totalPages - 2)) {
          lastPage = totalPages;
          firstPage = (totalPages - 4);
        } else {
          lastPage = (newPage + 2);
          firstPage = (newPage - 2);
        }
        
      } else if (newPage < 4 && totalPages >= 5) {

        if (newPage > 2) {
          lastPage = (newPage + 2);
          firstPage = (newPage - 2);
        } else {
          lastPage = 5;
          firstPage = 1;
        }
      }
      
      if (newPage === totalPages && totalPages >= 5) {
        lastPage = newPage;
        firstPage = (newPage - 4);
      }
    
      this.setState({ lastPage, firstPage }, () => {
        changePage(newPage);
      });
    }
    
  }

  goToEnd = () => {
    const { totalPages } = this.props;
    this.changePage(totalPages);
  }

  goToStart = () => {
    this.changePage(1);
  }

  goBackward = () => {
    const { currentPage } = this.props;
    const previousPage  = currentPage - 1.

    if (previousPage >= 1) {
      this.changePage(previousPage);
    }
  }

  goForward = () => {
    const { currentPage, totalPages } = this.props;
    const nextPage  = currentPage + 1.

    if (nextPage <= totalPages) {
      this.changePage(nextPage);
    }
  }

  isFinalPage = () => {
    const { currentPage } = this.props;
    const { allPages } = this.state;
    return currentPage === allPages;
  }

  isInitialPage = () => {
    const { currentPage } = this.props;
    return currentPage === 1;
  }

  renderNumberOfPages = () => {
    const { currentPage } = this.props;
    const { lastPage, firstPage } = this.state;
    if (lastPage !== undefined && lastPage > 0) {
      let count = firstPage;
      const pages = [];
      while(count <= lastPage) {
        pages.push(
          <PageNumber num={count} currentPage={currentPage} onClick={this.changePage} />
        );
        count++;
      }

      return pages;
    }

    return null;
  }


  render() {
    return (
      <PaginationCol>
        <StyledRow justify='space-between !important' >
          <BoundaryClickableContainer visible={!this.isInitialPage()} onClick={this.goToStart} justify="flex-start">
            <BoundaryContent margin={{ right: '10px' }}>
              {'\u25C2'}
              <BoundaryTitle  margin={{ left: '5px' }}>Primeira</BoundaryTitle>
            </BoundaryContent>
          </BoundaryClickableContainer>

          <PaginationContent>
            <PaginationClickableContainer visible={!this.isInitialPage()} onClick={this.goBackward} margin={{ right: '10px' }}>
              <PaginationClickableContent>
                {'\u25C2'}
              </PaginationClickableContent>
            </PaginationClickableContainer>

            {this.renderNumberOfPages()}
          
            <PaginationClickableContainer visible={!this.isFinalPage()} onClick={this.goForward} margin={{ left: '10px' }}>
              <PaginationClickableContent>
                {'\u25B8'}
              </PaginationClickableContent>
            </PaginationClickableContainer>
          </PaginationContent>

          <BoundaryClickableContainer visible={!this.isFinalPage()} onClick={this.goToEnd} justify="flex-end" >
            <BoundaryContent margin={{ left: '5px' }}>
              <BoundaryTitle  margin={{ right: '5px' }} >Última</BoundaryTitle>
              {'\u25B8'}
            </BoundaryContent>
          </BoundaryClickableContainer>
        </StyledRow>
      </PaginationCol>
    );
  }
}

export default Pagination;

