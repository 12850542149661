import styled from 'styled-components';
import { StyledRow, BaseColumn, HalfColumn } from '../sharedStyles.styles';

export const PaginationFooterContainer = styled(StyledRow)`
  height: 30px;

  @media(max-width: 800px){
    position: ${ props => props.position || 'unset'};
    bottom: 10px;
    left: 0;
    padding: .5rem;
  }
`;

export const PaginationCol = styled(BaseColumn).attrs({
  xl: 6,
  lg: 6,
  md: 6,
  sm: 6,
  xs: 12,
  col: 6
})`
`;

export const MaxPerPageCol = styled(HalfColumn)`
`;

export const Typography = styled.span`
  color: ${props => props.theme.color_gray};
  font-size: 13px;
  font-weight: 400;
  text-align: center;

  @media(max-width: 800px){
    display:block;
  }
`;

export const PageNumberTypography = styled(Typography)`
  font-size: 11px;
  font-weight: ${props => props.current ? 'bold' : 400};
`;

export const PageNumberContainer = styled.div`
  margin: 0 5px;
  display: flex;
  background-color: transparent;
  padding: 10px 15px;
  text-align: center;
  flex-direction: column;
  justify-content: center;

  @media(max-width: 800px){
    margin: 0;
    padding: 10px 13px;
  }
`;

export const PageNumberUnderScore = styled.div`
  height: 2px;
  width: 15px;
  background-color: ${props => props.theme.color_gray};
`;

export const PageNumberCol = styled(BaseColumn).attrs({
  xl: 'auto',
  lg: 'auto',
  md: 'auto',
  sm: 'auto',
  col: 'auto'
})`
  cursor: pointer;
`;

export const BoundaryClickableContainer = styled.div`
  cursor: ${props => props.visible ? 'pointer' : 'default'};
  width: 20%;
  display: flex;
  justify-content: ${props => props.justify};
  pointer-events: ${props => props.visible ? 'auto' : 'none'};
  opacity: ${props => props.visible ? '1' : '0.3'};
`;

export const BoundaryContent = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 11px;
  margin-right: ${props => props.margin.right || 0};
  margin-left: ${props => props.margin.left || 0};
`;

export const BoundaryTitle = styled.span`
  font-size: 13px;
  margin-right: ${props => props.margin.right || 0};
  margin-left: ${props => props.margin.left || 0};
`;

export const PaginationContent = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center 
`;

export const PaginationClickableContainer = styled.div`
  cursor: ${props => props.visible ? 'pointer' : 'default'};
  margin-right: ${props => props.margin.right || 0};
  margin-left: ${props => props.margin.left || 0};
  visibility: ${props => props.visible ? 'show' : 'hidden'}
`;

export const PaginationClickableContent = styled.span`
  font-size: 11px;
`;

export const ResulstSelect = styled.select`
  background-color: transparent;
  border: none;
  color: ${props => props.theme.color_gray};
  font-size: 14px;
  margin-left: 10px;

  &:focus {
    outline: none;
  }
`;
