import React, { PureComponent } from 'react';
import ModalContainer from '@bit/wavfichacerta.fichacerta.modals.modal-container';
import {
  Container,
} from './sacModal.styled'
import { CardTitle } from '../components/Card.styled'
import CardMessageForm from '../components/CardMessageForm';

export default class SACModal extends PureComponent {

  static defaultProps = {
    message: '',
    callBack: () => {}
  }

  state = { isOpen: true }

  closeModal = (action = true) => {
    this.setState({ isOpen: false }, () => {
      this.props.callBack(action);
    });
  }

  renderHeader = () => (
    <CardTitle>
        Atendimento ao cliente
    </CardTitle>
  )

  render() {
    return (
      <ModalContainer
        onClose={this.closeModal}
        isOpen={this.state.isOpen}
        header={this.renderHeader()}
      >
        <Container>
          <CardMessageForm toggleCard={this.props.toggleCard} />
        </Container>
      </ModalContainer>
    );
  }
}
