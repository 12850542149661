import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Container = styled.svg`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  & > path { fill: ${props => props.color || props.theme['color_purple']}; }
`;

export default class Location extends PureComponent {
  render() {
    return (
      <Container viewBox="0 0 307.11 409.57">
        <path d="M154.4,505.6c-7.94-5.29-8.9-8.82-4.74-17.63q25.59-54.15,51.15-108.31a16.87,16.87,0,0,1,9.13-8.92c8-3.35,15.79-7,24.08-10.68-.8-1.48-1.42-2.73-2.13-3.92-13.91-23.33-28-46.57-41.7-70-14.74-25.19-19.62-52.45-15.21-81.21,6.34-41.28,28.34-72.17,64.29-92.8,24.3-13.95,50.79-18.65,78.64-14.78,37.16,5.17,66.48,23.49,87.82,54.06,19,27.16,25.51,57.65,21.1,90.41-2.34,17.37-8.51,33.38-17.69,48.37C395.85,311.86,383,333.82,370,355.67c-.75,1.25-1.47,2.52-2.4,4.13,1.12.61,2.09,1.22,3.12,1.69,7.29,3.27,14.56,6.56,21.89,9.72a14.68,14.68,0,0,1,7.75,7.62q26.05,55.25,52.2,110.46c3.67,7.77,1.77,12.66-6.15,16.32h-4.8q-33.82-15.19-67.66-30.31a4.66,4.66,0,0,0-3.36.22q-33.72,15-67.38,30.09h-4.8c-22-9.83-44-19.62-65.89-29.56a7.63,7.63,0,0,0-7.11.09c-5.28,2.53-10.68,4.79-16,7.19Q184.68,494.45,160,505.6ZM408,217.78c-.6-4-1.23-11.32-2.86-18.43-6.19-27-20.81-48.6-43.67-64.13-29.45-20-61.58-24.52-95.2-13-32.86,11.29-55.33,34-66.7,66.83-10.37,29.87-7.88,59.31,8.08,86.67,27.18,46.57,55.13,92.68,82.78,139a29.22,29.22,0,0,0,3.17,4.59,9.74,9.74,0,0,0,14.5,0,29.82,29.82,0,0,0,3.16-4.59q41.16-68.93,82.29-137.88C403.75,259.65,408,240.94,408,217.78ZM178.18,475c2.1-.86,3.31-1.33,4.49-1.85,13.25-5.89,26.55-11.69,39.72-17.76a14.91,14.91,0,0,1,13.36,0c20.36,9.23,40.85,18.17,61.21,27.39a8.55,8.55,0,0,0,7.86-.09c20.25-9.14,40.62-18,60.85-27.22a15.23,15.23,0,0,1,13.72,0c13.16,6.09,26.46,11.87,39.71,17.76,1.16.51,2.36.92,3.73,1.45a5.32,5.32,0,0,0-.14-1.15q-19.5-41.34-39-82.65a4.37,4.37,0,0,0-1.67-2.11c-8.28-3.8-16.62-7.49-24.8-11.14a12.94,12.94,0,0,0-.85,1q-14.24,23.83-28.48,47.66c-13.34,22.2-40.83,22.12-54.19-.15q-13.46-22.44-26.81-44.93c-.73-1.23-1.55-2.4-2.35-3.63-6.35,2.81-12.32,5.82-18.55,8.09-5.26,1.92-8.25,5.27-10.59,10.32C203.31,422.09,190.92,448,178.18,475Z" transform="translate(-147.24 -96.03)"/>
        <path d="M300.67,177.37a46.17,46.17,0,0,1,46.28,45.81c.24,25.12-20.56,46.17-45.81,46.37a46.09,46.09,0,1,1-.47-92.18Zm25.94,46a25.79,25.79,0,1,0-25.89,25.91A25.84,25.84,0,0,0,326.61,223.4Z" transform="translate(-147.24 -96.03)"/>
      </Container>
    );
  }
};
