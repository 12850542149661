import React, { Component } from 'react';
const VerifyIcon = require('../../../img/png/ico-verify.png');

export default class Verify extends Component {
  render() {
    return (
      <img src={VerifyIcon} alt="" />
    )
  }
}
