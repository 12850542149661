import React, { PureComponent } from 'react';

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  CardAnalythic,
  SubtitleCard,
  SubtitleSemiBold,
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  RecommendationBox,
  RecommendationText,
  RecommendationsColumn,
  IconRecommendation,
  SpanRecommendation,
  OtherDataList
} from '../analythic.styled';

import { getLocationChecked, getLocationCheckedBoth, getLocationCheckedThree } from './products/fcEmpresa';
import Location from '@bit/wavfichacerta.fichacerta.icons.location';
import Person from '@bit/wavfichacerta.fichacerta.icons.person';
import ReportRecommendation from '@bit/wavfichacerta.fichacerta.icons.report-recommendation';

import styled from 'styled-components';

export const BaseColumnN = styled(BaseColumn)`
  ::before {
    content: '|';
    font-size: 46px;
    position: absolute;
    top: -32px;
    left: -13px;
    color: #fff;
  }
`;

export default class SociosDiretoresSection extends PureComponent {

  renderDataLine = (data) => (
    <AnalythicLine key={(data)} className='sociosDiretores' style={{color: '#000'}}>
      <BaseColumnN padding="0 .2rem" customstyle='display: flex;' xs={6} md={6} lg={6} xl={6}>
        <LineTitle maxWidth="20%">Nome (função)</LineTitle>
        <span data-testid={data}>{data.nome} ({data.funcao})</span>
      </BaseColumnN>

      <BaseColumnN padding="0 .2rem" customstyle='display: flex;' xs={3} md={3} lg={3} xl={3}>
        <LineTitle maxWidth="35%">{data.documentFormat}</LineTitle>
        <span data-testid={data}>{data.cpf}</span>
      </BaseColumnN>

      <BaseColumnN padding="0 .2rem" customstyle='display: flex;' xs={3} md={3} lg={3} xl={3}>
        <LineTitle maxWidth="40%">Participação</LineTitle>
        <span data-testid={data}>{data.participacao}</span>
      </BaseColumnN>
    </AnalythicLine>
  )

  renderOtherAddresses = (title, data) => (
    <AnalythicLine key={(title + data)} style={{color: '#000'}}>
      <LineTitle maxWidth="205px">{title}</LineTitle>
      <span data-testid={title}>
        <OtherDataList>{data.map((address) => <li>{address}</li>)}</OtherDataList>
      </span>
    </AnalythicLine>
  )

  renderDataChecked = () => {
    const dataChecked = getLocationChecked(this.props.data);

    if(dataChecked.length === 0) {
      return (
        <AnalythicLine className='sociosDiretores' style={{marginLeft: -4}}>
          <BaseColumnN padding="0 .2rem" customstyle='display: flex;' xs={12} md={12} lg={12} xl={12}>
            <span style={{marginLeft: 3}}>Não encontrado</span>
          </BaseColumnN>
        </AnalythicLine>
      )
    }

    return dataChecked.map((line) => {
      if (line.key === 'otherAddress' && Array.isArray(line.data)) {
        return this.renderOtherAddresses(line.title, line.data);
      }
      return this.renderDataLine(line);
    });
  }

  renderDataCheckedBoth = () => {
    const dataChecked = getLocationCheckedBoth(this.props.data);
    return dataChecked.map((line) => {
      if (line.key === 'otherAddress' && Array.isArray(line.data)) {
        return this.renderOtherAddresses(line.title, line.data);
      }
      return this.renderDataLine(line.title, line.data);
    });
  }

  renderDataCheckedThree = () => {
    const dataChecked = getLocationCheckedThree(this.props.data);
    return dataChecked.map((line) => {
      if (line.key === 'otherAddress' && Array.isArray(line.data)) {
        return this.renderOtherAddresses(line.title, line.data);
      }
      return this.renderDataLine(line.title, line.data);
    });
  }

  getRecommendations = () => {
    const { data } = this.props;

    if (data !== undefined && data !== null && (typeof data === "object" && Object.keys(data).length > 0)) {
      const searchKeys = ["veracidade_endereco", "veracidade_telefone", "veracidade_celular", "veracidade_email"];
      const { laudo } = data;
      let recommendations = [];
      searchKeys.forEach((key) => {
        if (laudo[key] !== undefined && laudo[key] !== null) {
          recommendations = [...recommendations, ...laudo[key].recommendation];
        }
      });

      if (Array.isArray(recommendations) && recommendations.length > 0) {
        // Remove duplicates
        recommendations = recommendations.filter((rec, index) => recommendations.indexOf(rec) === index);
        return (
          <RecommendationBox>
            <BaseColumn xs={12} md={1} lg={1} xl={1}>
              <RecommendationText>Obs: </RecommendationText>
            </BaseColumn>
            <RecommendationsColumn xs={12} md={11} lg={11} xl={11}>
              {recommendations.map((item) => (
                <RecommendationText>
                  <IconRecommendation><ReportRecommendation /></IconRecommendation>
                  <SpanRecommendation>{item}</SpanRecommendation>
                </RecommendationText>
              ))}
            </RecommendationsColumn>
          </RecommendationBox>
        );
      }
    }
    return null;
  }

  getRecommendationsDicaOuro = () => {
    const { data } = this.props;
  
    if (data !== undefined && data !== null && (typeof data === "object" && Object.keys(data).length > 0)) {
      const { laudo } = data;
  
      if (Array.isArray(laudo.parecer_sistemico) && laudo.parecer_sistemico.length > 0 && laudo.parecer_sistemico[0].dica_ouro) {
        return (
          <RecommendationBox customstyle='margin-bottom: 10px !important;width: 99.3%;'>
            <BaseColumn xs={12} md={1} lg={1} xl={1}>
              <RecommendationText style={{color: '#000'}}>Obs: </RecommendationText>
            </BaseColumn>
            <RecommendationsColumn xs={12} md={11} lg={11} xl={11}>
              <RecommendationText>
                <IconRecommendation><ReportRecommendation/></IconRecommendation>
                <SpanRecommendation style={{color: '#000'}} dangerouslySetInnerHTML={{__html: data.laudo.parecer_sistemico[0].dica_ouro}} />
              </RecommendationText>
            </RecommendationsColumn>
          </RecommendationBox>
        );
      }
    }
    return null;
  }

  renderResultFcRenda = (result) => {
    const { data } = this.props;
    const fcDoc = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {};
    if(!fcDoc.resultado || !fcDoc.confirmacao_socios_diretores || !fcDoc.ativo) {
      return null;
    }

    return(
      <div style={{border: '2px solid #152b4e', padding: '4px 10px', color: '#152b4e', fontSize: 12, marginTop: 6, width: '100%', zIndex: 1, fontWeight: 'bolder'}} className='result-fcdoc'>
        CONFIRMAÇÃO SÓCIOS/DIRETORES: {fcDoc.confirmacao_socios_diretores}
      </div>
    )
  }

  render() {
    const { data } = this.props;
    const fcDoc = data.pretendente.produtos.ativo.filter(product => product.id === 6 || product.id === 8)

    return (
      <React.Fragment>
        <CardAnalythic
          title="Sócios/Diretores"
          iconHeader={Person}
          iconLeft={true}
        >
          {fcDoc.length > 0 && fcDoc[0].ativo === true && data.laudo.produtos.fcrenda.resultado && data.laudo.produtos.fcrenda.confirmacao_socios_diretores &&
            <span style={{backgroundColor: '#152b4e', color: '#fff', fontWeight: 600, padding: '2px 6px', marginLeft: 4, position: 'absolute', top: 11, left: 213}}>
              FC DOC
            </span>
          }

          <StyledRow margin=".5rem 0 0 0">
            <BaseColumn padding="0 .2rem">
              <ContainerGray data-testid="dadosLocalizacao">
                {/* <SubtitleCard>DADOS DE LOCALIZAÇÃO <SubtitleSemiBold>ENCONTRADOS</SubtitleSemiBold></SubtitleCard> */}
                <AnalythicList>
                  {this.renderDataChecked()}
                </AnalythicList>
              </ContainerGray>
            </BaseColumn>
            {/* <BaseColumn padding="0 .2rem">
              <ContainerGray data-testid="dadosLocalizacao">
                <AnalythicList>
                  {this.renderDataCheckedBoth()}
                </AnalythicList>
              </ContainerGray>
            </BaseColumn> */}
            {/* <BaseColumn padding="0 .2rem">
              <ContainerGray data-testid="dadosLocalizacao">
                <AnalythicList>
                  {this.renderDataCheckedThree()}
                </AnalythicList>
              </ContainerGray>
            </BaseColumn> */}
            {this.renderResultFcRenda()}
          </StyledRow>
        </CardAnalythic>
        {this.getRecommendationsDicaOuro()}
      </React.Fragment>
    );
  }
}
