import { pull } from 'lodash';

export const actionCreator = (type, payload) => {
  if (payload === undefined) {
    return { type };
  }

  return { type, payload };
};

export const generalActionTypes = {
  NON_HANDLED_ERROR: 'general/NON_HANDLED_ERROR'
};

export const extractErrorMessages = (objError) => {
  if(typeof objError === "string"){
    return objError;
  } else if (typeof objError === "object") {
    if (Object.keys(objError).length > 0){
      let messages = [];
      Object.keys(objError).map((keyMessages) => {
        objError[keyMessages].map((message) => {
          const fieldReference = keyMessages.split('.');
          const splitedMessage = message.split(' ');
          const newMessage = pull(splitedMessage, keyMessages).join(' ');
          if(fieldReference.length > 1){
            messages.push(`Em ${fieldReference[0].toUpperCase()} o campo ${fieldReference[1].toUpperCase()} ${newMessage}`);
          } else {
            messages.push(message);
          }
        });
      });
      return messages.join('\n');
    }
  }
  return "Não foi possível realizar esta operação no momento.";
}
