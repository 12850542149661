import React, { PureComponent } from 'react';
import createInputWithValidation from './createInputWithValidation';
import { Select } from './selectInput.styles';

class SelectInput extends PureComponent {

  optionRendererDDI = (options) => {
    if (Array.isArray(options) && options.length > 0) {
      return options.map((option) => (
        <option value={option.value.replace('+', '')}>{option.label}</option>
      ));
    }
    return null;
  }

  optionRenderer = (options) => {
    if (Array.isArray(options) && options.length > 0) {
      return options.map((option) => (
        <option value={option.value}>{option.label}</option>
      ));
    }
    return null;
  }

  handleOptions = () => {
    const { name, options } = this.props;

    if (name.search(/ddi/) > -1) {
      return this.optionRendererDDI(options);
    }

    return this.optionRenderer(options);
  }

  render() {
    const { disabled, name, onChange, onBlur, required, value } = this.props;
    return (
      <Select
        id={name}
        placeholder={this.props.placeholder}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        disabled={disabled}
        value={value}
      >
        {this.handleOptions()}
      </Select>
    );
  }
}

SelectInput.defaultProps = {
  isRequired: false,
  placeholder: ''
}

export default createInputWithValidation('select')(SelectInput);
