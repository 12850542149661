import styled, { css } from 'styled-components';
import { StyledBlueButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import { FullColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { Col } from 'reactstrap';

export const Title = styled.span`
  text-transform: uppercase;
  width: 100%;
  height: 30px;
  color: ${props => props.theme.color_purple};
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 800px) {
    margin-left: 2.5%;
    font-size: 14px;
    font-weight: 400;
    height: initial;
    padding-top: 6px;
  }

  ${({customstyle}) => customstyle}
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items : center;

  ${({customstyle}) => customstyle}
`;

export const ResumeButton = styled(StyledBlueButton)`
  width: 12rem;
  font-size: .8rem;
  margin-top: .5rem;
  padding: .25rem 1.5rem .25rem 1.5rem;

  ${({customstyle}) => customstyle}
`;

export const FadeColumn = styled(FullColumn)`
  width: 100%;
  ${props => !props.visible ? css`
    visibility: hidden;
    opacity: 0;
    transition: visibility 1.5s, opacity 0.5s linear;
  ` : ''};
`;

export const AnimatedDiv = styled.div`
  width: 65%;
  height: auto;
  background: transparent;
  transition: width 1.5s, height 1.5s;
  ${props => !props.visible ? css`
    width: 0;
    height: 0;
  ` : ''};
`;


export const Trs = styled(Col)`
  width: auto;
  ${props => !props.visible ? css`
    flex-direction: row !important;
  ` : ''};
`;

export const ApplicantsContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 68vh;
  height: auto;
`;

export const TitleButton = styled(ResumeButton)`
  width: auto;
  margin-top: 0;
  padding: .15rem .5rem;
  font-weight: 300;
  font-size: 12px;
`;
