import React from 'react';

import TableForReport from '../TableForReport';
import TableHeader from './TableHeader';
import TableLine from './TableLine';

const Table = ({ lines }) => {
  const renderLine = (line) => <TableLine {...line} />;

  return (
    <TableForReport
      loading={false}
      lines={Array.isArray(lines) ? lines : [lines]}
      renderHeader={() => <TableHeader />}
      renderLine={renderLine}
    />
  );
}


export default Table;
