
const showWillReside = (fields) => {
  if ('tipo_imovel' in fields && fields.tipo_imovel === 'RESIDENCIAL' && (fields.tipo_pretendente === 'INQUILINO' || fields.tipo_pretendente === 'CONJUGE_INQUILINO')) {
    return [];
  }
  return ['residir'];
}

const showWillParticipate = (fields) => {
  if ('tipo_imovel' in fields && fields.tipo_imovel === 'NAO_RESIDENCIAL' && fields.tipo_pretendente === 'INQUILINO') {
    return [];
  }
  return ['participante'];
}

const showBuildingUsage = (fields) => {
  if ("tipo_pretendente" in fields && fields.tipo_pretendente !== "") {
    return [];
  }
  return ['uso_imovel'];
}

/**
 * This function returns an array with the fields
 * that will be removed from the form. If the function
 * returns an empty array, all fields will appear to the
 * user.
 * 
 * @param entity <String>
 * @param fields <Array>
 */
export default (entity = 'PF', fields) => {
  let fieldsToRemove = [];

  if (entity === "PF" && fields !== undefined && fields !== null) {
    fieldsToRemove = [];

    if (typeof fields === 'object' && Object.keys(fields).length > 0) {
      fieldsToRemove = showWillReside(fields);
      fieldsToRemove = [...fieldsToRemove, ...showWillParticipate(fields)];
    }
  } else if (entity === "PJ" && fields !== undefined && fields !== null) {
    fieldsToRemove = [];

    if (typeof fields === 'object' && Object.keys(fields).length > 0) {
      fieldsToRemove = showBuildingUsage(fields);
    }
  }

  return fieldsToRemove;
}

