
export const onSendRequest = (state) => ({
  ...state,
  requesting: true,
  error: undefined
});

export const onSendRequestSuccess = (state, payload) => ({
  ...state,
  requesting: false,
  error: false,
  requestId: payload
});

export const onSendRequestFailed = (state) => ({
  ...state,
  requesting: false,
  error: true,
  loadingFileUploadPrincipal: false,
  loadingFileUploadSecundario: false
});

export const loadingSendFileUploadPrincipal = (state, payload) => ({
  ...state,
  loadingFileUploadPrincipal: payload.loading
});

export const loadingSendFileUploadSecundario = (state, payload) => ({
  ...state,
  loadingFileUploadSecundario: payload.loading
});

export const onExcludedFileUploadPrincipal = (state, payload) => ({
  ...state,
  loadingFileUploadPrincipal: false,
  loadingFileUploadSecundario: false,
  modulo_principal: [...payload]
});

export const onExcludedFileUploadSecundario = (state, payload) => ({
  ...state,
  loadingFileUploadPrincipal: false,
  loadingFileUploadSecundario: false,
  modulo_secundario: [...payload]
});

export const onSendFileUploadPrincipal = (state, payload) => ({
  ...state,
  loadingFileUploadPrincipal: false,
  loadingFileUploadSecundario: false,
  modulo_principal: [...state.modulo_principal, ...payload]
});

export const onSendFileUploadSecundario = (state, payload) => ({
  ...state,
  loadingFileUploadPrincipal: false,
  loadingFileUploadSecundario: false,
  modulo_secundario: [...state.modulo_secundario, ...payload]
});