export const onSendCustomerMessage = (state) => ({
  ...state,
    isSending: true,
    errorSending: undefined
});

export const onSendCustomerMessageSuccess = (state) => ({
  ...state,
    isSending: false,
    errorSending: false
});

export const onSendCustomerMessageFailed = (state) => ({
  ...state,
  isSending: false,
  errorSending: true
});