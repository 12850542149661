import React from "react";
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.width? props.width : '1.5rem'};
  position: absolute;
  top: 3px;
  bottom: 0;
  left: 4px;
  right: 0;

  & > g > g > path {
    fill: ${props => props.theme.color_purple};
  }
`;

const BackSVG  = (props) => (
  <StyledSVG viewBox="0 0 632 667" {...props} >
    <g>
      <g id="reply">
        <path d="M178.5,140.25v-102L0,216.75l178.5,178.5V290.7c127.5,0,
        216.75,40.8,280.5,130.05C433.5,293.25,357,165.75,178.5,140.25z"
          />
      </g>
    </g>
  </StyledSVG>
);

export default BackSVG;
