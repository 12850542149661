import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  getRequests,
  changePage,
  changePerPage,
  onChangeSort
} from "./actions";
import { setFilter } from "./actions";
import { getRequestsList, getLoadingStatus } from "./selector";

import { BaseColumn } from "@bit/wavfichacerta.fichacerta.shared-styles.columns";
import {
  StyledCard,
  StyledRowMobile,
  StyledLineMobile,
  StyledTitleMobile,
  StyledCardBody
} from "./styled";
import PaginationFooter from "../../../components/shared/table/PaginationFooter";
import { formatAddress, formatDateToBR } from '../../../utils/formatter';
import { StyledHr } from "../../../components/Operational/AnalythicReport/TradeFinancialSection/styled";

class RequestsTable extends PureComponent {
  state = {
    current: "id",
    sort: "ASC"
  };

  componentDidMount() {
    this.props.setFilter("");
    this.props.getRequests();
  }

  onChangePage = page => {
    this.props.changePage(page);
    this.props.getRequests();
  };

  onChangeResultsperPage = perPage => {
    this.props.changePerPage(perPage);
    localStorage.setItem("resultsPerPageRequests", perPage);
    this.props.getRequests();
  };

  changeCurrentSort = name => {
    const { current, sort } = this.state;
    let newSort = "";
    if (current === name) {
      newSort = sort === "ASC" ? "DESC" : "ASC";
    } else {
      newSort = "ASC";
    }
    this.setState({ current: name, sort: newSort }, () => {
      const apiSort = newSort === "DESC" ? `-${name}` : name;
      this.props.onChangeSort(apiSort);
      this.props.getRequests();
    });
  };

  renderLine = (lines, index) => {
    return lines.map(line => {
      const onGoToResume = () => {
        this.props.setFilter("");
        this.props.history.push({
          pathname: "/intranet/operacional/solicitacao/resumo",
          state: { request: line.id }
        });
      };
      return (
        <StyledCard onClick={onGoToResume}>
          <StyledCardBody>
            <StyledRowMobile>
              <BaseColumn xs="4" sm="4">
                <StyledTitleMobile>ID</StyledTitleMobile>
                <StyledLineMobile>{line.id}</StyledLineMobile>
              </BaseColumn>
              <BaseColumn xs="8" sm="8">
                <StyledTitleMobile>Endereço</StyledTitleMobile>
                <StyledLineMobile>{line.locacao.endereco.length === 0 ?  'Endereço não Informado.' : line.locacao.endereco.map(endereco => {
                  return formatAddress(endereco)
                })} </StyledLineMobile>
              </BaseColumn>
            </StyledRowMobile>
            <StyledHr />
            <StyledRowMobile>
              <BaseColumn xs="4" sm="4">
                <StyledTitleMobile>Data de Inclusão</StyledTitleMobile>
                <StyledLineMobile>{formatDateToBR(line.data_criacao, true)}</StyledLineMobile>
              </BaseColumn>
              <BaseColumn xs="8" sm="8">
                <StyledTitleMobile>Pretendente</StyledTitleMobile>
                <StyledLineMobile>{line.pretendentes.map((pretendente, index) => {
                  if(pretendente.nome === ''){
                    return "Nome não Informado; ";
                  }
                  if(line.pretendentes.length - 1 === index){
                    return pretendente.nome
                  }
                  return `${pretendente.nome}; `
                })}</StyledLineMobile>
              </BaseColumn>
            </StyledRowMobile>
          </StyledCardBody>
        </StyledCard>
      );
    });
  };

  render() {
    const { lines } = this.props;
    return (
      <Fragment>
        {this.renderLine(lines)}
        <PaginationFooter
          pagination={this.props.pagination}
          changePage={this.onChangePage}
          changeMaxResults={this.onChangeResultsperPage}
          callToAction={this.props.callToAction}
          allowMaxPerPage={true}
          customstyleContainer={{padding: '.5rem 0'}}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getRequests, changePage, changePerPage, onChangeSort, setFilter },
    dispatch
  );

const mapStateToProps = state => ({
  lines: getRequestsList(state),
  requesting: getLoadingStatus(state),
  pagination: state.requestsTable.pagination
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RequestsTable)
);
