import {
  simpleNonRequiredMoney,
  simpleNonRequiredSelect,
  simpleDisabledText,
  simpleRequiredSelect,
  simpleRequiredMoney,
  simpleRequiredText,
  defaultNonRequiredPhone,
  defaultPhone
} from '../../../../../components/shared/fields';

export default {
  "renda_principal": simpleRequiredMoney({ name: 'renda_principal', label: 'Renda principal', size: 'field-fc-small field-fc-sm-4' }),
  "renda_principal_obrigatoria": simpleRequiredMoney({ name: 'renda_principal', label: 'Renda principal', size: 'field-fc-small field-fc-sm-4' }),
  
  "origem_renda": simpleRequiredSelect({ size: 'field-fc-medium field-fc-sm-8', name: 'origem_renda', label: 'Origem de Renda Principal' }),
  "origem_renda_obrigatoria": simpleRequiredSelect({ size: 'field-fc-medium field-fc-sm-8', name: 'origem_renda', label: 'Origem de Renda Principal' }),
  
  "outras_rendas": simpleNonRequiredMoney({ name: 'outras_rendas', label: 'Outras rendas', size: 'field-fc-small field-fc-sm-4' }),
  "outras_rendas_obrigatoria": simpleRequiredMoney({ name: 'outras_rendas', label: 'Outras rendas', size: 'field-fc-small field-fc-sm-4' }),
  
  "origem_renda_secundaria": simpleNonRequiredSelect({ size: 'field-fc-medium field-fc-sm-8', name: 'origem_renda_secundaria', label: 'Origem de Renda Secundária' }),
  "origem_renda_secundaria_obrigatoria": simpleRequiredSelect({ size: 'field-fc-medium field-fc-sm-8', name: 'origem_renda_secundaria', label: 'Origem de Renda Secundária' }),
  
  "total_renda": simpleDisabledText({ size: 'field-fc-small field-fc-sm-4 read-only', name: 'total_renda', label: 'Renda pessoal total' }),
  "compor_renda": simpleRequiredSelect({ size: 'field-fc-small field-fc-sm-8', name: 'compor_renda', label: 'Irá compor renda?' }),
  
  "faturamento_mensal": simpleRequiredMoney({ size: 'field-fc-medium', name: 'faturamento_mensal', label: 'Faturamento bruto médio mensal' }),
  "tipo_negocio": simpleRequiredSelect({ size: 'field-fc-medium', name: 'tipo_negocio', label: 'Tipo de negócio' }),
  "natureza_juridica": simpleRequiredSelect({ size: 'field-fc-medium', name: 'natureza_juridica', label: 'Natureza jurídica' }),

  "receita_media_mensal": simpleRequiredMoney({ size: 'field-fc-medium', name: 'receita_media_mensal', label: 'Receita Média Mensal' }),
  "capital_de_investimento": simpleRequiredMoney({ size: 'field-fc-medium', name: 'capital_de_investimento', label: 'Capital de Investimento (empresa recente)' }),
  "patrimonio_estimado": simpleRequiredMoney({ size: 'field-fc-medium', name: 'patrimonio_estimado', label: 'Patrimônio Estimado' }),
  "nome_do_responsavel_pelas_informacoes": simpleRequiredText({ size: 'field-fc-medium', name: 'nome_do_responsavel_pelas_informacoes', label: 'Nome do Responsável pelas Informações' }),
  "tel_contato_pj": defaultPhone( 'tel_contato_pj', 'Tel/Cel de Contato', 'field-fc-small field-fc-sm-4' ),
  "tel_contato_pj_nao_obrigatorio": defaultNonRequiredPhone( 'tel_contato_pj', 'Tel/Cel de Contato', 'field-fc-small field-fc-sm-4' ),

  "avaliacao_financeira": simpleRequiredSelect({ name: 'avaliacao_financeira', label: 'PERFIL FINANCEIRO por', size: 'field-fc-medium field-fc-sm-4' }),
  "avaliacao_financeira_fcdoc_pj": simpleRequiredSelect({ name: 'avaliacao_financeira_fcdoc_pj', label: 'PERFIL FINANCEIRO por', size: 'field-fc-medium field-fc-sm-4' }),

  "limite_cartao_credito": simpleRequiredMoney({ name: 'limite_cartao_credito', label: 'Valor', size: 'field-fc-small field-fc-sm-4' }),

  "movimentacao_bancaria_extratos": simpleRequiredMoney({ name: 'movimentacao_bancaria_extratos', label: 'Valor médio das receitas mensais (informado)', size: 'field-fc-small field-fc-sm-4' }),

  "tel_contato": defaultPhone( 'tel_contato', 'Tel/Cel do pretendente', 'field-fc-small field-fc-sm-4' ),
  "tel_contato_nao_obrigatorio": defaultNonRequiredPhone( 'tel_contato', 'Tel/Cel do pretendente', 'field-fc-small field-fc-sm-4' ),

  "tel_contato_trabalho": defaultPhone( 'tel_contato_trabalho', 'Tel/Cel do trabalho', 'field-fc-small field-fc-sm-4' ),
};