import { pick } from 'lodash';

export const filterClients = (list) => list.map((customer) => customer.cliente);

export const filterProducts = (products, id) => {
  if (Array.isArray(products) && products.length > 0) {
    const filteredList = products.filter(product => product.id !== id);
    return filteredList;
  }

  return [];
};

export const updateProductById = (products, id, { discount, value }) => {
  if (Array.isArray(products) && products.length > 0) {
    const mappedProducts = products.map((product) => {
      if (product.id === id) {
        return {
          ...product,
          desconto: discount,
          valor: value
        }
      }
    })
    return mappedProducts;
  }

  return [];
};

export const handleEmployeeDetail = (data) => {
  const baseObj = {
    perfil: data.perfil.titulo,
    ...pick(data.pessoa, ['nome', 'cpf', 'identidade', 'orgao_expedidor']),
    // telefone: data.telefones.comercial.numero,
    // ddi: data.telefones.comercial.ddi,
    email: data.usuario.email,
    id: data.funcionario.id,
    funcao: data.funcionario.funcao
  };

  if (data.funcionario.master) {
    const { operacional, financeiro } = data.funcionario;
    const tipo_acesso = [];

    if (financeiro) tipo_acesso.push('FINANCEIRO');
    if (operacional) tipo_acesso.push('OPERACIONAL');

    return {
      ...baseObj,
      tipo_acesso
    }
  }

  return baseObj;
};

export const getSumEmployeesCredit = (employees) => {
  const sum = employees.reduce((acc, employee) => acc + parseFloat(employee.credito), 0);
  return sum;
};

export const getSumEmployeesUsedCredit = (employees) => {
  const sum = employees.reduce((acc, employee) => acc + parseFloat(employee.credito_utilizado), 0);
  return sum;
}

export const getCreditBalances = (employees, credit) => {
  const avaliableBalance = parseFloat(credit) - getSumEmployeesUsedCredit(employees);
  const notLinkedBalance = parseFloat(credit) - getSumEmployeesCredit(employees);

  return {
    avaliableBalance,
    notLinkedBalance
  }
}