
export const getValues = (state, formName = '') => {
  if (formName in state.form && state.form[formName] !== undefined) {
    return state.form[formName].values || {};
  }
  return {};
}

export const getInitialValues = (state, formName = '') => {
  if (formName in state.form && state.form[formName] !== undefined) {
    return state.form[formName].initial || {};
  }
  return {};
}

export const getAllValues = (state, formName = '') => {
  if (formName in state.form && state.form[formName] !== undefined) {
    return {changedValues: state.form[formName].values, initialValues: state.form[formName].initial} || {};
  }
  return {};
}

export const getRequestStatus = (state) => state.requestForm.requesting;
export const getSubmitStatus = (state) => state.requestForm.error;

