import React from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { HalfColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  ContainerGray,
  AnalythicList,
  ColoredSpan,
  AnalythicLine,
  LineTitle,
} from '../../analythic.styled';
import { ColoredColumn, TableTitle, Line } from '../styled';

import { protestsResume, getResultObj } from '../products/fcAnalise';
import Table from './Table';
import ProtestTableCards from '../ProtestTableCards';

const Protest = ({ dataSource }) => {

  const source = getResultObj(dataSource, 'restricoes_financeiras');
  const details = window.matchMedia('(max-width: 800px)').matches ? <ProtestTableCards lines={"protestos" in source && source.protestos.detalhes} /> : <Table lines={"protestos" in source && source.protestos.detalhes} />


  if ("protestos" in source && source.protestos.info !== 'CONSTA') {
    return null
    // return (
    //   <ContainerGray margin="0 0 8px 0">
    //     <StyledRow customstyle="height: auto">
    //       <HalfColumn>
    //         <TableTitle>TÍTULOS PROTESTADOS</TableTitle>
    //       </HalfColumn>

    //       <HalfColumn>
    //         <TableTitle>NADA CONSTA</TableTitle>
    //       </HalfColumn>
    //     </StyledRow>
    //   </ContainerGray>
    // )
  }

  const renderDataLine = ({ title, data }) => (
    <AnalythicLine key={(title + data)}>
      <LineTitle maxWidth="205px" style={{color: '#000'}}>{title}</LineTitle>
      <ColoredSpan color='color_brown'>{data}</ColoredSpan>
    </AnalythicLine>
  );

  const renderResumeLines = (keys) => {
    const dataGiven = protestsResume(dataSource, keys);
    return dataGiven.map((line) => renderDataLine(line));
  };

  return (
    <ColoredColumn>
      <TableTitle style={{color: '#000'}}>TÍTULOS PROTESTADOS</TableTitle>
      <StyledRow align="initial" margin="0 0 .5rem 0" className='font-13-print'>
        <HalfColumn>
          <ContainerGray>
            <AnalythicList>
              {renderResumeLines(['total_ocorrencias', 'valor_total_pendencia'])}
            </AnalythicList>
          </ContainerGray>
        </HalfColumn>
        <HalfColumn>
          <ContainerGray>
            <AnalythicList>
              {renderResumeLines(['data_primeira_ocorrencia', 'data_ultima_ocorrencia'])}
            </AnalythicList>
          </ContainerGray>
        </HalfColumn>
      </StyledRow>
      <Line />
      <TableTitle style={{color: '#000'}}>DETALHAMENTO (MAIS RECENTES - ATÉ 5)</TableTitle>
      {details}
    </ColoredColumn>
  )
};

export default Protest;
