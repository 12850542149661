
const showIncomeTotal = (fields) => {
    if ("renda_principal_review" in fields) {
      return [];
    }
  
    return ['total_renda_review'];
  }
  
  /**
   * This function returns an array with the fields
   * what will be removed from the form. If the function
   * returns an empty array, all fields will appear to the
   * user.
   * 
   * @param entity <String>
   * @param fields <Array>
   */
  export default (entity = 'PF', fields) => {
    let fieldsRemoved = [
      'total_renda_review'
    ];
  
    if (fields !== undefined && (typeof fields === 'object' && Object.keys(fields).length > 0)) {
      fieldsRemoved = [];
  
      fieldsRemoved = [...showIncomeTotal(fields)];
  
      return fieldsRemoved;
    }
  
    return fieldsRemoved;
  }