import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};

  & > circle { fill: #fe4d36; }
  & > path:nth-child(1) { fill: #490b06; }
  & > path:nth-child(2) { fill: #4a0c06; }
  & > path:nth-child(3) { fill: #4a0b06; }
  & > path:nth-child(4) { fill: #4c0c07; }
  & > path:nth-child(5) { fill: #4c0c06; }
`;

export default (props) => (
  <SVG {...props} viewBox="0 0 137.67 137.67">
    <circle class="cls-1" cx="68.83" cy="68.83" r="68.83"/>
    <path class="cls-2" d="M77.49,91q18.49,0,37,0c2.25,0,5.26-.59,5.4,2.78.16,3.82-3.07,3.19-5.47,3.19q-36.74,0-73.48,0c-2.33,0-5.31.41-5.43-2.79-.14-3.65,3-3.2,5.51-3.2Z" transform="translate(-9.17 -6)"/>
    <path class="cls-3" d="M37.47,64.39c0-2.16-.16-4.33,0-6.47.25-2.72,2-4.09,4.69-4.17S47,55,47.15,57.62a100.55,100.55,0,0,1,0,13.92,4.43,4.43,0,0,1-4.91,4.05,4.67,4.67,0,0,1-4.83-4.72c-.13-2.15,0-4.32,0-6.48Z" transform="translate(-4.33 -3.83)"/>
    <path class="cls-4" d="M109.87,64.55c0,2.16.12,4.32,0,6.47-.21,3-2.19,4.5-5,4.55a4.43,4.43,0,0,1-4.78-4.18,102.21,102.21,0,0,1,0-13.42c.19-2.88,2.23-4.34,5.18-4.21a4.35,4.35,0,0,1,4.56,4.33C110,60.23,109.88,62.4,109.87,64.55Z" transform="translate(-4.33 -3.83)"/><path class="cls-5" d="M39.76,44a5.24,5.24,0,0,1-1.49,0c-2-.64-5.62,0-5.22-3.17s3.24-.95,5-.8c3.28.27,5.91-.56,7.69-3.48.71-1.17,1.6-2.53,3.11-1.56s.92,2.65.11,4A10.43,10.43,0,0,1,39.76,44Z" transform="translate(-4.33 -3.83)"/><path class="cls-6" d="M107.24,44c-4.71,0-8-1.89-10.07-5.75-.65-1.2-1.11-2.62.49-3.45,1.37-.71,2.21.39,2.78,1.39,2.07,3.62,5.28,4.3,9,3.57,1.6-.31,3.59-1.38,3.95,1.15.32,2.25-2.05,2.22-3.52,2.72A12,12,0,0,1,107.24,44Z" transform="translate(-4.33 -3.83)"/>
  </SVG>
);
