import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Header from "../../components/Header";
import HeaderButton, {
  ButtonEndWrapper,
  RequestButton
} from "../../components/Header/ButtonsHeader/DefaultButton";
import { StyledSpan, StyledMainComponent } from "./requests.styled";
import { StyledRow } from "@bit/wavfichacerta.fichacerta.shared-styles.rows";
import { PageContainer } from "@bit/wavfichacerta.fichacerta.shared-styles.containers";
import RequestsTable from "./table/RequestsTable";
import RequestsMobileTable from "./table/RequestsMobileTable";
import SearchGroup from "../../components/Header/Search/SearchGroup";
import SearchGroupMobile from "../../components/Header/Search/SearchGroupMobile";
import {
  setFilter,
  setFinalDate,
  setStartDate,
  getRequests,
  changePage
} from "./table/actions";
import { getLoadingStatus } from "./table/selector";
import FilterModal from './modal/FilterModal';
import { MobileFilterButton } from "../../components/Header/Search/SearchStyles";
import AddIcon from "../../components/shared/icons/AddIcon";
import { Main, MainContainer } from './request/resume/RequestResume';

import { toastr } from 'react-redux-toastr';
import Tour from 'reactour'
import axios from 'axios';

import ReactTooltip from 'react-tooltip'
import TourTooltip from '../../components/shared/TourTooltip';

const steps = [
  {
    selector: 'h1',
    content: 'Confira aqui todas as análises da sua imobiliária.',
  },
  {
    selector: '.box-search',
    content: 'Neste campo você pode pesquisar o pretendente analisado.',
  },
  {
    selector: 'table tbody tr',
    content: 'Ao localizar a análise clique nela para ter acesso ao resumo da solicitação.',
  },
  {
    selector: '.nova-locacao',
    content: 'Neste ícone você pode iniciar uma nova análise',
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

const breadcrumb = [{ link: "", label: "Operacional" }];

class Requests extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      print: false,
      isMobile: false,
      toggle: false,
      tourOpen: false,
      tourId: null
    }
  }

  componentDidMount() {
    this.setState({
      isMobile: window.matchMedia("(max-width: 800px)").matches
    });
    this.getCheckOnboard();
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'locacoes') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'locacoes'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'locacoes',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  addRequest = () => {
    const { history } = this.props;
    const status = this.props.login.user.usuario.status
    const statusCliente = this.props.customer && this.props.customer.status

    if(statusCliente === 'SUSPENSO' || statusCliente === 'INATIVO' || status === 'SUSPENSO') {
      toastr.warning('Status', 'Sua conta está SUSPENSA')
    } else {
      history.push(
        "/intranet/operacional/solicitacao/adicionar/selecionar-produto"
      );
    }
  };

  onFilter = text => {
    const { setFilter } = this.props;
    setFilter(text);
  };

  onSetStartDate = date => {
    this.props.setStartDate(date);
  };

  onSetEndDate = date => {
    this.props.setFinalDate(date);
  };

  onFilterDate = () => {
    this.props.changePage(1);
    this.props.getRequests();
  };

  toggle = () => {
    this.setState({
      toggle: !this.state.toggle
    })
  }

  adjustToPrint = () => {
    this.setState({
      print: true
    });

    setTimeout(() => {
      window.print();
    }, 2000);

    setTimeout(() => {
      window.location.reload();
    }, 2500);
  };

  render() {
    return (
      <Main>
        <Header
          breadcrumb={breadcrumb}
          title="Locações"
          actualLink="Locações"
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <MainContainer>
          <StyledRow justify="space-between" customstyle="margin: 0 0 35px 0; height: 25px;">
            <MobileFilterButton onClick={this.toggle.bind(this)} >
                Filtrar
            </MobileFilterButton>
            { !this.state.isMobile && <SearchGroup
                endDate={this.onSetEndDate}
                startDate={this.onSetStartDate}
                setFilter={this.onFilter}
                filterDate={this.onFilterDate}
            /> }
            {this.state.isMobile && 
            <FilterModal toggleModal={this.toggle} isOpen={this.state.toggle}>
              <SearchGroupMobile
                endDate={this.onSetEndDate}
                startDate={this.onSetStartDate}
                setFilter={this.onFilter}
                filterDate={this.onFilterDate}
              />
            </FilterModal> }
              {this.state.isMobile && (
                <RequestButton>
                  <AddIcon margin="0" position="absolute" boxShadow="0px 0px 4px gray" width="45px" onClick={this.addRequest} />
                </RequestButton>
              )}
              {!this.state.isMobile && (
                <ButtonEndWrapper onClick={this.addRequest}>
                  <HeaderButton className='nova-locacao'>
                    <StyledSpan color="color_white" styles="margin: 0 5px">
                      Nova Locação
                    </StyledSpan>
                  </HeaderButton>
                  <TourTooltip
                    target="tooltip-button-nova-locacao"
                    style={{right: -10}}
                  />
                  <ReactTooltip id="tooltip-button-nova-locacao" place="left" type="dark" effect="float">
                    Neste ícone você pode iniciar uma nova análise
                  </ReactTooltip>
                </ButtonEndWrapper>
              )}
          </StyledRow>
          {this.state.isMobile && <div style={{padding: '0 2.5%'}}><RequestsMobileTable /></div>}
          {!this.state.isMobile && <RequestsTable />}
        </MainContainer>
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
        />
      </Main>
    );
  }
}

function MapStateToProps(state) {
  return {
    date: state.requestsTable.pagination.date,
    requesting: getLoadingStatus(state),
    login: state.login,
    customer: state.customerData.data.cliente
  };
}

const MapDispatchToProps = dispatch =>
  bindActionCreators(
    { setFilter, setFinalDate, setStartDate, getRequests, changePage },
    dispatch
  );

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(Requests);
