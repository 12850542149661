import React, { PureComponent, Fragment } from 'react';

import {
  IdTd,
  CustomersTd,
  NameTd,
  AdressTd,
  RequestRow,
  DateTd,
  BaseEllipsisSpan,
  CountIndicatorSpan
} from './table/requestTable.styled';
import { StyledRow } from '../shared/sharedStyles.styles';
import RequestTableToolTip from './table/RequestTableToolTip';
import moment from 'moment';
import { formatCNPJ, formatCPF, formatAddress } from '../../utils/formatter';

class RequestLine extends PureComponent {

  getApplicantNames = () => {
    const { pretendentes } = this.props;
    if (Array.isArray(pretendentes) && pretendentes.length > 0) {
      return pretendentes.map((applicant) => {
        let nome = applicant.nome || applicant.razao_social
        if(Boolean(applicant.oculto) === true) {
          nome = 'IDENTIFICAÇÃO OCULTADA'
        }
        return nome
      });
    }
    return [];
  }

  getApplicantDocs = () => {
    const { pretendentes } = this.props;
    if (Array.isArray(pretendentes) && pretendentes.length > 0) {
      return pretendentes.map((applicant) => {
        if(Boolean(applicant.oculto) === true) {
          return 'OCULTADO'
        }

        if ("cpf" in applicant) {
          return formatCPF(applicant.cpf);
        }

        if ("cnpj" in applicant) {
          return formatCNPJ(applicant.cnpj);
        }

        return undefined
      });
    }
    return [];
  }

  getAdress = () => {
    const { endereco } = this.props.locacao;

    if( Array.isArray(endereco) && endereco.length > 0){
      return `${formatAddress(endereco[0])}`
    }else{
      return "-"
    }
  }

  handleOcultado = (text) => {
    const pretendentes = this.props.pretendentes
    const allOculto = pretendentes.every((pretendente) => Boolean(pretendente.oculto) === true);
    if(allOculto) {
      if(!text) {
        return true;
      }
      if(text === '-') {
        return text;
      }
      return 'OCULTADO';
    }
    return text;
  }

  render() {
    const applicantNames = this.getApplicantNames();
    const applicantDocs = this.getApplicantDocs();
    const adress = this.handleOcultado(this.getAdress());
    console.log('linesx1', this.props)
    
    return (
      <RequestRow onClick={this.props.onGoToResume} >
        <IdTd>
          <BaseEllipsisSpan>{this.props.id}</BaseEllipsisSpan>
        </IdTd>
        <CustomersTd>
          <StyledRow justify="flex-start">
            
            {this.props.cliente_nome.length >= 30 
              ?
                <RequestTableToolTip
                  key={(new Date().getTime() + 1).toString()}
                  message={this.props.cliente_nome}
                  index={this.props.index}
                  length={this.props.length}
                  type="customerName"
                >
                  <BaseEllipsisSpan id={`customerName${this.props.index}`}>{this.props.cliente_nome}</BaseEllipsisSpan>    
                </RequestTableToolTip>
              :
                <BaseEllipsisSpan>{this.props.cliente_nome}</BaseEllipsisSpan>
            }
          </StyledRow>
        </CustomersTd>
        <DateTd>
          <BaseEllipsisSpan>{this.handleOcultado(this.props.locacao.codigo_imovel)}</BaseEllipsisSpan>
        </DateTd>
        <AdressTd>
            {adress.length > 45 
              ?
                <RequestTableToolTip
                 key={(new Date().getTime() + 2).toString()}
                  message={adress}
                  index={this.props.index}
                  length={this.props.length}
                  type="adress"
                >
                  <CountIndicatorSpan color={"color_gray"} id={`adress${this.props.index}`}>{adress.substr(0, 45)}...</CountIndicatorSpan>
                </RequestTableToolTip>
              :
                adress
            }
        </AdressTd>
        <NameTd>
          <StyledRow justify="flex-start">
            {/* <BaseEllipsisSpan>{applicantNames.length > 0? applicantNames[0] : ""}</BaseEllipsisSpan> */}
            <RequestTableToolTip
              key={(new Date().getTime() + 3).toString()}
              message={applicantNames}
              extraData={applicantDocs}
              index={this.props.index}
              length={this.props.length}
              type="applicantName"
            >
              <CountIndicatorSpan color={"color_gray"} id={`applicantName${this.props.index}`}>{applicantNames[0]}</CountIndicatorSpan>
            </RequestTableToolTip>
          </StyledRow>
        </NameTd>
        <DateTd>
          <span>{moment(this.props.data_criacao).format('DD/MM/YYYY HH:mm')}</span>
        </DateTd>
      </RequestRow>
    );
  }
}
export default RequestLine;
