import React from "react";
import { BaseColumn } from "@bit/wavfichacerta.fichacerta.shared-styles.columns";
import { StyledCard, StyledRowMobile, StyledLineMobile, StyledTitleMobile, StyledCardBody, StyledHr } from "./styled";
import { formatToBRL } from '../../../../utils/formatter';

const CheckConstraintTableCards = ({ lines }) => {
  return lines.map(line => {
    return (
      <StyledCard>
        <StyledCardBody>
          <StyledRowMobile>
            <BaseColumn xs="3" sm="3">
              <StyledTitleMobile>Data</StyledTitleMobile>
              <StyledLineMobile>{line.data}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="2" sm="2">
              <StyledTitleMobile>Qtd.</StyledTitleMobile>
              <StyledLineMobile>{line.quantidade}</StyledLineMobile>
            </BaseColumn>
            {/* <BaseColumn xs="5" sm="5">
              <StyledTitleMobile>Cidade/UF</StyledTitleMobile>
              <StyledLineMobile>{line.cidade}</StyledLineMobile>
            </BaseColumn> */}
            {/* <BaseColumn xs="2" sm="2">
              <StyledTitleMobile>Número</StyledTitleMobile>
              <StyledLineMobile>{line.numero_cheque}</StyledLineMobile>
            </BaseColumn> */}
          </StyledRowMobile>
          <StyledHr/>
          <StyledRowMobile>
            {/* <BaseColumn xs="3" sm="3">
              <StyledTitleMobile>Valor</StyledTitleMobile>
              <StyledLineMobile>R$ {formatToBRL(line.valor)}</StyledLineMobile>
            </BaseColumn> */}
            <BaseColumn xs="5" sm="5">
              <StyledTitleMobile>Banco</StyledTitleMobile>
              <StyledLineMobile>{line.banco}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="2" sm="2">
              <StyledTitleMobile>Ag</StyledTitleMobile>
              <StyledLineMobile>{line.agencia}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="2" sm="2">
              <StyledTitleMobile>Alínea</StyledTitleMobile>
              <StyledLineMobile>{line.alinea}</StyledLineMobile>
            </BaseColumn>
          </StyledRowMobile>
        </StyledCardBody>
      </StyledCard>
    );
  });
};

export default CheckConstraintTableCards;
