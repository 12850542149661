import { toastr } from 'react-redux-toastr';
import * as types from './types';
import { actionCreator, generalActionTypes,extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { insertEmployer, getEmployer, updateEmployer } from '../../../../services/customer/customer';
import { replace } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../../../../pages/auth/login/actions/types.js';


export const resetForm = () => actionCreator(types.USER_FORM_RESET);

export const registerEmployer = (data) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.USER_FORM_SAVE_REQUESTING));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await insertEmployer(token.access_token, data);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Adicionar usuário', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.USER_FORM_SAVE_FAILED));
        }
      } else {
        toastr.success('Adicionar usuário', payload.data.message);
        dispatch(actionCreator(types.USER_FORM_SAVE_SUCCESS))
      }
    } catch (error) {
      toastr.error('Adicionar usuário', "Ocorreu um erro interno ao adicionar o usuário. Se persistir o erro, contate a FC ANALISE.");
      dispatch(actionCreator(types.USER_FORM_SAVE_FAILED));
      dispatch(actionCreator(generalActionTypes.NON_HANDLED_ERROR, "Ocorreu um erro interno ao adicionar o usuário. Se persistir o erro, contate a FC ANALISE."))
    }
  }
}

export const getUserInfo = (userId) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.USER_FORM_EMPLOYER_DATA_REQUESTING));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await getEmployer(token.access_token, userId);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Dados do usuário', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.USER_FORM_EMPLOYER_DATA_FAILED));
        }
      } else {
        dispatch(actionCreator(types.USER_FORM_EMPLOYER_DATA_SUCCESS, payload.data.data));
      }
    } catch (error) {
      dispatch(actionCreator(types.USER_FORM_EMPLOYER_DATA_FAILED));
      dispatch(actionCreator(generalActionTypes.NON_HANDLED_ERROR, "Ocorreu um erro interno ao adicionar o usuário. Se persistir o erro, contate a FC ANALISE."))
    }
  }
}

export const saveUser = (userId, data) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.USER_FORM_SAVE_REQUESTING));
    try {
      const { login: { user: { token } } } = getState();
      const payload = await updateEmployer(token.access_token, userId, data);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          toastr.warning('Editar usuário', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.USER_FORM_SAVE_FAILED));
        }
      } else {
        toastr.success('Editar usuário', payload.data.message);
        dispatch(actionCreator(types.USER_FORM_SAVE_SUCCESS))
      }
    } catch (error) {
      toastr.error('Editar usuário', "Ocorreu um erro interno ao editar o usuário. Se persistir o erro, contate a FC ANALISE.");
      dispatch(actionCreator(types.USER_FORM_SAVE_FAILED));
      dispatch(actionCreator(generalActionTypes.NON_HANDLED_ERROR, "Ocorreu um erro interno ao editar o usuário. Se persistir o erro, contate a FC ANALISE."))
    }
  }
}
