import React from 'react';
import Styled from 'styled-components';

const StyledSvg = Styled.svg`
  width: ${props => props.width ? props.width : '10px'};
  fill: ${props => props.color ? props.color : props.theme.color_gray};
  display: ${props => props.display ? props.display : "inline-block"};
  margin: ${props => props.margin ? props.margin : "auto"};
`;

const StyledCircle = Styled.circle`
    fill: none;
    stroke: #7f7f7f;
    stroke-miterlimit: 10;
    stroke-width: 6px;
`;

const StyledText = Styled.text`
    font-size: 180px;
    fill: #7f7f7f;
    font-family: TimesNewRomanPSMT, Times New Roman;
`;


export default (props) => (
    <StyledSvg {...props} viewBox="0 0 185.84 284.42">
        <StyledCircle class="cls-1" cx="92.92" cy="151.21" r="89.92"/>
        <StyledText class="cls-2" transform="translate(36.44 207.76)">
            c
        </StyledText>
    </StyledSvg>
)