import styled from 'styled-components';

export const StepBoxContainer = styled.div`
  min-width: 110px;
  height: 1.2rem;
  background-color: ${props => props.checked? '#ffefcb' : props.theme.color_purple};
  color: ${props => props.checked? '#7f7f7f' : props.theme.color_white};
  text-align: center;
  border-radius: 2rem;
  text-transform: uppercase;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 11px;
`;