import { formatPhone, formatCPF, formatCNPJ } from '@bit/wavfichacerta.fichacerta.utils.formatters';

const getPhones = (phonesObj) => {
  const principal = "principal" in phonesObj? phonesObj.principal : undefined;
  const adicional = "adicional" in phonesObj? phonesObj.adicional : undefined;
  return {
    telefone: (typeof principal === "object" && "numero" in principal)? formatPhone(principal.numero) : "",
    telefone_adicional: (typeof adicional === "object" && "numero" in adicional)? formatPhone(adicional.numero) : ""
  };
}

const getAditionalContacts = (data) => {
  const operational = "operacional" in data.contatos? data.contatos.operacional : {};
  const operacionalPhones = "telefones" in operational? operational.telefones : {};
  const financial = "financeiro" in data.contatos? data.contatos.financeiro : {};
  const financialPhones = "telefones" in financial? financial.telefones : {};
  const direction = "diretoria" in data.contatos? data.contatos.diretoria : {};
  const directionPhones = "telefones" in direction? direction.telefones : {};
  
  return {
    operacional_nome: operational.nome || null,
    operacional_email: operational.email || null,
    operacional_funcao: operational.funcao || null,
    operacional_telefone: "principal" in operacionalPhones? formatPhone(operacionalPhones.principal.numero) : null,
    financeiro_nome: financial.nome || null,
    financeiro_email: financial.email || null,
    financeiro_funcao: financial.funcao || null,
    financeiro_telefone: "principal" in financialPhones? formatPhone(financialPhones.principal.numero) : null,
    diretoria_nome: direction.nome || null,
    diretoria_email: direction.email || null,
    diretoria_funcao: direction.funcao || null,
    diretoria_telefone: "principal" in directionPhones? formatPhone(directionPhones.principal.numero) : null,
  }
}

const getInitialDataPF = (data) => ({
  locacao_residencial_inquilinos_parecer_nivel_1_aprovado: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved,
  locacao_residencial_inquilinos_parecer_nivel_1_aprovado_2: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2,
  locacao_residencial_inquilinos_parecer_nivel_1_incompativel: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning,
  locacao_residencial_inquilinos_parecer_nivel_2_aprovado: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved,
  locacao_residencial_inquilinos_parecer_nivel_2_aprovado_2: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2,
  locacao_residencial_inquilinos_parecer_nivel_2_incompativel: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning,
  locacao_residencial_inquilinos_parecer_nivel_3_aprovado: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_3.approved,
  locacao_residencial_inquilinos_parecer_nivel_3_incompativel: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning,
  locacao_residencial_inquilinos_parecer_nivel_4_aprovado: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_4.approved,
  locacao_residencial_inquilinos_parecer_nivel_4_incompativel: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning,
  locacao_residencial_inquilinos_parecer_nivel_5_aprovado: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_5.approved,
  locacao_residencial_inquilinos_parecer_nivel_5_incompativel: data.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning,
  locacao_residencial_fiadores_parecer_nivel_1_aprovado: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved,
  locacao_residencial_fiadores_parecer_nivel_1_aprovado_2: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2,
  locacao_residencial_fiadores_parecer_nivel_1_incompativel: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning,
  locacao_residencial_fiadores_parecer_nivel_2_aprovado: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved,
  locacao_residencial_fiadores_parecer_nivel_2_aprovado_2: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2,
  locacao_residencial_fiadores_parecer_nivel_2_incompativel: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning,
  locacao_residencial_fiadores_parecer_nivel_3_aprovado: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_3.approved,
  locacao_residencial_fiadores_parecer_nivel_3_incompativel: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning,
  locacao_residencial_fiadores_parecer_nivel_4_aprovado: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_4.approved,
  locacao_residencial_fiadores_parecer_nivel_4_incompativel: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning,
  locacao_residencial_fiadores_parecer_nivel_5_aprovado: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_5.approved,
  locacao_residencial_fiadores_parecer_nivel_5_incompativel: data.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning,
  locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved,
  locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2,
  locacao_residencial_fiadores_estudante_parecer_nivel_1_incompativel: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning,
  locacao_residencial_fiadores_estudante_parecer_nivel_2_aprovado: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved,
  locacao_residencial_fiadores_estudante_parecer_nivel_2_aprovado_2: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2,
  locacao_residencial_fiadores_estudante_parecer_nivel_2_incompativel: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning,
  locacao_residencial_fiadores_estudante_parecer_nivel_3_aprovado: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_3.approved,
  locacao_residencial_fiadores_estudante_parecer_nivel_3_incompativel: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning,
  locacao_residencial_fiadores_estudante_parecer_nivel_4_aprovado: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_4.approved,
  locacao_residencial_fiadores_estudante_parecer_nivel_4_incompativel: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning,
  locacao_residencial_fiadores_estudante_parecer_nivel_5_aprovado: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_5.approved,
  locacao_residencial_fiadores_estudante_parecer_nivel_5_incompativel: data.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning,

  locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved,
  locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado_2: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2,
  locacao_nao_residencial_inquilinos_parecer_nivel_1_incompativel: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning,
  locacao_nao_residencial_inquilinos_parecer_nivel_2_aprovado: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved,
  locacao_nao_residencial_inquilinos_parecer_nivel_2_aprovado_2: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2,
  locacao_nao_residencial_inquilinos_parecer_nivel_2_incompativel: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning,
  locacao_nao_residencial_inquilinos_parecer_nivel_3_aprovado: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.approved,
  locacao_nao_residencial_inquilinos_parecer_nivel_3_incompativel: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning,
  locacao_nao_residencial_inquilinos_parecer_nivel_4_aprovado: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.approved,
  locacao_nao_residencial_inquilinos_parecer_nivel_4_incompativel: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning,
  locacao_nao_residencial_inquilinos_parecer_nivel_5_aprovado: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.approved,
  locacao_nao_residencial_inquilinos_parecer_nivel_5_incompativel: data.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning,
  locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved,
  locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado_2: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2,
  locacao_nao_residencial_fiadores_parecer_nivel_1_incompativel: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning,
  locacao_nao_residencial_fiadores_parecer_nivel_2_aprovado: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved,
  locacao_nao_residencial_fiadores_parecer_nivel_2_aprovado_2: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2,
  locacao_nao_residencial_fiadores_parecer_nivel_2_incompativel: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning,
  locacao_nao_residencial_fiadores_parecer_nivel_3_aprovado: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.approved,
  locacao_nao_residencial_fiadores_parecer_nivel_3_incompativel: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning,
  locacao_nao_residencial_fiadores_parecer_nivel_4_aprovado: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.approved,
  locacao_nao_residencial_fiadores_parecer_nivel_4_incompativel: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning,
  locacao_nao_residencial_fiadores_parecer_nivel_5_aprovado: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.approved,
  locacao_nao_residencial_fiadores_parecer_nivel_5_incompativel: data.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_incompativel: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_2_aprovado: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_2_aprovado_2: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_2_incompativel: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_3_aprovado: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.approved,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_3_incompativel: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_4_aprovado: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.approved,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_4_incompativel: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_5_aprovado: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.approved,
  locacao_nao_residencial_fiadores_estudante_parecer_nivel_5_incompativel: data.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning,

  picked: 'Twox',
  email: 'fulano@com.brx',
  usar_recomendacao_customizada: 0
});

const getInitialDataPJ = (data) => ({
  ...data.endereco,
  ...getPhones(data.telefones),
  ...getAditionalContacts(data),
  nome: data.nome,
  cnpj: formatCNPJ(data.cnpj),
  creci: data.creci,
  website: data.website
});

export const getInitialData = (entity, data) => {
  if (entity === "PF") {
    return getInitialDataPF(data);
  }
  return getInitialDataPJ(data);
}

const pfEntityExtractor = (changedValues, originalValues) => {
  console.log('changedValues1', changedValues)
  console.log('originalValues1', originalValues)
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_3_aprovado
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_3_incompativel
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_4_aprovado
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_4_incompativel
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.tenants.residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_3_aprovado
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_3_incompativel
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_4_aprovado
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_4_incompativel
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.guarantors.residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_3_aprovado
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_3_incompativel
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_4_aprovado
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_4_incompativel
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_5_aprovado

  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_3_aprovado
  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_3_incompativel
  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_4_aprovado
  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_4_incompativel
  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.tenants.residential.credit.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_3_aprovado
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_3_incompativel
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_4_aprovado
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_4_incompativel
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.guarantors.residential.credit.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_3_aprovado
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_3_incompativel
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_4_aprovado
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_4_incompativel
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.residential.credit.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_5_aprovado

  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_3_aprovado
  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_3_incompativel
  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_4_aprovado
  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_4_incompativel
  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.tenants.residential.extract.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_3_aprovado
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_3_incompativel
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_4_aprovado
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_4_incompativel
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.guarantors.residential.extract.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_3_aprovado
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_3_incompativel
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_4_aprovado
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_4_incompativel
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.residential.extract.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_residencial_fiadores_estudante_parecer_nivel_5_aprovado


  /////

  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_3_aprovado
  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_3_incompativel
  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_4_aprovado
  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_4_incompativel
  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.tenants.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_3_aprovado
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_3_incompativel
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_4_aprovado
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_4_incompativel
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.guarantors.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_3_aprovado
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_3_incompativel
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_4_aprovado
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_4_incompativel
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.non_residential.income.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_5_aprovado

  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_3_aprovado
  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_3_incompativel
  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_4_aprovado
  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_4_incompativel
  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.tenants.non_residential.credit.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_3_aprovado
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_3_incompativel
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_4_aprovado
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_4_incompativel
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.guarantors.non_residential.credit.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_3_aprovado
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_3_incompativel
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_4_aprovado
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_4_incompativel
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.non_residential.credit.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_5_aprovado

  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado
  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_aprovado_2
  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_1_incompativel
  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_3_aprovado
  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_3_incompativel
  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_4_aprovado
  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_4_incompativel
  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.tenants.non_residential.extract.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_nao_residencial_inquilinos_parecer_nivel_5_aprovado
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_aprovado_2
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_1_incompativel
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_3_aprovado
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_3_incompativel
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_4_aprovado
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_4_incompativel
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.guarantors.non_residential.extract.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_1.approved_2 = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_1.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_2.approved_2 = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_aprovado_2
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_2.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_1_incompativel
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_3.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_3_aprovado
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_3.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_3_incompativel
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_4.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_4_aprovado
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_4.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_4_incompativel
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_5.approved = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_5_aprovado
  originalValues.studentsGuarantor.non_residential.extract.rentalRecommendations.PARECER_NIVEL_5.incompatibilityWarning = changedValues.locacao_nao_residencial_fiadores_estudante_parecer_nivel_5_aprovado

  return originalValues
};

export const recommendationDataExtractor = (entity = 'PF', data, originalData) => {
  if (entity === "PF") {
    return pfEntityExtractor(data, originalData);
  }
  return getInitialDataPJ(data);
}