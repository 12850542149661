import styled from 'styled-components';
import { Td, Th, ThCentered } from './styled';
import filter from '../../../img/png/ico-filter.png';

export const BaseEllipsisSpan = styled.span`
  display: -webkit-box;
  max-width: 220px;
  line-height: 1.5;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  padding: 0;
  margin: 0;
`;

export const IdTd = styled(Td)`
  padding-left: 5px !important;
  width: 4vw !important;
`;

export const NameTd = styled(Td)`
  width: 14.5vw !important;
  padding-left: 5px !important;
  text-transform: uppercase;
`;

export const CustomersTd = styled(Td)`
  width: 12vw !important;
  padding-left: 5px !important;
  text-transform: uppercase;
`;

export const ProfileTd = styled(Td)`
  width: 14.5vw !important;
  padding-left: 5px !important;
`;

export const AdressTd = styled(Td)`
  padding-left: 5px !important;
  width: 14.5vw !important;
  white-space: nowrap;
  text-align: center;
`;

export const DateTd = styled(Td)`
  padding-left: 5px !important;
  width: 6vw !important;
`;

export const IconTd = styled(Td)`
  width: 3.3vw !important;
`;

export const CountIndicatorSpan = styled.span`
  margin-left: 5px;
  color: ${props => props.color !== undefined ? props.theme[props.color] : props.theme.color_red};
`;


/**
 * Tags th
 */

export const IdTh = styled(Th)`
  font-weight: 500;
  padding-left: 5px !important;
  width: 4vw !important;
`;

export const NameTh = styled(Th)`
  font-weight: 500;
  width: 14.5vw !important;
  padding-left: 5px !important;
`;

export const CustomersTh = styled(Th)`
  font-weight: 500;
  width: 12vw !important;
  padding-left: 5px !important;
`;

export const ProfileTh = styled(Th)`
  font-weight: 500;
  width: 14.5vw !important;
  padding-left: 5px !important;
`;

export const AdressTh = styled(Th)`
  font-weight: 500;
  padding-left: 5px !important;
  width: 14.5vw !important;
`;

export const DateTh = styled(Th)`
  font-weight: 500;
  padding-left: 5px !important;
  width: 6vw !important;
`;

export const IconTh = styled(Th)`
  font-weight: 500;
  width: 3.3vw !important;
`;

export const FilterHeaderSelect = styled.select`
  width: 98%;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px ${props => props.theme.color_gray};
  color: ${props => props.theme.color_gray};
  font-size: 14px;
  &:focus {
    outline: none;
  }
`;

export const FilterIcon = styled.div`
  background-image: url(${filter});
  background-size: contain;
  background-color: transparent;
  width: 13px;
  height: 13px;
  mask: none;
`;

export const FilterLabel = styled.label`
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
`;

/**
 * Tag tr
 */

export const RequestRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme["table_line-hover"]}!important
  }
`