import React from "react";
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.width? props.width : '1.5rem'};
`;
const StyledPath = styled.path`
  fill: ${props => props.theme.color_green};
`;

const LikeSVG  = (props) => (
  <StyledSVG {...props} viewBox="0 0 490.2 490.2"> 
    <StyledPath d="M60.3,293.5V465c0,10.8,8.8,19.5,19.5,19.5h58.3c6.7,0,12.5-3.3,16.1-8.4    c9.3,8.7,21.7,14.1,35.4,14.1h156.2c35,0,57.3-18.4,61.3-50.4l21-133.6c0.1-0.5,0.1-1,0.1-1.5c0-28.5-23.2-51.7-51.7-51.7h-78.1    v-56.1c0-24.3-7.1-41.8-21.2-52.2c-22.3-16.3-52.8-7-54.1-6.6c-4.1,1.3-6.9,5.1-6.9,9.4v63.8c0,49.7-57.8,66.7-60.2,67.4    c-1.2,0.3-2.4,0.9-3.3,1.7c-3.6-4-8.8-6.5-14.5-6.5H79.8C69,273.9,60.3,282.7,60.3,293.5z M157.7,298.1c1.2,0.1,2.4,0,3.6-0.3    c3.1-0.9,74.7-21.6,74.7-86.4v-55.9c7.7-1.2,20.4-1.6,29.5,5.2c8.7,6.4,13.1,18.6,13.1,36.1v66c0,5.5,4.4,9.9,9.9,9.9h88    c17.4,0,31.5,13.9,31.9,31.2l-20.9,132.9c0,0.1,0,0.2,0,0.3c-2.7,22-16.7,33.1-41.7,33.1H189.6c-17.6,0-31.9-14.3-31.9-31.9    L157.7,298.1L157.7,298.1z M80.1,293.7h57.8v171H80.1V293.7z"
      data-original="#2C2F33" data-old_color="#6ac259" />
  </StyledSVG>
);

export default LikeSVG;
