import styled from 'styled-components';

export const ActionButton = styled.button`
    border-radius: .3rem;
    width: 100%;
    border: .1rem solid ${props => props.theme['color_border-light-gray']};
    background-color: ${props => props.theme['color_light-gray']};
    color: ${props => props.theme['color_dark-gray']};

    &:active { 
      background-color: ${props => props.theme.color_gray};
      color: ${props => props.theme.color_white};
    }
    &:focus { outline: 0; }
`;
