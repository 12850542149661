import React, { PureComponent } from 'react';

import ModalContainer from '../../../components/shared/modal/ModalContainer';
import { PrimaryButton } from '../../../components/shared/sharedStyles.styles';
import { Container, TitleModal, TextModal, ContainerButton } from './usersCreateSuccess.styled';

class UsersCreateSuccessModal extends PureComponent {

  render() {
    const { isOpen, closeModal, onGoBack } = this.props;
    return (
      <ModalContainer
        isOpen={isOpen}
        size="md"
        onClose={closeModal}
      >
        <Container>
          <TitleModal>USUÁRIO CRIADO COM SUCESSO!</TitleModal>
          <TextModal>Em até 24h um colaborador da FC ANALISE entrará em contato para definir o limite disponível deste novo usuário.</TextModal>
          <ContainerButton>
            <PrimaryButton onClick={closeModal}>OK</PrimaryButton>
          </ContainerButton>
        </Container>
      </ModalContainer>
    );
  }
}

export default UsersCreateSuccessModal
