import * as types from '../actions/types'
import {
  onRegisterUser,
  onRegisterUserFailed,
  onRegisterUserSuccess,
  onGetUserData,
  onGetUserDataFailed,
  onGetUserDataSuccess
} from './reducers'

const initialState = {
  requesting: false,
  error: undefined,
  userData: {
    requesting: false,
    error: undefined,
    data: {}
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.USER_FORM_SAVE_REQUESTING:
      return onRegisterUser(state);
    case types.USER_FORM_SAVE_SUCCESS:
      return onRegisterUserSuccess(state);
    case types.USER_FORM_SAVE_FAILED:
      return onRegisterUserFailed(state);
    case types.USER_FORM_EMPLOYER_DATA_REQUESTING:
      return onGetUserData(state);
    case types.USER_FORM_EMPLOYER_DATA_SUCCESS:
      return onGetUserDataSuccess(state, payload)
    case types.USER_FORM_EMPLOYER_DATA_FAILED:
      return onGetUserDataFailed(state);
    case types.USER_FORM_RESET:
      return initialState;
    default:
      return state;
  }
}
