import Styled from 'styled-components';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';

export const BlueArrow = Styled.div`
  display: inline-block;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: ${props =>  `20px solid ${props.theme.color_purple}`};
  margin-left: 5px;

  ${({filtered}) => filtered && `
    border-left-color: transparent;
  `}

  ${({type, filtered, theme}) => {
    if(type == 'toSecondField' && filtered) {
      return `
        margin-top: 3vh;
        border-left-color: ${theme.color_purple};
      `
    } else if(type == 'toSecondField') {
      return `
        margin-top: 3vh;
        border-left-color: transparent;
      `
    }
  }}
`;

export const MonthContainer = Styled.div`
  width: 90%;
  display: inline-flex;
  background-color: ${props => props.color ? props.color : props.theme.color_purple};
  height: 40px;
  vertical-align: top;

  & span {
    color: ${props => props.theme.color_white};
    font-family: "Open Sans";
    line-height: 2.5rem;
    margin-left: 10px;
    font-size: 13px;
  };

  ${({filtered, ...props}) => filtered && `
    background-color: ${props.theme.color_white};
    border: 1px solid ${props.theme.color_purple};

    span {
      color: ${props.theme.color_purple};
    }
  `}
`;

export const FilterContainer = Styled.div`
  width: 90%;
  margin-top: 15;
`;

export const DateContainer = Styled.div`
  border: 1px solid black;
  width: 90%;
  margin-top: 3vh;
  height: 40px;
  display: inline-flex;
  vertical-align: top;

  & label {
    display: none;
  }

  ${({filtered, ...props}) => filtered && `
    background-color: ${props.theme.color_purple};
    border: 1px solid ${props.theme.color_purple};

    span,
    select,
    input {
      color: ${props.theme.color_white};
    }

    label + div {
      border-bottom: 1px solid ${props.theme.color_white};
    }
  `}
`;

export const TableWrapper = Styled(BaseColumn)`
  & .odd {
    background-color: rgba(0, 0, 0, 0.05);

    tr, table {
      background-color: transparent !important;
      
      th, td {
        font-size: 11px !important;
        padding: 0 8px .3rem 8px;
      }

      &:hover {
        background-color: transparent !important;
      }
    }
  }

  & .even {
    background-color: transparent;

    tr, table {
      background-color: transparent !important;
      
      th, td {
        font-size: 11px !important;
        padding: 0 8px .3rem 8px;
      }

      &:hover {
        background-color: transparent !important;
      }
    }
  }
`;

export const BasicContainer = Styled.div`
  width: 90%;
`;

export const ResumeContainer = Styled.div`
  width: 100%;
  border: 1px solid #dee2e6;

  & > div {
    margin: 0 .5rem;
    padding: 0 0 0 .5rem;
    width: 92%;
  }
  
  & > div:last-child {
    margin: 0 .5rem .5rem .5rem;
  }

  & > div:first-child {
    margin: .5rem .5rem 0 .5rem;
  }

  & > div:only-child {
    margin: .5rem .5rem .5rem .5rem;
  }

  & > div:nth-child(odd) {
    background-color: #152b4e26;
  }
`;

export const ResumeSpan = Styled.span`
  font-size: .8rem;
  color: ${props => props.theme.color_gray};
  font-weight: 500;
`;

export const Line = Styled.div`
  height: 47px;
  border: 0.5px solid #dee2e6;
  width: 0.1px;
  position: relative;
  left: 50%;
`;

export const ValueContainer = Styled.div`
  min-height: 47px;
  border: 0.5px solid #dee2e6;
  padding: .5rem 0;
  margin-bottom: 5px;
`;

export const ValueTitle = Styled.p`
  font-size: 1.2rem;
  margin: 0 0 0 1rem;
  font-weight: 500;
  color: ${props => props.theme.color_purple};
`;

export const TotalValue = Styled.p`
  font-size: 1.4rem;
  text-align: right;
  margin: 0 1rem 0 0;
  font-weight: 500;
  color: ${props => props.theme.color_gray};
`;

export const ResumeTitle = Styled.p`
  font-size: 17px;
  color: ${props => props.theme.color_purple};
  margin-bottom: 3px;
`;

export const Caption = Styled.p`
  font-size: 10px;
  text-align: right;
  margin: .25rem 0;
  color: ${props => props.theme.color_gray};
`;