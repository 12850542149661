import styled from 'styled-components';
import { Tab, TabList } from 'react-tabs';
import { TabListContainer, MainContainer, DefaultTab, PrimaryButton } from '../../../../components/shared/sharedStyles.styles';

export const Container = styled(MainContainer)`
  max-height: 68vh;
  padding-top: 0 !important;
  overflow-y: auto;
  overflow-x: hidden;

  @media(max-width: 800px){
    max-height: 100%;
    padding: 0.5rem;
    width: 100%;
    margin: 0;
  }
`;

export const ApplicantTab = styled.text`
  height: 1.5rem;
  padding: 0 0 !important;
  & > button {
    height: 100%;
    font-size: 11px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    outline: none;
    
  }
`;

export const SeeMoreTab = styled.button`
  color: black;
  background: none;
  color: none;
  border: none;
  float: initial;
  font-size: 20px;
  cursor: pointer;
  width: 28px;

  @media(max-width: 800px){
    display: none;
  }
  `

export const Title = styled.h5`
  font-size: 16px;
  text-transform: uppercase;
  color: ${props => props.theme.color_gray};
  font-weight: 400;
  padding: 0 1rem;
`;

export const LineTitle = styled.hr`
  width: 100%;
  height: 1px;
  color: ${props => props.theme.color_gray};
`;

export const TabListSmallContainer = styled(TabListContainer)`
  top: 7.85rem;
`;

export const TabsContainer = styled.div`
  li.tab{
    display: inline-block;
    padding: .2rem .5rem .2rem .5rem;
    margin-bottom: 3px;
  }

  li{
    &:hover {
      border: 1px solid;
      border-color: ${props => {
        return(`
          ${props.theme['color_silver-chalice']}
          ${props.theme['color_silver-chalice']}
          ${props.theme.color_white}
          ${props.theme['color_silver-chalice']};
        `)
      }}
    }
  }

  li.active{
    background-color: ${props => props.theme.color_white};
    border: 1px solid ${props => props.theme['color_silver-chalice']};
    border-bottom: 0;
  }

  button{
    text-transform: uppercase;
    color: ${props => props.theme.color_gray};
    font-size: 1rem;
  }

  @media(max-width: 800px){
    display:none;
  }
`;

export const MobileTabsContainer = styled.div`
  position: fixed;
  top: 42px;
  right: 0;
  width: 100%;
  background-color: #3B4465;
  z-index: 1;

  li .tab{
    display: inline-block;
    padding: .2rem .5rem .2rem .5rem;
    margin-bottom: -1px;
    border-bottom: 0 !important;
  }

  li{
    border-bottom: 0px solid !important;

    &:hover {
      text-decoration: underline;
      border-bottom: 2px solid white !important;
    }
  }

  li.active{
    background-color: ${props => props.theme.color_white};
    border: 1px solid ${props => props.theme['color_silver-chalice']};
    border-bottom: 0;
  }

  button{
    color: ${props => props.theme.color_white};
    font-size: 1rem;
  }

  @media(min-width: 801px){
    display:none;
  }
`;

export const TabSmall = styled(Tab)`
  & > button {
    text-transform: unset;
    font-size: .9rem;
  }
`;

export const BottomButton = styled(PrimaryButton)`
  height: 40px;

  @media(max-width: 800px){
    padding: 0.375rem 1.65rem;
    transform: scale(1);
    font-weight: 300;
    font-size: .88rem;
  }
`;

export const TabListContainerRequest = styled(TabList)`
  margin-bottom: 0;
  padding-left: 0;
  width: calc(100% - 28px - 28px);

  @media(max-width: 800px){
    margin-top: 0;
  }
`;