import {
  GET_CUSTOMER_PRODUCTS,
  GET_CUSTOMER_PRODUCTS_FAILED,
  GET_CUSTOMER_PRODUCTS_SUCCESS
} from './types';

import { getClientInfos } from '../../../../services/customer/customer';
import { actionCreator } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { replace } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../../../../pages/auth/login/actions/types.js';


export const getClientProducts = () => {
  return async (dispatch, getState) => {
    const { login: { user: { token } } } = getState();
    try {
      dispatch(actionCreator(GET_CUSTOMER_PRODUCTS));
      const payload = await getClientInfos(token.access_token);
      if (payload.status !== 200) {
        if (payload.status === 401) {
          dispatch(actionCreator(LOGOUT_SUCCESS));
          dispatch(replace('/auth'));
        } else {
          dispatch(actionCreator(GET_CUSTOMER_PRODUCTS_FAILED, payload.data.message));
        }

      } else {
        let produtos = payload.data.data.cliente.produtos
        if(payload.data.data.cliente.a_vista) {
          produtos = payload.data.data.cliente.produtos_habilitados
        }
        dispatch(actionCreator(GET_CUSTOMER_PRODUCTS_SUCCESS, produtos));
      }
    } catch (e) {
      dispatch(actionCreator(GET_CUSTOMER_PRODUCTS_FAILED, ''));
    }
  }
};
