import React from 'react';
import { StyledMaskedInput, MaskedContainer } from './Inputs.styles';
import createInputWithValidation from './createInputWithValidation';


const CustomMaskedInput = ({ moneyMask, disabled, name, value, onChange, onBlur, placeholder, isRequired, mask }) => (
  <MaskedContainer moneyMask={moneyMask}>
    <StyledMaskedInput
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      type="text"
      onBlur={onBlur}
      placeholder={placeholder}
      mask={mask}
      required={isRequired}
      disabled={disabled}
      moneyMask={moneyMask}
    />
  </MaskedContainer>
)

CustomMaskedInput.defaultProps = {
  isRequired: false,
  moneyMask: false
}

export default createInputWithValidation('maskedText')(CustomMaskedInput);