import React, { PureComponent } from 'react';
import { PaginationFooterContainer } from './footer.styles';
import MaxPerPage from './MaxPerPage';
import Pagination from './Pagination';

export default class PaginationFooter extends PureComponent {
  selectResultsPerPage = (e) => {
    e.preventDefault();
    const { value } = e.target;
    this.props.changeMaxResults(value);
  }

  render() {
    const { allowMaxPerPage } = this.props;
    const { currentPage, lastPage, resultsPerPage, filter } = this.props.pagination;
    return (
      <PaginationFooterContainer style={this.props.customstyleContainer}>
        {allowMaxPerPage ? <MaxPerPage data={this.props} onSelect={this.selectResultsPerPage} max={resultsPerPage} /> : null}
        
        <Pagination filter={filter} resultsPerPage={resultsPerPage} totalPages={lastPage} changePage={this.props.changePage} currentPage={currentPage}/>
        
      </PaginationFooterContainer>
    );
  }
}
