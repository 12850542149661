import {
  CHANGE_STAGE,
  RESET_STATE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILED
} from './types';
import { actionCreator, generalActionTypes } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { toastr } from 'react-redux-toastr';
import {
  login,
  refreshToken,
  logout,
  getUser
} from '../../../../services/login';
import { loginErrorHandler } from '../../../../utils/loginHelper';
import { replace } from 'connected-react-router'
import { bugsnagClient } from './../../../../routes'
import { detect } from 'detect-browser'
import moment from 'moment'
const browser = detect();

const { NON_HANDLED_ERROR } = generalActionTypes;

export const changeStage = (payload) => actionCreator(CHANGE_STAGE, payload);
export const resetLogin = () => actionCreator(RESET_STATE);

const handlerBrowserInfo = (username = '', statusCode = '', responseData = '') => {
  const date = moment().format('DD/MM/YYYY (H:mm:ss)') + '\n'
  const ndate = new Date() + '\n'
  const zdate = new Date().getTimezoneOffset() + '\n'
  const user = username ? `\n User: ${username}` : ''
  const code = statusCode ? `\n Status code: {${statusCode}}` : ''
  const data = responseData ? `\n Data: ${JSON.stringify(responseData)}` : ''

  if (browser) {
    const { name, version, os, type } = browser
    return `${date} ${ndate} ${zdate} Browser info: {${type}} {${name}} {${version}} {${os}} ${user} ${code} ${data}`
  }

  return `${date} ${ndate} ${zdate} Browser info: Não encontrado ${code} ${data}`
}

const handleLoginErrorBrowserInfo = (data) => {
  let error = true
  if(data.message.username) {
    switch(data.message.username[0]) {
      case 'Todos os campos são obrigatórios':
        return false
      case 'E-mail inválido':
        return false
    }
  }

  if(data.message.password) {
    switch(data.message.password[0]) {
      case 'Todos os campos são obrigatórios':
        return false
    }
  }

  if(data.message) {
    switch(data.message) {
      case 'E-mail e/ou senha incorreto(s)':
        return false
    }
  }
  return error
}

export const loginClient = (username, password) => {
  return async (dispatch) => {
    dispatch(actionCreator(LOGIN_REQUEST));
    try {
      const payload = await login(username, password);

      if (payload.status !== 200) {
        dispatch(actionCreator(LOGIN_FAILED, loginErrorHandler(payload.data)));
        if(handleLoginErrorBrowserInfo(payload.data)) {
          bugsnagClient.notify(handlerBrowserInfo(username, payload.status, payload.data));
        }
      } else {
        dispatch(actionCreator(LOGIN_SUCCESS, payload.data));
      }

    } catch (e) {
      dispatch(actionCreator(LOGIN_FAILED, loginErrorHandler(e)));
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu. Tente mais tarde'));
      bugsnagClient.notify(handlerBrowserInfo(username));
      bugsnagClient.notify(e);
    }
  };
};

export const refreshAuthToken = (callBack) => {
  return async (dispatch, getState) => {
    try {
      const { user: { token: { refresh_token } } } = getState().login;
      const payload = await refreshToken(refresh_token);

      if (payload.status === 200) {
        dispatch(actionCreator(REFRESH_TOKEN_SUCCESS, payload.data));
        dispatch(callBack());
      } else {
        dispatch(actionCreator(REFRESH_TOKEN_FAILED));
        dispatch(replace('/auth'));
        setTimeout(() => window.location.reload(), 100);
        toastr.error('Acesso', 'Por favor faça o login novamente');
      }

    } catch (e) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu, Tente mais tarde'));
    }
  }
};

export const refreshAuthTokenFromExternalSource = (token) => {
  return async (dispatch) => {
    try {
      const payload = await refreshToken(token);
      if (payload.status === 200) {
        const payloadUser = await getUser(payload.data.access_token);
        dispatch(actionCreator(LOGIN_SUCCESS, { message: { token: payload.data, usuario: payloadUser.data } }));
        dispatch(replace('/'));
      } else {
        dispatch(replace('/auth'));
        toastr.error('Acesso', 'Por favor faça o login novamente');
      }

    } catch (e) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu, Tente mais tarde'));
    }
  }
};

export const logoutUser = () => {
  return async (dispatch, getState) => {
    try {
      const { user: { token: { access_token } } } = getState().login;
      const payload = await logout(access_token);

      if (payload.status === 200 || payload.status === 403 || payload.status === 401) {
        dispatch(actionCreator(LOGOUT_SUCCESS));
        dispatch(replace('/auth'));
      }

    } catch (e) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'Algo inesperado aconteceu, Tente mais tarde'));
    }
  }
};