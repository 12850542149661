import React, { Component } from 'react'
import '../svg.sass';

export default class BrandFichaCerta extends Component {
  render() {
    return (
        <svg viewBox="0 0 248 124" className="logo">

        <g className="symbol">
            <linearGradient id="ID_SVG_LinearGradient_1_1" gradientUnits="userSpaceOnUse" x1="149.7645" y1="47.1037" x2="149.7645" y2="101.1854">
                <stop  offset="0" className="white"/>
                <stop  offset="0.47" className="gray-X11-gray"/>
                <stop  offset="0.79" className="trolley-grey"/>
                <stop  offset="1" className="trolley-grey"/>
            </linearGradient>
            <polygon className="linearGradient id_1_1" points="195.7,88 195.7,50.7 103.8,50.7 103.8,70.5 143.4,70.5 143.4,82.1 164.3,82.1 164.3,70.5 172.4,70.5 172.4,88 "/>
            <linearGradient id="ID_SVG_LinearGradient_2_1" gradientUnits="userSpaceOnUse" x1="104.7319" y1="41.9384" x2="46.2145" y2="162.9981">
                <stop  offset="0" className="white"/>
                <stop  offset="0.2511" className="pastel-gray"/>
                <stop  offset="0.5041" className="spanish-gray"/>
                <stop  offset="0.6877" className="spanish-gray-light"/>
                <stop  offset="0.7538" className="spanish-gray-extra-light"/>
                <stop  offset="0.8007" className="old-oliver"/>
                <stop  offset="0.8246" className="trolley-grey"/>
                <stop  offset="1" className="trolley-grey"/>
            </linearGradient>
            <path className="linearGradient id_2_1" d="M89.1,16.5h11.8c17.4,0,32,12.2,35.9,28.5h-23.5c-3.6-4.4-9.1-7.2-15.1-7.2h-6.3c-10.8,0-19.6,8.8-19.6,19.6 v9.2c0,10.8,8.8,19.6,19.6,19.6h6.3c6.8,0,12.8-3.5,16.3-8.8h22.6c-3.2,17.1-18.3,30.1-36.2,30.1H89.1c-20.3,0-36.8-16.6-36.8-36.8 V53.3C52.3,33.1,68.9,16.5,89.1,16.5"/>
            <linearGradient id="ID_SVG_LinearGradient_3_1" gradientUnits="userSpaceOnUse" x1="110.3121" y1="101.9726" x2="94.7729" y2="46.5787">
                <stop  offset="0" className="silver-chalice"/>
                <stop  offset="0.4817" className="ligth-gray"/>
                <stop  offset="0.8" className="white"/>
                <stop  offset="1" className="white"/>
            </linearGradient>
            <path className="linearGradient id_3_1" d="M114.5,77.4c-3.5,5.3-9.5,8.8-16.3,8.8h-6.3c-2,0-4-0.3-5.8-0.9l0,0l-8.4,20.3c3.6,1.2,7.5,1.9,11.5,1.9h11.8 c17.9,0,33-13,36.2-30.1H114.5z"/>
            <linearGradient id="ID_SVG_LinearGradient_4_1" gradientUnits="userSpaceOnUse" x1="85.5995" y1="60.8549" x2="134.8743" y2="-4.5657">
                <stop  offset="0" className="silver-chalice"/>
                <stop  offset="0" className="ligth-gray"/>
                <stop  offset="0.8" className="white"/>
                <stop  offset="1" className="white"/>
            </linearGradient>
            <path className="linearGradient id_4_1" d="M90,37.9c0.6-0.1,1.3-0.1,1.9-0.1h6.3c6.1,0,11.5,2.8,15.1,7.2h23.5c-3.8-16.3-18.5-28.5-35.9-28.5H89.1 c-3.7,0-7.2,0.5-10.5,1.5C81.4,20.6,86.7,26.8,90,37.9z"/>
        </g>

        <g className="full">
            <path className="platinum" d="M218.3,71.4c-1.4,0-2.7,0.2-3.8,0.6c-1.1,0.4-2,1-2.8,1.8c-0.8,0.8-1.3,1.7-1.7,2.8c-0.4,1.1-0.6,2.3-0.6,3.7v9 h2.8v-7.5h16v7.5h2.8V71.4H218.3z M228.3,79h-15.8c0.2-2.1,0.9-3.4,2-4c1-0.5,3.1-0.8,6.2-0.8c0.3,0,0.7,0,1.3,0c0.6,0,1.3,0,2.1,0 c0.4,0,0.8,0,1.2,0c0.4,0,0.8,0,1.1,0h1.9V79z"/>
            <path className="platinum" d="M118.7,72.1c-1.1,0.4-2,1-2.8,1.7c-0.8,0.8-1.3,1.7-1.7,2.8c-0.4,1.1-0.6,2.3-0.6,3.7v9h21.6v-2.8h-18.8v-6.1 c0-2.3,0.5-3.8,1.4-4.8c0.9-0.9,2.5-1.4,4.8-1.4h12.6v-2.8h-12.7C121.1,71.5,119.8,71.7,118.7,72.1z"/>
            <path className="platinum" d="M140.3,73.8c-1.5,1.6-2.3,3.8-2.3,6.5v9h21.6v-2.8h-18.8v-4.7h18.8V79h-18.7c0.1-0.8,0.3-1.6,0.5-2.2 c0.3-0.6,0.6-1.1,1.1-1.5s1.1-0.6,1.8-0.8c0.7-0.2,1.6-0.3,2.6-0.3h12.6v-2.8h-12.7C144.1,71.5,141.9,72.3,140.3,73.8z"/>
            <path className="platinum" d="M183.7,81.8V71.5H171c-2.8,0-5,0.8-6.5,2.3c-1.5,1.6-2.3,3.8-2.3,6.5v9h2.8v-7.5h7.5l10.1,8.1l1.8-2.2l-7.4-5.9 H183.7z M165.1,79c0.2-2,0.9-3.4,2-4c1-0.5,3-0.8,6.2-0.8c0.3,0,0.7,0,1.3,0c0.6,0,1.3,0,2.1,0c0.4,0,0.8,0,1.2,0c0.4,0,0.8,0,1.1,0 h1.9V79H165.1z"/>
            <path className="platinum" d="M189.1,74.2c-1.1,1.5-1.8,4-2,7.5l2.8,0.2c0.1-1,0.1-1.9,0.2-2.6c0.1-0.7,0.2-1.3,0.3-1.7 c0.3-1.2,0.9-2,1.8-2.5c1-0.5,2.4-0.8,4.3-0.8v15h2.8v-15h9.4v-2.8H196C192.7,71.5,190.4,72.4,189.1,74.2z"/>
            <rect x="113.2" y="66.4" className="platinum" width="119.7" height="1.4"/>
            <path className="fire-engine-red" d="M216.8,40c-1.6,0-3,0.2-4.3,0.7c-1.2,0.5-2.3,1.1-3.2,2c-0.9,0.9-1.5,1.9-1.9,3.2c-0.4,1.2-0.7,2.6-0.7,4.2 v10.1h3.2v-8.5h18v8.5h3.2V40H216.8z M228,48.4h-17.8c0.3-2.3,1-3.8,2.3-4.5c1.1-0.6,3.5-0.9,7-0.9c0.3,0,0.8,0,1.4,0 c0.6,0,1.4,0,2.4,0c0.5,0,0.9,0,1.4,0c0.4,0,0.9,0,1.3,0h2.1V48.4z"/>
            <polygon className="fire-engine-red" points="202.6,60.1 199.4,60.1 199.4,51.6 181.4,51.6 181.4,60.1 178.3,60.1 178.3,40 181.4,40 181.4,48.4 199.4,48.4 199.4,40 202.6,40 "/>
            <path className="fire-engine-red" d="M174.8,60.1h-24.4V50c0-1.6,0.2-3,0.7-4.2c0.4-1.2,1.1-2.3,1.9-3.2c0.9-0.9,1.9-1.5,3.2-2 c1.2-0.5,2.7-0.7,4.3-0.7h14.4v3.2h-14.2c-2.5,0-4.3,0.5-5.4,1.6c-1,1-1.6,2.8-1.6,5.4v6.8h21.2V60.1z"/>
            <rect x="143.4" y="40" className="fire-engine-red" width="3.2" height="20.1"/>
            <path className="fire-engine-red" d="M138.6,51.6h-21.2v8.5h-3.2V50c0-3.1,0.9-5.6,2.6-7.4c1.7-1.8,4.2-2.6,7.4-2.6h14.4v3.2h-14.2 c-1.1,0-2.1,0.1-2.9,0.3c-0.8,0.2-1.5,0.5-2,0.9c-0.5,0.4-1,1-1.3,1.6c-0.3,0.7-0.5,1.5-0.6,2.4h21.1V51.6z"/>
            <linearGradient id="ID_SVG_LinearGradient_1" gradientUnits="userSpaceOnUse" x1="76.1443" y1="53.1783" x2="76.1443" y2="88.8365">
                <stop  offset="0" className="white"/>
                <stop  offset="0.47" className="gray-X11-gray"/>
                <stop  offset="0.79" className="trolley-grey"/>
                <stop  offset="1" className="trolley-grey"/>
            </linearGradient>
            <polygon className="linearGradient id_1" points="106.4,80.1 106.4,55.6 45.9,55.6 45.9,68.6 71.9,68.6 71.9,76.3 85.7,76.3 85.7,68.6 91.1,68.6 91.1,80.1 "/>
            <linearGradient id="ID_SVG_LinearGradient_2" gradientUnits="userSpaceOnUse" x1="46.4525" y1="49.7725" x2="7.8696" y2="129.5922">
                <stop  offset="0" className="white"/>
                <stop  offset="0.2511" className="pastel-gray"/>
                <stop  offset="0.5041" className="spanish-gray"/>
                <stop  offset="0.6877" className="spanish-gray-light"/>
                <stop  offset="0.7538" className="spanish-gray-extra-light"/>
                <stop  offset="0.8007" className="old-oliver"/>
                <stop  offset="0.8246" className="trolley-grey"/>
                <stop  offset="1" className="trolley-grey"/>
            </linearGradient>
            <path className="linearGradient id_2" d="M36.2,33H44c11.5,0,21.1,8,23.6,18.8H52.1c-2.4-2.9-6-4.7-10-4.7H38c-7.1,0-12.9,5.8-12.9,12.9V66 C25.1,73.2,30.9,79,38,79h4.1c4.5,0,8.4-2.3,10.8-5.8h14.9C65.7,84.4,55.8,93,44,93h-7.8c-13.4,0-24.3-10.9-24.3-24.3V57.3 C11.9,43.9,22.8,33,36.2,33"/>
            <linearGradient id="ID_SVG_LinearGradient_3" gradientUnits="userSpaceOnUse" x1="50.1317" y1="89.3556" x2="39.8861" y2="52.8321">
                <stop  offset="0" className="silver-chalice"/>
                <stop  offset="0.4817" className="ligth-gray"/>
                <stop  offset="0.8" className="white"/>
                <stop  offset="1" className="white"/>
            </linearGradient>
            <path className="linearGradient id_3" d="M52.9,73.2c-2.3,3.5-6.3,5.8-10.8,5.8H38c-1.3,0-2.6-0.2-3.8-0.6l0,0l-5.6,13.4c2.4,0.8,4.9,1.2,7.6,1.2H44 c11.8,0,21.8-8.6,23.9-19.8H52.9z"/>
            <linearGradient id="ID_SVG_LinearGradient_4" gradientUnits="userSpaceOnUse" x1="33.8377" y1="62.245" x2="66.3266" y2="19.1106">
                <stop  offset="0" className="silver-chalice"/>
                <stop  offset="0" className="ligth-gray"/>
                <stop  offset="0.8" className="white"/>
                <stop  offset="1" className="white"/>
            </linearGradient>
            <path className="linearGradient id_4" d="M36.7,47.1c0.4,0,0.8-0.1,1.3-0.1h4.1c4,0,7.6,1.8,10,4.7h15.5C65.1,41,55.4,33,44,33h-7.8 c-2.4,0-4.7,0.4-6.9,1C31.1,35.7,34.5,39.8,36.7,47.1z"/>
        </g>

    </svg>
    )
  }
}
