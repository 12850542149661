import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import CardOld from '@bit/wavfichacerta.fichacerta.cards.card';
import FormContent from '../../../../../components/shared/form/FormContent';
// import { CardContainer, CardTitle, IconHeaderContainer } from '../../../../../components/shared/card/card.styles';
import IncomeModal from '../incomeModal/IncomeModal';
import { getInitialValues } from './selectors';

import getIncomeFields from './incomeFields';
import getIncomeCardFields from './incomeCardFields';
import getIncomeExtractFields from './incomeExtractFields';
import getIncomeNomeResponsavelFields from './incomeNomeResponsavelFields';
import getIncomeSelectFields from './incomeSelectFields';
import getIncomeTelContatoFields from './incomeTelContatoFields';
import incomeFields from './fields';

import { getValues } from '../selectors';
import { formatToBRL } from '../../../../../utils/formatter';

import RoundedMoney from '../../../../../components/shared/treatedIcons/RoundedMoney';
import ModuleFiles from './moduleFiles'
import ModuleFilesCreateLocationFcCompleta from './moduleFilesCreateLocationFcCompleta'
import ModuleFilesAnalise from './moduleFilesAnalise'

import styled from 'styled-components';

const Card = styled(CardOld)`
  position: relative;

  svg {
    fill: #152b4e;
  }

  @media (max-width: 800px) {
    h5 {
      margin-bottom: 35px;
    }

    .label {
      margin-left: 0 !important;
      margin-top: 25px;
    }

    .moduleFiles {
      margin: 20px 0;
    }
  }
`

const CardTitle = styled.div``
const IconHeaderContainer = styled.div`
  width: 16px;
  display: inline-block;

  svg {
    margin-left: 6px;
  }
`

const WrapperSelect = styled.div`
  position: absolute;
  top: -11px;
  left: 266px;
  width: 100%;

  label,
  span {
    display: none;
  }

  [underlinecolor=color_orange-dark],
  [underlinecolor=color_dark-red],
  [underlinecolor=color_green] {
    background-color: ${({hasAvaliacaoFinanceira}) => hasAvaliacaoFinanceira && '#152b4e' || '#ff7200'};
    border-color: transparent;
  }

  select {
    color: #fff;
  }

  @media (max-width: 800px) {
    position: relative;
    left: 0;
    top: 0;

    .field-fc-medium {
      margin-top: 0 !important;
      flex: 0 0 85.5%;
      max-width: 85.5%;
    }
  }
`

const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 17px;
  left: 566px;

  @media (max-width: 800px) {
    position: static;
    margin-bottom: 10px;
    margin-left: 5px;
  }
`

class IncomeForm extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      files: [],
      desconsiderarFcRenda: this.props.desconsiderarFcRenda === undefined ? false : !this.props.desconsiderarFcRenda || false
    }
    
    this.inputRef = React.createRef()
  }

  static defaultProps = {
    products: [1],
    entity: 'PF',
    editMode: false,
    onChangeCheckRenda: () => null
  }

  calcTotalIncome = () => {
    const { entity, changedValues } = this.props;
    if (changedValues !== undefined && typeof changedValues === 'object') {
      if (entity === 'PF') {
        const { renda_principal, outras_rendas, total_renda } = changedValues;
        let total = 0;
        if (renda_principal !== undefined) {
          const other_income = (outras_rendas === undefined || isNaN(outras_rendas)) ? 0 : outras_rendas;
          total = other_income + renda_principal;
          total = formatToBRL(total);
          if (total !== total_renda) {
            this.props.change('total_renda', total);
          }
        }
      }
    }
  }

  getFields = (fieldsForm = 'renda') => {
    this.calcTotalIncome();
    const { products, entity, changedValues } = this.props;
    let fieldsName = getIncomeFields(products, entity, changedValues);
    if(fieldsForm == 'card') {
      fieldsName = getIncomeCardFields(products, entity, changedValues);
    } else if(fieldsForm == 'extratos') {
      fieldsName = getIncomeExtractFields(products, entity, changedValues);
    } else if (fieldsForm == 'select') {
      fieldsName = getIncomeSelectFields(products, entity, changedValues);
    } else if (fieldsForm == 'tel_contato') {
      fieldsName = getIncomeTelContatoFields(products, entity, changedValues, this.state.desconsiderarFcRenda);
    } else if (fieldsForm == 'nome_responsavel_fcdoc_pj') {
      fieldsName = getIncomeNomeResponsavelFields(products, entity, changedValues);
    }
    return [{
      name: 'firstRow',
      fields: fieldsName.map((field) => (field in incomeFields) && incomeFields[field])
    }];
  }

  handleCheckFcRenda = (e) => {
    const {desconsiderarFcRenda} = this.state
    this.setState({desconsiderarFcRenda: !desconsiderarFcRenda}, () => {
      this.props.onChangeCheckRenda(!desconsiderarFcRenda)
    })
  }

  currentActiveProduct = () => {
    if(!this.props.location) {
      return null
    }
    const { ativo } = this.props.location.state.products
    const produtoFcReport = ativo.filter(produto => produto.id === 1)
    const produtoFcEssencial = ativo.filter(produto => produto.id === 3)
    const produtoFcEmpresa = ativo.filter(produto => produto.id === 4)
    const produtoFcBasico = ativo.filter(produto => produto.id === 5)
    const produtoFcCompletaPF = ativo.filter(produto => produto.id === 7)
    const produtoFcCompletaPJ = ativo.filter(produto => produto.id === 8)
    if(produtoFcReport.length) {
      return produtoFcReport[0].id
    } else if(produtoFcEssencial.length) {
      return produtoFcEssencial[0].id
    } else if(produtoFcBasico.length) {
      return produtoFcBasico[0].id
    } else if(produtoFcEmpresa.length) {
      return produtoFcEmpresa[0].id
    } else if(produtoFcCompletaPF.length) {
      return produtoFcCompletaPF[0].id
    } else if(produtoFcCompletaPJ.length) {
      return produtoFcCompletaPJ[0].id
    }
    return null
  }

  render() {
    const { entity, changedValues, origem_renda_documento_recomendacao, origem_renda_documentos, origem_faturamento_documentos, label, products, isFcBasico } = this.props;
    let cardTitle = (entity === 'PF')? 'PERFIL FINANCEIRO POR:' : 'Dados financeiros';
    if(this.currentActiveProduct() === 5) {
      cardTitle = 'PERFIL FINANCEIRO'
    }
    if(this.currentActiveProduct() === 4) {
      cardTitle = 'PERFIL FINANCEIRO POR:'
    }
    return (
      <Card title={cardTitle} iconHeader={RoundedMoney} iconLeft>
        {label &&
          <span className='label' style={{position: 'absolute', backgroundColor: '#152b4e', color: '#fff', fontWeight: 600, padding: '0 6px', marginLeft: 222, top: 16}}>
            {label}
          </span>
        }
        {(this.currentActiveProduct() !== 5 || this.currentActiveProduct() === 5 && isFcBasico) &&
          <WrapperSelect hasAvaliacaoFinanceira={changedValues.avaliacao_financeira || changedValues.avaliacao_financeira_fcdoc_pj}>
            <FormContent rows={this.getFields('select')} editMode={this.props.editMode}/>
          </WrapperSelect>
        }
      
        {this.props.moduloAnalise && this.currentActiveProduct() !== 7 && this.currentActiveProduct() !== 8 &&
          <WrapperCheckbox style={{left: this.currentActiveProduct() === 5 ? 216 : null}}>
            <input name='desconsiderarFcRenda' id='desconsiderarFcRenda' type='checkbox' onChange={this.handleCheckFcRenda} checked={this.state.desconsiderarFcRenda} value={this.state.desconsiderarFcRenda} />
            <label for='desconsiderarFcRenda' style={{marginLeft: 4, marginBottom: 0, fontSize: 13, color: '#152b4e', fontWeight: 500}}>Desconsiderar o resultado da FC DOC</label>
          </WrapperCheckbox>
        }
        {(changedValues.avaliacao_financeira_fcdoc_pj == 1 || changedValues.avaliacao_financeira_fcdoc_pj == 2 || changedValues.avaliacao_financeira_fcdoc_pj == 3 || changedValues.avaliacao_financeira_fcdoc_pj == 4) && this.currentActiveProduct() !== 5 &&
          <FormContent rows={this.getFields()} editMode={this.props.editMode}/>
        }
        {changedValues.avaliacao_financeira == 'renda' && (this.currentActiveProduct() !== 5 || this.currentActiveProduct() === 5 && isFcBasico) &&
          <FormContent rows={this.getFields()} editMode={this.props.editMode}/>
        }
        {changedValues.avaliacao_financeira == 'card' && (this.currentActiveProduct() !== 5 || this.currentActiveProduct() === 5 && isFcBasico) &&
          <FormContent rows={this.getFields('card')} editMode={this.props.editMode}/>
        }
        {changedValues.avaliacao_financeira == 'extratos' && (this.currentActiveProduct() !== 5 || this.currentActiveProduct() === 5 && isFcBasico) &&
          <FormContent rows={this.getFields('extratos')} editMode={this.props.editMode}/>
        }
        {(this.props.moduloAnexo || this.props.moduloAnalise || this.props.moduloAnexoCreateLocationFcCompleta) && this.currentActiveProduct() !== 4 && this.currentActiveProduct() !== 8 &&
          <div style={{marginTop: this.currentActiveProduct() === 5 && !this.props.moduloAnalise ? -28 : null}}><FormContent rows={this.getFields('tel_contato')} editMode={this.props.editMode}/></div>
        }
        {(changedValues.avaliacao_financeira_fcdoc_pj == 1 || changedValues.avaliacao_financeira_fcdoc_pj == 2 || changedValues.avaliacao_financeira_fcdoc_pj == 3 || changedValues.avaliacao_financeira_fcdoc_pj == 4) && this.currentActiveProduct() !== 5 &&
          <FormContent rows={this.getFields('nome_responsavel_fcdoc_pj')} editMode={this.props.editMode}/>
        }
        {this.props.moduloAnexoCreateLocationFcCompleta &&
          <ModuleFilesCreateLocationFcCompleta
            changedValues={changedValues}
            origem_renda={changedValues.origem_renda}
            origem_renda_secundaria={changedValues.origem_renda_secundaria}
            origem_renda_documento_recomendacao={origem_renda_documento_recomendacao}
            origem_renda_documentos={origem_renda_documentos}
            origem_faturamento_documentos={origem_faturamento_documentos}
            sendFileUpload={this.props.sendFileUpload}
            removeFileUpload={this.props.removeFileUpload}
            modulo_principal={this.props.modulo_principal}
            modulo_secundario={this.props.modulo_secundario}
            loadingAllFiles={this.props.loadingAllFiles}
            loadingFileUploadPrincipal={this.props.loadingFileUploadPrincipal}
            loadingFileUploadSecundario={this.props.loadingFileUploadSecundario}
            location={this.props.location}
            principalIsValid={this.props.principalIsValid}
            secundarioIsValid={this.props.secundarioIsValid}
            reconsultaFcRenda={this.props.reconsultaFcRenda}
            products={products}
            clientId={this.props.clientId}
          /> || null
        }

        {this.props.moduloAnexo &&
          <ModuleFiles
            changedValues={changedValues}
            origem_renda={changedValues.origem_renda}
            origem_renda_secundaria={changedValues.origem_renda_secundaria}
            origem_renda_documento_recomendacao={origem_renda_documento_recomendacao}
            origem_renda_documentos={origem_renda_documentos}
            origem_faturamento_documentos={origem_faturamento_documentos}
            sendFileUpload={this.props.sendFileUpload}
            removeFileUpload={this.props.removeFileUpload}
            modulo_principal={this.props.modulo_principal}
            modulo_secundario={this.props.modulo_secundario}
            loadingAllFiles={this.props.loadingAllFiles}
            loadingFileUploadPrincipal={this.props.loadingFileUploadPrincipal}
            loadingFileUploadSecundario={this.props.loadingFileUploadSecundario}
            location={this.props.location}
            principalIsValid={this.props.principalIsValid}
            secundarioIsValid={this.props.secundarioIsValid}
            reconsultaFcRenda={this.props.reconsultaFcRenda}
          /> || null
        }

        {this.props.moduloAnalise &&
          <React.Fragment>
            <CardTitle>
              <span style={{color: '#152b4e', fontWeight: 500, marginRight: 4}}>RENDA MENSAL E PROFISSÃO</span>
              <div style={{backgroundColor: '#152b4e', color: '#fff', fontWeight: 600, padding: '0 6px', display: 'inline-block'}}>
                FC DOC
              </div>
              <IconHeaderContainer>
                <RoundedMoney color="color_purple" />
              </IconHeaderContainer>
            </CardTitle>

            <ModuleFiles
              changedValues={changedValues}
              moduloAnalise={this.props.moduloAnalise}
              origem_renda={changedValues.origem_renda}
              origem_renda_secundaria={changedValues.origem_renda_secundaria}
              origem_renda_documento_recomendacao={origem_renda_documento_recomendacao}
              origem_renda_documentos={origem_renda_documentos}
              origem_faturamento_documentos={origem_faturamento_documentos}
              sendFileUpload={this.props.sendFileUpload}
              removeFileUpload={this.props.removeFileUpload}
              modulo_principal={this.props.modulo_principal}
              modulo_secundario={this.props.modulo_secundario}
              loadingAllFiles={this.props.loadingAllFiles}
              loadingFileUploadPrincipal={this.props.loadingFileUploadPrincipal}
              loadingFileUploadSecundario={this.props.loadingFileUploadSecundario}
              location={this.props.location}
              principalIsValid={this.props.principalIsValid}
              secundarioIsValid={this.props.secundarioIsValid}
              reconsultaFcRenda={this.props.reconsultaFcRenda}
            />
          </React.Fragment> || null
        }
        
        <IncomeModal avaliacao_financeira={changedValues.avaliacao_financeira} origem_renda={changedValues.origem_renda} />
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => (console.log("form1", state),{
  ...ownProps,
  changedValues: getValues(state, 'incomeForm'),
  initialValues: getInitialValues(state, ownProps.editMode, ownProps.entity),
  origem_renda_documento_recomendacao: state.requirements.origem_renda,
  origem_renda_documentos: state.requirements.origem_renda_documento,
  origem_faturamento_documentos: state.requirements.origem_faturamento_documento,
  modulo_principal: state.requestForm.modulo_principal,
  modulo_secundario: state.requestForm.modulo_secundario,
  loadingAllFiles: state.requestForm.loadingAllFiles,
  loadingFileUploadPrincipal: state.requestForm.loadingFileUploadPrincipal,
  loadingFileUploadSecundario: state.requestForm.loadingFileUploadSecundario
});

const IncomeFormWithReduxForm = reduxForm({
  form: 'incomeForm',
  enableReinitialize: true
})(IncomeForm)

const IncomeFormConnected = connect(mapStateToProps, null)(IncomeFormWithReduxForm);

export default IncomeFormConnected;