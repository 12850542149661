import { instance as axios } from './axiosInstance';

const viaCepEndpoint = (cep) => `https://viacep.com.br/ws/${cep}/json/`;
const postmonEndpoint = (cep) => `https://api.postmon.com.br/v1/cep/${cep}`;

export const cepRequest = (cep, api) => {
  let url = ''
  if (api === 'VIA_CEP') {
    url = viaCepEndpoint(cep);
  } else {
    url = postmonEndpoint(cep);
  }
  return axios(url);
};