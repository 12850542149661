export default {
    'color_white': '#ffffff',
    'color_gray-white': '#f6f8f8',
    'color_gray': '#7f7f7f',
    'color_light-gray': ' #f8f6f6',
    'color-menu-gray': '#e5e5e5',
    'color_dark-gray': '#6d6d6d',
    'color_border-gray': ' #b0aeaf',
    'color_border-light-gray': '#e4e1e1',
    'color_blue': '#3a70b6',
    'color_purple': '#152b4e',
    'color_purple_2': '#673ab7',
    'color_purple_3': '#663399',
    'color_medium-blue': '#006080',
    'color_dark-blue': '#285ea3',
    'color_orange': '#d99600',
    'color_orange-dark': ' #d99601',
    'color_yellow-dark': '#deaf1e',
    'color_green': '#37c120',
    'color_brown': '#795548',
  
    'color_red': '#e04f5f',
    'color_dark-red': '#d80027',
    'color_light-red': '#ffb2b2',
    'color_beige': '#fbfafa',
    'color_black': ' #000000',
  
    'color_fire-engine-red': '#CB2528',
    'color_platinum': '#E4E5E6',
  
    'color_charcoal': '#2056ac',
  
    'color_light-green': '#6bc259',
    'color_dark-green': '#3fce00',
    'color_extra-light-green': '#a4fea4',
    'color_light-green-step': '#afe6a6',
    'color_gray-X11-gray': '#BBBCBD',
    'color_trolley-grey': '#7B7B7C',
    'color_pastel-gray': '#CECECE',
    'color_spanish-gray': '#999999',
    'color_spanish-gray-light': '#979797',
    'color_spanish-gray-extra-light': '#909091',
    'color_old-oliver': '#858585',
    'color_silver-chalice': '#B0AFAF',
    'color_ligth-gray': '#D6D6D6',
    'color-cp-gray': '#b0afaf',
    'color_border-gray-secondary': '#cdcdcd',
    'color_light_blue': '#152b4e',
    'color_deep-koamaru': '#152b4e',
    'input_underline_default_color': '#b0afaf',
    'input_label_color': '#ffffff',
    'input_color': '#6d6d6d',
    'transparent': 'transparent',
    'checkbox_color': '#ffffff',
    'table_line-hover': '#dad9fd',
    'color_tr-light-red': '#f9dcdf',
    'color_tr-light-green': '#d7f3d2',
    'color_tr-light-gray': '#e0e0e0',

    'color_used': '#ffc107',
  
    // PRODUCTS STATUS
    "color_included": "#3a70b6",
    "color_requested": "#6f36c9",
    "color_in-progress": "#2f8cff",
    "color_done": "#37c120",
    "color_edited": "#d9008c",
    "color_pending": "#d99600",
    'color_tr-light-yellow': '#ffca2845',
    'color_yellow-box': '#ffefcb',
    'color_report_hr': '#dee2e6',
    'color_systemic_feedback_very_good': '#00af24',
    'color_systemic_feedback_good': '#73cf2b',
    'color_systemic_feedback_regular': '#ff7200',
    'color_systemic_feedback_inconsistent': '#ff7200',
    'color_systemic_feedback_not_advisable': '#fe4d36',
    'color_systemic_feedback_others': '#d99600',
    'color_report-loading': "#0000000f"
  }