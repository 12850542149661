import React from 'react';
import { StyledRow, HalfColumn, MediumColumn } from '../../../../shared/sharedStyles.styles';
import {
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  ColoredSpan,
  RiskText
} from '../../analythic.styled';
import { ColoredColumn, TableTitle, Line } from '../styled';

import { acoesResume, getResultObj } from '../products/fcAnalise';
import LawsuitInfos from './LawsuitInfos';

const Lawsuits = ({ dataSource, hidenTitle }) => {
  const source = getResultObj(dataSource, 'restricoes_financeiras');
  
  if (source !== undefined && "acoes" in source && source.acoes && !source.acoes.detalhes.length && Object.keys(source.acoes.resumo).length && source.acoes.resumo.busca) {
    return (
      <ContainerGray margin="0 0 8px 0" className="acoes-despejo font-13-print">
        <StyledRow customStyle="height: auto" justify="flex-start">
            <HalfColumn>
              <TableTitle style={{display: hidenTitle ? 'none' : 'block', color: '#000'}}>AÇÕES DE DESPEJO E DE LOCAÇÃO*</TableTitle>
            </HalfColumn>
            <HalfColumn>
              <TableTitle style={{color: '#000'}}>NÃO ENCONTRADO*</TableTitle>
            </HalfColumn>
            {/* <RiskText style={{fontStyle: 'italic', fontSize: 13}}>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de apontamentos do pesquisado.</RiskText> */}
        </StyledRow>
      </ContainerGray>
    )
  } else if(source !== undefined && "acoes" in source && source.acoes && !source.acoes.detalhes.length && Object.keys(source.acoes.resumo).length <= 0 && source.acoes.resumo.busca === undefined) {
    return null
  }

  const renderDataLine = ({ title, data }) => (
    <AnalythicLine key={(title + data)}>
      <LineTitle maxWidth="205px" style={{color: '#000'}}>{title}</LineTitle>
      <ColoredSpan color='color_brown'>{data}</ColoredSpan>
    </AnalythicLine>
  );

  const renderResumeLines = (keys) => {
    const dataGiven = acoesResume(dataSource, keys);
    return dataGiven.map((line) => renderDataLine(line));
  };

  return (
    <ColoredColumn>
      <TableTitle style={{display: hidenTitle ? 'none' : 'block', color: '#000'}}>AÇÕES DE DESPEJO E DE LOCAÇÃO*</TableTitle>
      {/* <RiskText style={{fontStyle: 'italic', fontSize: 13}}>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de apontamentos do pesquisado.</RiskText> */}
      <StyledRow align="initial" margin="0 0 .5rem 0" className='font-13-print'>
        <MediumColumn>
          <ContainerGray>
            <AnalythicList>
              {renderResumeLines(['total_ocorrencias_acoes'])}
            </AnalythicList>
          </ContainerGray>
        </MediumColumn>
        <MediumColumn>
          <ContainerGray>
            <AnalythicList>
              {renderResumeLines(['data_primeira_ocorrencia'])}
            </AnalythicList>
          </ContainerGray>
        </MediumColumn>
        <MediumColumn>
          <ContainerGray>
            <AnalythicList>
              {renderResumeLines(['data_ultima_ocorrencia'])}
            </AnalythicList>
          </ContainerGray>
        </MediumColumn>
      </StyledRow>
      <Line />
      <TableTitle style={{marginBottom: 20, color: '#000'}}>DETALHAMENTO (MAIS RECENTES - ATÉ 10)</TableTitle>
      {source.acoes && source.acoes.detalhes.map((acao, index) => 
          <LawsuitInfos index={index} acoesLength={source.acoes.detalhes.length} acao={acao} color='color_brown'/>
      )}
      {/* <RiskText>*Captura complementar e com abrangência limitada.</RiskText> */}
    </ColoredColumn>
  )
};

export default Lawsuits;