import { pull, union } from 'lodash';

export const openMenuItem = (state, payload) => (
  {
    ...state,
    selectedItems: union(state.selectedItems, [payload])
  }
);

export const closeMenuItem = (state, payload) => (
  {
    ...state,
    selectedItems: pull(state.selectedItems, payload)
  }
);

export const retractMenu = (state) => (
  {
    ...state,
    selectedItems: []
  }
);
