import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';  
import { Row, ModalBody } from 'reactstrap';

import ModalContainer from '../../../components/shared/modal/ModalContainer'
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import {changeUserStatus, getUsers} from '../actions'

import { ModalTitle, CancelButton, ConfirmButton } from '../../../components/shared/modal/styled'
import StatusMessagesStatus from './StatusModalMessages';
import StatusModalForm from './StatusModalForm';
import { LastReasonContainer } from '../../../components/shared/status-modal/modal.styles';

class ChangeStatusModal extends PureComponent {

  static defaultProps = {
    options: [],
    changeClientStatus: () => {}
  }

  state = {
    confirmDisabled: true
  }

  renderHeader = () => (
    <ModalTitle>
      ATUALIZAR STATUS
    </ModalTitle>
  )

  renderFooter = (props) => {
    
    return (
      <StyledRow>
        <Row>
          <CancelButton backColor={'#152b4e'} onClick={this.props.closeStatusModal}>Cancelar</CancelButton>
          <ConfirmButton disabled={this.state.confirmDisabled} onClick={this.submitStatus}>Atualizar</ConfirmButton>
        </Row>
      </StyledRow>
    )
  }

  submitStatus = (e) => {
    e.preventDefault();
    const { changedValues, closeStatusModal, changeUserStatus, funcionario, getUsers} = this.props;
    
    if(changedValues.statusSelect === undefined) {
      return closeStatusModal();
    }
    changeUserStatus(funcionario.id,
       changedValues.statusSelect,
        changedValues.statusReason);
        closeStatusModal();
        return getUsers();
    
    

  }

  
  render() {
    const { pessoa, changedValues } = this.props;
    
    if(pessoa === undefined) {
      return null;
    }

    if(changedValues) {
      if(changedValues.statusSelect !== undefined && changedValues.statusReason !== undefined) {
        this.setState({
          confirmDisabled: false
        })
      }
    }
    
    return (
      
      <ModalContainer
        isOpen={this.props.opened}
        onClose={this.props.closeStatusModal}
        header={this.renderHeader(this.props)}
        footer={this.renderFooter(this.props)}
      >
      <ModalBody>
        <StatusMessagesStatus status={pessoa.status} name={pessoa.nome} />
        <StatusModalForm oldStatus={pessoa.status} options={this.props.options} initialValues={{ selectStatus: pessoa.status}} />
        <LastReasonContainer>
          <strong>MOTIVO DA ÚLTIMA ATUALIZAÇÃO:</strong>
          <span>&nbsp;{pessoa.observacao}</span>
        </LastReasonContainer>
      </ModalBody>
        
      </ModalContainer>
      
    )
  }
}

const mapDispatchTopProps = dispatch => bindActionCreators({ changeUserStatus, getUsers }, dispatch);

const mapStateToProps = (state, ...ownProps) =>  ({
  ...ownProps,
  data: state.stateModal,
  form: state.form.stateModalForm || {},
  changedValues: state.form.stateModalForm !== undefined ? state.form.stateModalForm.values : {},
   
  
});

export default connect(mapStateToProps, mapDispatchTopProps)(ChangeStatusModal);