import React from 'react';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import FilterField from './FilterField';
import DateSearchField from './DateSearchField';
import {FilterIcon, FilterButton, FilterText} from './SearchStyles'
import filter from '../../../img/svg/ico-filter.svg';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';

const SearchGroupMobile = ({setFilter, endDate, startDate, filterDate, hiddenInputFilter}) => {
    return (
    <StyledRow customstyle={"z-index: 2"}>
        {!hiddenInputFilter &&
          <BaseColumn customstyle="display: flex;justify-content: flex-start;align-items: center;" xs="12" sm="12" md="4" lg="4" xl="4">
              <FilterIcon src={filter} />
              <FilterField
                  type="text"
                  placeholder="Filtrar id, endereço, pretendente e CPF/CNPJ..."
                  submitSearch={setFilter}
                  noIcon={true}
              />
          </BaseColumn>
        }

        <BaseColumn customstyle="margin: 1.5rem;display: flex;justify-content: flex-start;align-items: center;" xs="12" sm="12" md="4" lg="4" xl="4">
            <FilterIcon src={filter} style={{marginRight: "5px"}} />
            <FilterText style={{fontWeight: 600, fontSize: "13px"}}>
                De 
            </FilterText>
            <DateSearchField
                type="text"
                placeholder="dd/mm/aaaa"
                submitSearch={startDate}
                />
            <FilterText style={{fontWeight: 600, fontSize: "13px"}}> a </FilterText>
            <DateSearchField
                type="text"
                placeholder="dd/mm/aaaa"
                submitSearch={endDate}
                />
        </BaseColumn>
        <BaseColumn customstyle="display: flex;justify-content: center;align-items: center;" xs="12" sm="12" md="2" lg="2" xl="2">
            <FilterButton onClick={filterDate} >
                Filtrar
            </FilterButton>
        </BaseColumn>
    </StyledRow>
    )
}

export default SearchGroupMobile