import * as types from '../actions/types';
const initialState = {
    identification: {},
    network: {},
    income: {},
    socioEconomic: {},
    company: {},
    risk: {},
    restrictions: {},
};
  
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.ID_REF:
            return {
                ...state,
                identification: payload
            };
        case types.NETWORK_REF: 
            return {
                ...state,
                network: payload
            };
        case types.INCOME_REF:    
            return {
                ...state,
                income: payload
            };
        case types.SOCIOECONOMIC_REF:
            return {
                ...state,
                socioEconomic: payload
            };
        case types.COMPANY_REF:
            return {
                ...state,
                company: payload
            };
        case types.RISK_REF:
            return {
                ...state,
                risk: payload
            };
        case types.RESTRICTIONS_REF:
            return {
                ...state,
                restrictions: payload
            };
        default:
            return state;
    }
}