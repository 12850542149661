import styled from 'styled-components';

export const DisabledOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: ${props => props.theme.color_white};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
  z-index: 99;
`;