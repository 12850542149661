import React from 'react';
import { Tr, Td } from '../../../../components/shared/customTable.styled';
import {checkDate} from '../charge-helpers';
import moment from 'moment'

const SubTableLines = (props) => {
  const {nome, razao_social, produtos: {original, reconsulta}, resumo, solicitante, master, oculto} = props

  const swapDotsForCommas = (value) => value.replace(/[.]/g, ",");

  var lines = [];
  for(let i = 0; i < original.length; i++){
    if(original[i].status !== "INCLUIDO" && original[i].status !== "REINCLUIDO"){
      lines.push((
        <Tr >
          <Td width="auto" customStyle={checkDate(original[i].data_pagamento, resumo) ? "" : {opacity: ".4"}} >
            {i === 0 ? (Boolean(oculto) === true ? 'IDENTIFICAÇÃO OCULTADA' : nome || razao_social) : ""}
          </Td>
          <Td width="auto" customStyle={checkDate(original[i].data_pagamento, resumo) ? "" : {opacity: ".4"}}>
            {original[i].usuario_solicitante} {moment(original[i].data_pagamento).format("DD/MM/YYYY HH:mm:ss")}
          </Td>
          <Td width="auto" customStyle={checkDate(original[i].data_pagamento, resumo) ? "" : {opacity: ".4"}}>
            {original[i].nome}
          </Td>
          {/* {master &&
            <Td width="auto" customStyle={checkDate(original[i].data_pagamento, resumo) ? "" : {opacity: ".4"}}>
              {`R$ ${swapDotsForCommas(original[i].valor)}`}
            </Td>
          } */}
        </Tr>
      ))
    }
  }

  for(let i = 0; i < reconsulta.length; i++){
    if(reconsulta[i].status !== "INCLUIDO" && reconsulta[i].status !== "REINCLUIDO"){
      lines.push((
        <Tr >
          <Td width="auto" />
          <Td width="auto" customStyle={checkDate(reconsulta[i].data_pagamento, resumo) ? "" : {opacity: ".4"}}>
            {reconsulta[i].usuario_solicitante} {moment(reconsulta[i].data_pagamento).format("DD/MM/YYYY HH:mm:ss")}
          </Td>
          <Td width="auto" customStyle={checkDate(reconsulta[i].data_pagamento, resumo) ? "" : {opacity: ".4"}}>
            {reconsulta[i].nome}
          </Td>
          {/* {master &&
            <Td width="auto" customStyle={checkDate(reconsulta[i].data_pagamento, resumo) ? "" : {opacity: ".4"}}>
              {`R$ ${swapDotsForCommas(reconsulta[i].valor)}`}
            </Td>
          } */}
        </Tr>
      ))
    }
  }
  return lines
}

export default SubTableLines