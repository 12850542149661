import Person from '@bit/wavfichacerta.fichacerta.icons.person';
import ReportRecommendation from '@bit/wavfichacerta.fichacerta.icons.report-recommendation';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { risk } from '../../../../pages/operational/request/report/singleReport/actions';
import { AnalythicLine, CardAnalythic, ContainerGray, DivisorGray, IconRecommendation, LineTitle, RecommendationBox, RecommendationsColumn, RecommendationText, SistemicFeedbackTitle, SpanRecommendation } from '../analythic.styled';
import ApplicableConcepts from './ApplicableConcepts';
import { getRecommendation, getRisks, getSystemicFeeback, getDicaOuro } from './products/fcAnalise';



class RiskProfile extends PureComponent {
  constructor(props){
    super(props)

    this.risk = React.createRef()
  }

  componentDidMount(){
    this.props.risk(this.risk.current)
  }

  renderObservations = () => {
    const { data } = this.props;
    const recommendations = getRecommendation(data);
    const risks = getRisks(data);
    const dicaOuro = getDicaOuro(data);
    console.log('risks',risks)
    console.log('dicaOuro',dicaOuro)
    console.log('recommendations',recommendations)

    if ((Array.isArray(recommendations) && recommendations.length > 0) 
    || (Array.isArray(risks) && risks.length > 0)) {
      return (
        <RecommendationBox>
          <BaseColumn xs={12} md={1} lg={1} xl={1}>
            <RecommendationText>Obs: </RecommendationText>
          </BaseColumn>
          <RecommendationsColumn xs={12} md={11} lg={11} xl={11}>
            {
              (Array.isArray(recommendations) && recommendations.length > 0) ?
              recommendations.map((item) => (
                item ?
              <RecommendationText>
                <IconRecommendation><ReportRecommendation /></IconRecommendation>
                <SpanRecommendation dangerouslySetInnerHTML={{__html: item}} />
              </RecommendationText>
              : null
            )) : null }
            {
              (Array.isArray(risks) && risks.length > 0) ?
              risks.map((item) => (
                item ?
              <RecommendationText>
                <IconRecommendation><ReportRecommendation /></IconRecommendation>
                <SpanRecommendation dangerouslySetInnerHTML={{__html: item}} />
              </RecommendationText>
              : null
            )) : null }
            {
              (Array.isArray(dicaOuro) && dicaOuro.length > 0) ?
              dicaOuro.map((item) => (
                item ?
              <RecommendationText>
                <IconRecommendation><ReportRecommendation /></IconRecommendation>
                <SpanRecommendation dangerouslySetInnerHTML={{__html: item}} />
              </RecommendationText>
              : null
            )) : null }
          </RecommendationsColumn>
        </RecommendationBox>
      );
    }
    return null;
  }

  renderLines = () => {
    const { data } = this.props;
    const systemicFeedback = getSystemicFeeback(data);
    const systemicFeedbackKey = getSystemicFeeback(data, 'parecer_key');
    const scoreFc = data.laudo.parecer_sistemico[0].score_fc
    const {parecer_key, parecer} = data.laudo.parecer_sistemico[0]

    let nao_confirmado = false

    if(data.laudo.produtos && Object.keys(data.laudo.produtos.fcrenda).length && data.laudo.produtos.fcrenda.ativo) {
      if(data.laudo.produtos.fcrenda.resultado && data.laudo.produtos.fcrenda.resultado.titulo == 'PERFIL FINANCEIRO NÃO CONFIRMADO') {
        nao_confirmado = true
      }
    }

    if (Array.isArray(systemicFeedback)) {
      return systemicFeedback.map((feedback, index) => {
        const lineColor = index % 2 === 0? undefined : "transparent";
        return (
          <StyledRow customstyle={{width: '98%'}} key={index}>
            <BaseColumn xs="12" sm="12" md="6" lg="6" xl="6" margin="20px 0 0 0">
              <AnalythicLine height="auto" margin="1px 0 1px -5px" padding=".4rem" background={lineColor}>
                <LineTitle maxWidth="205px">PARECER INDIVIDUAL</LineTitle>
                <SistemicFeedbackTitle type={systemicFeedbackKey[index]}>{ feedback }</SistemicFeedbackTitle>
              </AnalythicLine>
            </BaseColumn>
            <BaseColumn xs="12" sm="12" md="6" lg="6" xl="6" margin="20px 0 0 0" customstyle='@media (max-width: 800px) {.fc-points{margin-top: 7px !important;}}'>
              <AnalythicLine height="auto" margin="1px 0 1px 5px" padding=".4rem" background={lineColor}>
                <LineTitle maxWidth="205px">{(parecer_key == 'PARECER_NAO_APURADO' || parecer == 'NÃO APURADO' || nao_confirmado) ? 'Score de Mercado' : 'SCORE FC nesta locação*'}</LineTitle>
                <SistemicFeedbackTitle type={systemicFeedbackKey[index]}>{ scoreFc } {(scoreFc != 'Falta de Score Mercado' && scoreFc != 'Não apurado' && scoreFc != 'ALERTA – Verificar ações criminais' && scoreFc != 'ALERTA – Verificar ações criminais. Possibilidade de FRAUDE em virtude de excessivas consultas. Recomendamos a análise da documentação e das consultas, o que pode ser solicitado através do FC DOC.' && scoreFc != 'ALERTA – Possibilidade de FRAUDE em virtude de excessivas consultas. Recomendamos a análise da documentação e das consultas, o que pode ser solicitado através do FC DOC.') && 'PONTOS' || null}</SistemicFeedbackTitle>
                {(scoreFc != 'Falta de Score Mercado' && scoreFc != 'Não apurado' && scoreFc != 'ALERTA – Verificar ações criminais' && scoreFc != 'ALERTA – Verificar ações criminais. Possibilidade de FRAUDE em virtude de excessivas consultas. Recomendamos a análise da documentação e das consultas, o que pode ser solicitado através do FC DOC.' && scoreFc != 'ALERTA – Possibilidade de FRAUDE em virtude de excessivas consultas. Recomendamos a análise da documentação e das consultas, o que pode ser solicitado através do FC DOC.') && <span className='fc-points' style={{fontWeight: 500, fontSize: 10, marginLeft: -13, marginTop: 1}}>(de 0 a 1000)</span> || null}
              </AnalythicLine>
            </BaseColumn>
          </StyledRow>
        );
      });
    }
    return null;
  }

  render() {
    const { data } = this.props;

    const productFcReport = data.pretendente.produtos.ativo.filter(product => product.id === 1 && product.status !== 'INCLUIDO')
    const productFcEssencial = data.pretendente.produtos.ativo.filter(product => product.id === 3 && product.status !== 'INCLUIDO')
    const productFcEmpresa = data.pretendente.produtos.ativo.filter(product => product.id === 4)
    const productFcBasico = data.pretendente.produtos.ativo.filter(product => product.id === 5)

    if(!productFcEssencial.length && !productFcReport.length) {
      return null
    }

    return (
      <CardAnalythic
        title="RISCO DE INADIMPLÊNCIA"
        iconHeader={Person}
        iconLeft={true}
      >
        <StyledRow align="initial" margin="0 0 .5rem 0">
          <ContainerGray ref={this.risk}>
            <StyledRow align="initial" margin="0">
              { this.renderLines() }
              <BaseColumn xs={12} md={12} lg={12} xl={12}>
                { this.renderObservations() }
              </BaseColumn>
            </StyledRow>
          </ContainerGray>
          <BaseColumn xs={12} md={12} lg={12} xl={12}><DivisorGray /></BaseColumn>
          <ApplicableConcepts />
        </StyledRow>
      </CardAnalythic>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  risk
}, dispatch)

export default connect(null, mapDispatchToProps)(RiskProfile)