import styled from 'styled-components';
import { MainContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';


export const StyledMainContainer = styled(MainContainer)`
  padding-left: 0 !important;
  width: 95%;
  margin: 7rem auto auto auto;

  td {
    text-transform: uppercase;
  }
`;
