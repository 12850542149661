export const CHANGE_STAGE = 'login/CHANGE_STAGE';
export const RESET_STATE = 'login/RESET_STATE';
export const LOGIN_REQUEST = 'login/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'login/LOGIN_FAILED';
export const SEND_RECOVER_EMAIL_SUCCESS = 'login/SEND_RECOVER_EMAIL_SUCCESS';
export const SEND_RECOVER_EMAIL_FAILED = 'login/SEND_RECOVER_EMAIL_FAILED';
export const SAVE_RESET_PASSWORD_TOKEN = 'login/SAVE_RESET_PASSWORD_TOKEN';
export const SAVE_EMAIL_TO_RESET_PASSWORD = 'login/SAVE_EMAIL_TO_RESET_PASSWORD';
export const LOGOUT_SUCCESS = 'logout/LOGOUT_SUCCESS';
export const SET_PASSWORD = 'login/SAVE_SET_PASSWORD';
export const SET_PASSWORD_FAILED = 'login/SET_PASSWORD_FAILED';
export const SET_PASSWORD_SUCCESS = 'login/SET_PASSWORD_SUCCESS';
export const SAVE_SET_PASSWORD_TOKEN = 'login/SAVE_SET_PASSWORD_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'login/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILED = 'login/REFRESH_TOKEN_FAILED';
export const USER_REGISTER_CONFIRMATION_SUCCESS = 'login/USER_REGISTER_CONFIRMATION_SUCCESS';
export const USER_REGISTER_CONFIRMATION_FAILED = 'login/USER_REGISTER_CONFIRMATION_FAILED';
