import React from "react";
import Styled from "styled-components";

const StyledSvg = Styled.svg`
  width: 38px;
  fill: ${props => props.theme.color_purple};
  display: ${props => (props.display ? props.display : "inline-block")};
  margin: ${props => (props.margin ? props.margin : "0 0 0 0")};
  position: ${props => props.position || "static"};

`;

export default props => (
  <StyledSvg
    id="Camada_1"
    data-name="Camada 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 175.95 223.4"
    {...props}
  >
    <defs>
      <style>.cls-1{"fill:#37c020"}</style>
    </defs>
    <title>ico-fc-renda3-purple</title>
    <path
      class="cls-1"
      d="M115.8,192.47c.2-1.44.35-2.88.6-4.31a38.15,38.15,0,0,1,52.94-28.3,3.22,3.22,0,0,1,1.85,4.23,3.16,3.16,0,0,1-4.26,1.72,33.19,33.19,0,0,0-10-2.59,31.76,31.76,0,0,0-30.84,16.46,31.82,31.82,0,0,0-1.6,26.8,31.7,31.7,0,0,0,53.86,8.61,30,30,0,0,0,7-15.82c.3-2.44.28-4.92.36-7.39.07-2.15,1.44-3.59,3.43-3.5a3,3,0,0,1,3,2.77,54.7,54.7,0,0,1-.09,7.09,37.31,37.31,0,0,1-13.5,25.83,38.15,38.15,0,0,1-43.69,3.74,37.47,37.47,0,0,1-18.39-26.28c-.27-1.45-.41-2.92-.62-4.38Q115.8,194.81,115.8,192.47Z"
      transform="translate(-19.73 -9.6)"
    />
    <path
      class="cls-1"
      d="M154.09,197.38a7.45,7.45,0,0,1,.62-.84l35.07-35.08a3.6,3.6,0,0,1,2.72-1.3,3.08,3.08,0,0,1,2.85,1.84,3,3,0,0,1-.35,3.3,7.39,7.39,0,0,1-.74.8L156.7,203.67c-1.88,1.87-3.57,1.87-5.45,0q-4.91-4.91-9.81-9.82a3.24,3.24,0,0,1-.82-3.9,3.2,3.2,0,0,1,4.83-1.14,9.05,9.05,0,0,1,.86.79Z"
      transform="translate(-19.73 -9.6)"
    />
    <path
      class="cls-1"
      d="M78.2,9.6c0,6.13.14,12.27-.06,18.39-.09,2.68.78,3.3,3.34,3.27a118.37,118.37,0,0,1,41.11,7.15c1.49.53,3.84.61,2.36,3.32-1,1.91-1,4.58-4.86,3.21-18.74-6.67-38-9.36-57.74-5.68a41.87,41.87,0,0,0-12.73,4.54c-17,9.41-22,24.49-16.32,43.23,2.54,8.43,9.58,13.27,16.88,17.31,9.35,5.18,19.56,8.33,29.62,11.8,10.82,3.72,21.66,7.37,31.58,13.26,9.27,5.5,16.3,12.73,19.27,23.41.9,3.24.94,5.77-2.42,7.74-2,1.16-3.5,3.09-5.56,5-.26-3.47-.26-6.51-.75-9.46-1.46-9-7.32-14.88-14.44-19.69-11.29-7.62-24.25-11.42-37-15.65-10.77-3.58-21.3-7.62-30.91-13.78C17.71,92.93,15.94,61,36.17,44.7a59,59,0,0,1,31.37-12.8c3.07-.33,4.63-1,4.37-4.72-.42-5.83-.11-11.72-.11-17.58Z"
      transform="translate(-19.73 -9.6)"
    />
    <path
      class="cls-1"
      d="M113,189c.13,2.61.23,5.21.4,7.81.14,2.1-1.56,2.61-2.87,3.4-9,5.42-18.83,8-29.12,9-2.5.25-3.34,1-3.24,3.53.21,5.05-.09,10.12.12,15.17.12,2.83-1.53,2.46-3.27,2.47s-3.38.29-3.24-2.5c.22-4.65-.24-9.34.15-14,.33-3.89-1.21-4.51-4.63-4.42a146.69,146.69,0,0,1-41.54-4.47c-6.48-1.72-6.47-1.77-5.56-9,17.06,5.54,34.63,6.82,52.38,6.54,14.59-.24,28.28-3.15,39.43-13.49C112.12,189,112.46,189.08,113,189Z"
      transform="translate(-19.73 -9.6)"
    />
  </StyledSvg>
);
