import { defaultPhoneWithoutDDI } from '@bit/wavfichacerta.fichacerta.fields-templates.phone';

export const showPhoneFields = (state, ownProps) => {
  const defaultPhone = defaultPhoneWithoutDDI('telefone_principal', 'Telefone', ownProps.size, true);
  const aditionalPhone = defaultPhoneWithoutDDI('telefone_adicional', 'Telefone adicional', ownProps.size, false);

  if (state.form !== undefined && state.form !== null && "locationForm" in state.form) {
    const { locationForm: { values } } = state.form;
    if ("telefone_adicional" in values) {
      return [defaultPhone, aditionalPhone];
    }
  }

  return [defaultPhone];
}
