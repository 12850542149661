import styled from 'styled-components';
import { Button } from 'reactstrap';
import { LargeColumn, FullColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';

export const LoginResponseMessageContainer = styled(LargeColumn)`
  border: 1px solid ${props => props.theme[props.color]};
  color: ${props => props.theme[props.color]};
  text-align: center;
  padding: 5px 0 5px 0;
  max-width: 100%;
  flex: 0 0 100%;
  font-size: 13px;
  margin-bottom: 10px;

  svg {
    margin-top: -3px !important;
    margin-left: 6px !important;

    path {
      fill: #e04f5f !important;
    }
  }
`;

export const PasswordResponseMessageContainer = styled(FullColumn)`
  border: 1px solid ${props => props.theme[props.color]};
  color: ${props => props.theme[props.color]};
  text-align: center;
  padding: 5px 0 5px 0;
`;

export const ForgotPasswordButton = styled(Button)`
  color: #ff7200 !important;
  padding-left: 0;
  margin-top: 10px;
  background-color: transparent !important;
  border: none;
  outline: none;
  font-size: .8rem;
  cursor: pointer;
  box-shadow: none !important;
  
  &:hover { color: #aa00ff !important; }
`;

export const NewCustomerSpan = styled.span`
  font-weight: 100;
  color: ${props => props.theme.color_white};
  margin-right: 3px;
`;

export const RegisterButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${props => props.theme.color_purple} !important;

  &:hover { background-color: transparent; }
`;
