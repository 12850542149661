import React, { PureComponent } from 'react';

import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  CardAnalythic,
  RecommendationBox,
  RecommendationText,
  RecommendationsColumn,
  IconRecommendation,
  SpanRecommendation,
  RiskText
} from '../analythic.styled';
import FinancialConstraints from './financialConstraints/FinancialConstraints';
import CheckConstraints from './checkConstraints/CheckConstraints';
import Protest from './protest/Protest';
import AvoidConstraints from './avoidConstraints/AvoidConstraints';
import Block from '@bit/wavfichacerta.fichacerta.icons.block';
import ReportRecommendation from '@bit/wavfichacerta.fichacerta.icons.report-recommendation';
import Lawsuits from './lawsuits/Lawsuits';

export default class LocationSection extends PureComponent {

  getRecommendations = () => {
    const { data } = this.props;

    if (data !== undefined && data !== null && (typeof data === "object" && Object.keys(data).length > 0)) {
      const searchKeys = ['restricoes_financeiras'];
      let recommendations = [];
      searchKeys.forEach((key) => {
        if (data[key] !== undefined && data[key] !== null) {
          recommendations = [...recommendations, ...data[key].recommendation];
        }
      });

      if (Array.isArray(recommendations) && recommendations.length > 0) {
        // Remove duplicates
        recommendations = recommendations.filter((rec, index) => recommendations.indexOf(rec) === index);
        return (
          <RecommendationBox>
            <BaseColumn xs={12} md={1} lg={1} xl={1}>
              <RecommendationText>Obs: </RecommendationText>
            </BaseColumn>
            <RecommendationsColumn xs={12} md={11} lg={11} xl={11}>
              {recommendations.map((item) => (
                <RecommendationText>
                  <IconRecommendation><ReportRecommendation /></IconRecommendation>
                  <SpanRecommendation>{item}</SpanRecommendation>
                </RecommendationText>
              ))}
            </RecommendationsColumn>
          </RecommendationBox>
        );
      }
    }
    return null;
  }

  render() {
    const { data } = this.props;
    const pretendente = ("pretendente" in data)? data.pretendente : {};
    const productFcAnalysisPlus = pretendente.produtos.ativo.filter(produto => produto.id === 1 && produto.status !== 'INCLUIDO')
    const productFcAnalysis = pretendente.produtos.ativo.filter(produto => produto.id === 3 && produto.status !== 'INCLUIDO')
    const productFcBasico = pretendente.produtos.ativo.filter(produto => produto.id === 5)

    if (productFcAnalysisPlus.length) return null;
    if (productFcBasico.length && !productFcAnalysis.length) return null;

    if(this.props.data.laudo.restricoes_financeiras.result.acoes.info !== 'CONSTA') return null;

    return (
      <CardAnalythic
        title="AÇÕES DE DESPEJO E DE LOCAÇÃO*"
        iconHeader={Block}
        iconLeft={true}
      >
        {/* <AvoidConstraints dataSource={this.props.data.laudo} />
        <FinancialConstraints dataSource={this.props.data.laudo} pretendente={pretendente} />
        <CheckConstraints dataSource={this.props.data.laudo} />
        <Protest dataSource={this.props.data.laudo} /> */}
        <Lawsuits dataSource={this.props.data.laudo} hidenTitle />
        <RiskText style={{fontStyle: 'italic', fontSize: 11, fontWeight: 500, color: '#152b4e'}}>*Buscas automatizadas que dependem da abrangência e disponibilidade das fontes. Podem não refletir a totalidade de apontamentos do pesquisado. <span style={{color: '#fe4d36'}}>{this.props.data.laudo.restricoes_financeiras && this.props.data.laudo.restricoes_financeiras.alerta_acoes_riogrande_do_sul}</span></RiskText>
        {this.getRecommendations()}
      </CardAnalythic>
    );
  }
}
