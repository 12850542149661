const getIncomeInitialValues = values => {
  const income = values.renda;
  console.log('income22', values)
  if (income !== undefined) {
    const compor_renda = values.compor_renda? "1" : "0";
    let principal, outra, principal_review, outra_review, card_review, extract_review, telefone = {}
    let selectCardOrRenda = (income.principal.nao_informado)? 'nao-informado' : 'renda';
    if(income.principal){
      principal = {
        renda_principal: parseFloat(income.principal.valor),
        origem_renda: income.principal.origem
      }

      if(income.principal.origem == 13) {
        selectCardOrRenda = 'card'
        principal = {
          limite_cartao_credito: parseFloat(income.principal.valor)
        }
      }

      if(income.principal.origem == 15) {
        selectCardOrRenda = 'extratos'
        principal = {
          movimentacao_bancaria_extratos: parseFloat(income.principal.valor)
        }
      }

      if(income.principal.origem || income.principal.valor) {
        principal = {
          ...principal,
          avaliacao_financeira: selectCardOrRenda
        }
      }
    }

    if(income.outra && (income.outra.origem || income.outra.valor)){
      outra = {
        outras_rendas: parseFloat(income.outra.valor),
        origem_renda_secundaria: income.outra.origem  
      }
    }

    if(income.analises.length){
      principal_review = {
        renda_principal_review: parseFloat(income.analises[0].renda_principal),
        origem_renda_review: income.analises[0].origem_renda_principal
      }

      outra_review = {
        outras_rendas_review: parseFloat(income.analises[0].outra_renda),
        origem_renda_secundaria_review: income.analises[0].origem_outra_renda  
      }

      card_review = {
        limite_cartao_credito_review: parseFloat(income.analises[0].limite_credito),
        numero_cartao_credito_review: income.analises[0].numero_cartao,
        validade_cartao_credito_review: income.analises[0].validade,
        bandeira_cartao_credito_review: income.analises[0].bandeira,
      }

      extract_review = {
        movimentacao_bancaria_extratos_review: parseFloat(income.analises[0].renda_principal)
      }
    }

    if(values.telefones.length && values.telefones[0].principal) {
      telefone = {
        tel_contato: values.telefones[0].principal.numero
      }
    }

    if(values.telefones.length) {
      values.telefones.map(phone => {
        if(phone.contato_fcrenda) {
          telefone.tel_contato = phone.contato_fcrenda.numero
        }
        if(phone.contato_fcrenda_trabalho) {
          telefone.tel_contato_trabalho = phone.contato_fcrenda_trabalho.numero
        }
      })
    }

    return {
      ...principal,
      ...outra,
      ...principal_review,
      ...outra_review,
      ...card_review,
      ...extract_review,
      ...telefone,
      compor_renda
    }
  }
  return {};
};

const getIncomePJInitialValues = values => {
  const income = values.faturamento;
  if (income !== undefined) {
    let telefone = {}
    if(values.telefones.length && values.telefones[0].principal) {
      telefone = {
        tel_contato_pj: values.telefones[0].principal.numero
      }
    }
    if(values.telefones.length && values.telefones[0].contato_fcrenda) {
      telefone = {
        tel_contato_pj: values.telefones[0].contato_fcrenda.numero
      }
    }

    return {
      faturamento_mensal: parseFloat(income.faturamento),
      natureza_juridica: income.natureza_juridica,
      tipo_negocio: income.tipo_negocio,
      avaliacao_financeira_fcdoc_pj: income.faturamento_origem_id,
      receita_media_mensal: parseFloat(income.receita_media_mensal),
      capital_de_investimento: parseFloat(income.capital_de_investimento),
      patrimonio_estimado: parseFloat(income.patrimonio_estimado),
      nome_do_responsavel_pelas_informacoes: income.nome_do_responsavel_pelas_informacoes,
      ...telefone
    }
  }
  return {};
}

export const getInitialValues = (state, editMode, entity) => {
  if (editMode) {
    const { requestResume: { currentApplicant: { pretendente } } } = state;
    const initialValues = pretendente !== undefined ? (Array.isArray(pretendente) ? pretendente[0] : pretendente) : {};
    
    if (entity === "PF") {
      return getIncomeInitialValues(initialValues);
    }else{
      return getIncomePJInitialValues(initialValues);
    }
  }
  
  return {
    origem_renda: "",
    origem_renda_secundaria: ""
  }
};
