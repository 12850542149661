export const mapRequirements = (payload) => {
  const keys = Object.keys(payload);
  console.log('pay1', payload)
  
  const requirements = keys.reduce((reqAcc, key) => {
    
    if (key === 'colaboradores') {
      reqAcc[key] = payload[key].map(collaborator => ({ value: collaborator.id, label: collaborator.nome }));
      return reqAcc;
    }

    if (key === 'descontos') {
      reqAcc[key] = payload[key].map(discount => ({
        ...discount,
        value: discount.id,
        label: discount.titulo
      }));
      return reqAcc;
    }

    if (key === 'produtos') {
      reqAcc[key] = payload[key].map(product => ({ value: product.id, label: product.titulo }));
      return reqAcc;
    }

    if (key === 'localidades') {
      reqAcc[key] = payload[key].map(location => ({ value: location.id, label: location.descricao, price: location.valor }));
      return reqAcc;
    }

    if (key === 'servicos') {
      reqAcc[key] = payload[key].map(service => ({ value: service.id, label: service.titulo, price: service.valor }));
      return reqAcc;
    }

    if (key === 'seguros') {
      reqAcc[key] = payload[key].map(insurance => ({ value: insurance.id, label: insurance.titulo }));
      return reqAcc;
    }

    if (key === 'uf') {
      reqAcc[key] = Object.keys(payload[key]).map((uf) => ({ value: uf, label: uf }));
      return reqAcc;
    }

    if (key === "origem_renda" || key === "origem_renda_secundaria") {
      const payload_avaliacao_financeira = [
        {id: 'card', nome: 'Limite do Cartão de Crédito Informado'},
        {id: 'renda', nome: 'Renda Pessoal Informada'},
        {id: 'extratos', nome: 'Movimentação Bancária (extratos)'},
        {id: 'nao-informado', nome: 'Não informado'}
      ]
      reqAcc[key] = [
        { value: "", label: 'Selecione' },
        ...payload[key].map((origin) => ({ value: origin.id, label: origin.nome, documentos: origin.documentos }))
      ];
      reqAcc['origem_renda_review'] = [
        // { value: "", label: 'Selecione' },
        ...payload[key].map((origin) => ({ value: origin.id, label: origin.nome }))
      ];
      reqAcc['origem_renda_secundaria_review'] = [
        // { value: "", label: 'Selecione' },
        ...payload[key].map((origin) => ({ value: origin.id, label: origin.nome }))
      ];
      reqAcc['avaliacao_financeira'] = [
        // { value: "", label: 'Selecione' },
        ...payload_avaliacao_financeira.map((origin) => ({ value: origin.id, label: origin.nome }))
      ];
      return reqAcc;
    }

    if (key === "origem_faturamento") {
      reqAcc['avaliacao_financeira_fcdoc_pj'] = [
        // { value: "", label: 'Selecione' },
        ...payload[key].map((origin) => ({ value: origin.id, label: origin.nome }))
      ];
      return reqAcc;
    }

    if (key === "tipos") {
      const item = payload[key];
      reqAcc['tipo'] = Object.keys(item).map((keyTypes) => ({ value: keyTypes, label: item[keyTypes] }));
      return reqAcc;
    }

    if (key === "tratamentos") {
      reqAcc[key] = Object.entries(payload[key]).map(([key,value]) => (
        { value: key, label: value }
        ));
      reqAcc[key].push({ value: "", label: ""});
      return reqAcc;
    }

    reqAcc[key] = Object.entries(payload[key]).map(([key,value]) => (
      { value: key, label: value }
    ));

    return reqAcc;
  }, {});

  return requirements;
};
