import React from 'react';
import {
  ColoredSpan
} from '../../analythic.styled';
import { formatToBRL } from '../../../../../utils/formatter';
import { Td } from '@bit/wavfichacerta.fichacerta.tables.styles';
import moment from 'moment';

const TableLine = (props) => {
  const {
    data,
    valor,
    cartorio,
    cidade
  } = props;

  return (
    <tr>
      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{data ? moment(data).format('MM/YYYY') : 'Não informado'}</ColoredSpan>
      </Td>

      <Td align="right" bordercolor="transparent">
        <ColoredSpan color='color_brown'>R$ {formatToBRL(valor)}</ColoredSpan>
      </Td>

      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{cartorio}</ColoredSpan>
      </Td>


      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{cidade}</ColoredSpan>
      </Td>


    </tr>
  );
}

TableLine.defaultProps = {
  data: '',
  valor: '',
  cartorio: '',
  cidade: ''
};

export default TableLine;
