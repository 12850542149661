import React, { Component } from 'react'

import styled from 'styled-components'

const IconSVG = styled.svg`
  width: ${props => props.width ? props.width : "120px"};
	margin: ${props => props.margin ? props.margin : '0'};

	
	& > path {
		fill: ${props => props.fill ? props.fill : props.theme['color_purple']};
		opacity: ${props => props.opacity ? props.opacity : 1};
	}
`

export default class EnvelopeIcon extends Component {
	render() {
		return (
			<IconSVG {...this.props} viewBox="0 0 470.161 470.161">
				<path d="M463.247,55.313H6.914c-2.773,0-5.282,1.66-6.368,4.218C0.173,60.409-0.007,61.35,0,62.276v345.658   c0,0.007,0,0.014,0,0.021c0,0.926,0.187,1.811,0.518,2.613c0.339,0.823,0.836,1.583,1.507,2.254   c0.671,0.67,1.431,1.168,2.254,1.507c0.802,0.332,1.688,0.519,2.614,0.519c0.007,0,0.014,0,0.021,0h456.333   c0.968,0,1.881-0.193,2.708-0.552c0.768-0.324,1.487-0.795,2.116-1.41c0.021-0.021,0.014-0.014,0.014-0.014   c0.007-0.007,0.014-0.007,0.014-0.014c0,0,0,0,0.007,0c0-0.028,0.007-0.021,0.014-0.021c0.007,0,0.007-0.007,0.014-0.014   c0.007-0.007,0.014-0.007,0.014-0.014c0,0,0.007,0,0.007-0.007l0.014-0.014c0.007-0.007,0.007-0.007,0.014-0.014   c0,0,0,0,0.007-0.007c0.643-0.65,1.12-1.397,1.452-2.192c0.339-0.816,0.519-1.708,0.519-2.641V62.227   C470.161,58.404,467.071,55.313,463.247,55.313z M446.902,69.141L287.988,232.84l-0.007,0.007l-52.928,54.566l-53.336-54.165   L23.204,69.141H446.902z M13.828,79.34l153.245,158.659L13.828,391.244V79.34z M23.605,401.02l153.12-153.127l53.432,54.262   c1.307,1.327,3.077,2.067,4.93,2.067c0.007,0,0.021,0,0.028,0c1.86-0.007,3.637-0.768,4.937-2.102l52.976-54.608L446.556,401.02   H23.605z M456.333,391.244l-153.674-153.66L456.333,79.278V391.244z" fill="#152b4e"/>
			</IconSVG>
		)
	}
}


