import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { unregisterField, registerField, clearFields, FormSection, change } from 'redux-form';
import inputHandler from './inputHandler';
import { defaultPhone } from './fields';
import styled, { withTheme } from 'styled-components';
import icons from './treatedIcons';
import { drop } from 'lodash';

const { Plus, Minus } = icons;

const SignButtonContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: -7px;
`

class DynamicInputRow extends PureComponent {
  static defaultProps = {
    requirements: {}
  }
  state = {
    fields: this.props.phoneFields
  }

  handleComposedFields = (field) => {
    const { size } = this.props;
    const { ddi } = this.props.requirements;
    const firtsFieldName = field.name;

    if (firtsFieldName.search(/ddi/) > -1) {
      return inputHandler({...field, options: ddi, size });
    }

    return inputHandler({...field, size });
  }

  renderFields = () => {
    const { fields } = this.state;
    const { theme } = this.props;

    if (Array.isArray(fields) && fields.length > 0) {
      const fieldsToRender = fields.map((field, index) => {
        const newField = field;
        
        if (index === 1) {
          newField.after =  (
            <SignButtonContainer onClick={this.removeField}>
              <IconContainer>
                <Minus width="1rem" height="1rem" fill={theme.dynamicPhoneButtonColor} />
              </IconContainer>
            </SignButtonContainer>
          );
        } else if (fields.length === 1) {
          newField.after = (
            <SignButtonContainer onClick={this.addField}>
              <IconContainer>
                <Plus width="1rem" height="1rem" fill={theme.dynamicPhoneButtonColor} />
              </IconContainer>
            </SignButtonContainer>
          );
        } else {
          newField.after = null;
        }
  
        return (
          <FormSection name={index === 0 ? 'principal' : 'adicional'}>
            {this.handleComposedFields(newField)}
          </FormSection>
        );
      });
  
      return fieldsToRender
    }

    return null;
  }

  addField = (e) => {
    e.preventDefault();
    if (!this.props.theme.disabled) {
      this.setState({ fields: [...this.state.fields, defaultPhone('numero', 'Telefone Adicional')]});

      /**
       * @constant names {Array.<string>}
       * @property formName {String}
       * in formName we expect a string with the name of the form or a path with section name
       * for instance 'formName' or 'formName.sectionName',
       * due to the possibility of the second case we split on '.'
       * change function expect formName and just field path or the path to it
       * for instance 'fieldName' or 'sectioName.fieldName' or 'sectioName.subSectionName.fieldName'
       */
      const names = this.props.formName.split('.');
      let fieldPath = 'adicional.ddi';
      if (names.length > 1) {
        const namesWithoutFormName = drop(names);
        fieldPath = `${namesWithoutFormName.join('.')}.${fieldPath}`;
      }
      this.props.change(names[0], fieldPath, '+55');
    }
  }

  removeField = (e) => {
    e.preventDefault();
    if (!this.props.theme.disabled) { 
      this.setState((state) => {
        const popedFieldList = state.fields;
        popedFieldList.pop();
        return { fields: popedFieldList }
      })
      this.props.unregisterField(`${this.props.formName}.adicional`, 'ddi');
      this.props.unregisterField(`${this.props.formName}.adicional`, 'numero');
      this.props.clearFields(`${this.props.formName}.adicional`, true, true, 'ddi', 'numero')
    }
  }

  render() {
    
    return (
      <Fragment>
        {this.renderFields()}
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const names = ownProps.formName.split('.');

  const finalValue = names.reduce((accObj, name, index) => {
    if (index === 1) {
      return accObj.initial[name]
    }

    return accObj[name];
  }, state.form);
  const phoneFields = [defaultPhone('numero', 'Telefone')];

  if (finalValue !== undefined && finalValue.adicional !== undefined) {
    phoneFields.push(defaultPhone('numero', 'Telefone adicional'))
  }
  
  return {
    ...ownProps,
    phoneFields,
    requirements: state.requirements
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  unregisterField,
  registerField,
  clearFields,
  change
}, dispatch);

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(DynamicInputRow));
