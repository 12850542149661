import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getLoginError, getLoginRequestLoading } from './selectors';

import { LoginResponseMessageContainer } from '../../../components/login/login.styles';
import Icons from '../../../components/Menu/Icons';

class LoginResponseContainer extends PureComponent {
  handleLoginStatus = () => {
    const { isLoading, error } = this.props;
    const errorWithIcon = 'Algo inesperado aconteceu. Entre em contato com o suporte: (21) 98734-6472'
    if (error !== undefined) {
      return (
        <LoginResponseMessageContainer color="color_red">
          <span>{error}</span>
          { error == errorWithIcon && <span>{Icons.WPP()}</span> }
        </LoginResponseMessageContainer>
      );
    }

    if (isLoading) {
      return (
        <LoginResponseMessageContainer color="color_purple">
          <span>Checando dados...</span>
        </LoginResponseMessageContainer>
      )
    }

    return null;
  }

  render() {
    return this.handleLoginStatus();
  }
}

const mapStateToProps = (state, ownProps) => (
  {
    ...ownProps,
    error: getLoginError(state),
    isLoading: getLoginRequestLoading(state),
  }
);

export default connect(mapStateToProps, null)(LoginResponseContainer);