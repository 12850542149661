import React from 'react';
import {
  ColoredSpan
} from '../../analythic.styled';
import { Td } from '@bit/wavfichacerta.fichacerta.tables.styles';
import moment from 'moment';

const TableLine = (props) => {
  const {
    quantidade,
    alinea,
    data,
    numero_cheque,
    valor,
    banco,
    agencia,
    cidade
  } = props;

  return (
    <tr>
      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{moment(data).format('MM/YYYY')}</ColoredSpan>
      </Td>

      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{quantidade}</ColoredSpan>
      </Td>

      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{alinea}</ColoredSpan>
      </Td>

      {/* <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{numero_cheque}</ColoredSpan>
      </Td> */}

      {/* <Td align="right" bordercolor="transparent">
        <ColoredSpan color='color_brown'>{valor}</ColoredSpan>
      </Td> */}

      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{banco}</ColoredSpan>
      </Td>

      <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{agencia}</ColoredSpan>
      </Td>

      {/* <Td bordercolor="transparent">
        <ColoredSpan color='color_brown'>{cidade}</ColoredSpan>
      </Td> */}


    </tr>
  );
}

TableLine.defaultProps = {
  data: "",
  quantidade: "",
  alinea: "",
  numero_cheque: "",
  valor: "",
  banco: "",
  agencia: "",
  cidade: "",
  sub_judice: ""
};

export default TableLine;
