import { omitBy, isEmpty } from 'lodash';
import { removeNonDigit } from '@bit/wavfichacerta.fichacerta.utils.normalizers'

const addressExtractor = (addressObj) => ({
  cep: addressObj.cep,
  logradouro: addressObj.logradouro,
  numero: addressObj.numero,
  complemento: addressObj.complemento,
  bairro: addressObj.bairro,
  cidade: addressObj.cidade,
  uf: addressObj.uf
});

const aditionalContactsExtractor = (formData) => ({
  operacional: {
    nome: formData.operacional_nome,
    funcao: formData.operacional_funcao,
    email: formData.operacional_email,
    telefones: {
      principal: {
        numero: formData.operacional_telefone
      }
    }
  },
  financeiro: {
    nome: formData.financeiro_nome,
    funcao: formData.financeiro_funcao,
    email: formData.financeiro_email,
    telefones: {
      principal: {
        numero: formData.financeiro_telefone
      }
    }
  },
  diretoria: {
    nome: formData.diretoria_nome,
    funcao: formData.diretoria_funcao,
    email: formData.diretoria_email,
    telefones: {
      principal: {
        numero: formData.diretoria_telefone
      }
    }
  }
}) 

const pfEntityExtractor = (data) => ({
  tratamento: data !== null?  data.tratamentos : undefined,
  nome: data !== null? data.nome : undefined,
  tipo_pessoa_fisica: data !== null? data.tipo : undefined,
  identidade: data !== null? data.identidade : undefined,
  orgao_expedidor: data !== null? data.orgao_expedidor : undefined,
  website: data !== null? data.website : undefined,
  creci: data !== null? data.creci : undefined,
  telefones: data !== null? omitBy({
    principal: {
      numero: data.telefone
    },
    adicional: {
      numero: data.telefone_adicional
    }
  }, isEmpty) : {},
  endereco: data !== null? addressExtractor(data) : {},
  contato: data !== null? { ...aditionalContactsExtractor(data) } : {}
});

const pjEntityExtractor = (data) => ({
  nome: data.nome,
  website: data.website,
  creci: data.creci,
  endereco: data !== null? addressExtractor(data) : {},
  contato: data !== null? { ...aditionalContactsExtractor(data) } : {},
  telefones: data !== null? omitBy({
    principal: {
      numero: data.telefone
    },
    adicional: {
      numero: data.telefone_adicional
    }
  }, isEmpty) : {}
});

export const customerDataExtractor = (entity = "PF", data) => {
  if (entity === "PF") {
    return pfEntityExtractor(data);
  }
  return pjEntityExtractor(data);
}

export const userDataExtractor = (data) => {

  return {
    nome: data.nome,
    funcao: data.funcao,
    credito: data.credit,
    cpf: data.cpf,
    identidade: data.identidade,
    orgao_expedidor: data.orgao_expedidor,
    email: data.email,
    telefones: {
      principal: {
        numero: data.telefone !== undefined? removeNonDigit(data.telefone) : undefined
      } 
    },
    complemento: {
      tipo: data.tipo_usuario
    }
  }
}
