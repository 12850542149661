
export const getInitialValues = (state) => state.tenancyForm.data.data;
export const getCustomer = (state) => state.tenancyForm.data.customer;

export const getSavedStatus = (state) => {
  if (state.tenancyForm.error !== undefined) {
    return !state.tenancyForm.error;
  }

  return false;
}

export const getSendingStatus = state => state.tenancyForm.requesting;
