import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openModal, deleteProduct } from '../../pages/operational/request/resume/ModalDeleteLastProduct/actions';

import { Row } from 'reactstrap';
import { ColSpaceBetween, PTitle, BoldCards, IconsContainer, Icon, Card } from './applicantCard.styles';
import ProductResume from './ProductResume';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { CardButton } from './operational.styles';
import ModalDeleteProduct from '../../pages/operational/request/resume/ModalDeleteLastProduct/ModalDeleteLastProduct';
import UndoReinclusionModal from './ModalUndoReinclusion';
import Edit from '@bit/wavfichacerta.fichacerta.icons.edit';
import { resetReinclusion, updateApplicantInfos, getApplicantInfos } from '../../pages/operational/request/resume/actions';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import iconDots from './../../img/png/ico-dots-vertical.png'
import iconReconsultarBlue from './../../img/png/ico-reconsultar-blue.png'
import Icons from '../shared/treatedIcons';
import TourTooltip from '../shared/TourTooltip';

const { BlueTrash } = Icons;
const descriptionFcRapida = "Análise individual com perfil customizado para locação. Resposta instantânea.";
const descriptionFcRenda = (editCount, status) => {
  switch(status){
    case "CONCLUIDO":
      return `Você ainda pode editar esse produto ${editCount}x vezes pelo ícone de Edição`
    case "xx.xx":
      return 'Para solicitar um FC DOC, entre em contato com o suporte (21)98734-6472'
    default:
      return `Análise de documentos no prazo médio de até 1 hora.`
  }
}
// const descriptionFcAcoes = "INCLUIR\nAnálise individual com perfil customizado para locação. Resposta instantânea.";
// const descriptionFcFraude = "INCLUIR\nAnálise individual com perfil customizado para locação. Resposta instantânea.";

class ApplicantCard extends PureComponent {
  state = {
    undoModalOpen: false,
    dropdownOpen: false,
    oculto: Boolean(this.props.oculto),
    consultado_hoje: Boolean(this.props.consultado_hoje)
  }

  onSelectFcRenda = (editCount) => {
    if(editCount == 0) {
      this.props.addFcRenda(true)
    }
  }

  renderProducts = () => {
    const { produtos: { ativo }, products} = this.props;
    const productFcRenda = products.filter(item => item.id === 2)
    const productFcReportPrice = products.filter(item => item.id === 1)
    const productFcEssencialPrice = products.filter(item => item.id === 3)
    const productFcDocPJ = products.filter(item => item.id === 6)
    const oculto = this.state.oculto
    const consultado_hoje = this.state.consultado_hoje

    // se for xx.xx quer dizer que esse cliente não tem o produto FC DOC habilitado no checkbox.
    const valor_final_fcrenda = productFcRenda[0] && productFcRenda[0].id == 2 ? productFcRenda[0].valor_final : 'xx.xx';
    const valor_final_fcreport = productFcReportPrice[0] && productFcReportPrice[0].id == 1 ? productFcReportPrice[0].valor_final : 'xx.xx';
    const valor_final_fcessencial = productFcEssencialPrice[0] && productFcEssencialPrice[0].id == 3 ? productFcEssencialPrice[0].valor_final : 'xx.xx';
    const valor_final_fcdoc_pj = productFcDocPJ[0] && productFcDocPJ[0].id == 6 ? productFcDocPJ[0].valor_final : 'xx.xx';
    let statusFcRenda;
    let list = [];

    const renderAddFCReport = ativo.some(product => product.id === 1 || product.id === 4 || product.id === 7 || product.id === 8) ? null
    : valor_final_fcreport == 'xx.xx' ? false
    : (
        <ProductResume
          id={1}
          oculto={oculto}
          active={false}
          included={false}
          selected={false}
          icon="FcRapida"
          title="FC REPORT"
          statusProduct={"INCLUIR"}
          price={valor_final_fcreport}
          description={<span>Análise FC para Locação + Restrições e Ações Locatícias, Cíveis e Criminais.</span>}
          addFcRenda={valor_final_fcreport == 'xx.xx' ? () => {} : () => this.props.addFcReport()}
        />
      );

    const renderAddFCEssencial = ativo.some(product => product.id === 1 || product.id === 3 || product.id === 4 || product.id === 7 || product.id === 8) ? null
    : valor_final_fcessencial == 'xx.xx' ? false
    : (
        <ProductResume
          id={3}
          oculto={oculto}
          active={false}
          included={false}
          selected={false}
          icon="FcRapida"
          title="FC ESSENCIAL"
          statusProduct={"INCLUIR"}
          price={valor_final_fcessencial}
          description={<span>Análise FC para Locação + Ações Locatícias e Criminais.</span>}
          addFcRenda={valor_final_fcessencial == 'xx.xx' ? () => {} : () => this.props.addFcEssencial()}
        />
      );

    const renderAddFCRenda = ativo.some(product => product.id === 2) ? null
    : ativo.some(product => product.id === 4 || product.id === 7 || product.id === 8) ? false
    : valor_final_fcrenda == 'xx.xx' ? false
    : (
        <div style={{position: 'relative'}}>
          <ProductResume
            active={false}
            oculto={oculto}
            included={false}
            selected={false}
            icon="FcRenda"
            title="FC DOC"
            statusProduct={"INCLUIR"}
            price={valor_final_fcrenda}
            description={descriptionFcRenda(null, valor_final_fcrenda == 'xx.xx' ? valor_final_fcrenda : "INCLUIR")}
            addFcRenda={valor_final_fcrenda == 'xx.xx' ? () => {} : () => this.props.addFcRenda()}
          />
          {!this.state.oculto &&
            <TourTooltip
              target="tooltip-adicionar-fcrenda"
              style={{right: 0}}
            />
          }
        </div>
      );

    if(renderAddFCRenda === null) {
      statusFcRenda = ativo.filter(product => product.id === 2)[0].status;
    }

    const renderAddFCDocPJ = ativo.some(product => product.id === 6 || product.id === 7 || product.id === 8) ? null
    : ativo.some(product => product.id === 1 || product.id === 3 || product.id === 5) ? false
    : valor_final_fcdoc_pj == 'xx.xx' ? false
    : (
        <div style={{position: 'relative'}}>
          <ProductResume
            active={false}
            oculto={oculto}
            included={false}
            selected={false}
            icon="FcRenda"
            title="FC DOC"
            statusProduct={"INCLUIR"}
            price={valor_final_fcdoc_pj}
            description={descriptionFcRenda(null, valor_final_fcdoc_pj == 'xx.xx' ? valor_final_fcdoc_pj : "INCLUIR")}
            addFcRenda={valor_final_fcdoc_pj == 'xx.xx' ? () => {} : () => this.props.addFcDocPJ()}
          />
          <TourTooltip
            target="tooltip-adicionar-fcdoc-pj"
            style={{right: 0}}
          />
        </div>
      );

    const productsFcBasico = ativo.filter(product => product.id === 5)
    if(productsFcBasico.length) {
      list.push(
        <ProductResume
          id={productsFcBasico[0].id}
          oculto={oculto}
          consultado_hoje={consultado_hoje}
          toggleDontTodayReinclusionModal={this.props.toggleDontTodayReinclusionModal}
          active={true}
          included={true}
          selected={true}
          icon="Document"
          title="FC BASICO"
          statusProduct={productsFcBasico[0].status}
          price={productsFcBasico[0].valor}
          description={statusFcRenda == 'SOLICITADO' || statusFcRenda == 'ANDAMENTO' ? 'Disponível somente após conclusão da FC DOC' : <span>Consultas de Dados, Restrições, Ações Locatícias, Cíveis e Criminais</span>}
          onClickTrash={() => this.callDeleteModal(productsFcBasico[0].id, productsFcBasico[0].status)}
          onSelect={statusFcRenda == 'SOLICITADO' || statusFcRenda == 'ANDAMENTO' ? () => null : this.props.onEdit(true, productsFcBasico[0].id)}
          editCount={productsFcBasico[0].contador_edicao}
          // statusFcRenda={statusFcRenda}
        />
      );
    }

    const productFcEssencial = ativo.filter(product => product.id === 3)
    if(productFcEssencial.length) {
      list.push(
        <ProductResume
          id={productFcEssencial[0].id}
          oculto={oculto}
          consultado_hoje={consultado_hoje}
          toggleDontTodayReinclusionModal={this.props.toggleDontTodayReinclusionModal}
          active={true}
          included={true}
          selected={true}
          icon="FcRapida"
          title="FC ESSENCIAL"
          statusProduct={productFcEssencial[0].status}
          price={productFcEssencial[0].valor}
          description={statusFcRenda == 'SOLICITADO' || statusFcRenda == 'ANDAMENTO' ? 'Disponível somente após conclusão da FC DOC' : <span>Análise FC para Locação + Ações Locatícias e Criminais;</span>}
          onClickTrash={() => this.callDeleteModal(productFcEssencial[0].id, productFcEssencial[0].status)}
          onSelect={statusFcRenda == 'SOLICITADO' || statusFcRenda == 'ANDAMENTO' ? () => null : this.props.onEdit(true, productFcEssencial[0].id)}
          editCount={productFcEssencial[0].contador_edicao}
          statusFcRenda={statusFcRenda}
        />
      );
    }

    list.push(renderAddFCEssencial);

    const productFcReport = ativo.filter(product => product.id === 1)
    if(productFcReport.length) {
      list.push(
        <ProductResume
          id={productFcReport[0].id}
          oculto={oculto}
          consultado_hoje={consultado_hoje}
          toggleDontTodayReinclusionModal={this.props.toggleDontTodayReinclusionModal}
          active={true}
          included={true}
          selected={true}
          icon="FcRapida"
          title="FC REPORT"
          statusProduct={productFcReport[0].status}
          price={productFcReport[0].valor}
          description={statusFcRenda == 'SOLICITADO' || statusFcRenda == 'ANDAMENTO' ? 'Disponível somente após conclusão da FC DOC' : <span>Análise FC para Locação + Restrições e Ações Locatícias, Cíveis e Criminais;</span>}
          onClickTrash={() => this.callDeleteModal(productFcReport[0].id, productFcReport[0].status)}
          onSelect={statusFcRenda == 'SOLICITADO' || statusFcRenda == 'ANDAMENTO' ? () => null : this.props.onEdit(true, productFcReport[0].id)}
          editCount={productFcReport[0].contador_edicao}
          statusFcRenda={statusFcRenda}
        />
      );
    }
    
    list.push(renderAddFCReport);

    const productsFcRenda = ativo.filter(product => product.id === 2)
    if(productsFcRenda.length) {
      list.push(
        <ProductResume
          id={productsFcRenda[0].id}
          oculto={oculto}
          active={true}
          included={true}
          selected={true}
          icon="FcRenda"
          title="FC DOC"
          statusProduct={productsFcRenda[0].status === "SOLICITADO" ? "ANDAMENTO" : productsFcRenda[0].status}
          price={productsFcRenda[0].valor}
          description={descriptionFcRenda(productsFcRenda[0].contador_edicao, productsFcRenda[0].status)}
          onClickTrash={() => this.callDeleteModal(productsFcRenda[0].id, productsFcRenda[0].status)}
          onSelect={() => this.onSelectFcRenda(productsFcRenda[0].contador_edicao)}
          editCount={productsFcRenda[0].contador_edicao}
          // addFcRenda={() => this.props.addFcRenda()}
        />
      );
    }

    const productsFcCompletaPF = ativo.filter(product => product.id === 7)
    if(productsFcCompletaPF.length) {
      list.push(
        <ProductResume
          id={productsFcCompletaPF[0].id}
          oculto={oculto}
          consultado_hoje={consultado_hoje}
          toggleDontTodayReinclusionModal={this.props.toggleDontTodayReinclusionModal}
          active={true}
          included={true}
          selected={true}
          icon="FcRapida"
          title="FC COMPLETA"
          statusProduct={productsFcCompletaPF[0].status === "SOLICITADO" ? "ANDAMENTO" : productsFcCompletaPF[0].status}
          price={productsFcCompletaPF[0].valor}
          description={descriptionFcRenda(productsFcCompletaPF[0].contador_edicao, productsFcCompletaPF[0].status)}
          onClickTrash={() => this.callDeleteModal(productsFcCompletaPF[0].id, productsFcCompletaPF[0].status)}
          onSelect={productsFcCompletaPF[0].status == 'SOLICITADO' || productsFcCompletaPF[0].status == 'ANDAMENTO' ? () => null : productsFcCompletaPF[0].status === 'CONCLUIDO' ? this.props.onEdit(true, productsFcCompletaPF[0].id) : () => this.onSelectFcRenda(productsFcCompletaPF[0].contador_edicao)}
          editCount={productsFcCompletaPF[0].contador_edicao}
          // addFcRenda={() => this.props.addFcRenda()}
        />
      );
    }

    list.push(renderAddFCRenda);

    const productsFcEmpresa = ativo.filter(product => product.id === 4)
    if(productsFcEmpresa.length) {
      list.push(
        <ProductResume
          id={productsFcEmpresa[0].id}
          oculto={oculto}
          consultado_hoje={consultado_hoje}
          toggleDontTodayReinclusionModal={this.props.toggleDontTodayReinclusionModal}
          active={true}
          included={true}
          selected={true}
          icon="FcRapida"
          title="FC EMPRESA"
          statusProduct={productsFcEmpresa[0].status}
          price={productsFcEmpresa[0].valor}
          description={statusFcRenda == 'SOLICITADO' || statusFcRenda == 'ANDAMENTO' ? 'Disponível somente após conclusão da FC DOC' : <span>Análise do perfil de risco para locação: FC EMPRESA</span>}
          onClickTrash={() => this.callDeleteModal(productsFcEmpresa[0].id, productsFcEmpresa[0].status)}
          onSelect={statusFcRenda == 'SOLICITADO' || statusFcRenda == 'ANDAMENTO' ? () => null : this.props.onEdit(true, productsFcEmpresa[0].id)}
          editCount={productsFcEmpresa[0].contador_edicao}
          // statusFcRenda={statusFcRenda}
        />
      );
    }

    const productsFcDocPJ = ativo.filter(product => product.id === 6)
    if(productsFcDocPJ.length) {
      list.push(
        <ProductResume
          id={productsFcDocPJ[0].id}
          oculto={oculto}
          active={true}
          included={true}
          selected={true}
          icon="FcRenda"
          title="FC DOC"
          statusProduct={productsFcDocPJ[0].status === "SOLICITADO" ? "ANDAMENTO" : productsFcDocPJ[0].status}
          price={productsFcDocPJ[0].valor}
          description={descriptionFcRenda(productsFcDocPJ[0].contador_edicao, productsFcDocPJ[0].status)}
          onClickTrash={() => this.callDeleteModal(productsFcDocPJ[0].id, productsFcDocPJ[0].status)}
          onSelect={() => this.onSelectFcRenda(productsFcDocPJ[0].contador_edicao)}
          editCount={productsFcDocPJ[0].contador_edicao}
          // addFcRenda={() => this.props.addFcRenda()}
        />
      );
    }

    const productsFcCompletaPJ = ativo.filter(product => product.id === 8)
    if(productsFcCompletaPJ.length) {
      list.push(
        <ProductResume
          id={productsFcCompletaPJ[0].id}
          oculto={oculto}
          consultado_hoje={consultado_hoje}
          toggleDontTodayReinclusionModal={this.props.toggleDontTodayReinclusionModal}
          active={true}
          included={true}
          selected={true}
          icon="FcRapida"
          title="FC COMPLETA"
          statusProduct={productsFcCompletaPJ[0].status === "SOLICITADO" ? "ANDAMENTO" : productsFcCompletaPJ[0].status}
          price={productsFcCompletaPJ[0].valor}
          description={descriptionFcRenda(productsFcCompletaPJ[0].contador_edicao, productsFcCompletaPJ[0].status)}
          onClickTrash={() => this.callDeleteModal(productsFcCompletaPJ[0].id, productsFcCompletaPJ[0].status)}
          onSelect={productsFcCompletaPJ[0].status == 'SOLICITADO' || productsFcCompletaPJ[0].status == 'ANDAMENTO' ? () => null : productsFcCompletaPJ[0].status === 'CONCLUIDO' ? this.props.onEdit(true, productsFcCompletaPJ[0].id) : () => this.onSelectFcRenda(productsFcCompletaPJ[0].contador_edicao)}
          editCount={productsFcCompletaPJ[0].contador_edicao}
          // addFcRenda={() => this.props.addFcRenda()}
        />
      );
    }

    list.push(renderAddFCDocPJ);

    return list;
  }

  callDeleteModal = (idProduct, status) => {
    const { produtos, openModal, id } = this.props;
    // Delete last product and Applicant
    if(status !== "REINCLUIDA" && status !== "REINCLUIDO"){
      if (idProduct !== undefined && produtos.ativo.length === 1) {
        openModal(idProduct, produtos, id, true);
      } else {
        openModal(idProduct, produtos, id, false)
      }
    }else{
      this.setState({undoModalOpen: true, undoIdProduct: idProduct})
    }
  }

  deleteProduct = () => {
    const { id, request, produtos, deleteProduct, applicantsLength } = this.props;

    // Delete last product and Applicant
    if (produtos.ativo.length >= 1) {
      const lastApplicant = applicantsLength >= 1;
      deleteProduct(request, id, produtos.ativo[0].id, lastApplicant);
    }
  }

  renderName = () => {
    const { entity, nome, razao_social } = this.props;
    const { oculto } = this.state;
    if(oculto) {
      return 'IDENTIFICAÇÃO OCULTADA';
    }
    if (entity === "PF") {
      return nome;
    }

    if (entity === "PJ") {
      return (nome !== undefined && nome !== null && nome !== "")? nome : razao_social;
    }

    return "Não informado";
  }

  activateEditButton = () => {
    const nonEditableStatus = ['SOLICITADO', 'ANDAMENTO', 'PENDENTE'];
    const { produtos } = this.props;
    let disabled = false;

    if (Array.isArray(produtos.ativo) && produtos.ativo.length > 0) {
      produtos.ativo.forEach((product) => {
        if (nonEditableStatus.findIndex((status) => status === product.status) > -1 && !disabled) {
          disabled = true;
        }
      });
    }

    return disabled;
  }

  activateDeleteButton = () => {
    const nonEditableStatus = ['SOLICITADO', 'ANDAMENTO', 'PENDENTE', 'CONCLUIDO'];
    const { produtos } = this.props;
    let disabled = false;

    if (Array.isArray(produtos.ativo) && produtos.ativo.length > 0) {
      produtos.ativo.forEach((product) => {
        if (nonEditableStatus.findIndex((status) => status === product.status) > -1 && !disabled) {
          disabled = true;
        }
      });
    }

    return disabled;
  }

  undoReinclusion = () => {
    const { id, request } = this.props;
    this.props.resetReinclusion(request, id, this.state.undoIdProduct)
  }

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  renderEditButtonDesktop = () => {
    const { produtos: { ativo }, addFcRenda, addFcDocPJ, onEdit} = this.props;
    let hasFcRenda = false
    let hasFcDocPJ = false
    let editCount = 0

    ativo.map(produto => {
      if(produto.id == 2 || produto.id == 7) {
        hasFcRenda = true
        editCount = produto.contador_edicao
      }
      if(produto.id == 6 || produto.id == 8) {
        hasFcDocPJ = true
        editCount = produto.contador_edicao
      }
    })
    const oculto = this.state.oculto
    if(oculto) {
      return(
        <Icon style={{width: 'auto'}}>
          {/* <Edit /> */}
          <span style={{fontSize: 10, color: '#152b4e', fontWeight: 400, textDecoration: 'underline', cursor: 'no-drop'}}>EDITAR</span>
        </Icon>
      )
    }
    return(
      <Icon style={{width: 'auto'}} onClick={hasFcRenda ? () => addFcRenda() : hasFcDocPJ ? () => addFcDocPJ() : onEdit(false)}>
        {/* <Edit /> */}
        <span style={{fontSize: 10, color: '#152b4e', fontWeight: 400, textDecoration: 'underline'}}>EDITAR</span>
      </Icon>
    )
  }

  renderEditButton = () => {
    const { produtos: { ativo }, addFcRenda, addFcDocPJ, onEdit, pretendentes} = this.props;
    const showEditButton = !this.activateEditButton();
    let hasFcRenda = false
    let hasFcDocPJ = false
    let editCount = 0

    ativo.map(produto => {
      if(produto.id == 2 || produto.id == 7) {
        hasFcRenda = true
        editCount = produto.contador_edicao
      }
      if(produto.id == 6 || produto.id == 8) {
        hasFcDocPJ = true
        editCount = produto.contador_edicao
      }
    })

    if(showEditButton) {
      let allOcultos = pretendentes.every(pretendente => Boolean(pretendente.oculto) === true)
      return (
        <CardButton className="botao-editar-pretendente" style={{position: 'relative', cursor: this.state.oculto ? 'no-drop': 'pointer'}}>
          {window.innerWidth <= 800 ?
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
              <DropdownToggle tag="span">
                <img src={iconDots} style={{height: 14}} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={hasFcRenda ? () => addFcRenda() : onEdit(false)}><Edit /> Editar</DropdownItem>
                {ativo[0].status == 'INCLUIDO' && !hasFcRenda ?
                  <DropdownItem onClick={() => this.callDeleteModal(ativo[0].id, ativo[0].status)}><BlueTrash /> Excluir FC REPORT</DropdownItem> : null
                }
                {hasFcRenda && (ativo[1] && ativo[1].status == 'INCLUIDO' || ativo[0].status == 'INCLUIDO') ?
                  <DropdownItem onClick={() => this.callDeleteModal(ativo[1] && ativo[1].id || ativo[0].id, ativo[1] && ativo[1].status || ativo[0].status)}><BlueTrash /> Excluir FC DOC</DropdownItem> : null
                }
                {ativo[0].status == 'CONCLUIDO' || ativo[0].status == 'EDITADO' ?
                  <DropdownItem onClick={onEdit(true)}><img src={iconReconsultarBlue} /> Reconsultar FC REPORT</DropdownItem> : null
                }
                {hasFcRenda && (ativo[1] && ativo[1].status == 'CONCLUIDO' || ativo[0].status == 'CONCLUIDO') && editCount == 0 ?
                  <DropdownItem onClick={() => this.onSelectFcRenda(editCount)}><img src={iconReconsultarBlue} /> Reconsultar FC DOC</DropdownItem> : null
                }
                {ativo[0].status == 'REINCLUIDO' ?
                  <DropdownItem onClick={() => this.callDeleteModal(ativo[0].id, ativo[0].status)}><BlueTrash /> Cancelar reconsulta</DropdownItem> : null
                }
              </DropdownMenu>
            </Dropdown> :
            this.renderEditButtonDesktop()
          }
          {this.props.index === 0 && allOcultos === false &&
            <TourTooltip
              target="tooltip-editar-pretendente"
              position="top"
            />
          }
        </CardButton>
      )
    }
    return null
  }

  renderIconEyeOpen = () => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
      </svg>
    )
  }

  renderIconEyeClosed = () => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486z"/>
        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"/>
        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708"/>
      </svg>
    )
  }

  onClickOcultarPretendente = async () => {
    const requestId = this.props.request
    const applicantId = this.props.id
    const oculto = this.state.oculto
    const data = {
      oculto: !oculto,
      cancelGoBack: true
    }
    await this.props.updateApplicantInfos(requestId, applicantId, data)
    await this.props.getRequest(requestId)
    this.setState({ oculto: !oculto })
  }

  renderButtonOcultarPretendente = () => {
    if(!this.props.login.user.usuario.master) {return}
    const { produtos: { ativo }} = this.props;
    let hasConcluido = false
    console.log( 'ativox1', ativo)
    ativo.map(produto => {
      if(produto.status === 'CONCLUIDO' || produto.status === 'EDITADO') {
        hasConcluido = true
      }
    })
    if(!hasConcluido) {return}
    const oculto = this.state.oculto
    const icon = oculto ? this.renderIconEyeClosed() : this.renderIconEyeOpen()
    return(
      <div onClick={this.onClickOcultarPretendente} style={{marginRight: 20, marginTop: 1, display: 'flex', cursor: 'pointer'}} title={oculto ? 'Exibir pretendente' : 'Ocultar pretendente'}>
        {icon}
      </div>
    )
  }

  renderAdicionalButtonPretendenteOculto = () => {
    if(this.props.login.user.usuario.master || !this.state.oculto) {return}
    const oculto = this.state.oculto
    const icon = oculto ? this.renderIconEyeClosed() : null
    return(
      <div style={{marginRight: 20, marginTop: 1, display: 'flex'}} title={oculto ? 'Pretendente ocultado' : 'Ocultar pretendente'}>
        {icon}
      </div>
    )
  }

  render() {
    console.log('propsssx', this.props)
    // const showDeleteButton = !this.activateDeleteButton();
    return (
      <Card>
        <Row>
          <ColSpaceBetween xs="12" sm="12" md="6" lg="6" xl="6">
            <PTitle>
              <BoldCards>Nome:</BoldCards> {this.renderName()}
            </PTitle>
          </ColSpaceBetween>
          <ColSpaceBetween xs="12" sm="12" md="6" lg="6" xl="6">
            <PTitle>
              <BoldCards>Pretendente a:</BoldCards> {Boolean(this.props.oculto) ? 'OCULTADO' : this.props.tipo_pretendente}
            </PTitle>
            <IconsContainer>
              {this.renderButtonOcultarPretendente()}
              {this.renderAdicionalButtonPretendenteOculto()}
              {this.renderEditButton()}
              {/* {showDeleteButton && (
                <CardButton onClick={this.props.onRemove}>
                  <Icon><BlueTrash /></Icon>
                </CardButton>
              )} */}
              

            </IconsContainer>
          </ColSpaceBetween>
        </Row>
        <StyledRow margin="10px 0 0 0" justify="flex-start">
          {this.renderProducts()}
        </StyledRow>
        <ModalDeleteProduct confirmDelete={this.deleteProduct} />
        <UndoReinclusionModal
            undo={() => this.undoReinclusion()}
            isOpen={this.state.undoModalOpen}
            onClose={() => this.setState({undoModalOpen: false})}
          />
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openModal,
  deleteProduct,
  resetReinclusion,
  updateApplicantInfos
}, dispatch)

export default connect(null, mapDispatchToProps)(ApplicantCard);
