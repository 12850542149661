import styled from 'styled-components';
import { Col } from 'reactstrap';
import card from '@bit/wavfichacerta.fichacerta.cards.card';

export const Card = styled(card)`
  @media (max-width: 800px) {
    border-left: 3px solid ${props => props.theme.color_purple};
    box-shadow: 1px 0px 4px #e0e0e0;
    padding: 7px;

    p {
      font-size: 10.5px;
    }
    b {
      text-transform: uppercase;
      font-weight: 400;
    }
  }
`;

export const ColSpaceBetween = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const PTitle = styled.p`
  font-size: 12.5px;
  color: ${props => props.theme.color_gray};
  margin-bottom: 0;
`;

export const BoldCards = styled.b`
  font-weight: 600;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 1.5rem;

  @media (max-width: 800px) {
    position: absolute;
    top: -21px;
    right: 16px;
    z-index: 1;

    .dropdown-item {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #152b4e;

      svg,
      img {
        margin: 0;
        width: 15px;
        margin-right: 4px;
        margin-top: -2px;
      }

      img {
        width: 13px;
        margin-right: 5px;
      }
    }
  }
`;

export const Icon = styled.div`
  width: 1.4rem;
  display: flex;
  align-items: center;

  & > img {
    transition: .2s;
    height: 1.1rem;
  }

  & > svg {
    transition: .2s;
    height: 1.4rem;
  }

  & > svg, polygon, path {
    fill: #152b4e;
  }

  $:hover {
    & > img {
      transform: scale(1.2);
    }
  
    & > svg {
      transform: scale(1.2);
    }
  }
`;
