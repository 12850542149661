import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PricesTable from '../../components/financial/prices/PricesTable';
import PricesMobileTable from './table/PricesMobileTable';
import { PageContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';
import Header from '../../components/Header';
import { getPrices } from './actions';

import { getClientProducts } from './../operational/request/actions';
import { StyledMainContainer } from './styled';

import Tour from 'reactour'
import axios from 'axios';

const steps = [
  {
    selector: 'table tbody',
    content: <span>Aqui você confere os produtos disponíveis para a sua imobiliária e seus respectivos valores.</span>,
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class Prices extends PureComponent {
  state = {
    isMobile: window.matchMedia('(max-width: 600px)').matches,
    tourOpen: false,
    tourId: null
  }
  
  componentDidMount(){
    this.props.getPrices()
    this.props.getClientProducts()
    this.getCheckOnboard()
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'precos') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'precos'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'precos',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  render() {
    const { isMobile } = this.state; 

    return (
      <PageContainer>
        <Header
          title="Preços"
          actualLink="Preços"
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <StyledMainContainer>
          { !isMobile ? <PricesTable
            products={this.props.products}
          /> : <PricesMobileTable products={this.props.products} />
          }
        </StyledMainContainer>
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
        />
      </PageContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ getPrices, getClientProducts }, dispatch)

const mapStateToProps = (state, ownProps) => (
  {
    ...ownProps,
    products: state.request.products.list,
    login: state.login,
    // discounts: getDiscounts(state),
    // services: getServices(state)
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(Prices);
