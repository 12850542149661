import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCustomerData, saveForm, resetData } from './actions';
import { getRequirements } from '../../form-requirements/actions';
import { getInitialData } from './selector';
import { customerDataExtractor } from '../../../utils/extractors/CustomerExtractor';

import { PageContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';
import Header from '../../../components/Header';
import CustomerForm from './CustomerForm';
import { ContainerForm, FormWrapper } from '../../../components/shared/formStyles.styled';
import { Main, MainContainer } from './../../operational/request/resume/RequestResume';

import Tour from 'reactour'
import axios from 'axios';

const steps = [
  {
    selector: '.dados-cadastrais',
    content: <span>Aqui é possível conferir os dados cadastrais da imobiliária</span>,
  },
  {
    selector: '[title="Contatos Adicionais"]',
    content: 'Caso precise atualizar os contatos das áreas, acione o nosso suporte.',
  },
  // ...
]

let API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL;

if(process.env.REACT_APP_ENV === 'prod') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'stage') {
  API_NODE_V1_URL = process.env.REACT_APP_API_NODE_V1_URL_STAG;
}

class EditCustomer extends PureComponent {

  constructor(props) {
    super(props);
    this.customerForm = React.createRef();

    this.state = {
      tourOpen: false,
      tourId: null
    }
  }

  componentDidMount() {
    this.props.getRequirements();
    this.props.getCustomerData();
    this.getCheckOnboard()
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  getCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/check'

      const data = {
        email: this.props.login.user.usuario.email
      }

      let tourOpen = false
      let hasPage = false

      const result = await axios.post(URL, data);
      console.log('resultx2', result)
      if(result.data.length) {
        result.data.map(item => {
          if(item.pagina === 'home' && item.ver_depois === 0) {
            tourOpen = true
          }

          if(item.pagina === 'dados-do-cliente') {
            hasPage = true
          }
        })
      }

      if(hasPage === false) {
        this.setState({tourOpen})
      }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCheckOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard/permission/register'

      const data = {
        email: this.props.login.user.usuario.email,
        verDepois: false,
        page: 'dados-do-cliente'
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postAfterOpenOnboard = async () => {
    try {
      const URL = API_NODE_V1_URL + '/onboard'

      const data = {
        email: this.props.login.user.usuario.email,
        page: 'dados-do-cliente',
        name: this.props.login.user.usuario.nome,
        cargo: this.props.login.user.usuario.funcao,
        customer: this.props.login.user.usuario.cliente
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      this.setState({tourId: result.data.id})

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  postCurrentStepOnboard = async (curr) => {
    // alert(`The current step is ${curr + 1}`)
    try {
      const URL = API_NODE_V1_URL + '/onboard/steps'
      const step = curr + 1

      const data = {
        tourId: this.state.tourId,
        step
      }

      const result = await axios.post(URL, data);
      console.log('resultx3', result)
      //TODO: pegar o result.data.id e salvar no state.tourId
      // if(result.data.length) {
      //   this.setState({hasRating: true}) // usuário já fez avaliação.
      // } else {
      //   this.setState({modalTourOpen: true})
      // }

    } catch (err) {
      console.log('ERR:', err)
    }
  }

  handleTourClose = () => {
    this.setState({tourOpen: false})
    this.postCheckOnboard()
  }

  submitForm = (entity, changedValues) => {
    const data = customerDataExtractor(entity, changedValues);
    this.props.saveForm(data);
  }

  renderFormByEntity = () => {
    const { data } = this.props;
    if (typeof data === "object" && Object.keys(data).length > 0) {
      const entity = ("cnpj" in data)? "PJ" : "PF";
      return (
        <FormWrapper>
          <CustomerForm
            entity={entity}
            initialValues={getInitialData(entity, data)}
            onSubmitForm={this.submitForm}
          />
        </FormWrapper>
      );
    }
    return null;
  }

  render() {
    return (
      <Main>
        <Header
          title="DADOS DO CLIENTE"
          actualLink="Dados do cliente"
          openTourCallback={(call) => this.setState({tourOpen: call})}
        />
        <MainContainer>
          <ContainerForm>
            {this.renderFormByEntity()}
          </ContainerForm>
        </MainContainer>
        <Tour
          steps={steps}
          isOpen={this.state.tourOpen}
          onRequestClose={this.handleTourClose}
          getCurrentStep={this.postCurrentStepOnboard}
          onAfterOpen={this.postAfterOpenOnboard}
        />
      </Main>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getRequirements, getCustomerData, saveForm, resetData }, dispatch);

const mapStateToProps = (state) => ({
  data: state.customerData.data.cliente,
  login: state.login,
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);
