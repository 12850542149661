
export const onSaveComercialData = (state) => ({
  ...state,
  isSaving: true,
  error: undefined
});

export const onSaveComercialDataSuccess = (state) => ({
  ...state,
  isSaving: false,
  error: false
});

export const onSaveComercialDataFailed = (state) => ({
  ...state,
  isSaving: false,
  error: true
});
