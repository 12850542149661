import React from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import RequestBadge from '../../../../components/Operational/request/RequestBadge';
import {
  ContainerRequest,
  ContainerForm
} from './requestForm.styled';

const RequestFormContainer = ({ children, renderStepButton }) => (
  <ContainerRequest editMode>
    <ContainerForm>
      <StyledRow>
        <BaseColumn md={2} xl={2} lg={2}>
          <RequestBadge color="color_orange">Laranja: obrigatório</RequestBadge>
        </BaseColumn>
        <BaseColumn>
          <RequestBadge color="color_gray">Cinza: opcional</RequestBadge>
        </BaseColumn>
      </StyledRow>

      {children}

      {renderStepButton()}
    </ContainerForm>
  </ContainerRequest>
);

export default RequestFormContainer;
