import {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILED,
  SAVE_SELECTED_CUSTOMER,
  OPEN_STATUS_MODAL,
  CLOSE_STATUS_MODAL,
  CHANGE_SORT,
  CHANGE_PAGE,
  CHANGE_RESULTS_PER_PAGE,
  CHANGE_TAB,
  SET_FILTER,
  RESET_PAGINATION
} from '../../types';

import {
  onGetClientsSuccess,
  onGetClients,
  onGetClientsFailed,
  onStatusModalOpen,
  onStatusModalClose,
  saveSelectedCustomer,
  onChangeSort,
  onChangePage,
  onChangeResultsPerPage,
  onChangeTab,
  onResetPagination,
  onSetFilter
} from './reducers';

const initialState = {
  requesting: false,
  error: undefined,
  list: [],
  pagination: {
    totalCount: undefined,
    numberOfPages: undefined,
    lastPage: undefined,
    currentPage: 1,
    resultsPerPage: localStorage.getItem("resultsPerPageCustomers") || '10',
    sortBy: 'nome',
    nextPage: '',
    previousPage: '',
    filter: '',
    type: 'confirmado'
  },
  statusModal: {
    isVisible: false,
    selectedCustomer: {}
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CLIENTS_REQUEST:
      return onGetClients(state);
    case GET_CLIENTS_SUCCESS:
      return onGetClientsSuccess(state, payload);
    case GET_CLIENTS_FAILED:
      return onGetClientsFailed(state, payload);
    case OPEN_STATUS_MODAL:
      return onStatusModalOpen(state);
    case CLOSE_STATUS_MODAL:
      return onStatusModalClose(state);
    case SAVE_SELECTED_CUSTOMER:
      return saveSelectedCustomer(state, payload);
    case CHANGE_SORT:
      return onChangeSort(state, payload);
    case CHANGE_PAGE:
      return onChangePage(state, payload);
    case CHANGE_RESULTS_PER_PAGE:
      return onChangeResultsPerPage(state, payload);
    case CHANGE_TAB:
      return onChangeTab(state, payload);
    case SET_FILTER:
      return onSetFilter(state, payload);
    case RESET_PAGINATION:
      return onResetPagination(state);
    default:
      return state;
  }
};
