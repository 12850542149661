import React from 'react'
import { StyledLabel, StyledRadio } from './Inputs.styles';
import { withTheme } from 'styled-components';

const handleInputChange = (input, value, customChange) => () => {
  if (customChange) {
    customChange(input)
  } else {
    input.onChange(value);
  }
};

const handleInputCheck = (inputValue, comparator) => {
  if (comparator !== undefined) {
    return comparator(inputValue);
  }

  return inputValue;
}

const RadioButton = ({ label, value, onChange, isRequired, disabled, id, checked, input, comparator, theme, customChange }) => (
  <div style={{ position: 'relative', marginLeft: '30px' }}>
    <StyledRadio 
      id={id}
      name={id}
      value={value}
      onChange={input ? handleInputChange(input, id, customChange) : onChange}
      checked={input && comparator ? handleInputCheck(input.value, comparator) : checked}
      required={isRequired}
      disabled={disabled || theme.disabled}
      type="radio"
    />
    <StyledLabel htmlFor={id} >
      <span style={{ marginLeft: '6px',  width: '100%' }}>
        {label || 'LABEL'}
      </span>
    </StyledLabel>
  </div>
);

RadioButton.defaultProps = {
  isRequired: false,
  disabled: false,
  comparator: () => true
};

export default withTheme(RadioButton);