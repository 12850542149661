import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${props => props.theme['color_report-loading']};
  margin: ${props => props.margin || 'auto'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${props => props.fullWidth? '99%' : '81%'};

  ${props => {
    switch (props.size) {
      case "extra-small":
        return `
        height: 7vh;
        min-height: 7vh;
        `;
      case 'small':
        return `
        height: 16vh;
        min-height: 16vh;
        `;
      case 'medium':
        return `
        height: 40vh;
        min-height: 40vh;
        `;
      case 'large':
        return `
        height: 50vh;
        min-height: 50vh;
        `;
      case 'extra-large':
        return `
        height: 100vh;
        min-height: 100vh;
        `;
      default:
        return `
        height: 40vh;
        min-height: 40vh;
        `;
    }
  }}
`;

export const ContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > svg {
    position: relative;
    top: .3em;
    margin-left: .5em;
  }
`;

export const TitleLoading = styled.span`
  font-size: 26px;
  color: ${props => props.theme['color_border-gray']};
`;

export const TextLoading = styled.p`
  width: 80%;
  padding: 1rem 3rem 0 3rem;
  margin: 0 auto;
  font-size: 13px;
  text-align: center;
  color: ${props => props.theme['color_border-gray']};
`;
