import * as types from './types';
import { toastr } from 'react-redux-toastr';
import { actionCreator, generalActionTypes, extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { postSacMessage } from '../../../../services/customer/customer';
import { reset } from 'redux-form';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const sendSacMessage = (data) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(types.CUSTOMER_SEND_MESSAGE));
    try {
      const {login: {user: { token } } } = getState();
      const payload = await postSacMessage(token.access_token, data);
      if(payload.status !== 200) {
          toastr.warning('Error', extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.CUSTOMER_SEND_FAILED));
      } else {
        toastr.success('Sucesso!', payload.data);
        dispatch(actionCreator(types.CUSTOMER_SEND_SUCCESS));
        dispatch(reset('messageForm'))
      }

    } catch (error) {
      dispatch(actionCreator(NON_HANDLED_ERROR, 'No momento não foi possivel enviar a mensagem.'))
    }
  }
}