import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { saveComercialData } from './actions';
import { getCustomerData, resetData } from '../CustomerData/actions';
import { getInitialValues } from './selector';
import { getChangedValues } from '../../../utils/formHelper';

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { PageContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';
import Header from '../../../components/Header';
import { ContainerForm, FormWrapper } from '../../../components/shared/formStyles.styled';
import InterestSection from '../../../components/Customer/ComercialData/InterestSection';
import AnalysisSection from '../../../components/Customer/ComercialData/AnalysisSection';
import InsuranceSection from '../../../components/Customer/ComercialData/InsuranceSection';
import { ButtonForm } from '../../../components/shared/formStyles.styled';
import { Main, MainContainer } from './../../operational/request/resume/RequestResume';

class ComercialDataForm extends PureComponent {

  componentDidMount() {
    this.props.getCustomerData();
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  submitForm = () => {
    const { initialValues, changedValues, saveComercialData } = this.props;
    const valuesForm = getChangedValues(initialValues, changedValues) || {};
    saveComercialData(valuesForm);
  }

  renderForm = () => {
    const { data, initialValues, changedValues, master } = this.props;
    if (data !== undefined && typeof data === "object" && !isEmpty(data)) {
      const valuesForm = getChangedValues(initialValues, changedValues) || {};
      return (
        <ContainerForm>
          <FormWrapper>
            <InterestSection initialValues={initialValues} master={master} />
            <AnalysisSection initialValues={initialValues} />
            <InsuranceSection initialValues={initialValues} />
            
            <StyledRow margin="0 0 2vh 0">
              {master && <ButtonForm disabled={Object.keys(valuesForm).length === 0} onClick={this.submitForm}>Salvar Alterações</ButtonForm>}
            </StyledRow>
          </FormWrapper>
        </ContainerForm>
      );
    }
    return null;
  }

  render() {
    return (
      <Main>
        <Header
          title="Dados comerciais"
          actualLink="Dados comerciais"
        />
        <MainContainer>
          {this.renderForm()}
        </MainContainer>
      </Main>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getCustomerData, resetData, saveComercialData }, dispatch);

const mapStateToProps = (state) => ({
  data: state.customerData.data.cliente,
  initialValues: getInitialValues(state.customerData, state.requirements),
  master: state.login.user.usuario.master,
  changedValues: state.form.comercialDataForm !== undefined? state.form.comercialDataForm.values : {}
});

const Form = reduxForm({
  form: 'comercialDataForm',
  enableReinitialize: true
})(ComercialDataForm);

export default connect(mapStateToProps, mapDispatchToProps)(Form);
