import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getRequirements, sendFileUpload, removeFileUpload, createProductFcRenda, resetForm } from '../form/actions';
import { openModal } from '../form/fieldModal/actions';
import { getApplicantInfos, updateApplicantInfos } from './actions';
import { openModal as openModalIncomeSection } from '../form/incomeModal/actions';
import FormModalFields from '../form/fieldModal/FormModalFields';
import { getSuccessStatus, getLoadingFlag, getSendingStatus } from './selectors';

import ApplicantForm from '../form/Applicant/ApplicantForm';
import applicantFields from '../form/Applicant/fields';

import locationFields from '../form/LocationSection/fields';

import IncomeFormVeredito from '../form/IncomeSection/IncomeFormVeredito';
import IncomeFormReview from './../form/IncomeSection/IncomeFormReview'
import IncomeForm from '../form/IncomeSection/IncomeForm';
import incomeFields from '../form/IncomeSection/fields';

import { PageContainer } from '@bit/wavfichacerta.fichacerta.shared-styles.containers';
import Header from '../../../../components/Header';
import { getChangedValues } from '../../../../utils/formHelper';
import { getApplicantEditObj, getApplicantPJEditObj } from '../../../../utils/operational/requests/requestFormHelper';
import { LoadingOperational } from '../../../../components/Operational/operational.styles';

import { ContainerGifLoading } from '@bit/wavfichacerta.fichacerta.tables.styles';

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { FullColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import RequestStepButton from '../form/RequestStepButton';
import Container from '../form/Container';
import BlockNavigationContainer from '@bit/wavfichacerta.fichacerta.forms.block-navigation-container';
import { SubmitButton } from '@bit/wavfichacerta.fichacerta.shared-styles.buttons';
import LoadingCircle from '@bit/wavfichacerta.fichacerta.icons.loading-circle';
import { getAllValues } from '../form/selectors';
import { Main, MainContainer } from './RequestResume';

import ModalWarnRevision from './ModalWarn'
import ModalWarnRendas from './ModalWarnRendas'
import ModalWarnRequiredAnexos from './ModalWarnRequiredAnexos'

import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';

const Forms = ["applicantForm", "incomeForm"];

const BoxOpacity = styled.div`
  position: absolute;
  background: #fff;
  top: 294px;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .8;

  @media (max-width: 800px) {
    top: ${({avaliacao_financeira}) => avaliacao_financeira == 'card' ? '375px' : '505px'};
  }
`

class FormFcAnalysisPlus extends PureComponent {

  constructor(props) {
    super(props);
    this.applicantForm = React.createRef();
    this.locationForm = React.createRef();
    this.incomeForm = React.createRef();
    this.container = React.createRef();

    this.state = {
      fcAnaliseStatus: '',
      fcRendaStatus: '',
      principalIsValid: true,
      secundarioIsValid: true,
      telContatoIsValid: true,
      countWarn: 0,
      selectedItem: {},
      desconsiderarFcRenda: null,
      desconsiderarObito: null,
      obito: null,
      desconsiderarPendenciaCPF: null,
      pendenciaCPF: null,
      desconsiderarAcoesCriminais: null,
      temAcoesCriminais: null,
      desconsiderarAlertaFraude: null,
      temAlertaFraude: null,
      openModalWarn: false,
      openModalWarnRendas: false,
      openModalWarnAnexos: false,
      confirmModalWarn: false,
      contador_edicao: null
    }
  }

  componentDidMount() {
    const { state } = this.props.location;
    const { requestId, applicantId, entity } = state;

    this.props.getApplicantInfos(requestId, applicantId);
    this.props.getRequirements(entity);
    this.handleProductStatus();
  }

  componentDidUpdate() {
    this.handleValidations()
    this.handleWarnToastr()
    this.forceChangeTel()
  }

  componentWillUnmount() {
    this.props.resetForm()
  }

  forceChangeTel = () => {
    const element = document.getElementById('tel_contato')
    if(!element) return null
    if ("createEvent" in document) {
      var evt = document.createEvent("HTMLEvents")
      evt.initEvent("change", true, true)
      element.dispatchEvent(evt)
    } else {
      element.fireEvent("onchange")
    }
  }

  disabledFormReview = () => {
    const renda_principal_review = document.getElementById('renda_principal_review')
    const origem_renda_review = document.getElementById('origem_renda_review')
    const outras_rendas_review = document.getElementById('outras_rendas_review')
    const origem_renda_secundaria_review = document.getElementById('origem_renda_secundaria_review')
    const limite_cartao_credito_review = document.getElementById('limite_cartao_credito_review')
    const numero_cartao_credito_review = document.getElementById('numero_cartao_credito_review')
    const validade_cartao_credito_review = document.getElementById('validade_cartao_credito_review')
    const bandeira_cartao_credito_review = document.getElementById('bandeira_cartao_credito_review')

    renda_principal_review && renda_principal_review.setAttribute('disabled', true)
    origem_renda_review && origem_renda_review.setAttribute('disabled', true)
    outras_rendas_review && outras_rendas_review.setAttribute('disabled', true)
    origem_renda_secundaria_review && origem_renda_secundaria_review.setAttribute('disabled', true)
    limite_cartao_credito_review && limite_cartao_credito_review.setAttribute('disabled', true)
    numero_cartao_credito_review && numero_cartao_credito_review.setAttribute('disabled', true)
    validade_cartao_credito_review && validade_cartao_credito_review.setAttribute('disabled', true)
    bandeira_cartao_credito_review && bandeira_cartao_credito_review.setAttribute('disabled', true)
  }

  removeTitleToCard = () => {
    const avalicacao_financeira = document.querySelector('[title="PERFIL FINANCEIRO POR:"]')
    const pretendente = document.querySelector('[title="Pretendente"]')
    const resultado = document.querySelector('[title="resultado"]')

    avalicacao_financeira && avalicacao_financeira.setAttribute('title', '')
    pretendente && pretendente.setAttribute('title', '')
    resultado && resultado.setAttribute('title', '')
  }

  handleValidations = () => {
    let count = 0;
    if(count < 1) {
      const {incomeForm, modulo_principal, modulo_secundario} = this.props
      let principalIsValid = true
      let secundarioIsValid = true
      let telContatoIsValid = true

      if(incomeForm.changedValues) {
        const {changedValues} = incomeForm
        if(changedValues.avaliacao_financeira == 'renda') {
          if(changedValues.origem_renda) {
            if(!changedValues.renda_principal && changedValues.renda_principal != 0) {
              principalIsValid = false
            }
          }
          if(!changedValues.origem_renda) {
            if(changedValues.renda_principal) {
              principalIsValid = false
            }
            if(!changedValues.origem_renda_secundaria) {
              principalIsValid = false
            }
          }
          if(changedValues.origem_renda_secundaria) {
            if(!changedValues.outras_rendas) {
              secundarioIsValid = false
            }
          }
          if(!changedValues.origem_renda_secundaria) {
            if(changedValues.outras_rendas) {
              secundarioIsValid = false
            }
          }
        } else if(changedValues.avaliacao_financeira == 'card') {
          if(!changedValues.limite_cartao_credito && changedValues.limite_cartao_credito != 0) {
            principalIsValid = false
          }
          if(changedValues.limite_cartao_credito) {
          }
        }

        if(document.getElementById('tel_contato')) {
          document.getElementById('tel_contato').parentElement.parentElement.style.borderBottom = '1px solid #37c120'
        }
        if((!changedValues.tel_contato) || (changedValues.tel_contato && changedValues.tel_contato.length < 10)) {
          telContatoIsValid = false
          if(document.getElementById('tel_contato')) {
            document.getElementById('tel_contato').parentElement.parentElement.style.borderBottom = '1px solid #d80027'
          }
        }
      }
      this.setState({principalIsValid, secundarioIsValid, telContatoIsValid})
      count++
    }
  }

  handleWarnToastr = () => {
    if(this.state.countWarn != 0) return null
    const {requestResume, location} = this.props
    requestResume.currentApplicant && requestResume.currentApplicant.pretendente[0].produtos.ativo.map(produto => {
      if(produto.id == 2 && produto.contador_edicao === 0 && !location.state.reconsulta) {
        toastr.warning("ATENÇÃO!", 'Não é mais possível incluir novos arquivos para o FC DOC. Para nova análise, solicitar Reconsulta.');
        this.setState({countWarn: 1})
      }
      if(produto.id == 2) {
        this.setState({contador_edicao: produto.contador_edicao})
      }
    })
  }

  handleProductStatus = () => {
    const { state } = this.props.location;
    let fcAnaliseStatus = ''
    let fcRendaStatus = ''
    state.products.ativo.map(product => {
      if(product.id == 1) {
        fcAnaliseStatus = product.status
      }
      if(product.id == 2) {
        fcRendaStatus = product.status
      }
    })
    this.setState({fcAnaliseStatus, fcRendaStatus})
  }

  changedValues = () => {
    const changed = Forms.reduce((acc, form) => {
      if (this.props[form]) {
        const { changedValues, initialValues } = this.props[form];
        const values = getChangedValues(initialValues, changedValues);
        if (values === null) {
          return acc;
        }

        return {
          ...acc,
          [form]: values
        };
      }

      return acc;
    }, {});
    return changed;
  }

  checkTypeIncome = () => {
    const {incomeForm: {changedValues}} = this.props
    if (changedValues !== undefined) {
      if ("renda_principal" in changedValues || "outras_rendas" in changedValues || "origem_renda" in changedValues || "origem_renda_secundaria" in changedValues || "avaliacao_financeira" in changedValues) {
        const { renda_principal, outras_rendas, origem_renda, origem_renda_secundaria, avaliacao_financeira, limite_cartao_credito, movimentacao_bancaria_extratos } = changedValues;
        if(avaliacao_financeira == 'renda') {
          if (renda_principal > 0 && ((origem_renda !== undefined && origem_renda.toString() === "") || origem_renda === undefined)) {
            return false;
          }else if((origem_renda && origem_renda.toString() !== "1") && !renda_principal){
            return false
          }

          if(origem_renda == 1 && renda_principal > 0) {
            return false
          }

          if (outras_rendas > 0 && ((origem_renda_secundaria !== undefined && origem_renda_secundaria.toString() === "") || origem_renda_secundaria === undefined)) {
            return false;
          }else if((origem_renda_secundaria && origem_renda_secundaria.toString() !== "1") && !outras_rendas){
            return false
          }
        }

        if (avaliacao_financeira == 'card' && ((limite_cartao_credito !== undefined && limite_cartao_credito.toString() === "") || limite_cartao_credito === undefined)) {
          return false;
        }else if(avaliacao_financeira == 'card' && ((limite_cartao_credito == 0) || isNaN(limite_cartao_credito))){
          return false
        }

        if (avaliacao_financeira == 'extratos' && ((movimentacao_bancaria_extratos !== undefined && movimentacao_bancaria_extratos.toString() === "") || movimentacao_bancaria_extratos === undefined)) {
          return false;
        }else if(avaliacao_financeira == 'extratos' && ((movimentacao_bancaria_extratos == 0) || isNaN(movimentacao_bancaria_extratos))){
          return false
        }
      }
    }
    return true;
  }

  checkTemAnexoSemAnalise = () => {
    const { requestResume, modulo_principal, modulo_secundario } = this.props
    let temAnexoSemAnalise = false
    if(requestResume.currentApplicant) {
      modulo_principal.map(documento => {
        if(!Number.isInteger(documento.analise)) {
          temAnexoSemAnalise = true
        }
      })
      modulo_secundario.map(documento => {
        if(!Number.isInteger(documento.analise)) {
          temAnexoSemAnalise = true
        }
      })
    }
    return temAnexoSemAnalise;
  }

  handleConfirmModalWarn = () => {
    this.setState({confirmModalWarn: true}, () => {
      this.updateApplicant()
      this.handleToggleModalWarn()
    })
    
  }

  handleToggleModalWarn = () => {
    this.setState({openModalWarn: !this.state.openModalWarn})
  }
  
  handleToggleModalWarnRendas = () => {
    this.setState({openModalWarnRendas: !this.state.openModalWarnRendas})
  }

  handleToggleModalWarnAnexos = () => {
    this.setState({openModalWarnAnexos: !this.state.openModalWarnAnexos})
  }

  updateApplicant = () => {
    if (this.checkTypeIncome()) {
      const { requestId, applicantId, entity, reconsulta } = this.props.location.state;
      const { applicantForm } = this.props
      let pretendente = {}
      let configuracao = {}
      let fcRendaAtivo = null
      let obito = undefined
      let pendenciaCPF = undefined
      let temAcoesCriminais = undefined
      let temAlertaFraude = undefined
      
      if (this.checkTemAnexoSemAnalise() && !this.state.confirmModalWarn && this.state.fcRendaStatus != 'INCLUIDO' && !reconsulta && this.state.fcRendaStatus != 'REINCLUIDO') {
        this.setState({openModalWarn: true})
        return;
      }

      if(reconsulta && !this.checkTemAnexoSemAnalise()) {
        this.setState({openModalWarnAnexos: true})
        return;
      }

      this.props.requestResume.currentApplicant && this.props.requestResume.currentApplicant.pretendente[0].produtos.ativo.map(product => {
        if(product.id == 2) {
          fcRendaAtivo = product.ativo
        }
      })

      this.container.current.unBlock();
      const applicant = (entity === "PF")? getApplicantEditObj({...this.changedValues(), ...this.props.incomeForm}) : getApplicantPJEditObj(this.changedValues);

      if (applicant.hasOwnProperty('renda') && !this.state.confirmModalWarn && !this.checkTemAnexoSemAnalise() && this.state.fcRendaStatus != 'INCLUIDO' && this.state.fcRendaStatus != 'REINCLUIDO') {
        if(this.state.desconsiderarFcRenda == false || fcRendaAtivo == true && (this.state.desconsiderarFcRenda == false || this.state.desconsiderarFcRenda == null)) {
          if(applicant.renda && Object.keys(applicant.renda).length > 0) {
            this.setState({openModalWarnRendas: true})
            return;
          }
        }
      }

      for (const key in applicant) {
        if (applicant.hasOwnProperty(key)) {
          console.log('applicant1', applicant)
          if(applicant.renda && Object.keys(applicant.renda).length > 0) {
            pretendente = {
              pretendente: {
                ...pretendente.pretendente,
                renda: {...applicant.renda}
              }
            }
          }
          if(key != 'renda' && applicant[key] != undefined) {
            pretendente = {
              pretendente: {
                ...pretendente.pretendente,
              }
            }
            pretendente['pretendente'][key] = applicant[key]
          }
        }
      }

      if(this.state.desconsiderarFcRenda != null) {
        configuracao = {
          configuracao: {
            produtos: [{
              id: 2,
              ativo: !this.state.desconsiderarFcRenda
            }]
          }
        }
      }

      if(applicantForm.initialValues) {
        if(this.state.obito !== null && applicantForm.initialValues.suspeita_obito !== this.state.obito) {
          obito = this.state.obito
          pretendente = {
            pretendente: {
              ...pretendente.pretendente,
              suspeita_obito: obito
            }
          }
        }
      }

      if(applicantForm.initialValues) {
        if(this.state.pendenciaCPF !== null && applicantForm.initialValues.cpf_pendente !== this.state.pendenciaCPF) {
          pendenciaCPF = this.state.pendenciaCPF
          pretendente = {
            pretendente: {
              ...pretendente.pretendente,
              cpf_pendente: pendenciaCPF
            }
          }
        }

        if(this.state.temAcoesCriminais !== null && applicantForm.initialValues.tem_acoes_criminais !== this.state.temAcoesCriminais) {
          temAcoesCriminais = this.state.temAcoesCriminais
          pretendente = {
            pretendente: {
              ...pretendente.pretendente,
              tem_acoes_criminais: temAcoesCriminais
            }
          }
        }

        if(this.state.temAlertaFraude !== null && applicantForm.initialValues.tem_alerta_fraude !== this.state.temAlertaFraude) {
          temAlertaFraude = this.state.temAlertaFraude
          pretendente = {
            pretendente: {
              ...pretendente.pretendente,
              tem_alerta_fraude: temAlertaFraude
            }
          }
        }
      }

      this.props.updateApplicantInfos(
        requestId,
        applicantId,
        {
          ...pretendente,
          ...configuracao,
          reconsulta: reconsulta && 2 || undefined
        }
      );
    } else {
      this.props.openModalIncomeSection();
    }
  }


  renderStepButton = () => {
    const { isLoading, success, sending, requestResume } = this.props;
    const reInclusion = this.props.state && this.props.state.reInclusion || null;
    const requestId = this.props.location.state && this.props.location.state.requestId || null;
    const applicantId = this.props.location.state && this.props.location.state.applicantId || null;
    const entity = this.props.state && this.props.state.entity || null;
    const applicants = this.props.location.state.applicants
    const currentApplicant = applicants.filter(item => item.id === applicantId)
    console.log('lala1', this.props)
    console.log('lala2', this.state)
    let moveForLocation = false;
    // Caso seja um fc basico inicialmente e está sendo adicionado fc essencial/report e não tem os dados de locação preenchidos.
    if(!this.props.location.state.locacao.tipo_imovel) {
      moveForLocation = true
    }
    console.log('moveForLocation', moveForLocation)
    const applicant = (entity === "PF")? getApplicantEditObj(this.changedValues) : getApplicantPJEditObj(this.changedValues);
    console.log('pox1', currentApplicant)
    if(currentApplicant.length) {
      const productFcBasico = currentApplicant[0].produtos.ativo.filter(product => product.id === 5 && product.status === 'INCLUIDO')
      if(productFcBasico.length) {
        moveForLocation = false;
      }
    }
    if (!isLoading && success && !moveForLocation) {
      return(
        <StyledRow justify="center" padding="1rem 0">
          <SubmitButton 
            onClick={() => this.props.history.push({
              pathname: '/intranet/operacional/solicitacao/resumo',
              state: { request: requestId, applicant, applicantId }
            })}
            customstyle={{ color: 'white', marginRight: '1rem' }}>
            Cancelar
          </SubmitButton>
          <SubmitButton color="color_white" onClick={this.handleCreateProductFcRenda} disabled={this.state.principalIsValid && this.state.secundarioIsValid ? false : true}>
            Avançar
          </SubmitButton>
        </StyledRow>
      )
    }else if(moveForLocation){
      const applicant = (entity === "PF")? getApplicantEditObj(this.changedValues) : getApplicantPJEditObj(this.changedValues);
      return (
        <StyledRow justify="center" padding="1rem 0">
          <SubmitButton 
            onClick={() => this.props.history.push({
              pathname: '/intranet/operacional/solicitacao/resumo',
              state: { request: requestId, applicant, applicantId }
            })}
            customstyle={{ color: 'white', marginRight: '1rem' }}>
            Cancelar
          </SubmitButton>
          <SubmitButton color="color_white" disabled={this.state.principalIsValid && this.state.secundarioIsValid ? false : true} onClick={() => this.handleRedirectLocation()} >
            Avançar
          </SubmitButton>
        </StyledRow>
      )
    }

    return null;
  }

  handleSelectedResult = (value) => {
    this.setState({selectedItem: value})
  }

  handleDescritionResult = (value) => {
    this.setState({descritionResult: value})
  }

  handleContent = () => {
    const { isLoading, success, requestResume } = this.props;
    const { state } = this.props.location;
    let fcRendaStatus = null
    let fcRendaAtivo = null
    let suspeitaObito = null
    let pendenciaCPF = null
    let temAcoesCriminais = null
    let temAlertaFraude = null
    
    if(requestResume.currentApplicant) {
      requestResume.currentApplicant.pretendente[0].produtos.ativo.map(product => {
        if(product.id == 2) {
          fcRendaStatus = product.status
          fcRendaAtivo = product.ativo
        }
      })

      suspeitaObito = requestResume.currentApplicant.pretendente[0].suspeita_obito
      pendenciaCPF = requestResume.currentApplicant.pretendente[0].cpf_pendente
      temAcoesCriminais = requestResume.currentApplicant.pretendente[0].tem_acoes_criminais
      temAlertaFraude = requestResume.currentApplicant.pretendente[0].tem_alerta_fraude
    }

    console.log('vish1', this.props)
    console.log('vish2', this.state)

    if (isLoading) {
      return (
        <LoadingOperational>
          <ContainerGifLoading>
            <LoadingCircle />
          </ContainerGifLoading>
        </LoadingOperational>
      );
    }

    if (success) {
      return (
        <Fragment>
          {fcRendaStatus != 'INCLUIDO' && fcRendaStatus != null && // Modulo de Analise, aparece somente se houver produto Renda com status diferente de INCLUIDO
            <div style={{position: 'relative'}}>
              <ApplicantForm editMode ref={this.applicantForm} entity={state.entity} products={[1]} isEditable={state.products.ativo[0].status !== "INCLUIDO"} suspeitaObito={suspeitaObito} onChangeCheckObito={(value) => this.setState({obito: value})}  pendenciaCPF={pendenciaCPF} onChangeCheckPendenciaCPF={(value) => this.setState({pendenciaCPF: value})} temAcoesCriminais={temAcoesCriminais} onChangeCheckAcoesCriminais={(value) => this.setState({temAcoesCriminais: value})} temAlertaFraude={temAlertaFraude} onChangeCheckAlertaFraude={(value) => this.setState({temAlertaFraude: value})}/>
              <IncomeForm editMode reconsultaFcRenda={state.reconsulta} desconsiderarFcRenda={fcRendaAtivo} ref={this.incomeForm} entity={state.entity} products={[1]} sendFileUpload={this.props.sendFileUpload} onChangeCheckRenda={(value) => this.setState({desconsiderarFcRenda: value})} removeFileUpload={this.props.removeFileUpload} location={this.props.location} principalIsValid={this.state.principalIsValid} secundarioIsValid={this.state.secundarioIsValid} />
              <div style={{position: 'relative'}}>
                <IncomeFormReview editMode label='FC DOC' ref={this.incomeForm} entity={state.entity} products={[1]} />
                <IncomeFormVeredito handleSelectedResult={this.handleSelectedResult} handleDescritionResult={this.handleDescritionResult} editMode cardTitle='resultado fc doc' ref={this.incomeForm} entity={state.entity} products={[1]} />
                <div style={{background: '#fff', position: 'absolute', width: '100%', height: '100%', opacity: .6, top: 0}}></div>
              </div>

              {(fcRendaStatus == 'SOLICITADO' || fcRendaStatus == 'ANDAMENTO' || fcRendaStatus == 'PENDENTE') &&
                <Fragment>
                  <div style={{position: 'absolute', background: '#fff', height: 230, left: 0, right: 0, bottom: 0, opacity: .8}}></div>
                  <p
                    style={{marginLeft: '50%', transform: 'translate(-50%, 0)', position: 'absolute', bottom: 84, color: '#e04f5f', fontWeight: 500, textAlign: 'center', width: '100%', fontSize: 12}}
                  >
                    Essas informações estarão disponíveis após a análise da documentação enviada.
                  </p>
                </Fragment>
              }

              {(this.state.desconsiderarFcRenda || !fcRendaAtivo && this.state.desconsiderarFcRenda == null) &&
                <Fragment>
                  <BoxOpacity avaliacao_financeira={this.props.incomeForm.changedValues && this.props.incomeForm.changedValues.avaliacao_financeira}></BoxOpacity>
                </Fragment>
              }
            </div>
          }

          {(fcRendaStatus == 'INCLUIDO' || fcRendaStatus == null) && // Modulo de Anexo, aparece somente se houver produto Renda com status igual a INCLUIDO
            <div style={{position: 'relative'}}>
              <ApplicantForm editMode ref={this.applicantForm} entity={state.entity} products={[1]} isEditable={state.products.ativo[0].status !== "INCLUIDO"} suspeitaObito={fcRendaStatus == null ? null : suspeitaObito} onChangeCheckObito={(value) => this.setState({obito: value}) } pendenciaCPF={fcRendaStatus == null ? null : pendenciaCPF} onChangeCheckPendenciaCPF={(value) => this.setState({pendenciaCPF: value}) } temAcoesCriminais={fcRendaStatus == null ? null : temAcoesCriminais} onChangeCheckAcoesCriminais={(value) => this.setState({temAcoesCriminais: value}) } temAlertaFraude={fcRendaStatus == null ? null : temAlertaFraude} onChangeCheckAlertaFraude={(value) => this.setState({temAlertaFraude: value}) }/>
              <IncomeForm editMode ref={this.incomeForm} entity={state.entity} products={[1]} sendFileUpload={this.props.sendFileUpload} removeFileUpload={this.props.removeFileUpload} location={this.props.location} principalIsValid={this.state.principalIsValid} secundarioIsValid={this.state.secundarioIsValid} isFcBasico={true} />
            </div>
          }
          
        </Fragment>
      );
    }

    return (
      <StyledRow align="center" justify="center">
        <FullColumn>
          <h5>
            Não foi possivel carregar esse pretendente.<br />
            Por Favor, entre em contato com a FC ANALISE!
          </h5>
        </FullColumn>
      </StyledRow>
    );
  }

  areThereChangedValues = () => {
    return Object.keys(this.changedValues).length === 0;
  }

  handleCreateProductFcRenda = () => {
    if (this.checkTypeIncome()) {
      const {incomeForm, applicantForm} = this.props
      const id_solicitacao = this.props.location.state.requestId
      const id_pretendente = this.props.location.state.applicantId
      const productId = this.props.location.state.product
      const changedValues = this.changedValues()
      let pretendente = {}

      if('applicantForm' in changedValues) {
        pretendente = {
          pretendente: {
            ...changedValues.applicantForm
          }
        }
      }

      if('tel_contato' in incomeForm.changedValues) {
        const ddd = incomeForm.changedValues.tel_contato.substr(0, 2)
        const tel = incomeForm.changedValues.tel_contato.substr(2)
        if('pretendente' in pretendente) {
          pretendente = {
            pretendente: {
              ...pretendente.pretendente,
              ddd,
              tel_contato: tel
            }
          }
        } else {
          pretendente = {
            pretendente: {
              ddd,
              tel_contato: tel
            }
          }
        }
      }

      // objeto padrão de renda
      let objectRenda = {
        principal: {
          origem: incomeForm.changedValues.origem_renda,
          valor: incomeForm.changedValues.renda_principal
        },
        outra: {
          origem: incomeForm.changedValues.origem_renda_secundaria,
          valor: incomeForm.changedValues.outras_rendas
        }
      }

      // aqui verifico se foi escolhido perfil financeiro por limite de cartão de crédito ou extratos bancários
      if('incomeForm' in changedValues && 'avaliacao_financeira' in changedValues.incomeForm) {
        switch(changedValues.incomeForm.avaliacao_financeira) {
          case 'card':
            objectRenda = {
              principal: {
                origem: 13,
                valor: changedValues.incomeForm.limite_cartao_credito
              }
            }
            break;
          case 'extratos':
            objectRenda = {
              principal: {
                origem: 15,
                valor: changedValues.incomeForm.movimentacao_bancaria_extratos
              }
            }
            break;
        }
      }

      const data = {
        ...pretendente,
        renda: objectRenda
      }
      this.props.createProductFcRenda(data, id_solicitacao, id_pretendente, productId)
    } else {
      this.props.openModalIncomeSection();
    }
  }

  handleRedirectLocation = () => {
    const requestId = this.props.location.state && this.props.location.state.requestId || null;
    const applicantId = this.props.state && this.props.state.applicantId || null;
    const entity = this.props.state && this.props.state.entity || null;
    const applicants = this.props.location.state.applicants

    let moveForLocation = false;
    // Caso seja um fc basico inicialmente e está sendo adicionado fc essencial/report e não tem os dados de locação preenchidos.
    if(!this.props.location.state.locacao.tipo_imovel) {
      moveForLocation = true
    }
    const applicant = (entity === "PF")? getApplicantEditObj(this.changedValues) : getApplicantPJEditObj(this.changedValues);

    if (this.checkTypeIncome()) {
      const {incomeForm, applicantForm} = this.props
      const id_solicitacao = this.props.location.state.requestId
      const id_pretendente = this.props.location.state.applicantId
      const productId = this.props.location.state.product
      const changedValues = this.changedValues()
      let pretendente = {}

      if('applicantForm' in changedValues) {
        pretendente = {
          pretendente: {
            ...changedValues.applicantForm
          }
        }
      }

      if('tel_contato' in incomeForm.changedValues) {
        const ddd = incomeForm.changedValues.tel_contato.substr(0, 2)
        const tel = incomeForm.changedValues.tel_contato.substr(2)
        if('pretendente' in pretendente) {
          pretendente = {
            pretendente: {
              ...pretendente.pretendente,
              ddd,
              tel_contato: tel
            }
          }
        } else {
          pretendente = {
            pretendente: {
              ddd,
              tel_contato: tel
            }
          }
        }
      }

      // objeto padrão de renda
      let objectRenda = {
        principal: {
          origem: incomeForm.changedValues.origem_renda,
          valor: incomeForm.changedValues.renda_principal
        },
        outra: {
          origem: incomeForm.changedValues.origem_renda_secundaria,
          valor: incomeForm.changedValues.outras_rendas
        }
      }

      // aqui verifico se foi escolhido perfil financeiro por limite de cartão de crédito ou extratos bancários
      if('incomeForm' in changedValues && 'avaliacao_financeira' in changedValues.incomeForm) {
        switch(changedValues.incomeForm.avaliacao_financeira) {
          case 'card':
            objectRenda = {
              principal: {
                origem: 13,
                valor: changedValues.incomeForm.limite_cartao_credito
              }
            }
            break;
          case 'extratos':
            objectRenda = {
              principal: {
                origem: 15,
                valor: changedValues.incomeForm.movimentacao_bancaria_extratos
              }
            }
            break;
        }
      }

      const data = {
        ...pretendente,
        renda: objectRenda
      }
      // this.props.createProductFcRenda(data, id_solicitacao, id_pretendente, productId)

      this.props.history.push({
        pathname: '/intranet/operacional/solicitacao/resumo/locacao',
        state: { request: requestId, applicant, applicantId: id_pretendente, applicants, productId, moveForLocation, data }
      })
    } else {
      this.props.openModalIncomeSection();
    }
  }

  render() {
    const breadcrumb = [
      { link: '', label: 'operacional'},
      { link: '/intranet/operacional/solicitacao', label: 'Locações' },
      {
        link: {
          pathname: '/intranet/operacional/solicitacao/resumo',
          state: {request: this.props.location.state && this.props.location.state.requestId}
        },
        label: 'resumo'
      }
    ];

    this.disabledFormReview()
    this.removeTitleToCard()

    return (
      <Main>
        <Header
          title="FORMULÁRIO"
          actualLink="Editar"
          breadcrumb={breadcrumb}
          subtitle={this.props.customer}
        />

        <MainContainer>
          <BlockNavigationContainer
            ref={this.container}
            history={this.props.history}
            blockNavigation={this.areThereChangedValues}
          >
            <Container renderStepButton={this.renderStepButton}>
              {this.handleContent()}
            </Container>
          </BlockNavigationContainer>
          <FormModalFields />
          <ModalWarnRevision
            isOpen={this.state.openModalWarn}
            onClose={this.handleToggleModalWarn}
            undo={this.handleConfirmModalWarn}
            contador_edicao={this.state.contador_edicao}
          />
          <ModalWarnRendas
            isOpen={this.state.openModalWarnRendas}
            onClose={this.handleToggleModalWarnRendas}
            // undo={this.handleConfirmModalWarn}
          />
          <ModalWarnRequiredAnexos
            isOpen={this.state.openModalWarnAnexos}
            onClose={this.handleToggleModalWarnAnexos}
            // undo={this.handleConfirmModalWarn}
          />
        </MainContainer>
      </Main>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getRequirements,
  openModal,
  getApplicantInfos,
  updateApplicantInfos,
  openModalIncomeSection,
  sendFileUpload,
  removeFileUpload,
  createProductFcRenda,
  resetForm
}, dispatch);

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isLoading: getLoadingFlag(state),
    success: getSuccessStatus(state),
    sending: getSendingStatus(state),
    requestResume: state.requestResume,
    incomeForm: getAllValues(state, "incomeForm"),
    applicantForm: getAllValues(state, "applicantForm"),
    modulo_principal: state.requestForm.modulo_principal,
    modulo_secundario: state.requestForm.modulo_secundario,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormFcAnalysisPlus);
