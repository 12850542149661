import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
  margin: auto;
`;

export const TitleModal = styled.h5`
  color: ${props => props.theme.color_purple};
`;

export const TextModal = styled.p`
  font-size: 14px;
  color: ${props => props.theme.color_gray};
  text-align: center;
`;

export const ContainerButton = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: center;

  & > button { position: fixed; }
`;
