import BrandFichaCerta from './svg/BrandFichaCerta';
import DrawerIcon from './svg/DrawerIcon';
import Register from './svg/Register';
import Services from './svg/Services';
import Financial from './svg/Financial';
import Operational from './svg/Operational';
import Report from './svg/Report';
import Security from './svg/Security';
import Atendimento from './svg/Atendimento';
import WPP from './svg/WPP';
import Telefone from './svg/Telefone';
import Email from './svg/Email';
import UserIcon from './svg/UserIcon';
import BackArrow from './svg/BackArrow';

export default {
  BrandFichaCerta,
  BackArrow,
  DrawerIcon,
  Cadastro: Register,
  Servicos: Services,
  Consumo: Financial,
  Operacional: Operational,
  Relatorios: Report,
  Seguranca: Security,
  Suporte: Atendimento,
  Garantias: Report,
  WPP,
  Telefone,
  Email,
  UserIcon
};
