import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  getFCFiancaRequests,
  changePage,
  changePerPage,
  onChangeSort
} from "./actions";
import { setFilter } from "./actions";
import { getFCFiancaRequestsList, getLoadingStatus } from "./selector";

import { BaseColumn } from "@bit/wavfichacerta.fichacerta.shared-styles.columns";
import {
  StyledCard,
  StyledRowMobile,
  StyledLineMobile,
  StyledTitleMobile,
  StyledCardBody
} from "./styled";
import PaginationFooter from "../../../components/shared/table/PaginationFooter";
import { formatAddress, formatDateToBR } from '../../../utils/formatter';
import { StyledHr } from "../../../components/Operational/AnalythicReport/TradeFinancialSection/styled";

class FcFiancaMobileTable extends PureComponent {
  state = {
    current: "id",
    sort: "ASC"
  };

  componentDidMount() {
    const {complete} = this.props;
    this.props.setFilter("");
    this.props.getFCFiancaRequests(complete);
  }

  onChangePage = page => {
    const {complete} = this.props;
    this.props.changePage(page);
    this.props.getFCFiancaRequests(complete);
  };

  onChangeResultsperPage = perPage => {
    const {complete} = this.props;
    this.props.changePerPage(perPage);
    localStorage.setItem("resultsPerPageRequests", perPage);
    this.props.getFCFiancaRequests(complete);
  };

  changeCurrentSort = name => {
    const { current, sort } = this.state;
    const {complete} = this.props;
    let newSort = "";
    if (current === name) {
      newSort = sort === "ASC" ? "DESC" : "ASC";
    } else {
      newSort = "ASC";
    }
    this.setState({ current: name, sort: newSort }, () => {
      const apiSort = newSort === "DESC" ? `-${name}` : name;
      this.props.onChangeSort(apiSort);
      this.props.getFCFiancaRequests(complete);
    });
  };

  getAdress = (props) => {
    const endereco = {
      bairro: props.bairro,
      cidade: props.cidade,
      cep: props.cep,
      complemento: props.complemento,
      logradouro: props.logradouro,
      numero: props.numero
    }

    if( endereco ){
      return `${formatAddress(endereco)}`
    }else{
      return "-"
    }
  }

  renderLine = (lines, index) => {
    return lines.map(line => {
      const onGoToResume = () => {
        this.props.setFilter("");
        this.props.history.push({
          pathname: "/intranet/operacional/solicitacao/resumo",
          state: { request: line.id }
        });
      };
      const adress = this.getAdress(line);
      console.log('line1', line)
      return (
        <StyledCard onClick={onGoToResume}>
          <StyledCardBody>
            <StyledRowMobile>
              <BaseColumn xs="4" sm="4">
                <StyledTitleMobile>ID</StyledTitleMobile>
                <StyledLineMobile>{line.id}</StyledLineMobile>
              </BaseColumn>
              <BaseColumn xs="8" sm="8">
                <StyledTitleMobile>Endereço</StyledTitleMobile>
                <StyledLineMobile>{adress}</StyledLineMobile>
              </BaseColumn>
            </StyledRowMobile>
            <StyledHr />
            <StyledRowMobile>
              <BaseColumn xs="8" sm="8">
                <StyledTitleMobile>Status</StyledTitleMobile>
                <StyledLineMobile>{line.status}</StyledLineMobile>
              </BaseColumn>
              <BaseColumn xs="4" sm="4">
                <StyledTitleMobile>Data de Inclusão</StyledTitleMobile>
                <StyledLineMobile>{formatDateToBR(line.created_at, true)}</StyledLineMobile>
              </BaseColumn>
              {/* <BaseColumn xs="8" sm="8">
                <StyledTitleMobile>Pretendente</StyledTitleMobile>
                <StyledLineMobile>{line.pretendentes.map((pretendente, index) => {
                  if(pretendente.nome === ''){
                    return "Nome não Informado; ";
                  }
                  if(line.pretendentes.length - 1 === index){
                    return pretendente.nome
                  }
                  return `${pretendente.nome}; `
                })}</StyledLineMobile>
              </BaseColumn> */}
            </StyledRowMobile>
          </StyledCardBody>
        </StyledCard>
      );
    });
  };

  render() {
    const { lines } = this.props;
    return (
      <Fragment>
        {this.renderLine(lines)}
        {this.props.lines.length <= 0 && !this.props.requesting &&
          <div style={{textAlign: 'center', color: '#8000807a', padding: 12, borderBottom: '2px solid #7f7f7f', borderTop: '2px solid #7f7f7f'}}>
            <b>Não há registros nessa lista!</b>
          </div>
        }
        <PaginationFooter
          pagination={this.props.pagination}
          changePage={this.onChangePage}
          changeMaxResults={this.onChangeResultsperPage}
          callToAction={this.props.callToAction}
          allowMaxPerPage={true}
          customstyleContainer={{padding: '.5rem 0'}}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getFCFiancaRequests, changePage, changePerPage, onChangeSort, setFilter },
    dispatch
  );

const mapStateToProps = state => ({
  lines: getFCFiancaRequestsList(state),
  requesting: getLoadingStatus(state),
  pagination: state.fcfiancaTable.pagination
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FcFiancaMobileTable)
);
