import {
  GET_CUSTOMER_PRODUCTS,
  GET_CUSTOMER_PRODUCTS_FAILED,
  GET_CUSTOMER_PRODUCTS_SUCCESS
} from '../actions/types';

import {
  onGetCustomerProductsRequest,
  onGetCustomerProductsFailed,
  onGetCustomerProductsSuccess
} from './reducers';

const initialState = {
  products: {
    isLoading: false,
    error: undefined,
    list: []
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CUSTOMER_PRODUCTS:
      return onGetCustomerProductsRequest(state);
    case GET_CUSTOMER_PRODUCTS_FAILED:
      return onGetCustomerProductsFailed(state);
    case GET_CUSTOMER_PRODUCTS_SUCCESS:
      return onGetCustomerProductsSuccess(state, payload);
    default:
      return state;
  }
};
