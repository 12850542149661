import React from 'react';
import {
  IdTh,
  CustomersTh,
  NameTh,
  AdressTh,
  DateTh
} from './table/requestTable.styled';
import OrderContainer from '@bit/wavfichacerta.fichacerta.tables.order-container'

export default ({current, sort, sortHandler}) => (
  <tr>
    <IdTh>
      <OrderContainer tag="id" current={current} sort={sort} changeCurrent={sortHandler}>ID-FC</OrderContainer>
    </IdTh>
    <CustomersTh>
      <OrderContainer tag="cliente" current={current} sort={sort} changeCurrent={sortHandler}>Cliente</OrderContainer>  
    </CustomersTh>
    <DateTh>
      <OrderContainer tag="cod_imovel" current={current} sort={sort} changeCurrent={sortHandler}>CÓD. IMÓVEL</OrderContainer>
    </DateTh>
    <AdressTh>
      Endereço
    </AdressTh>
    <NameTh>
      Pretendentes
    </NameTh>
    <DateTh>
      <OrderContainer tag="data" current={current} sort={sort} changeCurrent={sortHandler}>Data de Inclusão</OrderContainer>
    </DateTh>
  </tr>
);