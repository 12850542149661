import React from 'react';
import moment from 'moment';

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import { SubtitleNoMargin, CardTextNoMargin, TextContainer } from './operational.styles';
import { Container, VerticalLine } from './reportHeader.styled';
import ContainerLoading from './ContainerLoading';
import { formatCPF, formatCNPJ, formatToBRL } from '../../utils/formatter';

const getAddress = (location) => {
  let finalAddress = "";
  if (location !== undefined) {
    const { endereco, codigo_imovel } = location;
    if (endereco !== null && typeof endereco === "object" && Object.keys(endereco).length > 0) {
      const address = endereco[0];
      finalAddress += (address.logradouro !== null)? address.logradouro : "";
      finalAddress += (address.numero !== null)? `, ${address.numero}` : "";
      finalAddress += (address.complemento !== null)? ` - ${address.complemento}` : "";

      if(address.bairro || address.cidade) {
        finalAddress += ' -'
        finalAddress += (address.bairro !== null)? ` ${address.bairro}` : "";
        finalAddress += (address.cidade !== null)? ` ${address.cidade}` : "";
      }

      if(address.uf) {
        finalAddress += (address.uf !== null)? ` - ${address.uf}` : "";
      }
    }

    if (codigo_imovel !== undefined && codigo_imovel !== null && typeof codigo_imovel === "string" && codigo_imovel.length > 0) {
      finalAddress += ` | Código: ${codigo_imovel}`;
    }
  }

  return finalAddress;
}

const getDate = (date) => {
  if (date !== undefined && date !== null && date !== "") {
    return moment(date).format('DD/MM/YYYY (H:mm)');
  }
  return "00/00/0000 [00:00]";
}

const ReportHeader = (props) => {
  if (props.loading) {
    return (
      <ContainerLoading
        size="extra-small"
        showFirstIcon={true}
        showSecondIcon={false}
        fullWidth={true}
        showText={false}
        margin="4rem auto auto auto"
      />
    );
  }

  const { data: { pretendente, locacao, laudo } } = props;
  const aluguel = (locacao !== undefined && parseFloat(locacao.aluguel)) || 0;
  const condominio = (locacao !== undefined && parseFloat(locacao.condominio)) || 0;
  const iptu = (locacao !== undefined && parseFloat(locacao.iptu)) || 0;
  const totalTenancy = formatToBRL(aluguel + condominio + iptu);
  const data = (laudo !== undefined && getDate(laudo.data_conclusao)) || "";
  const typeApplicant = (typeof pretendente === "object" && Object.keys(pretendente).length > 0)? pretendente.tipo_pretendente : "";
  const propertyType = (typeof locacao === "object" && Object.keys(locacao).length > 0)? locacao.tipo_imovel : "";
  const isPF = pretendente && pretendente.cpf ? true : false;
  const razao_social = laudo !== undefined && laudo.veracidade_nome.result

  return (
    <Container widthMobile="96%" width="98%" className='reportHeader'>
      <StyledRow margin="0">
        <BaseColumn xs="12" sm="12" md="4" lg="4" xl="4">
          <TextContainer>
            <SubtitleNoMargin style={{marginTop: 1}}>{isPF && 'NOME ENCONTRADO' || 'EMPRESA'}:</SubtitleNoMargin>
            <CardTextNoMargin data-testid="nomeApurado" style={{color: '#000'}}>&nbsp;{razao_social || 'Não encontrado'}</CardTextNoMargin>
          </TextContainer>
        </BaseColumn>
      
        <BaseColumn xs="12" sm="12" md="6" lg="6" xl="6">
          <TextContainer>
            <SubtitleNoMargin style={{marginTop: 1}}>IMÓVEL PRETENDIDO:</SubtitleNoMargin>
            <CardTextNoMargin style={{maxWidth: '100%', color: '#000'}} data-testid="imovelPretendido">&nbsp;{getAddress(locacao)}.</CardTextNoMargin>
          </TextContainer>
        </BaseColumn>

        <BaseColumn xs="2" sm="2" md="2" lg="2" xl="2">
          <TextContainer flexDirectionMobile="column" align="flex-end">
            <SubtitleNoMargin style={{marginTop: 1, marginRight: 3.3}}>DATA/HORA:</SubtitleNoMargin>
            <CardTextNoMargin style={{color: '#000'}}>{data.replace('(', '[').replace(')', ']')}</CardTextNoMargin>
          </TextContainer>
        </BaseColumn>
      </StyledRow>

      <StyledRow justify="space-between">
        <BaseColumn xs="3" sm="3" md="3" lg="3" xl="3">
          <TextContainer flexDirectionMobile="column">
            <SubtitleNoMargin style={{marginTop: 1}}>{isPF && 'CPF' || 'CNPJ'}:</SubtitleNoMargin>
            <CardTextNoMargin data-testid="cpf" style={{color: '#000'}}>&nbsp;{pretendente && formatCPF(pretendente.cpf) || pretendente && formatCNPJ(pretendente.cnpj)}</CardTextNoMargin>
          </TextContainer>
        </BaseColumn>
        <BaseColumn xs="1" sm="1" md="1" lg="1" xl="1">
          <VerticalLine/>
        </BaseColumn>
        <BaseColumn xs="4" sm="4" md="4" lg="4" xl="4">
          <TextContainer flexDirectionMobile="column">
            <SubtitleNoMargin style={{marginTop: 1}}>ALUGUEL + ENCARGOS:</SubtitleNoMargin>
            <CardTextNoMargin data-testid="valorLocacao" style={{color: '#000'}}>&nbsp;R$ {totalTenancy}</CardTextNoMargin>
          </TextContainer>
        </BaseColumn>
        <BaseColumn xs="1" sm="1" md="1" lg="1" xl="1">
          <VerticalLine/>
        </BaseColumn>
        <BaseColumn xs="12" sm="12" md="3" lg="3" xl="3">
          <TextContainer align="flex-end">
            <SubtitleNoMargin style={{marginTop: 1}}>PRETENDENTE/IMÓVEL:</SubtitleNoMargin>
            <CardTextNoMargin data-testid="valorLocacao" style={{color: '#000'}}>&nbsp;{typeApplicant}/{propertyType}</CardTextNoMargin>
          </TextContainer>
        </BaseColumn>
      </StyledRow>
    </Container>
  );
}

export default ReportHeader;
