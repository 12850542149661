import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logoutUser } from '../../pages/auth/login/actions';

import PropTypes from 'prop-types';
import Title from './Title/index';
import ButtonsHeader from './ButtonsHeader';
import Breadcrumb from '../Breadcrumb';
import UserPanel from './UserPanel/index';
import { HeaderPage, ContentHeader, SubtitleHeader } from './header.styles';
import { ResumeButton } from "./../../pages/operational/request/resume/requestResume.styles";


import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';

class Header extends PureComponent {

  static defaultProps = {
    isTab: false,
    breadcrumb: [],
    headerLinks: [],
    cancel: () => {},
    confirm: () => {},
    disabledCancel: false,
    disabledConfirm: false,
    formName: '',
    titleIcon: '',
    messageIcon: undefined,
    onClickTitleIcon: () => {},
    openTourCallback: () => {}
  }

  renderThirdRow = () => {
    switch(this.props.typeHeader){
      case "form":
        return (
          <StyledRow customstyle="height: 59px;" align="flex-end">
            <ButtonsHeader 
              headerLinks={this.props.headerLinks} 
              cancel={this.props.cancel} 
              confirm={this.props.confirm}
              disabledCancel={this.props.disabledCancel}
              disabledConfirm={this.props.disabledConfirm}
              formName={this.props.formName}
              confirmIcon={this.props.proccess}
              cancelIcon={this.props.proccess} />
          </StyledRow>);
      default:
        return this.props.children && this.props.children;
    }
  }

  renderButtonTour = () => {
    const pathPermited = [
      '/intranet/dashboard',
      '/intranet/operacional/solicitacao',
      '/intranet/operacional/solicitacao/adicionar/selecionar-produto',
      '/intranet/operacional/solicitacao/formulario',
      '/intranet/operacional/solicitacao/laudo',
      '/intranet/operacional/solicitacao/resumo',
      '/intranet/operacional/solicitacao/resumo/locacao',
      '/intranet/operacional/solicitacao/resumo/pretendente',
      '/intranet/operacional/solicitacao/resumo/adicionar/fc-renda',
      '/intranet/operacional/solicitacao/resumo/adicionar-pretendente',
      '/intranet/cadastro/dados-cliente',
      '/intranet/cadastro/usuarios',
      // '/intranet/cadastro/customizar-recomendacoes',
      '/intranet/consumo/relatorio',
      // '/intranet/consumo/precos'
    ]

    // if(this.props.master) {
    //   pathPermited.push('/intranet/cadastro/usuarios');
    // }

    if(this.props.forceRenderButtonTour) {
      return(
        <ResumeButton style={{position: 'absolute', bottom: 1, backgroundColor: '#ff7200', color: '#152b4e', width: '11rem', padding: '.25rem 0.5rem .25rem 0.5rem'}} onClick={this.handleOpenTour}>
          Tour do Sistema (v.Beta)
        </ResumeButton>
      )
    }

    if(!pathPermited.includes(window.location.pathname)) {
      return null
    }

    return(
      <ResumeButton style={{position: 'absolute', bottom: 1, backgroundColor: '#ff7200', color: '#152b4e', width: '11rem', padding: '.25rem 0.5rem .25rem 0.5rem'}} onClick={this.handleOpenTour}>
        Tour do Sistema (v.Beta)
      </ResumeButton>
    )
  }

  handleOpenTour = () => {
    this.props.openTourCallback(true)
  }

  render() {
    return (
        <HeaderPage height={this.props.height} isTab={this.props.isTab} className='headerPage'>
          <ContentHeader>
            <img src='https://cliente.ficharapida.rio.br/print/img/logo-fc-f-claro.png' style={{display: 'none'}} className='logo-print' />
            <StyledRow>
              <BaseColumn xs="12" sm="12" md="12" lg="12" xl="12">
                <StyledRow>
                  <BaseColumn xs="12" md="4" lg="4" xl="4" customstyle={{ alignItems: 'center' }} className='content-header-1'>
                    <Breadcrumb links={this.props.breadcrumb} actualLink={this.props.actualLink} />
                  </BaseColumn>

                  <BaseColumn xs="12" md="3" lg="3" xl="3" customstyle={{ alignItems: 'center' }} className='content-header-2'>
                    <Title 
                      title={this.props.title} 
                      icon={this.props.titleIcon} 
                      iconClick={this.props.onClickTitleIcon}
                      message={this.props.messageIcon} />
                    { this.props.subtitle && 
                      <StyledRow justify="center">
                        <SubtitleHeader style={{color: '#000'}}>{this.props.subtitle}</SubtitleHeader>
                      </StyledRow> }
                  </BaseColumn>

                  <BaseColumn xs="12" md="5" lg="5" xl="5" customstyle={{ alignItems: 'center' }} className='content-header-3'>
                    {this.renderButtonTour()}
                    {this.props.actualLink === 'Laudo' &&
                      <div style={{position: 'absolute', left: '12.5rem', backgroundColor: '#d4d4d4', width: '10rem', textAlign: 'center'}}>
                        <span style={{color: '#152b4e', fontSize: '.8rem', fontWeight: 400}}>Impressão/PDF</span>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <ResumeButton disabled={this.props.disableButtonPrintLaudo} style={{bottom: 1, backgroundColor: '#152b4e', color: '#fff', width: this.props.infos && this.props.infos.recibo ? '4rem' : '8rem', padding: '.25rem 0.5rem .25rem 0.5rem', marginBottom: 6, marginTop: '0.35rem'}} onClick={this.props.handleImpressao} customstyle="&:disabled {cursor: no-drop;}">
                            LAUDO
                          </ResumeButton>
                          {this.props.infos && this.props.infos.recibo &&
                            <ResumeButton disabled={this.props.disableButtonPrintLaudo} style={{bottom: 1, backgroundColor: '#152b4e', color: '#fff', width: '4rem', padding: '.25rem 0.5rem .25rem 0.5rem', marginBottom: 6, marginTop: '0.35rem', marginLeft: 10}} onClick={this.props.getPrintRecibo} customstyle="&:disabled {cursor: no-drop;}">
                              RECIBO
                            </ResumeButton> || null
                          }
                        </div>
                      </div>
                    }
                    <UserPanel logout={this.props.logoutUser} />
                  </BaseColumn>
                </StyledRow>
              </BaseColumn>          
            </StyledRow>
            
            {this.renderThirdRow()}
          </ContentHeader>
        </HeaderPage>
    ); 
  }
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  cancel: PropTypes.func,
  confirm: PropTypes.func
};

const mapDispatchToProps = dispatch => bindActionCreators({ logoutUser }, dispatch)

export default connect(null, mapDispatchToProps)(Header);
