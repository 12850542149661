import React, { PureComponent } from 'react';

import { Th, BalanceTh, CreditTh, EditCreditTh } from '../../../components/shared/customTable.styled';
import OrderContainer from '@bit/wavfichacerta.fichacerta.tables.order-container';

export default class HeaderUsersTable extends PureComponent {

  state = {
    current: 'nome',
    sort: 'ASC'
  }

  onChangeOrder = (tag) => {
    let newSort = "ASC";
    if (tag === this.state.current) {
      newSort = this.state.sort === "ASC"? "DESC" : "ASC";
    }
    this.setState({ current: tag, sort: newSort }, () => {
      const tagSort = newSort === "ASC"? tag : `-${tag}`;
      this.props.changeOrder(tagSort);
    })
  }

  render() {
    const { current, sort } = this.state;
    return (
      <tr>
      <Th width="7rem" maxWidth="7rem">
          <OrderContainer sort={sort} current={current} tag="master" changeCurrent={this.onChangeOrder}>Tipo</OrderContainer>
        </Th>
        <Th width="3rem" minWidth="3rem"></Th>
        <Th>
          <OrderContainer sort={sort} current={current} tag="nome" changeCurrent={this.onChangeOrder}>Nome</OrderContainer>
        </Th>
        <Th>
          <OrderContainer sort={sort} current={current} tag="funcao" changeCurrent={this.onChangeOrder}>Função</OrderContainer>
        </Th>
        {(this.props.condition === "POSPAGO" && this.props.typeUser === "MASTER") && 
        <div>
            <EditCreditTh>
              Editar limite disponível
            </EditCreditTh>
            <CreditTh align="center">
              Limite disponível
            </CreditTh>
            <BalanceTh align="center">
              Utiliz.
            </BalanceTh>
            <BalanceTh align="center">
              Dispon.
            </BalanceTh>
        </div>}
        <Th width="7rem" maxWidth="7rem">Status</Th>
      </tr>
    )
  }
}
