import {
  OPEN_LOGIN,
  OPEN_SIGNUP,
  CLOSE_LOGIN,
  CLOSE_SIGNUP
} from '../actions/types';

import {
  onOpenSignUp,
  onOpenLogin,
  onCloseLogin,
  onCloseSignUp
} from './reducers';

const initialState = {
  openSignUp: false,
  openLogin: false
};

export default (state = initialState, { type }) => {
  switch (type) {
    case OPEN_LOGIN:
      return onOpenLogin(state);
    case OPEN_SIGNUP:
      return onOpenSignUp(state);
    case CLOSE_LOGIN:
      return onCloseLogin(state);
    case CLOSE_SIGNUP:
      return onCloseSignUp(state);
    default:
      return state;
  }
};
