import React from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { CreditContainer, UsedCreditBar } from './creditBar.styled';


export default (props) => (
  <StyledRow>
    {props => props.renderHeader? props.renderHeader() : null}
    <CreditContainer hasColor={props.availableCredit > 0}>
      {props.availableCredit > 0 && <UsedCreditBar width={props.usagePercentage} />}
    </CreditContainer>
  </StyledRow>
)
