import React, { Component } from 'react';
import styled from 'styled-components';

export const StyledSVG = styled.svg`
  width: ${props => props.width? props.width : '100%'};
  fill: ${props => props.color || props.theme.color_green};
`;

export default class SaveSVG extends Component {
  render() {
    return (
        <StyledSVG viewBox="0 0 191.721 191.72" {...this.props}>
        <path className="fill-green" d="M189.28,17.607c-0.068-0.326-0.201-0.656-0.423-0.982C179.087,2.264,154.587,3.04,139.349,2.863
              c-1.531-0.018-2.278,1.567-1.946,2.783c-1.36,0.37-2.642,0.867-3.744,1.632c-15.664,10.874-23.13,33.706-30.526,50.352
              c-7.081,15.937-15.087,31.394-21.974,47.423c-2.927,6.812-10.756,20.566-11.987,31.024c-13.63-24.964-26.279-50.331-38.457-76.064
              c-0.641-1.355-2.105-1.521-3.264-1c-3.022-1.761-6.932-0.388-10.134,0.419c-4.364,1.101-8.917,2.397-12.992,4.34
              c-0.663,0.316-1.039,0.766-1.277,1.252c-1.467-0.627-3.478,0.628-2.966,2.51c10.971,40.352,41.381,74.327,57.006,113.101
              c0.567,1.408,1.742,2.08,2.96,2.237c0.29,0.592,0.788,1.071,1.557,1.244c5.749,1.292,11.503,1.977,17.374,2.447
              c5.133,0.411,11.125,1.452,16.1-0.074c1.716-0.526,2.41-2.135,2.152-3.584c0.405-0.076,0.833-0.314,1.279-0.806
              c0.516-0.565-0.007-1.291-0.693-1.191c4.101-0.591,7.852-18.662,9.001-21.8c3.677-10.038,7.715-19.947,11.753-29.845
              c2.336-5.725,4.956-11.391,7.767-17.003c1.098-1.959,2.116-3.979,3.104-6.024c16.025-30.111,37.997-58.36,59.695-83.453
              C190.61,21.082,190.333,19.031,189.28,17.607z M24.337,66.576c-0.232-0.435-0.449-0.881-0.674-1.321
              c1.093-0.265,2.162-0.583,3.102-1.096c0.18,0.505,0.387,0.999,0.569,1.502C26.294,65.507,25.151,65.857,24.337,66.576z
                M41.044,100.147c-0.204-0.285-0.388-0.582-0.595-0.867c-0.034-0.047-0.081-0.062-0.117-0.106
              c-0.514-2.094-1.073-4.171-1.698-6.223c0.01-0.017,0.019-0.034,0.029-0.051c1.026,2.211,2.058,4.418,3.122,6.612
              C41.509,99.682,41.275,99.915,41.044,100.147z M85.517,179.797c0.529-0.749,0.658-1.744,0.45-2.663
              c0.655-0.469,1.277-0.985,1.885-1.519c-0.113,0.469-0.253,0.926-0.364,1.396c-0.269,1.143,0.038,2.042,0.597,2.7
              C87.229,179.724,86.373,179.759,85.517,179.797z M106.918,137.661c-0.71,1.801-1.526,3.703-2.391,5.668
              c0.053-0.418,0.104-0.838,0.148-1.261c1.054-2.789,2.192-5.557,3.33-8.324c0.241-0.175,0.493-0.338,0.73-0.515
              C108.126,134.706,107.504,136.175,106.918,137.661z M126.89,96.093c-3.541,6.27-6.913,12.627-10.061,19.089
              c-0.984,1.416-2.016,2.785-3.1,4.104c4.305-8.278,9.013-16.344,13.673-24.43C127.234,95.27,127.061,95.681,126.89,96.093z
                M151.391,21.809c0.247-0.431,0.494-0.862,0.763-1.288c1.112-1.389,2.192-2.844,3.228-4.427c0.115-0.001,0.228-0.013,0.343-0.012
              c0.672,0.185,1.343,0.371,2.016,0.581c-0.676,0.066-1.363,0.305-1.988,0.926C154.291,19.039,152.845,20.436,151.391,21.809z"
        />
      </StyledSVG>
    )
  }
}
