import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getFCFiancaRequests, changePage, changePerPage, onChangeSort, resetRequests } from './actions';
import { setFilter } from '../table/actions';
import { getFCFiancaRequestsList, getLoadingStatus } from './selector';

import CustomTable from '@bit/wavfichacerta.fichacerta.tables.custom-table';
import RequestsHeader from '../../../components/Operational/RequestsHeader';
import FcFiancaHeader from './components/FcFiancaHeader';
import FcFiancaLine from './components/FcFiancaLine';
import PaginationFooter from '../../../components/shared/table/PaginationFooter';

class FcFiancaTable extends PureComponent {
  state = {
    current: 'id',
    sort: 'ASC'
  }

  componentDidMount() {
    const {complete} = this.props;
    this.props.setFilter("");
    this.props.getFCFiancaRequests(complete);
  }

  componentWillUnmount() {
    this.props.resetRequests();
  }

  onChangePage = (page) => {
    const {complete} = this.props;
    this.props.changePage(page);
    this.props.getFCFiancaRequests(complete);
  }

  onChangeResultsperPage = (perPage) => {
    const {complete} = this.props;
    this.props.changePerPage(perPage);
    localStorage.setItem("resultsPerPageRequests", perPage);
    this.props.getFCFiancaRequests(complete);
  }

  changeCurrentSort = (name) => {
    const { current, sort } = this.state;
    const {complete} = this.props;
    let newSort = '';
    if (current === name) {
      newSort = sort === "ASC"? 'DESC' : 'ASC';
    } else {
      newSort = 'ASC';
    }
    this.setState({ current: name, sort: newSort }, () => {
      const apiSort = (newSort === "DESC")? `-${name}` : name;
      this.props.onChangeSort(apiSort);
      this.props.getFCFiancaRequests(complete);
    });
  }

  renderHeader = () => (
    <FcFiancaHeader 
      sortHandler={this.changeCurrentSort} 
      current={this.state.current} 
      sort={this.state.sort} />
    )

  renderLine = (line, index) => {
    const onGoToResume = () => {
      this.props.setFilter("");
      this.props.history.push({
        pathname: '/intranet/operacional/solicitacao/resumo',
        state: { request: line.id }
      })
    };

    return (
      <FcFiancaLine
        key={line.id.toString()}
        {...line}
        index={index}
        length={this.props.lines.length}
        onGoToResume={onGoToResume}
      />
    );
  }

  render() {
    return (
      <Fragment>
        <CustomTable
          loading={this.props.requesting}
          lines={this.props.lines}
          renderHeader={this.renderHeader}
          renderLine={this.renderLine}
        />
        {this.props.lines.length <= 0 && !this.props.requesting &&
          <div style={{textAlign: 'center', color: '#8000807a', paddingBottom: 12, borderBottom: '4px solid #7f7f7f'}}>
            <b>Não há registros nessa lista!</b>
          </div>
        }
        <PaginationFooter 
          pagination={this.props.pagination}
          changePage={this.onChangePage}
          changeMaxResults={this.onChangeResultsperPage}
          callToAction={this.props.callToAction}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ getFCFiancaRequests, changePage, changePerPage, onChangeSort, setFilter, resetRequests }, dispatch);

const mapStateToProps = (state) => ({
  lines: getFCFiancaRequestsList(state),
  requesting: getLoadingStatus(state),
  pagination: state.fcfiancaTable.pagination
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FcFiancaTable));
