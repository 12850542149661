
import { formatToBRL } from './../../../../../../src/utils/formatter';

const formatTimes = (times) => {
  if (times === undefined || times === null) {
    return 0;
  }
  const timesString = times.toFixed(1);
  return timesString.replace(/\.(?!\.)/g, ",");
}

// 2.85
const residencialRule = (percentage) => {
  return percentage <= 35.08 ? "positivo" : "negativo";
}
// 3.85
const nonResidencialRule = (percentage) => {
  return percentage <= 25.97 ? "positivo" : "negativo";
}

export default (data, tipo_imovel, laudos_sintese, fiadores) => {
  console.log('datax12', data)

  let origens = '';
  let rendaConjuntaInformada = data.groupedTimesRentalResult
  let rendaConjuntaInformadaIcon = 'neutro'
  let rendaConjuntaPresumida = data.groupedTimesRentalResultPresumida;
  let rendaConjuntaPresumidaIcon = 'neutro'
  let riscoCreditoConjunto = data.parecerMercado;
  let riscoCreditoConjuntoIcon = 'neutro'
  let scoreConjunto = data.score_conjunto;
  let scoreConjuntoIcon = 'neutro'
  let parecerLocacao = data.parecer;
  let parecerLocacaoIcon = 'neutro'
  let temFiadorEstudante = false;
  const isFiadores = fiadores
  const temFcDOC = data.temFcDOC

  laudos_sintese && laudos_sintese.map(sintesePretendente => {
    if(sintesePretendente.tipo_pretendente === 'FIADOR_ESTUDANTE' && isFiadores) {
      temFiadorEstudante = true;
    }
  })

  // const pareceres_keys_elegibles = ['PARECER_NIVEL_1', 'PARECER_NIVEL_2', 'PARECER_NIVEL_3', 'PARECER_NIVEL_4'];

  // if(pareceres_keys_elegibles.includes(data.parecer_key)) {
  //   rendaConjuntaInformadaIcon = 'positivo';
  //   rendaConjuntaPresumidaIcon = 'positivo';
  // } else {
  //   rendaConjuntaInformadaIcon = 'negativo';
  //   rendaConjuntaPresumidaIcon = 'negativo';
  // }

  if (scoreConjunto !== undefined) {
    if (scoreConjunto >= 100) {
      scoreConjuntoIcon = "positivo";
    } else {
      scoreConjuntoIcon = "negativo";
    }
  }

  if(tipo_imovel === 'RESIDENCIAL') {
    if(rendaConjuntaInformada >= 2.85) {
      rendaConjuntaInformadaIcon = 'positivo'
    } else {
      rendaConjuntaInformadaIcon = 'negativo'
    }
    if(rendaConjuntaPresumida >= 2.85) {
      rendaConjuntaPresumidaIcon = 'positivo'
    } else {
      rendaConjuntaPresumidaIcon = 'neutro'
    }
  } else {
    if(rendaConjuntaInformada >= 3.85) {
      rendaConjuntaInformadaIcon = 'positivo'
    } else {
      rendaConjuntaInformadaIcon = 'negativo'
    }
    if(rendaConjuntaPresumida >= 3.85) {
      rendaConjuntaPresumidaIcon = 'positivo'
    } else {
      rendaConjuntaPresumidaIcon = 'neutro'
    }
  }

  // Só é permitido fiador de estudante em locação RESIDENCIAL
  if(temFiadorEstudante) {
    if(rendaConjuntaInformada >= 3.85) {
      rendaConjuntaInformadaIcon = 'positivo'
    } else {
      rendaConjuntaInformadaIcon = 'negativo'
    }
  }

  if(riscoCreditoConjunto === 'RISCO MUITO BAIXO') {
    riscoCreditoConjuntoIcon = 'triangule-green-dark'
  } else if(riscoCreditoConjunto === 'RISCO BAIXO') {
    riscoCreditoConjuntoIcon = 'triangule-green-light'
  } else if(riscoCreditoConjunto === 'RISCO MÉDIO') {
    riscoCreditoConjuntoIcon = 'triangule-yellow'
  } else if(riscoCreditoConjunto === 'RISCO ACIMA DA MÉDIA') {
    riscoCreditoConjuntoIcon = 'triangule-orange'
  } else if(riscoCreditoConjunto === 'RISCO MUITO ALTO') {
    riscoCreditoConjuntoIcon = 'triangule-red'
  }

  if(data.parecer_key === 'PARECER_NIVEL_1') {
    parecerLocacaoIcon = 'triangule-green-dark'
  } else if(data.parecer_key === 'PARECER_NIVEL_2') {
    parecerLocacaoIcon = 'triangule-green-light'
  } else if(data.parecer_key === 'PARECER_NIVEL_3') {
    parecerLocacaoIcon = 'triangule-yellow'
  } else if(data.parecer_key === 'PARECER_NIVEL_4') {
    parecerLocacaoIcon = 'triangule-orange'
  } else if(data.parecer_key === 'PARECER_NIVEL_5') {
    parecerLocacaoIcon = 'triangule-red'
  } else if(data.parecer_key === 'ALERTA_ACAO_CRIMINAL') {
    parecerLocacao = 'ALERTA – Verificar ações criminais'
    parecerLocacaoIcon = 'triangule-alert-red'
    // riscoCreditoConjuntoIcon = 'triangule-alert-red'
  } else if(data.parecer_key === 'ALERTA_FRAUDE_CRIMINAL') {
    parecerLocacao = 'ALERTA – Verificar ações criminais. Possibilidade de FRAUDE em virtude de excessivas consultas. Recomendamos a análise da documentação e das consultas, o que pode ser solicitado através do FC DOC.'
    parecerLocacaoIcon = 'triangule-alert-red'
    // riscoCreditoConjuntoIcon = 'triangule-alert-red'
  } else if(data.parecer_key === 'ALERTA_FRAUDE') {
    parecerLocacao = 'ALERTA – Possibilidade de FRAUDE em virtude de excessivas consultas. Recomendamos a análise da documentação e das consultas, o que pode ser solicitado através do FC DOC.'
    parecerLocacaoIcon = 'triangule-alert-red'
    // riscoCreditoConjuntoIcon = 'triangule-alert-red'
  } else if(data.parecer_key === 'ALERTA_FCDOC') {
    parecerLocacao = 'ALERTA FC – Ver resultados do FC DOC. Em caso de dúvidas, entre em contato com nosso suporte.'
    parecerLocacaoIcon = 'triangule-alert-red'
    // riscoCreditoConjuntoIcon = 'triangule-alert-red'
  }

  data.origemPerfilFinanceiroConjunto.map(item => {
    origens = origens + item + ', ';
  })

  let acoes_criminais_desconsideradas_pelo = data.acoes_criminais_desconsideradas_pelo ? ` (${data.acoes_criminais_desconsideradas_pelo})` : ''

  let objectReturn = [
    {
      label: temFcDOC ? "RENDAS CONFIRMADAS em conjunto" : "RENDAS INFORMADAS em conjunto",
      status: rendaConjuntaInformadaIcon,
      message: formatTimes(rendaConjuntaInformada) + ' vezes a locação'
    },
    {
      label: "ORIGEM Perfil Financeiro ",
      status: 'neutro',
      message: origens.slice(0, -2)
    },
    {
      label: "RENDAS PRESUMIDAS em conjunto (Mercado)",
      status: rendaConjuntaPresumidaIcon,
      message: formatTimes(rendaConjuntaPresumida) + ' vezes a locação'
    },
    {
      label: "RISCO DE CRÉDITO em conjunto (Mercado)",
      status: riscoCreditoConjuntoIcon,
      message: riscoCreditoConjunto
    },
    {
      label: "RISCO FC em conjunto (nesta locação)",
      status: parecerLocacaoIcon,
      message: parecerLocacao
    },
    {
      label: "SCORE FC em conjunto (nesta locação)",
      status: parecerLocacaoIcon,
      message: scoreConjunto
    },
    {
      label: "NEGATIVAÇÕES DE CRÉDITO*",
      status: (data.temNegativacoes === 'CONSTA')? 'negativo' : "positivo",
      message: (data.temNegativacoes === 'CONSTA')? "ENCONTRADO*" : "NÃO ENCONTRADO*"
    },
    {
      label: "AÇÕES DE DESPEJO E DE LOCAÇÃO*",
      status: (data.temAcoes === 'CONSTA')? 'negativo' : "positivo",
      message: (data.temAcoes === 'CONSTA')? "ENCONTRADO*" : "NÃO ENCONTRADO*"
    },
    {
      label: "AÇÕES CRIMINAIS (v. Beta)*",
      status: (data.temAcoesCriminais === 'CONSTA')? 'roxo-nulo' : 'neutro',
      message: (data.temAcoesCriminais === 'CONSTA')? `ENCONTRADO*${acoes_criminais_desconsideradas_pelo}` : "NÃO ENCONTRADO*"
    },
    {
      label: "AÇÕES CÍVEIS (v. Beta)*",
      status: (data.temAcoesCiveis === 'CONSTA')? 'brown-nulo' : "neutro",
      message: (data.temAcoesCiveis === 'CONSTA')? "ENCONTRADO*" : "NÃO ENCONTRADO*"
    },
  ]

  if(data.temNegativacoes === undefined) {
    objectReturn = objectReturn.filter(item => item.label != 'NEGATIVAÇÕES DE CRÉDITO*')
  }

  if(data.temAcoes === undefined) {
    objectReturn = objectReturn.filter(item => item.label != 'AÇÕES DE DESPEJO E DE LOCAÇÃO*')
  }

  if(data.temAcoesCriminais === undefined) {
    objectReturn = objectReturn.filter(item => item.label != 'AÇÕES CRIMINAIS (v. Beta)*')
  }

  if(data.temAcoesCiveis === undefined) {
    objectReturn = objectReturn.filter(item => item.label != 'AÇÕES CÍVEIS (v. Beta)*')
  }

  // if(productFcAnalysis.length && !productFcAnalysisPlus.length) {
  //   objectReturn = objectReturn.filter(item => item.label != 'INFORMAÇÕES RESTRITIVAS')
  // }

  return objectReturn
}
