import React from 'react';
import styled, { css } from 'styled-components';

const SVG = styled.svg`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  margin: ${props => props.margin || 'auto'};
  & > path { fill: ${props => props.theme[props.color] || props.theme.color_orange}; }
  ${props => css`${props.customStyle}`};
`;

export default (props) => (
  <SVG {...props} viewBox="0 0 409.57 363.13">
    <path d="M414.6,341c-.48,1.34-1,2.66-1.43,4a31.39,31.39,0,0,1-28.3,22.27c-.53,0-1.07.07-1.6.07q-173.37,0-346.74,0A31,31,0,0,1,6.24,344.43C3.51,335,5.61,326.38,10.43,318q45.14-78,90.13-156Q141.12,91.79,181.7,21.58C191.16,5.24,209.13-.29,224.81,8.15c6.09,3.28,10.22,8.34,13.62,14.23Q287.23,107,336.1,191.56q34.16,59.16,68.24,118.37c3.93,6.86,6.86,14.3,10.25,21.47ZM209.8,35.67l-173.23,300H383Z" transform="translate(-5.03 -4.24)"/>
    <path d="M231.49,144.15c-1.42,16.54-2.84,33.51-4.33,50.49s-3,33.67-4.49,50.51c-.71,8.25-5.6,13.44-12.88,13.46s-12.17-5.34-12.88-13.5q-4.22-48.33-8.57-96.65c-.85-9.23.2-17.68,8.15-23.67,7.23-5.45,15.21-6.29,23.26-2.1C228.13,127,231.82,134.33,231.49,144.15Z" transform="translate(-5.03 -4.24)"/>
    <path d="M209.74,301a18,18,0,1,1,18-18A17.94,17.94,0,0,1,209.74,301Z" transform="translate(-5.03 -4.24)"/>
  </SVG>
);
