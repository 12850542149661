import { instance as axios } from '../axiosInstance';
import { statusHandler } from '../../utils/loginHelper';

const GET_CLIENTS_END_POINT = '/api/administrador';
const getStatusEndPoint = (id) => `/api/administrador/${id}/status`;
const REGISTER_END_POINT = '/api/administrador';
const getClientInfoEndPoint = `/api/administrador?relations=true&information=true`;
const updateClientCreditEndPoint = clientId => `/api/administrador/${clientId}/credito`;
const updateClientEndPoint = clientId => `/api/administrador/${clientId}`;
const updateClientPaymentModeEndpoint = id => `/api/administrador/${id}/condicao`
const REGISTER_CUSTOMER_EMPLOYERS = "/api/administrador/funcionario";
const singleEmployer = (id) => `/api/administrador/funcionario/${id}`;
const GET_CUSTOMER_EMPLOYERS = "/api/administrador/funcionarios";
const POST_SAC_CUSTOMER_MESSAGE = "/api/sistema/mensagem/atendimento";

const getHeader = (token) => (
  {
    'Authorization': `Bearer ${token}`
  }
);

export const postSacMessage = (token, data) => {
  const headers = getHeader(token);

  return axios.post(POST_SAC_CUSTOMER_MESSAGE, data, {validateStatus: statusHandler, headers});
}

export const getCustomer = (token) => {
  const headers = getHeader(token);

  return axios.get(GET_CLIENTS_END_POINT, { validateStatus: statusHandler, headers });
};

export const getClients = (token, conf) => {
  const headers = getHeader(token);
  const params = {
    sort: conf.sortBy,
    perPage: conf.resultsPerPage,
    page: conf.currentPage,
    type: conf.type,
    filter: conf.filter
  }

  return axios.get(GET_CLIENTS_END_POINT, { validateStatus: statusHandler, headers, params });
};

export const setClientStatus = (token, id, status, changeReason) => {
  const headers = getHeader(token);
  const data = {
    _method: 'PUT',
    status,
    observacao: changeReason
  };

  return axios.put(getStatusEndPoint(id), data, { validateStatus: statusHandler, headers });
};

export const updateClientPaymentMode = (token, id, mode) => {
  const headers = getHeader(token);
  const data = {
    _method: 'PUT',
    condicao: mode,
  };

  return axios.put(updateClientPaymentModeEndpoint(id), data, { validateStatus: statusHandler, headers });
};

export const registerClient = (data, token) => {
  const headers = getHeader(token);

  return axios.post(REGISTER_END_POINT, data, { validateStatus: statusHandler, headers });
};

export const updateClient = (clientId, data, token) => {
  const headers = getHeader(token);

  return axios.put(updateClientEndPoint(clientId), data, { validateStatus: statusHandler, headers });
};

export const getClientInfos = (token) => {
  const headers = getHeader(token);

  return axios.get(getClientInfoEndPoint, { validateStatus: statusHandler, headers });
};

export const updateClientCredit = (token, clientId, credit) => {
  const headers = getHeader(token);
  const data = {
    _method: 'PUT',
    credito: credit
  };
  return axios.put(updateClientCreditEndPoint(clientId), data, { validateStatus: statusHandler, headers });
};

export const updateCustomerData = (token, data) => {
  const headers = getHeader(token);
  const axiosData = {
    cliente: {
      ...data
    }
  }
  return axios.put(REGISTER_END_POINT, axiosData, { headers });
}

export const updateComercialData = (token, data) => {
  const headers = getHeader(token);
  const axiosData = {
    pesquisa: {
      ...data
    }
  }
  return axios.put(REGISTER_END_POINT, axiosData, { headers });
}

export const getEmployersList = (token, paginationConf, userStatus) => {
  const headers = getHeader(token);
  const params = {
    perPage: paginationConf.resultsPerPage,
    page: paginationConf.currentPage,
    sort: paginationConf.sort
  };
  if(userStatus) {
    params.status = userStatus
  }
  return axios.get(GET_CUSTOMER_EMPLOYERS, { headers, params });
}

export const insertEmployer = (token, data) => {
  const headers = getHeader(token);
  return axios.post(REGISTER_CUSTOMER_EMPLOYERS, data, { headers });
}

export const getEmployer = (token, employer) => {
  const headers = getHeader(token);
  return axios.get(singleEmployer(employer), { headers });
}

export const updateEmployer = (token, idUser, data) => {
  const headers = getHeader(token);
  return axios.put(singleEmployer(idUser), data, { headers });
}
