import * as types from '../actions/types';
import {
  getRequestDataRequesting,
  getRequestDataSuccess,
  getRequestDataFailed,
  onTenancyFormRequsting,
  onTenancyFormSuccess,
  onTenancyFormFailed
} from './reducers';

const initialState = {
  requesting: false,
  error: undefined,
  data: {
    requesting: false,
    error: undefined,
    data: {},
    customer: ""
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_DATA_REQUEST:
      return getRequestDataRequesting(state);
    case types.GET_DATA_SUCCESS:
      return getRequestDataSuccess(state, payload);
    case types.GET_DATA_FAILED:
      return getRequestDataFailed(state);
    case types.TENANCY_FORM_REQUESTING:
      return onTenancyFormRequsting(state);
    case types.TENANCY_FORM_SUCCESS:
      return onTenancyFormSuccess(state);
    case types.TENANCY_FORM_FAILED:
      return onTenancyFormFailed(state, payload);
    case types.TENANCY_FORM_RESET:
      return initialState;
    default:
      return state;
  }
}
