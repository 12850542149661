import React from 'react'
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows'
import { defaultEmail } from '@bit/wavfichacerta.fichacerta.fields-templates.email'
import { defaultPhoneWithoutDDI } from '@bit/wavfichacerta.fichacerta.fields-templates.phone'
import handler from '@bit/wavfichacerta.fichacerta.inputs-functions.input-handler'
import { text, maskedText, textDisabled } from '@bit/wavfichacerta.fichacerta.inputs.inputs';
import { simpleDisabledText, simpleNonRequiredText } from '@bit/wavfichacerta.fichacerta.fields-templates.simple-text'

export default (props) => {
  const { editMode, typeUserEdit, typeUser, loggedUser, changedValues } = props;
  console.log('box1', typeUser)

  if (editMode && typeUserEdit === "MASTER" && !loggedUser) {
    return (
      <StyledRow justify="flex-start" margin="3rem 0 0 0" className='secondLineFields'>
        {handler({ text })(defaultEmail('field-fc field-fc-sm field-fc-sm-8'))}
        {handler({ maskedText })(defaultPhoneWithoutDDI ('telefone', 'Telefone','field-fc field-fc-xs field-fc-sm-4'))}
        {handler({ textDisabled })(simpleDisabledText({ size: 'field-fc field-fc-xs field-fc-sm-4', name: 'total', label: 'Total' }))}
      </StyledRow>
    );
  }

  return (
    <StyledRow justify="flex-start" margin="3rem 0 0 0" className='secondLineFields'>
      {handler({ text })(defaultEmail('field-fc field-fc-sm field-fc-sm-8'))}
      {handler({ maskedText })(defaultPhoneWithoutDDI ('telefone', 'Telefone','field-fc field-fc-xs field-fc-sm-4'))}
      {typeUser === "MASTER" && !editMode && (handler({ text })(simpleNonRequiredText({ size: 'field-fc field-fc-xs field-fc-sm-4', name: 'credit', label: 'Limite disponível' })))}
      {handler({ textDisabled })(simpleDisabledText({ size: 'field-fc field-fc-xs field-fc-sm-4', name: 'total', label: 'Total' }))}
    </StyledRow>
  );
}