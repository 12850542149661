import { formatToBRL, formatDateToBR, formatCNPJ } from '../../../../../utils/formatter';

export const getAmountData = (data) => {
  if (data !== undefined && data !== null && typeof data === "object") {
    const { laudo } = data;
    if (laudo !== undefined && laudo !== null && "compatibilidade_renda" in laudo) {
      const { compatibilidade_renda: { result } } = laudo;
      if (result !== null && typeof result === "object") {
        const amount = parseFloat(result.renda);
        return `R$ ${formatToBRL(amount)}`;
      }
    }
  }
  return "R$ 0,00";
}

export const getAmountRecommendations = (data) => {
  let title = "";
  let recommendations = [];

  if (data !== undefined && data !== null && typeof data === "object") {
    const { laudo } = data;
    if (laudo !== undefined && laudo !== null && "compatibilidade_renda" in laudo) {
      const { compatibilidade_renda: { recommendation } } = laudo;
      title = recommendation.titulo;
      recommendations = recommendation.observacoes;
    }
  }
  return {
    title,
    recommendations
  };
}

export const getDocRecommendation = (data) => {
  let recommendations = [];

  if (data !== undefined && data !== null && typeof data === "object") {
    const { laudo } = data;
    if (laudo !== undefined && laudo !== null && "principal_origem_renda" in laudo) {
      const { principal_origem_renda: { result } } = laudo;
      if (result !== null && typeof result === "object") {
        recommendations = result.documentacao;
      }
    }
  }

  return recommendations;
}

export const getProfileGiven = (data) => {
  let job = "";
  let scholarity = "";
  let amount = "";
  let economicGroup = "";
  let maritalStatus = "";
  let hasCar = "";

  if (data !== undefined && data !== null && typeof data === "object") {
    const { laudo } = data;
    const productFcReport = data.pretendente.produtos.ativo.filter(product => product.id === 1 && product.status !== 'INCLUIDO')
    const productFcRenda = data.pretendente.produtos.ativo.filter(product => product.id === 2)
    const productFcEssencial = data.pretendente.produtos.ativo.filter(product => product.id === 3 && product.status !== 'INCLUIDO')
    const productFcEmpresa = data.pretendente.produtos.ativo.filter(product => product.id === 4)
    const productFcBasico = data.pretendente.produtos.ativo.filter(product => product.id === 5)

    if (laudo !== undefined && laudo !== null && "perfil_socioeconomico" in laudo) {
      const { perfil_socioeconomico: { result } } = laudo;
      if (result !== null && typeof result === "object") {
        job = result.ocupacao || 'Não encontrado';
        scholarity = result.escolaridade || 'Não encontrado';
        amount = result.renda ? "R$ "+ formatToBRL(result.renda) : 'Não encontrado';
        economicGroup = result.grupo_economico || "Não encontrado";
        maritalStatus = result.estado_civil || "Não encontrado";
        hasCar = result.tem_veiculo? "Sim" : "Não";
      }

      if(productFcBasico.length && !productFcEssencial.length && !productFcReport.length && amount === 'Não encontrado') {
        amount = result.fraseRendaPresumidaBasico
      }
    }

  }

  return {
    job,
    scholarity,
    amount,
    economicGroup,
    maritalStatus,
    hasCar
  }
}

export const getProfileRecommendations = (data) => {
  let obs_list = [];
  let title = "";

  if (data !== undefined && data !== null && typeof data === "object") {
    const { laudo } = data;

    if (laudo !== undefined && laudo !== null && "perfil_socioeconomico" in laudo) {
      const { perfil_socioeconomico: { recommendation } } = laudo;
      
      obs_list = recommendation.observacoes;
      title = recommendation.titulo;
    }
  }

  return {
    title,
    obs_list
  };
}

export const getBusinessParticipation = (data) => {
  let hasParticipation = false;
  let list = [];

  if (data !== undefined && data !== null && typeof data === "object") {
    const { laudo } = data;

    if (laudo !== undefined && laudo !== null && "participacao_empresarial" in laudo) {
      const { participacao_empresarial: { result } } = laudo;

      if (Array.isArray(result) && result.length > 0) {
        hasParticipation = true;
        list = result.map((participationFound) => {
          const { cnpj, data_entrada, nome, participacao } = participationFound;
          let participationCNPJ = "";
          let incomeDate = "";
          let participationName = "";

          if (Array.isArray(cnpj) && cnpj.length > 0) {
            participationCNPJ = cnpj[0];
          } else {
            participationCNPJ = cnpj;
          }
          
          if (Array.isArray(data_entrada) && data_entrada.length > 0) {
            incomeDate = data_entrada[0];
          } else {
            incomeDate = data_entrada;
          }
  
          if (Array.isArray(nome) && nome.length > 0) {
            participationName = nome[0];
          } else {
            participationName = nome;
          }

          return {
            name: participationName,
            participation: (participacao === "%" || participacao === undefined || participacao === null) ? "Não encontrado" : participacao,
            cnpj: formatCNPJ(participationCNPJ) || 'Não encontrado',
            date: formatDateToBR(incomeDate) || 'Não encontrado'
          };
        })
      }
    }
  }

  return {
    hasParticipation,
    list
  }
}

export const getBusinessRecommendations = (data) => {
  let recommendations = [];

  if (data !== undefined && data !== null && typeof data === "object") {
    const { laudo } = data;

    if (laudo !== undefined && laudo !== null && "participacao_empresarial" in laudo) {
      const { participacao_empresarial: { recommendation } } = laudo;
      recommendations = recommendation;
    }
  }

  return recommendations;
}
