import React, { Component } from 'react';
const TrashGreen = require('../../../img/png/ico-lixeira-green.png');

export default class GreenTrash extends Component {
  render() {
    return (
      <img src={TrashGreen} alt="" />
    )
  }
}
