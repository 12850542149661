import { formatAddress, formatPhone } from '../../../../../utils/formatter';

export const getLocationGiven = (data) => {
  let address = "";
  let phone = "";
  let mobile = "";
  let email = "";

  if (data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("pretendente" in data) {
      const { pretendente } = data;

      if ("endereco" in pretendente) {
        const { endereco } = pretendente;
        address = formatAddress(endereco[0] || {});
      }

      if ("telefones" in pretendente) {
        const { telefones } = pretendente;
        if (Array.isArray(telefones) && telefones.length > 0) {
          telefones.forEach((phoneData) => {
            const key = Object.keys(phoneData)[0];
            if (key === "residencial") {
              phone = formatPhone(phoneData.residencial.numero);
            } else if (key === "celular") {
              mobile = formatPhone(phoneData.celular.numero);
            }
          });
        }
      }

      if ("emails" in pretendente) {
        const { emails: { principal } } = pretendente;
        email = principal;
      }
    }
  }

  return [
    { title: "Endereço", data: address || 'Não fornecido' },
    { title: "Telefone", data: phone || 'Não fornecido' },
    { title: "Celular", data: mobile || 'Não fornecido' },
    { title: "E-mail", data: email || 'Não fornecido' }
  ];
}

export const getLocationChecked = (data, tipo) => {
  let address = [];
  let phones = [];
  let mobiles = [];
  let emails = [];

  if (data !== null && typeof data === "object" && Object.keys(data).length > 0) {
    if ("laudo" in data) {
      const { laudo } = data;

      if ("veracidade_endereco" in laudo) {
        const { veracidade_endereco } = laudo;

        if ("result" in veracidade_endereco) {
          const { result } = veracidade_endereco;
          address = result.map((line) => {
            const logradouro = line.address.logradouro || ''
            const numero = line.address.numero && ', ' + line.address.numero || ''
            const complemento = line.address.complemento && ', ' + line.address.complemento || ''
            const bairro = line.address.bairro && ' - ' + line.address.bairro || ''
            const cidade = line.address.cidade && ' - ' + line.address.cidade || ''
            const cep = line.address.cep && ' - ' + line.address.cep || ''
            const uf = line.address.uf && ' - ' + line.address.uf || ''
            return logradouro + numero + complemento + bairro + cidade + uf + cep
          });
        }
      }

      if ("veracidade_telefone" in laudo) {
        const { veracidade_telefone: { result } } = laudo;
        if (Array.isArray(result) && result.length > 0) {
          phones = result.map((line) => formatPhone(line.phone)).join(' / ');
        }
      }

      if ("veracidade_celular" in laudo) {
        const { veracidade_celular: { result } } = laudo;
        if (Array.isArray(result) && result.length > 0) {
          mobiles = result.map((line) => formatPhone(line.phone)).join(' / ');
        }
      }

      if ("veracidade_email" in laudo) {
        const { veracidade_email: { result } } = laudo;
        if (Array.isArray(result) && result.length > 0) {
          emails = result.map((line) => line.email).join(' / ');
        }
      }
    }
  }

  // if(tipo === 'fcBasico') {
  //   return [
  //     { key: 'otherAddress', title: "Endereços possíveis", data: address.length > 0 ? address : 'Não encontrado' },
  //     { key: 'otherPhones', title: "Telefones possíveis", data: phones.length > 0 ? phones : 'Não encontrado' },
  //     { key: 'otherMobilePhones', title: "Celulares possíveis", data: mobiles.length > 0 ? mobiles : 'Não encontrado' }
  //   ];
  // }

  return [
    { key: 'otherAddress', title: "Endereços possíveis", data: address.length > 0 ? address : 'Não encontrado' },
    { key: 'otherEmails', title: "E-mails possíveis", data: emails.length > 0 ? emails : 'Não encontrado' },
    { key: 'otherPhones', title: "Telefones possíveis", data: phones.length > 0 ? phones : 'Não encontrado' },
    { key: 'otherMobilePhones', title: "Celulares possíveis", data: mobiles.length > 0 ? mobiles : 'Não encontrado' },
  ];
}

export const locationValidation = (data) => {
  const searchKeys = ["veracidade_endereco", "veracidade_telefone", "veracidade_celular", "veracidade_email"];
  return searchKeys.map((key) => data[key] !== undefined && data[key].icon);
}
