import { toastr } from 'react-redux-toastr';
import { isEmpty } from 'lodash';
import * as types from './types';
import { actionCreator, generalActionTypes,extractErrorMessages } from '@bit/wavfichacerta.fichacerta.utils.redux-utils';
import { updateComercialData } from '../../../../services/customer/customer';
import { getCustomerData } from '../../CustomerData/actions';

const { NON_HANDLED_ERROR } = generalActionTypes;

export const saveComercialData = (data) => {
  return async (dispatch, getState) => {
    if (typeof data === "object" && !isEmpty(data)) {
      dispatch(actionCreator(types.SAVE_COMERCIAL_DATA_REQUEST));
      try {
        const { login: { user: { token } } } = getState();
        const payload = await updateComercialData(token.access_token, data);
        if (payload.status !== 200) {
          toastr.warning("Dados comerciais", extractErrorMessages(payload.data.message));
          dispatch(actionCreator(types.SAVE_COMERCIAL_DATA_FAILED));
        } else {
          toastr.success('Dados comerciais', payload.data.message);
          dispatch(actionCreator(types.SAVE_COMERCIAL_DATA_SUCCESS));
          dispatch(getCustomerData());
        }
      } catch (error) {
        console.error(error);
        toastr.error('Dados comerciais', "Erro ao salvar, caso o erro persista contate a FC ANALISE.");
        dispatch(actionCreator(types.SAVE_COMERCIAL_DATA_FAILED));
        dispatch(actionCreator(NON_HANDLED_ERROR, "Erro ao salvar, caso o erro persista contate a FC ANALISE."));
      }
    }
  }
}
