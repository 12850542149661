
export const openModal = (state, payload) => ({
  ...state,
  isOpen: true,
  idProduct: payload.idProduct,
  products: payload.products,
  applicantId: payload.id,
  lastApplicant: payload.lastApplicant
});

export const closeModal = (state) => ({
  ...state,
  isOpen: false,
  idProduct: undefined,
  products: []
});