export const onChangeStage = (state, payload) => (
  {
    ...state,
    loginLoading: false,
    loginError: undefined,
    stage: payload
  }
);

export const resetLogin = (state) => (
  {
    ...state,
    stage: 'login',
    loginError: undefined,
    loginLoading: false,
    resetPasswordToken: undefined,
    setPasswordToken: undefined,
    emailToResetPassword: undefined,
    setPasswordLoading: false,
    setPasswordError: undefined,
    userConfirmed: undefined,
  }
);

export const onLoginProccessing = (state) => (
  {
    ...state,
    loginError: undefined,
    loginLoading: true
  }
);

export const onLoginSuccess = (state, payload) => (
  {
    ...state,
    loginError: false,
    loginLoading: false,
    logged: true,
    user: {
      ...payload.message
    }
  }
);

export const onLoginError = (state, payload) => (
  {
    ...state,
    loginLoading: false,
    loginError: payload,
    loginSuccess: undefined
  }
);

export const saveResetToken = (state, payload) => (
  {
    ...state,
    resetPasswordToken: payload
  }
);

export const saveSetPasswordToken = (state, payload) => (
  {
    ...state,
    setPasswordToken: payload
  }
);

export const saveEmailToResetPassword = (state, payload) => (
  {
    ...state,
    emailToResetPassword: payload
  }
);

export const onLogout = (state) => (
  {
    ...state,
    logged: false,
    user: {
      perfil: {},
      token: {
        ...state.user.token,
        access_token: undefined
      }
    }
  }
);
export const onSetPasswordRequest = (state) => (
  {
    ...state,
    setPasswordLoading: true,
    setPasswordError: undefined
  }
);

export const onStePasswordFailed = (state, payload) => (
  {
    ...state,
    setPasswordLoading: false,
    setPasswordError: payload
  }
);

export const onSetPasswordSuccess = (state) => (
  {
    ...state,
    setPasswordLoading: false,
    setPasswordError: false
  }
);

export const onRefreshToken = (state, payload) => (
  {
    ...state,
    user: {
      ...state.user,
      token: { ...payload }
    }
  }
);

export const onRefreshTokenFailed = (state) => (
  {
    ...state,
    user: {
      ...state.user,
      token: {}
    }
  }
);

export const onRegisterConfirmationSuccess = state => (
  {
    ...state,
    userConfirmed: true
  }
);

export const onRegisterConfirmationFailed = state => (
  {
    ...state,
    userConfirmed: false
  }
);