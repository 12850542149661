import React from 'react';
import { Route, Switch } from 'react-router-dom'

import Charge from '../../pages/finances/charge/Charge';
import Prices from '../../pages/prices/Prices';

export default function Routes() {
  return (
    <Switch>
      {/* <Route exact path="/intranet/consumo/precos" component={Prices} /> */}
      <Route exact path="/intranet/consumo/relatorio" component={Charge} />
    </Switch>
  )
}
