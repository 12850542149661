import { extractReportInitialStructure, updateApplicantReport } from '../../../../../utils/operational/requests/applicants';

export const getReportRequesting = (state) => ({
  ...state,
  requesting: true,
  error: undefined
});

export const getReportSuccess = (state, payload) => ({
  ...state,
  requesting: false,
  error: undefined,
  data: payload
});

export const getReportFailed = (state) => ({
  ...state,
  requesting: false,
  error: true
});

export const onPrepareReport = (state, payload) => ({
  ...state,
  applicants: extractReportInitialStructure(payload.applicants, payload.withIncludedApplicants)
});

export const onReceiveReport = (state, payload) => ({
  ...state,
  applicants: updateApplicantReport(state.applicants, payload)
});

export const getMailFiancaFailed = (state) => ({
  ...state,
  requestingMailFianca: false,
  successMailFianca: false,
  errorMailFianca: true
});

export const getMailFiancaSuccess = (state, payload) => ({
  ...state,
  requestingMailFianca: false,
  errorMailFianca: undefined,
  successMailFianca: true
});

export const getMailFiancaRequesting = (state) => ({
  ...state,
  requestingMailFianca: true,
  errorMailFianca: undefined
});

export const getMailFiancaReset = (state) => ({
  ...state,
  requestingMailFianca: false,
  errorMailFianca: false,
  successMailFianca: false
});