import React, { PureComponent, Fragment } from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';
import {
  ContainerGray,
  AnalythicList,
  AnalythicLine,
  LineTitle,
  TitleBox,
  BorderedBox,
  AnalythicOList,
  AnalythicLineItalic,
  AnalythicTextItalic,
  SubtitleCard,
  SubtitleSemiBold
} from '../analythic.styled';
import {
  getProfileGiven,
  getProfileRecommendations
} from './products/fcAnalise';
import { socioEconomic } from '../../../../pages/operational/request/report/singleReport/actions';
import { formatToBRL } from "../../../../utils/formatter";

class ProfileContainer extends PureComponent {
  constructor(props){
    super(props)

    this.economicProfile = React.createRef()
  }

  componentDidMount(){
    this.props.socioEconomic(this.economicProfile.current)
  }
  
  renderRecommendations = () => {
    const { data } = this.props;
    const recommendations = getProfileRecommendations(data);

    return (
      <Fragment>
        <TitleBox>{recommendations.title}:</TitleBox>
        <AnalythicOList>
          {recommendations.obs_list.map((item) => (<AnalythicLineItalic>{item}</AnalythicLineItalic>))}
        </AnalythicOList>
      </Fragment>
    );
  }

  renderResultFcRenda = (result) => {
    const { data } = this.props;
    const fcDoc = 'produtos' in data.laudo ? data.laudo.produtos.fcrenda : {};
    if(!fcDoc.resultado || !fcDoc.confirmacao_analise_receita || !fcDoc.ativo) {
      return null;
    }

    return(
      <div style={{border: '2px solid #152b4e', padding: '4px 10px', color: '#152b4e', fontSize: 12, marginTop: 6, width: '100%', fontWeight: 'bolder'}} className='result-fcdoc'>
        ANÁLISE DA RECEITA: {fcDoc.confirmacao_analise_receita}
      </div>
    )
  }
  
  render() {
    const { data } = this.props;
    const profile = getProfileGiven(data);
    const recommendations = getProfileRecommendations(data);
    let capitalSocial = '';
    let porteEmpresa = '';

    const isMensal = data.laudo && data.laudo.perfil_socioeconomico && 'tagFrontVersion' in data.laudo.perfil_socioeconomico ? true : false

    if ("laudo" in data && data.laudo !== null && data.laudo !== undefined) {
      const { veracidade_nome } = data.laudo;
      capitalSocial = veracidade_nome.capitalSocial && 'R$ ' + formatToBRL(Number(veracidade_nome.capitalSocial)) || 'Não encontrado';
      porteEmpresa = veracidade_nome.porteEmpresa || 'Não encontrado';
    }

    return (
      <StyledRow margin="1rem 0 0 0">
        <ContainerGray ref={this.economicProfile} className="socioEconomic">
          <StyledRow align="initial" margin="0">
            {/* <SubtitleCard>DADOS SOCIOECONÔMICOS <SubtitleSemiBold>ENCONTRADOS</SubtitleSemiBold> NO MERCADO</SubtitleCard>           */}
            <StyledRow>
            {/* <SubtitleCard><i>(Podem não representar a situação atual do pretendente)</i></SubtitleCard> */}
              {/* <StyledRow>
                <BaseColumn padding="0 .2rem">
                  <AnalythicList>
                    <AnalythicLine>
                      <LineTitle maxWidth="205px">Ocupação</LineTitle>
                      <span>{profile.job}</span>
                    </AnalythicLine>
                  </AnalythicList>
                </BaseColumn>
                <BaseColumn padding="0 .2rem">
                  <AnalythicList>
                    <AnalythicLine>
                      <LineTitle maxWidth="205px">Escolaridade</LineTitle>
                      <span>{profile.scholarity}</span>
                    </AnalythicLine>
                  </AnalythicList>
                </BaseColumn>
              </StyledRow> */}

              <StyledRow>
                <BaseColumn padding="0 .2rem">
                  <AnalythicList>
                    <AnalythicLine style={{color: '#000'}}>
                      <LineTitle maxWidth="260px">Faturamento {isMensal ? 'Mensal' : ''} Presumido (Mercado)</LineTitle>
                      <span>{profile.amount}</span>
                    </AnalythicLine>
                  </AnalythicList>
                </BaseColumn>
                <BaseColumn padding="0 .2rem">
                  <AnalythicList>
                    <AnalythicLine style={{color: '#000'}}>
                      <LineTitle maxWidth="205px">Porte</LineTitle>
                      <span>{porteEmpresa}</span>
                    </AnalythicLine>
                  </AnalythicList>
                </BaseColumn>

                {/* <BaseColumn padding="0 .2rem">
                  <AnalythicList>
                    <AnalythicLine background="transparent">
                      <LineTitle maxWidth="205px">Estado civil</LineTitle>
                      <span>{profile.maritalStatus}</span>
                    </AnalythicLine>
                  </AnalythicList>
                </BaseColumn> */}
              </StyledRow>

              {/* <StyledRow>
                <BaseColumn padding="0 .2rem">
                  <AnalythicList>
                    <AnalythicLine style={{color: '#000'}}>
                      <LineTitle maxWidth="260px">Capital Social</LineTitle>
                      <span>{capitalSocial}</span>
                    </AnalythicLine>
                  </AnalythicList>
                </BaseColumn>
                <BaseColumn padding="0 .2rem">
                  <AnalythicList>
                    <AnalythicLine style={{color: '#000'}}>
                      <LineTitle maxWidth="205px">Porte</LineTitle>
                      <span>{porteEmpresa}</span>
                    </AnalythicLine>
                  </AnalythicList>
                </BaseColumn>
              </StyledRow> */}

              {/* {Array.isArray(recommendations.obs_list) && recommendations.obs_list.length > 0 &&
                <BorderedBox xs={12} md={12} lg={12} xl={12}>
                  { this.renderRecommendations() }
                </BorderedBox>
              } */}

              <BorderedBox xs={12} md={12} lg={12} xl={12}>
                <AnalythicTextItalic style={{color: '#000'}}>
                  O faturamento presumido pelo mercado é apenas uma informação sistêmica baseada em dados estatísticos e de relacionamento capturados com o mercado.
                </AnalythicTextItalic>
              </BorderedBox>
              
            </StyledRow>
          </StyledRow>
          {this.renderResultFcRenda()}
        </ContainerGray>
      </StyledRow>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  socioEconomic
}, dispatch)

export default connect(null, mapDispatchToProps)(ProfileContainer)