import React from 'react';
import styled from 'styled-components';

const SVGContainer = styled.svg`
  width: ${props => props.width || '100%'};
  fill: ${props => props.color? props.theme[props.color] : props.theme.color_black};
`;

export default (props) => (
  <SVGContainer {...props} viewBox="0 0 409.54 365.2">
    <title>2 ico-house</title>
    <path d="M505.6,273.6c-4.93,9.18-10.84,10.47-19.41,4.19q-91-66.6-181.9-133.27c-2.81-2.06-4.49-1.8-7.12.13q-90.89,66.71-181.89,133.28c-3.7,2.71-7.36,4.7-12.14,2.95a10.42,10.42,0,0,1-4-17.24,23.77,23.77,0,0,1,3.08-2.53q95-69.56,190-139.11c6.91-5.05,10.28-5,17.28.09q94.85,69.43,189.63,139c2.64,1.94,4.34,5.15,6.47,7.77Z" transform="translate(-96.06 -118.22)"/>
    <path d="M162.42,461.86h85.13c0-1.84,0-3.42,0-5q0-42.4,0-84.8c0-23,9.92-40.4,30.79-50.54,33.34-16.2,73.83,7.44,75.21,44.46,1.09,
    29.16.38,58.38.47,87.58,0,2.65,0,5.29,0,8.24h85.13v-4.62q0-81.4,0-162.79c0-7.43,4.31-12.31,10.71-12.26a10.65,10.65,0,0,1,10.21,8.49,26,26,0,0,1,.36,4.77q0,87.4,0,174.79a16.79,16.79,0,0,1-1,6.62,10.29,10.29,0,0,1-10.1,6.47c-34.93,0-69.86,0-104.8,0-7.74,0-11.72-4.38-11.73-12.48q0-49.6,0-99.2c0-12.48-4.61-22.75-15.62-29.17-22.25-13-48.15,2.44-48.26,28.68-.14,32.8,0,65.6,0,98.4,0,10.06-3.69,13.8-13.64,13.8-33.6,0-67.2-.18-100.8.13-8.61.08-13.41-5.22-13.38-13.52.17-58.13.08-116.26.13-174.39a19.82,19.82,0,0,1,.89-6.67,10.37,10.37,0,0,1,11.56-6.53,10.85,10.85,0,0,1,8.78,10c.08,1.33,0,2.67,0,4q0,80.2,0,160.39C162.42,458.29,162.42,459.88,162.42,461.86Z" 
    transform="translate(-96.06 -118.22)"/>
  </SVGContainer>
)
