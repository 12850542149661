import React, { Component } from 'react';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { CreditContainer, UsedCreditBar, TitleCreditBar, SubtitleBox } from './creditBar.styled';
import { formatToBRL } from '@bit/wavfichacerta.fichacerta.utils.formatters';

export default class FcCreditBar extends Component {

  getUsedCredit = () => {
    const { available, usage } = this.props;
    if (typeof available === "number" && typeof usage === "number" && available !== undefined && available !== undefined) {
      const percentageUsage = (usage/available)*100;
      return `${percentageUsage}%`;
    }
    return "0%";
  }
  
  render() {
    const { available } = this.props;
    const usage = this.getUsedCredit();

    return (
      <StyledRow justify="flex-start">
        {/* <TitleCreditBar>Utilização FICHA CERTA</TitleCreditBar> */}
        {/* <CreditContainer hasColor={available > 0} activeColor="color_green">
          {available > 0 && <UsedCreditBar width={usage} activeColor="color_border-gray" />}
        </CreditContainer> */}
        
        <StyledRow justify="space-between" padding="0 .5rem">
          <SubtitleBox colorBox="color_border-gray" className='disponivel-alocar'>
            Disponível para alocar à funcionário: <SubtitleBox semiBold>&nbsp;R$ {formatToBRL(this.props.available)}</SubtitleBox>
          </SubtitleBox>

          <SubtitleBox colorBox="color_border-gray" className='total-utilizado'>
            Total do limite disponível utilizado (gastos): <SubtitleBox semiBold>&nbsp;R$ {formatToBRL(this.props.usage)}</SubtitleBox>
          </SubtitleBox>
        </StyledRow>
      </StyledRow>
    );
  }
}
