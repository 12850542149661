import React from "react";
import { BaseColumn } from "@bit/wavfichacerta.fichacerta.shared-styles.columns";
import { StyledCard, StyledRowMobile, StyledLineMobile, StyledTitleMobile, StyledCardBody, StyledHr } from "./styled";
import { formatToBRL } from '../../../../utils/formatter';

const FinancialTableCards = ({ lines }) => {
  return lines.map(line => {
    return (
      <StyledCard>
        <StyledCardBody>
          <StyledRowMobile>
            <BaseColumn xs="3" sm="3">
              <StyledTitleMobile>Data</StyledTitleMobile>
              <StyledLineMobile>{line.data}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="5" sm="5">
              <StyledTitleMobile>Tipo</StyledTitleMobile>
              <StyledLineMobile>{line.tipo_pefin}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="4" sm="4">
              <StyledTitleMobile>Modalidade</StyledTitleMobile>
              <StyledLineMobile>{line.modalidade_credito}</StyledLineMobile>
            </BaseColumn>
          </StyledRowMobile>
          <StyledHr/>
          <StyledRowMobile>
            <BaseColumn xs="3" sm="3">
              <StyledTitleMobile>Avalista</StyledTitleMobile>
              <StyledLineMobile>{line.cpf_avalista}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="3" sm="3">
              <StyledTitleMobile>Valor</StyledTitleMobile>
              <StyledLineMobile>R$ {formatToBRL(line.valor)}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="6" sm="6">
              <StyledTitleMobile>Contrato</StyledTitleMobile>
              <StyledLineMobile>{line.numero_contrato}</StyledLineMobile>
            </BaseColumn>
          </StyledRowMobile>
          <StyledHr/>
          <StyledRowMobile>
            <BaseColumn xs="6" sm="6">
              <StyledTitleMobile>Credor</StyledTitleMobile>
              <StyledLineMobile>{line.empresa}</StyledLineMobile>
            </BaseColumn>
            <BaseColumn xs="6" sm="6">
              <StyledTitleMobile>Filial</StyledTitleMobile>
              <StyledLineMobile>{line.praca}</StyledLineMobile>
            </BaseColumn>
          </StyledRowMobile>
        </StyledCardBody>
      </StyledCard>
    );
  });
};

export default FinancialTableCards;
