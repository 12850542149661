import styled from 'styled-components';

export const SistemicContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > svg { margin: 0 auto .5rem auto; }
`;

export const SistemicTitle = styled.h5`
  font-size: 17px;
  text-align: center;
  color: ${props => {
    switch (props.status) {
      case "PARECER_NIVEL_1":
        return props.theme.color_green;
      case "PARECER_NIVEL_2":
        return props.theme.color_green;
      case "PARECER_NIVEL_3":
        return props.theme.color_orange;
      case "PARECER_NIVEL_4":
        return props.theme.color_systemic_feedback_regular;
      case "PARECER_NIVEL_5":
        return props.theme.color_red;
      default:
        return props.theme.color_orange;
    }
  }};
`;

export const SistemicSubtitle = styled.p`
  width: 95%;
  margin: .5rem auto 1rem auto;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.color_gray};
`;

export const SistemicSubtitleSmall = styled(SistemicSubtitle)`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  text-transform: unset;
`;
