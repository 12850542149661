
export const onGetRequests = (state) => ({
  ...state,
  requesting: true,
  error: undefined
});

export const onGetRequestsSuccess = (state, payload) => ({
  ...state,
  requesting: false,
  error: false,
  data: payload.data,
  pagination: {
    ...state.pagination,
    totalCount: payload.pagination.total,
    lastPage: payload.pagination.last_page,
    currentPage: payload.pagination.current_page,
    perPage: parseInt(payload.pagination.per_page, 10),
  }
});

export const onGetRequestsFailed = (state) => ({
  ...state,
  requesting: false,
  error: true
});

export const onChangeSort = (state, payload) => ({
	...state,
	pagination: {
		...state.pagination,
		sort: payload
	}
});

export const onChangePage = (state, payload) => ({
  ...state,
  pagination: {
    ...state.pagination,
    currentPage: payload
  }
});

export const onChangeResultsPerPage = (state, payload) => ({
  ...state,
  pagination: {
    ...state.pagination,
    resultsPerPage: payload,
    currentPage: 1
  } 
});

export const onResetPagination = (state) => ({
  ...state,
  pagination: {
    totalCount: undefined,
    numberOfPages: undefined,
    lastPage: undefined,
    currentPage: 1,
    resultsPerPage: state.pagination.resultsPerPage,
    sort: 'nome',
    nextPage: '',  
    previousPage: '',
    filter: '',
    status: 'ATIVO'
  }
});

export const onSetInitialDate = (state, payload) => ({
  ...state,
  pagination: {
    ...state.pagination,
    date: {
      ...state.pagination.date,
      startDate: payload
    }
  }
});

export const onSetEndDate = (state, payload) => ({
  ...state,
  pagination: {
    ...state.pagination,
    date: {
      ...state.pagination.date,
      endDate: payload
    }
  }
});

export const onSetFilter = (state, payload) => ({
  ...state,
  pagination: {
    ...state.pagination,
    filter: payload
  }
});

export const onResetRequests = (state, initialState) => ({
  ...state,
  ...initialState
});