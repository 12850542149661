import styled, { css } from 'styled-components';
import Card from '@bit/wavfichacerta.fichacerta.cards.card';
import { StyledRow } from '@bit/wavfichacerta.fichacerta.shared-styles.rows';
import { BaseColumn } from '@bit/wavfichacerta.fichacerta.shared-styles.columns';

export const CardAnalythic = styled(Card)`
  width: 99%;
  margin: 0 auto .5rem auto;
  position: relative;

  svg path {
    fill: #152b4e;
  }
`;

export const ContainerGray = styled.div`
  width: 100%;
  height: 100%;
  padding: ${props => props.padding || 'auto'};
  margin: ${props => props.margin || 'auto'};
  background-color: ${props => props.theme['color_light-gray']};
`;

export const AnalythicList = styled.ul`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const AnalythicLine = styled.li`
  display: flex;
  list-style-type: none;
  justify-content: flex-start;
  width: auto;
  height: ${props => props.height || 'auto !important'};
  color: ${props => props.theme.color_gray};
  padding: ${props => props.padding || '.3rem'};
  margin: ${props => props.margin || '.1rem 0'};
  font-size: 12px;
  white-space: pre-line;
  
  &:nth-of-type(odd) {
    background-color: ${props => props.theme[props.background] || 'rgba(0, 0, 0, 0.05)'};
  }
  
  @media(max-width: 800px){
    height: ${props => props.height || 'auto !important'};
    margin: .3rem .1rem;
    padding: .1rem;
    font-size: 10px;
    align-text: center !important;
  }
`;

export const ContentCenter = styled.div`
  width: 100%;
  text-align: center;
`;

export const LineTitle = styled(BaseColumn)`
  flex-basis: unset;
  max-width: ${props => props.maxWidth || 'auto'};
  min-width: ${props => props.minWidth || 'auto'};
  font-weight: 500;

  @media(max-width: 800px){
    display: flex;
    align-items: center;
    font-size: 10px;
    width: 50%;
  }
`;

export const SubtitleCard = styled.p`
  font-size: 13px;
  margin-bottom: .5rem;
  text-align: center;
  color: ${props => props.theme.color_gray};
`;

export const SubtitleSemiBold = styled.span`
  font-weight: 500;
`;

export const IconContainer = styled.div`
  width: 15px;
  margin: .1rem auto;
`;

export const RecommendationBox = styled(StyledRow)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem;
  margin: ${props => props.margin || 'auto'};
  background-color: ${props => props.theme['color_yellow-box']};
`;

export const RecommendationsColumn = styled(BaseColumn)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RecommendationText = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.color_gray};
  font-size: 13px;
  font-weight: 500;
  padding: 0 .3rem;
`;

export const SpanRecommendation = styled.span`
  font-size: 12px;
`;

export const IconRecommendation = styled.div`
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  margin-right: .1rem;
`;

export const BorderedBox = styled(BaseColumn)`
  margin: ${props => props.margin || "0"};
  padding: .5rem;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme['color_light-gray']};
  border: 1px solid ${props => props.theme['color_border-light-gray']};
`;

export const TitleBox = styled.p`
  margin-bottom: .3rem;
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color_gray};
`;

export const AnalythicOList = styled.ol`
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  counter-reset: analythic-list-counter;
`;

export const AnalythicLineItalic = styled.li`
  color: ${props => props.theme.color_gray};
  font-size: 12px;
  font-style: italic;
  counter-increment: analythic-list-counter;

  ${props => {
    if (!props.hideNumber) {
      return `
      &:before {
        content: counter(analythic-list-counter) ". ";
        font-weight: 500;
      }
      `;
    }
  }}
`;

export const AnalythicTextItalic = styled.p`
  color: ${props => props.theme.color_gray};
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0;
`;

export const RiskText = styled.p`
  margin: 0;
  padding: .5rem;
  font-size: 12px;
  color: ${props => props.theme.color_gray};
`;

export const SistemicFeedbackTitle = styled.span`
  display: flex;
  align-items: center;
  padding: 0 1rem 0 0;
  font-weight: 500;
  text-align: center;
  ${props => {
    switch (props.type) {
      case "PARECER_NIVEL_1":
        return `color: ${props.theme['color_systemic_feedback_very_good']}`;
      case "PARECER_NIVEL_2":
        return `color: ${props.theme['color_systemic_feedback_good']}`;
      case "PARECER_NIVEL_3":
        return `color: ${props.theme['color_systemic_feedback_regular']}`;
      case "PARECER_NIVEL_4":
        return `color: ${props.theme['color_systemic_feedback_inconsistent']}`;
      case "PARECER_NIVEL_5":
      case "ALERTA_ACAO_CRIMINAL":
      case "ALERTA_FRAUDE_CRIMINAL":
      case "ALERTA_FRAUDE":
        return `color: ${props.theme['color_systemic_feedback_not_advisable']}`;
      case "other":
        return `color: #7f7f7f`;
      default:
        return `color: ${props.theme['color_systemic_feedback_others']}`;
    }
  }}
`;

export const DivisorGray = styled.hr`
  width: 100%;
  margin: 0;
  border: 1px solid ${props => props.theme['color_border-light-gray']};
  border-right: 0;
`;

export const ConceptTitle = styled.p`
  padding: 1rem .5rem .5rem;
  margin: 0;
  font-size: 13px;
  color: ${props => props.theme.color_gray};
`;

export const ConceptLine = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: ${props => props.theme.color_gray};
  display: flex;

  & > b { font-weight: 500; }
`;

export const ColoredSpan = styled.span`
  color: ${props => props.theme[props.color || 'color_red']};
  font-weight: ${props => props.fontWeight || 400}
`;

ColoredSpan.defaultProps = {
  className: 'ignore-css-print bold-print'
}

export const OtherDataList = styled.ul`
  padding-inline-start: 1rem;
`;

export const Recommendation = styled.p`
  width: 100%;
  line-height: 1.7;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  margin: 10px 0 0 0;
  color: #7f7f7f;

  @media(max-width: 800px){
    margin: 50px 0 0 0;
  }
`;