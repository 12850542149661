import React, { Component } from 'react';
const TrashGray = require('../../../img/png/ico-lixeira-gray.png');

export default class GrayTrash extends Component {
  render() {
    return (
      <img src={TrashGray} alt="" />
    )
  }
}
