import React, { Fragment } from 'react';
import CustomTooltip from '../../shared/CustomTooltip';

const tooltipStyle = 'width: auto; height: auto !important; padding-left: 15px; padding-right: 15px;max-height: max-content !important;';

const applicantsNames = (name, doc) => (
  <Fragment>
    <div style={{display: "flex", width: "inherit", whiteSpace: "nowrap"}}>
      <span style={{flex: 1}} >{ name } </span>
      <span style={{width: "10px"}} />
      <span style={{flex: 1}}> {doc && doc.length <= 14 ? "CPF:" : "CNPJ:"} { doc }</span>
    </div>
  </Fragment>
);

const tooltipPosition = (index, length) => {
  return index === (length - 1) || index === (length - 2) ? 'top' : 'bottom';
}

const handleMessage = (type, text, extraData) => {
  if (type === 'applicantName' && Array.isArray(text)) {
    return text.map((name, index) => applicantsNames(name, extraData[index]));
  }

  return text
}

const RequestTableToolTip = ({ children, message, index, length, type, extraData }) => {
  
  return (
  <CustomTooltip
    key={new Date().getTime().toString()}
    message={handleMessage(type, message, extraData)}
    position={tooltipPosition(index, length)}
    target={`${type}${index}`}
    innerstyle={tooltipStyle}
    customstyle="opacity: 1 !important;"
    
  >
    
    {children}
  </CustomTooltip>
)};

export default RequestTableToolTip;
