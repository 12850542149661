import React, { Component } from 'react'

export default class Atendimento extends Component {
  render() {
    return (
        <svg viewBox="0 0 392.598 392.598" className="nano">
            <path className="white" d="M360.921,180.105v-10.537c0-93.479-76.089-169.503-169.568-169.503S21.721,76.089,21.721,169.568v14.739    C8.727,191.871,0,205.834,0,221.931v33.228c0,24.048,19.523,43.572,43.572,43.572h30.578c6.012,0,10.925-4.849,10.925-10.925    v-98.521c0-6.012-4.849-10.925-10.925-10.925H43.572h-0.065v-8.792c0-81.455,66.327-147.717,147.717-147.717    s147.717,66.327,147.717,147.717v8.792h-20.622c-6.012,0-10.925,4.848-10.925,10.925v98.651c0,6.012,4.848,10.925,10.925,10.925    h14.739v24.113c0,13.576-11.055,24.63-24.63,24.63h-79.321c-5.301-13.446-20.234-23.208-37.818-23.208    c-22.044,0-39.887,15.321-39.887,34.069c0,18.747,17.907,34.069,39.887,34.069c17.519,0,32.453-9.762,37.818-23.208h79.451    c25.6,0,46.416-20.881,46.416-46.416v-24.566c21.204-2.909,37.624-21.204,37.624-43.119v-33.228    C392.533,202.149,379.152,185.341,360.921,180.105z M43.572,200.275h19.717v76.8H43.572c-12.024,0-21.786-9.826-21.786-21.786    v-33.228C21.786,210.036,31.612,200.275,43.572,200.275z M191.354,370.812c-9.826,0-18.101-5.624-18.101-12.283    s8.275-12.283,18.101-12.283c9.826,0,18.101,5.624,18.101,12.283C209.39,365.188,201.115,370.812,191.354,370.812z     M370.747,255.289c0,12.024-9.826,21.786-21.786,21.786h-19.653V200.21h19.653c12.024,0,21.786,9.826,21.786,21.786V255.289z"/>
        </svg>
    )
  }
}
